import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import {
  A,
  H2,
  H3,
  LinkButton,
  P,
  Page,
  PrimaryButton,
  DeleteButton,
  SecondaryButton,
  UL,
} from "ui-library";
import { InspectorsApi } from "services";
import { InspectorLicenseWidget } from "../components/inspection-license";
import { ProfessionalIndemnityWidget } from "../components/professional-imdemnity";
import { InspectionAgreementWidget } from "../components/inspection-agreement";
import { SampleReportsWidget } from "../components/sample-reports";
import { Stats } from "../components/stats";
import { Availability } from "../components/availability";
import { useDispatch, useSelector } from "react-redux";
import {
  inspectionTypesSelector,
  inspectorSelector,
  servicesSelector,
  updateCurrentInspector,
} from "store/features/inspectors/inspectorsSlice";
import { useNavigate } from "react-router-dom";
import { CircularProgressBar, Loader, Modal, Socials } from "components";
import { companySelector } from "store/features/companies/companiesSlice";
import { CompanyNameWidget } from "../components/company-name";
import openIcon from "assets/icons/open.svg";
import editIcon from "assets/icons/edit.svg";
import deleteIcon from "assets/icons/delete.svg";
import deleteWhiteIcon from "assets/icons/delete-white.svg";
import { DocumentTitle } from "components/document-title";
import { useAuth } from "common/authentication";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import NetworkImage from "components/network-image";
import InspectorDocumentDialog from "inspectors/components/inspector-docs";
import { useState } from "react";
import { toast } from "react-toastify";
import Toast from "components/toast";
import { displayErrorMessage } from "services/api";
import { InspectorBioWidget } from "inspectors/components/bio";
import { InspectionTypesWidget } from "inspectors/components/inspection-types";
import { InspectorAddressWidget } from "inspectors/components/address";

const Content = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

const InspectorProfileContainer = styled.div`
  padding: 32px 24px;
  background-color: #ffffff;
  border-radius: 32px;
  border: 1px solid #e4f0f7;
  box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
  margin-top: 64px;
  margin-bottom: 64px;

  @media only screen and (max-width: 576px) {
    padding: 24px;
    border-radius: 24px;

    ${PrimaryButton}, ${SecondaryButton} {
      width: 100%;
    }
  }

  @media only screen and (max-width: 576px) {
    padding: 16px;
    border-radius: 16px;
  }
`;

const InspectorProfileContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${PrimaryButton} {
    width: 200px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    > div {
      width: 100%;
    }

    ${PrimaryButton} {
      width: 100%;
    }
  }
`;

const InspectorProfileAbout = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const InspectorProfileImage = styled(NetworkImage)`
  width: 104px;
  height: 104px;
  border-radius: 50%;

  @media only screen and (min-width: 577px) {
    margin-right: 32px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 32px;
  }
`;

const Separator = styled.hr`
  width: calc(100% + 48px);
  height: 1px;
  border: none;
  background-color: #e4f0f7;
  margin: 32px -32px;

  @media only screen and (max-width: 768px) {
    width: calc(100% + 32px);
    margin: 32px -16px;
  }
`;

const StyledCircularProgressBar = styled(CircularProgressBar)`
  margin-top: 88px;

  @media only screen and (max-width: 576px) {
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;

const StyledH2 = styled(H2)`
  margin-top: 88px;

  @media only screen and (max-width: 576px) {
    margin-top: 32px;
    text-align: center;
  }
`;

const StyledP = styled(P)`
  @media only screen and (max-width: 576px) {
    text-align: center;
  }
`;

function InspectorDocumentActions({ hasDocument, type }) {
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();

  if (hasDocument) {
    return (
      <div style={{ display: "flex" }}>
        {/* View */}
        <LinkButton
          style={{ marginRight: 24 }}
          onClick={() =>
            InspectorsApi.downloadFileOfTypeForCurrentInspector(type)
          }
        >
          <span>View</span>
          <img src={openIcon} alt="open" width={14} style={{ marginLeft: 8 }} />
        </LinkButton>

        {/* Update */}
        <Modal
          trigger={
            <LinkButton style={{ marginRight: 24 }}>
              <span>Update</span>
              <img
                src={editIcon}
                alt="edit"
                width={14}
                style={{ marginLeft: 8 }}
              />
            </LinkButton>
          }
        >
          {(closeModal) => (
            <InspectorDocumentDialog
              title={
                type === "InspectionAgreement"
                  ? "Update Inspection Agreement"
                  : "Update Sample Report"
              }
              type={type}
              onComplete={closeModal}
            />
          )}
        </Modal>

        {/* Delete */}
        <Modal
          size="small"
          trigger={
            <LinkButton>
              <span style={{ color: "#CA0B0B" }}>Delete</span>
              <img
                src={deleteIcon}
                alt="delete"
                width={14}
                style={{ marginLeft: 8 }}
              />
            </LinkButton>
          }
          title="Delete file"
        >
          {(closeModal) => (
            <>
              <P>
                Are you you want to delete the uploaded file for{" "}
                <strong>
                  {type === "InspectionAgreement"
                    ? "Inspection Agreement"
                    : "Sample Report"}
                </strong>
                ?
              </P>

              <div
                style={{
                  marginTop: 32,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <SecondaryButton
                  style={{ width: 144 }}
                  onClick={isDeleting ? null : closeModal}
                >
                  Cancel
                </SecondaryButton>
                <DeleteButton
                  style={{ width: 144 }}
                  onClick={async () => {
                    try {
                      setIsDeleting(true);

                      await InspectorsApi.deleteFileOfType(type);
                      const updatedInspector =
                        await InspectorsApi.getCurrentInspectorProfile();
                      dispatch(updateCurrentInspector(updatedInspector));
                      toast.success(
                        <Toast
                          title={`${
                            type === "InspectionAgreement"
                              ? "Inspection agreement"
                              : "Sample report"
                          } has been removed`}
                        />
                      );
                      setIsDeleting(false);
                    } catch (e) {
                      setIsDeleting(false);
                      displayErrorMessage(e);
                    }
                  }}
                >
                  {isDeleting ? (
                    <noscript />
                  ) : (
                    <img
                      src={deleteWhiteIcon}
                      alt="delete"
                      width={14}
                      style={{ marginRight: 8 }}
                    />
                  )}

                  {isDeleting ? <Loader loading color="#fff" /> : "Delete"}
                </DeleteButton>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      {/* Add */}
      <Modal
        trigger={
          <LinkButton>
            <span>Add</span>
            <img
              src={editIcon}
              alt="edit"
              width={14}
              style={{ marginLeft: 8 }}
            />
          </LinkButton>
        }
      >
        {(closeModal) => (
          <InspectorDocumentDialog
            title={
              type === "InspectionAgreement"
                ? "Add Inspection Agreement"
                : "Add Sample Report"
            }
            type={type}
            onComplete={closeModal}
          />
        )}
      </Modal>
    </div>
  );
}

export default function InspectorDashboard() {
  const { user, session } = useAuth();
  const inspector = useSelector(inspectorSelector);
  const navigate = useNavigate();
  const inspectionServices = useSelector(servicesSelector);
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const company = useSelector(companySelector);

  if (!inspector || !inspectionServices || !inspectionTypes) {
    return <noscript />;
  }

  const completionPercentage = Number.parseFloat(
    inspector.completionPercentage
  ).toFixed(2);
  const inspectionTypeNames =
    inspectionTypes?.length > 0
      ? inspector.inspectionTypeIds
          ?.map((inspectionTypeId) => {
            const inspectionType = inspectionTypes.find(
              (_) => _.id === inspectionTypeId
            );
            return inspectionType?.name;
          })
          .filter((_) => !!_)
      : null;

  return (
    <DocumentTitle title="Dashboard">
      <Page>
        <Content>
          <Container>
            <Row>
              {completionPercentage < 100 ? (
                <Col xs={12} md={3}>
                  <StyledCircularProgressBar
                    percentage={Math.floor(completionPercentage)}
                  />
                </Col>
              ) : (
                <noscript />
              )}

              {completionPercentage < 100 ? (
                <Col xs={12} md={9}>
                  <StyledH2>Complete my profile</StyledH2>
                  <StyledP>
                    Fill out the goals below to complete your profile and become
                    a verified inspector.
                  </StyledP>

                  <CompanyNameWidget />

                  <InspectorBioWidget inspector={inspector} />

                  <InspectionTypesWidget inspector={inspector} />

                  <InspectorAddressWidget inspector={inspector} />

                  <InspectorLicenseWidget inspector={inspector} />

                  <ProfessionalIndemnityWidget inspector={inspector} />

                  <InspectionAgreementWidget inspector={inspector} />

                  <SampleReportsWidget inspector={inspector} />
                </Col>
              ) : (
                <noscript />
              )}

              <Col xs={12}>
                <Availability />
              </Col>

              <Stats />

              <Col xs={12}>
                <InspectorProfileContainer>
                  <InspectorProfileContainerHeader>
                    <InspectorProfileAbout>
                      <InspectorProfileImage
                        src={
                          user?.profilePhotoPath
                            ? user.profilePhotoPath + "?w=104&h=104"
                            : null
                        }
                      />

                      <div className="flex column">
                        <H3 style={{ fontSize: "26px" }}>
                          {company?.name && company?.name !== "Company name"
                            ? company?.name
                            : session?.fullName}
                        </H3>

                        <P style={{ fontSize: "20px", color: "#143A5A" }}>
                          {inspectionTypeNames ? (
                            inspectionTypeNames.length > 1 ? (
                              inspectionTypeNames[0] +
                              ` + ${inspectionTypeNames.length - 1} more`
                            ) : (
                              inspectionTypeNames[0]
                            )
                          ) : (
                            <noscript />
                          )}
                        </P>
                      </div>
                    </InspectorProfileAbout>

                    <ActionsContainer>
                      <PrimaryButton
                        onClick={() =>
                          navigate(
                            "/" +
                              INSPECTORS_MASTER_ROUTE +
                              "/" +
                              INSPECTOR_ROUTES.EDIT_PROFILE
                          )
                        }
                      >
                        Edit profile
                      </PrimaryButton>

                      <Socials socials={inspector.socials} />
                    </ActionsContainer>
                  </InspectorProfileContainerHeader>

                  <Separator style={{ marginLeft: -24, marginRight: 0 }} />

                  {/* <Container> */}
                  <Row>
                    <Col xs={12} style={{ marginBottom: 48 }}>
                      {inspector.bio ? <P>{inspector.bio}</P> : <noscript />}
                    </Col>

                    {/* Column 1 */}
                    <Col xs={12} md={4} style={{ marginBottom: 24 }}>
                      {/* License number */}
                      {inspector.licenses?.length ? (
                        <>
                          <P
                            style={{
                              color: "#143A5A",
                              fontWeight: 700,
                            }}
                          >
                            {inspector.licenses.length > 1
                              ? "Licenses"
                              : "License"}
                          </P>
                          {inspector.licenses.map((license, i) => (
                            <P key={i}>
                              {license.issuingAuthority}{" "}
                              {license.number ? ` - ${license.number}` : null}
                            </P>
                          ))}
                        </>
                      ) : (
                        <noscript />
                      )}

                      {/* Languages */}
                      {/* <P
                        style={{
                          color: "#143A5A",
                          fontWeight: 700,
                          marginTop: 24,
                        }}
                      >
                        Languages spoken
                      </P>
                      <P>English</P> */}

                      {/* Established Year */}
                      {inspector.establishedYear ? (
                        <>
                          <P
                            style={{
                              color: "#143A5A",
                              fontWeight: 700,
                              marginTop: 16,
                            }}
                          >
                            Years in business
                          </P>
                          <P style={{ marginBottom: 16 }}>
                            {new Date().getFullYear() -
                              Number(inspector.establishedYear)}{" "}
                            years
                          </P>
                        </>
                      ) : (
                        <noscript />
                      )}

                      {/* Website */}
                      {inspector.website ? (
                        <>
                          <P
                            style={{
                              color: "#143A5A",
                              fontWeight: 700,
                            }}
                          >
                            Website
                          </P>
                          <A
                            style={{ display: "block", marginBottom: 16 }}
                            href={inspector.website}
                            target="_blank"
                          >
                            {inspector.website}
                          </A>
                        </>
                      ) : (
                        <noscript />
                      )}

                      {/* Inspection Agreement */}
                      <P
                        style={{
                          color: "#143A5A",
                          fontWeight: 700,
                          marginTop: 8,
                        }}
                      >
                        Inspection agreement
                      </P>
                      <InspectorDocumentActions
                        hasDocument={!!inspector.inspectionAgreementPath}
                        type="InspectionAgreement"
                      />

                      {/* Sample report */}
                      <P
                        style={{
                          color: "#143A5A",
                          fontWeight: 700,
                          marginTop: 8,
                        }}
                      >
                        Sample report
                      </P>
                      <InspectorDocumentActions
                        hasDocument={!!inspector.sampleReportPath}
                        type="SampleReport"
                      />
                    </Col>

                    {/* Column 2 */}
                    {inspectionTypes ? (
                      <Col xs={12} md={4} style={{ marginBottom: 48 }}>
                        {inspector.inspectionTypeIds?.length ? (
                          <>
                            <P
                              style={{
                                color: "#143A5A",
                                fontWeight: 700,
                              }}
                            >
                              Inspection types
                            </P>
                            <UL>
                              {inspector.inspectionTypeIds
                                .map((inspectionTypeId) =>
                                  inspectionTypes.find(
                                    (_) => _.id === inspectionTypeId
                                  )
                                )
                                .filter((_) => !!_)
                                .map((inspectionType) => (
                                  <li key={inspectionType.id}>
                                    {inspectionType.name}
                                  </li>
                                ))}
                            </UL>
                          </>
                        ) : (
                          <noscript />
                        )}
                      </Col>
                    ) : (
                      <noscript />
                    )}

                    {/* Column 3 */}
                    {/* Service Areas */}
                    <Col xs={12} md={4} style={{ marginBottom: 48 }}>
                      {inspector.inspectionServiceIds?.length ||
                      inspector.otherInspectionServices?.length ? (
                        <>
                          <P
                            style={{
                              color: "#143A5A",
                              fontWeight: 700,
                            }}
                          >
                            Other services
                          </P>
                          <UL>
                            {inspector.inspectionServiceIds
                              ?.map((inspectionServiceId) =>
                                inspectionServices.find(
                                  (_) => _.id === inspectionServiceId
                                )
                              )
                              .filter((_) => !!_)
                              .map((inspectionService) => (
                                <li key={inspectionService.id}>
                                  {inspectionService.name}
                                </li>
                              ))}

                            {inspector.otherInspectionServices?.map(
                              (otherInspectionService, index) => (
                                <li key={`other-service-${index}`}>
                                  {otherInspectionService}
                                </li>
                              )
                            )}
                          </UL>
                        </>
                      ) : (
                        <noscript />
                      )}
                    </Col>
                  </Row>
                  {/* </Container> */}
                </InspectorProfileContainer>
              </Col>
            </Row>
          </Container>
        </Content>
      </Page>
    </DocumentTitle>
  );
}
