export default function RMICountryIcon() {
  return (
    <svg
      width="186"
      height="116"
      viewBox="0 0 186 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_270_396)">
        <path
          d="M74.6167 43.9346L73.6885 43.1323L87.9724 26.5436L66.6153 21.6265L66.4864 21.4022L55.4598 2.42404L44.2957 21.6265L21.9588 26.7679L21.6924 25.5775L43.5136 20.5569L55.4598 0L67.406 20.5569L90.2155 25.8017L74.6167 43.9346Z"
          fill="#3B7BBF"
        />
        <path
          d="M4.7704 54.3899H1.01465V51.1118H4.7704V54.3899ZM4.7704 69.1842H1.01465V55.7529H4.7704V69.1842Z"
          fill="#143A5A"
        />
        <path
          d="M20.0678 61.4635V69.1842H16.3894V61.6964C16.3894 59.4794 15.487 58.7807 14.2322 58.7807C11.9633 58.7807 10.7944 61.2047 10.7085 61.6964V69.1842H7.02148V55.7528H10.7085V58.289C10.8116 57.5126 12.3242 55.6234 15.0573 55.6234C17.5496 55.6234 20.0678 57.2279 20.0678 61.4635Z"
          fill="#143A5A"
        />
        <path
          d="M26.8922 58.703C26.325 58.703 25.8351 58.9877 25.8351 59.5657C25.8351 60.1437 26.1187 60.3593 26.7805 60.5922L28.5595 61.2392C30.3901 61.9121 32.3926 62.585 32.3926 65.2419C32.3926 68.08 29.4104 69.3999 26.9352 69.3999C24.46 69.3999 21.9762 68.1576 21.3574 65.8112L24.417 64.8537C24.6709 65.2941 25.0394 65.6568 25.483 65.903C25.9266 66.1491 26.4286 66.2695 26.9352 66.2512C27.7345 66.2512 28.5337 65.863 28.5337 65.1901C28.5337 64.7502 28.2501 64.4396 27.5798 64.2067L25.9382 63.5684C23.4373 62.6367 22.002 61.6792 22.002 59.5916C22.002 57.1072 24.2881 55.632 26.8149 55.632C27.9189 55.5886 29.009 55.8918 29.9333 56.4994C30.8576 57.1071 31.5701 57.989 31.9715 59.0222L28.6799 59.8849C28.5189 59.542 28.2669 59.2503 27.9515 59.0418C27.6362 58.8333 27.2696 58.7161 26.8922 58.703Z"
          fill="#143A5A"
        />
        <path
          d="M47.0541 62.447C47.0541 67.1743 43.6164 69.3482 40.7802 69.3482C38.666 69.3482 37.3425 67.9075 37.3425 67.3899V74.0755H33.6641V55.7528H37.3425V57.3315C37.3425 56.7621 38.6488 55.6062 40.7802 55.6062C41.6434 55.6303 42.493 55.8277 43.2789 56.1868C44.0648 56.546 44.7711 57.0595 45.3559 57.6972C45.9407 58.3349 46.3923 59.0838 46.684 59.8996C46.9757 60.7154 47.1016 61.5816 47.0541 62.447ZM43.3757 62.447C43.3757 60.1178 41.8116 58.7549 40.1872 58.7549C38.5629 58.7549 37.1792 59.7038 37.1792 62.447C37.1792 65.1902 38.6402 66.1909 40.1872 66.1909C41.7342 66.1909 43.3757 64.8451 43.3757 62.447Z"
          fill="#143A5A"
        />
        <path
          d="M61.6736 62.5246C61.6777 62.9753 61.6432 63.4256 61.5705 63.8703H52.0393C52.2417 64.5357 52.6497 65.1192 53.2044 65.5364C53.7591 65.9535 54.4316 66.1827 55.1247 66.1909C55.5539 66.2177 55.984 66.1548 56.3879 66.0064C56.7917 65.8579 57.1605 65.627 57.4709 65.3282L60.5048 66.881C59.8279 67.6503 58.996 68.2666 58.0641 68.6889C57.1321 69.1113 56.1214 69.3301 55.0989 69.3309C53.2754 69.3412 51.5225 68.624 50.2258 67.337C48.9292 66.0501 48.1949 64.2989 48.1847 62.4685C48.1744 60.6382 48.889 58.8788 50.1711 57.5773C51.4533 56.2758 53.198 55.5389 55.0215 55.5286C55.9186 55.5407 56.8043 55.7323 57.6268 56.0922C58.4492 56.452 59.1919 56.973 59.8113 57.6244C60.4307 58.2759 60.9145 59.0448 61.2343 59.8862C61.554 60.7276 61.7034 61.6246 61.6736 62.5246ZM52.0393 61.0754H57.935C57.7602 60.4214 57.3744 59.844 56.8379 59.4335C56.3014 59.023 55.6445 58.8026 54.97 58.8066C54.2975 58.7951 53.6412 59.0134 53.1087 59.4257C52.5761 59.8379 52.199 60.4197 52.0393 61.0754Z"
          fill="#143A5A"
        />
        <path
          d="M75.2441 58.6513L71.9782 59.8331C71.7004 59.4794 71.3427 59.1972 70.9348 59.0097C70.5268 58.8222 70.0802 58.7348 69.6319 58.7548C68.0677 58.7548 66.5208 60.1178 66.5208 62.5246C66.5208 64.9314 68.0677 66.1908 69.6319 66.1908C70.0792 66.2165 70.526 66.1334 70.9345 65.9487C71.343 65.7639 71.701 65.483 71.9782 65.1298L75.2699 66.3202C74.6047 67.289 73.7018 68.0692 72.6485 68.5854C71.5952 69.1016 70.427 69.3365 69.2569 69.2672C68.0867 69.1979 66.9541 68.8269 65.9686 68.1899C64.983 67.553 64.1778 66.6717 63.6307 65.6311C63.0836 64.5906 62.8131 63.426 62.8454 62.2499C62.8776 61.0738 63.2116 59.926 63.8149 58.9172C64.4182 57.9085 65.2706 57.073 66.2896 56.4915C67.3086 55.91 68.4598 55.6021 69.6319 55.5975C70.7461 55.6004 71.8422 55.881 72.8217 56.414C73.8013 56.9469 74.6336 57.7157 75.2441 58.6513Z"
          fill="#143A5A"
        />
        <path
          d="M81.0969 58.7548V64.8709C81.0969 65.4662 81.3203 66.1649 82.1969 66.1649C82.7854 66.1857 83.3715 66.0798 83.9158 65.8543V68.8736C83.1024 69.2221 82.221 69.3814 81.3375 69.3394C79.2061 69.3394 77.427 68.6148 77.427 64.8968V58.7548H76.1035V55.7528H77.4099V53.4323H81.0969V55.7528H83.9502V58.7548H81.0969Z"
          fill="#143A5A"
        />
        <path
          d="M98.2252 62.5246C98.1625 64.3002 97.4156 65.9821 96.1419 67.216C94.8682 68.4499 93.1672 69.1394 91.397 69.1394C89.6269 69.1394 87.9258 68.4499 86.6521 67.216C85.3784 65.9821 84.6315 64.3002 84.5688 62.5246C84.5362 61.6041 84.6887 60.6864 85.0172 59.8264C85.3457 58.9663 85.8435 58.1815 86.4807 57.5188C87.118 56.856 87.8818 56.3289 88.7264 55.9689C89.5711 55.6089 90.4793 55.4233 91.397 55.4233C92.3147 55.4233 93.2229 55.6089 94.0676 55.9689C94.9122 56.3289 95.676 56.856 96.3132 57.5188C96.9505 58.1815 97.4483 58.9663 97.7768 59.8264C98.1053 60.6864 98.2578 61.6041 98.2252 62.5246ZM88.2472 62.5246C88.2472 64.9227 89.8629 66.1908 91.4099 66.1908C92.9569 66.1908 94.5468 64.9227 94.5468 62.5246C94.5468 60.1264 92.9311 58.7548 91.4099 58.7548C89.8887 58.7548 88.2472 60.0401 88.2472 62.5246Z"
          fill="#143A5A"
        />
        <path
          d="M109.003 55.727V59.1775C108.579 59.1112 108.151 59.0852 107.722 59.0999C105.428 59.0999 103.657 60.8252 103.425 63.4131V69.2187H99.7725V55.7528H103.425V60.0661C103.709 57.5903 104.938 55.6234 107.722 55.6234C108.152 55.6103 108.581 55.645 109.003 55.727Z"
          fill="#143A5A"
        />
        <path
          d="M130.695 62.5246C130.507 64.2109 129.706 65.7683 128.445 66.8992C127.185 68.0302 125.553 68.6554 123.863 68.6554C122.172 68.6554 120.541 68.0302 119.28 66.8992C118.02 65.7683 117.219 64.2109 117.03 62.5246C116.922 61.5594 117.018 60.5821 117.313 59.6568C117.607 58.7315 118.092 57.8789 118.737 57.1548C119.382 56.4306 120.172 55.8513 121.055 55.4547C121.938 55.058 122.895 54.853 123.863 54.853C124.831 54.853 125.787 55.058 126.671 55.4547C127.554 55.8513 128.344 56.4306 128.989 57.1548C129.634 57.8789 130.119 58.7315 130.413 59.6568C130.707 60.5821 130.803 61.5594 130.695 62.5246ZM120.709 62.5246C120.709 64.9227 122.333 66.1908 123.88 66.1908C125.427 66.1908 127.017 64.9227 127.017 62.5246C127.017 60.1264 125.393 58.7548 123.88 58.7548C122.367 58.7548 120.709 60.0401 120.709 62.5246Z"
          fill="#143A5A"
        />
        <path
          d="M136.711 55.4509V55.7529H139.702V58.9101H136.685V69.1843H133.007V58.9101H131.288V55.7529H133.007V55.3733C133.007 51.4224 136.015 50.9307 137.614 50.9307C138.811 50.9473 139.987 51.2426 141.051 51.7933L139.865 54.3812C139.227 54.1287 138.548 53.997 137.863 53.9931C136.634 53.924 136.711 54.9937 136.711 55.4509Z"
          fill="#143A5A"
        />
        <path
          d="M153.59 58.7548V64.8709C153.59 65.4662 153.822 66.1649 154.699 66.1649C155.288 66.186 155.874 66.0801 156.418 65.8543V68.8736C155.605 69.2236 154.723 69.3829 153.84 69.3394C151.7 69.3394 149.929 68.6148 149.929 64.8968V58.7548H148.614V55.7528H149.929V53.4323H153.608V55.7528H156.461V58.7548H153.59Z"
          fill="#143A5A"
        />
        <path
          d="M171.011 61.4636V69.1842H167.324V61.6965C167.324 59.4795 166.465 58.7807 165.167 58.7807C162.898 58.7807 161.729 61.2048 161.643 61.6965V69.1842H157.965V51.1118H161.643V58.289C161.746 57.5127 163.259 55.6235 165.992 55.6235C168.484 55.6235 171.011 57.228 171.011 61.4636Z"
          fill="#143A5A"
        />
        <path
          d="M186.009 62.5246C186.013 62.9753 185.978 63.4256 185.905 63.8703H176.331C176.534 64.5357 176.942 65.1192 177.496 65.5364C178.051 65.9535 178.724 66.1827 179.417 66.1909C179.846 66.2177 180.276 66.1548 180.68 66.0064C181.084 65.8579 181.452 65.627 181.763 65.3282L184.797 66.881C184.12 67.6503 183.288 68.2666 182.356 68.6889C181.424 69.1113 180.413 69.3301 179.391 69.3309C177.567 69.3412 175.814 68.624 174.518 67.337C173.221 66.0501 172.487 64.2989 172.477 62.4685C172.466 60.6382 173.181 58.8788 174.463 57.5773C175.745 56.2758 177.49 55.5389 179.314 55.5286C180.214 55.535 181.105 55.7224 181.932 56.0797C182.759 56.437 183.507 56.9571 184.131 57.6091C184.755 58.2612 185.243 59.0321 185.565 59.8763C185.888 60.7205 186.038 61.621 186.009 62.5246ZM176.383 61.0754H182.279C182.104 60.4214 181.718 59.844 181.181 59.4335C180.645 59.023 179.988 58.8026 179.314 58.8066C178.641 58.7951 177.985 59.0134 177.452 59.4257C176.92 59.8379 176.543 60.4197 176.383 61.0754Z"
          fill="#143A5A"
        />
        <path
          d="M14.5677 78.1816L7.15933 96.5215H3.54968L5.62953 91.2248L-0.0341797 78.1558H3.59266L7.37419 86.9116L10.9065 78.1558L14.5677 78.1816Z"
          fill="#143A5A"
        />
        <path
          d="M28.7138 84.9534C28.718 85.4041 28.6835 85.8544 28.6107 86.2991H19.0451C19.2433 86.9684 19.6497 87.5565 20.2049 87.9771C20.7601 88.3978 21.4349 88.629 22.1305 88.6369C22.5584 88.663 22.987 88.5999 23.3894 88.4514C23.7917 88.3029 24.1591 88.0724 24.4682 87.7743L27.502 89.327C26.8269 90.0961 25.9965 90.7123 25.0659 91.1347C24.1354 91.5571 23.126 91.776 22.1047 91.7769C20.2812 91.7872 18.5283 91.07 17.2317 89.7831C15.935 88.4962 15.2008 86.7449 15.1905 84.9146C15.1803 83.0843 15.8948 81.3249 17.177 80.0234C18.4591 78.7219 20.2039 77.9849 22.0274 77.9746C22.9259 77.9822 23.8139 78.1699 24.6391 78.5267C25.4644 78.8835 26.2104 79.4021 26.8332 80.0522C27.4561 80.7023 27.9432 81.4707 28.2662 82.3123C28.5891 83.154 28.7413 84.0519 28.7138 84.9534ZM19.0881 83.5042H24.9839C24.8107 82.8509 24.4266 82.2737 23.8915 81.8632C23.3565 81.4526 22.7009 81.2318 22.0274 81.2354C21.3535 81.2219 20.6951 81.4393 20.1608 81.8517C19.6265 82.2642 19.2482 82.847 19.0881 83.5042Z"
          fill="#143A5A"
        />
        <path
          d="M39.723 79.7603V78.1817H43.4444V91.6131H39.766V89.836C39.766 90.3536 38.4596 91.7942 36.3282 91.7942C33.5265 91.7942 30.0801 89.6031 30.0801 84.8931C30.0303 84.0288 30.1536 83.1632 30.4427 82.3476C30.7318 81.5319 31.1809 80.7827 31.7634 80.1442C32.3458 79.5057 33.0499 78.9908 33.8339 78.6301C34.618 78.2693 35.4661 78.0699 36.3282 78.0436C38.4167 78.0264 39.6972 79.191 39.723 79.7603ZM33.7069 84.8758C33.7069 87.274 35.2711 88.6197 36.8955 88.6197C38.5198 88.6197 39.8777 87.5845 39.8777 84.8758C39.8777 82.1671 38.4425 81.1837 36.8955 81.1837C35.3485 81.1837 33.7069 82.5467 33.7069 84.8758Z"
          fill="#143A5A"
        />
        <path
          d="M54.6179 78.1557V81.6063C54.1915 81.5402 53.7599 81.5142 53.3287 81.5286C51.034 81.5286 49.2636 83.2539 49.0315 85.8419V91.6475H45.3359V78.1816H48.9886V82.4948C49.2722 80.019 50.5012 78.0522 53.2858 78.0522C53.7323 78.0364 54.1791 78.0711 54.6179 78.1557Z"
          fill="#143A5A"
        />
        <path
          d="M73.4049 80.0104L73.637 78.1988H76.4646L74.8144 91.6302H71.9869L72.2447 89.465C72.0126 90.5433 70.0789 91.8114 67.9475 91.8114C65.1199 91.8114 61.9314 89.5944 62.4901 84.9102C62.6517 83.0875 63.4645 81.3849 64.7787 80.1166C66.0929 78.8482 67.8197 78.0997 69.6406 78.009C71.8064 78.0263 72.9666 79.0097 73.4049 80.0104ZM65.3004 84.9188C64.9395 87.8346 66.7873 89.3615 68.6437 89.3615C70.5 89.3615 72.5541 88.0675 72.9409 84.9188C73.3276 81.7702 71.5486 80.5021 69.718 80.5021C68.5758 80.5744 67.4985 81.0602 66.6862 81.8692C65.8738 82.6783 65.3814 83.7558 65.3004 84.9016V84.9188Z"
          fill="#EB9F22"
        />
        <path
          d="M90.4563 91.613H89.9922L86.9068 82.9866L81.7501 91.613H81.2602L77.5303 78.1816H80.3406L82.4205 86.2215L87.2591 78.1816H87.7748L90.6539 86.2215L94.6933 78.1816H97.4951L90.4563 91.613Z"
          fill="#EB9F22"
        />
        <path
          d="M107.748 80.0104L107.98 78.1988H110.807L109.157 91.6302H106.33L106.587 89.465C106.355 90.5433 104.43 91.8114 102.29 91.8114C99.4627 91.8114 96.2742 89.5944 96.8328 84.9102C96.9947 83.0861 97.8088 81.3824 99.1247 80.1138C100.441 78.8453 102.17 78.0977 103.992 78.009C106.184 78.0263 107.309 79.0097 107.748 80.0104ZM99.6432 84.9188C99.2823 87.8346 101.13 89.3615 102.986 89.3615C104.843 89.3615 106.897 88.0675 107.284 84.9188C107.67 81.7702 105.891 80.5021 104.069 80.5021C102.926 80.5742 101.848 81.0596 101.034 81.8686C100.22 82.6775 99.7262 83.755 99.6432 84.9016V84.9188Z"
          fill="#EB9F22"
        />
        <path
          d="M121.327 78.1299L120.992 80.7696C120.618 80.7255 120.242 80.7082 119.866 80.7178C118.625 80.7463 117.428 81.1842 116.461 81.9638C115.493 82.7435 114.808 83.8215 114.512 85.0311L113.712 91.5872H110.885L112.526 78.1558H115.362L114.873 82.1843C115.176 80.9934 115.867 79.9381 116.835 79.1851C117.803 78.4321 118.994 78.0244 120.218 78.0264C120.59 78.0313 120.961 78.0659 121.327 78.1299Z"
          fill="#EB9F22"
        />
        <path
          d="M133.626 91.613H130.799L131.056 89.5254C130.824 90.6037 128.968 91.7941 126.862 91.7941C126.095 91.799 125.335 91.6458 124.628 91.344C123.922 91.0422 123.285 90.5982 122.757 90.0395C122.228 89.4808 121.82 88.8193 121.556 88.0958C121.293 87.3723 121.18 86.6023 121.224 85.8332C121.224 82.4689 123.674 78.0263 128.298 78.0263C130.515 78.0263 131.735 79.0615 132.182 79.9672L133.042 73.5405H135.878L133.626 91.613ZM124.026 85.7297C123.986 86.1848 124.04 86.6434 124.184 87.0767C124.328 87.51 124.56 87.9088 124.865 88.248C125.17 88.5873 125.541 88.8597 125.956 89.0484C126.371 89.237 126.82 89.3377 127.275 89.3442C130.515 89.3442 131.83 86.2646 131.83 83.944C131.83 81.4424 130.283 80.4935 128.607 80.4935C125.573 80.4848 124.026 83.5817 124.026 85.7297Z"
          fill="#EB9F22"
        />
        <path
          d="M141.421 80.4072C141.026 80.3966 140.639 80.5229 140.326 80.7649C140.013 81.0069 139.792 81.3497 139.702 81.7357C139.624 82.3741 139.934 82.8399 140.965 83.2281L142.461 83.7974C144.411 84.522 146.165 85.1949 145.855 87.7225C145.546 90.25 142.589 91.7769 140.192 91.7769C137.794 91.7769 135.568 90.5088 135.284 88.24L137.863 87.4895C138.007 88.0558 138.343 88.554 138.814 88.8987C139.284 89.2433 139.859 89.4131 140.441 89.3787C141.55 89.3787 142.761 88.7318 142.864 87.7225C142.968 86.9202 142.452 86.4802 141.472 86.1266L139.951 85.5313C137.588 84.6687 136.513 83.6939 136.737 81.7875C136.994 79.5101 139.315 78.0091 141.73 78.0091C142.72 77.9412 143.704 78.2146 144.518 78.784C145.332 79.3534 145.928 80.1848 146.208 81.1405L143.483 81.8133C143.347 81.3802 143.07 81.0059 142.695 80.7507C142.321 80.4956 141.872 80.3745 141.421 80.4072Z"
          fill="#EB9F22"
        />
        <path
          d="M151.296 91.613H147.239V87.6104H151.296V91.613Z"
          fill="#EB9F22"
        />
        <path
          d="M25.6799 31.383V32.6425C25.5227 32.6288 25.3645 32.6288 25.2072 32.6425C24.7915 32.6447 24.3931 32.8102 24.0975 33.1037C23.8019 33.3972 23.6326 33.7952 23.6259 34.2125V36.3518H22.2852V31.4175H23.6259V33.0048C23.729 32.0904 24.1845 31.3657 25.2158 31.3657C25.3706 31.3515 25.5266 31.3573 25.6799 31.383Z"
          fill="#0D1F30"
        />
        <path
          d="M29.3412 31.9697V31.3917H30.6991V36.326H29.3412V35.6704C29.2269 35.9041 29.0454 36.098 28.8202 36.2272C28.595 36.3563 28.3365 36.4148 28.0778 36.395C27.4528 36.3315 26.8736 36.0374 26.4523 35.5696C26.0311 35.1018 25.7979 34.4937 25.7979 33.8632C25.7979 33.2326 26.0311 32.6245 26.4523 32.1567C26.8736 31.689 27.4528 31.3948 28.0778 31.3313C28.8341 31.3313 29.3326 31.7626 29.3412 31.9697ZM27.1153 33.8502C27.0975 34.0182 27.1141 34.1881 27.164 34.3494C27.2139 34.5108 27.2961 34.6602 27.4055 34.7886C27.5149 34.917 27.6492 35.0216 27.8003 35.0961C27.9514 35.1706 28.116 35.2134 28.2841 35.2218C28.8513 35.2218 29.3842 34.8423 29.3842 33.8502C29.3842 32.8582 28.8513 32.4872 28.2841 32.4872C28.1172 32.497 27.954 32.5403 27.8041 32.6146C27.6543 32.689 27.5208 32.7928 27.4118 32.92C27.3027 33.0471 27.2203 33.195 27.1693 33.3549C27.1184 33.5147 27.1 33.6831 27.1153 33.8502Z"
          fill="#0D1F30"
        />
        <path
          d="M33.063 32.4873V34.7388C33.063 34.9631 33.149 35.2132 33.467 35.2132C33.6846 35.2225 33.9016 35.1842 34.103 35.1011V36.2139C33.8023 36.3439 33.4759 36.4029 33.149 36.3864C32.3669 36.3864 31.7137 36.119 31.7137 34.7474V32.4873H31.2324V31.3917H31.7137V30.2185L33.063 30.5377V31.4003H34.1115V32.4959L33.063 32.4873Z"
          fill="#0D1F30"
        />
        <path
          d="M39.242 33.8761C39.2551 34.0397 39.2551 34.2041 39.242 34.3678H35.7097C35.7816 34.6147 35.9307 34.8318 36.1349 34.9872C36.3392 35.1425 36.5879 35.2278 36.8441 35.2304C37.0004 35.2419 37.1573 35.221 37.3051 35.1691C37.4529 35.1171 37.5886 35.0352 37.7036 34.9285L38.8208 35.4978C38.576 35.7852 38.2713 36.0153 37.9283 36.1718C37.5853 36.3283 37.2123 36.4074 36.8355 36.4036C36.1692 36.4036 35.53 36.1385 35.058 35.6664C34.586 35.1942 34.3197 34.5535 34.3174 33.8847C34.3174 33.2258 34.5782 32.5939 35.0423 32.1279C35.5065 31.662 36.1361 31.4003 36.7926 31.4003C37.449 31.4003 38.0786 31.662 38.5428 32.1279C39.007 32.5939 39.2678 33.2258 39.2678 33.8847L39.242 33.8761ZM35.7097 33.3412H37.8755C37.819 33.0956 37.6811 32.8764 37.4844 32.7197C37.2876 32.563 37.0437 32.478 36.7926 32.4786C36.541 32.4764 36.2963 32.5608 36.0992 32.7178C35.9022 32.8748 35.7647 33.0948 35.7097 33.3412Z"
          fill="#0D1F30"
        />
        <path
          d="M50.7583 33.6776V36.326H49.3917V33.738C49.3917 32.9789 48.9878 32.5131 48.4206 32.5131C48.1277 32.5258 47.8485 32.6403 47.6305 32.8371C47.4126 33.0338 47.2695 33.3004 47.226 33.5914V36.3605H45.8594V33.7725C45.8594 33.0134 45.4555 32.5476 44.8625 32.5476C44.2695 32.5476 43.8398 32.9789 43.7023 33.6259V36.395H42.3271V31.4606H43.7023V32.4095C43.7934 32.1026 43.9862 31.8362 44.2488 31.6542C44.5113 31.4723 44.8279 31.3857 45.1461 31.4089C45.5264 31.3874 45.9045 31.4801 46.2321 31.6752C46.5597 31.8703 46.8219 32.159 46.9853 32.5044C47.1318 32.1608 47.3805 31.8711 47.6975 31.6749C48.0145 31.4788 48.3841 31.3859 48.7558 31.4089C49.7957 31.3399 50.7583 31.9696 50.7583 33.6776Z"
          fill="#0D1F30"
        />
        <path
          d="M56.491 31.3917L53.758 38.129H52.4345L53.208 36.1967L51.1797 31.3917H52.5118L53.9041 34.6094L55.2019 31.3917H56.491Z"
          fill="#0D1F30"
        />
        <path
          d="M11.3786 40.1734L8.93783 39.0951L6.49702 40.1734L2.01074 44.1329H8.92923H15.8563L11.3786 40.1734Z"
          fill="#EB9F22"
        />
        <path
          d="M8.92969 32.5045L8.93828 32.4873L8.92969 29.5198V32.4873V32.5045Z"
          fill="#EB9F22"
        />
        <path
          d="M8.93828 26.604L8.92969 26.5953V26.604V29.5197L8.93828 26.604Z"
          fill="#222B3F"
        />
        <path
          d="M6.49773 40.1734L6.73838 37.4647L4.98512 35.4203H4.97652L0.318359 32.9703V36.6366V44.133H2.01146L6.49773 40.1734Z"
          fill="#0D1F30"
        />
        <path
          d="M4.98512 35.4202L7.58063 34.825L8.92995 32.5045V32.4872V29.5197V26.604L1.26374 30.9259L0.318359 31.4607V32.9703L4.97652 35.4202H4.98512Z"
          fill="#143A5A"
        />
        <path
          d="M12.8919 35.4203L11.1387 37.4648L11.3793 40.1735L15.857 44.133H17.636V36.6366V32.9272L12.8919 35.4203Z"
          fill="#4F6473"
        />
        <path
          d="M16.6475 30.9L9.05001 26.6126L8.93828 26.604L8.92969 29.5197L8.93828 32.4872L10.2962 34.825L12.8917 35.4202L17.6358 32.9272V31.4607L16.6475 30.9Z"
          fill="#455A6B"
        />
        <path
          d="M23.7724 38.6034H22.3887V37.4043H23.7724V38.6034ZM23.7724 44.0294H22.3887V39.1037H23.7724V44.0294Z"
          fill="#0D1F30"
        />
        <path
          d="M29.3674 41.1913V44.0208H28.0266V41.2776C28.0266 40.4581 27.6915 40.2079 27.2274 40.2079C26.9315 40.2392 26.6514 40.3574 26.4221 40.5476C26.1928 40.7379 26.0245 40.9918 25.9382 41.2776V44.0208H24.5889V39.0951H25.9382V40.0268C26.0684 39.7178 26.2919 39.4577 26.5771 39.2828C26.8623 39.108 27.1949 39.0272 27.5282 39.052C28.4478 39.0865 29.3674 39.6731 29.3674 41.1913Z"
          fill="#0D1F30"
        />
        <path
          d="M31.8681 40.2079C31.6619 40.2079 31.4814 40.3201 31.4814 40.5185C31.4814 40.7169 31.5845 40.8118 31.8337 40.898L32.4869 41.1309C33.1573 41.3725 33.8878 41.6227 33.8878 42.5975C33.8878 43.5722 32.7963 44.1243 31.8853 44.1243C31.4478 44.1572 31.0116 44.0461 30.6425 43.808C30.2735 43.5699 29.9917 43.2176 29.8398 42.8045L30.9657 42.4594C31.0595 42.6192 31.1944 42.7507 31.3561 42.8402C31.5179 42.9297 31.7007 42.974 31.8853 42.9684C32.1861 42.9684 32.4783 42.8217 32.4783 42.5802C32.4783 42.3387 32.3666 42.3042 32.126 42.2179L31.5244 41.985C30.6048 41.6399 30.0805 41.2949 30.0805 40.5271C30.0975 40.3143 30.1571 40.1072 30.2557 39.9181C30.3543 39.729 30.4899 39.5618 30.6544 39.4265C30.8189 39.2912 31.0089 39.1906 31.213 39.1307C31.4171 39.0708 31.6312 39.0528 31.8423 39.0779C32.2481 39.0618 32.6489 39.1727 32.9892 39.3953C33.3294 39.6178 33.5924 39.941 33.7417 40.3201L32.5299 40.6306C32.4702 40.5049 32.3765 40.3986 32.2595 40.3239C32.1425 40.2491 32.0068 40.209 31.8681 40.2079Z"
          fill="#0D1F30"
        />
        <path
          d="M39.2678 41.5882C39.2899 41.9082 39.2472 42.2294 39.1422 42.5324C39.0371 42.8353 38.872 43.1138 38.6567 43.3508C38.4414 43.5879 38.1804 43.7787 37.8895 43.9117C37.5987 44.0446 37.284 44.117 36.9645 44.1244C36.7069 44.1447 36.4492 44.0864 36.2253 43.9571C36.0013 43.8278 35.8216 43.6335 35.7097 43.3997V45.8496H34.3604V39.1296H35.7097V39.7076C35.7097 39.5006 36.191 39.0779 36.9645 39.0779C37.2817 39.0854 37.5941 39.157 37.8831 39.2884C38.1722 39.4197 38.4319 39.6082 38.6468 39.8424C38.8618 40.0767 39.0275 40.352 39.1342 40.6519C39.2409 40.9518 39.2863 41.2703 39.2678 41.5882ZM37.9184 41.5882C37.9337 41.4215 37.9153 41.2535 37.8642 41.0942C37.8132 40.9349 37.7306 40.7876 37.6215 40.6611C37.5123 40.5346 37.3788 40.4315 37.229 40.3581C37.0792 40.2847 36.9161 40.2424 36.7496 40.2338C36.1824 40.2338 35.6409 40.5789 35.6409 41.5882C35.6409 42.5975 36.1824 42.9598 36.7496 42.9598C36.9177 42.9513 37.0823 42.9086 37.2334 42.8341C37.3845 42.7596 37.5188 42.655 37.6282 42.5266C37.7376 42.3982 37.8198 42.2488 37.8697 42.0874C37.9196 41.9261 37.9362 41.7562 37.9184 41.5882Z"
          fill="#0D1F30"
        />
        <path
          d="M44.6217 41.614C44.6345 41.7776 44.6345 41.942 44.6217 42.1057H41.1066C41.1785 42.3526 41.3276 42.5698 41.5318 42.7251C41.7361 42.8804 41.9848 42.9657 42.241 42.9683C42.3973 42.9798 42.5542 42.9589 42.702 42.907C42.8498 42.855 42.9855 42.7732 43.1005 42.6664L44.2091 43.2271C43.9652 43.5134 43.6619 43.7428 43.3205 43.8992C42.9791 44.0557 42.6077 44.1354 42.2324 44.1329C41.5911 44.0953 40.9884 43.813 40.5476 43.344C40.1068 42.8749 39.8613 42.2545 39.8613 41.6097C39.8613 40.9649 40.1068 40.3445 40.5476 39.8754C40.9884 39.4063 41.5911 39.1241 42.2324 39.0864C42.8814 39.109 43.4955 39.3865 43.9425 39.8594C44.3894 40.3322 44.6333 40.9623 44.6217 41.614ZM41.098 41.0791H43.2552C43.1959 40.835 43.0573 40.6176 42.8612 40.4613C42.6651 40.3051 42.4226 40.2189 42.1723 40.2165C41.9214 40.2131 41.6771 40.2972 41.4811 40.4546C41.2852 40.6119 41.1497 40.8327 41.098 41.0791Z"
          fill="#0D1F30"
        />
        <path
          d="M49.5896 40.1907L48.395 40.6306C48.2941 40.4999 48.1633 40.3956 48.0137 40.3265C47.8641 40.2574 47.7 40.2256 47.5355 40.2338C47.3689 40.2456 47.2063 40.291 47.0576 40.3675C46.9089 40.444 46.7772 40.55 46.6703 40.6789C46.5635 40.8079 46.4839 40.9572 46.4361 41.118C46.3883 41.2787 46.3735 41.4474 46.3925 41.6141C46.376 41.7784 46.3928 41.9445 46.4418 42.1021C46.4909 42.2598 46.5713 42.4059 46.678 42.5316C46.7848 42.6574 46.9158 42.7601 47.0632 42.8337C47.2106 42.9073 47.3712 42.9502 47.5355 42.9598C47.6992 42.9683 47.8626 42.9375 48.012 42.87C48.1615 42.8025 48.2928 42.7002 48.395 42.5716L49.5982 43.0029C49.3552 43.3604 49.0242 43.6488 48.6374 43.8401C48.2506 44.0313 47.8211 44.119 47.3906 44.0944C46.9601 44.0699 46.5432 43.9341 46.1804 43.7002C45.8177 43.4663 45.5213 43.1422 45.3201 42.7594C45.119 42.3765 45.0198 41.948 45.0323 41.5154C45.0448 41.0828 45.1685 40.6608 45.3915 40.2903C45.6144 39.9198 45.929 39.6135 46.3047 39.4011C46.6803 39.1886 47.1043 39.0773 47.5355 39.0779C47.943 39.078 48.344 39.1797 48.7026 39.3739C49.0611 39.5682 49.3659 39.8489 49.5896 40.1907Z"
          fill="#0D1F30"
        />
        <path
          d="M51.7466 40.2338V42.4766C51.7466 42.6923 51.8326 42.9511 52.1506 42.9511C52.3659 42.9569 52.58 42.9157 52.778 42.8303V43.9431C52.4801 44.0724 52.1567 44.1314 51.8326 44.1157C51.0419 44.1157 50.3973 43.8482 50.3973 42.4853V40.2338H49.916V39.1296H50.3973V38.0254L51.7466 38.2842V39.1468H52.7865V40.251L51.7466 40.2338Z"
          fill="#0D1F30"
        />
        <path
          d="M58.0547 41.6141C58.0547 42.2798 57.7912 42.9183 57.3222 43.3891C56.8531 43.8599 56.217 44.1244 55.5537 44.1244C54.8904 44.1244 54.2543 43.8599 53.7853 43.3891C53.3162 42.9183 53.0527 42.2798 53.0527 41.6141C53.0527 40.9483 53.3162 40.3098 53.7853 39.839C54.2543 39.3682 54.8904 39.1038 55.5537 39.1038C56.217 39.1038 56.8531 39.3682 57.3222 39.839C57.7912 40.3098 58.0547 40.9483 58.0547 41.6141ZM54.4021 41.6141C54.3772 41.781 54.3884 41.9513 54.4351 42.1134C54.4817 42.2755 54.5627 42.4256 54.6724 42.5535C54.7821 42.6814 54.9181 42.784 55.0709 42.8543C55.2237 42.9246 55.3899 42.961 55.558 42.961C55.7261 42.961 55.8923 42.9246 56.0451 42.8543C56.1979 42.784 56.3339 42.6814 56.4436 42.5535C56.5533 42.4256 56.6343 42.2755 56.6809 42.1134C56.7276 41.9513 56.7389 41.781 56.714 41.6141C56.747 41.443 56.7419 41.2667 56.6991 41.0978C56.6563 40.929 56.5768 40.7717 56.4663 40.6373C56.3558 40.5029 56.2171 40.3946 56.0601 40.3203C55.9031 40.246 55.7316 40.2075 55.558 40.2075C55.3844 40.2075 55.213 40.246 55.0559 40.3203C54.8989 40.3946 54.7602 40.5029 54.6497 40.6373C54.5393 40.7717 54.4598 40.929 54.4169 41.0978C54.3741 41.2667 54.369 41.443 54.4021 41.6141Z"
          fill="#0D1F30"
        />
        <path
          d="M61.9741 39.121V40.3805C61.8168 40.3668 61.6587 40.3668 61.5014 40.3805C61.0871 40.3849 60.6909 40.5515 60.3972 40.8447C60.1034 41.138 59.9353 41.5347 59.9286 41.9505V44.0812H58.5879V39.1555H59.9286V40.7428C60.0317 39.8284 60.4873 39.1124 61.5186 39.1124C61.6703 39.1004 61.8229 39.1032 61.9741 39.121Z"
          fill="#0D1F30"
        />
        <path
          d="M64.1914 40.2079C63.9851 40.2079 63.8046 40.3201 63.8046 40.5185C63.8046 40.7169 63.9078 40.8118 64.157 40.898L64.8102 41.1309C65.4719 41.3725 66.2111 41.6227 66.2111 42.5975C66.2111 43.5722 65.1196 44.1243 64.2086 44.1243C63.7711 44.1572 63.3348 44.0461 62.9658 43.808C62.5967 43.5699 62.3149 43.2176 62.1631 42.8045L63.289 42.4594C63.3828 42.6192 63.5176 42.7507 63.6794 42.8402C63.8411 42.9297 64.0239 42.974 64.2086 42.9684C64.5008 42.9684 64.793 42.8217 64.793 42.5802C64.793 42.3387 64.6898 42.3042 64.4492 42.2179L63.8476 41.985C62.928 41.6399 62.4037 41.2949 62.4037 40.5271C62.4208 40.3143 62.4804 40.1072 62.579 39.9181C62.6776 39.729 62.8132 39.5618 62.9777 39.4265C63.1421 39.2912 63.3321 39.1906 63.5362 39.1307C63.7403 39.0708 63.9544 39.0528 64.1656 39.0779C64.5707 39.0598 64.9712 39.17 65.3106 39.3929C65.6499 39.6158 65.9108 39.9402 66.0563 40.3201L64.8531 40.6306C64.7935 40.5049 64.6998 40.3986 64.5827 40.3239C64.4657 40.2491 64.3301 40.209 64.1914 40.2079Z"
          fill="#0D1F30"
        />
        <path
          d="M67.9993 44.0554H66.5039V42.5889H67.9993V44.0554Z"
          fill="#E7A523"
        />
        <path
          d="M1.40046 105.596V110.005H6.13596V110.729H1.40046V115.905H0.541016V104.872H7.02979V105.596H1.40046Z"
          fill="#3B7BBF"
        />
        <path
          d="M18.6932 110.384C18.6645 111.461 18.3201 112.505 17.7033 113.387C17.0864 114.268 16.2244 114.947 15.225 115.339C14.2256 115.732 13.1332 115.819 12.0843 115.592C11.0355 115.364 10.0768 114.831 9.3281 114.059C8.57941 113.288 8.07395 112.312 7.87495 111.253C7.67594 110.195 7.79221 109.101 8.20922 108.109C8.62623 107.116 9.32547 106.269 10.2195 105.674C11.1135 105.078 12.1627 104.76 13.2358 104.76C14.7022 104.78 16.1006 105.384 17.1239 106.439C18.1471 107.493 18.7116 108.912 18.6932 110.384ZM8.61198 110.384C8.59998 111.639 9.07659 112.849 9.94027 113.756C10.804 114.663 11.9864 115.196 13.2358 115.241C14.4849 115.188 15.6654 114.653 16.5306 113.747C17.3959 112.841 17.8789 111.635 17.8789 110.38C17.8789 109.125 17.3959 107.919 16.5306 107.013C15.6654 106.107 14.4849 105.572 13.2358 105.519C11.9849 105.564 10.8012 106.098 9.93724 107.007C9.07332 107.916 8.59772 109.128 8.61198 110.384Z"
          fill="#3B7BBF"
        />
        <path
          d="M26.789 115.888L23.4801 111.575H20.9018V115.888H20.1025V104.872H23.6778C25.8522 104.872 27.3734 106.243 27.3734 108.228C27.3825 109.058 27.077 109.862 26.5186 110.475C25.9602 111.088 25.1906 111.466 24.3654 111.531L27.7258 115.888H26.789ZM20.9104 110.867H23.4887C25.3709 110.867 26.5312 109.832 26.5312 108.228C26.5312 106.623 25.3709 105.579 23.4887 105.579H20.9104V110.867Z"
          fill="#3B7BBF"
        />
        <path
          d="M41.5451 106.131L41.0467 106.701C40.2478 105.926 39.1752 105.501 38.0644 105.519C36.8153 105.572 35.6348 106.107 34.7695 107.013C33.9043 107.919 33.4212 109.125 33.4212 110.38C33.4212 111.635 33.9043 112.841 34.7695 113.747C35.6348 114.653 36.8153 115.188 38.0644 115.241C39.1733 115.258 40.2443 114.836 41.0467 114.068L41.5451 114.628C40.6104 115.501 39.3838 115.99 38.1074 116C36.7015 115.889 35.3889 115.25 34.4315 114.211C33.4741 113.172 32.9424 111.808 32.9424 110.393C32.9424 108.977 33.4741 107.614 34.4315 106.575C35.3889 105.535 36.7015 104.896 38.1074 104.786C39.3804 104.788 40.6065 105.268 41.5451 106.131Z"
          fill="#3B7BBF"
        />
        <path
          d="M52.8983 110.384C52.8696 111.461 52.5252 112.505 51.9084 113.387C51.2915 114.268 50.4295 114.947 49.4301 115.339C48.4307 115.732 47.3382 115.819 46.2894 115.592C45.2406 115.364 44.2819 114.831 43.5332 114.059C42.7845 113.288 42.279 112.312 42.08 111.253C41.881 110.195 41.9973 109.101 42.4143 108.109C42.8313 107.116 43.5306 106.269 44.4246 105.674C45.3186 105.078 46.3678 104.76 47.4408 104.76C48.9073 104.78 50.3057 105.384 51.329 106.439C52.3522 107.493 52.9166 108.912 52.8983 110.384ZM42.8085 110.384C42.7965 111.64 43.2743 112.852 44.1398 113.759C45.0053 114.666 46.19 115.198 47.4408 115.241C48.69 115.188 49.8705 114.653 50.7357 113.747C51.6009 112.841 52.084 111.635 52.084 110.38C52.084 109.125 51.6009 107.919 50.7357 107.013C49.8705 106.107 48.69 105.572 47.4408 105.519C46.1809 105.551 44.9841 106.079 44.1092 106.989C43.2342 107.9 42.7515 109.119 42.7655 110.384H42.8085Z"
          fill="#3B7BBF"
        />
        <path
          d="M62.3524 111.721C62.3524 114.395 60.6765 116.034 58.2443 116.034C55.812 116.034 54.1533 114.43 54.1533 111.721V104.872H54.9354V111.704C54.9354 113.999 56.1902 115.293 58.2443 115.293C60.2983 115.293 61.5703 113.999 61.5703 111.704V104.872H62.3524V111.721Z"
          fill="#3B7BBF"
        />
        <path
          d="M72.3825 115.888H71.5746L64.7592 106.071V115.888H63.96V104.872H64.7592L71.5746 114.706V104.872H72.3825V115.888Z"
          fill="#3B7BBF"
        />
        <path
          d="M81.0539 105.579H77.8224V115.931H76.9629V105.579H73.6885V104.872H81.0281L81.0539 105.579Z"
          fill="#3B7BBF"
        />
        <path
          d="M89.0634 115.888L85.7545 111.575H83.1762V115.888H82.377V104.872H85.935C88.118 104.872 89.6392 106.243 89.6392 108.228C89.6461 109.059 89.3387 109.862 88.7789 110.476C88.2191 111.089 87.4486 111.466 86.6226 111.531L89.983 115.888H89.0634ZM83.1848 110.867H85.7632C87.6453 110.867 88.8056 109.832 88.8056 108.228C88.8056 106.623 87.6453 105.579 85.7632 105.579H83.1848V110.867Z"
          fill="#3B7BBF"
        />
        <path
          d="M95.0107 110.488V115.888H94.2286V110.488L90.1807 104.872H91.0401L94.5982 109.841L98.1649 104.872H99.0243L95.0107 110.488Z"
          fill="#3B7BBF"
        />
        <path
          d="M93.5406 34C91.3753 34 90.1024 35.6679 90.1024 37.1602V37.4381H92.2385V37.1602C92.2385 36.136 93.0724 35.8434 93.5406 35.8434C94.038 35.8434 94.9304 36.0629 94.9304 37.2333C94.9304 38.0819 94.4038 38.6086 92.414 40.9348L90 43.7877V44.3437H97.0665V42.5295H93.7454L94.6817 41.52C96.6568 39.3694 97.0665 38.6232 97.0665 37.2333C97.0665 35.6679 95.6912 34 93.5406 34Z"
          fill="#0D1F30"
        />
        <path
          d="M102.821 34C100.525 34 98.3885 35.6679 98.3885 39.0329V39.4133C98.3885 42.7782 100.525 44.4461 102.821 44.4461C105.148 44.4461 107.255 42.7782 107.255 39.4133V39.0329C107.255 35.6679 105.148 34 102.821 34ZM102.821 35.7849C104.709 35.7849 105.162 37.6576 105.162 39.0329V39.4133C105.162 40.8031 104.709 42.6466 102.821 42.6466C100.934 42.6466 100.481 40.8031 100.481 39.4133V39.0329C100.481 37.6576 100.934 35.7849 102.821 35.7849Z"
          fill="#0D1F30"
        />
        <path
          d="M112.043 34C109.878 34 108.605 35.6679 108.605 37.1602V37.4381H110.741V37.1602C110.741 36.136 111.575 35.8434 112.043 35.8434C112.54 35.8434 113.433 36.0629 113.433 37.2333C113.433 38.0819 112.906 38.6086 110.916 40.9348L108.502 43.7877V44.3437H115.569V42.5295H112.248L113.184 41.52C115.159 39.3694 115.569 38.6232 115.569 37.2333C115.569 35.6679 114.194 34 112.043 34Z"
          fill="#0D1F30"
        />
        <path
          d="M124.63 34.1024H117.271V35.8873H121.397L119.495 39.2962C119.758 39.2377 120.007 39.2084 120.227 39.2084C121.631 39.2084 122.333 40.1594 122.333 40.9494C122.333 42.4417 121.104 42.6905 120.578 42.6905C120.007 42.6905 118.983 42.3686 118.983 41.2274H116.891C116.891 43.027 118.412 44.49 120.578 44.49C122.597 44.49 124.425 43.1733 124.425 40.9494C124.425 39.4425 123.533 38.2574 122.406 38.0234L124.63 34.1024Z"
          fill="#0D1F30"
        />
      </g>
      <defs>
        <clipPath id="clip0_270_396">
          <rect width="186" height="116" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
