import { api } from "./api";

/**
 * @typedef {Object} InspectionReport
 * @property {number} id
 * @property {number[]} [inspectionTypeIds]
 * @property {string} [inspectionTypeOther]
 * @property {string} address
 * @property {string} inspectionDate
 * @property {number} price
 * @property {string} [propertyImagePath]
 */

/**
 * @typedef {Object} InspectorSummary
 * @property {number} id
 * @property {number} fullName
 * @property {string} imagePath
 * @property {string} phone
 * @property {string} code
 */

/**
 * @typedef {Object} ReportSearchInspector
 * @property {boolean} hasMatchedReport
 * @property {number} id
 * @property {string} code
 * @property {boolean} isClaimable
 * @property {import("./inspectors").InspectorProfileSummary} profile
 * @property {import("./companies").Company} company
 * @property {number[]} inspectionTypeIds
 * @property {number} reportCount
 * @property {number} videoCount
 * @property {string} location
 * @property {import("./inspectors").InspectorSummaryReviewInfo} reviews
 */

/**
 * @typedef {Object} PagedReportSearchInspectors
 * @property {number} currentPage
 * @property {number} pageCount
 * @property {number} pageSize
 * @property {number} totalCount
 * @property {string} pagingMaxId
 * @property {ReportSearchInspector[]} data
 */

/**
 * @typedef {Object} ReportSearchSummary
 * @property {number} matchedReportCount
 * @property {PagedReportSearchInspectors} inspectors
 */

/**
 * @typedef {Object} SearchReport
 * @property {number} id
 * @property {[number]} [inspectionTypeIds]
 * @property {string} [inspectionTypeOther]
 * @property {string} address
 * @property {string} inspectionDate
 * @property {number} price
 * @property {InspectorSummary} inspector
 * @property {string} propertyImagePath
 * @property {string} [externalLink]
 */

/**
 * @typedef {Object} PurchaseIntent
 * @property {string} paymentIntentSecret
 * @property {string} transactionId
 * @property {string} stripeAccountId
 */

/**
 * @typedef {Object} InspectionReportSale
 * @property {string} transactionId
 * @property {string} amount
 * @property {string} receiptUrl
 * @property {string} purchasedOn
 * @property {SearchReport} report
 */

export const InspectionReportsApi = {
  /**
   *
   * @returns {Promise<InspectionReport[]>}
   */
  getInspectionReportsForInspectorWithId: (inspectorId) =>
    api.get(`/InspectionReports/inspectors/${inspectorId}`),
  /**
   *
   * @returns {Promise<InspectionReport[]>}
   */
  getInspectionReportsForCurrentInspector: () =>
    api.get(`/InspectionReports/inspectors/current`),
  /**
   *
   * @returns {Promise<InspectionReport>}
   */
  createInspectionReportForCurrentInspector: (data) =>
    api.post(`/InspectionReports/inspectors/current`, data),
  /**
   *
   * @returns {Promise<InspectionReport>}
   */
  updateInspectionReportWithId: (inspectionReportId, data) =>
    api.put(`/InspectionReports/${inspectionReportId}`, data),
  /**
   *
   * @returns {Promise<void>}
   */
  deleteInspectionReportWithId: (inspectionReportId) =>
    api.delete(`/InspectionReports/${inspectionReportId}`),

  /**
   * @param {Object} params
   * @param {string} params.address
   * @param {string|number} params.point.latitude
   * @param {string|number} params.point.longitude
   * @returns {Promise<ReportSearchSummary>}
   */
  searchReportsByAddress: async (params) =>
    // searchReportsByAddress: (params) =>
    api.get(`/InspectionReports`, {
      params,
    }),
  /**
   *
   * @param {Object} params
   * @param {string} params.address
   * @param {string|number} params.point.latitude
   * @param {string|number} params.point.longitude
   * @param {string|number} params.point.longitude
   * @param {number} pageNumber
   * @returns {Promise<ReportSearchSummary>}
   */
  getReportsByPage: (params, pageNumber) =>
    api.get("/InspectionReports", {
      params: { ...params, pageNumber, pageSize: 10 },
    }),
  /**
   *
   * @param {number} reportId
   * @param {number} amount
   * @returns {Promise<PurchaseIntent>}
   */
  createReportPurchaseIntent: async (reportId, amount) =>
    api.post(`/InspectionReports/${reportId}/purchase`, { reportId, amount }),

  /**
   *
   * @returns {Promise<InspectionReportSale[]>}
   */
  getReportsPurchasedByCurrentCustomer: async () =>
    api.get("/InspectionReports/purchased"),
};
