import styled from "styled-components";
import Slider from "react-slick";

const cauroselData = [
  {
    image:
      "https://images.unsplash.com/photo-1605146769289-440113cc3d00?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80",
    title: "One call Building and Pest inspections",
    excerpt:
      "Scott from One Call was amazing. He found structural defects missed by the inspection report provided by the seller. Will definitely be recommending him to all our friends",
    stars: 5.0,
    author: "Jerome & Katy",
    location: "Pullenvale, QLD",
  },
  {
    image:
      "https://images.unsplash.com/photo-1600047509807-ba8f99d2cdde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHByb3BlcnR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
    title: "Jim's Building and Pest inspections - Melbourne",
    excerpt:
      "Brad Kovac was professional and prompt with getting back to me! Within a few days I had the appointment booked, inspection completed and the report sent to my email. I contacted 2 other local companies who said this wasn’t possible to be done, 10/10. It’s a bonus that he is easy going and easy to talk to!",
    stars: 5.0,
    author: "Candice",
    location: "Cranbourne, VIC",
  },
  {
    image:
      "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80",
    title: "Asset Building and Pest inspections.",
    excerpt: `I have used Martin Slater to complete a building and pest inspection
      at my own property. He was thorough, prompt and provided a
      professional service with a detailed report. Maggie, the Office
      Manager liaised perfectly with the agent and myself to gain access to
      the property. They took the hassle out of it and I highly recommend
      giving Asset Building and Pest a call for your inspection`,
    stars: 5.0,
    author: "Tracy Crowther",
    location: "Ferny Grove, QLD",
  },
];

const Wrapper = styled.div`
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;

  @media only screen and (max-width: 810px) {
    max-width: 100%;
  }

  @media only screen and (max-width: 576px) {
  }
`;

const Item = styled.div`
  max-width: 740px;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  min-height: 280px;
  max-height: 400px;
  border-radius: 8px;
  object-fit: cover;
`;

const Content = styled.div`
  background: #ffffff;
  box-shadow: 0px 9px 32px rgba(89, 92, 219, 0.05);
  border-radius: 16px;
  padding: 32px;
  width: 90%;
  margin: 0 auto;
  margin-top: -50px;
  z-index: 1;
  position: relative;

  .metadata-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h4 {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #143a5a;
  }

  p.excerpt {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #798995;
  }

  p.author {
    font-family: "HeadingFont";
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 4px;
    color: #143a5a;
  }

  p.location {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.2px;
    color: #888b97;
  }

  p.rating {
    display: flex;
    align-items: center;
    font-family: "HeadingFont";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #143a5a;
    span {
      margin-right: 8px;
    }
  }
`;

const Label = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #eb9f22;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;

  span {
    display: block;
    width: 32px;
    height: 1px;
    background: #eb9f22;
  }
`;

const Heading = styled.h3`
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 32px;
`;

export default function Caurosel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Label>
        <span />
        Customer testimonials
      </Label>
      <Heading>What our users say</Heading>
      <Wrapper>
        <Slider {...settings} arrows={false}>
          {cauroselData.map((data, index) => (
            <Slide
              key={`caurosel-item-${index}`}
              image={data.image}
              title={data.title}
              excerpt={data.excerpt}
              author={data.author}
              location={data.location}
              stars={data.stars}
            />
          ))}
        </Slider>
      </Wrapper>
    </div>
  );
}

function Slide({ image, title, excerpt, author, location, stars }) {
  return (
    <Item>
      <Image src={image} />
      <Content>
        <h4>{title}</h4>
        <p className="excerpt">{excerpt}</p>
        <div className="metadata-wrapper">
          <div>
            <p className="author">{author}</p>
            <p className="location">{location}</p>
          </div>
          <p className="rating">
            <span>
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.6048 10.7088C25.5315 10.4929 25.3965 10.3031 25.2165 10.1629C25.0366 10.0228 24.8195 9.93838 24.5921 9.92018L17.941 9.39168L15.0628 3.02052C14.9711 2.81531 14.822 2.64103 14.6335 2.51868C14.445 2.39634 14.2251 2.33118 14.0003 2.33105C13.7756 2.33093 13.5556 2.39585 13.3669 2.51799C13.1783 2.64012 13.029 2.81425 12.9371 3.01935L10.0589 9.39168L3.40778 9.92018C3.18431 9.93789 2.97068 10.0196 2.79243 10.1555C2.61418 10.2915 2.47887 10.4759 2.40269 10.6867C2.32651 10.8975 2.31268 11.1259 2.36285 11.3443C2.41303 11.5628 2.52508 11.7622 2.68562 11.9187L7.60078 16.7102L5.86245 24.2375C5.80967 24.4654 5.82658 24.7038 5.91101 24.9219C5.99543 25.14 6.14346 25.3278 6.33589 25.4607C6.52831 25.5936 6.75626 25.6657 6.99013 25.6674C7.22401 25.6692 7.45302 25.6007 7.64745 25.4707L14 21.2357L20.3525 25.4707C20.5512 25.6026 20.7855 25.6706 21.024 25.6653C21.2625 25.6601 21.4937 25.582 21.6864 25.4414C21.8792 25.3009 22.0243 25.1047 22.1022 24.8793C22.1801 24.6538 22.1871 24.4099 22.1223 24.1803L19.9885 16.7137L25.2805 11.9514C25.627 11.6387 25.7541 11.151 25.6048 10.7088Z"
                  fill="#EB9F22"
                />
              </svg>
            </span>
            {stars}
          </p>
        </div>
      </Content>
    </Item>
  );
}
