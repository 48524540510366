import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Thead = styled.thead`
  height: 48px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  background-color: #f5fafd;
  border-radius: 6px;
  color: #b5b5c3;

  th {
    padding: 0 16px;
    text-align: left;
  }
`;

export const Trow = styled.tr`
  border-bottom: 1px solid #f5fafd;
`;

export const Tcell = styled.td`
  padding: 8px 16px;
  height: 48px;
  color: #798995;
`;
