import { getFormattedDate } from "helpers";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import { Table, Thead, Trow, Tcell, A } from "ui-library";
import { EmptyState } from "./empty-state";
import { useNavigate } from "react-router-dom";
import {
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_MASTER_ROUTE,
} from "routes";

const StyledTable = styled(Table)`
  margin-top: 48px;
  margin-bottom: 88px;
`;

export function RequestedQuotes({ quotes }) {
  const navigate = useNavigate();

  if (quotes.length === 0) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <EmptyState
              title="You have not requested any quotes yet."
              description="Get quotes from the best inspectors in your area."
              actionTitle="Find an inspector"
              onAction={() =>
                navigate("/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME)
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <StyledTable>
      <Thead>
        <tr>
          <Hidden xs>
            <th>Address</th>
          </Hidden>
          <th>Inspector</th>
          <Hidden xs>
            <th>Preferred date and time</th>
          </Hidden>
          <Visible xs>
            <th>Date and time</th>
          </Visible>

          <Hidden xs>
            <th>Inspector Profile</th>
          </Hidden>
        </tr>
      </Thead>

      <tbody>
        {quotes.map((quote, index) => {
          let formattedDate = "";
          quote.preferredTimes?.forEach((preferredTime) => {
            formattedDate += `${getFormattedDate(preferredTime, true)}<br />`;
          });

          return (
            <Trow key={`report-${index}`}>
              <Hidden xs>
                <Tcell>{quote.address}</Tcell>
              </Hidden>
              <Tcell>{quote.inspector.fullName}</Tcell>
              <Tcell>
                <span
                  dangerouslySetInnerHTML={{ __html: formattedDate }}
                ></span>
              </Tcell>
              <Tcell>
                <A
                  href={
                    "/" +
                    SEARCH_MASTER_ROUTE +
                    "/" +
                    SEARCH_INSPECTORS_MASTER_ROUTE +
                    "/" +
                    quote.inspector.code
                  }
                  target="_blank"
                >
                  View profile
                </A>
              </Tcell>
            </Trow>
          );
        })}
      </tbody>
    </StyledTable>
  );
}
