import styled from "styled-components";
import { P } from "ui-library";
import googleIcon from "assets/icons/google.svg";
import { useDispatch } from "react-redux";
import { setIsInspector, setRedirectUrl } from "store/features/user/userSlice";
import { ACCOUNT_MASTER_ROUTE, ACCOUNT_ROUTES } from "routes";

const GoogleButton = styled.button`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-radius: 5px;
  border: 1px solid #efefef;
  width: 100%;
  height: 56px;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
  font-family: BodyFont;

  &:hover {
    background-color: #e8e8e8;
  }

  &:active {
    background-color: #d5d5d5;
  }

  @media only screen and (max-width: 576px) {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
`;

const GoogleIcon = styled.div`
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;

  @media only screen and (max-width: 576px) {
    margin-left: 12px;
  }
`;

const GoogleCopy = styled(P)`
  color: #143a5a;
  margin-right: 24px;
  margin-left: 8px;
  flex-grow: 1;
  text-align: left;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
  }
`;

/**
 *
 * @param { Object } props
 * @param { string } props.redirectUrl
 * @param { boolean } [props.isInspector]
 * @param { boolean } [props.isRegistering]
 */
export function GoogleSignIn({
  redirectUrl,
  isInspector = false,
  isRegistering = false,
}) {
  const dispatch = useDispatch();

  const signInWithGoogle = async () => {
    dispatch(setRedirectUrl(redirectUrl));
    dispatch(setIsInspector(isInspector));

    window.location.href =
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_API_EXTENSION +
      `/UserSessions/byoauth/Google?redirectUrl=${
        process.env.REACT_APP_DOMAIN +
        "/" +
        ACCOUNT_MASTER_ROUTE +
        "/" +
        ACCOUNT_ROUTES.SOCIAL_SIGNUP
      }`;
  };

  return (
    <GoogleButton onClick={signInWithGoogle}>
      <GoogleIcon>
        <img src={googleIcon} alt="Google" width={32} />
      </GoogleIcon>

      <GoogleCopy>Google</GoogleCopy>
    </GoogleButton>
  );
}
