import * as UiLibrary from "ui-library";
import { Field } from "react-final-form";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { useCallback, useEffect, useState, useRef, forwardRef } from "react";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { FILE_TYPE_TO_ICON_MAPPING } from "./data";
import StarRatings from "react-star-ratings";
import closeRedIcon from "assets/icons/close-red.svg";
import "react-datepicker/dist/react-datepicker.css";
import { Hidden, Visible } from "react-grid-system";
import calendarIcon from "assets/icons/calendar.svg";
import { phoneValidator } from "./validators";

const Label = styled.label`
  color: #143a5a;
  margin-top: 24px;
  display: block;
  line-height: 28px;
`;

const Description = styled(Label)`
  color: #798995;
  margin-top: 0;
  margin-bottom: 4px;
  display: block;
`;

const InputContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
`;

export function Input({
  label,
  description,
  name,
  type,
  validate,
  placeholder,
  disabled,
  icon,
  onKeyDown,
}) {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <>
          {label && <Label>{label}</Label>}
          {description && <Description>{description}</Description>}
          <InputContainer className="flex align-center">
            {icon ? <IconContainer>{icon}</IconContainer> : <noscript />}
            <UiLibrary.Input
              {...input}
              type={type}
              className={meta.touched && meta.error ? "error" : ""}
              placeholder={placeholder}
              disabled={disabled}
              onKeyDown={onKeyDown}
              style={
                icon
                  ? {
                      paddingLeft: 48,
                    }
                  : undefined
              }
            />
          </InputContainer>
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

export function TextArea({
  label,
  description,
  name,
  validate,
  placeholder,
  disabled,
  rows = 5,
}) {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <>
          <Label>{label}</Label>
          {description && <Description>{description}</Description>}
          <UiLibrary.TextArea
            {...input}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled}
            className={meta.touched && meta.error ? "error" : ""}
          />
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

export function PhoneNumber({
  label,
  name,
  type,
  placeholder,
  disabled,
  description,
}) {
  return (
    <Field name={name} validate={phoneValidator}>
      {({ input, meta }) => (
        <>
          <Label>{label}</Label>
          {description && <Description>{description}</Description>}
          <UiLibrary.Input
            {...input}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            className={meta.touched && meta.error ? "error" : ""}
          />
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export function Checkbox({ label, name, disabled }) {
  return (
    <Field name={name} type="checkbox">
      {({ input, meta }) => (
        <>
          <CheckboxContainer>
            <UiLibrary.Checkbox {...input} disabled={disabled} />

            <Label
              style={{
                marginTop: 0,
                marginLeft: 16,
                marginBottom: 0,
              }}
            >
              {label}
            </Label>
          </CheckboxContainer>
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

export function Toggle({ name }) {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => <UiLibrary.Switch {...input} />}
    </Field>
  );
}

const StyledDatePicker = styled.div`
  height: 48px;
  padding: 8px 16px;
  background-color: #f7f9fc;
  border-radius: 4px;
  color: #143a5a;
  border: 3px solid #f7f9fc;
  width: 100%;
  font-size: 16px;
  line-height: 28px;

  &::placeholder {
    color: #143a5a80;
  }
`;

const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
  <>
    <StyledDatePicker onClick={onClick} ref={ref}>
      {value}
    </StyledDatePicker>

    <img
      src={calendarIcon}
      alt="calendar"
      style={{
        position: "absolute",
        right: 12,
        top: 12,
      }}
      onClick={onClick}
    />
  </>
));

export function DateSelector({ name, label, validators }) {
  return (
    <Field name={name} validate={validators}>
      {({ input, meta }) => (
        <>
          <Label>{label}</Label>
          <Hidden xs>
            <div style={{ position: "relative" }}>
              <DatePicker
                name={input.name}
                selected={input.value}
                onChange={input.onChange}
                dateFormat="MMMM d, yyyy"
                customInput={<DatePickerCustomInput />}
              />
            </div>
          </Hidden>
          <Visible xs>
            <UiLibrary.Input
              {...input}
              type="date"
              placeholder="Select a date"
            />
          </Visible>

          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

const StyledDateTimePicker = styled(DatePicker)`
  height: 48px;
  padding: 8px 16px;
  background-color: #f7f9fc;
  border-radius: 4px;
  color: #143a5a;
  border: 3px solid #f7f9fc;
  width: 100%;
  font-size: 16px;
  line-height: 28px;

  &::placeholder {
    color: #143a5a80;
  }

  &.error {
    border: "1px solid #ca0b0b";
  }
`;

export function DateTimeSelector({ name, label, validators }) {
  const getFormattedDate = (value) => {
    if (!value) {
      return null;
    }

    if (typeof value === "string") {
      if (!value.length) {
        return null;
      }

      return new Date(value);
    }

    return value;
  };

  return (
    <Field name={name} validate={validators}>
      {({ input, meta }) => (
        <>
          {label && <Label>{label}</Label>}
          <Hidden xs>
            <StyledDateTimePicker
              name={input.name}
              selected={getFormattedDate(input.value)}
              onChange={input.onChange}
              showTimeSelect
              dateFormat="MMMM d, yyyy h:mm aa"
              minDate={new Date()}
              placeholderText="Select a date and time"
              className={meta.touched && meta.error ? "error" : ""}
            />
          </Hidden>
          <Visible xs>
            <UiLibrary.Input
              {...input}
              type="datetime-local"
              placeholder="Select a date"
              className={meta.touched && meta.error ? "error" : ""}
            />
          </Visible>

          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

export function Select({
  name,
  label,
  items,
  validators,
  description,
  placeholder,
}) {
  return (
    <Field
      name={name}
      validate={validators}
      parse={(inputValue) => inputValue?.value}
      format={(storedValue) =>
        items.find((option) => option.value === storedValue)
      }
    >
      {({ input, meta }) => (
        <>
          {label && <Label>{label}</Label>}
          {description && <Description>{description}</Description>}
          <ReactSelect
            {...input}
            options={items}
            components={animatedComponents}
            placeholder={placeholder}
            classNamePrefix="react-select"
            captureMenuScroll
            styles={{
              control: (styles) => {
                if (styles["boxShadow"]) {
                  // if box shadow is defined, it means the select
                  // field is focused
                  return {
                    ...styles,
                    boxShadow: "none",
                    minHeight: 48,
                    borderRadius: 4,
                    backgroundColor: "#ffffff",
                    border:
                      meta.touched && meta.error
                        ? "1px solid #ca0b0b"
                        : "3px solid #edf1f7",
                    cursor: "text",
                    "&:hover": {
                      borderColor:
                        meta.touched && meta.error ? "#ca0b0b" : "#edf1f7",
                    },
                  };
                }

                return {
                  ...styles,
                  minHeight: 48,
                  borderRadius: 4,
                  backgroundColor: "#f7f9fc",
                  border:
                    meta.touched && meta.error
                      ? "1px solid #ca0b0b"
                      : "3px solid #f7f9fc",
                  cursor: "text",
                  "&:hover": {
                    backgroundColor: "#edf1f7",
                    borderColor:
                      meta.touched && meta.error ? "#ca0b0b" : "#edf1f7",
                  },
                };
              },
              menu: (styles) => ({
                ...styles,
                backgroundColor: "#ffffff",
                boxShadow: "0px 5px 12px rgba(24, 105, 171, 0.15)",
                borderRadius: 8,
                padding: 8,
              }),
              option: (styles) => ({
                ...styles,
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingLeft: 16,
                paddingRight: 16,
                cursor: "pointer",
                color: "#143a5a",
                backgroundColor: "#ffffff",
                ":hover": {
                  backgroundColor: "#efefef",
                },
                ":active": { backgroundColor: "#efefef" },
              }),
              multiValue: (styles, state) => ({
                ...styles,
                backgroundColor: "#028CB0",
                color: "#ffffff",
                borderRadius: 50,
                padding: "4px 8px",
              }),
              multiValueLabel: (styles) => ({
                ...styles,
                color: "#ffffff",
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "transparent",
                },
              }),
            }}
          />
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

function InternalAddress({ input, placeholder, meta }) {
  const [inputRef, setInputRef] = useState(null);
  const autocomplete = useRef(null);

  const fillInAddress = () => {
    const place = autocomplete.current.getPlace();

    const { formatted_address, address_components } = place;
    const postalCode = address_components.find((address_component) =>
      address_component.types.includes("postal_code")
    );
    const formattedAddressWithoutPostalCode = formatted_address.replace(
      " " + postalCode.long_name,
      ""
    );

    input.onChange(formattedAddressWithoutPostalCode);
  };

  useEffect(() => {
    if (inputRef && window.google) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef,
        {
          fields: ["address_components", "formatted_address"],
          types: ["address"],
        }
      );

      autocomplete.current?.addListener("place_changed", fillInAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  return (
    <UiLibrary.Input
      {...input}
      ref={setInputRef}
      placeholder={placeholder || input.placeholder}
      className={meta.touched && meta.error ? "error" : ""}
    />
  );
}

export function Address({ name, label, validators, placeholder }) {
  return (
    <Field name={name} validate={validators}>
      {({ input, meta }) => (
        <>
          <Label>{label}</Label>
          <InternalAddress
            input={input}
            placeholder={placeholder}
            meta={meta}
          />
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

const DropzoneContainer = styled.div`
  height: 120px;
  padding: 32px;
  border: 2px dashed #e4f0f7;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;

  &.active {
    background-color: #e4f0f7;
  }
`;

/**
 *
 * @param {FieldInputProps<any, HTMLElement>} param0
 * @returns
 */
function InternalDropzone({
  input,
  placeholder = "Drop your PDF file here or select from your computer",
  activePlaceholder = "Drop the files here ...",
  accept,
  multiple = false,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      input.onChange({
        target: {
          value: acceptedFiles[0],
        },
      });
    },
    [input]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    accept,
  });

  return (
    <DropzoneContainer
      className={isDragActive ? "active" : ""}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <UiLibrary.P>
        {isDragActive ? activePlaceholder : placeholder}
      </UiLibrary.P>
    </DropzoneContainer>
  );
}

const FilePreviewContainer = styled.div`
  width: 100%;
  height: 64px;
`;
const FilePreviewImage = styled.img`
  width: 48px;
  height: 48px;
  margin-left: 8px;
`;

/**
 *
 * @param {File} file
 * @returns
 */
const _defaultFilePreview = (file, clearValue) => {
  return (
    <FilePreviewContainer className="flex align-center">
      <FilePreviewImage src={FILE_TYPE_TO_ICON_MAPPING[file.type]} />

      <UiLibrary.P style={{ marginLeft: 16 }}>{file.name}</UiLibrary.P>

      <UiLibrary.TertiaryAlertButton
        style={{ marginLeft: "auto" }}
        onClick={clearValue}
      >
        <img src={closeRedIcon} alt="close" width={12} />
      </UiLibrary.TertiaryAlertButton>
    </FilePreviewContainer>
  );
};

/**
 *
 * @param {File | File[]} value
 */
export const defaultFilePreview = (value, clearValue) => {
  if (Array.isArray(value)) {
    // multiple files
    return value.map((val, index) => {
      const clearIndividualValue = () => {
        const files = JSON.parse(JSON.stringify(value));
        files.splice(index, 1);
        clearValue(files);
      };

      return _defaultFilePreview(val, clearIndividualValue);
    });
  } else {
    // single file
    return _defaultFilePreview(value, clearValue);
  }
};

export function Dropzone({
  name,
  label,
  placeholder,
  activePlaceholder,
  accept,
  multiple,
  filePreview,
  validate,
}) {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const shouldShowDropzone = multiple ? true : !input.value;

        return (
          <>
            <Label>{label}</Label>

            {shouldShowDropzone ? (
              <InternalDropzone
                input={input}
                placeholder={placeholder}
                activePlaceholder={activePlaceholder}
                accept={accept}
                multiple={multiple}
              />
            ) : (
              <noscript />
            )}

            {/* Showing preview for file(s) dropped  */}
            {input.value ? (
              filePreview ? (
                filePreview(input.value, input.onChange) // use custom file preview if provided
              ) : (
                defaultFilePreview(input.value, input.onChange)
              ) // the default file preview
            ) : (
              <noscript />
            )}

            {/* Error(optional) */}
            {meta.touched && meta.error && (
              <UiLibrary.Error>{meta.error}</UiLibrary.Error>
            )}
          </>
        );
      }}
    </Field>
  );
}

const animatedComponents = makeAnimated();
export function MultiSelect({
  name,
  label,
  description,
  className,
  options,
  validators,
  placeholder,
}) {
  return (
    <Field
      name={name}
      validate={validators}
      parse={(inputValue) => {
        return inputValue.reduce((r, inspectionTypeOption) => {
          r.push(inspectionTypeOption.value);
          return r;
        }, []);
      }}
      format={(storedValue) => {
        if (!storedValue) {
          return "";
        }

        return options.filter(
          (option) => storedValue.indexOf(option.value) > -1
        );
      }}
    >
      {({ input, meta }) => (
        <>
          {label && <Label>{label}</Label>}
          {description && <Description>{description}</Description>}
          <ReactSelect
            {...input}
            options={options}
            closeMenuOnSelect={false}
            isMulti
            classNamePrefix="react-select"
            placeholder={placeholder}
            captureMenuScroll
            styles={{
              control: (styles) => {
                if (styles["boxShadow"]) {
                  // if box shadow is defined, it means the select
                  // field is focused
                  return {
                    ...styles,
                    boxShadow: "none",
                    minHeight: 48,
                    borderRadius: 4,
                    backgroundColor: "#ffffff",
                    border: "3px solid #edf1f7",
                    cursor: "text",
                    "&:hover": {
                      borderColor: "#edf1f7",
                    },
                  };
                }

                return {
                  ...styles,
                  minHeight: 48,
                  borderRadius: 4,
                  backgroundColor: "#f7f9fc",
                  border: "3px solid #f7f9fc",
                  cursor: "text",
                  "&:hover": {
                    backgroundColor: "#edf1f7",
                    borderColor: "#edf1f7",
                  },
                };
              },
              multiValue: (styles, state) => ({
                ...styles,
                backgroundColor: "#028CB0",
                borderRadius: 10,
              }),
              multiValueLabel: (styles) => ({
                // ...styles,
                color: "#ffffff",
                fontSize: "16px",
                padding: "8px 2px 8px 12px",
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                color: "#fff",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "transparent",
                },
              }),
            }}
          />
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

export function CreatableMultiSelect({
  name,
  label,
  className,
  validators,
  placeholder = "Type something and press enter...",
}) {
  const [inputValue, setInputValue] = useState("");

  return (
    <Field
      name={name}
      validate={validators}
      parse={(inputValue) => {
        return inputValue.map((otherServiceItem) => otherServiceItem.value);
      }}
      format={(storedValue) => {
        if (!storedValue) {
          return [];
        }

        return storedValue.map((storedValueItem) => ({
          label: storedValueItem,
          value: storedValueItem,
        }));
      }}
    >
      {({ input, meta }) => (
        <>
          <Label>{label}</Label>
          <CreatableSelect
            components={{
              DropdownIndicator: null,
            }}
            inputValue={inputValue}
            isClearable
            isMulti
            classNamePrefix="react-select"
            menuIsOpen={false}
            onInputChange={(inputValue) => {
              setInputValue(inputValue);
            }}
            onKeyDown={(event) => {
              if (!inputValue) return;

              switch (event.key) {
                case "Enter":
                case "Tab": {
                  input.onChange([
                    ...(input.value || []),
                    { label: inputValue, value: inputValue },
                  ]);
                  setInputValue("");
                  event.preventDefault();
                  break;
                }
                default:
                  break;
              }
            }}
            onChange={(inputValue) => input.onChange(inputValue)}
            placeholder={placeholder}
            value={input.value || []}
            styles={{
              control: (styles) => {
                if (styles["boxShadow"]) {
                  // if box shadow is defined, it means the select
                  // field is focused
                  return {
                    ...styles,
                    boxShadow: "none",
                    minHeight: 48,
                    borderRadius: 4,
                    backgroundColor: "#ffffff",
                    border: "3px solid #edf1f7",
                    cursor: "text",
                    "&:hover": {
                      borderColor: "#edf1f7",
                    },
                  };
                }

                return {
                  ...styles,
                  minHeight: 48,
                  borderRadius: 4,
                  backgroundColor: "#f7f9fc",
                  border: "3px solid #f7f9fc",
                  cursor: "text",
                  "&:hover": {
                    backgroundColor: "#edf1f7",
                    borderColor: "#edf1f7",
                  },
                };
              },
              multiValue: (styles, state) => ({
                ...styles,
                backgroundColor: "#028CB0",
                borderRadius: 10,
              }),
              multiValueLabel: (styles) => ({
                // ...styles,
                color: "#ffffff",
                fontSize: "16px",
                padding: "8px 2px 8px 12px",
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                color: "#fff",
                cursor: "pointer",
                ":hover": {
                  backgroundColor: "transparent",
                },
              }),
            }}
          />
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}

export function Rating({ name, label }) {
  return (
    <Field name={name}>
      {({ input, meta }) => (
        <>
          <Label>{label}</Label>
          <StarRatings
            numberOfStars={5}
            rating={Number(input.value)}
            starRatedColor="#FEBA00"
            starHoverColor="#FEBA00"
            starEmptyColor="#ffe9b3"
            starDimension="32px"
            starSpacing="3px"
            // eslint-disable-next-line max-len
            svgIconPath="M11.1537 17.5001L6.04819 20.1842C5.43713 20.5054 4.68135 20.2705 4.3601 19.6594C4.23218 19.4161 4.18803 19.1374 4.2345 18.8664L5.20956 13.1814L1.07915 9.15525C0.584792 8.67338 0.574676 7.88199 1.05655 7.38763C1.24844 7.19078 1.49987 7.06267 1.77191 7.02314L7.48001 6.1937L10.0327 1.02129C10.3383 0.402223 11.0878 0.14805 11.7069 0.453577C11.9534 0.57524 12.1529 0.774775 12.2746 1.02129L14.8273 6.1937L20.5354 7.02314C21.2186 7.12241 21.6919 7.75671 21.5927 8.43989C21.5531 8.71194 21.425 8.96337 21.2282 9.15525L17.0978 13.1814L18.0728 18.8664C18.1895 19.5469 17.7325 20.1931 17.0521 20.3098C16.7812 20.3562 16.5025 20.3121 16.2591 20.1842L11.1537 17.5001Z"
            svgIconViewBox="0 0 22 21"
            changeRating={input.onChange}
          />
          {meta.touched && meta.error && (
            <UiLibrary.Error>{meta.error}</UiLibrary.Error>
          )}
        </>
      )}
    </Field>
  );
}
