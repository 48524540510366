import { useAuth } from "common/authentication";
import {
  FacebookSignIn,
  GoogleSignIn,
  Input,
  Loader,
  PhoneNumber,
} from "components";
import {
  emailValidator,
  passwordValidator,
  requiredValidator,
} from "components/form/validators";
import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { useSearchParams } from "react-router-dom";
import {
  ACCOUNT_MASTER_ROUTE,
  ACCOUNT_ROUTES,
  INSPECTORS_MASTER_ROUTE,
  INSPECTOR_ROUTES,
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  USERS_MASTER_ROUTE,
} from "routes";
import { UsersApi } from "services";
import { displayErrorMessage } from "services/api";
import styled from "styled-components";
import { A, H2, InternalA, P, PrimaryButton, Separator } from "ui-library";

const Title = styled(H2)`
  margin-top: 40px;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const StyledCol = styled(Col)`
  @media only screen and (max-width: 576px) {
    text-align: center;
  }
`;

/**
 * @typedef {Object} AccountRegisterFormProps
 * @property {boolean} isInspector
 * @property {string} title
 * @property {string} description
 */

/**
 *
 * @param {AccountRegisterFormProps} props
 * @returns
 */
export default function AccountRegisterForm({
  description,
  isInspector,
  title,
}) {
  const auth = useAuth();
  const [params] = useSearchParams();
  const next = params?.get("next");
  const email = params?.get("Email");

  return (
    <Form
      initialValues={{ email }}
      onSubmit={async (values) => {
        try {
          let updatedValues = values;
          if (isInspector) {
            updatedValues["companyName"] = "Company name";
          }

          await UsersApi.signUpWithEmail({
            ...updatedValues,
            acceptTermsConditions: true,
            isAnonymous: false,
          });

          await auth.login(values.email, values.password, next);
        } catch (e) {
          displayErrorMessage(e);
        }
      }}
      render={({ handleSubmit, submitting }) => (
        <div style={{ maxWidth: 450, marginLeft: 0, width: "100%" }}>
          <Container>
            <Row>
              <StyledCol>
                <Title>{title}</Title>
                <P>{description}</P>
              </StyledCol>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <Input
                  label="First name"
                  name="firstName"
                  placeholder="First name"
                  validate={requiredValidator}
                  autocomplete="given-name"
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  label="Last name"
                  name="lastName"
                  placeholder="Last name"
                  validate={requiredValidator}
                  autocomplete="family-name"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PhoneNumber
                  label="Phone number"
                  name="phone"
                  placeholder="Your mobile number"
                  description="We will send you an SMS verification code"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Input
                  label="Email address"
                  name="email"
                  placeholder="Your email address"
                  validate={emailValidator}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Input
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Type a password"
                  validate={passwordValidator}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PrimaryButton
                  onClick={submitting ? null : handleSubmit}
                  style={{ marginTop: 24, width: "100%" }}
                >
                  {submitting ? <Loader loading /> : "Next step"}
                </PrimaryButton>
              </Col>
              <Col xs={12}>
                <P
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    lineHeight: "20px",
                    marginTop: 16,
                  }}
                >
                  By joining Rate my Inspectors, you confirm that you accept the{" "}
                  <A
                    href={"/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.TERMS}
                    target="_blank"
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    Terms and Conditions
                  </A>{" "}
                  and{" "}
                  <A
                    href={
                      "/" +
                      PUBLIC_MASTER_ROUTE +
                      "/" +
                      PUBLIC_ROUTES.PRIVACY_POLICY
                    }
                    target="_blank"
                    style={{
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  >
                    Privacy Policy
                  </A>
                </P>
              </Col>
              <Col xs={12} style={{ marginTop: 24 }}>
                <P style={{ textAlign: "center", marginBottom: 16 }}>
                  Already have an account?{" "}
                  <InternalA
                    style={{ fontSize: 16 }}
                    className="regular"
                    to={"/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.LOGIN}
                  >
                    Log in
                  </InternalA>
                </P>
              </Col>
              <Col xs={12} style={{ display: "flex", alignItems: "center" }}>
                <Separator style={{ flexBasis: "25%" }} />
                <span style={{ color: "#A6B1C4" }}>or continue with</span>
                <Separator style={{ flexBasis: "25%" }} />
              </Col>
              <Col xs={6}>
                <GoogleSignIn
                  redirectUrl={
                    next ||
                    "/" +
                      (isInspector
                        ? INSPECTORS_MASTER_ROUTE +
                          "/" +
                          INSPECTOR_ROUTES.ONBOARDING_INTRO
                        : USERS_MASTER_ROUTE)
                  }
                  isInspector={isInspector}
                  isRegistering
                />
              </Col>
              <Col xs={6}>
                <FacebookSignIn
                  redirectUrl={
                    next ||
                    "/" +
                      (isInspector
                        ? INSPECTORS_MASTER_ROUTE +
                          "/" +
                          INSPECTOR_ROUTES.ONBOARDING_INTRO
                        : USERS_MASTER_ROUTE)
                  }
                  isInspector={isInspector}
                  isRegistering
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    />
  );
}
