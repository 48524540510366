export default function FindYourInspectorsIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#EB9F22"
      />
      <g clipPath="url(#clip0_222_47)">
        <path
          d="M35.1104 40.6185C35.1104 39.5001 35.4407 38.4068 36.0597 37.4769C36.6786 36.547 37.5584 35.8223 38.5876 35.3943C39.6169 34.9663 40.7495 34.8543 41.8421 35.0725C42.9348 35.2907 43.9385 35.8292 44.7263 36.6201C45.514 37.4109 46.0505 38.4184 46.2679 39.5153C46.4852 40.6122 46.3737 41.7491 45.9473 42.7824C45.521 43.8156 44.799 44.6988 43.8727 45.3201C42.9464 45.9414 41.8573 46.2731 40.7432 46.2731C39.2493 46.2731 37.8166 45.6773 36.7602 44.6169C35.7038 43.5564 35.1104 42.1182 35.1104 40.6185ZM51.5989 49.5075L47.6874 45.6217C48.8915 43.8367 49.3834 41.6626 49.0656 39.5306C48.7478 37.3987 47.6436 35.4644 45.9718 34.1113C44.3 32.7583 42.1828 32.0853 40.0399 32.2258C37.897 32.3663 35.885 33.31 34.4027 34.8698C32.9204 36.4297 32.0762 38.4917 32.0374 40.6471C31.9985 42.8024 32.7679 44.8938 34.193 46.5064C35.6181 48.119 37.5948 49.1352 39.7313 49.3534C41.8677 49.5716 44.0078 48.9759 45.7272 47.6845L49.6161 51.5884C49.8865 51.8217 50.2348 51.9435 50.5911 51.9295C50.9473 51.9154 51.285 51.7664 51.5363 51.5124C51.7875 51.2585 51.9336 50.9185 51.9453 50.5608C51.9569 50.2031 51.8331 49.8542 51.5989 49.5844V49.5075Z"
          fill="#EB9F22"
        />
        <path
          d="M30.87 29.3093C30.0338 29.3102 29.2161 29.0621 28.5204 28.5963C27.8247 28.1306 27.2822 27.4683 26.9616 26.693C26.641 25.9177 26.5566 25.0644 26.7192 24.241C26.8818 23.4176 27.284 22.6611 27.875 22.0672C28.4659 21.4734 29.2191 21.0688 30.0392 20.9047C30.8592 20.7406 31.7094 20.8244 32.482 21.1454C33.2546 21.4664 33.915 22.0103 34.3797 22.7082C34.8443 23.4061 35.0924 24.2266 35.0924 25.066C35.0929 25.6231 34.9842 26.1747 34.7722 26.6895C34.5603 27.2043 34.2494 27.6721 33.8572 28.0662C33.4651 28.4603 32.9994 28.7729 32.4868 28.9862C31.9743 29.1995 31.4248 29.3093 30.87 29.3093ZM30.87 18C29.2383 17.9939 27.655 18.5564 26.3903 19.5914C25.1256 20.6264 24.2579 22.0698 23.9351 23.6755C23.6123 25.2811 23.8545 26.9494 24.6203 28.3958C25.3862 29.8421 26.6283 30.9769 28.1346 31.6065C29.641 32.2362 31.3183 32.3216 32.8804 31.8482C34.4424 31.3749 35.7925 30.3721 36.7002 29.0109C37.608 27.6498 38.0171 26.0146 37.8578 24.3844C37.6986 22.7543 36.9808 21.2301 35.8269 20.0719C34.5133 18.7492 32.7305 18.0041 30.87 18ZM18.0811 43.3327C17.9455 43.6831 17.9538 44.0731 18.1042 44.4173C18.2545 44.7616 18.5346 45.032 18.8832 45.1693C19.0467 45.2292 19.2185 45.2628 19.3924 45.2688C19.6786 45.2707 19.9586 45.1852 20.1953 45.0236C20.4319 44.862 20.614 44.632 20.7173 44.3641C21.502 42.295 22.8981 40.5162 24.7184 39.2662C26.5386 38.0162 28.6961 37.3547 30.9015 37.3705C31.5692 37.3779 32.2346 37.449 32.8888 37.5831C33.2416 36.6245 33.7653 35.7385 34.4344 34.9684C31.1644 34.0866 27.6848 34.4526 24.6678 35.9957C21.6509 37.5389 19.3106 40.1496 18.0991 43.3236L18.0811 43.3327Z"
          fill="#EB9F22"
        />
      </g>
      <defs>
        <clipPath id="clip0_222_47">
          <rect
            width="34"
            height="34"
            fill="white"
            transform="translate(18 18)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
