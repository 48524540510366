import { Rating, TextArea, Loader } from "components";
import Toast from "components/toast";
import { useState } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { USERS_MASTER_ROUTE, USER_ROUTES } from "routes";
import { InspectorReviewsApi } from "services";
import { displayErrorMessage } from "services/api";
import styled from "styled-components";
import { A, P, PrimaryButton, TertiaryButton } from "ui-library";
import MultipleImageUploadForReview from "./multi-upload";

const RateWarning = styled.div`
  background-color: #fffdf2;
  border: 1px solid #fff1c2;
  border-radius: 3px;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 32px;

  ${P} {
    color: #143a5a;
  }
`;

/**
 * @typedef {Object} RateInspectorFormProps
 * @property {string | number} inspectorId
 * @property {import("services").InspectorReview} review
 * @returns
 */

function FormContent({
  values,
  inspectorId,
  defaultImagePaths,
  isEditing,
  onSubmit,
  submitting,
  onImagesFinalised,
}) {
  const navigate = useNavigate();

  return (
    <>
      <Rating name="stars" />

      {values.stars <= 3 ? (
        <RateWarning>
          <P>
            If you are not satisfied with the inspectors service, then we
            recommend you contact them and try to resolve the matter before you
            leave a review on Rate my Inspectors.
          </P>
        </RateWarning>
      ) : (
        <noscript />
      )}

      <TextArea
        name="review"
        label="Your review"
        placeholder="Share details of your own experience with this inspector or inspection company."
      />

      <MultipleImageUploadForReview
        inspectorId={inspectorId}
        imagePaths={defaultImagePaths || []}
        onImagesFinalised={onImagesFinalised}
      />

      <P style={{ textAlign: "center", marginTop: 16 }}>
        Your post will be made public on the Rate My Inspectors website, subject
        to our{" "}
        <A href="/terms" target="_blank" style={{ fontSize: "inherit" }}>
          terms and conditions
        </A>
      </P>

      <PrimaryButton
        style={{ width: "100%", marginTop: 48 }}
        onClick={submitting ? null : onSubmit}
      >
        {submitting ? <Loader loading /> : "Leave a review"}
      </PrimaryButton>
      <TertiaryButton
        style={{ width: "100%", marginTop: 8 }}
        onClick={() => {
          if (isEditing) {
            navigate(-1);
          } else {
            window.open("https://www.ratemyinspectors.com", "_self");
          }
        }}
      >
        Cancel
      </TertiaryButton>
    </>
  );
}

function EditReviewForm({ review, inspectorId, inspectorName }) {
  const navigate = useNavigate();
  const [reviewImages, setReviewImages] = useState({});

  return (
    <Form
      initialValues={review}
      onSubmit={async (values) => {
        // compile review data
        const reviewData = {
          ...values,
        };
        if (Object.values(reviewImages).length) {
          reviewData.imagePaths = Object.values(reviewImages);
        }

        try {
          await InspectorReviewsApi.editReview(
            inspectorId,
            review.id,
            reviewData
          );

          // notification
          toast.success(
            <Toast
              title={`Your review for ${inspectorName} has been updated`}
            />
          );

          // navigate away
          navigate(
            "/" +
              USERS_MASTER_ROUTE +
              "/" +
              USER_ROUTES.DASHBOARD +
              "?v=reviews"
          );
        } catch (e) {
          displayErrorMessage(e);
        }
      }}
      render={({ handleSubmit, submitting, values }) => {
        return (
          <FormContent
            defaultImagePaths={review.imagePaths}
            inspectorId={inspectorId}
            isEditing
            onSubmit={handleSubmit}
            submitting={submitting}
            values={values}
            onImagesFinalised={(imagePathMap) => setReviewImages(imagePathMap)}
          />
        );
      }}
    />
  );
}

function CreateReviewForm({ inspectorId, inspectorName }) {
  const navigate = useNavigate();
  const [reviewImages, setReviewImages] = useState({});

  return (
    <Form
      onSubmit={async (values) => {
        // compile review data
        const reviewData = {
          ...values,
        };
        if (Object.values(reviewImages).length) {
          reviewData.imagePaths = Object.values(reviewImages);
        }

        try {
          await InspectorReviewsApi.postReviewForInspectorWithId(
            inspectorId,
            reviewData
          );

          // notification
          toast.success(
            <Toast
              title={`Your review for ${inspectorName} has been submitted`}
            />
          );

          // navigate away
          navigate(
            "/" +
              USERS_MASTER_ROUTE +
              "/" +
              USER_ROUTES.DASHBOARD +
              "?v=reviews"
          );
        } catch (e) {
          displayErrorMessage(e);
        }
      }}
      render={({ handleSubmit, submitting, values }) => {
        return (
          <FormContent
            defaultImagePaths={[]}
            inspectorId={inspectorId}
            isEditing
            onSubmit={handleSubmit}
            submitting={submitting}
            values={values}
            onImagesFinalised={(imagePathMap) => setReviewImages(imagePathMap)}
          />
        );
      }}
    />
  );
}

/**
 *
 * @param {RateInspectorFormProps} props
 * @returns
 */
export default function RateInspectorForm({
  review,
  inspectorId,
  inspectorName,
}) {
  const isEditingAnExistingReview = !!review;

  if (isEditingAnExistingReview) {
    return (
      <EditReviewForm
        review={review}
        inspectorName={inspectorName}
        inspectorId={inspectorId}
      />
    );
  }

  return (
    <CreateReviewForm inspectorId={inspectorId} inspectorName={inspectorName} />
  );
}
