/* eslint-disable no-loop-func */
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import {
  H2,
  H4,
  LinkButton,
  P,
  Page,
  PrimaryButton,
  TertiaryAlertButton,
} from "ui-library";
import {
  Address,
  CreatableMultiSelect,
  Dropzone,
  Input,
  Loader,
  Modal,
  MultiSelect,
  Select,
  SocialInput,
  TextArea,
} from "components";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import {
  inspectionTypesSelector,
  inspectorSelector,
  servicesSelector,
} from "store/features/inspectors/inspectorsSlice";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { InspectorsApi, UsersApi } from "services";
import { useNavigate } from "react-router-dom";
import defaultProfilePhoto from "assets/images/default-profile-photo.svg";
import cameraIcon from "assets/icons/camera.svg";
import { selectAccount, updateUser } from "store/features/user/userSlice";
import { toast } from "react-toastify";
import { displayErrorMessage } from "services/api";
import closeIcon from "assets/icons/close-red.svg";
import { DocumentTitle } from "components/document-title";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import { requiredValidator, urlValidator } from "components/form/validators";
import Toast from "components/toast";

const Content = styled.div`
  margin-left: -16px;
  margin-right: -16px;
`;

const Image = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
`;

const ImageEditButton = styled.div`
  position: absolute;
  top: 10px;
  right: -20px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #eb9f22;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #efb14e;
  }

  &:focus,
  &:active {
    background-color: #df9112;
  }
`;

const commonColProps = {
  xs: 12,
  sm: 8,
  md: 6,
  offset: {
    sm: 2,
    md: 3,
  },
};

const LicenseContainer = styled.div`
  padding: 16px 16px 32px;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-bottom: 1px solid #e4f0f7;

  &.first {
    border-bottom: none;
  }
`;

const LicenseTitle = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;

  &:hover {
    background-color: #f7ebec;
  }
`;

const InputContainer = styled.div`
  width: 224px;
`;

const Label = styled.label`
  color: #143a5a;
  margin-top: 24px;
  margin-bottom: 4px;
  display: block;
`;

export default function EditInspectorProfilePage() {
  const navigate = useNavigate();
  const user = useSelector(selectAccount);
  const inspectorProfile = useSelector(inspectorSelector);
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const inspectionServices = useSelector(servicesSelector);
  const { firstName, lastName, email, phone } = useSelector(selectAccount);
  const dispatch = useDispatch();

  return (
    <DocumentTitle title="Edit Profile">
      <Page>
        <Content>
          <Container>
            <Row style={{ marginTop: 80 }}>
              {/* Page Title */}
              <Col {...commonColProps}>
                <H2>Edit your profile</H2>
              </Col>

              {/* Profile Photo */}
              <Col {...commonColProps} style={{ marginTop: 24 }}>
                <Image
                  style={{
                    backgroundImage: `url(${
                      user.profilePhotoPath
                        ? user.profilePhotoPath + "?w=104&h=104)"
                        : defaultProfilePhoto
                    }`,
                  }}
                >
                  <Modal
                    title="Update profile photo"
                    trigger={
                      <ImageEditButton>
                        <img src={cameraIcon} alt="camera" width={20} />
                      </ImageEditButton>
                    }
                  >
                    {(closeModal) => (
                      <Form
                        initialValues={{
                          firstName,
                          lastName,
                          email,
                          phone,
                        }}
                        onSubmit={async (values) => {
                          if (!values.point) {
                            delete values.address;
                          }

                          const formData = new FormData();
                          Object.keys(values).forEach((key) => {
                            formData.append(key, values[key]);
                          });
                          try {
                            const response = await UsersApi.updateCurrentUser(
                              formData
                            );
                            dispatch(updateUser(response));
                            closeModal();
                          } catch (e) {
                            displayErrorMessage(e);
                          }
                        }}
                        render={({ handleSubmit, submitting }) => (
                          <>
                            <input type="hidden" name="firstName" />
                            <input type="hidden" name="lastName" />
                            <input type="hidden" name="email" />

                            <Dropzone
                              name="profilePhotoFile"
                              placeholder="Drop an image file here or select from your device"
                              activePlaceholder="Drop the file here ..."
                              accept=".png, .jpg, .jpeg"
                              filePreview={(file, clearValue) => {
                                return (
                                  <FilePreview
                                    file={file}
                                    clearValue={clearValue}
                                  />
                                );
                              }}
                            />

                            <PrimaryButton
                              style={{
                                marginLeft: "auto",
                                marginTop: 24,
                                width: 160,
                              }}
                              onClick={submitting ? null : handleSubmit}
                            >
                              {submitting ? <Loader loading /> : "Save"}
                            </PrimaryButton>
                          </>
                        )}
                      />
                    )}
                  </Modal>
                </Image>
              </Col>

              <Form
                initialValues={{
                  ...inspectorProfile,
                  addressDisplay: inspectorProfile.address?.formatted,
                }}
                mutators={arrayMutators}
                onSubmit={async (values) => {
                  let updatedValues = values;
                  try {
                    if (updatedValues.addressDisplay) {
                      // address has been filled in

                      // Add point
                      const geocoder = new window.google.maps.Geocoder();
                      const geocodingResults = await geocoder.geocode({
                        address: values.addressDisplay,
                      });

                      if (!geocodingResults.results?.length) {
                        toast.error(
                          <Toast title="Please check the business address and try again" />
                        );
                        return;
                      }

                      let addressComponents = {};
                      let street = "";
                      for (var addressComponent of geocodingResults.results[0]
                        .address_components) {
                        switch (addressComponent.types[0]) {
                          case "postal_code": {
                            addressComponents.postcode =
                              addressComponent.long_name;
                            break;
                          }

                          case "country": {
                            addressComponents.country =
                              addressComponent.long_name;
                            addressComponents.region =
                              addressComponent.short_name;
                            break;
                          }

                          case "administrative_area_level_1": {
                            addressComponents.state =
                              addressComponent.short_name;
                            break;
                          }

                          case "locality": {
                            addressComponents.suburb =
                              addressComponent.long_name;
                            break;
                          }

                          case "street_number": {
                            street += addressComponent.long_name;
                            break;
                          }
                          case "route": {
                            street += " ";
                            street += addressComponent.long_name;
                            addressComponents.street = street;
                            break;
                          }

                          default: {
                            break;
                          }
                        }
                      }

                      updatedValues = {
                        ...updatedValues,
                        address: {
                          ...addressComponents,
                          point: {
                            latitude:
                              geocodingResults.results[0].geometry.location.lat(),
                            longitude:
                              geocodingResults.results[0].geometry.location.lng(),
                          },
                          formatted:
                            geocodingResults.results[0].formatted_address,
                        },
                      };
                    }

                    // filter incomplete or empty socials
                    // format complete ones
                    if (updatedValues.socials?.length) {
                      updatedValues.socials = updatedValues.socials
                        .filter((_) => _.length)
                        .map((_) => {
                          if (!_.startsWith("http")) {
                            return `https://${_}`;
                          }

                          return _;
                        });
                    }

                    // filter incomplete or empty website
                    // format filled on
                    if (updatedValues.website?.length) {
                      updatedValues.website = `https://${updatedValues.website}`;
                    }

                    // Complete the rest of the fields
                    await InspectorsApi.updateCurrentInspector(updatedValues);
                    toast.success(
                      <Toast title="Profile updated successfully" />
                    );
                    navigate(
                      INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.DASHBOARD
                    );
                  } catch (e) {
                    displayErrorMessage(e);
                  }
                }}
                render={({ handleSubmit, submitting, hasValidationErrors }) => {
                  return (
                    <>
                      {/* About You */}
                      <Col {...commonColProps} style={{ marginTop: 48 }}>
                        <H4>About you</H4>
                      </Col>

                      {/* Address */}
                      <Col {...commonColProps}>
                        <Address
                          name="addressDisplay"
                          label="Business address"
                        />
                      </Col>

                      {/* Radius */}
                      <Col {...commonColProps}>
                        <Select
                          name="searchRadiusInKilometres"
                          label="Radius"
                          description="Adjust your radius so you reach more potential clients."
                          items={[
                            { label: "20 km", value: 20 },
                            { label: "50 km", value: 50 },
                            { label: "100 km", value: 100 },
                            { label: "200 km", value: 200 },
                            { label: "500 km", value: 500 },
                            { label: "1000 km +", value: 1000 },
                          ]}
                        />
                      </Col>

                      {/* ABN/ACN */}
                      <Col {...commonColProps}>
                        <Input name="companyId" label="ABN/ACN number" />
                      </Col>

                      {/* Inspection types */}
                      <Col {...commonColProps}>
                        <MultiSelect
                          name="inspectionTypeIds"
                          label="Inspection types"
                          description="These are the inspection types you offer."
                          options={inspectionTypes.map((inspectionType) => ({
                            label: inspectionType.name,
                            value: inspectionType.id,
                          }))}
                          validators={requiredValidator}
                        />
                      </Col>

                      {/* Bio */}
                      <Col {...commonColProps}>
                        <TextArea
                          name="bio"
                          label="Bio"
                          rows={5}
                          placeholder="Add a short bio so your potential clients get to know your business a little more"
                        />
                      </Col>

                      {/* Year */}
                      <Col {...commonColProps}>
                        <div style={{ width: 200 }}>
                          <Input
                            name="establishedYear"
                            label="Year business was established"
                            type="number"
                          />
                        </div>
                      </Col>

                      {/* Website */}
                      <Col {...commonColProps}>
                        <Input
                          name="website"
                          label="Website URL"
                          validate={urlValidator}
                        />
                      </Col>

                      {/* Socials */}
                      <FieldArray name="socials">
                        {({ fields }) => {
                          return (
                            <>
                              <Col {...commonColProps}>
                                <Label>Socials</Label>
                              </Col>

                              {fields.map((name, index) => (
                                <Col {...commonColProps} key={index}>
                                  <SocialInput
                                    name={name}
                                    index={index}
                                    removeField={() => fields.remove(index)}
                                    addField={() => {
                                      // Only add a field if the current field
                                      // is the last field on the list
                                      if (index === fields.length - 1) {
                                        // add field
                                        fields.push();
                                      }
                                    }}
                                  />
                                </Col>
                              ))}

                              <Col {...commonColProps}>
                                <LinkButton
                                  style={{ marginTop: 16 }}
                                  onClick={() => fields.push()}
                                >
                                  Add social link
                                </LinkButton>
                              </Col>
                            </>
                          );
                        }}
                      </FieldArray>

                      {/* Licenses */}
                      <Col {...commonColProps} style={{ marginTop: 48 }}>
                        <H4>Licenses</H4>
                      </Col>

                      <FieldArray name="licenses">
                        {({ fields }) => {
                          return (
                            <>
                              <Col {...commonColProps}>
                                {fields.map((name, index) => (
                                  <LicenseContainer
                                    key={name}
                                    className={
                                      index === 0 && fields.length === 1
                                        ? "first"
                                        : null
                                    }
                                  >
                                    <LicenseTitle>
                                      <P
                                        style={{
                                          color: "#143A5A",
                                          fontWeight: 700,
                                        }}
                                      >
                                        License {index + 1}
                                      </P>

                                      <DeleteButton
                                        onClick={() => fields.remove(index)}
                                      >
                                        <img
                                          src={closeIcon}
                                          alt="close"
                                          width={14}
                                        />
                                      </DeleteButton>
                                    </LicenseTitle>

                                    <InputContainer>
                                      <Input
                                        name={`${name}.number`}
                                        label="License number (if applicable)"
                                        autocomplete="false"
                                      />
                                    </InputContainer>

                                    <InputContainer>
                                      <Input
                                        name={`${name}.issuingAuthority`}
                                        label="Name of license issuing authority"
                                        autocomplete="false"
                                        validate={requiredValidator}
                                      />
                                    </InputContainer>

                                    <InputContainer>
                                      <Input
                                        name={`${name}.type`}
                                        label="Type of license (optional)"
                                        autocomplete="false"
                                      />
                                    </InputContainer>

                                    <InputContainer>
                                      <Input
                                        name={`${name}.link`}
                                        label="Link to license (optional)"
                                        style={{
                                          marginBottom: 16,
                                        }}
                                        autocomplete="false"
                                      />
                                    </InputContainer>
                                  </LicenseContainer>
                                ))}
                              </Col>

                              <Col {...commonColProps}>
                                <LinkButton
                                  style={{ marginTop: 8 }}
                                  onClick={() =>
                                    fields.push({
                                      number: "",
                                      issuingAuthority: "",
                                    })
                                  }
                                >
                                  Add a license
                                </LinkButton>
                              </Col>
                            </>
                          );
                        }}
                      </FieldArray>

                      {/* Services */}
                      <Col {...commonColProps} style={{ marginTop: 48 }}>
                        <H4>Other services</H4>
                      </Col>

                      <Col {...commonColProps}>
                        <MultiSelect
                          name="inspectionServiceIds"
                          label="Other services"
                          options={inspectionServices.map(
                            (inspectionService) => ({
                              label: inspectionService.name,
                              value: inspectionService.id,
                            })
                          )}
                          validators={requiredValidator}
                        />
                      </Col>

                      {/* Other Services */}
                      <Col {...commonColProps}>
                        <CreatableMultiSelect
                          name="otherInspectionServices"
                          label="Other services not listed"
                          placeholder="Start typing and hit enter to add other services not listed"
                        />
                      </Col>

                      {/* Submit */}
                      <Col
                        {...commonColProps}
                        style={{ marginTop: 48, marginBottom: 32 }}
                      >
                        <PrimaryButton
                          onClick={
                            submitting
                              ? null
                              : (event) => {
                                  // submit form
                                  handleSubmit(event);

                                  // if there are validation errors,
                                  // show error message
                                  if (hasValidationErrors) {
                                    toast.error(
                                      <Toast title="Please correct the errors in the form and then submit again." />
                                    );
                                  }
                                }
                          }
                          style={{ marginRight: "auto", width: 200 }}
                        >
                          <Loader loading={submitting} />

                          {submitting ? " Updating..." : "Update profile"}
                        </PrimaryButton>
                      </Col>
                    </>
                  );
                }}
              />
            </Row>
          </Container>
        </Content>
      </Page>
    </DocumentTitle>
  );
}

function FilePreview({ file, clearValue }) {
  const [image, setImage] = useState();

  const showFilePreview = () => {
    var reader = new FileReader();
    reader.onload = function () {
      const data = reader.result;
      setImage(data);
    };
    reader.readAsDataURL(file);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(showFilePreview, []);

  return (
    <div className="flex justify-between">
      <Image
        style={{ backgroundImage: `url(${image})`, width: 160, height: 160 }}
      />

      <TertiaryAlertButton style={{ width: 48 }} onClick={clearValue}>
        X
      </TertiaryAlertButton>
    </div>
  );
}
