import styled from "styled-components";
import accountImage from "assets/images/account.jpeg";
import logo from "assets/icons/logo.svg";
import { useNavigate } from "react-router-dom";
import { Hidden, Visible } from "react-grid-system";

const FiftyFiftyContainer = styled.div`
  display: flex;
  align-items: stretch;
  height: 100vh;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    height: unset;
  }
`;

const FortyChild = styled.div`
  flex: 0 0 40%;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
`;

const SixtyChild = styled.div`
  flex: 0 0 60%;
  max-width: 100%;
  overflow: hidden;

  @media only screen and (max-width: 576px) {
    flex-basis: 100%;
  }
`;

const MaxedContent = styled.div`
  width: 100%;
  padding: 64px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  &.centered {
    justify-content: center;
  }

  @media only screen and (max-width: 1024px) {
    padding: 40px;
    padding-top: 64px;
    height: 100%;
  }

  @media only screen and (max-width: 576px) {
    height: auto;
    padding: 16px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  &.expanded {
    max-width: unset;
  }

  @media only screen and (max-height: 500px) {
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  @media only screen and (max-width: 576px) {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
    padding-top: 24px;
  }
`;

const Logo = styled.img`
  max-width: 153px;

  @media only screen and (max-width: 576px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const DesktopLogo = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  max-width: 153px;
`;

export function FiftyFifty({ image, children, expanded, centered }) {
  const navigate = useNavigate();

  const onLogoClick = () => navigate("/");

  return (
    <FiftyFiftyContainer>
      <FortyChild
        style={{ backgroundImage: `url(${image || accountImage})` }}
      ></FortyChild>
      <SixtyChild>
        <Hidden xs>
          <DesktopLogo src={logo} alt="logo" onClick={onLogoClick} />
        </Hidden>

        <Visible xs>
          <LogoContainer>
            <Logo src={logo} alt="logo" onClick={onLogoClick} />
          </LogoContainer>
        </Visible>

        <MaxedContent className={`${centered ? "centered" : ""}`}>
          <Content className={`${expanded ? "expanded" : ""}`}>
            {children}
          </Content>
        </MaxedContent>
      </SixtyChild>

      <style
        dangerouslySetInnerHTML={{
          __html: "nav, footer { display: none } #root { padding-top: 0 }",
        }}
      />
    </FiftyFiftyContainer>
  );
}
