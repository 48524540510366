import { api } from "./api";

/**
 * @typedef {Object} InspectionService
 * @property {number} id
 * @property {string} name
 * @property {string} description
 */

export const InspectionServicesApi = {
  /**
   *
   * @returns {Promise<InspectionService[]>}
   */
  getListOfInspectionServices: () => api.get("/InspectionServices"),
  /**
   *
   * @param {string | number} inspectionServiceId
   * @returns {Promise<InspectionService>}
   */
  getServiceById: (inspectionServiceId) =>
    api.get(`/InspectionServices/${inspectionServiceId}`),
};
