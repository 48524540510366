import { api } from "./api";

/**
 * @typedef {Object} InspectorProfile
 * @property {number} id
 * @property {string} code
 * @property {Inspector} fullName
 * @property {string} [phone]
 * @property {string} [imagePath]
 * @property {import('services/inspectors').InspectorCompany} company
 */

/**
 * @typedef {Object} InspectorReviewer
 * @property {number} id
 * @property {Inspector} fullName
 * @property {string} [profilePhotoPath]
 */

/**
 * @typedef {Object} InspectorReview
 * @property {number} id
 * @property {string} code
 * @property {InspectorProfile} inspector
 * @property {InspectorReviewer} reviewer
 * @property {number} stars
 * @property {string} review
 * @property {string} [imagePaths]
 * @property {string} createdOn
 * @property {'Google' | 'System'} source
 */

/**
 * @typedef {Object} PagedInspectorReviewsSummary
 * @property {number} currentPage
 * @property {number} pageCount
 * @property {number} pageSize
 * @property {number} totalCount
 * @property {number} [pagingMaxId]
 * @property {InspectorReview[]} data
 */

export const InspectorReviewsApi = {
  /**
   * @returns {Promise<PagedInspectorReviewsSummary>}
   */
  getReviewsForCurrentInspector: async () =>
    api.get("/inspectors/current/reviews"),

  /**
   *
   * @param {number} inspectorId
   * @returns {Promise<PagedInspectorReviewsSummary>}
   */
  getReviewsForInspectorWithId: async (inspectorId) =>
    api.get(`/inspectors/${inspectorId}/reviews`),

  /**
   *
   * @param {string|number} inspectorId
   * @param {any} review
   * @returns {Promise<void>}
   */
  postReviewForInspectorWithId: async (inspectorId, review) =>
    api.post(`/inspectors/${inspectorId}/reviews`, review),

  /**
   *
   * @param {string} reviewCode
   * @returns {Promise<InspectorReview>}
   */
  getReviewByReviewCode: async (reviewCode) =>
    api.get(`/inspectors/all/reviews/${reviewCode}`),

  /**
   *
   * @param {InspectorReview} review
   * @param {string} reason
   * @returns {Promise<void>}
   */
  flagReviewForReason: async (review, reason) =>
    api.post(`/inspectors/${review.inspector.id}/reviews/${review.id}`, {
      reviewId: review.id,
      reason,
      review,
    }),

  /**
   * @returns {Promise<PagedInspectorReviewsSummary>}
   */
  getReviewsCreatedByCurrentCustomer: async () =>
    api.get("/inspectors/all/reviews/current"),

  /**
   *
   * @param {number} inspectorId
   * @param {number} reviewId
   * @param {FormData} data
   * @returns
   */
  editReview: async (inspectorId, reviewId, data) =>
    api.put(`/inspectors/${inspectorId}/reviews/${reviewId}`, data),

  /**
   *
   * @param {number} inspectorId
   * @param {number} reviewId
   * @returns
   */
  deleteReview: async (inspectorId, reviewId) =>
    api.delete(`/inspectors/${inspectorId}/reviews/${reviewId}`),

  /**
   *
   * @param {string|number} inspectorId
   * @param {File} file
   * @param {() => void} onUploadComplete
   * @returns
   */
  uploadImageForReview: async (inspectorId, file, onUploadComplete) => {
    const formData = new FormData();
    formData.append("imageFile", file);

    return api.post(`inspectors/${inspectorId}/reviews/images`, formData);
  },
};
