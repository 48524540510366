import { useCallback, useState } from "react";
import { Accordion, defaultFilePreview, Loader } from "components";
import styled from "styled-components";
import circleCheckedIcon from "assets/icons/circle-checked.svg";
import circleUncheckedIcon from "assets/icons/circle-unchecked.svg";
import { A, P, PrimaryButton } from "ui-library";
import { useDropzone } from "react-dropzone";
import { InspectorsApi } from "services";
import { updateCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Toast from "components/toast";

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed #e4f0f7;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;

  &.active {
    background-color: #e4f0f7;
  }
`;

const Info = styled.div`
  background-color: #fffdf2;
  border: 1px solid #fff1c2;
  border-radius: 3px;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
  max-width: 650px;

  ${P} {
    color: #143a5a;
  }
`;

/**
 *
 * @param {Object} props
 * @param {import("services").InspectorProfile} props.inspector
 */
export function InspectionAgreementWidget({ inspector }) {
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const dispatch = useDispatch();

  const uploadAgreement = async () => {
    setSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("inspectionAgreementFile", file);
      await InspectorsApi.uploadFilesToCurrentInspector(formData);

      const updatedInspector = await InspectorsApi.getCurrentInspectorProfile();
      dispatch(updateCurrentInspector(updatedInspector));

      toast.success(<Toast title="Inspection agreement added" />);
      setFile(null);
    } catch (e) {
      toast.error(
        <Toast title={e?.response?.data?.globalErrors[0]?.message} />
      );
    }

    setSubmitting(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: ".pdf",
  });

  if (inspector.inspectionAgreementPath) {
    return <noscript />;
  }

  return (
    <StyledAccordion
      icon={
        inspector.inspectionAgreementPath
          ? circleCheckedIcon
          : circleUncheckedIcon
      }
      title="Inspection agreement"
    >
      <P>
        Upload your terms and conditions to save you time sending these to your
        potential customers.
      </P>

      <Info>
        <P>
          If you have more than one inspection agreement, include all the
          agreements within one PDF document.
        </P>
      </Info>

      {inspector.inspectionAgreementPath ? (
        <DropzoneContainer />
      ) : (
        <DropzoneContainer
          className={isDragActive ? "active" : ""}
          {...getRootProps()}
        >
          {file ? (
            defaultFilePreview(file, () => setFile(null))
          ) : (
            <>
              <input {...getInputProps()} />

              <P style={{ marginTop: 80, marginBottom: 80 }}>
                {isDragActive ? (
                  "Drop the files here ..."
                ) : (
                  <>
                    Drop your PDF file here or <A>select</A> from your computer
                  </>
                )}
              </P>
            </>
          )}
        </DropzoneContainer>
      )}

      <PrimaryButton
        disabled={!file}
        style={{ marginTop: 24 }}
        onClick={file ? (submitting ? null : uploadAgreement) : null}
      >
        {submitting ? <Loader loading /> : "Upload"}
      </PrimaryButton>
    </StyledAccordion>
  );
}
