import { useAuth } from "common/authentication";
import { hasInspectorProvidedRequiredInfo } from "common/helpers";
import { Loader } from "components";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ACCOUNT_MASTER_ROUTE,
  ACCOUNT_ROUTES,
  INSPECTORS_MASTER_ROUTE,
  INSPECTOR_ROUTES,
  PUBLIC_MASTER_ROUTE,
  USERS_MASTER_ROUTE,
} from "routes";
import { companySelector } from "store/features/companies/companiesSlice";
import { inspectorSelector } from "store/features/inspectors/inspectorsSlice";

export function RouteDecider() {
  const auth = useAuth();
  const navigate = useNavigate();
  const inspector = useSelector(inspectorSelector);
  const { pathname } = useLocation();
  const company = useSelector(companySelector);

  useEffect(() => {
    if (auth?.session) {
      const VERIFY_ROUTE =
        "/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.VERIFY;

      if (
        auth.session.status === "Registered" &&
        !pathname.startsWith(VERIFY_ROUTE)
      ) {
        navigate(VERIFY_ROUTE);
      } else if (auth.session.roles?.indexOf("CompanyAdmin") > -1) {
        const hasProvidedAllRequiredInfo = hasInspectorProvidedRequiredInfo(
          inspector,
          company
        );

        if (!hasProvidedAllRequiredInfo) {
          navigate(
            "/" +
              INSPECTORS_MASTER_ROUTE +
              "/" +
              INSPECTOR_ROUTES.ONBOARDING_INTRO
          );
        } else {
          navigate("/" + INSPECTORS_MASTER_ROUTE);
        }
      } else {
        navigate("/" + USERS_MASTER_ROUTE);
      }
    } else {
      navigate("/" + PUBLIC_MASTER_ROUTE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: 32 }}>
      <Loader color="#feba00" size="large" loading />
    </div>
  );
}
