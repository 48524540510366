import styled from "styled-components";
import facebookIcon from "assets/icons/facebook.svg";
import instagramIcon from "assets/icons/instagram.svg";
import linkedInIcon from "assets/icons/linkedin.svg";

const SocialIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

/**
 *
 * @param {Object} props
 * @param {string} props.link
 * @returns
 */
export function SocialIcon({ link }) {
  const openLink = () => {
    window.open(link, "_blank");
  };

  const getIcon = () => {
    if (link.indexOf("facebook") > -1) {
      return facebookIcon;
    } else if (link.indexOf("instagram") > -1) {
      return instagramIcon;
    } else {
      return linkedInIcon;
    }
  };

  return (
    <SocialIconContainer onClick={openLink}>
      <img
        src={getIcon()}
        alt="social"
        style={{ maxWidth: 24, maxHeight: 24 }}
      />
    </SocialIconContainer>
  );
}
