import { Loader } from "components/loader";
import { useEffect, useState } from "react";
import { InspectionReportsApi } from "services";
import { P } from "ui-library";
import { Report } from "../report";

export function InspectorReports({ inspectorId, inspectionTypes }) {
  const [reports, setReports] = useState(null);

  useEffect(() => {
    async function fetchReports() {
      try {
        const response =
          await InspectionReportsApi.getInspectionReportsForInspectorWithId(
            inspectorId
          );
        setReports(response);
      } catch (e) {
        setReports([]);
      }
    }
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!reports) {
    return (
      <div style={{ textAlign: "center" }}>
        <Loader size="medium" loading color="#EB9F22" />
      </div>
    );
  }

  if (reports.length === 0) {
    return (
      <div>
        <P style={{ marginLeft: 20 }}>
          <em>There are no reports for this inspector yet.</em>
        </P>
      </div>
    );
  }

  return reports.map((report) => {
    return (
      <Report
        report={report}
        inspectionTypes={inspectionTypes}
        key={report.id}
      />
    );
  });
}
