import Banner from "components/public-pages/banner/banner";
import SecondSectionRow from "components/public-pages/second-section-row/second-section-row";
import ReportsIcon from "../icons/reports";
import TypesIcon from "../icons/types";
import SecureIcon from "../icons/secure";
import Caurosel from "components/public-pages/caurosel/caurosel";
import { H2, P1, PrimaryButton } from "ui-library";
import AgentImage from "assets/images/for-agents-image-one.png";
import InspectionImage from "assets/images/inspection.png";
import LadyOnTopImage from "assets/images/lady-on-laptop.png";
import ForAgentsBannerImage from "assets/images/for-agents.png";
import FAQList from "components/public-pages/faqs/list";
import { agents as agentsFAQs } from "../helpers/faqs";
import { DocumentTitle } from "components/document-title";
import StatsCardWrapper from "components/public-pages/stats-card-wrapper";
import { Spacer } from "common/helpers";
import { Container } from "react-grid-system";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { PUBLIC_MASTER_ROUTE, PUBLIC_ROUTES } from "routes";

const StyledStatsCardWrapper = styled(StatsCardWrapper)`
  @media only screen and (min-width: 1020px) {
    /* margin-bottom: 32px; */

    > div {
      margin-top: -72px;
    }
  }

  @media only screen and (max-width: 1020px) {
    margin-bottom: 72px;
  }

  @media only screen and (max-width: 812px) {
    margin-bottom: 72px;
  }
`;

const items = [
  {
    icon: <ReportsIcon />,
    title: "Buyer and Seller reports",
    description: `You, your vendors and buyers can quickly organise your property inspections.`,
  },
  {
    icon: <TypesIcon />,
    title: "Existing reports",
    description: `Check for existing reports on a property.  Search our massive database of more than 50,000 reports.`,
  },
  {
    icon: <SecureIcon />,
    title: "Other inspection types",
    description: `Need an Electrical inspection or Asbestos inspection? How about a "Noise or Air pollution inspection"? We have you covered for more than 50 inspection types.`,
  },
];

export const ImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 80px;

  .image-section-div {
    padding-bottom: 54px;

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  p {
    width: 80%;
  }

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;

    p {
      width: 100%;
    }
  }
`;

export const ReverseImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 80px;

  .image-section-div {
    padding-bottom: 54px;

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  p {
    width: 80%;
  }

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;

    p {
      width: 100%;
    }
  }
`;

export const BannerButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 40px;

  button:last-child {
    color: #eb9f22;
    background: white;
    border: 1px solid #eb9f22;
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;

function ForAgentsPage() {
  const navigate = useNavigate();

  return (
    <>
      <DocumentTitle title="For Agents">
        <Banner
          title="Sell faster by providing reports to buyers"
          excerpt={
            <P1>
              Help both buyers and sellers obtain property reports to keep the
              deal moving.
            </P1>
          }
          image={ForAgentsBannerImage}
        >
          <BannerButtonsWrapper>
            <PrimaryButton onClick={() => navigate("/" + PUBLIC_MASTER_ROUTE)}>
              Find my inspector
            </PrimaryButton>
            <PrimaryButton
              onClick={() =>
                navigate(
                  "/" +
                    PUBLIC_MASTER_ROUTE +
                    "/" +
                    PUBLIC_ROUTES.PROPERTY_REPORTS
                )
              }
            >
              Search existing reports
            </PrimaryButton>
          </BannerButtonsWrapper>
        </Banner>
      </DocumentTitle>
      <StyledStatsCardWrapper />

      <Spacer />

      <Container>
        <SecondSectionRow items={items} />
      </Container>
      <Spacer />

      <Container>
        <ImageSection>
          <img src={AgentImage} alt="Checking inspector information" />
          <div className="image-section-div">
            <H2>More inspector information</H2>
            <P1>
              A complete inspector profile will include: Service area,
              inspections offered, other services, sample reports, videos,
              inspection agreements, licenses, reviews and contact details
            </P1>
          </div>
        </ImageSection>
      </Container>
      <Spacer />

      <Container>
        <ReverseImageSection>
          <div className="image-section-div">
            <H2>Less time at inspections</H2>
            <P1>
              Why spend hours waiting for multiple inspectors? Have the
              inspections done when it suits you and direct buyers to find the
              reports on Rate My Inspectors.
            </P1>
          </div>
          <img src={InspectionImage} alt="Inspection" />
        </ReverseImageSection>
      </Container>
      <Spacer />

      <Container>
        <ImageSection>
          <img src={LadyOnTopImage} alt="Lady checking reviews and dashboard" />
          <div className="image-section-div">
            <H2>Your own dashboard</H2>
            <P1>
              Have your own dashboard with preferred inspectors, quotes
              requested, reports purchased and reviews given.
            </P1>
          </div>
        </ImageSection>
      </Container>
      <Spacer />

      <Caurosel />

      <Container>
        <FAQList openedOnDefault={false} faqs={agentsFAQs.slice(0, 5)} />
      </Container>
      <Spacer />
    </>
  );
}

export default ForAgentsPage;
