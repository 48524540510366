import { Navigate, Route, Routes } from "react-router-dom";
import { SEARCH_QUOTES_ROUTES } from "routes";
import QuoteRequestViewForInspector from "./pages/quote-request";

export default function SearchQuotes() {
  return (
    <Routes>
      <Route
        path={SEARCH_QUOTES_ROUTES.REQUEST}
        element={<QuoteRequestViewForInspector />}
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
