import styled from "styled-components";
import quoteIcon from "assets/icons/stat-quote.svg";
import salesIcon from "assets/icons/stat-sales.svg";
import soldIcon from "assets/icons/stat-sold.svg";
import viewsIcon from "assets/icons/stat-views.svg";
import { H3, H5 } from "ui-library";
import { useSelector } from "react-redux";
import { statisticsSelector } from "store/features/inspectors/inspectorsSlice";
import { Col } from "react-grid-system";

const Stat = styled.div`
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  margin-top: 32px;
  background: #ffffff;
  border: 1px solid #e4f0f7;
  box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
  border-radius: 32px;
`;

const StateContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 30px;

  box-sizing: border-box;

  @media only screen and (max-width: 576px) {
    padding: 16px 20px;
  }
`;

const StatIcon = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const Icon = styled.img`
  max-width: 24px;
  max-height: 24px;
`;

const StatContent = styled.div`
  margin-top: 29px;

  @media only screen and (max-width: 576px) {
    margin-top: 16px;
  }
`;

const StyledH3 = styled(H3)`
  line-height: normal;
`;
const StyledH5 = styled(H5)`
  color: #798995;
  margin-top: 4px;

  @media only screen and (max-width: 576px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

// https://stackoverflow.com/a/11832950
const roundToTwoDecimalsMax = (value) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export function Stats() {
  const stats = useSelector(statisticsSelector);

  const formatNumber = (value) => {
    if (value < 1000) {
      return value;
    }

    if (value < 1000000) {
      return roundToTwoDecimalsMax(value / 1000) + "K";
    }

    if (value < 100000000) {
      return roundToTwoDecimalsMax(value / 1000000) + "M";
    }

    // TODO: This only accounts for numbers less than 1,000,000,000
    // (1 Billion). Hopefully, we will have better system than this
    // by then if we reach there
  };

  if (!stats) {
    return <noscript />;
  }

  const quoteStat = stats?.find((_) => _.type === "Quote requests");
  const salesStat = stats?.find((_) => _.type === "Report sales");
  const soldStat = stats?.find((_) => _.type === "Reports sold");
  const profileStat = stats?.find((_) => _.type === "Profile views");
  return (
    <>
      <Col xs={6} md={3}>
        <Stat>
          <StateContentContainer>
            <StatIcon style={{ backgroundColor: "#FEF5DB" }}>
              <Icon src={quoteIcon} alt="quote" />
            </StatIcon>

            <StatContent>
              <StyledH3>{formatNumber(quoteStat?.value || 0)}</StyledH3>
              <StyledH5>Quote interests</StyledH5>
            </StatContent>
          </StateContentContainer>
        </Stat>
      </Col>

      <Col xs={6} md={3}>
        <Stat>
          <StateContentContainer>
            <StatIcon style={{ backgroundColor: "#D7FCF0" }}>
              <Icon src={salesIcon} alt="sales" />
            </StatIcon>

            <StatContent>
              <StyledH3>${formatNumber(salesStat?.value || 0)}</StyledH3>
              <StyledH5>Report sales</StyledH5>
            </StatContent>
          </StateContentContainer>
        </Stat>
      </Col>

      <Col xs={6} md={3}>
        <Stat>
          <StateContentContainer>
            <StatIcon style={{ backgroundColor: "#E5F9FD" }}>
              <Icon src={soldIcon} alt="sold" />
            </StatIcon>

            <StatContent>
              <StyledH3>{formatNumber(soldStat?.value || 0)}</StyledH3>
              <StyledH5>Reports sold</StyledH5>
            </StatContent>
          </StateContentContainer>
        </Stat>
      </Col>

      <Col xs={6} md={3}>
        <Stat>
          <StateContentContainer>
            <StatIcon style={{ backgroundColor: "#EFECFF" }}>
              <Icon src={viewsIcon} alt="views" />
            </StatIcon>

            <StatContent>
              <StyledH3>{formatNumber(profileStat?.value || 0)}</StyledH3>
              <StyledH5>Profile views</StyledH5>
            </StatContent>
          </StateContentContainer>
        </Stat>
      </Col>
    </>
  );
}
