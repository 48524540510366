import { RequireAuth } from "common/authentication";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  SEARCH_REPORTS_ROUTES,
} from "routes";
import PurchaseConfirmation from "./pages/confirmation";
import ReportsList from "./pages/list";
import PurchaseReport from "./pages/purchase";

export default function SearchReports() {
  return (
    <Routes>
      <Route
        path={SEARCH_REPORTS_ROUTES.SEARCH_LIST}
        element={<ReportsList />}
      />
      <Route
        path={":reportId/" + SEARCH_REPORTS_ROUTES.PURCHASE_REPORT}
        element={
          <RequireAuth>
            <PurchaseReport />
          </RequireAuth>
        }
      />
      <Route
        path={":reportId/" + SEARCH_REPORTS_ROUTES.PURCHASE_CONFIRMATION}
        element={
          <RequireAuth>
            <PurchaseConfirmation />
          </RequireAuth>
        }
      />

      <Route
        path="*"
        element={
          <Navigate
            to={
              "/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.PROPERTY_REPORTS
            }
          />
        }
      />
    </Routes>
  );
}
