import styled from "styled-components";
import closeIcon from "assets/icons/close.svg";

const ToastCloseContainer = styled.div`
  position: absolute;
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #f5fafd;
  }
`;

export function ToastClose({ closeToast }) {
  return (
    <ToastCloseContainer onClick={closeToast}>
      <img src={closeIcon} alt="close" width={15} />
    </ToastCloseContainer>
  );
}
