import { useEffect } from "react";
import { P1, H2, H4, InternalA, H1 } from "ui-library";
import Envelope from "components/public-pages/icons/envelope";
import InspectorYear from "components/public-pages/icons/inspector-year";
import { images, faqsSections } from "../helpers/data";
import { DocumentTitle } from "components/document-title";
import { useDispatch, useSelector } from "react-redux";
import { InspectionTypesApi } from "services";
import {
  inspectionTypesSelector,
  setInspectionTypes,
} from "store/features/inspectors/inspectorsSlice";
import { Spacer } from "common/helpers";
import styled from "styled-components";
import { Container } from "react-grid-system";
import {
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";

export const AwardsList = styled.div`
  background: #f5fafd;

  h2 {
    text-align: center;
    margin-top: 80px;
    @media (min-width: 1020px) {
      transform: translateY(100px);
    }
  }

  .awards-section {
    padding: 20px 0;
    display: grid;
    grid-gap: 40px;
    place-items: center;

    @media (min-width: 1020px) {
      grid-template-columns: 1fr 1fr 1fr;
      background: #ffffff;
      box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
      border-radius: 32px;
      padding: 72px 24px;
      transform: translateY(50%);
    }
  }
`;

export const Heading = styled(H1)`
  text-align: center;
  margin-bottom: 80px;
  @media (min-width: 1020px) {
    margin: 160px 0;
  }
`;

export const BannerLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    max-width: 100%;
  }

  @media (min-width: 1020px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
`;

export const BannerLayoutContent = styled.div`
  margin-top: 40px;
  > * {
    text-align: center;
    @media (min-width: 1020px) {
      text-align: left;
    }
  }
`;

export const Label = styled.p`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 28px;
  span {
    margin-bottom: 8px;
  }
  @media (min-width: 1020px) {
    flex-direction: row;
    color: #eb9f22;
    align-items: center;
    span {
      display: inline-block;
      width: 32px;
      margin-bottom: 0;
      margin-right: 8px;
      height: 1px;
      background-color: #eb9f22;
    }
  }
`;

export const FAQSection = styled.section`
  h2 {
    text-align: center;
  }
  .sections-wrapper {
    margin-top: 55px;
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 49px;
  }
  .second-section {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 49px;
  }
  .contact-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f5fafd;
    border-radius: 20px;
    h4 {
      width: 380px;
      max-width: 100%;
    }
    a {
      color: #028cb0;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #eb9f22;
    span {
      width: 32px;
      background: #eb9f22;
      height: 1px;
    }
  }
  margin-top: 0;

  @media screen and (max-width: 1020px) {
    .label {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #eb9f22;
      span {
        width: 32px;
        background: #eb9f22;
        height: 1px;
      }
    }
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
    .second-section,
    .sections-wrapper {
      grid-template-columns: 1fr;
    }

    .contact-us {
      padding: 40px 24px;
      h4 {
        width: 100%;
      }
    }
  }
`;

export const CategoriesList = styled.div`
  h2 {
    text-align: center;
  }

  .categories-list {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      background: #ffffff;
      box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
      border-radius: 32px;

      p {
        margin-top: 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.2px;
        color: #143a5a;
      }
    }

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 1020px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
`;

export default function AwardsPage() {
  const dispatch = useDispatch();
  const inspectionTypes = useSelector(inspectionTypesSelector);

  useEffect(() => {
    async function fetchInspectionTypes() {
      try {
        const types = await InspectionTypesApi.getInspectionTypes();
        dispatch(setInspectionTypes(types));
      } catch (e) {}
    }
    fetchInspectionTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocumentTitle title="Awards">
        <Container>
          <Heading>Rate my Inspectors - Inspector of the year Awards</Heading>
          <BannerLayout>
            <InspectorYear />
            <BannerLayoutContent>
              <Label>
                <span></span>It's worth rewarding
              </Label>
              <H2 style={{ margin: "30px 0" }}>
                Showcasing the best inspectors and inspection companies in
                Australia.
              </H2>
              <P1>
                The Rate my Inspectors Awards showcases the best inspectors and
                inspection companies in Australia based upon the number and
                rating of real customer reviews. <br />
                <br /> Inspectors from all property-related industries can now
                be recognised as being the best in their field.
              </P1>
            </BannerLayoutContent>
          </BannerLayout>
        </Container>
      </DocumentTitle>

      <Spacer />

      <AwardsList>
        <Container>
          <H2 style={{ width: 750, maxWidth: "100%", margin: "0 auto" }}>
            Awards are given for each type of inspection and in each area
          </H2>
          <div className="awards-section">
            {images.map((img) => {
              return img;
            })}
          </div>
        </Container>
      </AwardsList>

      <Spacer />

      <Spacer />

      <Container>
        <CategoriesList>
          <H2>Categories include</H2>
          <div className="categories-list">
            {inspectionTypes.map((inspectionType, index) => {
              return (
                <div key={index}>
                  <img
                    src={inspectionType.imagePath}
                    alt={inspectionType.name}
                  />
                  <p>{inspectionType.name}</p>
                </div>
              );
            })}
          </div>
        </CategoriesList>
      </Container>
      <Spacer />

      <FAQSection>
        <Container>
          <Label className="label">
            <span></span>Have a question
          </Label>
          <H2>Frequently asked questions</H2>
          <div className="sections-wrapper">
            {faqsSections.map((section, index) => {
              return (
                <div key={index}>
                  <H4>{section.title}</H4>
                  <P1>{section.excerpt}</P1>
                </div>
              );
            })}
          </div>
          <div className="second-section">
            <div>
              <H4>When are the Awards being held?</H4>
              <P1>
                The Awards will be held in June 2023. <br />
                <br /> So{" "}
                <InternalA
                  to={
                    "/" +
                    SEARCH_MASTER_ROUTE +
                    "/" +
                    SEARCH_ACCOUNTS_MASTER_ROUTE +
                    "/" +
                    SEARCH_ACCOUNTS_ROUTES.FIND
                  }
                >
                  get started
                </InternalA>{" "}
                with Rate My Inspectors today so you can get the maximum number
                of reviews before the winners are announced in June 2023.
              </P1>
            </div>
            <div className="contact-us">
              <Envelope />
              <H4>
                If you have any questions please{" "}
                <a href="mailto:info@ratemyinspectors.com">contact</a> the Rate
                My Inspectors team.
              </H4>
            </div>
          </div>
        </Container>
      </FAQSection>
      <Spacer />
    </>
  );
}
