import { useNavigate } from "react-router-dom";
import {
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import styled from "styled-components";
import { H2, H3, P, P1, PrimaryButton } from "ui-library";

const Wrapper = styled.div`
  background: #f5fafd;
  padding: 140px 0;

  h2,
  p {
    text-align: center;
    width: 730px;
    max-width: 100%;
    margin: 0 auto;
  }

  p {
    margin-top: 21px auto 14px;
  }

  .pricing-card-wrapper {
    margin-top: 56px;
    justify-content: center;
    display: flex;
    gap: 40px;

    @media (max-width: 1020px) {
      flex-direction: column;
      padding: 18px;
      align-items: center;
    }
  }
`;

const PricingCardWrapper = styled.div`
  max-width: 100%;
  width: 410px;
  background: ${(props) => (props.darkMode ? "#143a5a" : "#fff")};
  box-shadow: ${(props) =>
    props.darkMode
      ? "0px 11px 48px rgba(24, 105, 171, 0.06)"
      : "0px 11px 48px #e8f5ff"};
  padding: 60px 32px;

  position: relative;
  border-radius: 20px;

  .description {
    margin-top: 0;
    width: 85%;
    padding-left: 0;
    margin-left: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }

  .tag {
    position: absolute;
    top: 21px;
    right: 32px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    font-size: 13px;
    line-height: 22px;
    font-weight: bold;
    font-family: "HeadingFont";
    color: #fff;
    width: 110px;
    height: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .price {
    line-height: 46px;
  }

  .pricing {
    span {
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  h2 {
    text-align: left;
  }

  h3,
  h2 {
    color: ${(props) => (props.darkMode ? "#fff" : "#143A5A")};
    span {
      font-weight: normal;
    }
  }

  p {
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: left;
    color: ${(props) => (props.darkMode ? "#fff" : "#798995")};
  }

  button {
    margin-top: 18px;
    margin-bottom: 42px;
    width: 100%;
  }

  .show-features {
    cursor: pointer;
    margin: 0;
    width: auto;
    background: none;
    border: none;
    color: #028cb0;
  }

  ul {
    list-style: none;
    font-size: 20px;
    line-height: 34px;
    position: relative;
    padding-left: 0;
    color: ${(props) => (props.darkMode ? "#fff" : "#798995")};
    li {
      display: flex;
      gap: 16px;
      align-items: baseline;
      svg {
        display: block;
        width: 14px;
        height: 12px;
      }

      a {
        color: #028cb0;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .free-plus {
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
  }

  .explanation {
    font-size: 16px;
    line-height: 28px;
  }
`;

const FreeProfileButton = styled(PrimaryButton)`
  margin: 32px auto 10px;
`;

const Paragraph = styled(P1)`
  font-weight: 400;
  line-height: 34px;
`;

const TickIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.63551 6.97422C0.893506 6.08223 -0.535893 7.15502 0.206107 8.04701L3.81111 12.382C3.90144 12.4903 4.01765 12.5766 4.15012 12.6338C4.28259 12.691 4.4275 12.7173 4.57284 12.7107C4.71818 12.7041 4.85977 12.6646 4.98586 12.5957C5.11196 12.5267 5.21893 12.4302 5.29791 12.3142L13.7903 2.11945C14.5323 1.22746 13.1029 0.153352 12.3609 1.04667L4.52791 10.4531L1.63551 6.97954V6.97422Z"
        fill="#00E096"
      />
    </svg>
  );
};

const FREE_PLAN_BENEFITS = [
  "Receive quote requests",
  "List the types of inspections you offer",
  "Sell existing reports",
  "List your service areas",
  "Display your Bio",
  "Share inspection agreement",
  "Share sample reports",
  "Links to your Social Media",
  "Website and phone number displayed",
  "Reviews displayed",
];

const PAID_PLAN_BENEFITS = [
  "Priority listing",
  "Add videos to your profile",
  "Share your review link with clients",
  "Eligible for entry to Rate my inspectors Awards",
  "Share your reviews on Social Media with one click",
  "Discount on report sales",
];

export default function PricingSection() {
  const navigate = useNavigate();

  const searchForProfile = () => {
    navigate(
      "/" +
        SEARCH_MASTER_ROUTE +
        "/" +
        SEARCH_ACCOUNTS_MASTER_ROUTE +
        "/" +
        SEARCH_ACCOUNTS_ROUTES.FIND
    );
  };

  return (
    <Wrapper>
      <section>
        <H2>Pricing</H2>
        <Paragraph>
          Build trust faster and more easily within one platform. Making sure
          everything <br /> your customer needs is in one place. <br />
        </Paragraph>
        <FreeProfileButton onClick={searchForProfile}>
          Claim your free profile
        </FreeProfileButton>
        <P>Save up to 13% on report sales with the 'Inspector' plan</P>
        <div className="pricing-card-wrapper">
          <PricingCardWrapper>
            <H3>Free Profile</H3>
            <P1 className="description">
              Activate your profile, update your inspection types and service
              areas and sell existing reports.
            </P1>
            <H2 className="price">Free</H2>
            {/* <PrimaryButton onClick={searchForProfile}>
              Find your profile
            </PrimaryButton> */}
            <P1 className="explanation">
              For inspectors who want to try before they buy. Claim your
              profile, update your details and sell your reports. Connect with
              other inspectors and access resources for your business.
            </P1>

            <ul>
              {FREE_PLAN_BENEFITS.map((benefit, index) => (
                <li key={index}>
                  <TickIcon />
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
          </PricingCardWrapper>
          <PricingCardWrapper darkMode>
            <span className="tag">Most Popular</span>
            <H3>Inspector</H3>
            <P1 className="description">
              Complete your profile, request reviews, receive quote requests and
              be eligible for the Rate my Inspectors Awards.
            </P1>
            <H2 className="price pricing" style={{ fontSize: 32 }}>
              $69
              <span style={{ fontSize: 20 }}>/month</span>
            </H2>
            {/* <PrimaryButton onClick={searchForProfile}>
              Find your profile
            </PrimaryButton> */}
            <P1 className="explanation">
              For the professional inspectors or companies who want to take the
              hassle out of advertising. Activate your profile so your customers
              can see your hard-earned reviews where they matter.
            </P1>

            <P1 className="free-plus">All the Free plan features plus</P1>
            <ul>
              {PAID_PLAN_BENEFITS.map((benefit, index) => (
                <li key={index}>
                  <TickIcon />
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>
          </PricingCardWrapper>
        </div>
        <FreeProfileButton onClick={searchForProfile}>
          Claim your free profile
        </FreeProfileButton>
        <P>No credit card required. Prices include GST.</P>
      </section>
    </Wrapper>
  );
}
