import { useState } from "react";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import { FiftyFifty, Input, Loader } from "components";
import { H2, P, PrimaryButton, TertiaryButton } from "ui-library";
import { Form } from "react-final-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  CompaniesApi,
  InspectorsApi,
  UsersApi,
  UserSessionsApi,
} from "services";
import { useDispatch } from "react-redux";
import { setSession, updateUser } from "store/features/user/userSlice";
import { displayErrorMessage } from "services/api";
import {
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import { DocumentTitle } from "components/document-title";
import { setCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import { setCurrentCompany } from "store/features/companies/companiesSlice";

const FormArea = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 16px;
`;

export default function VerifyClaim() {
  const { state } = useLocation();
  const [isResendingCode, setIsResendingCode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resendVerificationCode = async () => {
    try {
      setIsResendingCode(true);
      await UsersApi.triggerAccountVerificationCode(
        state.selectedInspector.profile.email
      );
      setIsResendingCode(false);
    } catch (e) {}
  };

  if (!state?.selectedInspector) {
    return (
      <Navigate
        to={
          "/" +
          SEARCH_MASTER_ROUTE +
          "/" +
          SEARCH_ACCOUNTS_MASTER_ROUTE +
          "/" +
          SEARCH_ACCOUNTS_ROUTES.FIND
        }
      />
    );
  }

  const inspector = state.selectedInspector;
  return (
    <DocumentTitle title="Verify Claim | RateMyInspectors">
      <FiftyFifty>
        {/* Step */}
        <P style={{ color: "#143A5A", marginTop: 24 }}>
          Step 3 <span style={{ color: "#798995" }}>of 3</span>
        </P>

        <H2>Verification</H2>

        <P style={{ marginTop: 32 }}>
          {inspector.profile.phone
            ? `Please enter the 4-digit code we just sent to 04** *** ${inspector.profile.phone.slice(
                7
              )}`
            : `Please enter the 4-digit code we just sent to ${inspector.profile.email.slice(
                0,
                3
              )}********@${inspector.profile.email.slice(
                inspector.profile.email.length - 3
              )}`}
        </P>

        <Form
          onSubmit={async (values) => {
            try {
              const session = await UserSessionsApi.signInWithOtpCode(
                inspector.profile.email,
                values.token
              );
              const user = await UsersApi.getCurrentUser();
              dispatch(updateUser(user));
              dispatch(setSession(session));

              const inspectorProfile =
                await InspectorsApi.getCurrentInspectorProfile();
              dispatch(setCurrentInspector(inspectorProfile));

              const currentCompany = await CompaniesApi.getCurrentCompany();
              dispatch(setCurrentCompany(currentCompany));

              navigate("/");
              window.location.reload(false);
            } catch (e) {
              displayErrorMessage(e);
            }
          }}
          render={({ handleSubmit, submitting }) => (
            <FormArea>
              <Row>
                <Col xs={12}>
                  <Input
                    label="Verification code"
                    name="token"
                    type="number"
                    placeholder="Security code"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <PrimaryButton
                    style={{ marginTop: 24, width: "100%" }}
                    onClick={submitting ? null : handleSubmit}
                  >
                    {submitting ? <Loader loading /> : "Submit"}
                  </PrimaryButton>
                </Col>
                <Col xs={12}>
                  <TertiaryButton
                    style={{ marginTop: 16, width: "100%" }}
                    onClick={resendVerificationCode}
                  >
                    {isResendingCode ? (
                      <Loader loading color="#028cb0" />
                    ) : (
                      "Resend code"
                    )}
                  </TertiaryButton>
                </Col>
              </Row>
            </FormArea>
          )}
        />
      </FiftyFifty>
    </DocumentTitle>
  );
}
