import { useState } from "react";
import styled from "styled-components";
import { H3, P2 } from "ui-library";

const CollapseIcon = () => {
  return (
    <svg
      width="18"
      height="2"
      viewBox="0 0 18 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1H1"
        stroke="#143A5A"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const ExpandIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 8H1M9 16V8V16ZM9 8V0V8ZM9 8H17H9Z"
        stroke="#143A5A"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const Wrapper = styled.div`
  border-bottom: 1px solid #e4f0f7;
  padding: 24px 0;
  > button {
    cursor: pointer;
    background: white;
    border: 0;
    width: 100%;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-gap: 20px;
    text-align: left;
    justify-content: space-between;

    h3 {
      line-height: 1.5em;
    }
  }
`;

export default function FAQItem({
  question,
  expandedByDefault = false,
  answer,
}) {
  const [expanded, setIsExpanded] = useState(expandedByDefault);

  return (
    <Wrapper>
      <button style={{ padding: 0 }} onClick={() => setIsExpanded(!expanded)}>
        <H3>{question || "Question goes here"} </H3>
        {expanded ? <CollapseIcon /> : <ExpandIcon />}
      </button>

      {expanded && <P2 dangerouslySetInnerHTML={{ __html: answer }} />}
    </Wrapper>
  );
}
