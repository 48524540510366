const PropertyInspectorsIcon = () => (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
      fill="#E7F2F6"
    />
    <path
      d="M35 22C34.3 22 33.794 22.294 33.594 22.594C33.494 22.694 33.413 22.9 33.313 23H32C30.9 23 30 23.9 30 25H28C26.3 25 25 26.3 25 28V45C25 46.7 26.3 48 28 48H42C43.7 48 45 46.7 45 45V28C45 26.3 43.7 25 42 25H40C40 23.9 39.1 23 38 23H36.687C36.587 22.9 36.507 22.694 36.407 22.594C36.206 22.294 35.7 22 35 22ZM32 25H38V27H32V25ZM28 28H30.313C30.613 28.6 31.3 29 32 29H38C38.7 29 39.387 28.6 39.688 28H42V45H28V28ZM38.563 32.688C38.375 32.688 38.193 32.756 38.093 32.906L33.688 37.594L32 36C31.7 35.7 31.2 35.7 31 36L30.312 36.688C30.012 36.988 30.012 37.488 30.312 37.688L33.094 40.312C33.494 40.712 34.1 40.587 34.5 40.187L39.906 34.407C40.106 34.207 40.113 33.8 39.813 33.5L39.093 32.906C39.0234 32.8364 38.9408 32.7812 38.8499 32.7436C38.7589 32.706 38.6614 32.6868 38.563 32.687V32.688Z"
      fill="#028CB0"
    />
  </svg>
);

export default PropertyInspectorsIcon;
