import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  H3,
  H5,
  P,
  PrimaryButton,
  SecondaryButton,
  Checkbox,
} from "ui-library";
import { DisplayRating } from "../rating";
import { Tabs } from "../tabs";
import { InspectorDetails } from "./inspector-details";
import { InspectorReports } from "./inspector-reports";
import { InspectorReviews } from "./inspector-reviews";
import { InspectorVideos } from "./inspector-videos";
import { Loader } from "../loader";
import { InspectorsApi } from "services";
import { useNavigate } from "react-router-dom";
import { setSelectedInspectorIds } from "store/features/search/searchSlice";
import { useDispatch } from "react-redux";
import { setInspectorCode } from "store/features/customer-reviews/customerReviewsSlice";
import { getCompanyName } from "helpers";
import { PhoneNumberButton } from "components";
import {
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_INSPECTORS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import NetworkImage from "components/network-image";

const InspectorProfileContainer = styled.div`
  padding: 32px 24px;
  background-color: #ffffff;
  border-radius: 32px;
  border: 1px solid #e4f0f7;
  position: relative;

  @media only screen and (min-width: 1200px) {
    margin-left: -64px;
    margin-right: -64px;
  }

  @media only screen and (max-width: 576px) {
    padding: 24px;
    border-radius: 24px;

    ${PrimaryButton}, ${SecondaryButton} {
      width: 100%;
    }
  }

  @media only screen and (max-width: 576px) {
    border-radius: 16px;
  }

  &.active {
    box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
  }
`;

const InspectorProfileContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 810px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    > div {
      width: 100%;
    }
  }

  .checkbox {
    @media only screen and (max-width: 576px) {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
`;

const InspectorProfileAbout = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
  margin-right: 32px;

  @media only screen and (max-width: 810px) {
    margin-left: 0;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
    text-align: center;

    > div {
      margin-top: 16px;
    }
  }
`;

const InspectorProfileImage = styled(NetworkImage)`
  width: 104px;
  height: 104px;
  border-radius: 50%;

  @media only screen and (min-width: 811px) {
    margin-right: 32px;
  }

  @media only screen and (max-width: 576px) {
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }
`;

const InspectorHeaderDetailsContainer = styled.div`
  margin-left: auto;
  margin-right: 24px;

  @media only screen and (max-width: 810px) {
    margin-top: 24px;
    margin-right: auto;
  }
`;

const InspectorHeaderDetails = styled.div`
  width: 128px;

  @media only screen and (max-width: 810px) {
    width: 50%;
    text-align: center;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${PrimaryButton}, ${SecondaryButton} {
    width: 200px;
  }

  ${SecondaryButton} {
    margin-top: 8px;
  }

  @media only screen and (max-width: 810px) {
    width: 100%;
    margin-top: 32px;

    ${PrimaryButton}, ${SecondaryButton} {
      width: 100%;
    }
  }
`;

const Separator = styled.hr`
  width: calc(100% + 48px);
  height: 1px;
  border: none;
  background-color: #e4f0f7;
  margin: 32px -24px;

  @media only screen and (max-width: 768px) {
    width: calc(100% + 32px);
    margin: 32px -16px;
  }
`;

const InspectorProfileContainerBody = styled.div``;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 200px;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

/**
 * @typedef {Object} InspectorProfileProps
 * @property {string} [className]
 * @property {import('../../services').InspectorSummary} inspector
 * @property {Record<string, import('../../services').InspectionType>} types
 * @property {Record<string, import('../../services').inspectionService>} services
 * @property {Function} [onCheckOrUncheck]
 * @property {boolean} [isSelectable]
 * @property {boolean} [alwaysExpanded]
 */

/**
 *
 * @param {InspectorProfileProps} props
 */
export function InspectorProfile(props) {
  const {
    className,
    inspector,
    types,
    services,
    isSelectable = true,
    onCheckOrUncheck,
    alwaysExpanded = false,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const [showProfileDetails, setShowProfileDetails] = useState(alwaysExpanded);
  const [inspectorProfile, setInspectorProfile] = useState(null);
  const inspectionTypeNames = inspector.inspectionTypeIds.map(
    (inspectionTypeId) => {
      const inspectionType = types[inspectionTypeId];
      return inspectionType.name;
    }
  );

  useEffect(() => {
    if (showProfileDetails) {
      async function fetchInspectorDetails() {
        try {
          const response = await InspectorsApi.getInspectorProfileByCode(
            inspector.code
          );
          setInspectorProfile(response);
        } catch (e) {}
      }
      fetchInspectorDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showProfileDetails]);

  useEffect(() => {
    if (isSelectable) {
      if (isSelected) {
        onCheckOrUncheck(inspector.id, inspector);
      } else {
        onCheckOrUncheck(inspector.id, undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  if (!inspector) {
    return <noscript />;
  }

  return (
    <InspectorProfileContainer
      className={`${className} ${showProfileDetails ? "active" : ""}`}
    >
      <InspectorProfileContainerHeader>
        {isSelectable ? (
          <Checkbox
            className="checkbox"
            type="checkbox"
            checked={isSelected}
            readOnly
            onClick={() => setIsSelected((_) => !_)}
          />
        ) : (
          <noscript />
        )}

        <InspectorProfileAbout>
          <InspectorProfileImage
            src={
              inspector.company?.logoPath
                ? inspector.company.logoPath + "?w=104&h=104"
                : inspector.profile.imagePath
                ? inspector.profile.imagePath + "?w=104&h=104"
                : null
            }
            isAvatar
          />

          <div className="flex column">
            <H3 style={{ lineHeight: "32px" }}>
              {getCompanyName(inspector.company?.name) ||
                inspector.profile.fullName}
            </H3>

            <P>
              {inspectionTypeNames.length > 1
                ? inspectionTypeNames[0] +
                  ` + ${inspectionTypeNames.length - 1} more`
                : inspectionTypeNames[0]}
            </P>
          </div>
        </InspectorProfileAbout>

        {/* Reviews reports */}
        <InspectorHeaderDetailsContainer className="flex justify-center">
          <InspectorHeaderDetails>
            <H5 style={{ color: "#798995", marginBottom: 8 }}>
              {inspector.reviews.count} review
              {inspector.reviews.count > 1 ? "s" : ""}
            </H5>
            <DisplayRating rating={inspector.reviews.average} />
          </InspectorHeaderDetails>

          <InspectorHeaderDetails style={{ marginLeft: 20 }}>
            <H5>{inspector.reportCount}</H5>
            <P style={{ fontSize: "14px" }}>Reports available</P>
          </InspectorHeaderDetails>
        </InspectorHeaderDetailsContainer>

        {/* actions */}
        <ActionsContainer
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <PrimaryButton
            onClick={() => {
              dispatch(setSelectedInspectorIds([inspector.id]));
              dispatch(setInspectorCode(inspector.code));

              navigate(
                "/" +
                  SEARCH_MASTER_ROUTE +
                  "/" +
                  SEARCH_INSPECTORS_MASTER_ROUTE +
                  "/" +
                  SEARCH_INSPECTORS_ROUTES.QUOTE
              );
            }}
          >
            Get a quote
          </PrimaryButton>

          {alwaysExpanded ? (
            <noscript />
          ) : (
            <SecondaryButton
              onClick={() => setShowProfileDetails((_) => !_)}
              style={{ fontSize: "14px" }}
            >
              {showProfileDetails ? "Hide profile info" : "View profile info"}
            </SecondaryButton>
          )}
        </ActionsContainer>
      </InspectorProfileContainerHeader>

      {showProfileDetails ? (
        !inspectorProfile ? (
          <InspectorProfileContainerBody
            style={{ paddingTop: 48, textAlign: "center" }}
          >
            <Loader loading color="#EB9F22" size="medium" />
          </InspectorProfileContainerBody>
        ) : (
          <>
            <Separator />

            <InspectorProfileContainerBody>
              <Tabs
                tabs={[
                  {
                    title: "Overview",
                    content: (
                      <InspectorDetails
                        inspectorProfile={inspectorProfile}
                        inspectionTypes={types}
                        inspectionServices={services}
                      />
                    ),
                    headerAction: inspector.company?.phone ? (
                      <PhoneNumberButton
                        phoneNumber={inspector.company?.phone}
                        isCompany
                      />
                    ) : inspector.profile.phone ? (
                      <PhoneNumberButton
                        phoneNumber={inspector.profile.phone}
                      />
                    ) : null,
                  },
                  {
                    title: "Reviews",
                    content: (
                      <InspectorReviews
                        inspectorId={inspectorProfile.id}
                        inspectorCode={inspector.code}
                      />
                    ),
                    headerAction: (
                      <StyledPrimaryButton
                        onClick={() => {
                          dispatch(setInspectorCode(inspector.code));

                          navigate(
                            "/" +
                              SEARCH_MASTER_ROUTE +
                              "/" +
                              SEARCH_INSPECTORS_MASTER_ROUTE +
                              `/${inspector.code}/` +
                              SEARCH_INSPECTORS_ROUTES.RATE_INSPECTOR
                          );
                        }}
                      >
                        Write a review
                      </StyledPrimaryButton>
                    ),
                  },
                  inspector.reportCount > 0
                    ? {
                        title: "Reports",
                        content: (
                          <InspectorReports
                            inspectorId={inspectorProfile.id}
                            inspectionTypes={types}
                          />
                        ),
                      }
                    : null,
                  inspector.videoCount > 0
                    ? {
                        title: "Videos",
                        content: (
                          <InspectorVideos inspectorId={inspectorProfile.id} />
                        ),
                      }
                    : null,
                ].filter((_) => !!_)}
              />
            </InspectorProfileContainerBody>
          </>
        )
      ) : null}
    </InspectorProfileContainer>
  );
}
