import { Form } from "react-final-form";
import styled from "styled-components";
import { P } from "ui-library";

const AvailabilityContainer = styled.div`
  margin-top: 88px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AvailabilityContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* margin-right: 32px; */

  ${P}:first-of-type {
    font-weight: bold;
    color: #143a5a;
  }
`;

const Tag = styled.span`
  padding: 0 8px;
  background-color: #028cb0;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  margin-right: 12px;
`;

export function Availability() {
  return (
    <Form
      onSubmit={(values) => {}}
      render={({ handleSubmit }) => (
        <AvailabilityContainer>
          <AvailabilityContent>
            <P>
              <Tag>COMING SOON</Tag>
              <span>Available for work today?</span>
            </P>
            <P>Let your customers know you’re available today!</P>
          </AvailabilityContent>

          {/* <Toggle name="currentAvailability" /> */}
        </AvailabilityContainer>
      )}
    />
  );
}
