import { Input, Loader, Modal, Select } from "components";
import { useEffect } from "react";
import { Col, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { PaymentsApi } from "services";
import {
  setPaymentsConfig,
  paymentsConfigSelector,
} from "store/features/subscription/subscriptionSlice";
import {
  A,
  H2,
  H3,
  Page,
  Table,
  Tcell,
  TertiaryAlertButton,
  TertiaryButton,
  Thead,
  Trow,
} from "ui-library";
import openIcon from "assets/icons/open.svg";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import styled from "styled-components";
import { Form } from "react-final-form";
import { displayErrorMessage } from "services/api";
import Subscribe from "../components/subscribe";
import { DocumentTitle } from "components/document-title";
import { requiredValidator } from "components/form/validators";
import { toast } from "react-toastify";
import Toast from "components/toast";
import { useSearchParams } from "react-router-dom";

const CancelButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 48px;

  button {
    width: 200px;
    margin-left: 24px;
  }
`;

const StyledTertiaryAlertButton = styled(TertiaryAlertButton)`
  background-color: #f7ebec;
`;

const CancelReasons = [
  "None",
  "Using another account",
  "I will be back later",
  "Using a competitor's product",
  "Do not require the product",
  "Other",
];

function CardElementForm({ hasValidCard }) {
  return (
    <Subscribe
      title={hasValidCard ? "Resubscribe to Individual Plan" : null}
      trigger={
        <TertiaryButton style={{ marginLeft: 16 }}>
          {hasValidCard ? "Re-subscribe" : "Subscribe"}
        </TertiaryButton>
      }
    />
  );
}

const ValueCell = styled(Tcell)`
  color: #143a5a;
  height: auto;
`;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
export default function InspectorManageSubscriptionPage() {
  const [params] = useSearchParams();
  const hasBeenRedirectedAfterSubscribing =
    params?.get("redirect_status") === "succeeded";
  const dispatch = useDispatch();
  const paymentConfig = useSelector(paymentsConfigSelector);

  useEffect(() => {
    async function fetchPaymentInfo() {
      try {
        const response = await PaymentsApi.getInfo();
        dispatch(setPaymentsConfig(response));
      } catch (e) {}
    }
    fetchPaymentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasBeenRedirectedAfterSubscribing) {
      toast.success(<Toast title="Subscription successful" />);
    }
  }, [hasBeenRedirectedAfterSubscribing]);

  if (!paymentConfig) {
    return (
      <DocumentTitle title="Subscription">
        <Page>
          <Row style={{ marginTop: 72 }}>
            <Col xs={12} md={10} offset={{ md: 1 }}>
              <Loader loading color="#efb14e" />
            </Col>
          </Row>
        </Page>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title="Subscription">
      <Page>
        <Row style={{ marginTop: 72, marginBottom: 72 }}>
          <Col xs={12} md={10} offset={{ md: 1 }}>
            <H2 style={{ marginBottom: 24 }}>Subscription</H2>
          </Col>

          <Col xs={12} md={10} offset={{ md: 1 }}>
            <Table>
              <tbody>
                <Trow>
                  <Tcell>Current plan</Tcell>
                  <ValueCell style={{ display: "flex", alignItems: "center" }}>
                    {paymentConfig.plan !== 0 ? paymentConfig.plan : "None"}

                    {paymentConfig.planStatus === "Active" &&
                    paymentConfig.nextPaymentAmount &&
                    paymentConfig.nextPaymentAmount > 0 ? (
                      <Modal
                        title="Cancel plan"
                        trigger={
                          <TertiaryAlertButton style={{ marginLeft: 16 }}>
                            Cancel plan
                          </TertiaryAlertButton>
                        }
                      >
                        {(closeModal) => (
                          <Form
                            onSubmit={async (values) => {
                              try {
                                const response =
                                  await PaymentsApi.removePaymentInfo(
                                    values.cancelReason,
                                    values.otherCancelReason
                                  );
                                dispatch(setPaymentsConfig(response));
                                toast.success(
                                  <Toast title="Subscription cancelled" />
                                );
                                closeModal();
                              } catch (e) {
                                displayErrorMessage(e);
                              }
                            }}
                            render={({ handleSubmit, submitting, values }) => (
                              <>
                                <Select
                                  label="Please select a reason for cancelling your plan"
                                  name="cancelReason"
                                  items={[
                                    "None",
                                    "Another",
                                    "Temporary",
                                    "Competitor",
                                    "Unnecessary",
                                    "Other",
                                  ].map((item, index) => ({
                                    label: CancelReasons[index],
                                    value: item,
                                  }))}
                                />

                                {values.cancelReason === "Other" ? (
                                  <Input
                                    name="otherCancelReason"
                                    label="Please specify"
                                    validate={requiredValidator}
                                  />
                                ) : (
                                  <noscript />
                                )}

                                <CancelButtons>
                                  <TertiaryButton onClick={closeModal}>
                                    Cancel
                                  </TertiaryButton>

                                  <StyledTertiaryAlertButton
                                    onClick={handleSubmit}
                                  >
                                    {submitting ? (
                                      <Loader loading color="#ca0b0b" />
                                    ) : (
                                      "Cancel plan"
                                    )}
                                  </StyledTertiaryAlertButton>
                                </CancelButtons>
                              </>
                            )}
                          />
                        )}
                      </Modal>
                    ) : (
                      <Elements stripe={stripePromise}>
                        <CardElementForm
                          hasValidCard={paymentConfig.creditCardIsValid}
                        />
                      </Elements>
                    )}
                  </ValueCell>
                </Trow>
                <Trow>
                  <Tcell>Credit card</Tcell>
                  <ValueCell>{paymentConfig.creditCardText}</ValueCell>
                </Trow>
                <Trow>
                  <Tcell>Status</Tcell>
                  <ValueCell>
                    <span>{paymentConfig.planStatus}</span>

                    {paymentConfig.planStatus === "Active" &&
                    paymentConfig.nextPaymentAmount === 0 ? (
                      <span>
                        {" "}
                        - {paymentConfig.subscriptionDaysRemaining} days
                        remaining
                      </span>
                    ) : (
                      <noscript />
                    )}
                  </ValueCell>
                </Trow>
              </tbody>
            </Table>
          </Col>

          {paymentConfig.payments.data.length > 0 ? (
            <>
              <Col xs={12} md={10} offset={{ md: 1 }}>
                <H3 style={{ marginTop: 24 }}>Past Payments</H3>
              </Col>
              <Col xs={12} md={10} offset={{ md: 1 }}>
                <Table>
                  <Thead>
                    <Trow>
                      <Tcell>Description</Tcell>
                      <Tcell>Date</Tcell>
                      <Tcell>Receipt Link</Tcell>
                    </Trow>
                  </Thead>

                  <tbody>
                    {paymentConfig.payments.data.map((payment) => (
                      <Trow key={payment.id}>
                        <Tcell>{payment.description}</Tcell>
                        <Tcell>{new Date(payment.date).toDateString()}</Tcell>
                        <Tcell>
                          <A href={payment.receiptUrl} target="_blank">
                            <span>View</span>

                            <img
                              src={openIcon}
                              alt="open"
                              style={{ width: 14, marginLeft: 8 }}
                            />
                          </A>
                        </Tcell>
                      </Trow>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </>
          ) : (
            <noscript />
          )}
        </Row>
      </Page>
    </DocumentTitle>
  );
}
