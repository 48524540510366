import { Navigate, Route, Routes } from "react-router-dom";
import { PUBLIC_MASTER_ROUTE, PUBLIC_ROUTES } from "routes";
import Home from "./pages/home";
import About from "./pages/about";
import PropertyReports from "./pages/property-reports";
import ForAgents from "./pages/for-agents";
import ForBuyers from "./pages/for-buyers";
import ForInspectors from "./pages/for-inspectors";
import ForSellers from "./pages/for-sellers";
import FAQs from "./pages/faqs";
import TermsAndConditions from "./pages/terms-and-conditions";
import PrivacyPolicy from "./pages/privacy-policy";
import InspectionTypes from "./pages/inspection-types";
import Awards from "./pages/awards";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearSearch } from "store/features/search/searchSlice";
import { clearCustomerReviews } from "store/features/customer-reviews/customerReviewsSlice";
import BlogPage from "./pages/blogs";
import SingleBlog from "./pages/single-blog";

export default function Public() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearSearch());
    dispatch(clearCustomerReviews());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path={PUBLIC_ROUTES.HOME} element={<Home />} />
      <Route path={PUBLIC_ROUTES.ABOUT} element={<About />} />
      <Route
        path={PUBLIC_ROUTES.PROPERTY_REPORTS}
        element={<PropertyReports />}
      />
      <Route path={PUBLIC_ROUTES.FOR_AGENTS} element={<ForAgents />} />
      <Route path={PUBLIC_ROUTES.FOR_BUYERS} element={<ForBuyers />} />
      <Route path={PUBLIC_ROUTES.FOR_INSPECTORS} element={<ForInspectors />} />
      <Route path={PUBLIC_ROUTES.FOR_SELLERS} element={<ForSellers />} />
      <Route path={PUBLIC_ROUTES.FAQS} element={<FAQs />} />
      <Route path={PUBLIC_ROUTES.TERMS} element={<TermsAndConditions />} />
      <Route path={PUBLIC_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route
        path={PUBLIC_ROUTES.INSPECTION_TYPES}
        element={<InspectionTypes />}
      />
      <Route path={PUBLIC_ROUTES.AWARDS} element={<Awards />} />
      <Route path={PUBLIC_ROUTES.BLOG} element={<BlogPage />} />
      <Route path={`${PUBLIC_ROUTES.BLOG}/:id`} element={<SingleBlog />} />

      <Route
        path="*"
        element={
          <Navigate
            to={PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME}
            replace
          />
        }
      />
    </Routes>
  );
}
