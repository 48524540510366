import Banner from "components/public-pages/banner/banner";
import SecondSectionRow from "components/public-pages/second-section-row/second-section-row";
import Caurosel from "components/public-pages/caurosel/caurosel";
import { H2, P1, PrimaryButton } from "ui-library";
import HappyFamilyImage from "assets/images/happy-family.png";
import KitchenRenovationImage from "assets/images/kitchen-renovation.png";
import ForSellersBannerImage from "assets/images/for-sellers.png";
import FirstIcon from "../icons/first";
import SecondIcon from "../icons/second";
import ThirdIcon from "../icons/third";
import FAQList from "components/public-pages/faqs/list";
import { sellers as sellersFAQs } from "../helpers/faqs";
import { DocumentTitle } from "components/document-title";
import StatsCardWrapper from "components/public-pages/stats-card-wrapper";
import { Container } from "react-grid-system";
import { Spacer } from "common/helpers";
import styled from "styled-components";
import { PUBLIC_MASTER_ROUTE, PUBLIC_ROUTES } from "routes";
import { useNavigate } from "react-router-dom";

const StyledStatsCardWrapper = styled(StatsCardWrapper)`
  @media only screen and (min-width: 1020px) {
    /* margin-bottom: 32px; */

    > div {
      margin-top: -72px;
    }
  }

  @media only screen and (max-width: 1020px) {
    margin-bottom: 72px;
  }

  @media only screen and (max-width: 812px) {
    margin-bottom: 72px;
  }
`;

const items = [
  {
    icon: <FirstIcon />,
    title: "More unconditional offers",
    description: `Buyers are more likely to make an offer if they are provided with inspection reports.`,
  },
  {
    icon: <SecondIcon />,
    title: "Less negotiation",
    description: `Buyers rarely try and negotiate price reductions when they are aware of any issues before they offer.`,
  },
  {
    icon: <ThirdIcon />,
    title: "Sell for more",
    description: `Remove barriers to the sale and save the buyer the cost of the report so they can pay more for your property.`,
  },
];

export const ImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 80px;
  .image-section-div {
    padding-bottom: 54px;

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }
  img {
    width: 100%;
    object-fit: contain;
  }

  p {
    width: 80%;
  }

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;

    p {
      width: 100%;
    }
  }
`;

export const ReverseImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 80px;
  img {
    width: 100%;
    object-fit: contain;
  }

  p {
    width: 80%;
  }

  .image-section-div {
    padding-bottom: 54px;

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;

    p {
      width: 100%;
    }
  }
`;

export const BannerButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 40px;

  button:last-child {
    color: #eb9f22;
    background: white;
    border: 1px solid #eb9f22;
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;

function ForSellersPage() {
  const navigate = useNavigate();

  return (
    <>
      <DocumentTitle title="For Sellers">
        <Banner
          title="Sell your property sooner with no stress"
          excerpt={
            <P1>
              Don't let your contract of sale fall through because of unforseen
              structural or termite issues.
            </P1>
          }
          image={ForSellersBannerImage}
        >
          <BannerButtonsWrapper>
            <PrimaryButton onClick={() => navigate("/" + PUBLIC_MASTER_ROUTE)}>
              Find my inspector
            </PrimaryButton>
            <PrimaryButton
              onClick={() =>
                navigate(
                  "/" +
                    PUBLIC_MASTER_ROUTE +
                    "/" +
                    PUBLIC_ROUTES.PROPERTY_REPORTS
                )
              }
            >
              Search existing reports
            </PrimaryButton>
          </BannerButtonsWrapper>
        </Banner>
      </DocumentTitle>

      <StyledStatsCardWrapper />

      <Spacer />

      <Container>
        <SecondSectionRow items={items} />
      </Container>

      <Spacer />

      <Container>
        <ImageSection>
          <img src={HappyFamilyImage} alt="Happy Family" />
          <div className="image-section-div">
            <H2>Speed up the sales process</H2>
            <P1>
              Remove friction by supplying reports that give prospective buyers
              confidence.
            </P1>
          </div>
        </ImageSection>
      </Container>
      <Spacer />

      <Container>
        <ReverseImageSection>
          <div className="image-section-div">
            <H2>Fix any deal breakers</H2>
            <P1>
              If the inspection reveals an issue you can get the problem fixed
              or get a quote on it before the property goes to market.
            </P1>
          </div>
          <img src={KitchenRenovationImage} alt="Kitchen Renovation" />
        </ReverseImageSection>
      </Container>
      <Spacer />
      {/* 
      <Container>
        <ImageSection>
          <img src={LoungeroomImage} alt="Loungeroom" />
          <div className="image-section-div">
            <H2>inndox logbook</H2>
            <P1>
              Get an <a href="https://inndox.com">inndox</a> logbook when buying
              a past report. With inndox you can manage property related
              documents, warranties and maintenance.
            </P1>
          </div>
        </ImageSection>
      </Container>
      <Spacer /> */}

      <Caurosel />

      <Container>
        <FAQList openedOnDefault={false} faqs={sellersFAQs.slice(0, 5)} />
      </Container>
      <Spacer />
    </>
  );
}

export default ForSellersPage;
