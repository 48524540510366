import { FiftyFifty, Loader } from "components";
import { DocumentTitle } from "components/document-title";
import { useEffect } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { ACCOUNT_MASTER_ROUTE, ACCOUNT_ROUTES } from "routes";
import { CompaniesApi, UsersApi, UserSessionsApi } from "services";
import { displayErrorMessage } from "services/api";
import { setCurrentCompany } from "store/features/companies/companiesSlice";
import {
  clearIsInspector,
  clearRedirectUrl,
  selectIsInspector,
  selectRedirectUrl,
  setSession,
  updateUser,
} from "store/features/user/userSlice";
import styled from "styled-components";
import { H2 } from "ui-library";

const Title = styled(H2)`
  margin-top: 60px;

  @media screen and (max-width: 576px) {
    margin-top: 20px;
  }
`;

export default function AccountSocialSignUp() {
  const [params] = useSearchParams();
  const token = params?.get("token");
  const email = params?.get("email");
  const redirectUrl = useSelector(selectRedirectUrl);
  const isInspector = useSelector(selectIsInspector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (token && email) {
      async function signInWithSocialProvider() {
        // first sign in
        try {
          const session = await UserSessionsApi.signInWithAuthProviderViaToken(
            email,
            token
          );
          dispatch(setSession(session));
        } catch (e) {
          displayErrorMessage(e);
          navigate("/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.LOGIN);
        }

        // create company if not present
        let needsToCreateCompany = false;
        if (isInspector) {
          try {
            // Fetch the company details
            const company = await CompaniesApi.getCurrentCompany();
            dispatch(setCurrentCompany(company));
          } catch (e) {
            needsToCreateCompany = true;
          }
        }

        if (needsToCreateCompany) {
          try {
            await CompaniesApi.createCompany({
              name: "Company name",
            });

            // Fetch the company details
            const company = await CompaniesApi.getCurrentCompany();
            dispatch(setCurrentCompany(company));
          } catch (e) {
            displayErrorMessage(e);
          }
        }

        // remaining things
        try {
          const user = await UsersApi.getCurrentUser();
          const session = await UserSessionsApi.getCurrentSession();

          dispatch(updateUser(user));
          dispatch(setSession(session));
          navigate(redirectUrl);
          dispatch(clearRedirectUrl());
          dispatch(clearIsInspector());
        } catch (e) {
          displayErrorMessage(e);
        }
      }
      signInWithSocialProvider();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, email]);

  if (!token && !email) {
    return (
      <Navigate to={"/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.LOGIN} />
    );
  }

  return (
    <DocumentTitle title="Redirecting...">
      <FiftyFifty>
        <div style={{ maxWidth: 450, marginLeft: 0, width: "100%" }}>
          <Container>
            <Row>
              <Col style={{ textAlign: "left" }}>
                <Title>Please wait ...</Title>
                <Loader loading color="#EB9F22" size="medium" />
              </Col>
            </Row>
          </Container>
        </div>
      </FiftyFifty>
    </DocumentTitle>
  );
}
