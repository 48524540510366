import { BackButton, FiftyFifty, Input, Loader, PhoneNumber } from "components";
import { Col, Row } from "react-grid-system";
import { A, H2, H3, P, P1, PrimaryButton } from "ui-library";
import styled from "styled-components";
import { Form } from "react-final-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { InspectorsApi } from "services";
import { displayErrorMessage } from "services/api";
import {
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import NetworkImage from "components/network-image";
import { emailValidator, passwordValidator } from "components/form/validators";

const FormArea = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 16px;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const ProfileImage = styled(NetworkImage)`
  width: 106px;
  height: 106px;
  border-radius: 50%;
  background-color: grey;
  margin-right: 32px;
`;

export default function ClaimProfile() {
  const navigate = useNavigate();
  const { state } = useLocation();

  if (!state?.selectedInspector || !state?.inspectionTypes) {
    return (
      <Navigate
        to={
          "/" +
          SEARCH_MASTER_ROUTE +
          "/" +
          SEARCH_ACCOUNTS_MASTER_ROUTE +
          "/" +
          SEARCH_ACCOUNTS_ROUTES.FIND
        }
      />
    );
  }

  const inspector = state.selectedInspector;
  const inspectionTypes = state.inspectionTypes;
  return (
    <FiftyFifty>
      {/* Back Button */}
      <BackButton />

      {/* Step */}
      <P style={{ color: "#143A5A", marginTop: 24 }}>
        Step 2 <span style={{ color: "#798995" }}>of 3</span>
      </P>

      {/* Title */}
      <H2>Claim your profile</H2>

      <ProfileHeader>
        <ProfileImage
          src={
            inspector.profile.imagePath
              ? inspector.profile.imagePath + "?w=106&h=106"
              : null
          }
        />

        <div>
          <H3 style={{ lineHeight: "48px" }}>
            {inspector.company?.name &&
            inspector.company.name !== "Company name"
              ? inspector.company.name
              : inspector.profile.fullName}
          </H3>
          <P1 style={{ color: "#143a5a" }}>
            {inspector.inspectionTypeIds
              .map((inspectionTypeId) => {
                const inspectionType = inspectionTypes.find(
                  (_) => _.id === inspectionTypeId
                );
                return inspectionType.name;
              })
              .join(", ")}
          </P1>
        </div>
      </ProfileHeader>

      <Form
        initialValues={{
          email: inspector.profile.email,
          phone: inspector.profile.phone,
        }}
        onSubmit={async (values) => {
          try {
            await InspectorsApi.claimProfile(inspector.code, {
              ...values,
              acceptTermsConditions: true,
            });

            // navigate to verify page
            navigate(
              "/" +
                SEARCH_MASTER_ROUTE +
                "/" +
                SEARCH_ACCOUNTS_MASTER_ROUTE +
                "/" +
                SEARCH_ACCOUNTS_ROUTES.VERIFY,
              {
                state: {
                  selectedInspector: {
                    ...state.selectedInspector,
                    profile: {
                      ...state.selectedInspector.profile,
                      email: values.email,
                      phone: values.phone,
                    },
                  },
                },
              }
            );
          } catch (e) {
            displayErrorMessage(e);
          }
        }}
        render={({ handleSubmit, submitting }) => (
          <FormArea>
            <Row>
              <Col xs={12}>
                <PhoneNumber
                  label="Phone number"
                  name="phone"
                  placeholder="Your mobile number"
                  description="We will send you an SMS verification code"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Input
                  label="Email address"
                  name="email"
                  placeholder="Your email address"
                  validate={emailValidator}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Input
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Set a password"
                  validate={passwordValidator}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <PrimaryButton
                  onClick={submitting ? null : handleSubmit}
                  style={{ marginTop: 32, width: "100%" }}
                >
                  {submitting ? <Loader loading /> : "Next step"}
                </PrimaryButton>
              </Col>
              <Col xs={12}>
                <P
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    lineHeight: "20px",
                    marginTop: 16,
                  }}
                >
                  By joining Rate my Inspectors, you confirm that you accept the{" "}
                  <A href="/terms" target="_blank">
                    Terms and Conditions
                  </A>{" "}
                  and{" "}
                  <A href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </A>
                </P>
              </Col>
            </Row>
          </FormArea>
        )}
      />
    </FiftyFifty>
  );
}
