import styled, { keyframes } from "styled-components";

const rippleDefault = keyframes`
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    opacity: 0;
  }
`;

const rippleMedium = keyframes`
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    opacity: 0;
  }
`;

const rippleLarge = keyframes`
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 200px;
    height: 200px;
    opacity: 0;
  }
`;

const LoaderContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  &.medium {
    width: 100px;
    height: 100px;
  }

  &.large {
    width: 200px;
    height: 200px;
  }
`;

const Ring1 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  opacity: 1;
  opacity: 1;
  border: 2px solid ${(props) => props.color};
  border-radius: 50%;
  animation: ${rippleDefault} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  &.medium {
    animation: ${rippleMedium} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border: 10px solid ${(props) => props.color};
  }

  &.large {
    animation: ${rippleLarge} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border: 20px solid ${(props) => props.color};
  }
`;

const Ring2 = styled(Ring1)`
  animation-delay: -0.5s;

  &.medium {
    animation-delay: -0.5s;
  }

  &.large {
    animation-delay: -0.5s;
  }
`;

export function Loader({ loading = false, color = "#fff", size = "default" }) {
  if (!loading) {
    return <noscript />;
  }

  return (
    <LoaderContainer className={size}>
      <Ring1 className={size} color={color} />
      <Ring2 className={size} color={color} />
    </LoaderContainer>
  );
}
