import { groupBy } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Review, DisplayRating, RatingSlider, Loader } from "components";
import {
  inspectorReviewsSelector,
  setReviews,
  inspectorSelector,
} from "store/features/inspectors/inspectorsSlice";
import { selectAccount } from "store/features/user/userSlice";
import { InspectorReviewsApi } from "services";
import {
  H2,
  Rating,
  P,
  H3,
  H4,
  // PrimaryButton
} from "ui-library";
import { toast } from "react-toastify";
import copyIcon from "assets/icons/copy.svg";
import { DocumentTitle } from "components/document-title";
import {
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_INSPECTORS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import Toast from "components/toast";
// import { paymentsConfigSelector } from "store/features/subscription/subscriptionSlice";
// import Subscribe from "account/components/subscribe";
// import image from "assets/images/lady-cuddling-dog.png";

const HeaderContainer = styled.div`
  margin-top: 88px;
  position: relative;
  margin-bottom: 150px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 32px 0 160px;

  @media only screen and (max-width: 960px) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0px;
    margin-top: 32px;
    margin-bottom: 64px;
    height: unset;
  }

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    height: unset;
    margin-bottom: 56px;
    padding-bottom: 0px;
  }
`;

const ReviewsScorecardContainer = styled.div`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media only screen and (max-width: 576px) {
    max-width: 100%;
  }
`;

const ReviewsScorecard = styled.div`
  width: 499px;
  height: 449px;
  background: #ffffff;
  box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
  border-radius: 32px;
  padding: 40px;
  margin-top: calc(-389px - 200px);

  @media only screen and (max-width: 960px) {
    margin-top: 0;
  }

  @media only screen and (max-width: 576px) {
    box-shadow: 0 0 48px rgba(24, 105, 171, 0.06);
    max-width: 100%;
    height: unset;
    border-radius: 0;
    padding: 24px;
    margin-top: 0;
  }
`;

const StyledDisplayRating = styled(DisplayRating)`
  margin-left: 32px;
`;

const StyledRatingSlider = styled(RatingSlider)`
  margin-top: 24px;
`;

const Extendedbackground = styled.div`
  height: 389px;
  margin-left: 40%;
  margin-right: 0;
  background: #f5fafd;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  position: relative;

  @media only screen and (max-width: 960px) {
    margin-top: 32px;
    margin-left: 0;
    border-radius: 20px;
    border-radius: 0;
  }

  @media only screen and (max-width: 576px) {
    padding: 32px;
  }
`;

const ExtendedbackgroundContent = styled.div`
  max-width: 545px;
  margin-left: auto;
  margin-right: 111px;

  @media only screen and (max-width: 1300px) {
    margin-right: 32px;
    max-width: 500px;
  }

  @media only screen and (max-width: 1100px) {
    max-width: 400px;
    margin-right: 40px;
  }

  @media only screen and (max-width: 960px) {
    margin-right: auto;
    max-width: 545px;
  }

  @media only screen and (max-width: 576px) {
    margin-right: auto;
    max-width: 100%;
  }

  &.blurred {
    filter: blur(10px);
  }
`;

const BlurredCover = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  > div {
    max-width: 545px;
    margin-left: auto;
    margin-right: 111px;

    @media only screen and (max-width: 1300px) {
      margin-right: 32px;
      max-width: 500px;
    }

    @media only screen and (max-width: 1100px) {
      max-width: 400px;
      margin-right: 40px;
    }

    @media only screen and (max-width: 960px) {
      margin-right: auto;
      max-width: 545px;
    }

    @media only screen and (max-width: 576px) {
      margin-right: auto;
      max-width: 100%;
      text-align: center;
    }
  }
`;

const ReviewsSummaryContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ShareLink = styled.div`
  max-width: 393px;
  height: 48px;
  padding: 8px 16px;
  padding-right: 0;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-top: 24px;

  @media only screen and (max-width: 576px) {
    padding: 8px;
  }

  span {
    color: #143a5a;
  }
`;

const CopyButton = styled.div`
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 0;
  margin-left: 16px;

  &:hover,
  &:active {
    background-color: #d6eaf5;
  }
`;

const Separator = styled.hr`
  margin-top: 48px;
  margin-bottom: 48px;
  height: 1px;
  border: none;
  background-color: #e4f0f7;
`;

// const SubscriptionRequiredGuard = styled.div`
//   background: #f5fafd;
//   border-radius: 20px;
//   margin: 44px auto;
//   padding: 59px 52px 58px 55px;
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;
//   max-width: 1037px;
//   width: 100%;

//   @media only screen and (max-width: 576px) {
//     flex-direction: column-reverse;
//     max-width: calc(100% - 32px);
//     padding: 32px;
//     margin-top: 0px;
//   }
// `;

// const SubscriptionRequiredGuardContent = styled.div`
//   @media only screen and (max-width: 576px) {
//     margin-top: 32px;
//   }
// `;

// const SubscriptionRequiredGuardTitle = styled(H3)`
//   max-width: 388px;
//   line-height: 40px;
// `;

// const SubscriptionRequiredGuardDescription = styled(P)`
//   max-width: 404px;
//   font-size: 20px;
//   line-height: 34px;
// `;

// const SubscriptionRequiredGuardImage = styled.img`
//   max-width: 498px;

//   @media only screen and (max-width: 576px) {
//     max-width: 100%;
//   }
// `;

export default function InspectorReviewsPage() {
  const dispatch = useDispatch();
  const [isFetchingReviews, setIsFetchingReviews] = useState(true);
  const user = useSelector(selectAccount);
  const inspector = useSelector(inspectorSelector);
  const reviews = useSelector(inspectorReviewsSelector);
  const inspectorReviewUrl =
    process.env.REACT_APP_DOMAIN +
    "/" +
    SEARCH_MASTER_ROUTE +
    "/" +
    SEARCH_INSPECTORS_MASTER_ROUTE +
    "/" +
    inspector.code +
    "/" +
    SEARCH_INSPECTORS_ROUTES.RATE_INSPECTOR;
  // const paymentConfig = useSelector(paymentsConfigSelector);
  // const hasValidSubscription = paymentConfig?.planStatus === "Active";

  const fetchReviews = async () => {
    try {
      const response =
        await InspectorReviewsApi.getReviewsForCurrentInspector();
      dispatch(setReviews(response));
      setIsFetchingReviews(false);
    } catch (e) {}
  };

  const copyInspectorReviewUrl = () => {
    navigator.clipboard.writeText(inspectorReviewUrl);
    toast.success(<Toast title="Review link copied" />);
  };

  useEffect(() => {
    fetchReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REVIEWS NO LONGER REQUIRE SUBSCRIPTION - TICKET #1335
  // if (!hasValidSubscription) {
  //   return (
  //     <DocumentTitle title="Reviews">
  //       <SubscriptionRequiredGuard>
  //         <SubscriptionRequiredGuardContent>
  //           <SubscriptionRequiredGuardTitle>
  //             Already have reviews?
  //           </SubscriptionRequiredGuardTitle>
  //           <SubscriptionRequiredGuardTitle>
  //             No need to start from scratch
  //           </SubscriptionRequiredGuardTitle>
  //           <SubscriptionRequiredGuardDescription
  //             style={{ marginTop: 16, maxWidth: 404 }}
  //           >
  //             Start linking reviews from around the web creating trust and
  //             showing your prospects exactly why they should work with you.
  //           </SubscriptionRequiredGuardDescription>

  //           <Subscribe
  //             trigger={
  //               <PrimaryButton style={{ marginTop: 34 }}>
  //                 Activate subscription
  //               </PrimaryButton>
  //             }
  //           />
  //         </SubscriptionRequiredGuardContent>

  //         <SubscriptionRequiredGuardImage src={image} />
  //       </SubscriptionRequiredGuard>
  //     </DocumentTitle>
  //   );
  // }

  if (isFetchingReviews) {
    return (
      <div style={{ textAlign: "center", marginTop: 32 }}>
        <Loader color="#feba00" size="large" loading />
      </div>
    );
  }

  const averageRating =
    reviews.length > 0
      ? Number(
          (
            reviews.reduce((reduction, review) => {
              reduction += review.stars;
              return reduction;
            }, 0) / reviews.length
          ).toFixed(2)
        )
      : 0;
  const groupedReviews = groupBy(reviews, (review) => Math.round(review.stars));

  const getPercentage = (reviewCount) => {
    if (reviews.length === 0) {
      return 0;
    }

    return Math.round((reviewCount * 100) / reviews.length);
  };

  return (
    <>
      <HeaderContainer>
        {/* Blue background */}
        <Extendedbackground>
          <ExtendedbackgroundContent
            className={inspector.completionPercentage !== 100 ? "blurred" : ""}
          >
            <H4>Share your review link with customers</H4>
            <P style={{ marginTop: 16, fontSize: "20px" }}>
              Sharing your review link gives your customers an easy way to leave
              a review for your service.
            </P>
            <ShareLink>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {inspectorReviewUrl}
              </span>

              <CopyButton onClick={copyInspectorReviewUrl}>
                <img src={copyIcon} alt="copy" width={20} />
              </CopyButton>
            </ShareLink>
          </ExtendedbackgroundContent>

          {inspector.completionPercentage !== 100 ? (
            <BlurredCover>
              <div>
                <H4>
                  Please complete your profile to generate your unique review
                  link
                </H4>
              </div>
            </BlurredCover>
          ) : (
            <noscript />
          )}
        </Extendedbackground>

        {/* Scorecard */}
        <ReviewsScorecardContainer>
          <ReviewsScorecard>
            <H2>{user.fullName}</H2>
            <ReviewsSummaryContainer>
              <div style={{ marginRight: 12 }}>
                <Rating rating={averageRating} />
                <StyledDisplayRating rating={averageRating} />
              </div>
              <P>
                {reviews.length} customer review{reviews.length > 1 ? "s" : ""}
              </P>
            </ReviewsSummaryContainer>

            {/* Rating Sliders */}
            <StyledRatingSlider
              label="5 star"
              percentage={getPercentage(groupedReviews["5"]?.length || 0)}
            />
            <StyledRatingSlider
              label="4 star"
              percentage={getPercentage(groupedReviews["4"]?.length || 0)}
            />
            <StyledRatingSlider
              label="3 star"
              percentage={getPercentage(groupedReviews["3"]?.length || 0)}
            />
            <StyledRatingSlider
              label="2 star"
              percentage={getPercentage(groupedReviews["2"]?.length || 0)}
            />
            <StyledRatingSlider
              label="1 star"
              percentage={getPercentage(groupedReviews["1"]?.length || 0)}
            />
          </ReviewsScorecard>
        </ReviewsScorecardContainer>
      </HeaderContainer>

      <DocumentTitle title="Reviews">
        <Container>
          {reviews?.length > 0 ? (
            <Row>
              <Col xs={12}>
                <H3 style={{ marginBottom: 24 }}>Customer Reviews</H3>
              </Col>
              <Col xs={12}>
                <div className="restricted-content">
                  {reviews.map((review, index) => (
                    <Fragment key={`review-${index}`}>
                      <Review
                        inspectorCode={inspector.code}
                        review={review}
                        canFlag={review.source !== "Google"}
                      />

                      <Separator />
                    </Fragment>
                  ))}
                </div>
              </Col>
            </Row>
          ) : (
            <noscript />
          )}
        </Container>
      </DocumentTitle>
    </>
  );
}
