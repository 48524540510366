import { FiftyFifty, Input, Loader } from "components";
import { DocumentTitle } from "components/document-title";
import { useState } from "react";
import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_MASTER_ROUTE, ACCOUNT_ROUTES } from "routes";
import { UsersApi } from "services";
import { displayErrorMessage } from "services/api";
import styled from "styled-components";
import { H2, P, PrimaryButton } from "ui-library";

const Title = styled(H2)`
  margin-top: 40px;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

export default function ForgotPasswordPage() {
  const [hasSubmittedResetRequest, setHasSubmittedResetRequest] =
    useState(false);
  const navigate = useNavigate();

  return (
    <DocumentTitle title="Forgot Password">
      <FiftyFifty>
        {hasSubmittedResetRequest ? (
          <div style={{ maxWidth: 450, marginLeft: 0, width: "100%" }}>
            <Container>
              <Row>
                <Col>
                  <Title>Forgot password</Title>
                  <P style={{ marginTop: 16 }}>
                    Your password reset request has submitted. You will receive
                    an email if your email is registered in our system.
                  </P>

                  <PrimaryButton
                    style={{ marginTop: 32, width: 200 }}
                    onClick={() =>
                      navigate(
                        "/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.LOGIN
                      )
                    }
                  >
                    Return to login
                  </PrimaryButton>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <Form
            onSubmit={async (values) => {
              try {
                await UsersApi.initiatePasswordReset(values.email);
                setHasSubmittedResetRequest(true);
              } catch (e) {
                displayErrorMessage(e);
              }
            }}
            render={({ handleSubmit, submitting }) => (
              <div style={{ maxWidth: 450, marginLeft: 0, width: "100%" }}>
                <Container>
                  <Row>
                    <Col>
                      <Title>Forgot password?</Title>
                      <P style={{ marginTop: 16 }}>
                        Enter your email address and we will send you a link to
                        reset your password
                      </P>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Input
                        label="Email address"
                        name="email"
                        placeholder="Enter your email address"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <PrimaryButton
                        style={{ marginTop: 32 }}
                        onClick={handleSubmit}
                      >
                        <Loader loading={submitting} />

                        {submitting ? "Resetting ..." : "Reset password"}
                      </PrimaryButton>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}
          />
        )}
      </FiftyFifty>
    </DocumentTitle>
  );
}
