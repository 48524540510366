import { Loader, Modal, PaginationControls } from "components";
import { DocumentTitle } from "components/document-title";
import { useEffect, useState } from "react";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_INSPECTORS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import { InspectionReportsApi, InspectorsApi } from "services";
import {
  inspectionTypesSelector,
  servicesSelector,
} from "store/features/inspectors/inspectorsSlice";
import {
  clearSearch,
  setQuery,
  setSelectedInspectorIds as setUserSelectedInspectorIds,
} from "store/features/search/searchSlice";
import styled from "styled-components";
import { H2, H3, P, P1, Page, PrimaryButton } from "ui-library";
import SearchResult from "./search-result";
import SearchNav from "./search-nav";
import thickCheckIcon from "assets/icons/thick-check.svg";
import {
  clearCustomerReviews,
  setInspectorCode,
} from "store/features/customer-reviews/customerReviewsSlice";
import { useAuth } from "common/authentication";
import { clearSession } from "store/features/user/userSlice";
import emptyResultsImage from "assets/images/empty-results.svg";
import image from "assets/images/search-tutorial.png";
import imageMobile from "assets/images/search-tutorial-mobile.png";

const LimitedContent = styled.div`
  max-width: 1042px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 72px;

  @media only screen and (max-width: 576px) {
    max-width: 100%;
    margin-top: 56px;

    > div {
      margin-top: 56px !important;
    }

    ${H3} {
      text-align: center;
    }
  }
`;

const Copy = styled.p`
  color: #798995;
  line-height: 28px;

  @media only screen and (max-width: 576px) {
    text-align: center;
  }
`;

const SearchResultsContainer = styled.div`
  background: #ffffff;
  border: 1px solid #e4f0f7;
  border-radius: 32px;
  box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
  margin-top: 56px;
  margin-left: -16px;
  margin-right: -16px;

  > div:last-child {
    border-bottom: none;
  }

  @media only screen and (max-width: 576px) {
    border-radius: 0;
    box-shadow: none;
  }
`;

const MultipleInspectorsSelected = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00e58f;
  z-index: 1;
`;

const MultipleInspectorsSelectedContent = styled.div`
  display: flex;
  align-items: center;
  height: 80px;

  @media only screen and (max-width: 576px) {
    margin-left: -8px;
    margin-right: -8px;
  }

  @media only screen and (max-width: 320px) {
    height: 96px;
  }
`;

const ThickCheck = styled.img`
  width: 32px;
  height: 24px;

  @media only screen and (max-width: 576px) {
    width: 24px;
    height: 16px;
  }
`;

const MultipleInspectorsSelectedContentCopy = styled(P)`
  margin-left: 16px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  font-family: HeadingFont;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin-left: 12px;
  }
`;

const QuoteNextButton = styled.button`
  width: 144px;
  height: 48px;
  background-color: #03a86a;
  border: none;
  margin-left: auto;
  margin-right: 0;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
`;

const NoMatchingResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 56px;
  margin-bottom: 64px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const NoMatchingResultsContainerCopy = styled.div`
  margin-left: 16px;

  @media only screen and (max-width: 576px) {
    text-align: center;
    max-width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

const TutorialImage = styled.img`
  max-width: 100%;
`;

const NextStepsTitle = styled(H2)`
  text-align: center;
  margin-top: 32px;

  @media only screen and (max-width: 375px) {
    margin-top: -25px;
    line-height: 20px;
    font-size: 17px;
  }
`;

const NextStepsDescription = styled(P1)`
  text-align: center;
  color: #143a5a;
  margin-bottom: 48px;

  @media only screen and (max-width: 375px) {
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 22px;
    margin-top: 8px;
  }
`;

export default function SearchResults({ product }) {
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [queryString, setQueryString] = useState(searchParams.get("q"));
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const inspectionServices = useSelector(servicesSelector);
  const [inspectionTypeIds, setInspectionTypeIds] = useState([]);
  const navigate = useNavigate();
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetchingResults, setIsFetchingResults] = useState(false);
  const [errorFetchingResults, setErrorFetchingResults] = useState(false);
  const [results, setResults] = useState();
  const allInspectionTypeIds = inspectionTypes.map((_) => _.id);
  const [matchedResults, setMatchedResults] = useState();
  const [selectedInspectorIds, setSelectedInspectorIds] = useState({});
  const keyedInspectionTypes = inspectionTypes.reduce(
    (reduction, inspectionType) => {
      reduction[inspectionType.id] = inspectionType;
      return reduction;
    },
    {}
  );
  const keyedInspectionServices = inspectionServices.reduce(
    (reduction, inspectionService) => {
      reduction[inspectionService.id] = inspectionService;
      return reduction;
    },
    {}
  );

  // Methods
  const updateSearch = (updatedQuery, updatedInspectionTypeIds) => {
    if (updatedQuery) {
      setQueryString(updatedQuery);
    }

    if (updatedInspectionTypeIds) {
      setInspectionTypeIds(updatedInspectionTypeIds);
    }

    // Update page if required
    if (currentPage === 1) {
      searchForResults(undefined, updatedInspectionTypeIds);
    } else {
      setCurrentPage(1);
    }
  };

  const searchForResults = async (
    query = queryString,
    inspectionIds = inspectionTypeIds
  ) => {
    if (product === "reports") {
      _searchForReports(query, inspectionIds);
    } else {
      _searchForInspectors(query, inspectionIds);
    }
  };

  const _searchForReports = async (query, inspectionIds) => {
    if (!isFetchingResults && query) {
      try {
        setIsFetchingResults(true);
        const geocoder = new window.google.maps.Geocoder();
        const result = await geocoder.geocode({ address: query });

        if (result.results.length) {
          const place = result.results[0];

          const response = await InspectionReportsApi.getReportsByPage(
            {
              address: queryString,
              inspectionTypeIds:
                inspectionIds?.length > 0
                  ? inspectionIds.join(",")
                  : allInspectionTypeIds.join(","),
              "point.latitude": place.geometry.location.lat(),
              "point.longitude": place.geometry.location.lng(),
            },
            currentPage
          );

          setResults(response.inspectors);
          setMatchedResults(response.matchedReportCount);
        }
      } catch (e) {
        setErrorFetchingResults(e);
      } finally {
        setIsFetchingResults(false);
      }
    }
  };

  const _searchForInspectors = async (query, inspectionIds) => {
    if (!isFetchingResults && query) {
      try {
        setIsFetchingResults(true);
        const geocoder = new window.google.maps.Geocoder();
        const result = await geocoder.geocode({ address: query });

        if (result.results.length) {
          const place = result.results[0];
          const response = await InspectorsApi.getInspectorsByPage(
            {
              inspectionTypeIds:
                inspectionIds?.length > 0
                  ? inspectionIds.join(",")
                  : allInspectionTypeIds.join(","),
              "point.latitude": place.geometry.location.lat(),
              "point.longitude": place.geometry.location.lng(),
            },
            currentPage
          );

          setResults(response);

          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      } catch (e) {
        setErrorFetchingResults(e);
      } finally {
        setIsFetchingResults(false);
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
      }
    }
  };

  /**
   *
   * @param {import("services").InspectorSummary} inspector
   */
  const toggleSelectedInspector = (inspectorId, inspector) => {
    setSelectedInspectorIds((selectedInspectors) => {
      const currentlySelectedCount = Object.values(selectedInspectors).filter(
        (_) => !!_
      ).length;
      const isSelected = !!selectedInspectors[`inspector-${inspectorId}`];

      return {
        ...selectedInspectors,
        [`inspector-${inspectorId}`]: isSelected
          ? undefined
          : currentlySelectedCount <= 2
          ? inspector
          : undefined,
      };
    });
  };

  const toggleInspectorSelectionMap = (id) => {
    const inspector = results.data.find((inspector) => inspector.id === id);
    toggleSelectedInspector(id, inspector);
  };

  const selectPage = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const clearTutorial = () => {
    setShowTutorial(false);
    localStorage.setItem("rmi-inspector-search-tutorial-completed", "true");
  };

  // Effects

  useEffect(() => {
    searchForResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    navigate(window.location.pathname + `?q=${queryString}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  useEffect(() => {
    if (!queryString) {
      // Ensures that a user doesn't arrive by mistake and
      // get stuck
      navigate("/");
    } else {
      // clear existing store if any
      dispatch(clearCustomerReviews());
      dispatch(clearSearch());
      dispatch(setQuery(queryString));

      // if currently logged in user is an inspector,
      // log them out silently since this view is only
      // for customers
      if (auth?.session?.roles.indexOf("CompanyAdmin") > -1) {
        dispatch(clearSession());
      }

      // If this is the first time, show the tutorial
      const hasCompletedTutorial =
        (localStorage.getItem("rmi-inspector-search-tutorial-completed") ||
          false) === "true";
      if (!hasCompletedTutorial) {
        setShowTutorial(true);
      }
    }

    return () => {
      window.localStorage.removeItem("filters");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingResults || !results) {
    return (
      <DocumentTitle title="Search Results">
        <LimitedContent style={{ textAlign: "center", paddingTop: 64 }}>
          <Loader color="#feba00" size="large" loading />
        </LimitedContent>
      </DocumentTitle>
    );
  }

  if (errorFetchingResults) {
    return (
      <Page>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 80,
          }}
        >
          <img src={emptyResultsImage} alt="no results" width={218} />

          <H3 style={{ marginTop: 32, textAlign: "center" }}>
            There seems to be an error on our end.
          </H3>

          <P
            style={{
              textAlign: "center",
              marginTop: 16,
              fontSize: "20px",
              color: "#143A5A",
            }}
          >
            Please try again later.
          </P>

          <div
            style={{
              textAlign: "center",
              marginTop: 48,
              marginBottom: 104,
            }}
          >
            <PrimaryButton
              style={{ width: 280 }}
              onClick={() =>
                navigate("/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME)
              }
            >
              Try again
            </PrimaryButton>
          </div>
        </div>
      </Page>
    );
  }

  if (results.data.length === 0) {
    return (
      <DocumentTitle title="Search Results">
        <LimitedContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 80,
            }}
          >
            <img src={emptyResultsImage} alt="no results" width={218} />

            <P
              style={{
                textAlign: "center",
                marginTop: 32,
                fontSize: "20px",
                color: "#143A5A",
              }}
            >
              Sorry, we couldn’t find any results for
            </P>

            <H3 style={{ marginTop: 8 }}>{queryString}</H3>

            <P
              style={{
                textAlign: "center",
                marginTop: 32,
                fontSize: "20px",
                color: "#143A5A",
              }}
            >
              Please try searching again
            </P>

            <div
              style={{
                textAlign: "center",
                marginTop: 32,
                marginBottom: 104,
              }}
            >
              <PrimaryButton
                style={{ width: 280 }}
                onClick={() => navigate("/property-reports")}
              >
                Start over
              </PrimaryButton>
            </div>
          </div>
        </LimitedContent>
      </DocumentTitle>
    );
  }

  const formattedSelectedInspectorIds = Object.keys(
    selectedInspectorIds
  ).filter(
    (selectedInspectorId) => !!selectedInspectorIds[selectedInspectorId]
  );

  return (
    <DocumentTitle title="Search Results">
      <>
        <LimitedContent>
          <Container fluid>
            <Row>
              <Col xs={12}>
                {matchedResults === 0 ? (
                  <NoMatchingResultsContainer>
                    <img src={emptyResultsImage} alt="no results" width={218} />

                    <NoMatchingResultsContainerCopy>
                      <P
                        style={{
                          fontSize: "20px",
                          color: "#143A5A",
                          marginTop: 16,
                        }}
                      >
                        Sorry, we couldn’t find any matching reports for
                      </P>

                      <H3>{queryString}</H3>

                      <P
                        style={{
                          fontSize: "20px",
                          color: "#143A5A",
                        }}
                      >
                        Try requesting a quote instead
                      </P>
                    </NoMatchingResultsContainerCopy>
                  </NoMatchingResultsContainer>
                ) : (
                  <noscript />
                )}

                <H3 style={{ marginTop: 56 }}>
                  Showing inspectors for {queryString}
                </H3>
                <Copy>
                  Showing <strong>{results.data.length} inspector</strong>{" "}
                  results{" "}
                  {inspectionTypeIds.length > 0
                    ? ` for ${inspectionTypeIds
                        .map((_) => keyedInspectionTypes[_].name)
                        .join(", ")}`
                    : " across all inspection types"}
                </Copy>
                {product === "reports" ? (
                  matchedResults > 0 ? (
                    <Copy>
                      Showing <strong>{matchedResults} matched report</strong>{" "}
                      for this address.
                    </Copy>
                  ) : (
                    <noscript />
                  )
                ) : (
                  <noscript />
                )}

                <SearchResultsContainer>
                  {results.data.map((inspector) => (
                    <SearchResult
                      key={inspector.id}
                      inspector={inspector}
                      isSearchingForReports
                      types={keyedInspectionTypes}
                      services={keyedInspectionServices}
                      onClick={toggleInspectorSelectionMap}
                      isSelected={
                        !!selectedInspectorIds[`inspector-${inspector.id}`]
                      }
                    />
                  ))}
                </SearchResultsContainer>

                <div style={{ height: 56 }} />
              </Col>

              <Col xs={12}>
                {results.pageCount > 1 ? (
                  <PaginationControls
                    currentPage={currentPage}
                    pageCount={results.pageCount}
                    onPageSelected={selectPage}
                  />
                ) : (
                  <noscript />
                )}
              </Col>
            </Row>
          </Container>
        </LimitedContent>

        <SearchNav
          value={queryString}
          inspectionTypes={inspectionTypes}
          onSearchUpdated={(...args) => updateSearch(...args)}
        />

        {Object.keys(formattedSelectedInspectorIds).length ? (
          <MultipleInspectorsSelected>
            <Container fluid>
              <Row>
                <Col>
                  <MultipleInspectorsSelectedContent>
                    <ThickCheck src={thickCheckIcon} alt="check" />

                    <MultipleInspectorsSelectedContentCopy>
                      <Hidden xs>
                        {Object.keys(formattedSelectedInspectorIds).length}/3
                        Inspector(s) selected will be sent a quote request
                      </Hidden>

                      <Visible xs>
                        {Object.keys(formattedSelectedInspectorIds).length}/3
                        Inspector(s) selected
                      </Visible>
                    </MultipleInspectorsSelectedContentCopy>

                    <QuoteNextButton
                      onClick={() => {
                        const inspectorKeys = formattedSelectedInspectorIds;
                        const selectedInspectorIds = inspectorKeys.map((_) =>
                          parseInt(_.replace("inspector-", ""), 10)
                        );

                        dispatch(
                          setUserSelectedInspectorIds(selectedInspectorIds)
                        );
                        if (selectedInspectorIds.length === 1) {
                          const inspector = results.data.find(
                            (inspector) =>
                              inspector.id === selectedInspectorIds[0]
                          );
                          dispatch(setInspectorCode(inspector.code));
                        }

                        navigate(
                          "/" +
                            SEARCH_MASTER_ROUTE +
                            "/" +
                            SEARCH_INSPECTORS_MASTER_ROUTE +
                            "/" +
                            SEARCH_INSPECTORS_ROUTES.QUOTE
                        );
                      }}
                    >
                      Next
                    </QuoteNextButton>
                  </MultipleInspectorsSelectedContent>
                </Col>
              </Row>
            </Container>
          </MultipleInspectorsSelected>
        ) : null}

        {/* Turtorial for first time users */}
        <Modal isOpen={showTutorial} size="medium" onAfterClose={clearTutorial}>
          <>
            <NextStepsTitle>What to expect now</NextStepsTitle>
            <NextStepsDescription>
              Select up to <strong>3 inspectors</strong> to receive quotes from
              inspectors
            </NextStepsDescription>

            <Hidden xs>
              <TutorialImage src={image} />
            </Hidden>

            <Visible xs>
              <TutorialImage src={imageMobile} />
            </Visible>

            <PrimaryButton
              onClick={clearTutorial}
              style={{
                marginTop: 28,
                width: "100%",
                maxWidth: 317,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Next
            </PrimaryButton>
          </>
        </Modal>
      </>
    </DocumentTitle>
  );
}
