import { useState } from "react";
import { Accordion } from "components";
import styled from "styled-components";
import circleUncheckedIcon from "assets/icons/circle-unchecked.svg";
import { P, PrimaryButton, TertiaryButton } from "ui-library";
import { InspectorsApi } from "services";
import { useDispatch } from "react-redux";
import { updateCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import { toast } from "react-toastify";
import greenCheckIcon from "assets/icons/green-check.svg";
import lightGreenCheckIcon from "assets/icons/light-green-check.svg";
import redCrossIcon from "assets/icons/red-cross.svg";
import lightRedCrossIcon from "assets/icons/light-red-cross.svg";
import Toast from "components/toast";

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
`;

const CustomButton = styled(TertiaryButton)`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const YesButton = styled(CustomButton)`
  background-color: #d9fee3;
  color: #2abe52;

  &:hover {
    background-color: #d9fee3;
  }

  &.selected {
    background-color: #2abe52;
    color: #d9fee3;

    &:hover {
      background-color: #2abe52;
    }
  }
`;

const NoButton = styled(CustomButton)`
  background-color: #ffe5e2;
  color: #ea4335;

  &:hover {
    background-color: #ffe5e2;
  }

  &.selected {
    background-color: #ea4335;
    color: #ffe5e2;

    &:hover {
      background-color: #ea4335;
    }
  }
`;

/**
 * @param {Object} props
 * @param {import("services").InspectorProfile} props.inspector
 */
export function ProfessionalIndemnityWidget({ inspector }) {
  const dispatch = useDispatch();
  const [status, setStatus] = useState();

  const saveIndemnityStatus = async () => {
    try {
      await InspectorsApi.updateCurrentInspector({
        hasProfessionalIndemnity: status,
      });
      const updatedInspector = await InspectorsApi.getCurrentInspectorProfile();
      dispatch(updateCurrentInspector(updatedInspector));

      toast.success(<Toast title="Professional indemnity status updated" />);
    } catch (e) {}
  };

  if (
    inspector.hasProfessionalIndemnity !== undefined &&
    inspector.hasProfessionalIndemnity !== null
  ) {
    return <noscript />;
  }

  return (
    <StyledAccordion
      icon={circleUncheckedIcon}
      title="Professional indemnity and public liability"
    >
      <P>Do you hold a certificate of currency for these insurances?</P>

      <div className="flex align-center">
        <YesButton
          className={status === true ? "selected" : null}
          style={{ marginTop: 24 }}
          onClick={() => setStatus(true)}
        >
          <img
            src={status === true ? lightGreenCheckIcon : greenCheckIcon}
            alt="check"
            style={{ width: 18, marginRight: 8 }}
          />
          <span>Yes</span>
        </YesButton>
        <NoButton
          className={status === false ? "selected" : null}
          style={{ marginTop: 24, marginLeft: 24 }}
          onClick={() => setStatus(false)}
        >
          <img
            src={status === false ? lightRedCrossIcon : redCrossIcon}
            alt="cross"
            style={{ width: 18, marginRight: 8 }}
          />
          <span>No</span>
        </NoButton>
      </div>

      <PrimaryButton style={{ marginTop: 32 }} onClick={saveIndemnityStatus}>
        Save
      </PrimaryButton>
    </StyledAccordion>
  );
}
