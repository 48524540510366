import Item from "./item";
import styled from "styled-components";
import { H2, PrimaryButton } from "ui-library";
import { useNavigate } from "react-router-dom";
import { PUBLIC_MASTER_ROUTE, PUBLIC_ROUTES } from "routes";

const Wrapper = styled.div`
  width: ${(props) => (props.fullWidth ? "100%" : " 670px")};
  margin: 0 auto;
  max-width: 100%;

  h2 {
    text-align: center;
    margin-bottom: 32px;
  }

  > button {
    display: block;
    margin: 0 auto;
  }
`;

const Label = styled.p`
  color: #eb9f22;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  line-height: 28px;
  span {
    background: #eb9f22;
    display: block;
    width: 32px;
    height: 1px;
    margin-bottom: 4px;
  }
`;

const List = styled.div`
  margin-bottom: 40px;
`;

export default function FAQList({
  faqs,
  title,
  noCTA = false,
  fullWidth = false,
  openedOnDefault = true,
}) {
  const navigate = useNavigate();

  return (
    <Wrapper fullWidth>
      <Label>
        <span></span>Have a question
      </Label>
      <H2>{title || "Frequently asked questions"}</H2>
      <List>
        {faqs.map((each, index) => (
          <Item
            key={index}
            {...each}
            expandedByDefault={index === 0 && openedOnDefault}
          />
        ))}
      </List>
      {!noCTA && (
        <PrimaryButton
          onClick={() =>
            navigate("/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.FAQS)
          }
        >
          Read more FAQs
        </PrimaryButton>
      )}
    </Wrapper>
  );
}
