import styled from "styled-components";
import chevronUpIcon from "assets/icons/chevron-up.svg";
import chevronDownIcon from "assets/icons/chevron-down.svg";
import { useEffect, useState } from "react";
import NetworkImage from "components/network-image";
import { H3, P, PrimaryButton } from "ui-library";
import {
  DisplayRating,
  Loader,
  Modal,
  PhoneNumberButton,
  Tabs,
} from "components";
import { SelectableButton } from "./selectable-button";
import { InspectorDetails } from "components/profile/inspector-details";
import { InspectorReviews } from "components/profile/inspector-reviews";
import { useDispatch } from "react-redux";
import { setInspectorCode } from "store/features/customer-reviews/customerReviewsSlice";
import { useNavigate } from "react-router-dom";
import {
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_INSPECTORS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import { InspectorReports } from "components/profile/inspector-reports";
import { InspectorVideos } from "components/profile/inspector-videos";
import { InspectorsApi } from "services";
import { Hidden, Visible } from "react-grid-system";

const ReportSearchResultContainer = styled.div`
  border-bottom: 1px solid #e4f0f7;
  cursor: pointer;
`;

const ReportSearchResultSummary = styled.div`
  height: 186px;
  padding: 32px 24px;
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 812px) {
    flex-direction: column;
    height: auto;
    text-align: center;
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
`;

const ReportSearchResultDetails = styled.div`
  border-top: 1px solid #e4f0f7;
  padding: 32px 24px;
`;

const ExpansionIcon = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    transition: all 150ms linear;
  }

  @media only screen and (max-width: 812px) {
    position: absolute;
    right: 16px;
    top: 64px;
  }
`;

const CompanyOrInspectorImage = styled(NetworkImage)`
  width: 105px;
  height: 105px;
  border-radius: 50%;
  margin-left: 20px;
  filter: drop-shadow(0px 9px 32px rgba(89, 92, 219, 0.1));

  @media only screen and (max-width: 812px) {
    margin-left: unset;
  }

  @media only screen and (max-width: 812px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const CompanyName = styled.div`
  margin-left: 30px;
  flex-grow: 1;

  @media only screen and (max-width: 812px) {
    margin-left: unset;
    margin-top: 16px;
  }
`;

const ReportsAndReviewsSummary = styled.div`
  margin-left: 32px;
  flex-shrink: 0;

  @media only screen and (max-width: 812px) {
    margin-left: unset;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 16px;
  }
`;

const ReviewsSummary = styled.div`
  display: inline-block;
`;

const ReportsSummary = styled.div`
  margin-left: 64px;
  display: inline-block;

  @media only screen and (max-width: 812px) {
    margin-left: unset;
  }
`;

const CountSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MatchedReportBadge = styled.p`
  width: 100px;
  font-size: 12px;
  line-height: 20px;
  background-color: #00e096;
  margin-left: 8px;
  border-radius: 100px;
  font-weight: bold;
  color: #fff;
  text-align: center;
`;

const RequestQuoteButton = styled(SelectableButton)`
  margin-left: 72px;

  @media only screen and (max-width: 812px) {
    margin-top: 20px;
    margin-left: unset;
    width: 100%;
  }

  @media only screen and (max-width: 576px) {
    margin-bottom: 32px;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 200px;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

function FormattedTabs({
  inspectorProfile,
  types,
  services,
  inspector,
  startingTabIndex,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Tabs
      defaultTabIndex={startingTabIndex}
      tabs={[
        {
          title: "Overview",
          content: (
            <InspectorDetails
              inspectorProfile={inspectorProfile}
              inspectionTypes={types}
              inspectionServices={services}
            />
          ),
          headerAction: inspector.company?.phone ? (
            <PhoneNumberButton
              phoneNumber={inspector.company?.phone}
              isCompany
            />
          ) : inspector.profile.phone ? (
            <PhoneNumberButton phoneNumber={inspector.profile.phone} />
          ) : null,
        },
        {
          title: "Reviews",
          content: (
            <InspectorReviews
              inspectorId={inspectorProfile.id}
              inspectorCode={inspector.code}
            />
          ),
          headerAction: (
            <StyledPrimaryButton
              onClick={() => {
                dispatch(setInspectorCode(inspector.code));

                navigate(
                  "/" +
                    SEARCH_MASTER_ROUTE +
                    "/" +
                    SEARCH_INSPECTORS_MASTER_ROUTE +
                    `/${inspector.code}/` +
                    SEARCH_INSPECTORS_ROUTES.RATE_INSPECTOR
                );
              }}
            >
              Write a review
            </StyledPrimaryButton>
          ),
        },
        inspector.reportCount > 0
          ? {
              title: "Reports",
              content: (
                <InspectorReports
                  inspectorId={inspectorProfile.id}
                  inspectionTypes={types}
                />
              ),
            }
          : null,
        inspector.videoCount > 0
          ? {
              title: "Videos",
              content: <InspectorVideos inspectorId={inspectorProfile.id} />,
            }
          : null,
      ].filter((_) => !!_)}
    />
  );
}

/**
 * @typedef {Object} ReportSearchResultProps
 * @property {import("services").ReportSearchInspector} inspector
 * @property {Record<string|number, import("services").InspectionType>} types
 * @property {Record<string|number, import("services").InspectionService>} services
 * @property {Function} onClick
 * @property {boolean} isSelected
 */

/**
 *
 * @param {ReportSearchResultProps} props
 */
export default function SearchResult({
  inspector,
  types,
  services,
  onClick,
  isSelected,
  isSearchingForReports = false,
}) {
  const [isShowingDetailedInfo, setIsShowingDetailedInfo] = useState(false);
  const [inspectorProfile, setInspectorProfile] = useState(null);

  const inspectionTypeNames = inspector.inspectionTypeIds.map(
    (inspectionTypeId) => {
      const inspectionType = types[inspectionTypeId];
      return inspectionType.name;
    }
  );

  useEffect(() => {
    if (isShowingDetailedInfo && !inspectorProfile) {
      async function fetchInspectorDetails() {
        try {
          const response = await InspectorsApi.getInspectorProfileByCode(
            inspector.code
          );
          setInspectorProfile(response);
        } catch (e) {}
      }
      fetchInspectorDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowingDetailedInfo]);

  const summaryComponents = [
    <CompanyOrInspectorImage
      key={1}
      isAvatar
      src={
        inspector.company && inspector.company.logoPath
          ? inspector.company.logoPath + "?w=105&h=105"
          : inspector.profile.imagePath
          ? inspector.profile.imagePath + "?w=105&h=105"
          : null
      }
    />,
    <CompanyName key={2}>
      <H3 style={{ lineHeight: "normal" }}>
        {inspector.company && inspector.company.name !== "Company name"
          ? inspector.company.name
          : inspector.profile.fullName}
      </H3>
      <P style={{ marginTop: 8 }}>
        {inspectionTypeNames.length > 1
          ? inspectionTypeNames[0] + ` + ${inspectionTypeNames.length - 1} more`
          : inspectionTypeNames[0]}
      </P>
    </CompanyName>,

    <ReportsAndReviewsSummary key={3}>
      <ReviewsSummary>
        <P style={{ fontSize: "20px", lineHeight: "34px" }}>
          {inspector.reviews.count} review
          {inspector.reviews.count > 1 ? "s" : ""}
        </P>
        <DisplayRating rating={inspector.reviews.average} />
      </ReviewsSummary>

      <ReportsSummary>
        <CountSummary>
          <P
            style={{
              color: "#143A5A",
              fontSize: "20px",
              lineHeight: "34px",
            }}
          >
            {inspector.reportCount}
          </P>

          {inspector.hasMatchedReport ? (
            <MatchedReportBadge>Matched report</MatchedReportBadge>
          ) : (
            <noscript />
          )}
        </CountSummary>
        <P style={{ fontSize: "14px", lineHeight: "21px" }}>
          Report{inspector.reportCount > 1 ? "s" : ""} available
        </P>
      </ReportsSummary>
    </ReportsAndReviewsSummary>,

    <RequestQuoteButton
      key={4}
      title="Request a quote"
      isSelected={isSelected}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        onClick(inspector.id);
      }}
    />,
  ];

  return (
    <>
      <ReportSearchResultContainer>
        {/* Summary */}
        <ReportSearchResultSummary
          onClick={() => setIsShowingDetailedInfo((_) => !_)}
        >
          <Hidden xs>
            <ExpansionIcon>
              <img
                src={isShowingDetailedInfo ? chevronUpIcon : chevronDownIcon}
                alt="expand/collapse"
              />
            </ExpansionIcon>
          </Hidden>

          {summaryComponents}
        </ReportSearchResultSummary>

        {/* Detailed Info */}
        <Hidden xs>
          {isShowingDetailedInfo ? (
            !inspectorProfile ? (
              <Loader loading color="#EB9F22" size="medium" />
            ) : (
              <ReportSearchResultDetails>
                <FormattedTabs
                  inspector={inspector}
                  inspectorProfile={inspectorProfile}
                  services={services}
                  types={types}
                  startingTabIndex={
                    isSearchingForReports && inspector.reportCount > 0 ? 2 : 0
                  }
                />
              </ReportSearchResultDetails>
            )
          ) : (
            <noscript />
          )}
        </Hidden>
      </ReportSearchResultContainer>

      <Visible xs>
        {inspectorProfile && isShowingDetailedInfo ? (
          <Modal
            isOpen
            size="mobile-modal"
            onAfterClose={() => setIsShowingDetailedInfo(false)}
          >
            <>
              <ReportSearchResultSummary
                style={{ marginLeft: -24, marginRight: -24 }}
              >
                {summaryComponents}
              </ReportSearchResultSummary>

              <FormattedTabs
                inspector={inspector}
                inspectorProfile={inspectorProfile}
                services={services}
                types={types}
                startingTabIndex={
                  isSearchingForReports && inspector.reportCount > 0 ? 2 : 0
                }
              />
            </>
          </Modal>
        ) : (
          <noscript />
        )}
      </Visible>
    </>
  );
}
