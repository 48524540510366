import { api } from "./api";

export const UserSessionsApi = {
  /**
   *
   * @param {Object} userInfo
   * @param {string} userInfo.email
   * @param {string} userInfo.password
   * @returns {Promise<ApiResponse<UserSession>>}
   */
  signInWithEmail: async (userInfo) =>
    api.post("/UserSessions/byemail", userInfo),
  /**
   *
   * @param {string} email
   * @param {string} token
   * @returns {Promise<UserSession>}
   */
  signInWithAuthProviderViaToken: async (email, token) =>
    api.post(`/UserSessions/bytoken/${token}`, { email, token }),
  /**
   *
   * @param {string} email
   * @param {string} token
   * @returns {Promise<UserSession>}
   */
  signInWithOtpCode: async (email, token) =>
    api.post(`/UserSessions/bycode/${token}`, { email, token }),

  /**
   *
   * @returns {Promise<UserSession>}
   */
  getCurrentSession: async () => api.get("/UserSessions/current"),
  /**
   *
   * @param {Object} linkInfo
   * @param {'Unknown' | 'Google' | 'Facebook'} linkInfo.authProvider
   * @param {string} linkInfo.redirectUrl
   */
  linkOAuthAccountForCurrentUser: async (authProvider) =>
    api.post(`/UserSessions/byoauth/${authProvider}/link`),

  finishLinkingGoogleAccountForCurrentInspector: async (token) =>
    api.post(`/UserSessions/byoauth/Google`, {
      provider: "Google",
      token,
    }),
  /**
   *
   * @returns {Promise<void>}
   */
  signOutCurrentUser: async () => api.delete("/UserSessions/current"),

  /**
   *
   * @returns {Promise<UserSession>}
   */
  undoImpersonate: () => api.post("/UserSessions/undoimpersonate"),
};
