import Inspectors from "inspectors/home";
import { Route, Routes, useLocation } from "react-router-dom";
import Users from "users/home";
import Layout from "common/layout";
import Account from "account/home";
import {
  ACCOUNT_MASTER_ROUTE,
  INSPECTORS_MASTER_ROUTE,
  PUBLIC_MASTER_ROUTE,
  SEARCH_MASTER_ROUTE,
  USERS_MASTER_ROUTE,
} from "routes";
import { AuthProvider } from "common/authentication";
import Public from "public/home";
import { ToastContainer } from "react-toastify";
import { ToastClose } from "components";
import { RouteDecider } from "RouteDecider";
import Search from "search/home";
import { loadMarkerIO } from "common/marker-io";
import { useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

export default function App() {
  const location = useLocation();

  useEffect(() => {
    loadMarkerIO();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <AuthProvider>
      <Routes>
        <Route path="/*" element={<Layout />}>
          {/* Public Pages */}
          <Route index path={PUBLIC_MASTER_ROUTE + "/*"} element={<Public />} />

          {/* Search */}
          <Route path={SEARCH_MASTER_ROUTE + "/*"} element={<Search />} />

          {/* Inspectors */}
          <Route
            path={INSPECTORS_MASTER_ROUTE + "/*"}
            element={<Inspectors />}
          />

          {/* Users */}
          <Route path={USERS_MASTER_ROUTE + "/*"} element={<Users />} />

          {/* Account */}
          <Route path={ACCOUNT_MASTER_ROUTE + "/*"} element={<Account />} />

          {/* Default */}
          <Route path="*" element={<RouteDecider />} />
        </Route>
      </Routes>

      <ToastContainer
        position="top-center"
        closeButton={ToastClose}
        limit={1}
      />
    </AuthProvider>
  );
}
