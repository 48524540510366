import styled from "styled-components";
import facebookIcon from "assets/icons/facebook.svg";
import instagramIcon from "assets/icons/instagram.svg";
import linkedinIcon from "assets/icons/linkedin.svg";
import twitterIcon from "assets/icons/twitter.svg";
import otherLink from "assets/icons/other-link.svg";

const SocialContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 24px;
  cursor: pointer;
  text-align: center;
`;

const Social = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const SocialsContainer = styled.div`
  flex-shrink: 0;
  margin-right: 0;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const iconToNameMapping = {
  facebook: facebookIcon,
  instagram: instagramIcon,
  linkedin: linkedinIcon,
  twitter: twitterIcon,
  other: otherLink,
};

/**
 *
 * @param {Object} props
 * @param {string[]} props.socials
 * @returns
 */
export function Socials({ socials, className }) {
  const getIconFromSocial = (social) => {
    const linkedinLink = social.indexOf("linkedin.com") > -1;
    const instagramLink = social.indexOf("instagram.com") > -1;
    const twitterLink = social.indexOf("twitter.com") > -1;
    const facebookLink = social.indexOf("facebook.com") > -1;

    if (linkedinLink) {
      return "linkedin";
    } else if (instagramLink) {
      return "instagram";
    } else if (twitterLink) {
      return "twitter";
    } else if (facebookLink) {
      return "facebook";
    } else {
      return "other";
    }
  };

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  const getLinkView = (social, icon, index) => (
    <SocialContainer key={index} onClick={() => openLink(social)}>
      <Social src={iconToNameMapping[icon]} alt={icon} />
    </SocialContainer>
  );

  if (!socials?.length) {
    return <noscript />;
  }

  return (
    <SocialsContainer className={className}>
      {socials
        .filter((_) => _.length)
        .map((social, index) =>
          getLinkView(social, getIconFromSocial(social), index)
        )}
    </SocialsContainer>
  );
}
