import axios from "axios";
import Toast from "components/toast";
import { toast } from "react-toastify";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_EXTENSION,
  withCredentials: true,
  headers: {
    apiKey: process.env.REACT_APP_API_KEY,
  },
});

const handleError = (error) => {
  // console.group("handleError");
  // console.log(error);
  // console.log(error.response);
  // console.log(error.request);
  // console.log(error.response);
  // console.groupEnd();

  if (
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    // means session has expired.
    // log user out.
    store.dispatch({
      type: "CLEAR_OUT",
    });
  }

  // throw the error back to the caller
  throw error;
  // if (error.response) {
  //   // The request was made and the server responded with a status code
  //   // that falls out of the range of 2xx
  //   console.log({ data: error.response.data });
  //   console.log({ status: error.response.status });
  //   console.log({ headers: error.response.headers });

  //   if (error.response.status === 401) {
  //     // unauthorized
  //     persistor.purge();
  //     store.dispatch(clearSession());
  //   }
  // } else if (error.request) {
  //   // The request was made but no response was received
  //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //   // http.ClientRequest in node.js
  //   console.log({ request: error.request });
  //   throw error;
  // } else {
  //   // Something happened in setting up the request that triggered an Error
  //   console.log("Error", error.message);
  //   throw error;
  // }
  // console.log(error.config);
};

const displayErrorMessage = (error) => {
  if (error?.response?.data?.globalErrors?.length) {
    toast.error(
      <Toast title={error?.response?.data?.globalErrors[0]?.message} />
    );
  } else if (error?.response?.data?.fieldErrors) {
    const keys = Object.keys(error?.response?.data?.fieldErrors);

    if (keys.indexOf("address.Point") > -1) {
      toast.error(
        <Toast title="Please check the business address and try again." />
      );
    } else {
      toast.error(
        <Toast title={error?.response?.data?.fieldErrors[keys[0]][0]} />
      );
    }
  }
};

const api = {
  /**
   *
   * @param {string} url
   * @param {import("axios").AxiosRequestConfig} options
   * @returns
   */
  get: async (url, options) => {
    try {
      const response = await axiosInstance.get(url, options);
      return response.data;
    } catch (e) {
      handleError(e);
    }
  },
  /**
   *
   * @param {string} url
   * @param {Record} data
   * @param {import("axios").AxiosRequestConfig} [config]
   * @returns
   */
  post: async (url, data, config) => {
    try {
      const response = await axiosInstance.post(url, data, config);
      return response.data;
    } catch (e) {
      handleError(e);
    }
  },
  /**
   *
   * @param {string} url
   * @param {Record} data
   * @param {import("axios").AxiosRequestConfig} [config]
   * @returns
   */
  put: async (url, data, config) => {
    try {
      const response = await axiosInstance.put(url, data, config);
      return response.data;
    } catch (e) {
      handleError(e);
    }
  },
  /**
   *
   * @param {string} url
   * @param {Record} data
   * @param {import("axios").AxiosRequestConfig} [config]
   * @returns
   */
  patch: async (url, data, config) => {
    try {
      const response = await axiosInstance.patch(url, data, config);
      return response.data;
    } catch (e) {
      handleError(e);
    }
  },
  /**
   *
   * @param {string} url
   * @returns
   */
  delete: async (url, data) => {
    try {
      const response = await axiosInstance.delete(url, { data });
      return response.data;
    } catch (e) {
      handleError(e);
    }
  },
};

export { api, handleError, displayErrorMessage, axiosInstance };
