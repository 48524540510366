import { RequireAuth } from "common/authentication";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  SEARCH_INSPECTORS_ROUTES,
} from "routes";
import InspectorsList from "./pages/list";
import InspectorQuote from "./pages/quote";
import RateInspector from "./pages/rate";
import PublicInspectorProfile from "./pages/public-profile";

export default function SearchInspectors() {
  return (
    <Routes>
      <Route
        path={SEARCH_INSPECTORS_ROUTES.SEARCH_LIST}
        element={<InspectorsList />}
      />
      <Route
        path={SEARCH_INSPECTORS_ROUTES.QUOTE}
        element={
          <RequireAuth>
            <InspectorQuote />
          </RequireAuth>
        }
      />
      <Route path={":inspectorCode"} element={<PublicInspectorProfile />} />
      <Route
        path={":inspectorCode/" + SEARCH_INSPECTORS_ROUTES.RATE_INSPECTOR}
        element={
          <RequireAuth>
            <RateInspector />
          </RequireAuth>
        }
      />

      <Route
        path="*"
        element={
          <Navigate to={"/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME} />
        }
      />
    </Routes>
  );
}
