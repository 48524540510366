export default function ThirdIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#EB9F22"
      />
      <path
        d="M43.3197 28.2401C43.3197 30.3201 40.1997 30.3201 40.1997 28.2401C40.1997 26.1601 43.3197 26.1601 43.3197 28.2401Z"
        fill="#EB9F22"
      />
      <path
        d="M50.5998 23.56C50.5998 22.4571 50.1611 21.3988 49.3811 20.6188C48.601 19.8388 47.5428 19.4 46.4398 19.4H35.4304C34.3274 19.4 33.2692 19.8367 32.4892 20.6167L20.316 32.7962C19.729 33.3812 19.3999 34.1754 19.3999 35.0021C19.3999 35.8309 19.729 36.6251 20.316 37.2101L32.796 49.6901C33.381 50.2751 34.1752 50.6041 35.0019 50.6041C35.8307 50.6041 36.6249 50.2751 37.2099 49.6901L49.3831 37.5106C50.1631 36.7306 50.5998 35.6723 50.5998 34.5694L50.5998 23.56ZM34.9999 47.48L22.5199 35L26.0969 31.423L38.5769 43.903L34.9999 47.48ZM47.4798 34.5695C47.4778 34.8437 47.3681 35.1078 47.1731 35.3007L40.7829 41.6972L28.3029 29.2172L34.6931 22.8269C34.8881 22.6299 35.1542 22.5202 35.4305 22.5202H46.4399C46.7162 22.5202 46.9803 22.6299 47.1753 22.8249C47.3703 23.0199 47.48 23.284 47.48 23.5602L47.4798 34.5695Z"
        fill="#EB9F22"
      />
    </svg>
  );
}
