export default function InspectorYear() {
  return (
    <svg
      width="377"
      height="195"
      viewBox="0 0 377 195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_270_80)">
        <path
          d="M185.848 65.6902C183.3 65.6902 181.802 67.6533 181.802 69.4097V69.7369H184.316V69.4097C184.316 68.2043 185.297 67.8599 185.848 67.8599C186.434 67.8599 187.484 68.1182 187.484 69.4958C187.484 70.4946 186.864 71.1145 184.522 73.8525L181.681 77.2104V77.8647H189.998V75.7295H186.089L187.191 74.5413C189.516 72.0099 189.998 71.1317 189.998 69.4958C189.998 67.6533 188.38 65.6902 185.848 65.6902ZM196.772 65.6902C194.068 65.6902 191.554 67.6533 191.554 71.6139V72.0616C191.554 76.0222 194.068 77.9853 196.772 77.9853C199.51 77.9853 201.99 76.0222 201.99 72.0616V71.6139C201.99 67.6533 199.51 65.6902 196.772 65.6902ZM196.772 67.791C198.993 67.791 199.527 69.9952 199.527 71.6139V72.0616C199.527 73.6975 198.993 75.8672 196.772 75.8672C194.551 75.8672 194.017 73.6975 194.017 72.0616V71.6139C194.017 69.9952 194.551 67.791 196.772 67.791ZM207.625 65.6902C205.077 65.6902 203.579 67.6533 203.579 69.4097V69.7369H206.093V69.4097C206.093 68.2043 207.074 67.8599 207.625 67.8599C208.211 67.8599 209.261 68.1182 209.261 69.4958C209.261 70.4946 208.641 71.1145 206.3 73.8525L203.458 77.2104V77.8647H211.775V75.7295H207.867L208.969 74.5413C211.293 72.0099 211.775 71.1317 211.775 69.4958C211.775 67.6533 210.157 65.6902 207.625 65.6902ZM222.441 65.8107H213.779V67.9116H218.635L216.397 71.9238C216.707 71.855 216.999 71.8205 217.258 71.8205C218.911 71.8205 219.737 72.9398 219.737 73.8697C219.737 75.6261 218.291 75.9189 217.671 75.9189C216.999 75.9189 215.794 75.54 215.794 74.1969H213.331C213.331 76.3149 215.122 78.0369 217.671 78.0369C220.047 78.0369 222.2 76.4871 222.2 73.8697C222.2 72.096 221.149 70.7012 219.823 70.4257L222.441 65.8107Z"
          fill="#0D1F30"
        />
        <path
          d="M151.239 88.7599L149.358 87.1392L178.31 53.6254L135.021 43.6916L134.76 43.2384L112.41 4.89722L89.7819 43.6916L44.5078 54.0786L43.9678 51.6735L88.1967 41.5305L112.41 0L136.624 41.5305L182.856 52.1266L151.239 88.7599Z"
          fill="#EB9F22"
        />
        <path
          d="M9.7023 109.883H2.08984V103.26H9.7023V109.883ZM9.7023 139.789H2.08984V112.654H9.7023V139.789Z"
          fill="#143A5A"
        />
        <path
          d="M40.6757 124.191V139.788H33.22V124.661C33.22 120.165 31.3909 118.753 28.8302 118.753C24.2488 118.753 21.8623 123.668 21.6881 124.661V139.788H14.2324V112.653H21.6881V117.76C21.8971 116.209 24.9805 112.392 30.5025 112.392C35.5717 112.322 40.6757 115.634 40.6757 124.191Z"
          fill="#143A5A"
        />
        <path
          d="M54.4889 118.596C53.3392 118.596 52.3462 119.171 52.3462 120.339C52.3462 121.507 52.9211 121.96 54.2798 122.43L57.8857 123.738C61.5788 125.097 65.655 126.439 65.655 131.824C65.655 137.558 59.5929 140.224 54.5934 140.224C49.5939 140.224 44.5247 137.715 43.2705 132.974L49.4894 131.04C50.0011 131.932 50.7474 132.667 51.6472 133.165C52.547 133.662 53.566 133.904 54.5934 133.863C56.2134 133.863 57.8335 133.079 57.8335 131.72C57.8335 130.831 57.2586 130.203 55.8999 129.733L52.5553 128.426C47.5035 126.543 44.577 124.626 44.577 120.391C44.577 115.39 49.2281 112.409 54.3321 112.409C56.5668 112.325 58.7725 112.938 60.6447 114.161C62.5169 115.385 63.9632 117.16 64.784 119.241L58.1122 120.984C57.7876 120.29 57.2772 119.7 56.6376 119.278C55.9981 118.857 55.2543 118.621 54.4889 118.596Z"
          fill="#143A5A"
        />
        <path
          d="M95.303 126.265C95.303 135.798 88.3351 140.207 82.5691 140.207C78.3013 140.207 75.6012 137.279 75.6012 136.233V149.757H68.1455V112.653H75.6012V115.808C75.6012 114.658 78.2664 112.322 82.5691 112.322C84.3311 112.368 86.0657 112.769 87.6692 113.501C89.2726 114.233 90.7119 115.281 91.9008 116.583C93.0898 117.885 94.0039 119.413 94.5885 121.077C95.1731 122.74 95.4161 124.505 95.303 126.265ZM87.8473 126.265C87.8473 121.559 84.6595 118.788 81.3846 118.788C78.1096 118.788 75.2702 120.722 75.2702 126.265C75.2702 131.807 78.249 133.828 81.3846 133.828C84.5201 133.828 87.917 131.022 87.917 126.265H87.8473Z"
          fill="#143A5A"
        />
        <path
          d="M124.919 126.265C124.927 127.175 124.857 128.085 124.71 128.984H105.391C105.794 130.334 106.618 131.52 107.744 132.367C108.869 133.213 110.237 133.677 111.645 133.689C112.512 133.744 113.382 133.617 114.198 133.317C115.013 133.017 115.758 132.55 116.383 131.946L122.55 135.066C121.18 136.624 119.495 137.873 117.606 138.729C115.717 139.586 113.667 140.03 111.593 140.033C107.915 140.033 104.386 138.578 101.775 135.986C99.1649 133.394 97.6845 129.875 97.6569 126.195C97.5963 124.363 97.9051 122.538 98.5651 120.828C99.2251 119.118 100.223 117.559 101.498 116.243C102.774 114.927 104.301 113.882 105.989 113.17C107.678 112.458 109.492 112.093 111.324 112.098C113.156 112.102 114.968 112.476 116.653 113.197C118.337 113.918 119.86 114.971 121.128 116.293C122.397 117.615 123.387 119.18 124.038 120.893C124.689 122.606 124.989 124.433 124.919 126.265ZM105.426 123.354H117.359C117.011 122.031 116.234 120.861 115.149 120.029C114.064 119.196 112.734 118.747 111.366 118.753C110.005 118.747 108.682 119.204 107.615 120.049C106.548 120.895 105.801 122.079 105.496 123.407L105.426 123.354Z"
          fill="#143A5A"
        />
        <path
          d="M152.476 118.492L145.822 120.897C145.267 120.176 144.547 119.6 143.722 119.218C142.897 118.835 141.992 118.658 141.084 118.701C137.896 118.701 134.778 121.472 134.778 126.317C134.778 131.162 137.896 133.741 141.084 133.741C141.988 133.794 142.892 133.627 143.717 133.254C144.542 132.88 145.265 132.312 145.822 131.597L152.511 134.002C151.16 135.957 149.329 137.53 147.193 138.57C145.057 139.609 142.689 140.081 140.318 139.938C137.947 139.795 135.653 139.043 133.658 137.754C131.662 136.466 130.032 134.684 128.926 132.581C127.819 130.479 127.273 128.126 127.341 125.751C127.408 123.375 128.086 121.057 129.31 119.021C130.534 116.984 132.262 115.298 134.328 114.125C136.393 112.951 138.726 112.33 141.101 112.322C143.359 112.33 145.58 112.897 147.565 113.974C149.551 115.05 151.238 116.603 152.476 118.492Z"
          fill="#143A5A"
        />
        <path
          d="M164.357 118.701V131.075C164.357 132.277 164.827 133.689 166.604 133.689C167.797 133.735 168.986 133.521 170.088 133.061V139.161C168.44 139.87 166.653 140.192 164.862 140.102C160.542 140.102 156.936 138.638 156.936 131.127V118.701H154.271V112.653H156.936V107.965H164.391V112.653H170.21V118.701H164.357Z"
          fill="#143A5A"
        />
        <path
          d="M199.091 126.265C198.709 129.671 197.085 132.818 194.53 135.103C191.976 137.387 188.669 138.65 185.243 138.65C181.816 138.65 178.509 137.387 175.955 135.103C173.4 132.818 171.776 129.671 171.394 126.265C171.175 124.315 171.37 122.34 171.966 120.471C172.562 118.601 173.546 116.879 174.853 115.416C176.16 113.953 177.761 112.783 179.552 111.981C181.342 111.18 183.281 110.766 185.243 110.766C187.204 110.766 189.143 111.18 190.933 111.981C192.724 112.783 194.325 113.953 195.632 115.416C196.939 116.879 197.923 118.601 198.519 120.471C199.115 122.34 199.31 124.315 199.091 126.265ZM178.919 126.265C178.919 131.127 182.211 133.689 185.347 133.689C188.483 133.689 191.705 131.127 191.705 126.265C191.705 121.402 188.413 118.649 185.347 118.649C182.281 118.649 178.919 121.315 178.919 126.265Z"
          fill="#143A5A"
        />
        <path
          d="M220.935 112.601V119.572C220.071 119.439 219.196 119.386 218.322 119.416C213.689 119.416 210.083 122.901 209.612 128.129V139.876H202.209V112.654H209.612V121.367C210.187 116.366 212.696 112.392 218.322 112.392C219.198 112.367 220.074 112.437 220.935 112.601Z"
          fill="#143A5A"
        />
        <path
          d="M264.904 126.264C264.904 129.026 264.084 131.726 262.548 134.021C261.012 136.316 258.829 138.103 256.277 139.155C253.724 140.207 250.917 140.477 248.211 139.931C245.505 139.384 243.022 138.046 241.077 136.086C239.132 134.125 237.813 131.631 237.287 128.92C236.762 126.208 237.052 123.402 238.123 120.856C239.194 118.31 240.997 116.14 243.302 114.621C245.607 113.102 248.312 112.301 251.073 112.322C254.751 112.35 258.268 113.831 260.859 116.443C263.45 119.054 264.904 122.585 264.904 126.264ZM244.662 126.264C244.662 131.127 247.937 133.689 251.073 133.689C254.208 133.689 257.431 131.127 257.431 126.264C257.431 121.402 254.156 118.648 251.073 118.648C247.989 118.648 244.662 121.315 244.662 126.264Z"
          fill="#143A5A"
        />
        <path
          d="M277.096 112.026V112.654H283.193V119.015H277.096V139.789H269.64V119.015H266.156V112.654H269.64V111.869C269.64 103.887 275.737 102.894 278.977 102.894C281.404 102.921 283.791 103.518 285.945 104.637L283.541 109.865C282.244 109.371 280.87 109.112 279.482 109.098C276.939 108.959 277.096 111.137 277.096 112.026Z"
          fill="#143A5A"
        />
        <path
          d="M311.309 118.701V131.075C311.309 132.277 311.779 133.689 313.556 133.689C314.749 133.731 315.937 133.517 317.04 133.061V139.161C315.391 139.865 313.605 140.187 311.814 140.102C307.494 140.102 303.888 138.638 303.888 131.127V118.701H301.24V112.653H303.888V107.965H311.309V112.653H317.11V118.701H311.309Z"
          fill="#143A5A"
        />
        <path
          d="M346.603 124.191V139.789H339.182V124.661C339.182 120.165 337.353 118.753 334.792 118.753C330.211 118.753 327.824 123.668 327.65 124.661V139.789H320.194V103.19H327.65V117.69C327.859 116.139 330.942 112.322 336.464 112.322C341.499 112.322 346.603 115.634 346.603 124.191Z"
          fill="#143A5A"
        />
        <path
          d="M377.001 126.265C377.01 127.175 376.94 128.085 376.792 128.983H357.404C357.806 130.334 358.631 131.519 359.756 132.366C360.882 133.213 362.249 133.677 363.658 133.689C364.525 133.744 365.395 133.617 366.21 133.317C367.026 133.017 367.771 132.55 368.396 131.946L374.563 135.066C373.205 136.66 371.521 137.944 369.625 138.831C367.729 139.718 365.664 140.187 363.571 140.207C359.893 140.207 356.364 138.752 353.753 136.16C351.143 133.568 349.662 130.049 349.635 126.369C349.635 122.75 351.072 119.279 353.63 116.72C356.188 114.161 359.657 112.723 363.274 112.723C366.892 112.723 370.361 114.161 372.919 116.72C375.477 119.279 376.914 122.75 376.914 126.369L377.001 126.265ZM357.509 123.354H369.441C369.093 122.031 368.316 120.861 367.231 120.028C366.147 119.195 364.816 118.747 363.449 118.753C362.075 118.73 360.736 119.18 359.655 120.027C358.573 120.874 357.816 122.067 357.509 123.406V123.354Z"
          fill="#143A5A"
        />
        <path
          d="M29.5092 157.966L14.4933 195.017H7.1944L11.4797 184.369L0 157.966H7.35117L15.0159 175.655L22.158 157.966H29.5092Z"
          fill="#143A5A"
        />
        <path
          d="M58.2007 171.577C58.2091 172.488 58.1391 173.397 57.9917 174.296H38.586C38.9932 175.643 39.8189 176.826 40.9435 177.672C42.068 178.518 43.4327 178.984 44.8397 179.001C45.7097 179.055 46.5815 178.929 47.4001 178.629C48.2186 178.329 48.9661 177.862 49.5953 177.259L55.7445 180.378C54.3918 181.969 52.7138 183.251 50.8236 184.138C48.9334 185.025 46.875 185.496 44.7874 185.519C41.1094 185.519 37.5806 184.065 34.9701 181.473C32.3596 178.881 30.8791 175.361 30.8516 171.682C30.8516 168.062 32.2886 164.592 34.8465 162.032C37.4045 159.473 40.8738 158.036 44.4913 158.036C48.1088 158.036 51.5781 159.473 54.136 162.032C56.694 164.592 58.131 168.062 58.131 171.682L58.2007 171.577ZM38.6905 168.667H50.6405C50.2894 167.342 49.5088 166.172 48.4211 165.339C47.3334 164.506 46.0002 164.059 44.6307 164.066C43.2575 164.043 41.9181 164.493 40.8368 165.34C39.7554 166.187 38.9977 167.38 38.6905 168.719V168.667Z"
          fill="#143A5A"
        />
        <path
          d="M80.5151 161.12V157.931H87.9708V185.066H80.5151V181.459C80.5151 182.504 77.8499 185.432 73.5472 185.432C67.8509 185.432 60.8656 180.988 60.8656 171.49C60.7647 169.743 61.0145 167.993 61.6003 166.345C62.1861 164.696 63.096 163.181 64.2764 161.89C65.4567 160.598 66.8836 159.557 68.4729 158.826C70.0621 158.095 71.7816 157.69 73.5298 157.635C77.8847 157.635 80.4629 160.005 80.5151 161.12ZM68.3212 171.455C68.3212 176.3 71.5091 179.019 74.784 179.019C78.0589 179.019 80.8461 176.927 80.8461 171.455C80.8461 165.983 77.9196 163.979 74.784 163.979C71.6484 163.979 68.3038 166.784 68.3038 171.577L68.3212 171.455Z"
          fill="#143A5A"
        />
        <path
          d="M110.6 157.914V164.885C109.736 164.751 108.861 164.699 107.987 164.728C103.353 164.728 99.7473 168.214 99.277 173.442V185.188H91.8213V157.966H99.2247V166.68C99.7996 161.678 102.308 157.705 107.935 157.705C108.828 157.675 109.722 157.745 110.6 157.914Z"
          fill="#143A5A"
        />
        <path
          d="M148.767 161.626L149.237 157.966H154.968L151.641 185.101H145.823L146.345 180.709C145.875 182.905 141.973 185.467 137.635 185.467C131.904 185.467 125.441 180.971 126.591 171.525C126.919 167.842 128.566 164.403 131.23 161.84C133.894 159.278 137.394 157.766 141.084 157.583C145.526 157.635 147.878 159.639 148.767 161.626ZM132.34 171.577C131.608 177.485 135.371 180.552 139.116 180.552C142.861 180.552 147.042 177.956 147.826 171.577C148.61 165.198 145.021 162.671 141.311 162.671C138.989 162.81 136.797 163.791 135.145 165.43C133.494 167.07 132.496 169.255 132.34 171.577Z"
          fill="#EB9F22"
        />
        <path
          d="M183.343 185.101H182.402L176.148 167.673L165.609 185.101H164.669L157.108 157.966H162.787L167.02 174.191L176.81 157.966H177.855L183.708 174.191L191.896 157.966H197.575L183.343 185.101Z"
          fill="#EB9F22"
        />
        <path
          d="M218.376 161.626L218.846 157.966H224.595L221.25 185.101H215.502L216.024 180.709C215.554 182.905 211.634 185.467 207.314 185.467C201.566 185.467 195.12 180.971 196.253 171.525C196.58 167.842 198.228 164.403 200.891 161.84C203.555 159.278 207.055 157.766 210.746 157.583C215.153 157.635 217.487 159.639 218.376 161.626ZM201.949 171.577C201.217 177.485 204.98 180.552 208.725 180.552C212.47 180.552 216.669 177.956 217.435 171.577C218.202 165.198 214.631 162.671 210.92 162.671C208.599 162.814 206.409 163.796 204.759 165.435C203.108 167.073 202.109 169.256 201.949 171.577Z"
          fill="#EB9F22"
        />
        <path
          d="M245.915 157.861L245.236 163.177C244.473 163.087 243.704 163.052 242.936 163.072C240.423 163.135 238 164.022 236.04 165.596C234.079 167.171 232.689 169.345 232.084 171.786L230.481 185.049H224.732L228.077 157.914H233.808L232.815 166.052C233.434 163.645 234.837 161.512 236.803 159.991C238.768 158.47 241.183 157.647 243.668 157.652C244.421 157.662 245.173 157.732 245.915 157.861Z"
          fill="#EB9F22"
        />
        <path
          d="M270.844 185.101H265.095L265.618 180.866C265.147 183.062 261.402 185.467 257.117 185.467C255.562 185.472 254.022 185.159 252.593 184.546C251.164 183.933 249.875 183.034 248.806 181.905C247.737 180.775 246.911 179.438 246.378 177.977C245.845 176.515 245.616 174.96 245.707 173.407C245.707 166.628 250.654 157.722 260.043 157.722C264.52 157.722 267.011 159.813 267.917 161.643L269.659 148.642H275.39L270.844 185.101ZM251.386 173.198C251.304 174.116 251.413 175.041 251.704 175.915C251.996 176.789 252.464 177.594 253.08 178.279C253.697 178.964 254.447 179.515 255.285 179.897C256.124 180.279 257.032 180.485 257.953 180.5C264.52 180.5 267.185 174.296 267.185 169.608C267.185 164.536 264.05 162.637 260.67 162.637C254.521 162.619 251.386 168.876 251.386 173.198Z"
          fill="#EB9F22"
        />
        <path
          d="M286.696 162.462C285.873 162.413 285.058 162.657 284.398 163.152C283.738 163.646 283.274 164.359 283.09 165.164C282.933 166.471 283.56 167.412 285.651 168.196L288.664 169.346C292.636 170.81 296.172 172.152 295.632 177.276C295.092 182.4 288.995 185.467 284.153 185.467C279.31 185.467 274.763 182.905 274.188 178.322L279.414 176.805C279.711 177.946 280.394 178.949 281.347 179.642C282.3 180.335 283.464 180.675 284.64 180.605C286.888 180.605 289.326 179.315 289.535 177.276C289.744 175.655 288.699 174.766 286.731 174.034L283.647 172.832C278.857 171.089 276.68 169.137 277.132 165.268C277.655 160.685 282.358 157.652 287.253 157.652C289.259 157.495 291.258 158.038 292.909 159.188C294.56 160.338 295.763 162.025 296.312 163.961L290.79 165.321C290.523 164.456 289.976 163.705 289.234 163.187C288.492 162.67 287.599 162.414 286.696 162.462Z"
          fill="#EB9F22"
        />
        <path
          d="M306.659 185.101H298.42V177.015H306.659V185.101Z"
          fill="#EB9F22"
        />
        <path
          d="M52.0498 63.4024V65.9643C51.7314 65.9284 51.41 65.9284 51.0917 65.9643C50.2474 65.9732 49.4399 66.3116 48.8413 66.9073C48.2426 67.503 47.9 68.3089 47.8864 69.1536V73.4757H45.1689V63.4895H47.8864V66.6963C48.0955 64.8489 49.0187 63.4024 51.1091 63.4024C51.4219 63.3717 51.737 63.3717 52.0498 63.4024Z"
          fill="#0D1F30"
        />
        <path
          d="M59.4719 64.5877V63.42H62.2068V73.4061H59.4719V72.0816C59.239 72.5566 58.8685 72.9504 58.4086 73.2116C57.9487 73.4727 57.4208 73.5891 56.8938 73.5456C55.6269 73.4173 54.4528 72.823 53.599 71.878C52.7452 70.9329 52.2725 69.7044 52.2725 68.4305C52.2725 67.1566 52.7452 65.9281 53.599 64.983C54.4528 64.038 55.6269 63.4437 56.8938 63.3154C58.4964 63.3154 59.4545 64.1694 59.4719 64.5877ZM54.9776 68.3869C54.939 68.7291 54.9713 69.0755 55.0723 69.4047C55.1733 69.7339 55.341 70.0387 55.5649 70.3003C55.7887 70.5618 56.064 70.7745 56.3735 70.925C56.683 71.0756 57.0202 71.1608 57.3641 71.1754C58.5138 71.1754 59.5938 70.4085 59.5938 68.3869C59.5938 66.3653 58.5138 65.6508 57.3641 65.6508C57.0251 65.6658 56.6927 65.7496 56.387 65.8969C56.0813 66.0442 55.8086 66.2521 55.5855 66.5078C55.3624 66.7636 55.1935 67.062 55.089 67.385C54.9845 67.708 54.9466 68.0488 54.9776 68.3869Z"
          fill="#0D1F30"
        />
        <path
          d="M67.014 65.6507V70.1993C67.014 70.635 67.1882 71.1579 67.8327 71.1579C68.2737 71.1755 68.7133 71.0982 69.1218 70.9313V73.1795C68.5097 73.4315 67.8493 73.5446 67.1882 73.5106C65.5856 73.5106 64.2616 72.9878 64.2616 70.2167V65.6507H63.2861V63.4199H64.2616V61.0671L67.014 61.6945V63.4373H69.174V65.6681L67.014 65.6507Z"
          fill="#0D1F30"
        />
        <path
          d="M79.6264 68.4564C79.6515 68.787 79.6515 69.1191 79.6264 69.4497H72.5017C72.6449 69.95 72.9465 70.3903 73.3611 70.7046C73.7757 71.0188 74.281 71.19 74.8011 71.1925C75.1177 71.2157 75.4358 71.1735 75.7354 71.0686C76.0351 70.9636 76.31 70.7982 76.5431 70.5826L78.8077 71.7328C78.3032 72.3037 77.6841 72.7617 76.9907 73.077C76.2973 73.3923 75.5453 73.5578 74.7837 73.5627C73.433 73.5627 72.1375 73.0271 71.1808 72.0733C70.2241 71.1194 69.6843 69.8251 69.6797 68.4738C69.6797 67.1426 70.2083 65.8659 71.1491 64.9247C72.09 63.9834 73.366 63.4546 74.6966 63.4546C76.0272 63.4546 77.3032 63.9834 78.2441 64.9247C79.1849 65.8659 79.7135 67.1426 79.7135 68.4738L79.6264 68.4564ZM72.4495 67.3759H76.8392C76.7184 66.8797 76.4348 66.4383 76.0338 66.1224C75.6327 65.8064 75.1374 65.6342 74.6269 65.6331C74.1037 65.6053 73.5877 65.7657 73.1724 66.0854C72.7571 66.405 72.4697 66.8627 72.3623 67.3759H72.4495Z"
          fill="#0D1F30"
        />
        <path
          d="M102.864 68.0557V73.406H100.112V68.1776C100.112 66.644 99.2934 65.7029 98.1437 65.7029C97.5506 65.7212 96.983 65.9478 96.5402 66.343C96.0975 66.7381 95.8079 67.2766 95.7223 67.864V73.4757H92.9351V68.2474C92.9351 66.7137 92.1338 65.7726 90.9318 65.7726C89.7299 65.7726 88.8589 66.6266 88.5627 67.9337V73.5454H85.793V63.5593H88.5627V65.2672C88.7569 64.6507 89.153 64.1177 89.6871 63.7541C90.2213 63.3905 90.8623 63.2175 91.5067 63.263C92.2732 63.2192 93.0353 63.4047 93.6961 63.7957C94.3569 64.1868 94.8864 64.7658 95.2171 65.4589C95.5228 64.7725 96.0295 64.1951 96.6703 63.803C97.311 63.4109 98.0556 63.2227 98.8056 63.263C100.913 63.3327 102.864 64.5875 102.864 68.0557Z"
          fill="#0D1F30"
        />
        <path
          d="M114.466 63.4197L108.944 77.0482H106.261L107.829 73.127L103.613 63.4197H106.313L109.135 69.9377L111.748 63.4197H114.466Z"
          fill="#0D1F30"
        />
        <path
          d="M23.0638 81.1616L18.1166 78.9832L13.1693 81.1616L4.07617 89.161H18.0991H32.1395L23.0638 81.1616Z"
          fill="#EB9F22"
        />
        <path
          d="M18.0996 65.668L18.117 65.6331L18.0996 59.6379V65.6331V65.668Z"
          fill="#EB9F22"
        />
        <path
          d="M18.117 53.7474L18.0996 53.73V53.7474V59.638L18.117 53.7474Z"
          fill="#222B3F"
        />
        <path
          d="M13.1684 81.1614L13.6562 75.689L10.1025 71.5586H10.0851L0.643555 66.6091V74.016V89.1607H4.07526L13.1684 81.1614Z"
          fill="#0D1F30"
        />
        <path
          d="M10.1025 71.5588L15.3633 70.3563L18.0982 65.6682V65.6333V59.6382V53.7476L2.55974 62.4789L0.643555 63.5594V66.6093L10.0851 71.5588H10.1025Z"
          fill="#143A5A"
        />
        <path
          d="M26.1308 71.5586L22.5771 75.689L23.0649 81.1613L32.1406 89.1607H35.7465V74.0159V66.522L26.1308 71.5586Z"
          fill="#4F6473"
        />
        <path
          d="M33.7426 62.4266L18.3435 53.765L18.117 53.7476L18.0996 59.6382L18.117 65.6333L20.8694 70.3563L26.1301 71.5588L35.7459 66.5222V63.5594L33.7426 62.4266Z"
          fill="#455A6B"
        />
        <path
          d="M48.1671 78.0592H45.3799V75.7239H48.1671V78.0592ZM48.1671 89.0213H45.3799V79.07H48.1671V89.0213Z"
          fill="#0D1F30"
        />
        <path
          d="M59.5233 83.3051V89.0214H56.7884V83.4619C56.7884 81.8237 56.1265 81.3008 55.1858 81.3008C54.5861 81.3641 54.0184 81.6028 53.5536 81.9872C53.0888 82.3716 52.7477 82.8845 52.5728 83.4619V89.0214H49.8379V79.0701H52.5728V80.9523C52.8368 80.3282 53.2897 79.8026 53.8677 79.4494C54.4458 79.0961 55.12 78.933 55.7955 78.9829C57.642 78.9829 59.5233 80.1681 59.5233 83.3051Z"
          fill="#0D1F30"
        />
        <path
          d="M64.5935 81.2487C64.1754 81.2487 63.8096 81.4578 63.8096 81.8587C63.8096 82.2595 64.0187 82.4512 64.5064 82.6255L65.8303 83.1135C67.1891 83.6014 68.6872 84.1068 68.6872 86.0762C68.6872 88.0455 66.4574 89.1435 64.6284 89.1435C63.7427 89.2169 62.8575 88.9975 62.1085 88.5189C61.3596 88.0404 60.7883 87.3292 60.4824 86.4944L62.747 85.7799C62.9386 86.1053 63.2147 86.3727 63.5459 86.5537C63.8772 86.7347 64.2512 86.8227 64.6284 86.8082C65.2206 86.8082 65.8129 86.5293 65.8129 86.0239C65.8129 85.5185 65.6039 85.4836 65.0987 85.3094L63.8793 84.8214C62.0328 84.1417 60.9528 83.4272 60.9528 81.8761C60.9895 81.4455 61.1124 81.0267 61.3141 80.6445C61.5158 80.2624 61.7923 79.9247 62.127 79.6516C62.4618 79.3784 62.848 79.1754 63.2627 79.0546C63.6775 78.9338 64.1123 78.8976 64.5412 78.9482C65.3609 78.9195 66.1693 79.1453 66.8555 79.5946C67.5416 80.044 68.072 80.6948 68.3736 81.4578L65.9348 82.0852C65.8083 81.837 65.6166 81.628 65.3803 81.4806C65.1439 81.3333 64.8719 81.2531 64.5935 81.2487Z"
          fill="#0D1F30"
        />
        <path
          d="M79.6256 84.0197C79.6705 84.6662 79.5839 85.3152 79.371 85.9272C79.1581 86.5393 78.8233 87.1019 78.387 87.5808C77.9506 88.0598 77.4216 88.4452 76.8321 88.7138C76.2425 88.9824 75.6047 89.1286 74.9571 89.1435C74.4391 89.1874 73.9199 89.0725 73.4688 88.8142C73.0176 88.5558 72.6557 88.1661 72.4312 87.697V92.6465H69.6963V79.0702H72.4312V80.2379C72.4312 79.8196 73.3893 78.9482 74.9571 78.9482C75.5992 78.9659 76.2313 79.1121 76.8161 79.3783C77.4008 79.6445 77.9263 80.0252 78.3615 80.498C78.7967 80.9707 79.1327 81.5259 79.3499 82.1308C79.567 82.7357 79.6607 83.378 79.6256 84.0197ZM76.8907 84.0197C76.9191 83.6835 76.8801 83.3451 76.7757 83.0242C76.6713 82.7034 76.5038 82.4067 76.283 82.1517C76.0622 81.8967 75.7926 81.6885 75.49 81.5394C75.1875 81.3903 74.8582 81.3033 74.5216 81.2836C73.3719 81.2836 72.2918 81.9981 72.2918 84.0197C72.2918 86.0414 73.3719 86.7908 74.5216 86.7908C74.8578 86.7669 75.1857 86.6756 75.4858 86.5221C75.786 86.3686 76.0521 86.1562 76.2683 85.8976C76.4846 85.639 76.6465 85.3394 76.7445 85.0167C76.8424 84.6941 76.8743 84.355 76.8384 84.0197H76.8907Z"
          fill="#0D1F30"
        />
        <path
          d="M90.4253 84.0894C90.4507 84.42 90.4507 84.7521 90.4253 85.0828H83.318C83.4638 85.5816 83.766 86.0203 84.18 86.3341C84.5941 86.648 85.098 86.8203 85.6175 86.8256C85.9344 86.8531 86.2536 86.8129 86.5539 86.7078C86.8542 86.6026 87.1288 86.4349 87.3595 86.2156L89.624 87.3658C89.1158 87.9212 88.4964 88.3634 87.8061 88.6637C87.1158 88.964 86.3701 89.1155 85.6175 89.1086C84.3176 89.0325 83.096 88.4623 82.2026 87.5147C81.3091 86.5671 80.8115 85.3136 80.8115 84.011C80.8115 82.7083 81.3091 81.4549 82.2026 80.5072C83.096 79.5596 84.3176 78.9894 85.6175 78.9133C86.939 78.9678 88.1856 79.5422 89.086 80.5115C89.9865 81.4809 90.4678 82.7668 90.4253 84.0894ZM83.2832 83.0089H87.6556C87.5441 82.5111 87.2654 82.0666 86.866 81.7494C86.4666 81.4323 85.9706 81.2617 85.4607 81.2661C84.9513 81.2558 84.4544 81.4246 84.0565 81.743C83.6587 82.0615 83.385 82.5094 83.2832 83.0089Z"
          fill="#0D1F30"
        />
        <path
          d="M100.531 81.2136L98.1096 82.085C97.9015 81.8252 97.6357 81.6175 97.3333 81.4783C97.0309 81.3392 96.7003 81.2724 96.3677 81.2833C96.0291 81.3092 95.6992 81.4032 95.3977 81.5596C95.0962 81.716 94.8294 81.9316 94.6131 82.1936C94.3968 82.4555 94.2355 82.7584 94.1389 83.0841C94.0423 83.4098 94.0124 83.7516 94.0508 84.0892C94.0198 84.4199 94.0557 84.7534 94.1563 85.07C94.2569 85.3865 94.4202 85.6796 94.6364 85.9316C94.8526 86.1837 95.1173 86.3896 95.4147 86.5372C95.7122 86.6848 96.0362 86.7709 96.3677 86.7905C96.6996 86.8091 97.0311 86.7476 97.3343 86.6111C97.6375 86.4746 97.9034 86.2671 98.1096 86.0062L100.548 86.8951C99.9501 87.7996 99.0758 88.4868 98.0558 88.8543C97.0357 89.2217 95.9243 89.2499 94.8869 88.9346C93.8495 88.6194 92.9416 87.9775 92.2982 87.1044C91.6548 86.2314 91.3103 85.1739 91.3159 84.0892C91.2997 83.4128 91.418 82.7399 91.664 82.1097C91.91 81.4794 92.2787 80.9044 92.7487 80.4179C93.2188 79.9314 93.7807 79.5433 94.402 79.276C95.0233 79.0087 95.6914 78.8676 96.3677 78.8608C97.2028 78.8738 98.0214 79.096 98.7487 79.507C99.476 79.918 100.089 80.5047 100.531 81.2136Z"
          fill="#0D1F30"
        />
        <path
          d="M104.868 81.2835V85.8147C104.868 86.2678 105.042 86.7732 105.686 86.7732C106.127 86.7919 106.567 86.7147 106.975 86.5467V88.7774C106.369 89.0483 105.705 89.1678 105.042 89.126C103.457 89.126 102.133 88.6031 102.133 85.8495V81.2835H101.157V79.0701H102.133V76.8394L104.868 77.3448V79.0875H106.993V81.3009L104.868 81.2835Z"
          fill="#0D1F30"
        />
        <path
          d="M117.602 84.0892C117.526 85.3852 116.958 86.6031 116.014 87.4937C115.07 88.3844 113.822 88.8805 112.524 88.8805C111.227 88.8805 109.978 88.3844 109.034 87.4937C108.09 86.6031 107.522 85.3852 107.446 84.0892C107.406 83.3971 107.507 82.7039 107.744 82.0523C107.981 81.4007 108.348 80.8044 108.824 80.3C109.299 79.7956 109.873 79.3937 110.509 79.119C111.145 78.8443 111.831 78.7026 112.524 78.7026C113.217 78.7026 113.903 78.8443 114.539 79.119C115.176 79.3937 115.749 79.7956 116.225 80.3C116.7 80.8044 117.068 81.4007 117.304 82.0523C117.541 82.7039 117.643 83.3971 117.602 84.0892ZM110.181 84.0892C110.131 84.4264 110.153 84.7705 110.248 85.098C110.343 85.4256 110.507 85.7289 110.729 85.9872C110.951 86.2455 111.227 86.4528 111.537 86.5948C111.847 86.7369 112.183 86.8105 112.524 86.8105C112.865 86.8105 113.202 86.7369 113.511 86.5948C113.821 86.4528 114.097 86.2455 114.319 85.9872C114.541 85.7289 114.706 85.4256 114.8 85.098C114.895 84.7705 114.918 84.4264 114.867 84.0892C114.934 83.7436 114.924 83.3875 114.837 83.0463C114.75 82.7052 114.589 82.3874 114.365 82.1159C114.141 81.8443 113.86 81.6257 113.542 81.4755C113.223 81.3254 112.876 81.2476 112.524 81.2476C112.172 81.2476 111.825 81.3254 111.506 81.4755C111.188 81.6257 110.907 81.8443 110.683 82.1159C110.459 82.3874 110.298 82.7052 110.211 83.0463C110.124 83.3875 110.114 83.7436 110.181 84.0892Z"
          fill="#0D1F30"
        />
        <path
          d="M125.614 79.0525V81.597C125.296 81.57 124.975 81.57 124.656 81.597C123.817 81.606 123.014 81.9425 122.418 82.5349C121.823 83.1273 121.482 83.9289 121.468 84.7689V89.0735H118.751V79.1222H121.434V82.3115C121.643 80.4816 122.548 79.0351 124.639 79.0351C124.964 79.0079 125.291 79.0138 125.614 79.0525Z"
          fill="#0D1F30"
        />
        <path
          d="M130.143 81.2484C129.725 81.2484 129.359 81.4575 129.359 81.8584C129.359 82.2592 129.568 82.4509 130.056 82.6252L131.38 83.1132C132.739 83.6012 134.237 84.1066 134.237 86.0759C134.237 88.0453 132.007 89.1432 130.178 89.1432C129.293 89.2166 128.407 88.9972 127.658 88.5187C126.909 88.0401 126.338 87.3289 126.032 86.4942L128.297 85.7796C128.488 86.105 128.764 86.3724 129.096 86.5534C129.427 86.7345 129.801 86.8224 130.178 86.8079C130.77 86.8079 131.363 86.529 131.363 86.0236C131.363 85.5182 131.154 85.4834 130.648 85.3091L129.429 84.8211C127.583 84.1414 126.503 83.4269 126.503 81.8758C126.539 81.4469 126.662 81.0296 126.862 80.6488C127.063 80.2679 127.338 79.931 127.671 79.6582C128.004 79.3853 128.388 79.1818 128.8 79.0598C129.213 78.9379 129.646 78.8998 130.074 78.9479C130.896 78.9155 131.708 79.1396 132.398 79.5892C133.088 80.0388 133.621 80.6917 133.923 81.4575L131.485 82.0849C131.358 81.8368 131.166 81.6278 130.93 81.4804C130.694 81.333 130.422 81.2528 130.143 81.2484Z"
          fill="#0D1F30"
        />
        <path
          d="M137.81 89.0211H134.796V86.0583H137.81V89.0211Z"
          fill="#E7A523"
        />
      </g>
      <defs>
        <clipPath id="clip0_270_80">
          <rect width="377" height="195" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
