import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import ClaimProfile from "./pages/claim";
import FindProfile from "./pages/find";
import VerifyClaim from "./pages/verify";

export default function SearchAccounts() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "CLEAR_OUT",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path={SEARCH_ACCOUNTS_ROUTES.FIND} element={<FindProfile />} />
      <Route path={SEARCH_ACCOUNTS_ROUTES.CLAIM} element={<ClaimProfile />} />
      <Route path={SEARCH_ACCOUNTS_ROUTES.VERIFY} element={<VerifyClaim />} />

      <Route
        path="*"
        element={
          <Navigate
            to={
              "/" +
              SEARCH_MASTER_ROUTE +
              "/" +
              SEARCH_ACCOUNTS_MASTER_ROUTE +
              "/" +
              SEARCH_ACCOUNTS_ROUTES.FIND
            }
          />
        }
      />
    </Routes>
  );
}
