import { Hidden, Visible } from "react-grid-system";
import styled from "styled-components";
import { P } from "ui-library";

const RatingSliderContainer = styled.div``;

const SliderContainer = styled.div``;

const SliderBackground = styled.div`
  width: 306px;
  height: 16px;
  border-radius: 10px;
  background-color: #e5e5e5;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

const Slider = styled.div`
  height: 16px;
  border-radius: 10px;
  background-color: #feba00;
`;

export function RatingSlider({ percentage, label, className }) {
  return (
    <RatingSliderContainer>
      <SliderContainer
        className={`flex align-center justify-between ${className}`}
      >
        <P>{label}</P>
        <Hidden xs>
          <SliderBackground>
            <Slider style={{ width: `${percentage}%` }} />
          </SliderBackground>
        </Hidden>
        <P>{percentage}%</P>
      </SliderContainer>
      <Visible xs>
        <SliderBackground>
          <Slider style={{ width: `${percentage}%` }} />
        </SliderBackground>
      </Visible>
    </RatingSliderContainer>
  );
}
