import styled from "styled-components";
import { H2, P1, PrimaryButton, SecondaryButton } from "ui-library";
import image from "../../../assets/images/onboarding-step-2.png";
import { Hidden, Visible } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import { Form } from "react-final-form";
import { TextArea } from "components";
import { minLengthValidator } from "components/form/validators";

const OnboardingStep = styled.div`
  height: calc(100vh - 104px);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 576px) {
    overflow: auto;
    height: calc(100dvh - 56px);
  }
`;

const OnboardingStepContainer = styled.div`
  flex-grow: 1;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 91px;
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    padding-top: 35px;
  }
`;

const StepContent = styled.div`
  display: flex;
`;

const LeftContent = styled.div`
  @media only screen and (max-width: 576px) {
    padding-bottom: 32px;
  }
`;

const StepLabel = styled.p`
  color: #143a5a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;

  span {
    color: #798995;
  }
`;

const RightContent = styled.div`
  margin-left: 57px;

  img {
    max-width: 450px;
  }
`;

const Action = styled.div`
  background-color: white;

  @media only screen and (max-width: 576px) {
    position: sticky;
    bottom: 0;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;

  @media only screen and (max-width: 576px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 5px;
  background-color: #e4f0f7;
`;

const Progress = styled.div`
  height: 5px;
  width: 67%;
  background-color: #00e096;
`;

export default function OnboardingStep2() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <Form
      initialValues={{
        ...state,
      }}
      onSubmit={(values) => {
        navigate(
          "/" +
            INSPECTORS_MASTER_ROUTE +
            "/" +
            INSPECTOR_ROUTES.ONBOARDING_STEP_3,
          {
            state: values,
          }
        );
      }}
      render={({ handleSubmit, values }) => (
        <OnboardingStep>
          <OnboardingStepContainer>
            <StepContent>
              <LeftContent>
                <StepLabel>
                  Step 2 <span>of 3</span>
                </StepLabel>

                <H2>
                  Great. Now write a bio to tell your customers about yourself
                </H2>

                <P1
                  style={{
                    marginTop: 19,
                    color: "#143A5A",
                    marginBottom: 27,
                  }}
                >
                  Provide customers with an instant insight about yourself.
                  Showcase the services you offer and demonstrate how you can
                  assist them with the specific inspection they're seeking.
                  Remember, you can always edit later.
                </P1>

                <TextArea
                  name="bio"
                  rows={10}
                  placeholder="Enter your experiences and skills. This will be displayed on your profile for clients to see."
                  validate={minLengthValidator(150)}
                />
              </LeftContent>

              <Hidden xs>
                <RightContent>
                  <img src={image} style={{ marginTop: 30 }} alt="cover" />
                </RightContent>
              </Hidden>
            </StepContent>
          </OnboardingStepContainer>

          <Action>
            <ProgressBar>
              <Progress />
            </ProgressBar>
            <Footer>
              <SecondaryButton
                onClick={() =>
                  navigate(
                    "/" +
                      INSPECTORS_MASTER_ROUTE +
                      "/" +
                      INSPECTOR_ROUTES.ONBOARDING_STEP_1,
                    { state: values || state }
                  )
                }
              >
                Back
              </SecondaryButton>

              <Hidden xs>
                <PrimaryButton onClick={handleSubmit}>
                  Next, add your photo and location
                </PrimaryButton>
              </Hidden>

              <Visible xs>
                <PrimaryButton onClick={handleSubmit}>Next</PrimaryButton>
              </Visible>
            </Footer>
          </Action>
        </OnboardingStep>
      )}
    />
  );
}
