import { H2, H4, P1, PrimaryButton } from "ui-library";
import Banner from "components/public-pages/banner/banner";
import faqs from "../helpers/faqs-summary";
import PricingSection from "../components/pricing";
import SecondSectionRow from "components/public-pages/second-section-row/second-section-row";

// Assets
import ElectricalInspectionsImage from "assets/images/electrical-inspections.png";
import InspectorBanner from "assets/images/inspector-banner.png";
import VerifiedIcon from "../icons/verified";
import RMIIcon from "../icons/rmi";
import ProfileIcon from "../icons/profile";
import { useNavigate } from "react-router-dom";
import { DocumentTitle } from "components/document-title";
import StatsCardWrapper from "components/public-pages/stats-card-wrapper";
import { Form } from "react-final-form";
import { Input, Loader } from "components";
import { api } from "services/api";
import { toast } from "react-toastify";
import { Spacer } from "common/helpers";
import { Container } from "react-grid-system";
import styled from "styled-components";
import {
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import {
  emailValidator,
  phoneValidator,
  requiredValidator,
} from "components/form/validators";
import Toast from "components/toast";

const StyledStatsCardWrapper = styled(StatsCardWrapper)`
  @media only screen and (min-width: 1020px) {
    /* margin-bottom: 32px; */

    > div {
      margin-top: -72px;
    }
  }

  @media only screen and (max-width: 1020px) {
    margin-bottom: 72px;
  }

  @media only screen and (max-width: 812px) {
    margin-bottom: 72px;
  }
`;

export const ElectricalPersonSection = styled.section`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  margin-right: -16px;

  img {
    display: block;
    height: 500px;
    margin-right: 0;
    margin-left: auto;
  }

  @media screen and (max-width: 812px) {
    display: flex;
    flex-direction: column;
    margin-right: unset;

    h2 {
      text-align: center;
    }

    button {
      width: 100%;
    }

    img {
      margin-right: auto;
      height: 350px;
      margin-top: 40px;
    }
  }
`;

export const FAQSection = styled.section`
  h2 {
    text-align: center;
  }
  .label {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 28px;
    span {
      width: 32px;
      height: 1px;
      margin-bottom: 8px;
      background: #eb9f22;
      display: block;
    }
    p {
      margin-top: -8px;
      color: #eb9f22;
    }
  }
  .wrapper {
    margin-top: 56px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    @media screen and (max-width: 1020px) {
      grid-template-columns: 1fr;
    }

    a {
      color: #028cb0;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      text-decoration: none;
      display: inline;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .button-wrapper {
    margin-top: 48px;
    display: flex;
    justify-content: center;
  }
`;

export const WantToHearMoreSection = styled.section`
  position: relative;
  padding: 220px 0 120px;
  .background {
    position: absolute;
    top: 100px;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -20;
    background: #028cb0;

    z-index: -20;
  }

  @media screen and (max-width: 768px) {
    margin-top: 40px;
  }

  h2,
  p {
    color: white;
    text-align: center;
  }
`;

export const WantToHearMoreForm = styled.div`
  background: white;
  max-width: 760px;
  border-radius: 20px;
  margin: 40px auto 0;
  z-index: 100;

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 24px;
    @media (min-width: 1020px) {
      padding: 40px;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  fieldset {
    border: 0;
  }

  label {
    display: block;
    font-size: 16px;
    line-height: 28px;
    color: #143a5a;
    margin-bottom: 4px;
  }

  input {
    font-size: 16px;
    line-height: 28px;
    background: #f7f9fc;
    border: 1px solid #f7f9fc;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    padding: 0 16px;
  }

  a {
    display: block;
    width: 177px;
    height: 48px;
    padding: 14px 0 12px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    color: white;
    background: #eb9f22;
    margin-top: 28px;
    text-decoration: none;
    border-radius: 3px;

    &:hover {
      background-color: #efb14e;
    }
  }
`;

function ForInspectorsPage() {
  const navigate = useNavigate();

  return (
    <>
      <DocumentTitle title="For Inspectors">
        <Banner
          image={InspectorBanner}
          title="More inspections. Less effort"
          excerpt={
            <P1>
              Many inspectors have enough work already. But it is the right kind
              of work that brings profit With more enquiries from your profile,
              you can choose the profitable work. Work smarter not harder so you
              can grow your business and spend more time doing what you love.
            </P1>
          }
        />
      </DocumentTitle>
      <StyledStatsCardWrapper />

      <Spacer />

      <Container>
        <SecondSectionRow
          items={[
            {
              icon: <VerifiedIcon />,
              title: "Customer reviews",
              description: `Create trust and show your prospects exactly why they should work with you. Free profiles can collect and display reviews.  Paid profiles can display Google reviews and where appropriate can have unfair or fake reviews adjudicated by Rate My Inspectors.`,
            },
            {
              icon: <RMIIcon />,
              title: "Rate my Inspectors awards",
              description: `<p>Subscribers are eligible for the Rate my Inspectors Awards that recognise the best rated inspectors by, Region, State and Country. Awards are a powerful selling tool. <a href="${
                "/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.AWARDS
              }">Find out more</a></p>`,
            },
            {
              icon: <ProfileIcon />,
              title: "Sell your past reports",
              description: `Upload past reports and make them available for sale. You set the price. Great for auctions too where multiple buyers can purchase your report. Make money while you sleep.`,
            },
          ]}
        />
      </Container>

      <Spacer />

      <PricingSection />
      <Spacer />

      <Container fluid>
        <ElectricalPersonSection>
          <div
            style={{ maxWidth: 468, marginLeft: "auto", marginRight: "auto" }}
          >
            <H2>
              The tools for you to grow your business are here. So what are you
              waiting for?
            </H2>
            <PrimaryButton
              style={{ marginTop: 24 }}
              onClick={() =>
                navigate(
                  "/" +
                    SEARCH_MASTER_ROUTE +
                    "/" +
                    SEARCH_ACCOUNTS_MASTER_ROUTE +
                    "/" +
                    SEARCH_ACCOUNTS_ROUTES.FIND
                )
              }
            >
              Claim your free profile
            </PrimaryButton>
          </div>
          <div>
            <img src={ElectricalInspectionsImage} alt="Electrical person" />
          </div>
        </ElectricalPersonSection>
      </Container>
      <Spacer />
      <Spacer />

      <Container>
        <FAQSection>
          <div className="label">
            <span />
            <p>Have a question?</p>
          </div>
          <H2>Frequently asked questions</H2>
          <div className="wrapper">
            {faqs.map((faq, index) => {
              return (
                <div key={index}>
                  <H4>{faq.question}</H4>
                  <P1 dangerouslySetInnerHTML={{ __html: faq.answer }} />
                </div>
              );
            })}
          </div>
          <div className="button-wrapper">
            <PrimaryButton
              onClick={() =>
                navigate("/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.FAQS)
              }
            >
              Read more FAQs
            </PrimaryButton>
          </div>
        </FAQSection>
      </Container>

      <WantToHearMoreSection>
        <div className="background" />
        <Container>
          <H2>Want to hear more?</H2>
          <P1>We’d love to take you through a demo!</P1>
          <WantToHearMoreForm>
            <Form
              onSubmit={async (values, form) => {
                try {
                  await api.post("/Inspectors/demo", values);
                  toast.success(
                    <Toast title="Your message has been submitted successfully." />
                  );
                  setTimeout(() => window.location.reload(), 5000);
                } catch (e) {
                  toast.error(
                    <Toast title="There was an error submitting your message. Please try again later." />
                  );
                }
              }}
              render={({ handleSubmit, submitting, form }) => (
                <div className="form">
                  <fieldset>
                    <Input
                      name="firstname"
                      label="First name"
                      validate={requiredValidator}
                      placeholder="Your first name"
                    />
                  </fieldset>
                  <fieldset>
                    <Input
                      name="lastname"
                      label="Last name"
                      validate={requiredValidator}
                      placeholder="Your last name"
                    />
                  </fieldset>
                  <fieldset>
                    <Input
                      name="email"
                      label="Email"
                      validate={emailValidator}
                      placeholder="Your email address"
                    />
                  </fieldset>
                  <fieldset>
                    <Input
                      name="phone"
                      label="Phone"
                      validate={phoneValidator}
                      placeholder="Your phone number"
                    />
                  </fieldset>
                  <fieldset>
                    <PrimaryButton
                      style={{ marginTop: 24 }}
                      onClick={submitting ? null : handleSubmit}
                    >
                      {submitting ? (
                        <Loader loading size={16} color="#fff" />
                      ) : (
                        "Submit message"
                      )}
                    </PrimaryButton>
                  </fieldset>
                </div>
              )}
            />
          </WantToHearMoreForm>
        </Container>
      </WantToHearMoreSection>
    </>
  );
}

export default ForInspectorsPage;
