import PropTypes from "prop-types";
import { useState } from "react";
import StarRatings from "react-star-ratings";
import styled from "styled-components";

export const Checkbox = styled.input`
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  background-color: #f6f7f9;
  border: 1px solid #8f9bb3;
  cursor: pointer;
  position: relative;
  transition: all 150ms linear;
  flex-shrink: 0;

  &:hover {
    background-color: #d6faee;
    border-color: #00e58f;
  }

  &:checked {
    background-color: #00e096;
    border-color: #00e096;

    &:before {
      content: "";
      position: absolute;
      width: 6px;
      height: 2px;
      background-color: #ffffff;
      border-radius: 16px;
      transform: rotate(45deg);
      left: 3px;
      top: 8px;
      transition: all 150ms linear;
    }
    &:after {
      content: "";
      position: absolute;
      width: 8px;
      height: 2px;
      background-color: #ffffff;
      border-radius: 16px;
      transform: rotate(-45deg);
      left: 6px;
      top: 7px;
      transition: all 150ms linear;
    }
  }
`;

export const Switch = styled.input`
  width: 62px;
  height: 35px;
  border-radius: 20px;
  background-color: #798995;
  position: relative;
  appearance: none;

  &:before {
    content: "";
    position: absolute;
    left: 12px;
    top: 12px;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: #ffffff;
    transform: rotate(45deg);
    transition: linear 150ms;
  }

  &:checked {
    background-color: #2abe52;

    &:before {
      transform: translateX(27px) rotate(135deg);
    }
  }
`;

export function Rating({ rating = 0, editable = true }) {
  const [starRating, setStarRating] = useState(rating);

  return (
    <StarRatings
      numberOfStars={5}
      rating={starRating}
      starRatedColor="#FEBA00"
      starHoverColor="#FEBA00"
      starEmptyColor="#ffe9b3"
      starDimension="22px"
      starSpacing="3px"
      // eslint-disable-next-line max-len
      svgIconPath="M11.1537 17.5001L6.04819 20.1842C5.43713 20.5054 4.68135 20.2705 4.3601 19.6594C4.23218 19.4161 4.18803 19.1374 4.2345 18.8664L5.20956 13.1814L1.07915 9.15525C0.584792 8.67338 0.574676 7.88199 1.05655 7.38763C1.24844 7.19078 1.49987 7.06267 1.77191 7.02314L7.48001 6.1937L10.0327 1.02129C10.3383 0.402223 11.0878 0.14805 11.7069 0.453577C11.9534 0.57524 12.1529 0.774775 12.2746 1.02129L14.8273 6.1937L20.5354 7.02314C21.2186 7.12241 21.6919 7.75671 21.5927 8.43989C21.5531 8.71194 21.425 8.96337 21.2282 9.15525L17.0978 13.1814L18.0728 18.8664C18.1895 19.5469 17.7325 20.1931 17.0521 20.3098C16.7812 20.3562 16.5025 20.3121 16.2591 20.1842L11.1537 17.5001Z"
      svgIconViewBox="0 0 22 21"
      changeRating={editable ? (newRating) => setStarRating(newRating) : null}
    />
  );
}
Rating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export const Input = styled.input`
  height: 48px;
  padding: 8px 16px;
  background-color: #f7f9fc;
  border-radius: 4px;
  color: #143a5a;
  border: 3px solid #f7f9fc;
  width: 100%;
  font-size: 16px;
  line-height: 28px;

  &::placeholder {
    color: #143a5a80;
  }

  &:hover {
    background-color: #edf1f7;
    border-color: #edf1f7;
  }

  &:focus {
    background-color: #ffffff;
    border: 3px solid #edf1f7;
  }

  &:disabled {
    background-color: #f7f9fc;
    cursor: not-allowed;
  }

  &.error {
    border: 1px solid #ca0b0b;
  }
`;

export const TextArea = styled.textarea`
  padding: 8px 16px;
  background-color: #f7f9fc;
  border-radius: 4px;
  color: #143a5a;
  border: 3px solid #f7f9fc;
  width: 100%;
  font-size: 16px;
  line-height: 28px;
  resize: none;

  &::placeholder {
    color: #143a5a80;
  }

  &:hover {
    background-color: #edf1f7;
    border-color: #edf1f7;
  }

  &:focus {
    background-color: #ffffff;
    border: 3px solid #edf1f7;
  }

  &:disabled {
    background-color: #f7f9fc;
    cursor: not-allowed;
  }

  &.error {
    border: 1px solid #ca0b0b;
  }
`;

export const Select = styled.select`
  appearance: none;
  height: 48px;
  width: 100%;
  background-color: #f7f9fc;
  border: none;
  padding: 8px 16px;
  position: relative;
  font-size: 16px;
  border-radius: 4px;

  &:hover {
    background-color: #edf1f7;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 3px;
    height: 3px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
    top: 50%;
    right: 16px;
  }

  &.error {
    border: 1px solid #ca0b0b;
  }
`;

export const Error = styled.span`
  display: block;
  color: #ca0b0b;
  margin-top: 4px;
  font-size: 14px;
  line-height: 20px;
`;
