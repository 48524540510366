import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reviews: null,
  reports: null,
  quotes: null,
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setCustomerReviews: (state, action) => {
      state.reviews = action.payload;
    },
    setPurchasedReports: (state, action) => {
      state.reports = action.payload;
    },
    setRequestedQuotes: (state, action) => {
      state.quotes = action.payload;
    },
    deleteReview: (state, action) => {
      if (state.reviews?.data) {
        state.reviews.data = state.reviews.data?.filter(
          (_) => _.id !== action.payload.reviewId
        );
      }
    },
  },
});

export const {
  setCustomerReviews,
  deleteReview,
  setPurchasedReports,
  setRequestedQuotes,
} = customersSlice.actions;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectorReview[]}
 */
export const selectCustomerReviews = (state) => state.customers.reviews?.data;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectionReportSale[]}
 */
export const selectPurchasedReports = (state) => state.customers.reports;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectionQuote[]}
 */
export const selectRequestedQuotes = (state) => state.customers.quotes;

export default customersSlice.reducer;

export { initialState as customersSliceInitialState };
