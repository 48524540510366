import { useAuth } from "common/authentication";
import { FacebookSignIn, GoogleSignIn, Input, Loader } from "components";
import { emailValidator, passwordValidator } from "components/form/validators";
import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { useSearchParams } from "react-router-dom";
import {
  ACCOUNT_MASTER_ROUTE,
  ACCOUNT_ROUTES,
  INSPECTORS_MASTER_ROUTE,
  USERS_MASTER_ROUTE,
} from "routes";
import styled from "styled-components";
import { A, H2, InternalA, P, PrimaryButton, Separator } from "ui-library";

const Title = styled(H2)`
  margin-top: 40px;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const StyledCol = styled(Col)`
  @media only screen and (max-width: 576px) {
    text-align: center;
  }
`;

/**
 * @typedef {Object} AccountLoginFormProps
 * @property {boolean} isInspector
 * @property {string} title
 * @property {string} description
 */

/**
 *
 * @param {AccountLoginFormProps} props
 * @returns
 */
export default function AccountLoginForm({ isInspector, title, description }) {
  const auth = useAuth();
  const [params] = useSearchParams();
  const next = params?.get("next");
  const email = params?.get("Email");

  return (
    <Form
      initialValues={{ email }}
      onSubmit={async (values) =>
        auth.login(values.email, values.password, next)
      }
      render={({ handleSubmit, submitting }) => (
        <div style={{ maxWidth: 450, marginLeft: 0, width: "100%" }}>
          <Container>
            <Row>
              <StyledCol>
                <Title>{title}</Title>
                <P>{description}</P>
              </StyledCol>
            </Row>
            <Row>
              <Col xs={12}>
                <Input
                  label="Email"
                  name="email"
                  type="email"
                  validate={emailValidator}
                />
              </Col>
              <Col xs={12}>
                <Input
                  label="Password"
                  name="password"
                  type="password"
                  validate={passwordValidator}
                />
                <div style={{ textAlign: "right", marginTop: 4 }}>
                  <A
                    href={
                      "/" +
                      ACCOUNT_MASTER_ROUTE +
                      "/" +
                      ACCOUNT_ROUTES.FORGOT_PASSWORD
                    }
                  >
                    Forgot password?
                  </A>
                </div>
              </Col>
              <Col xs={12} style={{ marginTop: 32 }}>
                <PrimaryButton
                  style={{ width: "100%" }}
                  onClick={submitting ? null : handleSubmit}
                >
                  {submitting ? <Loader loading={true} /> : "Log in"}
                </PrimaryButton>
              </Col>
              <Col>
                <P style={{ textAlign: "center", marginTop: 16 }}>
                  Don't have an account?{" "}
                  <InternalA
                    className="regular"
                    to={
                      "/" +
                      ACCOUNT_MASTER_ROUTE +
                      "/" +
                      ACCOUNT_ROUTES.REGISTER +
                      (next ? `?next=${next}` : "") +
                      (email ? `?Email=${email}` : "")
                    }
                    style={{ fontSize: 16 }}
                  >
                    Sign up
                  </InternalA>
                </P>
              </Col>

              <Col xs={12} style={{ display: "flex", alignItems: "center" }}>
                <Separator style={{ flexBasis: "25%" }} />
                <span style={{ color: "#A6B1C4" }}>or continue with</span>
                <Separator style={{ flexBasis: "25%" }} />
              </Col>

              <Col xs={6}>
                <GoogleSignIn
                  redirectUrl={
                    next ||
                    "/" +
                      (isInspector
                        ? INSPECTORS_MASTER_ROUTE
                        : USERS_MASTER_ROUTE)
                  }
                  isInspector={isInspector}
                />
              </Col>
              <Col xs={6}>
                <FacebookSignIn
                  redirectUrl={
                    next ||
                    "/" +
                      (isInspector
                        ? INSPECTORS_MASTER_ROUTE
                        : USERS_MASTER_ROUTE)
                  }
                  isInspector={isInspector}
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    />
  );
}
