import styled from "styled-components";
import { H3 } from "ui-library";
import checkmarkIcon from "assets/icons/quote-request-selection.svg";

const SelectableButtonContainer = styled.div`
  padding: 12px 0;
  width: 162px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f7f9;
  border: 1px solid #dde1e8;
  border-radius: 3px;
  flex-shrink: 0;
  cursor: pointer;

  ${H3} {
    color: #8f9bb3;
  }

  &.selected {
    background: #00e096;
    border: 1px solid #00e096;

    ${H3} {
      color: #fff;
    }
  }
`;

const Checkmark = styled.img`
  margin-right: 12px;
`;

export function SelectableButton({ title, className, onClick, isSelected }) {
  return (
    <SelectableButtonContainer
      className={`${isSelected ? "selected" : null} ${className}`}
      onClick={onClick}
    >
      {isSelected ? <Checkmark src={checkmarkIcon} /> : <noscript />}
      <H3 style={{ fontSize: "14px", lineHeight: "22px" }}>{title}</H3>
    </SelectableButtonContainer>
  );
}
