import { useAuth } from "common/authentication";
import { getFormattedDate } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SEARCH_MASTER_ROUTE,
  SEARCH_REPORTS_MASTER_ROUTE,
  SEARCH_REPORTS_ROUTES,
} from "routes";
import { inspectionTypesSelector } from "store/features/inspectors/inspectorsSlice";
import { selectSelectedReportId } from "store/features/search/searchSlice";
import styled from "styled-components";
import { H4, P, PrimaryButton } from "ui-library";
import NetworkImage from "./network-image";

const SearchReportContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const PropertyImage = styled(NetworkImage)`
  width: 229px;
  height: 145px;
  border-radius: 8px;
  flex-shrink: 0;

  @media only screen and (max-width: 576px) {
    width: 100%;
    height: 176px;
  }
`;

const ReportDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-right: 32px;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
    margin-top: 8px;
  }
`;

const InspectorDetails = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const InspectorImage = styled(NetworkImage)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-right: 0;
  flex-shrink: 0;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

/**
 * @param {Object} props
 * @param {import('services').SearchReport} props.report
 * @returns
 */
export function SearchReport({ report, purchased }) {
  const { user } = useAuth();
  const inspectionTypes = useSelector(inspectionTypesSelector);

  const filteredInspectionTypes = report.inspectionTypeIds
    .map((inspectionTypeId) => {
      return inspectionTypes.find(
        (inspectionType) => inspectionType.id === inspectionTypeId
      );
    })
    .filter((_) => !!_)
    .map((_) => _.name);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // TODO
  const downloadReport = () => {
    if (report.externalLink) {
      // redirect to inndox
      window.open(
        report.externalLink +
          `?email=${encodeURIComponent(user.email)}&firstName=${
            user.firstName
          }&lastName=${user.lastName}`,
        "_blank"
      );
    } else {
      window.open(
        process.env.REACT_APP_BASE_URL +
          `/api/v1/InspectionReports/${report.id}?apikey=${process.env.REACT_APP_API_KEY}`
      );
    }
  };

  return (
    <SearchReportContainer>
      <PropertyImage
        isAvatar={false}
        src={`${report.propertyImagePath}?w=278&h=176`}
      />

      <ReportDetails>
        <H4>
          {filteredInspectionTypes.length
            ? filteredInspectionTypes.join(", ") + " report"
            : report.inspectionTypeOther
            ? report.inspectionTypeOther + " report"
            : "Unknown inspection report"}
        </H4>
        <P style={{ fontSize: 20 }}>{report.address}</P>
        <P>
          <em>{getFormattedDate(report.inspectionDate)}</em>
        </P>

        <InspectorDetails>
          <InspectorImage src={report.inspector.imagePath + "?w=40&h=40"} />

          <P style={{ color: "#143A5A", marginLeft: 16 }}>
            {report.inspector.fullName}
          </P>
        </InspectorDetails>
      </ReportDetails>

      {purchased ? (
        <StyledPrimaryButton onClick={downloadReport}>
          Download report
        </StyledPrimaryButton>
      ) : (
        <StyledPrimaryButton
          onClick={() => {
            dispatch(selectSelectedReportId(report.id));

            navigate(
              "/" +
                SEARCH_MASTER_ROUTE +
                "/" +
                SEARCH_REPORTS_MASTER_ROUTE +
                "/" +
                report.id +
                "/" +
                SEARCH_REPORTS_ROUTES.PURCHASE_REPORT
            );
          }}
        >
          ${report.price} - Purchase report
        </StyledPrimaryButton>
      )}
    </SearchReportContainer>
  );
}
