export default function RMIStateIcon() {
  return (
    <svg
      width="187"
      height="116"
      viewBox="0 0 187 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_270_205)">
        <path
          d="M75.0174 43.9346L74.0843 43.1323L88.445 26.5436L66.973 21.6265L66.8434 21.4022L55.7575 2.42404L44.5334 21.6265L22.0765 26.7679L21.8086 25.5775L43.7471 20.5569L55.7575 0L67.768 20.5569L90.7001 25.8017L75.0174 43.9346Z"
          fill="#D66D27"
        />
        <path
          d="M4.79547 54.3899H1.01953V51.1118H4.79547V54.3899ZM4.79547 69.1842H1.01953V55.7615H4.79547V69.1842Z"
          fill="#143A5A"
        />
        <path
          d="M20.176 61.4635V69.1842H16.4778V61.6964C16.4778 59.4794 15.5706 58.7807 14.309 58.7807C12.0279 58.7807 10.8528 61.2047 10.7664 61.6964V69.1842H7.05957V55.7614H10.7664V58.289C10.8701 57.5126 12.3908 55.6234 15.1385 55.6234C17.6443 55.6234 20.176 57.2279 20.176 61.4635Z"
          fill="#143A5A"
        />
        <path
          d="M27.0362 58.7031C26.466 58.7031 25.9734 58.9878 25.9734 59.5657C25.9734 60.1437 26.2586 60.3594 26.9325 60.5923L28.7125 61.2393C30.5529 61.9121 32.5662 62.585 32.5662 65.242C32.5662 68.0887 29.5679 69.3999 27.0794 69.3999C24.5909 69.3999 22.0938 68.1577 21.4717 65.8113L24.5477 64.8538C24.803 65.2941 25.1735 65.6568 25.6195 65.903C26.0655 66.1492 26.5701 66.2696 27.0794 66.2513C27.883 66.2513 28.6866 65.8631 28.6866 65.1902C28.6866 64.7503 28.4014 64.4397 27.7275 64.2068L26.0771 63.5684C23.5627 62.6368 22.1197 61.6792 22.1197 59.5916C22.1197 57.1072 24.4181 55.6407 26.9585 55.6407C28.0677 55.5953 29.1635 55.8967 30.0929 56.5029C31.0223 57.1091 31.7389 57.9899 32.1428 59.0223L28.8335 59.8849C28.6717 59.542 28.4183 59.2503 28.1012 59.0418C27.7842 58.8333 27.4157 58.7161 27.0362 58.7031Z"
          fill="#143A5A"
        />
        <path
          d="M47.3078 62.447C47.3078 67.1743 43.8515 69.3482 41.0001 69.3482C38.8745 69.3482 37.5439 67.9075 37.5439 67.3899V74.0755H33.8457V55.7615H37.5439V57.3315C37.5439 56.7621 38.8573 55.6062 41.0001 55.6062C41.8683 55.6291 42.7231 55.8257 43.5138 56.1844C44.3044 56.5432 45.015 57.0568 45.6032 57.6947C46.1914 58.3326 46.6453 59.082 46.9382 59.8983C47.231 60.7146 47.3566 61.5813 47.3078 62.447ZM43.6096 62.447C43.6096 60.1178 42.037 58.7549 40.4039 58.7549C38.7709 58.7549 37.3797 59.7124 37.3797 62.447C37.3797 65.1816 38.8573 66.1909 40.4039 66.1909C41.9506 66.1909 43.6096 64.8451 43.6096 62.447Z"
          fill="#143A5A"
        />
        <path
          d="M62.0051 62.5246C62.0092 62.9753 61.9745 63.4255 61.9014 63.8703H52.3189C52.5224 64.5357 52.9327 65.1191 53.4903 65.5363C54.048 65.9535 54.7241 66.1827 55.4209 66.1908C55.8525 66.2176 56.2849 66.1548 56.6909 66.0063C57.0969 65.8578 57.4677 65.6269 57.7798 65.3282L60.8299 66.8809C60.1495 67.6503 59.3131 68.2665 58.3761 68.6889C57.4392 69.1112 56.423 69.3301 55.395 69.3309C53.6606 69.2038 52.038 68.4288 50.8505 67.1604C49.6631 65.8919 48.9982 64.2233 48.9884 62.4871C48.9786 60.751 49.6246 59.0749 50.7976 57.7932C51.9706 56.5115 53.5844 55.7182 55.3172 55.5717C56.2158 55.5827 57.1031 55.7722 57.9276 56.129C58.7521 56.4858 59.4972 57.0028 60.1196 57.6498C60.742 58.2969 61.2293 59.0612 61.553 59.8981C61.8767 60.735 62.0303 61.6278 62.0051 62.5246ZM52.3189 61.0753H58.2464C58.0706 60.4214 57.6827 59.844 57.1434 59.4335C56.604 59.023 55.9436 58.8025 55.2654 58.8066C54.5893 58.7951 53.9295 59.0134 53.3941 59.4256C52.8587 59.8379 52.4795 60.4196 52.3189 61.0753Z"
          fill="#143A5A"
        />
        <path
          d="M75.6488 58.6513L72.3653 59.8332C72.0874 59.4801 71.7293 59.1982 71.3206 59.0107C70.9119 58.8232 70.4644 58.7355 70.0151 58.7549C68.4338 58.7549 66.8785 60.1178 66.8785 62.5246C66.8785 64.9314 68.4338 66.1909 70.0151 66.1909C70.4633 66.2151 70.9109 66.1313 71.3199 65.9467C71.7289 65.762 72.0875 65.4818 72.3653 65.1298L75.6747 66.3203C75.0068 67.2909 74.0993 68.073 73.0399 68.5909C71.9805 69.1087 70.8051 69.3447 69.6276 69.2761C68.4501 69.2075 67.3102 68.8366 66.3184 68.1992C65.3265 67.5619 64.5162 66.6797 63.966 65.6381C63.4158 64.5964 63.1442 63.4306 63.1775 62.2535C63.2109 61.0763 63.5481 59.9277 64.1564 58.9188C64.7648 57.9099 65.6237 57.0749 66.6501 56.4945C67.6765 55.9142 68.8355 55.6082 70.0151 55.6062C71.1335 55.6073 72.234 55.8862 73.2175 56.4178C74.201 56.9494 75.0365 57.717 75.6488 58.6513Z"
          fill="#143A5A"
        />
        <path
          d="M81.5329 58.7548V64.8709C81.5329 65.4662 81.7662 66.1649 82.6389 66.1649C83.2305 66.1857 83.8198 66.0798 84.367 65.8543V68.8736C83.5494 69.2227 82.6631 69.382 81.7748 69.3394C79.6233 69.3394 77.8347 68.6148 77.8347 64.8968V58.7548H76.5127V55.7614H77.8261V53.4323H81.5329V55.7614H84.4016V58.7548H81.5329Z"
          fill="#143A5A"
        />
        <path
          d="M98.7538 62.5246C98.6906 64.3002 97.9397 65.9821 96.6592 67.216C95.3787 68.4499 93.6685 69.1394 91.8888 69.1394C90.1091 69.1394 88.3989 68.4499 87.1184 67.216C85.8379 65.9821 85.087 64.3002 85.0239 62.5246C84.9911 61.6041 85.1445 60.6864 85.4747 59.8264C85.805 58.9663 86.3054 58.1815 86.9461 57.5188C87.5868 56.856 88.3547 56.3289 89.2039 55.9689C90.0531 55.6089 90.9662 55.4233 91.8888 55.4233C92.8114 55.4233 93.7245 55.6089 94.5737 55.9689C95.4229 56.3289 96.1908 56.856 96.8315 57.5188C97.4722 58.1815 97.9726 58.9663 98.3029 59.8264C98.6331 60.6864 98.7865 61.6041 98.7538 62.5246ZM88.722 62.5246C88.722 64.9227 90.3465 66.1908 91.9018 66.1908C93.4571 66.1908 95.0556 64.9227 95.0556 62.5246C95.0556 60.1264 93.4312 58.7548 91.9018 58.7548C90.3724 58.7548 88.722 60.0401 88.722 62.5246Z"
          fill="#143A5A"
        />
        <path
          d="M109.597 55.7269V59.1775C109.169 59.1114 108.735 59.0854 108.301 59.0998C105.994 59.0998 104.214 60.8251 103.981 63.413V69.2187H100.309V55.7614H103.981V60.0746C104.266 57.5988 105.502 55.632 108.301 55.632C108.735 55.6165 109.17 55.6483 109.597 55.7269Z"
          fill="#143A5A"
        />
        <path
          d="M131.398 62.5246C131.208 64.2109 130.403 65.7683 129.136 66.8992C127.869 68.0302 126.228 68.6554 124.529 68.6554C122.829 68.6554 121.189 68.0302 119.922 66.8992C118.655 65.7683 117.849 64.2109 117.659 62.5246C117.551 61.5594 117.648 60.5821 117.943 59.6568C118.239 58.7315 118.727 57.8789 119.375 57.1548C120.024 56.4306 120.818 55.8513 121.706 55.4547C122.594 55.058 123.556 54.853 124.529 54.853C125.502 54.853 126.463 55.058 127.352 55.4547C128.24 55.8513 129.034 56.4306 129.682 57.1548C130.331 57.8789 130.819 58.7315 131.114 59.6568C131.41 60.5821 131.507 61.5594 131.398 62.5246ZM121.358 62.5246C121.358 64.9227 122.991 66.1908 124.546 66.1908C126.101 66.1908 127.7 64.9227 127.7 62.5246C127.7 60.1264 126.067 58.7548 124.546 58.7548C123.025 58.7548 121.358 60.0401 121.358 62.5246Z"
          fill="#143A5A"
        />
        <path
          d="M137.446 55.4509V55.7615H140.453V58.9101H137.42V69.1843H133.722V58.9101H131.994V55.7615H133.722V55.3733C133.722 51.4224 136.746 50.9307 138.354 50.9307C139.557 50.9473 140.74 51.2426 141.81 51.7933L140.626 54.3812C139.982 54.1293 139.296 53.9977 138.604 53.9931C137.369 53.924 137.446 54.9937 137.446 55.4509Z"
          fill="#143A5A"
        />
        <path
          d="M154.416 58.7548V64.8709C154.416 65.4662 154.649 66.1649 155.531 66.1649C156.122 66.186 156.712 66.0801 157.259 65.8543V68.8736C156.441 69.2236 155.555 69.3829 154.667 69.3394C152.515 69.3394 150.735 68.6148 150.735 64.8968V58.7548H149.413V55.7614H150.735V53.4323H154.433V55.7614H157.302V58.7548H154.416Z"
          fill="#143A5A"
        />
        <path
          d="M171.931 61.4636V69.1842H168.224V61.6965C168.224 59.4795 167.36 58.7807 166.055 58.7807C163.783 58.7807 162.599 61.2048 162.513 61.6965V69.1842H158.814V51.1118H162.513V58.289C162.616 57.5127 164.146 55.6235 166.885 55.6235C169.391 55.6235 171.931 57.228 171.931 61.4636Z"
          fill="#143A5A"
        />
        <path
          d="M187.008 62.5246C187.012 62.9753 186.978 63.4255 186.905 63.8703H177.279C177.482 64.5357 177.893 65.1191 178.45 65.5363C179.008 65.9535 179.684 66.1827 180.381 66.1908C180.812 66.2176 181.245 66.1548 181.651 66.0063C182.057 65.8578 182.428 65.6269 182.74 65.3282L185.79 66.8809C185.109 67.6503 184.273 68.2665 183.336 68.6889C182.399 69.1112 181.383 69.3301 180.355 69.3309C178.621 69.2038 176.998 68.4288 175.811 67.1604C174.623 65.8919 173.958 64.2233 173.948 62.4871C173.939 60.751 174.585 59.0749 175.758 57.7932C176.931 56.5115 178.544 55.7182 180.277 55.5717C181.179 55.577 182.071 55.7623 182.901 56.1165C183.73 56.4708 184.481 56.9869 185.108 57.6346C185.735 58.2822 186.226 59.0484 186.552 59.8881C186.879 60.7279 187.034 61.6242 187.008 62.5246ZM177.331 61.0753H183.258C183.082 60.4214 182.695 59.844 182.155 59.4335C181.616 59.023 180.955 58.8025 180.277 58.8066C179.601 58.7951 178.941 59.0134 178.406 59.4256C177.87 59.8379 177.491 60.4196 177.331 61.0753Z"
          fill="#143A5A"
        />
        <path
          d="M14.6462 78.1902L7.198 96.5214H3.56895L5.65998 91.2248L-0.0341797 78.1643H3.61216L7.45722 86.9115L10.9999 78.1643L14.6462 78.1902Z"
          fill="#143A5A"
        />
        <path
          d="M28.8687 84.9534C28.8729 85.4041 28.8382 85.8543 28.765 86.2991H19.148C19.3473 86.9684 19.7559 87.5564 20.3141 87.9771C20.8723 88.3977 21.5507 88.629 22.25 88.6369C22.6803 88.664 23.1115 88.6013 23.5162 88.4528C23.9209 88.3043 24.2901 88.0732 24.6003 87.7742L27.659 89.327C26.9779 90.0955 26.1413 90.7111 25.2045 91.1334C24.2677 91.5557 23.2519 91.775 22.2241 91.7769C20.4897 91.6499 18.8671 90.8749 17.6796 89.6064C16.4922 88.338 15.8273 86.6693 15.8175 84.9332C15.8077 83.197 16.4537 81.521 17.6267 80.2392C18.7997 78.9575 20.4135 78.1643 22.1463 78.0177C23.0463 78.0243 23.9359 78.2098 24.7632 78.5635C25.5906 78.9172 26.339 79.4319 26.9649 80.0776C27.5907 80.7233 28.0814 81.4871 28.4083 82.3242C28.7352 83.1614 28.8917 84.0551 28.8687 84.9534ZM19.1999 83.5041H25.1187C24.9447 82.8508 24.5584 82.2737 24.0205 81.8631C23.4826 81.4525 22.8234 81.2317 22.1463 81.2354C21.4695 81.2207 20.8081 81.4378 20.272 81.8506C19.736 82.2633 19.3576 82.8469 19.1999 83.5041Z"
          fill="#143A5A"
        />
        <path
          d="M39.937 79.7602V78.1902H43.6784V91.613H39.9802V89.8359C39.9802 90.3535 38.6669 91.7942 36.524 91.7942C33.7071 91.7942 30.2423 89.603 30.2423 84.893C30.1922 84.0291 30.3162 83.164 30.607 82.3489C30.8978 81.5337 31.3494 80.785 31.9351 80.1472C32.5208 79.5094 33.2287 78.9953 34.0169 78.6355C34.8051 78.2756 35.6576 78.0773 36.524 78.0522C38.6237 78.0349 39.9111 79.1909 39.937 79.7602ZM33.8886 84.8757C33.8886 87.2739 35.4612 88.6196 37.0943 88.6196C38.7273 88.6196 40.0926 87.5844 40.0926 84.8757C40.0926 82.167 38.6496 81.1836 37.0943 81.1836C35.539 81.1836 33.8886 82.5466 33.8886 84.8757Z"
          fill="#143A5A"
        />
        <path
          d="M54.911 78.1557V81.6063C54.4823 81.5403 54.0484 81.5143 53.6149 81.5287C51.3078 81.5287 49.5279 83.254 49.2946 85.8419V91.6475H45.5791V78.1902H49.2514V82.5035C49.5365 80.0277 50.7721 78.0608 53.5717 78.0608C54.0202 78.0422 54.4695 78.074 54.911 78.1557Z"
          fill="#143A5A"
        />
        <path
          d="M73.7999 80.0104L74.0332 78.2075H76.876L75.217 91.6302H72.3742L72.6334 89.465C72.4001 90.5433 70.456 91.8114 68.3131 91.8114C65.4704 91.8114 62.2647 89.5944 62.8263 84.9102C62.9873 83.0854 63.8052 81.3807 65.1286 80.1118C66.452 78.8429 68.1912 78.0959 70.024 78.009C72.1928 78.0349 73.3592 79.0097 73.7999 80.0104ZM65.6518 84.9188C65.2889 87.8346 67.1466 89.3615 69.013 89.3615C70.8794 89.3615 72.9445 88.0675 73.3333 84.9188C73.7221 81.7702 71.9335 80.5021 70.1017 80.5021C68.9519 80.5724 67.8668 81.0573 67.0482 81.8665C66.2297 82.6758 65.7334 83.7544 65.6518 84.9016V84.9188Z"
          fill="#EB9F22"
        />
        <path
          d="M90.9427 91.613H90.4761L87.3742 82.9865L82.1898 91.613H81.6973L77.9473 78.1902H80.7727L82.8638 86.2214L87.7284 78.1902H88.2469L91.1415 86.2214L95.2026 78.1902H98.0194L90.9427 91.613Z"
          fill="#EB9F22"
        />
        <path
          d="M108.327 80.0104L108.561 78.2075H111.403L109.744 91.6302H106.902L107.161 89.465C106.927 90.5433 104.992 91.8114 102.84 91.8114C99.9977 91.8114 96.792 89.5944 97.3537 84.9102C97.5146 83.0854 98.3325 81.3807 99.6559 80.1118C100.979 78.8429 102.719 78.0959 104.551 78.009C106.755 78.0349 107.887 79.0097 108.327 80.0104ZM100.179 84.9188C99.8163 87.8346 101.674 89.3615 103.54 89.3615C105.407 89.3615 107.472 88.0675 107.861 84.9188C108.25 81.7702 106.461 80.5021 104.629 80.5021C103.48 80.5742 102.396 81.0596 101.578 81.8686C100.759 82.6775 100.263 83.755 100.179 84.9016V84.9188Z"
          fill="#EB9F22"
        />
        <path
          d="M121.98 78.1299L121.643 80.7696C121.267 80.7255 120.889 80.7082 120.511 80.7178C119.264 80.7463 118.06 81.1842 117.087 81.9638C116.114 82.7435 115.426 83.8215 115.128 85.0311L114.324 91.5872H111.481L113.132 78.1644H115.983L115.491 82.193C115.794 81.0005 116.488 79.9432 117.461 79.1885C118.435 78.4338 119.633 78.0249 120.865 78.0264C121.239 78.0313 121.612 78.0659 121.98 78.1299Z"
          fill="#EB9F22"
        />
        <path
          d="M134.344 91.613H131.501L131.761 89.5254C131.527 90.6037 129.661 91.7942 127.544 91.7942C126.772 91.799 126.008 91.6458 125.298 91.344C124.588 91.0422 123.948 90.5982 123.417 90.0396C122.885 89.4809 122.474 88.8193 122.209 88.0959C121.944 87.3724 121.831 86.6023 121.876 85.8333C121.876 82.469 124.338 78.0695 128.987 78.0695C131.216 78.0695 132.443 79.096 132.893 80.0018L133.757 73.5751H136.608L134.344 91.613ZM124.693 85.7298C124.652 86.1849 124.706 86.6434 124.851 87.0767C124.996 87.5101 125.23 87.9088 125.536 88.2481C125.843 88.5873 126.216 88.8598 126.633 89.0484C127.05 89.237 127.501 89.3377 127.959 89.3442C131.216 89.3442 132.538 86.2732 132.538 83.9441C132.538 81.4424 130.983 80.4935 129.298 80.4935C126.248 80.4849 124.693 83.5818 124.693 85.7298Z"
          fill="#EB9F22"
        />
        <path
          d="M142.181 80.4072C141.784 80.3966 141.395 80.5229 141.081 80.7648C140.766 81.0068 140.544 81.3496 140.453 81.7356C140.375 82.374 140.686 82.8398 141.723 83.228L143.227 83.7974C145.188 84.522 146.951 85.1949 146.64 87.7224C146.329 90.388 143.356 91.7768 140.946 91.7768C138.535 91.7768 136.297 90.5088 136.012 88.24L138.604 87.4895C138.749 88.0558 139.087 88.5539 139.56 88.8986C140.033 89.2433 140.612 89.413 141.196 89.3787C142.311 89.3787 143.529 88.7317 143.633 87.7224C143.736 86.9201 143.218 86.4802 142.233 86.1265L140.704 85.5313C138.327 84.6686 137.247 83.6938 137.472 81.7874C137.731 79.51 140.064 78.0176 142.492 78.0176C143.487 77.9477 144.475 78.2192 145.294 78.7872C146.113 79.3552 146.713 80.1855 146.994 81.1404L144.255 81.8133C144.118 81.3802 143.839 81.0058 143.463 80.7506C143.086 80.4955 142.635 80.3745 142.181 80.4072Z"
          fill="#EB9F22"
        />
        <path
          d="M152.118 91.613H148.031V87.6104H152.118V91.613Z"
          fill="#EB9F22"
        />
        <path
          d="M25.8183 31.383V32.6425C25.6602 32.6288 25.5012 32.6288 25.3431 32.6425C24.9251 32.6447 24.5246 32.8102 24.2274 33.1037C23.9302 33.3972 23.76 33.7952 23.7532 34.2125V36.3518H22.4053V31.4175H23.7532V33.0048C23.8655 32.0904 24.3148 31.3657 25.3517 31.3657C25.5073 31.3515 25.6642 31.3573 25.8183 31.383Z"
          fill="#0D1F30"
        />
        <path
          d="M29.4989 31.9697V31.3917H30.8642V36.326H29.4989V35.6704C29.384 35.9041 29.2015 36.098 28.9751 36.2272C28.7487 36.3563 28.4888 36.4148 28.2288 36.395C27.6004 36.3315 27.018 36.0374 26.5945 35.5696C26.171 35.1018 25.9365 34.4937 25.9365 33.8632C25.9365 33.2326 26.171 32.6245 26.5945 32.1567C27.018 31.689 27.6004 31.3948 28.2288 31.3313C28.9891 31.3313 29.4903 31.7626 29.4989 31.9697ZM27.261 33.8502C27.2432 34.0182 27.2599 34.1881 27.31 34.3494C27.3602 34.5108 27.4428 34.6602 27.5528 34.7886C27.6628 34.917 27.7979 35.0216 27.9497 35.0961C28.1016 35.1706 28.2671 35.2134 28.4362 35.2218C29.0064 35.2218 29.5421 34.8423 29.5421 33.8502C29.5421 32.8582 29.0064 32.4872 28.4362 32.4872C28.2684 32.497 28.1043 32.5403 27.9536 32.6146C27.8029 32.689 27.6688 32.7928 27.5591 32.92C27.4495 33.0471 27.3666 33.195 27.3154 33.3549C27.2642 33.5147 27.2457 33.6831 27.261 33.8502Z"
          fill="#0D1F30"
        />
        <path
          d="M33.2408 32.4873V34.7388C33.2408 34.9631 33.3273 35.2132 33.647 35.2132C33.8657 35.2225 34.0839 35.1842 34.2864 35.1011V36.2139C33.9841 36.3439 33.656 36.4029 33.3273 36.3864C32.541 36.3864 31.8843 36.119 31.8843 34.7474V32.4873H31.4004V31.3917H31.8843V30.2185L33.2408 30.5377V31.4003H34.295V32.4959L33.2408 32.4873Z"
          fill="#0D1F30"
        />
        <path
          d="M39.4532 33.8761C39.4664 34.0398 39.4664 34.2042 39.4532 34.3678H35.9019C35.9742 34.6148 36.1241 34.8319 36.3295 34.9873C36.5349 35.1426 36.7848 35.2279 37.0425 35.2305C37.1995 35.242 37.3573 35.2211 37.5059 35.1691C37.6546 35.1172 37.7909 35.0353 37.9065 34.9286L39.0298 35.4979C38.7836 35.7853 38.4773 36.0154 38.1325 36.1719C37.7876 36.3283 37.4126 36.4075 37.0338 36.4037C36.3868 36.366 35.7787 36.0828 35.334 35.6121C34.8893 35.1415 34.6416 34.5189 34.6416 33.8718C34.6416 33.2248 34.8893 32.6022 35.334 32.1315C35.7787 31.6609 36.3868 31.3777 37.0338 31.34C37.6901 31.3603 38.3119 31.6378 38.7647 32.1125C39.2175 32.5871 39.4649 33.2208 39.4532 33.8761ZM35.9019 33.3413H38.0794C38.0226 33.0957 37.884 32.8765 37.6862 32.7198C37.4884 32.5631 37.2431 32.4781 36.9906 32.4787C36.7377 32.4765 36.4917 32.5609 36.2936 32.7179C36.0955 32.8749 35.9572 33.0949 35.9019 33.3413Z"
          fill="#0D1F30"
        />
        <path
          d="M51.0311 33.6776V36.326H49.6573V33.738C49.6573 32.9789 49.2512 32.5131 48.6809 32.5131C48.3865 32.5258 48.1057 32.6403 47.8866 32.8371C47.6674 33.0338 47.5237 33.3004 47.4798 33.5914V36.3605H46.106V33.7725C46.106 33.0134 45.6999 32.5476 45.1037 32.5476C44.5075 32.5476 44.0754 32.9789 43.9372 33.6259V36.395H42.5547V31.4606H43.9372V32.4095C44.0288 32.1026 44.2227 31.8362 44.4866 31.6542C44.7506 31.4723 45.0689 31.3857 45.3888 31.4089C45.7712 31.3874 46.1513 31.4801 46.4807 31.6752C46.81 31.8703 47.0736 32.159 47.2379 32.5044C47.3851 32.1608 47.6353 31.8711 47.9539 31.6749C48.2726 31.4788 48.6442 31.3859 49.0179 31.4089C50.0634 31.3399 51.0311 31.9696 51.0311 33.6776Z"
          fill="#0D1F30"
        />
        <path
          d="M56.795 31.3917L54.0473 38.129H52.7166L53.4943 36.1967L51.4551 31.3917H52.7944L54.1941 34.6094L55.4989 31.3917H56.795Z"
          fill="#0D1F30"
        />
        <path
          d="M11.4397 40.1734L8.98581 39.0951L6.53188 40.1734L2.02148 44.1329H8.97717H15.9415L11.4397 40.1734Z"
          fill="#EB9F22"
        />
        <path
          d="M8.97754 32.5045L8.98618 32.4873L8.97754 29.5198V32.4873V32.5045Z"
          fill="#EB9F22"
        />
        <path
          d="M8.98618 26.604L8.97754 26.5953V26.604V29.5197L8.98618 26.604Z"
          fill="#222B3F"
        />
        <path
          d="M6.53193 40.1734L6.77387 37.4647L5.01119 35.4203H5.00254L0.319336 32.9703V36.6366V44.133H2.02154L6.53193 40.1734Z"
          fill="#0D1F30"
        />
        <path
          d="M5.01119 35.4202L7.62065 34.825L8.97722 32.5045V32.4872V29.5197V26.604L1.2698 30.9259L0.319336 31.4607V32.9703L5.00254 35.4202H5.01119Z"
          fill="#143A5A"
        />
        <path
          d="M12.9609 35.4203L11.1982 37.4648L11.4402 40.1735L15.9419 44.133H17.7305V36.6366V32.9272L12.9609 35.4203Z"
          fill="#4F6473"
        />
        <path
          d="M16.7368 30.9L9.09851 26.6126L8.98618 26.604L8.97754 29.5197L8.98618 32.4872L10.3514 34.825L12.9609 35.4202L17.7305 32.9272V31.4607L16.7368 30.9Z"
          fill="#455A6B"
        />
        <path
          d="M23.8999 38.6034H22.5088V37.4043H23.8999V38.6034ZM23.8999 44.0294H22.5088V39.1037H23.8999V44.0294Z"
          fill="#0D1F30"
        />
        <path
          d="M29.5249 41.1913V44.0208H28.1769V41.2776C28.1769 40.4581 27.84 40.2079 27.3734 40.2079C27.0759 40.2392 26.7943 40.3574 26.5638 40.5476C26.3332 40.7379 26.164 40.9918 26.0773 41.2776V44.0208H24.7207V39.0951H26.0773V40.0268C26.2082 39.7178 26.4329 39.4577 26.7196 39.2828C27.0063 39.108 27.3407 39.0272 27.6758 39.052C28.6003 39.0865 29.5249 39.6731 29.5249 41.1913Z"
          fill="#0D1F30"
        />
        <path
          d="M32.0392 40.2165C31.8318 40.2165 31.6504 40.3201 31.6504 40.5185C31.6504 40.7169 31.754 40.8118 32.0046 40.898L32.6613 41.1309C33.3353 41.3811 34.0697 41.6227 34.0697 42.5975C34.0697 43.5722 32.9724 44.1243 32.0565 44.1243C31.6166 44.1572 31.178 44.0461 30.807 43.808C30.436 43.5699 30.1527 43.2176 30 42.8045L31.1319 42.4594C31.2262 42.6192 31.3618 42.7507 31.5244 42.8402C31.6871 42.9297 31.8708 42.974 32.0565 42.9684C32.3589 42.9684 32.6527 42.8217 32.6527 42.5802C32.6527 42.3387 32.5403 42.3042 32.2984 42.2179L31.6936 41.985C30.769 41.6399 30.2419 41.2949 30.2419 40.5271C30.259 40.3143 30.319 40.1072 30.4181 39.9181C30.5173 39.729 30.6536 39.5618 30.819 39.4265C30.9843 39.2912 31.1753 39.1906 31.3805 39.1307C31.5857 39.0708 31.8009 39.0528 32.0133 39.0779C32.4213 39.0618 32.8242 39.1727 33.1662 39.3953C33.5083 39.6178 33.7727 39.941 33.9228 40.3201L32.7045 40.6306C32.6425 40.5072 32.5476 40.4032 32.4302 40.3301C32.3128 40.2571 32.1775 40.2178 32.0392 40.2165Z"
          fill="#0D1F30"
        />
        <path
          d="M39.4787 41.5882C39.501 41.9082 39.458 42.2294 39.3524 42.5324C39.2468 42.8353 39.0808 43.1138 38.8643 43.3508C38.6479 43.5879 38.3855 43.7787 38.0931 43.9117C37.8007 44.0446 37.4843 44.117 37.163 44.1244C36.9041 44.1447 36.645 44.0864 36.4199 43.9571C36.1947 43.8278 36.014 43.6335 35.9015 43.3997V45.8583H34.5449V39.1296H35.9015V39.7076C35.9015 39.5006 36.3854 39.0779 37.163 39.0779C37.4819 39.0854 37.796 39.157 38.0866 39.2884C38.3772 39.4197 38.6384 39.6082 38.8545 39.8424C39.0706 40.0767 39.2372 40.352 39.3445 40.6519C39.4517 40.9518 39.4974 41.2703 39.4787 41.5882ZM38.1221 41.5882C38.1375 41.4215 38.119 41.2535 38.0677 41.0942C38.0163 40.9349 37.9333 40.7876 37.8236 40.6611C37.7138 40.5346 37.5796 40.4315 37.429 40.3581C37.2784 40.2847 37.1144 40.2424 36.947 40.2338C36.3767 40.2338 35.8324 40.5789 35.8324 41.5882C35.8324 42.5975 36.3767 42.9598 36.947 42.9598C37.116 42.9513 37.2815 42.9086 37.4334 42.8341C37.5853 42.7596 37.7204 42.655 37.8304 42.5266C37.9404 42.3982 38.023 42.2488 38.0731 42.0874C38.1233 41.9261 38.14 41.7562 38.1221 41.5882Z"
          fill="#0D1F30"
        />
        <path
          d="M44.8617 41.614C44.8745 41.7777 44.8745 41.9421 44.8617 42.1057H41.3277C41.3999 42.3526 41.5498 42.5698 41.7552 42.7251C41.9606 42.8805 42.2106 42.9657 42.4682 42.9684C42.6253 42.9798 42.783 42.959 42.9317 42.907C43.0803 42.8551 43.2167 42.7732 43.3323 42.6664L44.4469 43.2272C44.2017 43.5135 43.8968 43.7428 43.5535 43.8993C43.2102 44.0557 42.8369 44.1355 42.4596 44.1329C41.7919 44.1329 41.1515 43.8687 40.6786 43.3982C40.2056 42.9277 39.9388 42.2892 39.9365 41.6226C39.9365 40.966 40.1978 40.3363 40.6629 39.872C41.1279 39.4077 41.7587 39.1469 42.4164 39.1469C43.0741 39.1469 43.7048 39.4077 44.1699 39.872C44.635 40.3363 44.8962 40.966 44.8962 41.6226L44.8617 41.614ZM41.319 41.0878H43.4878C43.4283 40.8436 43.2889 40.6262 43.0917 40.47C42.8945 40.3138 42.6508 40.2276 42.3991 40.2252C42.1468 40.2217 41.9012 40.3059 41.7042 40.4632C41.5072 40.6206 41.3711 40.8413 41.319 41.0878Z"
          fill="#0D1F30"
        />
        <path
          d="M49.8563 40.1907L48.6552 40.6306C48.5539 40.4999 48.4224 40.3956 48.2719 40.3265C48.1215 40.2574 47.9566 40.2256 47.7912 40.2338C47.6236 40.2456 47.4602 40.291 47.3107 40.3675C47.1612 40.444 47.0287 40.55 46.9213 40.6789C46.814 40.8079 46.7339 40.9572 46.6858 41.118C46.6378 41.2787 46.6229 41.4474 46.642 41.6141C46.6254 41.7784 46.6423 41.9445 46.6916 42.1021C46.7409 42.2598 46.8217 42.4059 46.9291 42.5316C47.0364 42.6574 47.1681 42.7601 47.3163 42.8337C47.4645 42.9073 47.626 42.9502 47.7912 42.9598C47.9559 42.9697 48.1206 42.9396 48.2711 42.872C48.4216 42.8044 48.5534 42.7013 48.6552 42.5716L49.8649 43.0029C49.6206 43.3604 49.2879 43.6488 48.899 43.8401C48.5101 44.0313 48.0783 44.119 47.6455 44.0944C47.2127 44.0699 46.7935 43.9341 46.4288 43.7002C46.0641 43.4663 45.7661 43.1422 45.5639 42.7594C45.3616 42.3765 45.2619 41.948 45.2745 41.5154C45.2871 41.0828 45.4115 40.6608 45.6356 40.2903C45.8597 39.9198 46.176 39.6135 46.5537 39.4011C46.9314 39.1886 47.3577 39.0773 47.7912 39.0779C48.2008 39.078 48.604 39.1797 48.9645 39.3739C49.325 39.5682 49.6314 39.8489 49.8563 40.1907Z"
          fill="#0D1F30"
        />
        <path
          d="M52.025 40.2338V42.4766C52.025 42.6923 52.1114 42.9511 52.4311 42.9511C52.6467 42.9554 52.8611 42.9173 53.0619 42.839V43.9431C52.7624 44.0724 52.4373 44.1314 52.1114 44.1157C51.3165 44.1157 50.6684 43.8482 50.6684 42.4853V40.2338H50.1846V39.1296H50.6684V38.0254L52.025 38.2842V39.1468H53.0705V40.251L52.025 40.2338Z"
          fill="#0D1F30"
        />
        <path
          d="M58.3677 41.6141C58.3677 42.2798 58.1028 42.9183 57.6312 43.3891C57.1597 43.8599 56.5202 44.1244 55.8533 44.1244C55.1864 44.1244 54.5469 43.8599 54.0753 43.3891C53.6038 42.9183 53.3389 42.2798 53.3389 41.6141C53.3389 40.9483 53.6038 40.3098 54.0753 39.839C54.5469 39.3682 55.1864 39.1038 55.8533 39.1038C56.5202 39.1038 57.1597 39.3682 57.6312 39.839C58.1028 40.3098 58.3677 40.9483 58.3677 41.6141ZM54.6954 41.6141C54.6704 41.781 54.6817 41.9513 54.7286 42.1134C54.7756 42.2755 54.8569 42.4256 54.9673 42.5535C55.0776 42.6814 55.2142 42.784 55.3679 42.8543C55.5215 42.9246 55.6886 42.961 55.8576 42.961C56.0266 42.961 56.1937 42.9246 56.3473 42.8543C56.501 42.784 56.6376 42.6814 56.748 42.5535C56.8583 42.4256 56.9397 42.2755 56.9866 42.1134C57.0335 41.9513 57.0448 41.781 57.0198 41.6141C57.053 41.443 57.0479 41.2667 57.0048 41.0978C56.9618 40.929 56.8818 40.7717 56.7708 40.6373C56.6597 40.5029 56.5203 40.3946 56.3624 40.3203C56.2045 40.246 56.0321 40.2075 55.8576 40.2075C55.6831 40.2075 55.5107 40.246 55.3528 40.3203C55.195 40.3946 55.0555 40.5029 54.9444 40.6373C54.8334 40.7717 54.7535 40.929 54.7104 41.0978C54.6674 41.2667 54.6623 41.443 54.6954 41.6141Z"
          fill="#0D1F30"
        />
        <path
          d="M62.3077 39.121V40.3805C62.1496 40.3668 61.9906 40.3668 61.8325 40.3805C61.416 40.3849 61.0177 40.5515 60.7224 40.8447C60.427 41.138 60.258 41.5347 60.2513 41.9505V44.0812H58.9033V39.1555H60.2513V40.7428C60.3549 39.8284 60.8129 39.1124 61.8498 39.1124C62.0023 39.1004 62.1557 39.1032 62.3077 39.121Z"
          fill="#0D1F30"
        />
        <path
          d="M64.5363 40.2165C64.3289 40.2165 64.1474 40.3201 64.1474 40.5185C64.1474 40.7169 64.2511 40.8118 64.5017 40.898L65.1584 41.1309C65.8237 41.3811 66.5668 41.6227 66.5668 42.5975C66.5668 43.5722 65.4694 44.1243 64.5535 44.1243C64.1137 44.1572 63.6751 44.0461 63.3041 43.808C62.933 43.5699 62.6498 43.2176 62.4971 42.8045L63.629 42.4594C63.7233 42.6192 63.8589 42.7507 64.0215 42.8402C64.1842 42.9297 64.3679 42.974 64.5535 42.9684C64.8473 42.9684 65.1411 42.8217 65.1411 42.5802C65.1411 42.3387 65.0374 42.3042 64.7955 42.2179L64.1906 41.985C63.2661 41.6399 62.739 41.2949 62.739 40.5271C62.7561 40.3143 62.816 40.1072 62.9152 39.9181C63.0143 39.729 63.1507 39.5618 63.316 39.4265C63.4814 39.2912 63.6724 39.1906 63.8776 39.1307C64.0828 39.0708 64.298 39.0528 64.5103 39.0779C64.9183 39.0618 65.3212 39.1727 65.6633 39.3953C66.0054 39.6178 66.2698 39.941 66.4199 40.3201L65.2016 40.6306C65.1396 40.5072 65.0447 40.4032 64.9273 40.3301C64.8099 40.2571 64.6746 40.2178 64.5363 40.2165Z"
          fill="#0D1F30"
        />
        <path
          d="M68.3648 44.0554H66.8613V42.5889H68.3648V44.0554Z"
          fill="#E7A523"
        />
        <path
          d="M1.40801 105.596V110.005H6.16897V110.729H1.40801V115.905H0.543945V104.872H7.06761V105.596H1.40801Z"
          fill="#D66D27"
        />
        <path
          d="M18.7929 110.384C18.7929 111.104 18.651 111.816 18.3752 112.48C18.0995 113.145 17.6954 113.749 17.1859 114.258C16.6764 114.766 16.0715 115.17 15.4058 115.445C14.7401 115.72 14.0267 115.862 13.3061 115.862C12.5856 115.862 11.8721 115.72 11.2064 115.445C10.5407 115.17 9.93588 114.766 9.42638 114.258C8.91689 113.749 8.51273 113.145 8.237 112.48C7.96126 111.816 7.81934 111.104 7.81934 110.384C7.81934 109.665 7.96126 108.953 8.237 108.288C8.51273 107.623 8.91689 107.019 9.42638 106.511C9.93588 106.002 10.5407 105.599 11.2064 105.323C11.8721 105.048 12.5856 104.906 13.3061 104.906C14.0267 104.906 14.7401 105.048 15.4058 105.323C16.0715 105.599 16.6764 106.002 17.1859 106.511C17.6954 107.019 18.0995 107.623 18.3752 108.288C18.651 108.953 18.7929 109.665 18.7929 110.384ZM8.65748 110.384C8.64534 111.64 9.12423 112.851 9.99234 113.759C10.8605 114.668 12.0493 115.202 13.3061 115.25C14.5484 115.176 15.7156 114.631 16.569 113.727C17.4224 112.822 17.8977 111.627 17.8977 110.384C17.8977 109.142 17.4224 107.946 16.569 107.042C15.7156 106.138 14.5484 105.593 13.3061 105.519C12.0486 105.564 10.8584 106.098 9.98986 107.007C9.1213 107.916 8.64314 109.128 8.65748 110.384Z"
          fill="#D66D27"
        />
        <path
          d="M26.9332 115.888L23.6065 111.575H21.0144V115.888H20.2021V104.872H23.8053C25.9914 104.872 27.5207 106.243 27.5207 108.228C27.5299 109.058 27.2227 109.862 26.6613 110.475C26.0999 111.088 25.3262 111.466 24.4965 111.531L27.875 115.888H26.9332ZM21.023 110.867H23.6152C25.5075 110.867 26.674 109.832 26.674 108.228C26.674 106.623 25.5075 105.579 23.6152 105.579H21.023V110.867Z"
          fill="#D66D27"
        />
        <path
          d="M40.3001 107.046L39.6261 107.313C39.373 106.745 38.9555 106.266 38.4274 105.937C37.8992 105.608 37.2845 105.444 36.6624 105.467C35.133 105.467 34.0702 106.33 34.0702 107.512C34.0702 108.693 34.8219 109.151 35.9711 109.547L37.4228 110.048C39.0472 110.6 40.4556 111.324 40.4556 113.102C40.4556 114.879 38.6584 116 36.7315 116C35.8824 116.031 35.0444 115.799 34.3326 115.336C33.6209 114.873 33.0704 114.2 32.7568 113.412L33.4394 113.127C33.6924 113.786 34.1464 114.349 34.7372 114.736C35.3279 115.123 36.0256 115.314 36.7315 115.284C38.2609 115.284 39.6693 114.482 39.6693 113.093C39.6693 111.704 38.4942 111.178 37.3191 110.764L35.7983 110.246C34.2862 109.729 33.2753 109.004 33.2753 107.503C33.2753 106.002 34.7787 104.751 36.671 104.751C37.4355 104.732 38.1885 104.94 38.8346 105.348C39.4806 105.757 39.9907 106.348 40.3001 107.046Z"
          fill="#D66D27"
        />
        <path
          d="M48.6895 105.579H45.406V115.931H44.5938V105.579H41.3018V104.872H48.6808L48.6895 105.579Z"
          fill="#D66D27"
        />
        <path
          d="M55.1446 112.273H49.8739L48.284 115.888H47.4199L52.371 104.872H52.6216L57.5726 115.888H56.7086L55.1446 112.273ZM54.8249 111.566L52.5092 106.321L50.1935 111.566H54.8249Z"
          fill="#D66D27"
        />
        <path
          d="M63.6984 105.579H60.4236V115.931H59.62V105.579H56.3193V104.872H63.6984V105.579Z"
          fill="#D66D27"
        />
        <path
          d="M71.4925 115.163V115.888H64.8047V104.872H71.3283V105.596H65.6255V109.987H69.7558V110.712H65.6255V115.163H71.4925Z"
          fill="#D66D27"
        />
        <path
          d="M93.5406 34C91.3753 34 90.1024 35.6679 90.1024 37.1602V37.4381H92.2385V37.1602C92.2385 36.136 93.0724 35.8434 93.5406 35.8434C94.038 35.8434 94.9304 36.0629 94.9304 37.2333C94.9304 38.0819 94.4038 38.6086 92.414 40.9348L90 43.7877V44.3437H97.0665V42.5295H93.7454L94.6817 41.52C96.6568 39.3694 97.0665 38.6232 97.0665 37.2333C97.0665 35.6679 95.6912 34 93.5406 34Z"
          fill="#0D1F30"
        />
        <path
          d="M102.821 34C100.525 34 98.3885 35.6679 98.3885 39.0329V39.4133C98.3885 42.7782 100.525 44.4461 102.821 44.4461C105.148 44.4461 107.255 42.7782 107.255 39.4133V39.0329C107.255 35.6679 105.148 34 102.821 34ZM102.821 35.7849C104.709 35.7849 105.162 37.6576 105.162 39.0329V39.4133C105.162 40.8031 104.709 42.6466 102.821 42.6466C100.934 42.6466 100.481 40.8031 100.481 39.4133V39.0329C100.481 37.6576 100.934 35.7849 102.821 35.7849Z"
          fill="#0D1F30"
        />
        <path
          d="M112.043 34C109.878 34 108.605 35.6679 108.605 37.1602V37.4381H110.741V37.1602C110.741 36.136 111.575 35.8434 112.043 35.8434C112.54 35.8434 113.433 36.0629 113.433 37.2333C113.433 38.0819 112.906 38.6086 110.916 40.9348L108.502 43.7877V44.3437H115.569V42.5295H112.248L113.184 41.52C115.159 39.3694 115.569 38.6232 115.569 37.2333C115.569 35.6679 114.194 34 112.043 34Z"
          fill="#0D1F30"
        />
        <path
          d="M124.63 34.1024H117.271V35.8873H121.397L119.495 39.2962C119.758 39.2377 120.007 39.2084 120.227 39.2084C121.631 39.2084 122.333 40.1594 122.333 40.9494C122.333 42.4417 121.104 42.6905 120.578 42.6905C120.007 42.6905 118.983 42.3686 118.983 41.2274H116.891C116.891 43.027 118.412 44.49 120.578 44.49C122.597 44.49 124.425 43.1733 124.425 40.9494C124.425 39.4425 123.533 38.2574 122.406 38.0234L124.63 34.1024Z"
          fill="#0D1F30"
        />
      </g>
      <defs>
        <clipPath id="clip0_270_205">
          <rect width="187" height="116" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
