export default function SecureIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#E7F2F6"
      />
      <path
        d="M35 52.5781C34.9086 52.5781 34.8242 52.564 34.7399 52.5289C25.9296 49.0062 20.2344 41.1032 20.2344 32.3984V22.3436C20.2344 22.0342 20.4383 21.76 20.7336 21.6686L34.7959 17.4497C34.9295 17.4075 35.0701 17.4075 35.1967 17.4497L49.259 21.6686C49.5543 21.76 49.7582 22.0342 49.7582 22.3436V32.3984C49.7582 41.1032 44.063 48.9991 35.2527 52.5289C35.1754 52.564 35.091 52.5781 34.9996 52.5781H35ZM21.6408 22.864V32.3983C21.6408 40.4349 26.8719 47.7544 35 51.1154C43.1281 47.7545 48.3593 40.4349 48.3593 32.3983V22.864L35 18.8562L21.6408 22.864Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.5"
      />
      <path
        d="M35 44.1407C29.1853 44.1407 24.4531 39.4085 24.4531 33.5938C24.4531 27.7791 29.1853 23.0469 35 23.0469C40.8148 23.0469 45.547 27.7791 45.547 33.5938C45.547 39.4085 40.8148 44.1407 35 44.1407ZM35 24.453C29.9586 24.453 25.8593 28.5524 25.8593 33.5938C25.8593 38.6352 29.9586 42.7346 35 42.7346C40.0415 42.7346 44.1408 38.6352 44.1408 33.5938C44.1408 28.5524 40.0415 24.453 35 24.453Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.5"
      />
      <path
        d="M32.0189 37.2781C31.8361 37.2781 31.6603 37.2078 31.5197 37.0742L29.5299 35.0844C29.2557 34.8101 29.2557 34.3672 29.5299 34.0929C29.8041 33.8187 30.2471 33.8187 30.5213 34.0929L32.0119 35.5836L38.4735 29.1219C38.7478 28.8477 39.1907 28.8477 39.4649 29.1219C39.7392 29.3961 39.7392 29.8391 39.4649 30.1133L32.504 37.0743C32.3774 37.2149 32.1946 37.2782 32.0188 37.2782L32.0189 37.2781Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.5"
      />
    </svg>
  );
}
