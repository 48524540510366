import { api } from "./api";

/**
 * @typedef {Object} InspectorProfileSummary
 * @property {number} id
 * @property {string} fullName
 * @property {string} imagePath
 * @property {string} phone
 * @property {string} email
 */

/**
 * @typedef {Object} InspectorSummaryReviewInfo
 * @property {number} average
 * @property {number} count
 */

/**
 * @typedef {Object} InspectorCompany
 * @property {string} name
 * @property {string} [phone]
 * @property {string} [logoPath]
 * @property {any} [stripe]
 */

/**
 * @typedef {Object} InspectorSummary
 * @property {number} id
 * @property {string} code
 * @property {InspectorProfileSummary} profile
 * @property {InspectorCompany} company
 * @property {number[]} inspectionTypeIds
 * @property {number} reportCount
 * @property {number} videoCount
 * @property {string} location
 * @property {InspectorSummaryReviewInfo} reviews
 */

/**
 * @typedef {Object} Inspector
 * @property {number} id
 * @property {string} code
 * @property {InspectorProfileSummary} profile
 * @property {InspectorCompany} company
 * @property {number[]} inspectionTypeIds
 * @property {number} inspectionServiceIds
 * @property {InspectorProfile} data
 */

/**
 * @typedef {Object} PagedInspectorSummary
 * @property {number} currentPage
 * @property {number} pageCount
 * @property {number} pageSize
 * @property {number} totalCount
 * @property {number} [pagingMaxId]
 * @property {InspectorSummary[]} data
 */

/**
 * @typedef {Object} GeoCoordinate
 * @property {number} latitude
 * @property {number} longitude
 */

/**
 * @typedef {Object} Address
 * @property {string} street
 * @property {string} suburb
 * @property {string} state
 * @property {string} postcode
 * @property {string} country
 * @property {string} region
 * @property {GeoCoordinate} point
 * @property {string} [formatted]
 */

/**
 * @typedef {Object} InspectionLicense
 * @property {string} [number]
 * @property {string} [issuingAuthority]
 * @property {string} [type]
 * @property {string} [link]
 */

/**
 * @typedef {Object} InspectorProfile
 * @property {string} code
 * @property {number[]} [inspectionTypeIds]
 * @property {number[]} [inspectionServiceIds]
 * @property {string[]} [otherInspectionServices]
 * @property {number} searchRadiusInKilometres
 * @property {'Incomplete' | 'Active' | 'Inactive'} status
 * @property {number} [pagingMaxId]
 * @property {Address} address
 * @property {string} [companyId]
 * @property {string} [bio]
 * @property {number} establishedYear
 * @property {InspectionLicense[]} [licenses]
 * @property {string} [website]
 * @property {string[]} [socials]
 * @property {boolean} hasProfessionalIndemnity
 * @property {boolean} termsAgreement
 * @property {string} [inspectionAgreementPath]
 * @property {string} [sampleReportPath]
 * @property {boolean} termsAgreement
 * @property {number} completionPercentage
 * @property {string[]} incomplete
 */

/**
 * @typedef {Object} Stat
 * @property {string} type
 * @property {number} value
 */

export const InspectorsApi = {
  /**
   * @returns {Promise<PagedInspectorSummary>}
   */
  getInspectors: (params) => api.get("/Inspectors", { params }),

  /**
   * @returns {Promise<PagedInspectorSummary>}
   */
  getInspectorsByPage: (params, pageNumber) =>
    api.get("/Inspectors", {
      params: { ...params, pageNumber, pageSize: 10 },
    }),
  /**
   * @returns {Promise<InspectorProfile>}
   */
  getCurrentInspectorProfile: () => api.get("/Inspectors/current"),
  /**
   * @returns {Promise<Inspector>}
   */
  getInspectorProfileByCode: (code) => api.get(`/Inspectors/${code}`),
  /**
   * @param {Partial<InspectorProfile>} profileInfo
   * @returns {Promise<void>}
   */
  updateCurrentInspector: (profileInfo) =>
    api.patch("/Inspectors/current", profileInfo),
  /**
   *
   * @param {Object} fileInfo
   * @param {File} [fileInfo.inspectionAgreementFile]
   * @param {File} [fileInfo.sampleReportFile]
   */
  uploadFilesToCurrentInspector: (fileInfo) =>
    api.put("/Inspectors/current/files", fileInfo),
  /**
   *
   * @param {string} inspectorCode
   * @param {'InspectionAgreement' | 'SampleReport'} fileType
   * @returns
   */
  downloadFileOfType: async (inspectorCode, fileType) => {
    // api.get(`/Inspectors/${inspectorCode}/file/${fileType}`),
    window.open(
      process.env.REACT_APP_BASE_URL +
        `/api/v1/Inspectors/${inspectorCode}/file/${fileType}?apikey=${process.env.REACT_APP_API_KEY}`
    );
  },
  deleteFileOfType: async (fileType) =>
    api.delete(`/Inspectors/current/file/${fileType}`),
  downloadFileOfTypeForCurrentInspector: async (fileType, inspectorCode) => {
    if (inspectorCode) {
      window.open(
        process.env.REACT_APP_BASE_URL +
          `/api/v1/Inspectors/${inspectorCode}/file/${fileType}?apikey=${process.env.REACT_APP_API_KEY}`
      );
    } else {
      window.open(
        process.env.REACT_APP_BASE_URL +
          `/api/v1/Inspectors/current/file/${fileType}?apikey=${process.env.REACT_APP_API_KEY}`
      );
    }
  },
  claimProfile: async (code, data) =>
    api.post(`/Inspectors/${code}/claim`, data),

  /**
   *
   * @returns {Promise<Stat[]>}
   */
  fetchStatistics: async () => api.get("/Inspectors/current/statistics"),
};
