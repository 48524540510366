import { useAuth } from "common/authentication";
import { Loader } from "components";
import { useEffect } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  InspectionServicesApi,
  InspectionTypesApi,
  InspectorsApi,
  UsersApi,
  UserSessionsApi,
} from "services";
import { handleError } from "services/api";
import {
  setCurrentInspector,
  setInspectionTypes,
  setServices,
} from "store/features/inspectors/inspectorsSlice";
import { setSession, updateUser } from "store/features/user/userSlice";
import { H2, P, TertiaryButton } from "ui-library";

export default function ImpersonateRMIUser() {
  const { session, logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function getRequiredInfo() {
      try {
        const session = await UserSessionsApi.getCurrentSession();
        dispatch(setSession(session));

        const user = await UsersApi.getCurrentUser();
        dispatch(updateUser(user));

        if (user.roles.indexOf("CompanyAdmin") > -1) {
          const inspector = await InspectorsApi.getCurrentInspectorProfile();
          dispatch(setCurrentInspector(inspector));

          const inspectionTypes = await InspectionTypesApi.getInspectionTypes();
          dispatch(setInspectionTypes(inspectionTypes));

          const inspectionServices =
            await InspectionServicesApi.getListOfInspectionServices();
          dispatch(setServices(inspectionServices));
        }
      } catch (e) {
        handleError(e);

        // navigate user to login
        navigate("/app/account/login");
      }
    }
    getRequiredInfo();
  }, [dispatch, navigate]);

  if (!session || !session.impersonator) {
    return (
      <Container>
        <Row>
          <Col>
            <Loader loading size="large" color="#efb14e" />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row style={{ paddingBottom: 32 }}>
        <Col xs={12}>
          <H2 style={{ marginTop: 32, marginBottom: 8 }}>
            Impersonating {session.fullName}
          </H2>
        </Col>

        <Col xs={4} md={3}>
          <P>First Name</P>
        </Col>
        <Col xs={8} md={9}>
          <P>{session.firstName}</P>
        </Col>

        <Col xs={4} md={3}>
          <P>Last Name</P>
        </Col>
        <Col xs={8} md={9}>
          <P>{session.lastName}</P>
        </Col>

        <Col xs={4} md={3}>
          <P>Email</P>
        </Col>
        <Col xs={8} md={9}>
          <P>{session.email}</P>
        </Col>

        <Col xs={4} md={3}>
          <P>Phone</P>
        </Col>
        <Col xs={8} md={9}>
          <P>{session.phone || "Not set"}</P>
        </Col>

        <Col xs={4} md={3}>
          <P>User Type</P>
        </Col>
        <Col xs={8} md={9}>
          <P>
            {session.roles.includes("CompanyAdmin") ? "Inspector" : "Customer"}
          </P>
        </Col>

        <Col xs={4} md={3}>
          <P>Impersonator</P>
        </Col>
        <Col xs={8} md={9}>
          <P>
            {session.roles.includes("CompanyAdmin") ? "Inspector" : "Customer"}
          </P>
        </Col>

        <Col xs={12}>
          <H2 style={{ marginTop: 32, marginBottom: 8 }}>Actions</H2>
        </Col>
        <Col xs={6} sm={4}>
          <TertiaryButton onClick={() => window.open("/admin", "_self")}>
            Return to SuperAdmin
          </TertiaryButton>
        </Col>
        <Col xs={6} sm={4}>
          <TertiaryButton onClick={logout}>Log out</TertiaryButton>
        </Col>
      </Row>
    </Container>
  );
}
