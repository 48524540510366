import { api } from "./api";

/**
 * @typedef {Object} UserRole
 * @property {string} role
 * @property {number} [companyId]
 */

/**
 * @typedef {Object} User
 * @property {string} [firstName]
 * @property {string} [lastName]
 * @property {string} [fullName]
 * @property {string} [email]
 * @property {string} [phone]
 * @property {'Registered' | 'Activated' | 'Deleted' | 'Invited' | 'Anonymous'} status
 * @property {UserRole[]} [userRoles]
 * @property {string[]} [roles]
 * @property {string} [profilePhotoPath]
 */

export const UsersApi = {
  /**
   * @param {Object} userInfo
   * @param {string} userInfo.firstName
   * @param {string} userInfo.lastName
   * @param {string} userInfo.email
   * @param {string} userInfo.phone
   * @param {string} [userInfo.companyName]
   * @param {string} [userInfo.companyGuid]
   * @param {string} userInfo.password
   * @param {boolean} userInfo.acceptTermsConditions
   * @param {boolean} userInfo.isAnonymous
   * @param {string} [userInfo.anonymousUserId]
   * @returns {Promise<User>}
   */
  signUpWithEmail: async (userInfo) => api.post("/Users/byemail", userInfo),

  /**
   *
   * @param {*} email
   * @param {*} token
   * @returns {Promise<User>}
   */
  getUserDetailsFromAuthProvider: (email, token) =>
    api.get(`/Users/bytoken/${token}?email=${email}&token=${token}`),

  /**
   * This API call redirects to OAuth URL
   * @param {'Unknown' | 'Google' | 'Facebook'} provider
   * @returns {Promise<void>}
   */
  signUpWithOAuth: async (provider, redirectUrl) =>
    api.get(
      `/Users/byoauth/${provider}?provider=${provider}&redirectUrl=${redirectUrl}`
    ),

  /**
   *
   * @returns {Promise<User>}
   */
  getCurrentUser: async () => api.get("/Users/current"),

  /**
   *
   * @param {Object} loginInfo
   * @param {string} loginInfo.currentPassword
   * @param {string} loginInfo.password
   * @param {boolean} loginInfo.passwordSpecified
   * @param {string} loginInfo.email
   * @param {string} loginInfo.emailSpecified
   * @param {string} loginInfo.phone
   * @param {string} loginInfo.phoneSpecified
   * @param {string} loginInfo.firstName
   * @param {string} loginInfo.lastName
   * @param {string} loginInfo.nameSpecified
   * @returns {Promise<User>}
   */
  updateLoginDetailsForCurrentUser: async (loginInfo) =>
    api.patch("/Users/current", loginInfo),

  /**
   *
   * @param {Object} userInfo
   * @param {string} userInfo.firstName
   * @param {string} userInfo.lastName
   * @param {string} userInfo.email
   * @param {string} [userInfo.phone]
   * @param {string} [userInfo.profilePhotoPath]
   * @param {string} [userInfo.companyName]
   * @returns {Promise<User>}
   */
  updateCurrentUser: async (userInfo) => api.put("/Users/current", userInfo),
  /**
   *
   * @returns {Promise<void>}
   */

  removeCurrentUser: async () => api.delete("/Users/current"),
  /**
   *
   * @param {string} email
   * @returns {Promise<void>}
   */
  triggerAccountVerificationCode: async (email) =>
    api.post("/Users/tokens", { email, type: "OneTimePassword" }),

  /**
   *
   * @param {*} email
   * @returns {Promise<void>}
   */
  initiatePasswordReset: async (email) =>
    api.post("/Users/tokens", { email, type: "Password" }),

  /**
   *
   * @param {string} token
   * @param {string} email
   * @param {string} newPassword
   * @returns {Promise<User>}
   */
  resetPassword: async (token, email, newPassword) =>
    api.patch(`/Users/bytoken/${token}`, {
      email,
      token,
      password: newPassword,
    }),
};
