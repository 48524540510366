import { FiftyFifty, Input, Loader, StripeWrapper } from "components";
import purchaseImage from "assets/images/purchase.png";
import { H2, H3, H4, P, PrimaryButton } from "ui-library";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  selectReportId,
  selectSearchReports,
} from "store/features/search/searchSlice";
import { inspectionTypesSelector } from "store/features/inspectors/inspectorsSlice";
import { getFormattedDate } from "helpers";
import { Form } from "react-final-form";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import defaultProfilePhoto from "assets/images/default-profile-photo.svg";
import { DocumentTitle } from "components/document-title";
import Toast from "components/toast";
import {
  SEARCH_MASTER_ROUTE,
  SEARCH_REPORTS_MASTER_ROUTE,
  SEARCH_REPORTS_ROUTES,
} from "routes";

const ReportPreviewContainer = styled.div`
  width: 100%;
  max-width: 600px;
  background: #ffffff;
  box-shadow: 0px 5px 12px rgba(24, 105, 171, 0.15);
  border-radius: 20px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
`;

const ReportImage = styled.img`
  width: 160px;
  height: 100px;
  border-radius: 8px;
`;

const ReportContent = styled.div`
  margin-left: 16px;
`;

const Inspector = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const InspectorPhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const FormContent = styled.div`
  margin-top: 56px;
  width: 100%;
  max-width: 500px;
`;

function CheckoutForm({ reportId, inspectorCode }) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          process.env.REACT_APP_DOMAIN +
          "/" +
          SEARCH_MASTER_ROUTE +
          "/" +
          SEARCH_REPORTS_MASTER_ROUTE +
          `/${reportId}` +
          "/" +
          SEARCH_REPORTS_ROUTES.PURCHASE_CONFIRMATION,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      toast.error(<Toast title={result.error.message} />);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <FormContent>
            <Input
              name="promoCode"
              label="Do you have a promo code?"
              placeholder="Discount or promo code"
            />

            <H4 style={{ marginTop: 56, marginBottom: 16 }}>
              Credit card information
            </H4>

            <PaymentElement />

            <PrimaryButton
              style={{ width: "100%", marginTop: 24 }}
              onClick={handleSubmit}
            >
              <Loader loading={submitting} />
              {submitting ? "Purchasing ..." : "Purchase report"}
            </PrimaryButton>

            <P
              style={{
                lineHeight: "20px",
                marginTop: 16,
                textAlign: "center",
              }}
            >
              By purchasing this report, you accept the inspector's terms and
              conditions.
              {/* {" "}
              <A
                style={{
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
                onClick={() =>
                  InspectorsApi.downloadFileOfType(
                    inspectorCode,
                    "InspectionAgreement"
                  )
                }
              >
                
              </A>
              . */}
            </P>
          </FormContent>
        );
      }}
    />
  );
}

export default function PurchaseReport() {
  const reportId = useSelector(selectReportId);
  const reports = useSelector(selectSearchReports);
  const report = reports.find((report) => report.id === reportId);
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const filteredInspectionTypes = inspectionTypes.filter((_) =>
    report.inspectionTypeIds.includes(_.id)
  );

  return (
    <DocumentTitle title="Purchase Report">
      <StripeWrapper reportId={reportId} price={report.price}>
        <FiftyFifty image={purchaseImage} expanded>
          <H2>Purchase inspection report</H2>

          <ReportPreviewContainer>
            <ReportImage src={report.propertyImagePath + "?w=160&h=100"} />
            <ReportContent>
              <H4>
                {filteredInspectionTypes.length
                  ? filteredInspectionTypes.map((_) => _.name).join(", ") +
                    " report"
                  : report.inspectionTypeOther
                  ? report.inspectionTypeOther + " report"
                  : "Unknown inspection report"}
              </H4>
              <P style={{ fontSize: 20 }}>
                {report.address} -{" "}
                <em>{getFormattedDate(report.inspectionDate)}</em>
              </P>

              <Inspector>
                <InspectorPhoto
                  src={
                    report.inspector.imagePath
                      ? report.inspector.imagePath + "?w=40&h=40"
                      : defaultProfilePhoto
                  }
                />
                <P style={{ marginLeft: 16, color: "#143A5A" }}>
                  {report.inspector.fullName}
                </P>
              </Inspector>

              <Price>
                <H3>Total ${report.price}</H3>
                <P style={{ marginLeft: 8, color: "#143A5A" }}>inc. GST</P>
              </Price>
            </ReportContent>
          </ReportPreviewContainer>

          <CheckoutForm
            reportId={reportId}
            inspectorCode={report.inspector.code}
          />
        </FiftyFifty>
      </StripeWrapper>
    </DocumentTitle>
  );
}
