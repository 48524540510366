import styled from "styled-components";

export const PrimaryButton = styled.button`
  padding: 12px 20px;
  border-radius: 3px;
  background-color: #eb9f22;
  border: none;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  &:hover {
    background-color: #efb14e;
  }

  &:active {
    background-color: #df9112;
  }

  &:disabled {
    /* opacity: 0.3; */
    cursor: not-allowed;
  }
`;

export const DeleteButton = styled(PrimaryButton)`
  background-color: #ca0b0b;

  &:hover {
    background-color: #ca0b0b;
  }

  &:active {
    background-color: #ca0b0b;
  }
`;

export const RoundedPrimaryButton = styled(PrimaryButton)`
  @media only screen and (max-width: 576px) {
    font-size: 24px;
    padding: 20px;
  }
`;

export const SecondaryButton = styled.button`
  padding: 12px 20px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid #eb9f22;
  color: #eb9f22;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #efb14e;
    color: #ffffff;
  }

  &:active {
    background-color: #eb9f22;
    color: #ffffff;
  }
`;

export const TertiaryButton = styled.button`
  display: block;
  padding: 12px 20px;
  border-radius: 3px;
  background-color: #ffffff;
  border: none;
  color: #028cb0;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #e4f0f7;
  }

  &:active {
    background-color: #d6eaf5;
  }
`;

export const TertiaryAlertButton = styled.button`
  padding: 12px 20px;
  border-radius: 3px;
  background-color: #ffffff;
  border: none;
  color: #ca0b0b;
  cursor: pointer;

  &:hover {
    background-color: #f7ebec;
  }

  &:active {
    background-color: #f1d9d9;
  }
`;

export const CustomButton = styled.div`
  width: 328px;
  height: 64px;
  background-color: #efefef;
  transition: all 150ms linear;
  border-radius: 5px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  cursor: pointer;

  &:hover {
    background-color: #d7d7d7;
  }

  &:active {
    background-color: #d7d7d7;
  }
`;

export const Separator = styled.hr`
  height: 1px;
  border: none;
  background-color: #e4f0f7;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const LinkButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 12px 0;
  border-radius: 3px;
  background-color: transparent;
  border: none;
  color: #028cb0;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  font-family: HeadingFont;

  &:hover {
    text-decoration: underline;
  }
`;

export function LinkButton({ children, onClick, style }) {
  return (
    <LinkButtonContainer style={style} onClick={onClick}>
      {children}
    </LinkButtonContainer>
  );
}
