import { createRef, useEffect } from "react";
import defaultAvatar from "assets/images/default-profile-photo.svg";
import defaultProperty from "assets/images/defaultProperty.png";

export default function NetworkImage({
  className,
  src,
  isAvatar = true,
  onClick,
}) {
  const imageRef = createRef();

  useEffect(() => {
    if (src) {
      const image = new Image();
      image.onload = () => {
        if (imageRef.current) {
          imageRef.current.src = src;
        }
      };
      image.onerror = () => {
        if (imageRef.current) {
          imageRef.current.src = isAvatar ? defaultAvatar : defaultProperty;
        }
      };
      image.src = src;
    } else {
      imageRef.current.src = defaultAvatar;
    }
  }, [imageRef, isAvatar, src]);

  return <img ref={imageRef} className={className} alt="" onClick={onClick} />;
}
