import styled from "styled-components";

export const Spacer = styled.div`
  height: 60px;
  @media (min-width: 1020px) {
    height: 80px;
  }
`;

export const hasInspectorProvidedRequiredInfo = (inspector, company) => {
  const hasProvidedRequiredInfo =
    inspector.bio?.length > 0 &&
    inspector.inspectionTypeIds?.length > 0 &&
    !!inspector.address &&
    !!inspector.searchRadiusInKilometres &&
    !!company.name;

  return hasProvidedRequiredInfo;
};
