import styled from "styled-components";
import { P } from "ui-library";

const VideoContainer = styled.div`
  display: inline-block;
  width: 228px;
  margin-right: 40px;

  @media only screen and (max-width: 768px) {
    margin-right: 16px;
  }
`;

const VideoEmbedContainer = styled.div`
  width: 100%;
  height: 144px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
`;

export function Video() {
  return (
    <VideoContainer>
      <VideoEmbedContainer>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video height="100%" controls>
          <source
            src="http://techslides.com/demos/sample-videos/small.webm"
            type="video/webm"
          />
          <source
            src="http://techslides.com/demos/sample-videos/small.ogv"
            type="video/ogg"
          />
          <source
            src="http://techslides.com/demos/sample-videos/small.mp4"
            type="video/mp4"
          />
          <source
            src="http://techslides.com/demos/sample-videos/small.3gp"
            type="video/3gp"
          />
        </video>
      </VideoEmbedContainer>

      <P style={{ marginTop: 16 }}>New inspection</P>
      <P style={{ fontSize: 16 }}>
        <em>Added on June 21, 2021</em>
      </P>
    </VideoContainer>
  );
}
