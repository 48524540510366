import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 55px;
  line-height: 66px;
  letter-spacing: 0.2px;
  color: #143a5a;

  @media only screen and (max-width: 576px) {
    font-size: 25px;
    line-height: 33px;
  }
`;

export const H2 = styled.h2`
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.2px;
  color: #143a5a;

  @media only screen and (max-width: 576px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

export const H3 = styled.h3`
  font-size: 26px;
  line-height: 66px;
  letter-spacing: 0.2px;
  color: #143a5a;

  @media only screen and (max-width: 576px) {
    font-size: 17px;
    line-height: 21px;
  }
`;

export const H4 = styled.h4`
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: #143a5a;

  @media only screen and (max-width: 576px) {
    font-size: 15px;
    line-height: 18px;
  }
`;

export const H5 = styled.h5`
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #143a5a;
`;

export const P = styled.p`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #798995;
`;

export const P1 = styled.p`
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: #798995;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const A = styled.a`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #028cb0;
  text-decoration: none;
  cursor: pointer;

  &.regular {
    font-size: unset;
    line-height: unset;
  }

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const InternalA = styled(NavLink)`
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: #028cb0;
  text-decoration: none;
  cursor: pointer;

  &.regular {
    font-size: unset;
    line-height: unset;
  }

  &:hover {
    text-decoration: underline;
  }

  @media only screen and (max-width: 576px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const UL = styled.ul`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #798995;
  padding-left: 32px;
`;

export const OL = styled.ol`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #798995;
  padding-left: 32px;
`;

export const P2 = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #798995;
`;
