export default function BlueAndYellowBlob() {
  return (
    <svg
      width="751"
      height="789"
      viewBox="0 0 751 789"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3" filter="url(#filter0_f_222_31)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M532.209 352.001C521.173 386.669 495.914 413.19 473.037 441.462C453.326 465.821 432.32 487.535 407.292 506.373C379.414 527.356 353.122 556.558 318.273 557.946C283.311 559.339 253.951 533.407 225.067 513.634C197.911 495.044 178.575 469.427 155.166 446.286C123.126 414.612 67.8334 396.642 61.5661 352.001C55.5073 308.845 99.4887 275.35 126.353 241.061C150.162 210.671 176.738 183.755 209.438 163.262C243.015 142.219 279.082 115.019 318.273 120.785C357.401 126.54 374.038 176.499 410.088 192.779C452.116 211.758 514.755 185.58 542.836 222.192C569.703 257.222 545.604 309.919 532.209 352.001Z"
          fill="#93D3FA"
        />
      </g>
      <g opacity="0.3" filter="url(#filter1_f_222_31)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M608.209 463.001C597.173 497.669 571.914 524.19 549.037 552.462C529.326 576.821 508.32 598.535 483.292 617.373C455.414 638.356 429.122 667.558 394.273 668.946C359.311 670.339 329.951 644.407 301.067 624.634C273.911 606.044 254.575 580.427 231.166 557.286C199.126 525.612 143.833 507.642 137.566 463.001C131.507 419.845 175.489 386.35 202.353 352.061C226.162 321.671 252.738 294.755 285.438 274.262C319.015 253.219 355.082 226.019 394.273 231.785C433.401 237.54 450.038 287.499 486.088 303.779C528.116 322.758 590.755 296.58 618.836 333.192C645.703 368.222 621.604 420.919 608.209 463.001Z"
          fill="#FFC25F"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_222_31"
          x="-59"
          y="0"
          width="734"
          height="678"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="60"
            result="effect1_foregroundBlur_222_31"
          />
        </filter>
        <filter
          id="filter1_f_222_31"
          x="17"
          y="111"
          width="734"
          height="678"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="60"
            result="effect1_foregroundBlur_222_31"
          />
        </filter>
      </defs>
    </svg>
  );
}
