import styled from "styled-components";
import InternalSearch from "./internal-search";

const Container = styled.div``;

export default function SquareSpaceReportSearch() {
  return (
    <Container>
      <InternalSearch product="reports" />

      <style
        dangerouslySetInnerHTML={{
          __html: "#root > div { margin-left: 0; }",
        }}
      />
    </Container>
  );
}
