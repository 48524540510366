import { Navbar, Footer } from "components";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import { useAuth } from "./authentication";
import { Hidden, Visible } from "react-grid-system";

const OutletContainer = styled.div`
  flex-grow: 1;
`;

export default function Layout() {
  const auth = useAuth();
  const isAuthenticated = !!auth?.session;

  return (
    <>
      <Hidden xs>
        <style
          dangerouslySetInnerHTML={{ __html: "#root { padding-top: 104px; }" }}
        />
      </Hidden>

      <Visible xs>
        <style
          dangerouslySetInnerHTML={{ __html: "#root { padding-top: 56px; }" }}
        />
      </Visible>

      <Navbar authenticated={isAuthenticated} />

      <OutletContainer>
        <Outlet />
      </OutletContainer>

      <Footer />
    </>
  );
}
