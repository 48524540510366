import { api } from "./api";

/**
 * @typedef {Object} ConnectedStripeAccount
 * @property {string} name
 * @property {string} email
 * @property {'PendingInformation' | 'PendingVerification' | 'Completed'} status
 */

/**
 * @typedef {Object} Company
 * @property {string} name
 * @property {string} [phone]
 * @property {string} [logoPath]
 * @property {ConnectedStripeAccount} [stripe]
 */

export const CompaniesApi = {
  /**
   * @param companyInfo Information about the company.
   * @param companyInfo.name The name of the company.
   * @param companyInfo.logoFileBase64 The logo of the company, encoded.
   * @param companyInfo.logoFileName The logo of the company.
   * @returns {Promise<void>}
   */
  createCompany: async (companyInfo) => api.post("/Companies", companyInfo),
  /**
   * Retreives info about current company
   * @returns {Promise<Company>}
   */
  getCurrentCompany: async () => api.get("/Companies/current"),
  /**
   * Retreives info about current company
   * @param companyInfo Information about the company.
   * @param companyInfo.name Information about the company.
   * @param [companyInfo.logoFileBase64] Information about the company.
   * @param [companyInfo.logoFileName] Information about the company.
   * @returns {Promise<void>}
   */
  updateCurrentCompany: async (companyInfo) =>
    api.put("/Companies/current", companyInfo),
};
