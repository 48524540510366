export const loadMarkerIO = () => {
  const body = window.document.body;

  if (process.env.NODE_ENV !== "production") {
    window["markerConfig"] = {
      destination: "628651e72de1981095d713d6",
      source: "snippet",
    };

    const script = window.document.createElement("script");
    script.innerHTML = `
    !(function (e, r, a) {
      if (!e.__Marker) {
        e.__Marker = {};
        var t = [],
          n = { __cs: t };
        [
          "show",
          "hide",
          "isVisible",
          "capture",
          "cancelCapture",
          "unload",
          "reload",
          "isExtensionInstalled",
          "setReporter",
          "setCustomData",
          "on",
          "off",
        ].forEach(function (e) {
          n[e] = function () {
            var r = Array.prototype.slice.call(arguments);
            r.unshift(e), t.push(r);
          };
        }),
          (e.Marker = n);
        var s = r.createElement("script");
        (s.async = 1), (s.src = "https://edge.marker.io/latest/shim.js");
        var i = r.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(s, i);
      }
    })(window, document);
    `;
    script.async = true;
    script.defer = true;

    body.appendChild(script);
  }
};
