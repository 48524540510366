import Toast from "components/toast";
import { createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ACCOUNT_MASTER_ROUTE,
  ACCOUNT_ROUTES,
  INSPECTORS_MASTER_ROUTE,
  INSPECTOR_ROUTES,
  USERS_MASTER_ROUTE,
} from "routes";
import {
  CompaniesApi,
  InspectorsApi,
  UsersApi,
  UserSessionsApi,
} from "services";
import {
  setCurrentCompany,
  companySelector,
} from "store/features/companies/companiesSlice";
import {
  inspectorSelector,
  updateCurrentInspector,
} from "store/features/inspectors/inspectorsSlice";
import {
  selectAccount,
  selectSession,
  setSession,
  updateUser,
} from "store/features/user/userSlice";
import { store } from "store/store";
import { hasInspectorProvidedRequiredInfo } from "./helpers";

/**
 * @function logoutFunction
 * @returns {Promise<void>}
 */

/**
 * @typedef {Object} IAuthContext
 * @property {import('services').User} [user]
 * @property {UserSession} [session]
 * @property {(email:string, password:string, next?: string) => Promise<UserSession>} login
 * @property {logoutFunction} logout
 */

const AuthContext = createContext(null);

/**
 *
 * @returns {IAuthContext}
 */
export function useAuth() {
  return useContext(AuthContext);
}

const authProvider = {
  /**
   *
   * @param {string} email
   * @param {string} password
   * @returns {Promise<UserSession>}
   */
  signIn: async (email, password) => {
    try {
      const userSession = await UserSessionsApi.signInWithEmail({
        email,
        password,
      });

      return userSession;
    } catch (e) {
      toast.error(<Toast title={e?.response?.data?.globalErrors[0].message} />);
      throw e;
    }
  },
  signOut: async () => UserSessionsApi.signOutCurrentUser(),
};

export function AuthProvider({ children }) {
  const session = useSelector(selectSession);
  const user = useSelector(selectAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (email, password, next) => {
    const loggedInUserSession = await authProvider.signIn(email, password);
    dispatch(setSession(loggedInUserSession));

    const loggedInUser = await UsersApi.getCurrentUser();
    dispatch(updateUser(loggedInUser));

    const isInspector = loggedInUser?.roles.indexOf("CompanyAdmin") > -1;
    let inspector;
    let company;

    if (isInspector) {
      inspector = await InspectorsApi.getCurrentInspectorProfile();
      dispatch(updateCurrentInspector(inspector));

      company = await CompaniesApi.getCurrentCompany();
      dispatch(setCurrentCompany(company));
    }

    const hasProvidedAllRequiredInfo = isInspector
      ? hasInspectorProvidedRequiredInfo(inspector, company)
      : false;
    if (inspector && !hasProvidedAllRequiredInfo) {
      navigate(
        "/" + INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.ONBOARDING_INTRO
      );
    } else if (next) {
      navigate(next);
    } else if (isInspector) {
      navigate("/" + INSPECTORS_MASTER_ROUTE);
    } else {
      navigate("/" + USERS_MASTER_ROUTE);
    }
  };

  const logout = async () => {
    await authProvider.signOut();
    store.dispatch({
      type: "CLEAR_OUT",
    });
  };

  const value = { user, session, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const inspector = useSelector(inspectorSelector);
  const company = useSelector(companySelector);

  if (!auth.session) {
    const LOGIN_ROUTE = "/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.LOGIN;
    const NEXT_ROUTE = !location.pathname.startsWith(LOGIN_ROUTE)
      ? location.pathname === "/account/logout"
        ? ""
        : `?next=${encodeURIComponent(location.pathname)}`
      : "";

    return <Navigate to={LOGIN_ROUTE + NEXT_ROUTE} />;
  }

  // all users need to verify their phone number
  const VERIFY_ROUTE = "/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.VERIFY;

  if (auth.session.status === "Registered") {
    if (location.pathname.startsWith(VERIFY_ROUTE)) {
      return children;
    }

    return <Navigate to={VERIFY_ROUTE} />;
  }

  if (auth.session?.roles.indexOf("CompanyAdmin") > -1) {
    // is inspector
    const isBeingOnboarded = location.pathname.startsWith(
      "/" + INSPECTORS_MASTER_ROUTE + "/onboarding"
    );

    const hasProvidedAllRequiredInfo = hasInspectorProvidedRequiredInfo(
      inspector,
      company
    );

    if (!hasProvidedAllRequiredInfo) {
      if (isBeingOnboarded) {
        return children;
      } else {
        return (
          <Navigate
            to={
              "/" +
              INSPECTORS_MASTER_ROUTE +
              "/" +
              INSPECTOR_ROUTES.ONBOARDING_INTRO
            }
          />
        );
      }
    } else {
      if (isBeingOnboarded) {
        return (
          <Navigate
            to={
              "/" + INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.DASHBOARD
            }
          />
        );
      } else {
        return children;
      }
    }
  } else {
    // customer
    return children;
  }
}

export function DoNotRequireAuth({ children }) {
  const auth = useAuth();
  const dispatch = useDispatch();

  if (auth?.session) {
    dispatch({
      type: "CLEAR_OUT",
    });
  }

  return children;
}
