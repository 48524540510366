import styled from "styled-components";
import { A, H2, P1, PrimaryButton, SecondaryButton } from "ui-library";
import image from "../../../assets/images/inspection.png";
import { Hidden, Visible } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import {
  INSPECTORS_MASTER_ROUTE,
  INSPECTOR_ROUTES,
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
} from "routes";
import { Form } from "react-final-form";
import { MultiSelect } from "components";
import {
  inspectionTypesSelector,
  inspectorSelector,
} from "store/features/inspectors/inspectorsSlice";
import { useSelector } from "react-redux";
import { requiredValidator } from "components/form/validators";
import { companySelector } from "store/features/companies/companiesSlice";

const OnboardingStep = styled.div`
  height: calc(100vh - 104px);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 576px) {
    overflow: auto;
    height: calc(100dvh - 56px);
  }
`;

const OnboardingStepContainer = styled.div`
  flex-grow: 1;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 91px;
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    padding-top: 35px;
  }
`;

const StepContent = styled.div`
  display: flex;
`;

const LeftContent = styled.div`
  @media only screen and (max-width: 576px) {
    padding-bottom: 32px;
  }
`;

const StepLabel = styled.p`
  color: #143a5a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;

  span {
    color: #798995;
  }
`;

const RightContent = styled.div`
  margin-left: 57px;

  img {
    max-width: 450px;
  }
`;

const Action = styled.div`
  background-color: white;

  @media only screen and (max-width: 576px) {
    position: sticky;
    bottom: 0;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;

  @media only screen and (max-width: 576px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 5px;
  background-color: #e4f0f7;
`;

const Progress = styled.div`
  height: 5px;
  width: 33%;
  background-color: #00e096;
`;

export default function OnboardingStep1() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const inspector = useSelector(inspectorSelector);
  const company = useSelector(companySelector);

  const initialValues = {
    bio: inspector.bio,
    inspectionTypeIds: inspector.inspectionTypeIds,
    name: company.name,
    searchRadiusInKilometres: inspector.searchRadiusInKilometres,
    addressDisplay: inspector.address?.formatted,
    ...state,
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(values) => {
        navigate(
          "/" +
            INSPECTORS_MASTER_ROUTE +
            "/" +
            INSPECTOR_ROUTES.ONBOARDING_STEP_2,
          {
            state: values,
          }
        );
      }}
      render={({ handleSubmit }) => (
        <OnboardingStep>
          <OnboardingStepContainer>
            <StepContent>
              <LeftContent>
                <StepLabel>
                  Step 1 <span>of 3</span>
                </StepLabel>

                <H2>What are the main inspection types you offer?</H2>

                <P1 style={{ marginTop: 19, color: "#143A5A" }}>
                  Adding the inspection types that you offer will show your
                  clients what you offer, and help us recommending you.
                </P1>

                <Hidden xs>
                  <A
                    href={
                      "/" +
                      PUBLIC_MASTER_ROUTE +
                      "/" +
                      PUBLIC_ROUTES.INSPECTION_TYPES
                    }
                    target="_blank"
                    style={{
                      display: "block",
                      marginBottom: 44,
                      fontSize: "20px",
                      lineHeight: "32px",
                    }}
                  >
                    View list of inspections
                  </A>
                </Hidden>

                <Visible xs>
                  <A
                    href={
                      "/" +
                      PUBLIC_MASTER_ROUTE +
                      "/" +
                      PUBLIC_ROUTES.INSPECTION_TYPES
                    }
                    target="_blank"
                    style={{
                      display: "block",
                      marginBottom: 24,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    View list of inspections
                  </A>
                </Visible>

                <MultiSelect
                  name="inspectionTypeIds"
                  placeholder="Start typing to pick inspection types"
                  options={inspectionTypes.map((inspectionType) => ({
                    label: inspectionType.name,
                    value: inspectionType.id,
                  }))}
                  validators={requiredValidator}
                />
              </LeftContent>

              <Hidden xs>
                <RightContent>
                  <img src={image} style={{ marginTop: 144 }} alt="cover" />
                </RightContent>
              </Hidden>
            </StepContent>
          </OnboardingStepContainer>

          <Action>
            <ProgressBar>
              <Progress />
            </ProgressBar>

            <Footer>
              <SecondaryButton onClick={() => navigate(-1)}>
                Back
              </SecondaryButton>

              <Hidden xs>
                <PrimaryButton onClick={handleSubmit}>
                  Next, add your bio
                </PrimaryButton>
              </Hidden>

              <Visible xs>
                <PrimaryButton onClick={handleSubmit}>Next</PrimaryButton>
              </Visible>
            </Footer>
          </Action>
        </OnboardingStep>
      )}
    />
  );
}
