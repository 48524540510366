import PropTypes from "prop-types";
import styled from "styled-components";
import starIcon from "assets/icons/star.svg";

const DisplayRatingContainer = styled.div`
  padding: 4px 8px;
  border-radius: 100px;
  background-color: #feba00;
  display: inline-flex;
  align-items: center;
`;

const RatingContent = styled.span`
  font-size: 15px;
  line-height: 12px;
  letter-spacing: 0.3px;
  color: #ffffff;
`;

export function DisplayRating({ rating, className }) {
  return (
    <DisplayRatingContainer className={className}>
      <RatingContent>
        {rating}
        /5
      </RatingContent>
      <img
        src={starIcon}
        width={14}
        style={{ marginTop: -1, marginLeft: 4 }}
        alt="rating star"
      />
    </DisplayRatingContainer>
  );
}
DisplayRating.propTypes = {
  rating: PropTypes.number.isRequired,
};
