import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { BackButton, FiftyFifty, Loader } from "components";
import { H2, P, PrimaryButton, H3, A, InternalA } from "ui-library";
import { throttle } from "lodash";
import { InspectionTypesApi, InspectorsApi } from "services";
import { displayErrorMessage } from "services/api";
import {
  ACCOUNT_MASTER_ROUTE,
  ACCOUNT_ROUTES,
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import NetworkImage from "components/network-image";
import { Col, Container, Row } from "react-grid-system";
import findProfileImage from "assets/images/find-profile.png";

const SearchArea = styled.div`
  margin-top: 32px;
  width: 100%;
`;

const SearchBarContainer = styled.div`
  position: relative;
  max-width: 500px;
  margin-top: 8px;
  box-shadow: 0px 14px 50px rgba(24, 105, 171, 0.08);

  @media only screen and (max-width: 576px) {
    box-shadow: none;
  }
`;

const SearchBar = styled.input`
  height: 56px;
  background-color: #ffffff;
  border-radius: 32px;
  padding: 8px 24px;
  color: #143a5a;
  border: none;
  width: 100%;
  font-size: 16px;
  line-height: 34px;

  @media only screen and (max-width: 576px) {
    box-shadow: none;
    background-color: #f7f9fc;
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InspectorList = styled.div`
  margin-top: 8px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  max-height: 280px;
  overflow: auto;
  max-width: 500px;
  border-radius: 4px;
  border: 1px solid #e4f0f7;
`;

const InspectorSuggestionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: #f7f9fc;
  }
`;

const InspectorPhoto = styled(NetworkImage)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

const InspectorDetails = styled.div`
  margin-left: 16px;
`;

const SelectedInspector = styled.div`
  margin-top: 32px;
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  background-color: #fff;
  padding: 32px;
  display: flex;
  align-items: flex-start;
  box-shadow: 0px 5px 12px rgba(24, 105, 171, 0.15);

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    padding: 16px 24px;
  }
`;

const SelectedInspectorPhoto = styled(NetworkImage)`
  width: 105px;
  height: 105px;
  border-radius: 50%;
  flex-shrink: 0;

  @media only screen and (max-width: 576px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const SelectedPhotoCopy = styled.div`
  margin-left: 16px;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
    margin-top: 16px;
    text-align: center;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 32px;
  width: 240px;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

function InspectorSuggestion({ inspector, onClick }) {
  return (
    <InspectorSuggestionContainer onClick={onClick}>
      <InspectorPhoto
        src={
          inspector.profile.imagePath
            ? inspector.profile.imagePath + "?w=45&h=45"
            : null
        }
      />

      <InspectorDetails>
        <p>
          <strong>
            {inspector.profile?.fullName?.length
              ? inspector.profile?.fullName
              : inspector.company?.name &&
                inspector.company?.name !== "Company name"
              ? inspector.company?.name
              : "NA"}
          </strong>
        </p>

        {inspector.profile?.fullName?.length ? (
          inspector.company?.name &&
          inspector.company?.name !== "Company name" ? (
            <p style={{ color: "#798995" }}>{inspector.company?.name}</p>
          ) : (
            <noscript />
          )
        ) : (
          <p style={{ color: "#798995" }}>{inspector.profile?.email}</p>
        )}
      </InspectorDetails>
    </InspectorSuggestionContainer>
  );
}

export default function FindProfile() {
  const navigate = useNavigate();
  // const [accountType, setAccountType] = useState(0); // Inspectors - 0, Inspection companies - 1\
  const [inspectors, setInspectors] = useState();
  const [currentValue, setCurrentValue] = useState("");
  const [selectedInspector, setSelectedInspector] = useState();
  const [inspectionTypes, setInspectionTypes] = useState();
  const [isFetchingNewResults, setIsFetchingNewResults] = useState(false);

  // methods
  const searchForExistingAccounts = throttle(async (val) => {
    if (val?.length >= 2 && !isFetchingNewResults) {
      try {
        setIsFetchingNewResults(true);
        const response = await InspectorsApi.getInspectors({
          name: val.trim(),
        });
        setInspectors(response.data);
      } catch (e) {
        displayErrorMessage(e);
      } finally {
        setIsFetchingNewResults(false);
      }
    }
  }, 10000);

  const ignoreMoreThanOneSpace = (event) => {
    const KEY_CODE_FOR_SPACE = 32;

    if (
      event.keyCode === KEY_CODE_FOR_SPACE &&
      currentValue.at(currentValue.length - 1) === " "
    ) {
      event.preventDefault();
      return false;
    }

    return;
  };

  useEffect(() => {
    searchForExistingAccounts(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  useEffect(() => {
    async function fetchInspectionTypes() {
      try {
        const types = await InspectionTypesApi.getInspectionTypes();
        setInspectionTypes(types);
      } catch (e) {}
    }
    fetchInspectionTypes();
  }, []);

  const inspectionTypeNames = selectedInspector
    ? selectedInspector.inspectionTypeIds.map((inspectionTypeId) => {
        const inspectionType = inspectionTypes.find(
          (_) => _.id === inspectionTypeId
        );
        return inspectionType.name;
      })
    : [];

  return (
    <FiftyFifty image={findProfileImage}>
      <div style={{ maxWidth: 500, marginLeft: 0, width: "100%" }}>
        {/* Back Button */}
        <Container>
          <Row>
            <Col xs={12}>
              <BackButton />
            </Col>

            <Col xs={12}>
              {/* Step */}
              <P style={{ color: "#143A5A", marginTop: 24 }}>
                Step 1 <span style={{ color: "#798995" }}>of 3</span>
              </P>

              {/* Title */}
              <H2>Find your profile</H2>
            </Col>

            <Col xs={12}>
              {/* Search Area */}
              <SearchArea>
                <P style={{ color: "#143A5A" }}>
                  Search your name or your company name
                </P>
                <SearchBarContainer>
                  <SearchBar
                    placeholder="Search your name"
                    onFocus={() => setSelectedInspector(null)}
                    onChange={(e) => setCurrentValue(e.target.value)}
                    onKeyDown={ignoreMoreThanOneSpace}
                  />

                  <LoaderContainer>
                    <Loader loading={isFetchingNewResults} color="#eb9f22" />
                  </LoaderContainer>
                </SearchBarContainer>

                {!selectedInspector && inspectors?.length ? (
                  <InspectorList>
                    {inspectors
                      // .filter((_) => _.isClaimable)
                      .filter(
                        (_) =>
                          _.profile?.fullName?.length > 0 ||
                          _.company?.name?.length > 0
                      )
                      .map((inspector) => (
                        <InspectorSuggestion
                          key={inspector.id}
                          inspector={inspector}
                          onClick={() => setSelectedInspector(inspector)}
                        />
                      ))}
                  </InspectorList>
                ) : (
                  <noscript />
                )}
              </SearchArea>

              {selectedInspector ? (
                <>
                  <P
                    style={{ fontWeight: 700, color: "#143A5A", marginTop: 60 }}
                  >
                    Is this you? If not, try searching again
                  </P>

                  <SelectedInspector>
                    <SelectedInspectorPhoto
                      src={
                        selectedInspector.profile.imagePath
                          ? selectedInspector.profile.imagePath + "?w=105&h=105"
                          : null
                      }
                    />

                    <SelectedPhotoCopy>
                      <H3 style={{ lineHeight: "normal", fontSize: 20 }}>
                        {selectedInspector.company?.name &&
                        selectedInspector.company?.name !== "Company name"
                          ? selectedInspector.company?.name
                          : selectedInspector.profile.fullName}
                      </H3>
                      <P style={{ color: "#143A5A", marginTop: 4 }}>
                        {inspectionTypeNames.length > 1
                          ? inspectionTypeNames[0] +
                            ` + ${inspectionTypeNames.length - 1} more`
                          : inspectionTypeNames[0]}
                      </P>

                      <P style={{ marginTop: 8 }}>
                        <em>{selectedInspector.location}</em>
                      </P>

                      {selectedInspector.isClaimable ? (
                        <StyledPrimaryButton
                          onClick={() =>
                            navigate(
                              "/" +
                                SEARCH_MASTER_ROUTE +
                                "/" +
                                SEARCH_ACCOUNTS_MASTER_ROUTE +
                                "/" +
                                SEARCH_ACCOUNTS_ROUTES.CLAIM,
                              {
                                state: {
                                  selectedInspector,
                                  inspectionTypes,
                                },
                              }
                            )
                          }
                        >
                          Claim this profile
                        </StyledPrimaryButton>
                      ) : (
                        <StyledPrimaryButton disabled>
                          Profile Claimed
                        </StyledPrimaryButton>
                      )}

                      {selectedInspector.isClaimable ? (
                        <P
                          style={{
                            marginTop: 16,
                          }}
                        >
                          Need help with this profile?{" "}
                          <A
                            href={`mailto:info@ratemyinspectors.com?subject=Inspector profile for ${selectedInspector.profile.fullName} on RateMyInspectors`}
                          >
                            Contact us
                          </A>
                        </P>
                      ) : (
                        <P
                          style={{
                            marginTop: 16,
                            fontSize: "14px",
                            lineHeight: "normal",
                          }}
                        >
                          Has your profile already been claimed? Please contact
                          our{" "}
                          <A
                            style={{ fontSize: "14px" }}
                            target="_blank"
                            href={`mailto:info@ratemyinspectors.com?subject=Inspector profile for ${selectedInspector.profile.fullName} on RateMyInspectors`}
                          >
                            support team
                          </A>
                          .
                        </P>
                      )}
                    </SelectedPhotoCopy>
                  </SelectedInspector>

                  <P
                    style={{
                      textAlign: "center",
                      marginTop: 32,
                    }}
                  >
                    Can’t find your profile?{" "}
                    <InternalA
                      style={{ fontSize: "16px" }}
                      to={
                        "/" +
                        ACCOUNT_MASTER_ROUTE +
                        "/" +
                        ACCOUNT_ROUTES.REGISTER +
                        "?type=inspector"
                      }
                    >
                      Create your free profile
                    </InternalA>{" "}
                    and instantly request ratings from clients and sell existing
                    reports.
                  </P>
                </>
              ) : (
                <noscript />
              )}

              {inspectors !== null &&
              inspectors !== undefined &&
              !inspectors?.length ? (
                <>
                  <P style={{ color: "#143A5A", marginTop: 32 }}>
                    <strong>We do not have a profile for you just yet.</strong>
                  </P>
                  <P style={{ marginTop: 8 }}>
                    Can’t find your profile? Create your free profile and
                    instantly request ratings from clients and sell existing
                    reports.
                  </P>
                  <PrimaryButton
                    style={{ marginTop: 32 }}
                    onClick={() =>
                      navigate(
                        "/" +
                          ACCOUNT_MASTER_ROUTE +
                          "/" +
                          ACCOUNT_ROUTES.REGISTER +
                          "?type=inspector"
                      )
                    }
                  >
                    Activate your free profile
                  </PrimaryButton>
                </>
              ) : (
                <noscript />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </FiftyFifty>
  );
}
