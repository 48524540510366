import styled from "styled-components";
import stripeLogo from "assets/icons/stripe.svg";
import { P, PrimaryButton } from "ui-library";
import { useSelector } from "react-redux";
import { companySelector } from "store/features/companies/companiesSlice";
import { Modal } from "./modal";
import { inspectorSelector } from "store/features/inspectors/inspectorsSlice";
import { useNavigate } from "react-router-dom";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import { CompanyPaymentsApi } from "services";

const StripeConnectBannerContainer = styled.div`
  border-radius: 20px;
  background-color: #f5fafd;
  padding: 32px 40px;
  margin-top: 16px;

  &.pending {
    border: 1px solid #fff1c2;
    background-color: #fffdf2;
  }

  &.connected {
    border: 1px solid #00e58f;
    background-color: #e5fdf4;
  }
`;

const Logo = styled.img`
  width: 84px;
  margin-left: -8px;
`;

function ConnectToStripeButton() {
  const inspector = useSelector(inspectorSelector);
  const navigate = useNavigate();

  if (inspector?.completionPercentage !== 100) {
    return (
      <Modal
        title="Connect to Stripe"
        size="small"
        trigger={
          <PrimaryButton style={{ marginTop: 16, fontSize: 14 }}>
            Connect your Stripe account
          </PrimaryButton>
        }
      >
        {(closeModal) => (
          <>
            <P>
              You need to complete your inspector profile before setting up your
              Stripe account.
            </P>

            <PrimaryButton
              style={{ width: 200, marginTop: 32 }}
              onClick={() => {
                closeModal();
                navigate(
                  "/" +
                    INSPECTORS_MASTER_ROUTE +
                    "/" +
                    INSPECTOR_ROUTES.EDIT_PROFILE
                );
              }}
            >
              Complete my profile
            </PrimaryButton>
          </>
        )}
      </Modal>
    );
  }

  return (
    <PrimaryButton
      style={{ marginTop: 16, fontSize: 14 }}
      onClick={() => {
        window.location.href =
          process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_API_EXTENSION +
          "/companies/current/payout/setup?apikey=" +
          process.env.REACT_APP_API_KEY;
      }}
    >
      Connect your Stripe account
    </PrimaryButton>
  );
}

export function StripeConnectBanner() {
  const currentCompany = useSelector(companySelector);

  if (currentCompany?.stripe) {
    // Completed
    if (currentCompany.stripe.status === "Completed") {
      return (
        <StripeConnectBannerContainer className="connected">
          <Logo src={stripeLogo} alt="stripe logo" />

          <P>
            You are currently accepting card payments through Stripe account{" "}
            <b>{currentCompany.stripe.name}</b> associated with{" "}
            <b>{currentCompany.stripe.email}</b>
          </P>

          <PrimaryButton
            style={{ marginTop: 16 }}
            onClick={() => {
              CompanyPaymentsApi.setupReportSalesPayouts();
            }}
          >
            Go to your Stripe account
          </PrimaryButton>
        </StripeConnectBannerContainer>
      );
    }

    // Pending Verification
    if (currentCompany.stripe.status === "PendingVerification") {
      return (
        <StripeConnectBannerContainer className="pending">
          <Logo src={stripeLogo} alt="stripe logo" />

          <P>
            Connection to Stripe account <b>{currentCompany.stripe.name}</b>{" "}
            associated with <b>{currentCompany.stripe.email}</b> is currently
            pending verification. Please check your email for details. You can
            also refresh to check the current status.
          </P>

          <PrimaryButton
            style={{ marginTop: 16 }}
            onClick={window.location.reload}
          >
            Refresh now
          </PrimaryButton>
        </StripeConnectBannerContainer>
      );
    }

    // Pending Information
    return (
      <StripeConnectBannerContainer className="pending">
        <Logo src={stripeLogo} alt="stripe logo" />

        <P>
          Connection to Stripe account <b>{currentCompany.stripe.name}</b>{" "}
          associated with <b>{currentCompany.stripe.email}</b> is currently
          pending. Visit the Stripe dashboard for more information.
        </P>

        <PrimaryButton
          style={{ marginTop: 16 }}
          onClick={() => {
            CompanyPaymentsApi.setupReportSalesPayouts();
          }}
        >
          Go to your Stripe account
        </PrimaryButton>
      </StripeConnectBannerContainer>
    );
  }

  return (
    <StripeConnectBannerContainer>
      <Logo src={stripeLogo} alt="stripe logo" />

      <P>
        Allow for clients to purchase and pay for inspection reports by
        connecting your stripe account. Stripe fees will apply.
      </P>

      <ConnectToStripeButton />
    </StripeConnectBannerContainer>
  );
}
