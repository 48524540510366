export default function VerifiedIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#EB9F22"
      />
      <path
        d="M35 19.4166L22.25 25.0833V33.5833C22.25 41.4458 27.69 48.7983 35 50.5833C42.31 48.7983 47.75 41.4458 47.75 33.5833V25.0833L35 19.4166ZM44.9167 33.5833C44.9167 39.9866 40.695 45.8941 35 47.6508C29.305 45.8941 25.0833 39.9866 25.0833 33.5833V26.925L35 22.5191L44.9167 26.925V33.5833ZM28.4975 34.4191L26.5 36.4166L32.1667 42.0833L43.5 30.75L41.5025 28.7383L32.1667 38.0741L28.4975 34.4191Z"
        fill="#EB9F22"
      />
    </svg>
  );
}
