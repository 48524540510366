import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import closeIcon from "assets/icons/close.svg";
import NetworkImage from "./network-image";

const Image = styled(NetworkImage)`
  margin-top: 16px;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin-bottom: 24px;
  border: 1px solid #edf1f7;
  margin-right: 12px;
  margin-bottom: 12px;
`;

const LightboxContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #143a5ad4;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LightboxImage = styled(NetworkImage)`
  max-width: 80vw;
  max-height: 90vh;
  height: auto;

  @media only screen and (max-width: 576px) {
    max-width: 100%;
  }
`;

const CloseButton = styled.div`
  position: fixed;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  img {
    width: 14px;
    height: 14px;
  }
`;

const LIGHTBOX_ROOT_ELEMENT_ID = "lightbox";

/**
 * @param {Object} props
 * @param {number} props.width
 * @param {number} props.height
 * @param {Object} props.src
 */
export function Lightbox({ width, height, src, className }) {
  const [showLightbox, setShowLightbox] = useState(false);

  useEffect(() => {
    const root = document.getElementById(LIGHTBOX_ROOT_ELEMENT_ID);
    if (!root) {
      const newRoot = document.createElement("div");
      newRoot.id = LIGHTBOX_ROOT_ELEMENT_ID;
      document.body.appendChild(newRoot);
    }
  }, []);

  useEffect(() => {
    if (showLightbox) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showLightbox]);

  return (
    <>
      <Image
        style={{ width, height }}
        src={src + `?w=${width}&h=${height}`}
        onClick={() => setShowLightbox(true)}
      />

      {showLightbox ? (
        createPortal(
          <LightboxContainer onClick={() => setShowLightbox(false)}>
            <LightboxImage
              src={src + `?w=700`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />

            <CloseButton onClick={() => setShowLightbox(false)}>
              <img src={closeIcon} alt="close" />
            </CloseButton>
          </LightboxContainer>,
          document.getElementById(LIGHTBOX_ROOT_ELEMENT_ID)
        )
      ) : (
        <noscript />
      )}
    </>
  );
}
