export default function FindInspections() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#FDF5E9"
      />
      <path
        d="M28.6999 48.65H43.9249H46.4449C47.7049 48.65 48.6499 47.705 48.6499 46.55C48.6499 46.445 48.6499 46.445 48.6499 46.34L46.7599 25.34C46.6549 24.29 45.7099 23.45 44.5549 23.45"
        stroke="#EB9F22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3899 23.45H44.4499C45.6049 23.45 46.5499 24.395 46.5499 25.55C46.5499 25.655 46.5499 25.655 46.5499 25.76L44.6599 46.76C44.5549 47.81 43.6099 48.65 42.5599 48.65H24.4999C23.3449 48.65 22.3999 47.705 22.3999 46.55C22.3999 46.445 22.3999 46.445 22.3999 46.34L24.2899 25.34C24.3949 24.29 25.2349 23.45 26.3899 23.45Z"
        stroke="#EB9F22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8499 42.875H36.2599"
        stroke="#EB9F22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7999 34.6849L32.6899 37.6249L38.6749 31.6399"
        stroke="#EB9F22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7999 26.6C30.1699 26.6 29.7499 26.075 29.7499 25.445V22.505C29.7499 21.875 30.1699 21.35 30.7999 21.35C31.4299 21.35 31.8499 21.875 31.8499 22.505"
        stroke="#EB9F22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.1999 26.6C38.5699 26.6 38.1499 26.075 38.1499 25.445V22.505C38.1499 21.875 38.5699 21.35 39.1999 21.35C39.8299 21.35 40.2499 21.875 40.2499 22.505"
        stroke="#EB9F22"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
