import { useEffect, useState } from "react";
import { P, PrimaryButton, TertiaryButton } from "ui-library";
import filterIcon from "assets/icons/filter.svg";
import chevronDownIcon from "assets/icons/chevron-down-small.svg";
import { usePopper } from "react-popper";
import styled from "styled-components";
import houseIcon from "assets/icons/house.svg";
import officeIcon from "assets/icons/office.svg";
import { Hidden, Visible } from "react-grid-system";
import { Label } from "public/pages/awards";
import { createPortal } from "react-dom";
import close from "assets/icons/close-grey.svg";

const FiltersBackground = styled.div`
  position: fixed;
  top: 168px;
  left: 0;
  width: 100%;
  bottom: 0;
  background-color: #143a5a66;

  @media only screen and (max-width: 576px) {
    position: absolute;
    top: 0;
    z-index: 2;
  }
`;

const SectionTitle = styled(P)`
  font-size: 16px;
  line-height: 28px;
  font-weight: normal;
  color: #143a5a;
  font-weight: 700;
  margin-bottom: 8px;
`;

const PropertyTypeOptionContainer = styled.div`
  width: 160px;
  height: 102px;
  background: #ffffff;
  border: 1px solid #dde1e8;
  border-radius: 15px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.selected {
    border: 1px solid #00e096;
  }

  @media only screen and (max-width: 576px) {
    width: calc(50% - 8px);
  }
`;

const FiltersContainer = styled.div`
  padding: 40px 32px 0 32px;
  background: #ffffff;
  border: 1px solid #e4f0f7;
  border-radius: 20px;
  width: 700px;
  max-height: 700px;
  overflow: scroll;
  position: relative;

  ${PropertyTypeOptionContainer}:first-of-type {
    margin-right: 16px;
  }

  @media only screen and (max-height: 992px) {
    max-height: 500px;
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
    position: fixed;
    top: 38px;
    bottom: 0;
    left: 0;
    max-height: unset;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
  }
`;

const PropertyTypeImageContainer = styled(P)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PropertyTypeLabel = styled(P)`
  color: #143a5a;
`;

const Separator = styled.hr`
  border: 0.5px solid #e4f0f7;
  margin-top: 32px;
  margin-bottom: 20px;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const CheckboxOptionContainer = styled.div`
  width: 50%;
  flex-shrink: 0;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    width: 100%;
    margin-bottom: 8px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const InspectionTypesGroupContainer = styled.div`
  width: 100%;
  height: calc(28px * 4);
  overflow: hidden;

  &.show-all {
    height: unset;
  }
`;

const FiltersTrigger = styled(TertiaryButton)`
  border-radius: 50px;
  padding: 8px 20px;
  position: relative;

  &.has-selected-filters {
    background-color: #e4f0f7;
    border: 1px solid #028cb0;
  }
`;

const ApplyActionContainer = styled.div`
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 0.5px solid #e4f0f7;
  padding-bottom: 16px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ApplyButton = styled(PrimaryButton)`
  padding: 11px 20px;
`;

const ClearAllButton = styled(PrimaryButton)`
  padding: 11px 20px;
  background: transparent;
  color: #028cb0;
`;

const FilterCountBadge = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: #028cb0;
  border-radius: 100px;
  height: 20px;
  line-height: 20px;
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  font-family: BodyFont;
`;

const CheckboxOptionBackground = styled.div`
  width: 18px;
  height: 18px;
  opacity: 0.24;
  border: 1px solid #8f9bb3;
  border-radius: 3px;
  position: relative;

  &.selected {
    background: #00e096;
    border-radius: 3px;
    opacity: 1;
    border: none;

    &:before {
      content: "";
      position: absolute;
      width: 6px;
      height: 2px;
      background-color: #ffffff;
      border-radius: 16px;
      transform: rotate(45deg);
      left: 3px;
      top: 9px;
      transition: all 150ms linear;
    }

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: #ffffff;
      border-radius: 16px;
      transform: rotate(-45deg);
      left: 5.5px;
      top: 8px;
      transition: all 150ms linear;
    }
  }
`;

const CloseContainer = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #fff;
  top: 52px;
  right: 12px;
  border-radius: 50%;
`;

const Close = styled.img`
  width: 20px;
  height: 20px;
`;

const FILTER_ROOT_ELEMENT = "filter-root";

function CheckboxOption({ onClick, label, isChecked }) {
  return (
    <CheckboxOptionContainer onClick={onClick}>
      <CheckboxContainer>
        <CheckboxOptionBackground
          className={isChecked ? "selected" : null}
        ></CheckboxOptionBackground>

        <Label
          style={{
            marginTop: 0,
            marginLeft: 16,
            marginBottom: 0,
            color: "#143A5A",
          }}
        >
          {label}
        </Label>
      </CheckboxContainer>
    </CheckboxOptionContainer>
  );
}

function PropertyTypeOption({ isSelected, children, onClick }) {
  return (
    <PropertyTypeOptionContainer
      className={isSelected ? "selected" : null}
      onClick={onClick}
    >
      {children}
    </PropertyTypeOptionContainer>
  );
}

/**
 * @typedef {Object} FiltersProps
 * @property {import("services").InspectionType[]} inspectionTypes
 */

/**
 *
 * @param {FiltersProps} props
 * @returns
 */
export default function Filters({ inspectionTypes, onNewFiltersSelected }) {
  const [showFilters, setShowFilters] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });
  const [showMore, setShowMore] = useState(false);
  const toggleVisibleFields = () => setShowMore((_) => !_);

  // Filter States
  const [selectedPropertyType, setSelectedPropertyType] = useState();
  const [reasonsForLooking, setReasonsForLooking] = useState([]);
  const [selectedInspectionTypes, setSelectedInspectionTypes] = useState({});

  const toggleSelectedPropertyType = (propertyType) => {
    if (selectedPropertyType && selectedPropertyType === propertyType) {
      setSelectedPropertyType();
    } else {
      setSelectedPropertyType(propertyType);
    }
  };

  const toggleInspectionTypeSelection = (inspectionId) => {
    const inspectionTypeKey = `type-${inspectionId}`;
    setSelectedInspectionTypes((_) => ({
      ..._,
      [inspectionTypeKey]: selectedInspectionTypes[inspectionTypeKey]
        ? false
        : true,
    }));
  };

  const toggleReasonForLooking = (reason) => {
    let updatedReasons;

    if (reasonsForLooking.includes(reason)) {
      const index = reasonsForLooking.indexOf(reason);
      updatedReasons = [
        ...reasonsForLooking.slice(0, index),
        ...reasonsForLooking.slice(index + 1),
      ];
    } else {
      updatedReasons = [...reasonsForLooking, reason];
    }

    setReasonsForLooking(updatedReasons);
  };

  const toggleFilterVisibility = () => setShowFilters((_) => !_);

  const updateSelectedFilters = () => {
    const keys = Object.keys(selectedInspectionTypes);
    const inspectionTypeIds = keys
      .filter((key) => !!selectedInspectionTypes[key])
      .map((key) => key.replace("type-", ""));

    // Store selected filters in localstorage
    window.localStorage.setItem(
      "filters",
      JSON.stringify({
        selectedPropertyType,
        reasonsForLooking,
        inspectionTypeIds,
      })
    );

    // continue on
    onNewFiltersSelected(inspectionTypeIds);
    toggleFilterVisibility();
  };

  const clearAllFilters = () => {
    setSelectedPropertyType();
    setReasonsForLooking([]);
    setSelectedInspectionTypes({});

    window.localStorage.removeItem("filters");
  };

  const getFiltersContent = () => {
    return (
      <>
        <CloseContainer onClick={toggleFilterVisibility}>
          <Close src={close} />
        </CloseContainer>

        {/* Property Types */}
        <SectionTitle>Property type</SectionTitle>
        <div>
          <PropertyTypeOption
            isSelected={selectedPropertyType === "Residental"}
            onClick={() => toggleSelectedPropertyType("Residental")}
          >
            <PropertyTypeImageContainer>
              <img src={houseIcon} alt="residential" />
            </PropertyTypeImageContainer>
            <PropertyTypeLabel>Residential</PropertyTypeLabel>
          </PropertyTypeOption>
          <PropertyTypeOption
            isSelected={selectedPropertyType === "Commerical"}
            onClick={() => toggleSelectedPropertyType("Commerical")}
          >
            <PropertyTypeImageContainer>
              <img src={officeIcon} alt="commercial" />
            </PropertyTypeImageContainer>
            <PropertyTypeLabel>Commercial</PropertyTypeLabel>
          </PropertyTypeOption>
        </div>
        <Separator></Separator>

        {/* Reason for Looking */}
        <SectionTitle>Reason for looking</SectionTitle>
        <CheckboxGroup>
          {["Buying", "Selling", "Maintaining", "Building"].map(
            (reason, index) => (
              <CheckboxOption
                key={`reason-${index}`}
                label={reason}
                isChecked={reasonsForLooking.includes(reason)}
                onClick={() => toggleReasonForLooking(reason)}
              />
            )
          )}
        </CheckboxGroup>
        <Separator></Separator>

        {/* Inspection Types */}
        <SectionTitle>Inspection types</SectionTitle>
        <InspectionTypesGroupContainer className={showMore ? "show-all" : null}>
          <CheckboxGroup>
            {inspectionTypes
              .filter((inspectionType) => {
                if (selectedPropertyType && reasonsForLooking) {
                  const inspectionTypeConfirmsToReasonsForLooking =
                    inspectionType.tags.some((tag) =>
                      reasonsForLooking.includes(tag)
                    );

                  return (
                    inspectionType.tags.includes(selectedPropertyType) &&
                    inspectionTypeConfirmsToReasonsForLooking
                  );
                }

                return true;
              })
              .map((inspectionType) => (
                <CheckboxOption
                  key={`type-${inspectionType.id}`}
                  label={inspectionType.name}
                  isChecked={
                    selectedInspectionTypes[`type-${inspectionType.id}`] ===
                    true
                  }
                  onClick={() =>
                    toggleInspectionTypeSelection(inspectionType.id)
                  }
                />
              ))}
          </CheckboxGroup>
        </InspectionTypesGroupContainer>
        <TertiaryButton onClick={toggleVisibleFields}>
          {showMore ? "Show less" : "Show More"}
        </TertiaryButton>

        <ApplyActionContainer>
          <ClearAllButton onClick={clearAllFilters}>Clear all</ClearAllButton>

          <ApplyButton
            style={{ marginRight: 0, marginLeft: "auto", width: 130 }}
            onClick={updateSelectedFilters}
          >
            Apply
          </ApplyButton>
        </ApplyActionContainer>
      </>
    );
  };

  const updateSelectedInspectionTypes = () => {
    const savedFilters = window.localStorage.getItem("filters");
    if (savedFilters) {
      const { inspectionTypeIds = [] } = JSON.parse(savedFilters || "{}");
      setSelectedInspectionTypes(
        inspectionTypeIds.reduce((previous, inspectionTypeId) => {
          previous[`type-${inspectionTypeId}`] = true;
          return previous;
        }, {})
      );
    }
  };

  const getFilterCount = () => {
    const savedFilters = window.localStorage.getItem("filters");
    const {
      selectedPropertyType: storedSelectedPropertyType,
      reasonsForLooking: storedReasonsForLooking,
      inspectionTypeIds: storedInspectionTypeIds,
    } = JSON.parse(savedFilters || "{}");

    return (
      (storedSelectedPropertyType ? 1 : 0) +
      (storedReasonsForLooking?.length || 0) +
      (storedInspectionTypeIds?.length || 0)
    );
  };

  useEffect(() => {
    if (showFilters) {
      document.body.style.overflow = "hidden";

      const savedFilters = window.localStorage.getItem("filters");
      if (savedFilters) {
        const { selectedPropertyType, reasonsForLooking, inspectionTypeIds } =
          JSON.parse(savedFilters || "{}");
        setSelectedPropertyType(selectedPropertyType);
        setReasonsForLooking(reasonsForLooking);
        setSelectedInspectionTypes(
          inspectionTypeIds.reduce((previous, inspectionTypeId) => {
            previous[`type-${inspectionTypeId}`] = true;
            return previous;
          }, {})
        );
      }
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showFilters]);

  useEffect(() => {
    const rootElement = document.getElementById(FILTER_ROOT_ELEMENT);
    if (!rootElement) {
      const rootElement = document.createElement("div");
      rootElement.id = FILTER_ROOT_ELEMENT;
      document.body.appendChild(rootElement);
    }
  }, []);

  useEffect(() => {
    updateSelectedInspectionTypes();
  }, []);

  const filterCount = getFilterCount();
  return (
    <>
      <FiltersTrigger
        className={filterCount > 0 ? "has-selected-filters" : null}
        style={{ flexShrink: 0 }}
        ref={setReferenceElement}
        onClick={toggleFilterVisibility}
      >
        <img src={filterIcon} alt="filter" />

        <Hidden xs>
          <span style={{ marginLeft: 8 }}>Filters</span>
        </Hidden>

        <Hidden xs>
          <img
            src={chevronDownIcon}
            alt="chevron down"
            style={{ marginLeft: 8 }}
          />
        </Hidden>

        {filterCount > 0 ? (
          <FilterCountBadge>{filterCount}</FilterCountBadge>
        ) : (
          <noscript />
        )}
      </FiltersTrigger>

      {showFilters ? (
        <>
          <Hidden xs>
            <FiltersBackground>
              <FiltersContainer
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {getFiltersContent()}
              </FiltersContainer>
            </FiltersBackground>
          </Hidden>

          {createPortal(
            <Visible xs>
              <FiltersBackground>
                <FiltersContainer>{getFiltersContent()}</FiltersContainer>
              </FiltersBackground>
            </Visible>,
            document.getElementById(FILTER_ROOT_ELEMENT)
          )}
        </>
      ) : (
        <noscript />
      )}
    </>
  );
}
