import { useState } from "react";
import { Col } from "react-grid-system";
import { useSelector } from "react-redux";
import { inspectionTypesSelector } from "store/features/inspectors/inspectorsSlice";
import styled from "styled-components";
import { H3, P1 } from "ui-library";

const ItemContainer = styled(Col)`
  width: 100%;
  margin-top: 56px;

  @media only screen and (max-width: 576px) {
    ${H3} {
      margin-top: 16px;
    }

    ${P1} {
      margin-top: 8px;
    }
  }
`;

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 60px;
    max-height: 60px;
  }
`;

const ReadMoreOrLessButton = styled.button`
  cursor: pointer;
  border-bottom: none;
  color: #028cb0;
  background-color: white;
  border: none;
  margin-top: 8px;
  text-align: left;
  padding: 0;
`;

/**
 *
 * @param {Object} props
 * @param {import("services").InspectionType} props.inspectionType
 * @returns
 */
function Item({ inspectionType }) {
  const [expanded, setExpanded] = useState(false);

  const hasMoreThan300Words = inspectionType.description?.length > 300;
  const content =
    hasMoreThan300Words && !expanded
      ? inspectionType.description?.substring(0, 300) + "..."
      : inspectionType.description;

  return (
    <ItemContainer xs={12} sm={6}>
      <IconContainer>
        <img
          src={inspectionType.imagePath + "?h=40"}
          alt={inspectionType.name}
        />
      </IconContainer>

      <H3>{inspectionType.name}</H3>
      <P1 dangerouslySetInnerHTML={{ __html: content }} />
      {hasMoreThan300Words && (
        <ReadMoreOrLessButton onClick={() => setExpanded(!expanded)}>
          Read {expanded ? "less" : "more"}
        </ReadMoreOrLessButton>
      )}
    </ItemContainer>
  );
}

export default function InspectionTypesList() {
  const inspectionTypes = useSelector(inspectionTypesSelector);
  return inspectionTypes.map((inspectionType) => (
    <Item key={inspectionType.id} inspectionType={inspectionType} />
  ));
}
