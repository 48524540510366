import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: null,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setPaymentsConfig: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { setPaymentsConfig } = subscriptionSlice.actions;

/**
 *
 * @param {*} state
 * @returns {import("services").PaymentConfiguration}
 */
export const paymentsConfigSelector = (state) => state.subscription.current;

export default subscriptionSlice.reducer;

export { initialState as subscriptionSliceInitialState };
