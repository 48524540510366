import { ACCOUNT_MASTER_ROUTE, ACCOUNT_ROUTES } from "routes";

const faqs = [
  {
    question: "How can i get more customers",
    answer: `The best way to get more enquiry is to create a free account, fully complete your profile and ask every customer for a review. The more reviews you have the more attractive you are to customers looking for the best inspector.`,
  },
  {
    question: "What are the benefits of activating my profile?",
    answer: `Activating your FREE profile will unlock the ability to display more of the details of your company, let potential customers know what areas you service and what inspections you offer and get quotes.`,
  },
  {
    question: "How much does it cost?",
    answer:
      "Inspectors pay $69 per month including GST.  We also have a free version so you can get to know us.",
  },
  {
    question: "Will my clients be able to call me?",
    answer:
      "Yes. Your phone number can be shown on your profile to prospective clients to contact you for a quote or further information.",
  },
  {
    question: "What if my profile has already been claimed?",
    answer: `Contact us at <a href="mailto:info@ratemyinspectors.com.au">info@ratemyinspectors.com.au</a> and we will investigate. We will need proof that it’s your company and then we will make things right.`,
  },
  {
    question: "What if I can’t find my profile?",
    answer: `Can't find your profile? Easily <a href="${
      "/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.REGISTER
    }">set up your own profile</a> in minutes.`,
  },
];

export default faqs;
