import AccountRegisterForm from "../components/register-form";
import { useEffect, useState } from "react";
import { matchPath, useSearchParams } from "react-router-dom";
import { selectInspectorCode } from "store/features/customer-reviews/customerReviewsSlice";
import {
  selectInspectorIds,
  selectReportId,
} from "store/features/search/searchSlice";
import AccountTypeSelector from "../components/account-type-selector";
import { useSelector } from "react-redux";
import { InspectorsApi } from "services";
import { FiftyFifty, Loader } from "components";
import { DocumentTitle } from "components/document-title";
import findProfileImage from "assets/images/free-profile.png";

export default function AccountRegister() {
  // If account type from query params
  const [params] = useSearchParams();
  const accountType = params?.get("type");
  const next = params?.get("next");

  // For customised login page for customers going through
  // search flow of some kind
  const inspectorCode =
    useSelector(selectInspectorCode) ||
    matchPath("/search/inspectors/:inspectorCode/rate", next || "")?.params
      .inspectorCode;
  const reportId = useSelector(selectReportId);
  const selectedInspectorIds = useSelector(selectInspectorIds);
  const isPartOfCustomerSearchFlow =
    !!reportId || !!selectedInspectorIds?.length || inspectorCode;

  // local state
  const [isInspector, setIsInspector] = useState(
    isPartOfCustomerSearchFlow
      ? false
      : accountType === "inspector"
      ? true
      : accountType === "user"
      ? false
      : undefined
  );
  const [isFetchingInspector, setIsFetchingInspector] = useState(
    !!inspectorCode
  );
  const [inspector, setInspector] = useState();

  useEffect(() => {
    if (inspectorCode) {
      setIsFetchingInspector(true);
      async function fetchInspector() {
        const inspectorResponse = await InspectorsApi.getInspectorProfileByCode(
          inspectorCode
        );
        setInspector(inspectorResponse);
        setIsFetchingInspector(false);
      }
      fetchInspector();
    }
  }, [inspectorCode]);

  // account type not defined, show account type selector
  if (isInspector === undefined) {
    return (
      <AccountTypeSelector
        type="register"
        isInspectorSelected={setIsInspector}
      />
    );
  }

  if (isFetchingInspector) {
    return (
      <div style={{ textAlign: "center", marginTop: 32 }}>
        <Loader color="#feba00" size="large" loading />
      </div>
    );
  }

  // For register form
  let title = "";
  let description = "";

  if (isPartOfCustomerSearchFlow) {
    if (reportId) {
      // Purchasing report
      title = "Sign up to continue";
      description = "Create an account to purchase the report";
    } else if (selectedInspectorIds) {
      if (selectedInspectorIds.length > 1) {
        // quote request from multiple inspectors
        title = `Request a quote from ${selectedInspectorIds.length} inspectors`;
      } else {
        // quote request from single inspector
        title = `Request a quote from ${
          inspector.company?.name !== "Company name"
            ? inspector.company?.name
            : inspector.profile?.fullName
        }`;
      }

      description = "Create an account to request a quote.";
    } else {
      // inspectorCode
      title =
        "Review " +
        (inspector.company?.name !== "Company name"
          ? inspector.company?.name
          : inspector.profile?.fullName);
      description = "Create an account to leave a review";
    }
  } else {
    title = isInspector ? "Create your free profile" : "Sign up";
    description = "";
  }

  return (
    <DocumentTitle title="Sign up">
      <FiftyFifty image={findProfileImage}>
        <AccountRegisterForm
          isInspector={isInspector}
          title={title}
          description={description}
        />
      </FiftyFifty>
    </DocumentTitle>
  );
}
