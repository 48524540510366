import { ScreenClassRender } from "react-grid-system";
import styled from "styled-components";

const SVG = styled.svg`
  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: #e5e5e5;
  }

  .circle-progress {
    stroke: #00e096;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .circle-text {
    font-size: 2em;
    font-weight: bold;
    fill: #143a5a;
  }
`;

export function CircularProgressBar(props) {
  return (
    <ScreenClassRender
      render={(screenClass) => {
        // Size of the enclosing square
        let sqSize = props.sqSize;
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        let radius = (sqSize - props.strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        let viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        let dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        let dashOffset = dashArray - (dashArray * props.percentage) / 100;

        if (["xs", "sm"].includes(screenClass)) {
          sqSize = sqSize * 0.75;
          radius = (sqSize - props.strokeWidth) / 2;
          viewBox = `0 0 ${sqSize} ${sqSize}`;
          dashArray = radius * Math.PI * 2;
          dashOffset = dashArray - (dashArray * props.percentage) / 100;
        }

        return (
          <SVG
            className={props.className}
            width={sqSize}
            height={sqSize}
            viewBox={viewBox}
          >
            <circle
              className="circle-background"
              cx={sqSize / 2}
              cy={sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
            />
            <circle
              className="circle-progress"
              cx={sqSize / 2}
              cy={sqSize / 2}
              r={radius}
              strokeWidth={`${props.strokeWidth}px`}
              // Start progress marker at 12 O'Clock
              transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
              style={{
                strokeDasharray: dashArray,
                strokeDashoffset: dashOffset,
              }}
            />
            <text
              className="circle-text"
              x="50%"
              y="50%"
              dy=".3em"
              textAnchor="middle"
            >
              {`${props.percentage}%`}
            </text>
          </SVG>
        );
      }}
    />
  );
}

CircularProgressBar.defaultProps = {
  sqSize: 150,
  percentage: 0,
  strokeWidth: 10,
};
