import parsePhoneNumber from "libphonenumber-js";
import { useState } from "react";
import styled from "styled-components";
import { PrimaryButton } from "ui-library";

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 200px;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

/**
 *
 * @param {Object} props
 * @param {string} props.phoneNumber
 * @returns
 */
export function PhoneNumberButton({ phoneNumber, isCompany }) {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "AU");
  const formattedPhoneNumber = parsedPhoneNumber.formatInternational();
  const [shouldShowFullNumber, setShouldShowFullNumber] = useState(false);

  if (isCompany) {
    return (
      <StyledPrimaryButton
        onClick={() => window.open(parsedPhoneNumber.getURI())}
      >
        {phoneNumber}
      </StyledPrimaryButton>
    );
  }

  const redactedPhoneNumber =
    formattedPhoneNumber.substring(0, 5) +
    " · ·  · · ·  ·" +
    formattedPhoneNumber.substring(
      formattedPhoneNumber.length - 1,
      formattedPhoneNumber.length - 4
    );
  return (
    <StyledPrimaryButton
      onClick={() => {
        if (shouldShowFullNumber) {
          window.open(parsedPhoneNumber.getURI());
        } else {
          setShouldShowFullNumber(true);
        }
      }}
    >
      {shouldShowFullNumber
        ? parsedPhoneNumber.formatInternational()
        : redactedPhoneNumber}
    </StyledPrimaryButton>
  );
}
