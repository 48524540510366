import axios from "axios";
import { isValidPhoneNumber } from "libphonenumber-js";

export const requiredValidator = (value) =>
  value ? undefined : "This is required";

const email = (value) =>
  value.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
    ? undefined
    : "Please enter a valid email";

const isValidUrl = (value) => {
  return /(https?:\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
};

const isValidYoutubeUrl = (value) => {
  const regex =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

  if (value.match(regex)) {
    return !!value.match(regex)[1];
  }

  return false;
};

const isValidVimeoUrl = async (value) => {
  try {
    await axios.get(
      `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(value)}`
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const emailValidator = (value) => {
  const passesRequired = requiredValidator(value);

  if (passesRequired !== undefined) {
    return "Your email address is required";
  }

  return email(value);
};
export const passwordValidator = (value) => {
  if (requiredValidator(value) !== undefined) {
    return "Please enter a password";
  }

  return undefined;
};
export const phoneValidator = (value) => {
  if (!value) {
    return "Your mobile number is required";
  }

  return isValidPhoneNumber(
    value,
    // navigator.language?.split("-")[1] || "AU"
    "AU"
  )
    ? undefined
    : "Invalid phone number";
};
/**
 *
 * @param {string} value
 * @returns {string|undefined}
 */
export const urlValidator = (value) => {
  if (!value) {
    return undefined;
  }

  if (isValidUrl(value)) {
    return undefined;
  }

  return "Please enter a valid link";
};
export const videoValidator = async (value) => {
  if (!value) {
    return undefined;
  }

  const isYouTubeUrl = isValidYoutubeUrl(value);
  if (isYouTubeUrl) {
    return undefined;
  }

  const isVimeoUrl = await isValidVimeoUrl(value);
  if (isVimeoUrl) {
    return undefined;
  }

  return "Please enter a valid Vimeo or YouTube link";
};

export const minLengthValidator = (minLength) => (value) => {
  const passesRequired = requiredValidator(value);

  if (passesRequired !== undefined) {
    return passesRequired;
  }

  if (value.trim().length < minLength) {
    return `Please enter at least ${minLength} characters`;
  }

  return undefined;
};
