import { api } from "./api";

/**
 * @typedef {Object} InspectionType
 * @property {number} id
 * @property {string} name
 * @property {string} imagePath
 * @property {boolean} [isFeatured]
 * @property {string} [description]
 * @property {string[]} tags
 */

export const InspectionTypesApi = {
  /**
   *
   * @returns {Promise<InspectionType[]>}
   */
  getInspectionTypes: () => api.get("/InspectionTypes"),
  /**
   *
   * @param {number} inspectionTypeId
   * @returns {Promise<InspectionType>}
   */
  getInspectionTypeById: (inspectionTypeId) =>
    api.get(`/InspectionTypes/${inspectionTypeId}`),
};
