import { Input, Loader, Modal, SubscriptionStripeWrapper } from "components";
import styled from "styled-components";
import { H2, H4, H5, P, PrimaryButton } from "ui-library";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { PaymentsApi } from "services";
import {
  paymentsConfigSelector,
  setPaymentsConfig,
} from "store/features/subscription/subscriptionSlice";
import lockIcon from "assets/icons/lock-white.svg";
import { ACCOUNT_MASTER_ROUTE, ACCOUNT_ROUTES } from "routes";
import { toast } from "react-toastify";
import Toast from "components/toast";

const Content = styled.div`
  width: calc(100% + 48px);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 48px;
  border-radius: 20px;

  @media only screen and (max-width: 576px) {
    margin-left: -24px;
    margin-right: -24px;
  }
`;

const FormContent = styled.div`
  width: 100%;
  max-width: 500px;
`;

const PlanInfoContainer = styled.div`
  width: 100%;
  background-color: #f5fafd;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 576px) {
    justify-content: unset;
    flex-direction: column;

    > div {
      text-align: center;
    }

    ${H2} {
      margin-top: 20px;
    }
  }
`;

const StyledH4 = styled(H4)`
  margin-top: 56px;
  margin-bottom: 16px;

  @media only screen and (max-width: 576px) {
    margin-top: 32px;
  }
`;

function SubscribeForm({ onComplete }) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url:
          process.env.REACT_APP_DOMAIN +
          "/" +
          ACCOUNT_MASTER_ROUTE +
          "/" +
          ACCOUNT_ROUTES.SUBSCRIPTION,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      toast.error(<Toast title={result.error.message} />);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      onComplete();
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <FormContent>
            <PlanInfoContainer>
              <div>
                <H5>Monthly subscription</H5>
                <P>(Inspector)</P>
              </div>
              <H2>$69</H2>
            </PlanInfoContainer>

            <Input
              name="promoCode"
              label="Do you have a promo code?"
              placeholder="Discount or promo code"
            />

            <StyledH4>Credit card information</StyledH4>

            <PaymentElement />

            <PrimaryButton
              style={{ width: "100%", marginTop: 24 }}
              onClick={handleSubmit}
            >
              {submitting ? (
                <Loader loading />
              ) : (
                <img src={lockIcon} alt="lock" style={{ marginRight: 16 }} />
              )}

              {submitting ? "Subscribing ..." : "Subscribe now"}
            </PrimaryButton>
          </FormContent>
        );
      }}
    />
  );
}

export default function Subscribe({
  trigger,
  title = "Upgrade your subscription",
}) {
  const dispatch = useDispatch();
  const [isFetchingPaymentIntent, setIsFetchingPaymentIntent] = useState(false);
  const paymentsConfig = useSelector(paymentsConfigSelector);

  const createPaymentIntent = async () => {
    try {
      setIsFetchingPaymentIntent(true);
      const response = await PaymentsApi.createSubscriptionPaymentIntent();

      if (response.planPaymentIntentSecret) {
        setIsFetchingPaymentIntent(false);
      } else {
        toast.success(<Toast title="Subscription renewed" />);
      }
      dispatch(setPaymentsConfig(response));
    } catch (e) {}
  };

  return (
    <Modal
      size="full"
      trigger={
        trigger ? (
          React.cloneElement(trigger, {
            onClick: createPaymentIntent,
          })
        ) : (
          <noscript />
        )
      }
    >
      {(closeModal) => (
        <Content>
          {isFetchingPaymentIntent ||
          !paymentsConfig?.planPaymentIntentSecret ? (
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
            >
              <Loader loading color="#EB9F22" size="medium" />
            </div>
          ) : (
            <SubscriptionStripeWrapper
              paymentIntent={paymentsConfig.planPaymentIntentSecret}
            >
              <SubscribeForm onComplete={closeModal} />
            </SubscriptionStripeWrapper>
          )}
        </Content>
      )}
    </Modal>
  );
}
