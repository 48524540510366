import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BlogsApi } from "services/blog";
import styled from "styled-components";
import { format } from "date-fns";
import { P1 } from "ui-library";
import facebookIcon from "assets/icons/facebook.svg";
import twitterIcon from "assets/icons/twitter.svg";
import linkedinIcon from "assets/icons/linkedin.svg";
import YouTube from "react-youtube";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 72px 0;

  @media screen and (max-width: 1280px) {
    padding: 48px 18px;
  }

  img {
    max-width: 100%;
    max-height: 600px;
    width: 100%;
    object-fit: cover;
    margin-bottom: 24px;
  }

  h1 {
    margin-bottom: 32px;
  }

  .date {
    margin-bottom: 14px;
  }

  .video > div {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .blog-social-share-wrapper {
    margin-top: 40px;
    display: flex;
    gap: 8px;

    button {
      cursor: pointer;
      background: white;
      border: 0;

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }
`;

const SingleBlog = () => {
  const [blog, setBlog] = useState({});
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    BlogsApi.getBlogById(id)
      .then((response) => {
        setBlog({
          ...response,
          heroImageUrl:
            response.heroImageUrl ||
            "https://images.unsplash.com/photo-1655432960599-931eeab4c735?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=80",
        });
      })
      .catch((error) => console.log(error));
  }, [id]);

  const onShare = (url, urlParam) => {
    const socialURL = `${window.location.href}`;
    window.open(`${url}?${urlParam}=${socialURL}`, "_blank");
  };

  return (
    <Wrapper>
      <Helmet>
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.title} />
        <meta property="og:image" content={blog.heroImageUrl} />
      </Helmet>
      <h1>{blog.title}</h1>
      {blog.heroVideoUrl && (
        <div className="video">
          <YouTube
            videoId={blog.heroVideoUrl.split("/").pop()}
            opts={{
              height: "390",
              width: "610",
            }}
          />
        </div>
      )}
      {!blog.heroVideoUrl && blog.heroImageUrl && (
        <img
          src={
            `${blog.heroImageUrl}?w=600&h=560` ||
            "https://images.unsplash.com/photo-1655432960599-931eeab4c735?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=80"
          }
          alt="hero"
        />
      )}
      <div>
        {blog.updatedOn && (
          <p className="date">
            {format(new Date(blog.updatedOn), "dd-MM-yyyy")}
          </p>
        )}
        <P1
          dangerouslySetInnerHTML={{
            __html: `${blog.content}`,
          }}
        />
        <div className="blog-social-share-wrapper">
          <p>Share on: </p>
          <div>
            <button
              className="social-share-link"
              onClick={() => onShare("http://www.facebook.com/sharer.php", "u")}
            >
              <img src={facebookIcon} alt="facebook" />
            </button>
          </div>
          <div>
            <button
              className="social-share-link"
              onClick={() => onShare("https://twitter.com/intent/tweet", "url")}
            >
              <img src={twitterIcon} alt="twitter" />
            </button>
          </div>
          <div>
            <button
              className="social-share-link"
              onClick={() => {
                onShare("https://www.linkedin.com/shareArticle", "url");
              }}
            >
              <img src={linkedinIcon} alt="linkedin" />
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SingleBlog;
