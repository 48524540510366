export default function FastQuotes() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#FDF5E9"
      />
      <path
        d="M25 22V48H45V28.6L44.7 28.3L38.7 22.3L38.4 22H25ZM27 24H37V30H43V46H27V24ZM39 25.4L41.6 28H39V25.4ZM29 32V34H41V32H29ZM29 37V39H36V37H29ZM38 37V39H41V37H38ZM29 41V43H36V41H29ZM38 41V43H41V41H38Z"
        fill="#EB9F22"
      />
    </svg>
  );
}
