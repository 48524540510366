import { Loader } from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  InspectionQuotesApi,
  PaymentsApi,
  UsersApi,
  UserSessionsApi,
  InspectorsApi,
  InspectionTypesApi,
  CompaniesApi,
} from "services";
import { displayErrorMessage } from "services/api";
import {
  quoteRequestSelector,
  setQuoteRequest,
  setCurrentInspector,
  setInspectionTypes,
  inspectorSelector,
  inspectionTypesSelector,
} from "store/features/inspectors/inspectorsSlice";
import { setPaymentsConfig } from "store/features/subscription/subscriptionSlice";
import { setSession, updateUser } from "store/features/user/userSlice";
import {
  A,
  H1,
  H2,
  H3,
  P,
  Page,
  PrimaryButton,
  SecondaryButton,
} from "ui-library";
import styled from "styled-components";
import { Col, Container, Hidden, Row, Visible } from "react-grid-system";
import { getFormattedDate } from "helpers";
import { setCurrentCompany } from "store/features/companies/companiesSlice";
import {
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import { useAuth } from "common/authentication";

const QuoteRequestContainer = styled.div`
  width: 100%;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 64px;
`;

const QuoteContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  padding: 44px;
  border-radius: 32px;
  background-color: #fff;
  border: 1px solid #e4f0f7;
  box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);

  @media only screen and (max-width: 576px) {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 32px;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 24px;
`;

const RequestLabel = styled(P)`
  color: #143a5a;
  font-weight: 700;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  ${SecondaryButton} {
    margin-left: 16px;
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;

    ${PrimaryButton}, ${SecondaryButton} {
      width: 100%;
    }

    ${SecondaryButton} {
      margin-top: 8px;
      margin-left: 0;
    }
  }
`;

const StyledH3 = styled(H3)`
  margin-top: 72px;

  @media only screen and (max-width: 576px) {
    margin-top: 0;
  }
`;

export default function QuoteRequestViewForInspector() {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const code = searchParams.get("code");
  const dispatch = useDispatch();
  const quote = useSelector(quoteRequestSelector);
  const navigate = useNavigate();
  const inspector = useSelector(inspectorSelector);
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const { session: userSession } = useAuth();

  const claimProfile = () => {
    navigate(
      "/" +
        SEARCH_MASTER_ROUTE +
        "/" +
        SEARCH_ACCOUNTS_MASTER_ROUTE +
        "/" +
        SEARCH_ACCOUNTS_ROUTES.CLAIM,
      {
        state: {
          selectedInspector: {
            ...inspector,
            profile: {
              id: inspector.id,
              fullName: userSession.fullName,
              imagePath: userSession.profilePhotoPath,
              phone: userSession.phone,
              email: userSession.email,
            },
          },
          inspectionTypes,
        },
      }
    );
  };

  useEffect(() => {
    async function fetchRequiredData() {
      try {
        // Step 1: Login & get current user
        const session = await UserSessionsApi.signInWithAuthProviderViaToken(
          email,
          token
        );
        dispatch(setSession(session));

        const user = await UsersApi.getCurrentUser();
        dispatch(updateUser(user));

        const inspectorProfile =
          await InspectorsApi.getCurrentInspectorProfile();
        dispatch(setCurrentInspector(inspectorProfile));

        const inspectionTypes = await InspectionTypesApi.getInspectionTypes();
        dispatch(setInspectionTypes(inspectionTypes));

        const subscriptionInfo = await PaymentsApi.getInfo();
        dispatch(setPaymentsConfig(subscriptionInfo));

        const company = await CompaniesApi.getCurrentCompany();
        dispatch(setCurrentCompany(company));

        // Step 2: Get quote
        const quote = await InspectionQuotesApi.getQuoteByCode(code);
        dispatch(setQuoteRequest(quote));

        setIsLoading(false);
      } catch (e) {
        displayErrorMessage(e);
      }
    }
    fetchRequiredData();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      if (userSession?.status === "Anonymous") {
        dispatch({
          type: "CLEAR_OUT",
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Page>
        <div
          style={{
            textAlign: "center",
            marginTop: 32,
          }}
        >
          <Loader size="large" loading color="#EB9F22" />
        </div>
      </Page>
    );
  }

  return (
    <Page>
      <QuoteRequestContainer>
        <StyledH3>Inspection quote request</StyledH3>

        <QuoteContainer>
          <Container fluid>
            {/* Client */}
            <Row>
              <Col xs={12} md={4}>
                <RequestLabel>Client</RequestLabel>
              </Col>
              <Col xs={12} md={8}>
                <P>{quote.name}</P>
              </Col>
            </Row>

            {/* Phone */}
            <StyledRow>
              <Col xs={12} md={4}>
                <RequestLabel>Phone</RequestLabel>
              </Col>
              <Col xs={12} md={8}>
                <A style={{ fontSize: "16px" }} href={`tel:${quote.phone}`}>
                  {quote.phone}
                </A>
              </Col>
            </StyledRow>

            {/* Message */}
            <StyledRow>
              <Col xs={12} md={4}>
                <RequestLabel>Message</RequestLabel>
              </Col>
              <Col xs={12} md={8}>
                <P>{quote.message}</P>
              </Col>
            </StyledRow>

            {/* Address */}
            <StyledRow>
              <Col xs={12} md={4}>
                <RequestLabel>Address</RequestLabel>
              </Col>
              <Col xs={12} md={8}>
                <P>{quote.address}</P>
              </Col>
            </StyledRow>

            {/* Building Type */}
            <StyledRow>
              <Col xs={12} md={4}>
                <RequestLabel>Building type</RequestLabel>
              </Col>
              <Col xs={12} md={8}>
                <P>{quote.buildingType || quote.buildingTypeOther}</P>
              </Col>
            </StyledRow>

            {/* Phone */}
            <StyledRow>
              <Col xs={12} md={4}>
                <RequestLabel>
                  {quote.preferredTimes.length > 1
                    ? "Preferred times"
                    : "Preferred time"}
                </RequestLabel>
              </Col>
              <Col xs={12} md={8}>
                {quote.preferredTimes.map((preferredTime, index) => (
                  <P key={index}>{getFormattedDate(preferredTime, true)}</P>
                ))}
              </Col>
            </StyledRow>
          </Container>
        </QuoteContainer>

        {userSession?.status === "Anonymous" ? (
          <>
            <Hidden xs>
              <H1 style={{ marginTop: 60, textAlign: "center" }}>
                Don't just be busy.
                <br />
                Be profitable too.
              </H1>
            </Hidden>
            <Visible xs>
              <H2 style={{ marginTop: 60, textAlign: "center" }}>
                Don't just be busy.
                <br />
                Be profitable too.
              </H2>
            </Visible>
          </>
        ) : (
          <noscript />
        )}

        {userSession?.status === "Anonymous" ? (
          <ActionContainer>
            <PrimaryButton onClick={claimProfile}>
              Create your free profile
            </PrimaryButton>
            <SecondaryButton
              onClick={() => window.open("/for-inspectors", "_blank")}
            >
              Find out more
            </SecondaryButton>
          </ActionContainer>
        ) : (
          <noscript />
        )}
      </QuoteRequestContainer>
    </Page>
  );
}
