import { useEffect, useState, isValidElement } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { H3, P1, Separator } from "ui-library";
import closeGreyIcon from "assets/icons/close-grey.svg";

const ModalTrigger = styled.div``;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(20, 58, 90, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &.mobile-modal {
    align-items: flex-end;
  }
`;

const ModalHeader = styled.div`
  width: 100%;
  background-color: #ffffff;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 24px 40px 0;

  &.full {
    padding-top: 0;
  }

  @media only screen and (max-width: 576px) {
    padding: 32px 24px 0;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* @media only screen and (max-width: 576px) {
    margin-top: 24px;
  } */
`;

const HeaderTexts = styled.div``;

const Close = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;

  img {
    opacity: 0.5;
    width: 20px;
  }

  &.mobile-modal {
    width: 48px;
    height: 48px;
  }

  @media only screen and (max-width: 576px) {
    right: 16px;

    img {
      width: 16px;
    }
  }
`;

const HeaderImage = styled.div`
  margin-right: 24px;
  width: 105px;
  height: 105px;
  background: #fff;
  box-shadow: 0px 9px 32px rgba(89, 92, 219, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @media only screen and (max-width: 576px) {
    width: 80px;
    height: 80px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const ModalBody = styled.div`
  padding: 40px;

  @media only screen and (max-width: 576px) {
    padding: 32px 24px;
  }
`;

const ModalContent = styled.div`
  width: 600px;
  height: 500px;
  border-radius: 16px;
  background-color: #ffffff;
  position: relative;
  overflow: scroll;
  z-index: 2;

  &.small {
    width: 400px;
    min-height: 300px;
    height: auto;
  }

  &.medium {
    width: 712px;
    height: auto;
    max-height: 682px;
  }

  &.full {
    width: 100dvw;
    height: 100dvh;
    background: #fff;
    padding: 24px 16px;
    border-radius: 0;

    @media only screen and (max-width: 576px) {
      padding: 0;
    }

    ${ModalHeader} {
      background-color: transparent;
    }
  }

  &.mobile-modal {
    width: 100%;
    height: 90%;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }

  @media only screen and (max-width: 576px) {
    width: 100vw;
    height: 100vh;
    max-height: unset;
    border-radius: 0;

    &.small,
    &.medium,
    &.full {
      width: 100vw;
      height: 100vh;
      max-height: unset;
      border-radius: 0;
    }
  }
`;

const MODAL_ROOT_ELEMENT = "dialog-root";

export function Modal({
  isOpen = false,
  trigger,
  title,
  subtitle,
  children,
  size,
  onAfterClose,
  headerImage,
  separateHeader = false,
}) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  useEffect(() => {
    const rootElement = document.getElementById(MODAL_ROOT_ELEMENT);
    if (!rootElement) {
      const rootElement = document.createElement("div");
      rootElement.id = MODAL_ROOT_ELEMENT;
      document.body.appendChild(rootElement);
    }
  }, []);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
      document.getElementById("root").style.filter = "blur(10px)";
    } else {
      document.body.style.overflow = "auto";
      document.getElementById("root").style.filter = "unset";

      if (onAfterClose) {
        onAfterClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const closeModal = () => {
    document.body.style.overflow = "auto";
    document.getElementById("root").style.filter = "unset";
    setIsModalOpen(false);
  };

  return (
    <>
      <ModalTrigger onClick={() => setIsModalOpen(true)}>
        {trigger}
      </ModalTrigger>

      {isModalOpen ? (
        createPortal(
          <ModalOverlay className={size}>
            <ModalContent className={size}>
              <ModalHeader className={size}>
                <HeaderContent>
                  {headerImage ? (
                    <HeaderImage>
                      <Image src={headerImage + "?w=105&h=105"} />
                    </HeaderImage>
                  ) : (
                    <noscript />
                  )}

                  <HeaderTexts>
                    <H3 style={{ lineHeight: "unset" }}>{title}</H3>
                    {subtitle ? (
                      <P1 style={{ color: "#143A5A" }}>{subtitle}</P1>
                    ) : (
                      <noscript />
                    )}
                  </HeaderTexts>
                </HeaderContent>

                <Close className={size} onClick={() => setIsModalOpen(false)}>
                  <img src={closeGreyIcon} alt="close" />
                </Close>

                {separateHeader ? <Separator /> : <noscript />}
              </ModalHeader>

              <ModalBody>
                {isValidElement(children) ? children : children(closeModal)}
              </ModalBody>
            </ModalContent>
          </ModalOverlay>,
          document.getElementById(MODAL_ROOT_ELEMENT)
        )
      ) : (
        <noscript />
      )}
    </>
  );
}
