import styled from "styled-components";
import searchIcon from "assets/icons/search-yellow.svg";
import { useEffect, useRef, useState } from "react";
import Filters from "./filters";

const SearchNavContainer = styled.div`
  width: 100%;
  height: 64px;
  border-top: 1px solid #e4f0f7;
  border-bottom: 1px solid #e4f0f7;
  position: fixed;
  top: 104px;
  background-color: #fff;
  z-index: 1;

  @media only screen and (max-width: 576px) {
    top: 56px;
  }
`;

const SearchNavLimit = styled.div`
  max-width: 1042px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;

  @media only screen and (max-width: 576px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const InputContainer = styled.div`
  height: 42px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f7f9fc;
  margin-right: 16px;
  border: 1px solid #cedee8;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;

  @media only screen and (min-width: 812px) {
    &.full-address {
      width: 390px;
    }
  }

  @media only screen and (max-width: 576px) {
    justify-content: space-between;
    flex-grow: 1;
    padding-left: 10px;
    padding-right: 10px;
    max-width: calc(100% - 71px);
  }
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  color: #143a5a;
  font-size: 16px;
  line-height: 34px;
  flex-grow: 1;
  display: inline-block;

  @media only screen and (max-width: 576px) {
    max-width: 170px;
  }
`;

export default function SearchNav({
  value,
  inspectionTypes,
  isUsingFullAddress = false,
  onSearchUpdated,
}) {
  const [inputRef, setInputRef] = useState(null);
  const autocomplete = useRef(null);

  const fillInAddress = () => {
    const place = autocomplete.current.getPlace();
    const { formatted_address, address_components } = place;
    let formattedAddressWithoutPostalCode = formatted_address;

    if (isUsingFullAddress) {
      const postalCode = address_components.find((address_component) =>
        address_component.types.includes("postal_code")
      );

      formattedAddressWithoutPostalCode = formatted_address.replace(
        " " + postalCode.long_name,
        ""
      );
    }

    onSearchUpdated(formattedAddressWithoutPostalCode);
  };

  useEffect(() => {
    if (inputRef && window.google) {
      autocomplete.current = new window.google.maps.places.Autocomplete(
        inputRef,
        {
          fields: ["address_components", "formatted_address"],
          types: ["address"],
          componentRestrictions: {
            country: "au",
          },
        }
      );

      autocomplete.current?.addListener("place_changed", fillInAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  return (
    <SearchNavContainer>
      <SearchNavLimit>
        <InputContainer className="full-address">
          <Input ref={setInputRef} defaultValue={value} />
          <img src={searchIcon} alt="search" style={{ marginLeft: 8 }} />
        </InputContainer>

        {isUsingFullAddress ? (
          <noscript />
        ) : (
          <Filters
            inspectionTypes={inspectionTypes}
            onNewFiltersSelected={(newSelectedInspectionTypeIds) => {
              onSearchUpdated(undefined, newSelectedInspectionTypeIds);
            }}
          />
        )}
      </SearchNavLimit>
    </SearchNavContainer>
  );
}
