import styled from "styled-components";
import {
  H2,
  P1,
  PrimaryButton,
  SecondaryButton,
  TertiaryAlertButton,
} from "ui-library";
import image from "assets/images/onboarding-step-3.png";
import { Hidden, Visible } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import { Form } from "react-final-form";
import { Address, Select, Dropzone, Modal, Loader, Input } from "components";
import { useAuth } from "common/authentication";
import defaultProfilePhoto from "assets/images/default-profile-photo.svg";
import cameraIcon from "assets/icons/camera.svg";
import { UsersApi } from "services";
import { useDispatch } from "react-redux";
import { updateUser } from "store/features/user/userSlice";
import { displayErrorMessage } from "services/api";
import { useEffect, useState } from "react";
import { requiredValidator } from "components/form/validators";

const OnboardingStep = styled.div`
  height: calc(100vh - 104px);
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 576px) {
    overflow: auto;
    height: calc(100dvh - 56px);
  }
`;

const OnboardingStepContainer = styled.div`
  flex-grow: 1;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 91px;
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    padding-top: 35px;
  }
`;

const StepContent = styled.div`
  display: flex;
`;

const FormContent = styled.div`
  display: flex;
  margin-top: 36px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const FormContentRight = styled.div`
  margin-left: 68px;
  margin-top: -24px;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
    margin-top: 16px;
  }
`;

const LeftContent = styled.div`
  @media only screen and (max-width: 576px) {
    padding-bottom: 32px;
  }
`;

const StepLabel = styled.p`
  color: #143a5a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;

  span {
    color: #798995;
  }
`;

const RightContent = styled.div`
  margin-left: 57px;

  img {
    max-width: 450px;
  }
`;

const Action = styled.div`
  background-color: white;

  @media only screen and (max-width: 576px) {
    position: sticky;
    bottom: 0;
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;

  @media only screen and (max-width: 576px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 5px;
  background-color: #e4f0f7;
`;

const Progress = styled.div`
  height: 5px;
  width: 100%;
  background-color: #00e096;
`;

const Image = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 50%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
`;

const ImageEditButton = styled.div`
  position: absolute;
  top: 10px;
  right: -20px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #eb9f22;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #efb14e;
  }

  &:focus,
  &:active {
    background-color: #df9112;
  }
`;

export default function OnboardingStep3() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { user } = useAuth();
  const { firstName, lastName, email, phone } = user;
  const dispatch = useDispatch();

  return (
    <Form
      initialValues={{
        ...state,
      }}
      onSubmit={(values) => {
        navigate(
          "/" +
            INSPECTORS_MASTER_ROUTE +
            "/" +
            INSPECTOR_ROUTES.ONBOARDING_PREVIEW,
          {
            state: values,
          }
        );
      }}
      render={({ handleSubmit, values }) => (
        <OnboardingStep>
          <OnboardingStepContainer>
            <StepContent>
              <LeftContent>
                <StepLabel>
                  Step 3 <span>of 3</span>
                </StepLabel>

                <H2>Last few details, then we can publish your profile</H2>

                <P1 style={{ marginTop: 19, color: "#143A5A" }}>
                  Choosing your business location enables us to connect you with
                  potential clients effectively. Remember to include a polished
                  photo – it enhances your credibility and fosters trust among
                  your clients.
                </P1>

                <FormContent>
                  <Image
                    style={{
                      backgroundImage: `url(${
                        user.profilePhotoPath
                          ? user.profilePhotoPath + "?w=104&h=104)"
                          : defaultProfilePhoto
                      }`,
                    }}
                  >
                    <Modal
                      title="Update profile photo"
                      trigger={
                        <ImageEditButton>
                          <img src={cameraIcon} alt="camera" width={20} />
                        </ImageEditButton>
                      }
                    >
                      {(closeModal) => (
                        <Form
                          initialValues={{
                            firstName,
                            lastName,
                            email,
                            phone,
                          }}
                          onSubmit={async (values) => {
                            if (!values.point) {
                              delete values.address;
                            }

                            const formData = new FormData();
                            Object.keys(values).forEach((key) => {
                              formData.append(key, values[key]);
                            });
                            try {
                              const response = await UsersApi.updateCurrentUser(
                                formData
                              );
                              dispatch(updateUser(response));
                              closeModal();
                            } catch (e) {
                              displayErrorMessage(e);
                            }
                          }}
                          render={({ handleSubmit, submitting }) => (
                            <>
                              <input type="hidden" name="firstName" />
                              <input type="hidden" name="lastName" />
                              <input type="hidden" name="email" />

                              <Dropzone
                                name="profilePhotoFile"
                                placeholder="Drop an image file here or select from your device"
                                activePlaceholder="Drop the file here ..."
                                accept=".png, .jpg, .jpeg"
                                filePreview={(file, clearValue) => {
                                  return (
                                    <FilePreview
                                      file={file}
                                      clearValue={clearValue}
                                    />
                                  );
                                }}
                              />

                              <PrimaryButton
                                style={{
                                  marginLeft: "auto",
                                  marginTop: 24,
                                  width: 160,
                                }}
                                onClick={submitting ? null : handleSubmit}
                              >
                                {submitting ? <Loader loading /> : "Save"}
                              </PrimaryButton>
                            </>
                          )}
                        />
                      )}
                    </Modal>
                  </Image>

                  <FormContentRight>
                    <Input
                      name="name"
                      label="Business name"
                      placeholder="Enter business name"
                    />

                    <Address
                      name="addressDisplay"
                      label="Business address"
                      validators={requiredValidator}
                    />

                    <Select
                      name="searchRadiusInKilometres"
                      label="Radius"
                      description="Adjust your radius so you reach more potential clients."
                      validators={requiredValidator}
                      items={[
                        { label: "20 km", value: 20 },
                        { label: "50 km", value: 50 },
                        { label: "100 km", value: 100 },
                        { label: "200 km", value: 200 },
                        { label: "500 km", value: 500 },
                        { label: "1000 km +", value: 1000 },
                      ]}
                    />
                  </FormContentRight>
                </FormContent>
              </LeftContent>

              <Hidden xs>
                <RightContent>
                  <img src={image} style={{ marginTop: 144 }} alt="cover" />
                </RightContent>
              </Hidden>
            </StepContent>
          </OnboardingStepContainer>

          <Action>
            <ProgressBar>
              <Progress />
            </ProgressBar>

            <Footer>
              <SecondaryButton
                onClick={() =>
                  navigate(
                    "/" +
                      INSPECTORS_MASTER_ROUTE +
                      "/" +
                      INSPECTOR_ROUTES.ONBOARDING_STEP_2,
                    { state: values }
                  )
                }
              >
                Back
              </SecondaryButton>

              <Hidden xs>
                <PrimaryButton onClick={handleSubmit}>
                  Review your profile
                </PrimaryButton>
              </Hidden>

              <Visible xs>
                <PrimaryButton onClick={handleSubmit}>Next</PrimaryButton>
              </Visible>
            </Footer>
          </Action>
        </OnboardingStep>
      )}
    />
  );
}

function FilePreview({ file, clearValue }) {
  const [image, setImage] = useState();

  const showFilePreview = () => {
    var reader = new FileReader();
    reader.onload = function () {
      const data = reader.result;
      setImage(data);
    };
    reader.readAsDataURL(file);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(showFilePreview, []);

  return (
    <div className="flex justify-between">
      <Image
        style={{ backgroundImage: `url(${image})`, width: 160, height: 160 }}
      />

      <TertiaryAlertButton style={{ width: 48 }} onClick={clearValue}>
        X
      </TertiaryAlertButton>
    </div>
  );
}
