import { H1, H2, H4, P, P1, P2 } from "ui-library";

// Assets
import FastQuotesIcon from "components/public-pages/icons/fast-quotes";
import FindYourInspectorsIcon from "components/public-pages/icons/find-your-inspectors";
import FindInspectionsIcon from "components/public-pages/icons/find-inspections";
import HomepageBannerImage from "assets/images/homepage-banner.png";
import HomepageBannerMobileImage from "assets/images/homepage-banner-mobile.png";
import YellowBlob from "components/public-pages/icons/yellow-blob";
import QualityIcon from "components/public-pages/icons/quality";
import CheckAvailabilityIcon from "components/public-pages/icons/check-availablity";
import PropertyInspectorsIcon from "components/public-pages/icons/property-inspectors";
import AndrewTrishImage from "assets/images/trish-and-andrew.png";

// Components
import SecondSectionRow from "components/public-pages/second-section-row/second-section-row";
import Caurosel from "components/public-pages/caurosel/caurosel";
import BlueAndYellowBlob from "components/public-pages/icons/blue-and-yellow-blob";
import { DocumentTitle } from "components/document-title";
import StatsCardWrapper from "components/public-pages/stats-card-wrapper";
import styled from "styled-components";
import { Container, Hidden, Visible } from "react-grid-system";
import { Spacer } from "common/helpers";
import InspectorSearch from "search/components/inspector-search";

const StyledStatsCardWrapper = styled(StatsCardWrapper)`
  @media only screen and (min-width: 1024px) {
    margin-top: -52px;
  }
`;

const Banner = styled.section`
  position: relative;
`;

const BannerImage = styled.img`
  display: none;

  @media (min-width: 1020px) {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 65%;
    height: 100%;
    min-height: 500px;
    z-index: -1;
    object-fit: cover;
    object-position: left;

    + div {
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (max-width: 576px) {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 445px;
    z-index: -1;
    object-fit: cover;
    opacity: 0.2;

    + div {
      height: 445px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

const Heading = styled(H1)`
  margin-bottom: 16px;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 800;
  @media (min-width: 1020px) {
    width: 60%;
    text-align: left;
  }
  @media (max-width: 576px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const Subheading = styled(P)`
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.3px;
  color: #404852;

  @media (min-width: 1020px) {
    width: 40%;
    text-align: left;
  }
`;

const SuburbSearch = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: 1020px) {
    display: block;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 576px) {
    margin-bottom: 40px;
  }
`;

const SecondSection = styled.section`
  position: relative;
`;

const FounderSection = styled.section`
  position: relative;
`;

const FounderContent = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const FounderContentLeft = styled.div`
  margin-right: 56px;

  @media only screen and (max-width: 576px) {
    margin-right: unset;
  }
`;

const YearsInBusiness = styled.p`
  color: #eb9f22;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
`;

const FounderImage = styled.img`
  width: 557px;
  height: 421px;
  border-radius: 20px;

  @media only screen and (max-width: 576px) {
    width: 100%;
    height: 253px;
  }
`;

const YellowBlobContainer = styled.span`
  display: none;

  @media (min-width: 1020px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-20%, -40%);

    svg {
      width: 500px;
      height: 500px;
    }
  }
`;

const SecondSectionHeading = styled(H2)`
  text-align: center;
  margin-top: 96px;
`;

const SecondSectionSubheading = styled(P2)`
  text-align: center;
  font-size: 20px;

  margin: 18px 0 24px;
  @media (min-width: 1020px) {
    margin: 18px auto 42px;
    width: 70%;
  }
`;

const GetQuotes = styled.section`
  position: relative;
`;

const GetQuotesYellowBlob = styled.span`
  display: none;

  @media screen and (min-width: 1020px) {
    display: block;
    position: absolute;
    bottom: -40px;
    left: 0px;
    transform: translate(-30%, 30%);
    z-index: -1;
    svg {
      width: 500px;
      height: 500px;
    }
  }
`;

const GetQuotesContainer = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  box-shadow: none;
  padding: 18px 0;

  @media (min-width: 1020px) {
    max-width: 1020px;
    padding: 96px 64px;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 5px 12px rgba(24, 105, 171, 0.15);
    border-radius: 20px;
    padding: 54px 0;
    .suburb-search {
      width: 100%;
      min-width: 600px;
      > div > div {
        margin: 0 auto;
      }
    }
    p {
      max-width: 60%;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  @media screen and (max-width: 768px) {
    .suburb-search {
      width: 100%;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 14px;
  }
`;

const FindBestInspectorSection = styled.section`
  position: relative;
  padding: 60px 0;
  @media (min-width: 1020px) {
    padding: 150px 0;
  }
`;

const Bluebackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f5fafd;
  z-index: -1;

  @media (min-width: 1020px) {
    width: 75vw;
    left: auto;
    right: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  @media (min-width: 1550px) {
    width: 65vw;
  }
`;

const FindBestInspectorContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

  @media (min-width: 1020px) {
    display: grid;
    grid-gap: 80px;
    grid-template-columns: 1.5fr 3fr;
  }
`;

const FindBestInspectorLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 24px;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
  }
`;

const FindBestInspectorRightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media (min-width: 1020px) {
    align-items: flex-start;
  }

  .item,
  .item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 1020px) {
      align-items: flex-start;
    }
    svg {
      margin-bottom: 16px;
    }
    > * {
      text-align: center;
      @media (min-width: 1020px) {
        text-align: left;
      }
    }
  }

  .item {
    margin-bottom: 40px;
    @media (min-width: 1020px) {
      display: grid;
      grid-template-columns: 64px 1fr;
      align-items: flex-start;
      grid-gap: 36px;
    }
  }
`;

const BlueAndYellowBlobContainer = styled.span`
  display: none;
  @media (min-width: 1020px) {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(0, 0);
    z-index: -1;
    svg {
      width: 800px;
      height: 800px;
    }
  }
`;

export default function Home() {
  return (
    <>
      {/* Banner */}
      <DocumentTitle title="Home">
        <Banner>
          <Hidden xs>
            <BannerImage src={HomepageBannerImage} alt="Homepage Banner" />
          </Hidden>

          <Visible xs>
            <BannerImage
              src={HomepageBannerMobileImage}
              alt="Homepage Banner"
            />
          </Visible>
          <Container fluid>
            <div style={{ maxWidth: 1280, margin: "0 auto" }}>
              <Hidden xs>
                <Heading>
                  Get quotes from the best inspectors in your area
                </Heading>
              </Hidden>

              <Visible xs>
                <Heading>Find the best inspectors in your area</Heading>
              </Visible>

              <Subheading>
                Find and compare inspector ratings, their availability and
                service area for the inspection type you need.
              </Subheading>
              <SuburbSearch>
                <InspectorSearch />
              </SuburbSearch>
            </div>
          </Container>
        </Banner>
      </DocumentTitle>

      <Hidden xs>
        <StyledStatsCardWrapper />

        <Spacer />
      </Hidden>

      <SecondSection>
        <YellowBlobContainer>
          <YellowBlob />
        </YellowBlobContainer>
        <Container>
          <SecondSectionHeading>
            Over 12000 inspectors and more than 50 inspection types
          </SecondSectionHeading>
          <SecondSectionSubheading>
            Spend less time searching and comparing for property professionals,
            buyers and sellers.
          </SecondSectionSubheading>
          <SecondSectionRow
            items={[
              {
                icon: <FindYourInspectorsIcon />,
                title: "Find your inspectors",
                description:
                  "Rate my Inspectors helps you quickly find the best inspectors in your area so you can compare their reviews.",
              },
              {
                icon: <FindInspectionsIcon />,
                title: "Find the inspections you need",
                description:
                  "Not sure what inspections you need or what is included in each inspection. We take the guesswork out of briefing your inspector.",
              },
              {
                icon: <FastQuotesIcon />,
                title: "Get fast quotes and availability",
                description: `If you need an inspection and report fas then you have come to the right place. Rate my Inspectors has the unique "Available today" feature.`,
              },
            ]}
          />
        </Container>
      </SecondSection>

      <Spacer />

      <FounderSection>
        <Container>
          <FounderContent>
            <FounderContentLeft>
              <YearsInBusiness>Over 20 years in business</YearsInBusiness>
              <H2 style={{ textAlign: "center" }}>About the founders</H2>
              <Hidden xs sm md>
                <P1 style={{ marginTop: 18 }}>
                  The RMI founders have owned and run an inspection company for
                  more than 20 years. They are now dedicated to using advanced
                  technology to connect the best inspectors with property
                  professionals, buyers and sellers.
                </P1>
              </Hidden>
            </FounderContentLeft>

            <FounderImage src={AndrewTrishImage} />

            <Visible xs sm md>
              <P1 style={{ textAlign: "center", marginTop: 32 }}>
                The RMI founders have owned and run an inspection company for
                more than 20 years. They are now dedicated to using advanced
                technology to connect the best inspectors with property
                professionals, buyers and sellers.
              </P1>
            </Visible>
          </FounderContent>
        </Container>
      </FounderSection>

      <Spacer />

      <GetQuotes>
        <Container style={{ position: "relative" }}>
          <GetQuotesYellowBlob>
            <svg
              width="734"
              height="678"
              viewBox="0 0 734 678"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.3" filter="url(#filter0_f_2160_1736)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M591.209 352.001C580.173 386.669 554.914 413.19 532.037 441.462C512.326 465.821 491.32 487.535 466.292 506.373C438.414 527.356 412.122 556.558 377.273 557.946C342.311 559.339 312.951 533.407 284.067 513.634C256.911 495.044 237.575 469.427 214.166 446.286C182.126 414.612 126.833 396.642 120.566 352.001C114.507 308.845 158.489 275.35 185.353 241.061C209.162 210.671 235.738 183.755 268.438 163.262C302.015 142.219 338.082 115.019 377.273 120.785C416.401 126.54 433.038 176.499 469.088 192.779C511.116 211.758 573.755 185.58 601.836 222.192C628.703 257.222 604.604 309.919 591.209 352.001Z"
                  fill="#FFC25F"
                />
              </g>
              <defs>
                <filter
                  id="filter0_f_2160_1736"
                  x="0"
                  y="0"
                  width="734"
                  height="678"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="60"
                    result="effect1_foregroundBlur_2160_1736"
                  />
                </filter>
              </defs>
            </svg>
          </GetQuotesYellowBlob>
          <GetQuotesContainer>
            <H2>Get quotes from the best rated inspectors in your area</H2>
            <SuburbSearch className="suburb-search">
              <InspectorSearch />
            </SuburbSearch>
          </GetQuotesContainer>
        </Container>
      </GetQuotes>

      <Spacer />

      <FindBestInspectorSection>
        <Bluebackground />
        <FindBestInspectorContentSection>
          <FindBestInspectorLeftContent>
            <H2>Find the best rated property inspectors</H2>
            <P2>
              Our platform matches your needs with verified professional
              inspectors ready to help you with the right advice.
            </P2>
          </FindBestInspectorLeftContent>
          <FindBestInspectorRightContent>
            <div className="item">
              <CheckAvailabilityIcon />
              <div className="item-content">
                <H4>Check availability</H4>
                <P>
                  You need an inspector right away and don't want to cal 10
                  different companies to find one available. With our "Available
                  now" feature you can find an inspector without making multiple
                  calls.
                </P>
              </div>
            </div>
            <div className="item">
              <QualityIcon />
              <div className="item-content">
                <H4>Quality first</H4>
                <P>
                  A cheap inspection that misses essential defects will cost you
                  more than using a Quality inspector. We show you the best
                  rated inspectors with the right experience and qualifications.
                  Property is a valuable asset, use the best inspectors.
                </P>
              </div>
            </div>
            <div className="item">
              <PropertyInspectorsIcon />
              <div className="item-content">
                <H4>All property inspectors in one place</H4>
                <P>
                  Building, Termite, General Pest, Fire safety, Electrical,
                  Plumbing, Asbestos, Pool Safety, Welding, Mould, Drug Lab, Air
                  quality and more. With more than 4000 inspectors, put together
                  your inspection team here.
                </P>
              </div>
            </div>
          </FindBestInspectorRightContent>
        </FindBestInspectorContentSection>
      </FindBestInspectorSection>

      <Spacer />

      <Spacer />

      <Caurosel />

      <GetQuotes>
        <BlueAndYellowBlobContainer>
          <BlueAndYellowBlob />
        </BlueAndYellowBlobContainer>
        <Container>
          <GetQuotesContainer>
            <H2>Not sure what inspections to get?</H2>
            <P>
              Our platform matches your needs with professional inspectors ready
              to help you with the right advice.
            </P>
            <SuburbSearch className="suburb-search">
              <InspectorSearch />
            </SuburbSearch>
          </GetQuotesContainer>
        </Container>
      </GetQuotes>

      <Spacer />
    </>
  );
}
