import { useEffect, useState } from "react";
import styled from "styled-components";
import { FiftyFifty, Loader } from "components";
import { H2, P } from "ui-library";
import { useParams } from "react-router-dom";
import { InspectorReviewsApi, InspectorsApi } from "services";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { selectAccount } from "store/features/user/userSlice";
import { DocumentTitle } from "components/document-title";
import RateInspectorForm from "../components/rate-form";
import NetworkImage from "components/network-image";
import reviewImage from "assets/images/reviews.png";

const Title = styled(H2)`
  margin-top: 40px;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const CustomerInfo = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 16px;
  background-color: #edf1f7;
  border-radius: 8px;
`;

const CustomerPhoto = styled(NetworkImage)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 16px;
`;

export default function RateInspector() {
  const { inspectorCode } = useParams();
  const [inspector, setInspector] = useState(null);
  const [isFetchingReview, setIsFetchingReview] = useState(true);
  const [review, setReview] = useState(null);
  const user = useSelector(selectAccount);

  useEffect(() => {
    async function fetchRequiredInfo() {
      try {
        setIsFetchingReview(true);

        const inspectorResponse = await InspectorsApi.getInspectorProfileByCode(
          inspectorCode
        );
        setInspector(inspectorResponse);

        const reviewsResponse =
          await InspectorReviewsApi.getReviewsCreatedByCurrentCustomer();
        const filteredReviews = reviewsResponse?.data.filter(
          (_) => _.inspector.id === inspectorResponse.id
        );

        if (filteredReviews) {
          setReview(filteredReviews[0]);
        }
      } catch (e) {
      } finally {
        setIsFetchingReview(false);
      }
    }
    fetchRequiredInfo();
  }, [inspectorCode]);

  if (!inspector || isFetchingReview) {
    return (
      <FiftyFifty>
        <Container fluid>
          <Row>
            <Col style={{ textAlign: "center" }}>
              <Loader loading size="medium" />
            </Col>
          </Row>
        </Container>
      </FiftyFifty>
    );
  }

  return (
    <DocumentTitle title="Rate Inspector">
      <FiftyFifty image={reviewImage}>
        <div style={{ maxWidth: 450, marginLeft: 0, width: "100%" }}>
          <Title>
            {!!review
              ? `Edit review for ${
                  inspector.company?.name &&
                  inspector.company?.name !== "Company name"
                    ? inspector.company?.name
                    : inspector.profile.fullName
                }`
              : `${
                  inspector.company?.name &&
                  inspector.company?.name !== "Company name"
                    ? inspector.company?.name
                    : inspector.profile.fullName
                } review`}
          </Title>

          <CustomerInfo>
            <P>Posting as</P>
            <CustomerPhoto
              src={
                user.profilePhotoPath
                  ? user.profilePhotoPath + `?w=40&h=40`
                  : null
              }
            />
            <P style={{ marginLeft: 16, color: "#143a5a" }}>{user.fullName}</P>
          </CustomerInfo>

          <RateInspectorForm
            inspectorId={inspector.id}
            inspectorName={
              inspector.company?.name &&
              inspector.company?.name !== "Company name"
                ? inspector.company?.name
                : inspector.profile.fullName
            }
            review={review}
          />

          <style
            dangerouslySetInnerHTML={{
              __html: "nav, footer { display: none !important; }",
            }}
          />
        </div>
      </FiftyFifty>
    </DocumentTitle>
  );
}
