import PropTypes from "prop-types";
import { Col, Container, Row } from "react-grid-system";

export function Page({ children }) {
  return (
    <Container>
      <Row>
        <Col xs={12}>{children}</Col>
      </Row>
    </Container>
  );
}
Page.propTypes = {
  children: PropTypes.node,
};
Page.defaultProps = {
  children: null,
};
