import { SearchReport } from "components";
import { Col, Container, Row } from "react-grid-system";
import { EmptyState } from "./empty-state";
import { useNavigate } from "react-router-dom";
import { PUBLIC_MASTER_ROUTE, PUBLIC_ROUTES } from "routes";

export function PurchasedReports({ reports }) {
  const navigate = useNavigate();

  if (reports.length === 0) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <EmptyState
              title="You don’t have any reports yet."
              description="Search our database of more than 50,000 inspection reports by clicking the button."
              actionTitle="Property reports"
              onAction={() =>
                navigate(
                  "/" +
                    PUBLIC_MASTER_ROUTE +
                    "/" +
                    PUBLIC_ROUTES.PROPERTY_REPORTS
                )
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }

  return reports.map((report, index) => (
    <SearchReport key={index} report={report.report} purchased />
  ));
}
