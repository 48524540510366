import { useState } from "react";
import { Accordion, Input, Loader } from "components";
import styled from "styled-components";
import circleUncheckedIcon from "assets/icons/circle-unchecked.svg";
import { PrimaryButton } from "ui-library";
import { CompaniesApi, InspectorsApi } from "services";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  companySelector,
  updateCurrentCompany,
} from "store/features/companies/companiesSlice";
import { Form } from "react-final-form";
import { displayErrorMessage } from "services/api";
import { setCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import Toast from "components/toast";

const StyledAccordion = styled(Accordion)`
  margin-top: 16px;
`;

/**
 *
 * @param {Object} props
 * @param {import("services").InspectorProfile} props.inspector
 */
export function CompanyNameWidget() {
  const company = useSelector(companySelector);
  const [submitting, setSubmitting] = useState(null);
  const dispatch = useDispatch();

  if (company?.name && company.name !== "Company name") {
    return <noscript />;
  }

  return (
    <Form
      onSubmit={async (values) => {
        try {
          setSubmitting(true);
          await CompaniesApi.updateCurrentCompany(values);
          dispatch(updateCurrentCompany(values));

          const inspectorProfile =
            await InspectorsApi.getCurrentInspectorProfile();
          dispatch(setCurrentInspector(inspectorProfile));

          toast.success(<Toast title="Company name updated" />);
        } catch (e) {
          displayErrorMessage(e);
        } finally {
          setSubmitting(false);
        }
      }}
      render={({ handleSubmit }) => (
        <StyledAccordion icon={circleUncheckedIcon} title="Business name">
          <Input
            name="name"
            label="Business name"
            placeholder="Enter business name"
          />

          <PrimaryButton style={{ marginTop: 24 }} onClick={handleSubmit}>
            <Loader loading={submitting} color="#fff" />
            {submitting ? " Saving" : "Save"}
          </PrimaryButton>
        </StyledAccordion>
      )}
    />
  );
}
