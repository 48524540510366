import { Navigate, Route, Routes } from "react-router-dom";
import AccountLogin from "./pages/login";
import AccountRegister from "./pages/register";
import AccountManage from "./pages/manage";
import AccountSubscription from "./pages/subscription";
import AccountLogout from "./pages/logout";
import { ACCOUNT_ROUTES } from "routes";
import ForgotPasswordPage from "./pages/forgot-password";
import { RequireAuth } from "common/authentication";
import ImpersonateRMIUser from "./pages/impersonate";
import AccountVerify from "./pages/verify";
import AccountSocialSignUp from "./pages/social-signup";

export default function Account() {
  return (
    <Routes>
      <Route path={ACCOUNT_ROUTES.LOGIN} element={<AccountLogin />} />

      <Route
        path={ACCOUNT_ROUTES.FORGOT_PASSWORD}
        element={<ForgotPasswordPage />}
      />

      <Route path={ACCOUNT_ROUTES.REGISTER} element={<AccountRegister />} />

      <Route
        path={ACCOUNT_ROUTES.SOCIAL_SIGNUP}
        element={<AccountSocialSignUp />}
      />

      <Route
        path={ACCOUNT_ROUTES.VERIFY}
        element={
          <RequireAuth>
            <AccountVerify />
          </RequireAuth>
        }
      />

      <Route
        path={ACCOUNT_ROUTES.MANAGE}
        element={
          <RequireAuth>
            <AccountManage />
          </RequireAuth>
        }
      />

      <Route
        path={ACCOUNT_ROUTES.SUBSCRIPTION}
        element={
          <RequireAuth>
            <AccountSubscription />
          </RequireAuth>
        }
      />

      <Route
        path={ACCOUNT_ROUTES.LOGOUT}
        element={
          <RequireAuth>
            <AccountLogout />
          </RequireAuth>
        }
      />

      <Route
        path={ACCOUNT_ROUTES.IMPERSONATE}
        element={<ImpersonateRMIUser />}
      />

      <Route
        path="*"
        element={<Navigate to={ACCOUNT_ROUTES.LOGIN} replace />}
      />
    </Routes>
  );
}
