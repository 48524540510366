import { DocumentTitle } from "components/document-title";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { A, H2, P, UL } from "ui-library";

const StyledA = styled(A)`
  font-size: 16px;
  line-height: 28px;
`;

const StyledP = styled(P)`
  margin-top: 8px;

  strong {
    font-size: 20px;
  }
`;

export default function PrivacyPage() {
  return (
    <DocumentTitle title="Privacy Policy">
      <Container>
        <Row>
          <Col xs={12}>
            <H2 style={{ marginTop: 32 }}>Privacy Policy</H2>

            <StyledP>Last modified:January 10th 2022</StyledP>

            <StyledP>
              For the purposes of this Privacy Policy the words "RMI", "we",
              "our" and "us" refer to Rate my Inspectors Pty Ltd (ABN 94 622 352
              710) trading as Rate my inspectors and "Services" refers to
              materials and services delivered on or through the website located
              at the domain name{" "}
              <StyledA href="https://www.ratemyinspectors">
                www.ratemyinspectors
              </StyledA>
              . com and
              <StyledA href="https://www.ratemyinspectors.com.au">
                www.ratemyinspectors.com.au
              </StyledA>
              , social media web pages and smartphone applications operated by
              RMI (with ratemyinspectors.com.au, each related website, and each
              smartphone application to be known as the "Site").
            </StyledP>

            <StyledP>
              This Privacy Policy covers how we collect, use, disclose and store
              your personal information, and you should read it carefully prior
              to accessing or using the information and Services available
              through the Site. In this Privacy Policy, "personal information"
              means any information or opinion about an identified individual,
              or an individual who is reasonably identifiable, whether true or
              not and whether recorded in a material form or not.
            </StyledP>

            <StyledP>
              By providing us with your personal information for the provision
              of our Services to you, you agree to our collection, use and
              disclosure of your personal information as described in this
              Privacy Policy. You are not required to provide personal
              information to us. However, in some instances, if you do not
              provide us with certain personal information we may not be able to
              provide you with our Services. This may have an effect on whether
              we can begin or continue a relationship with you or allow you to
              use our Services.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>Our commitment to your privacy</strong>
            </StyledP>

            <StyledP>
              RMI provides a Service that allows you to provide feedback or
              commentary on inspectors and inspection companies listed on the
              Site, and for inspectors and inspection companies to respond to
              feedback and manage and utilize their online profiles.
            </StyledP>

            <StyledP>
              RMI takes your privacy seriously and is bound by the Privacy Act
              1988 (Cth), and the Australian Privacy Principles under that Act,
              and any other applicable laws relating to privacy when handling
              personal information (together, "Australian Privacy Law").
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>Information we collect</strong>
            </StyledP>

            <StyledP>
              We will only collect personal information that is reasonably
              necessary for us to provide you with the Services, or which is
              directly related to our provision of the Services.
            </StyledP>

            <StyledP>
              The personal information which RMI collects depends on the nature
              of your relationship with us.
            </StyledP>

            <StyledP>
              If you are an Inspector, we collect information such as your name,
              the name of the Inspection Company you work for, your contact
              numbers and address, email address, profile photo, inspection
              address, reports, images of properties you have inspected, your
              inspection history, credit card or direct debit details (if you
              purchase a subscription from us) and payment details.
            </StyledP>

            <StyledP>
              If you post a review on the Site, we collect information such as
              your name, email address, username, password, property address and
              image of the property.
            </StyledP>

            <StyledP>
              Other information we collect includes information that you post on
              our Sites or provide through our social media channels, and
              records of our communications with you, including any complaints,
              requests or queries.
            </StyledP>

            <StyledP>
              RMI may collect personal information directly from you, from third
              parties you have authorized to provide us with such information,
              or who provide services to us ("Service Providers"), or
              alternatively through publicly available sources. Where an agent
              provides us with information about their vendors for testimonial
              purposes, we will only use that information in the way authorized
              by the Inspector (who must have their client and property owner's
              consent).
            </StyledP>

            <StyledP>
              When you access a Site via a browser or application, our servers
              may record certain information, including your IP address, web
              request, GPS locations, session IDs of your interaction with a
              Service, browser type, browser language, and the date and time of
              your request.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>Anonymity</strong>
            </StyledP>

            <StyledP>
              We will, if practicable, allow you to use a pseudonym or to not
              identify yourself unless this is impractical or against the law
              (including the Australian Privacy Law).
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>How we use your information</strong>
            </StyledP>

            <StyledP>
              We collect personal information for the purpose of:
            </StyledP>
            <UL>
              <li>providing you with information, products, or Services;</li>
              <li>
                personalizing, customizing, and improving your experience with
                our Site;
              </li>
              <li>direct marketing;</li>
              <li>communicating with you by various means;</li>
              <li>the general management and conduct of our business; and</li>
              <li>complying with our legal obligations.</li>
            </UL>

            <StyledP>
              We may also use personal information for related purposes that
              would be reasonably expected by you.
            </StyledP>

            <StyledP>
              We will only use your personal information for the provision of
              our Services to you in the ways set out in this Privacy Policy or
              in our <StyledA href="/terms">Terms and Conditions</StyledA>, or
              for any other purpose, which we state at the time of the
              collection.
            </StyledP>

            <StyledP>
              We may also use your personal information for direct marketing
              purposes in order to tell you about services and offers that we
              think may be of interest to you. If we use data about Inspectors
              or reviewers and how they use our Site to market the Site and
              Services to the public, we will only use aggregated information on
              a purely anonymous basis. You can opt-out of marketing activities
              at any time by using the link on the emails we send you or via
              <StyledA href="mailto:info@ratemyinspectors.com.au">
                info@ratemyinspectors.com.au
              </StyledA>
              .
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>Cookies</strong>
            </StyledP>

            <StyledP>
              We may also collect information about you and how you interact
              with our Site, by using or storing "cookies" on your computer,
              phone, tablet or another device. You can delete cookies from your
              device at any time via your internet browser. However, doing so
              may limit your use of some of the Site's features.
            </StyledP>

            <StyledP>
              RMI uses Google Adwords to promote the Site and advertise on
              third-party websites. Third-party vendors, including Google, may
              use your cookies to serve ads based on your past visits to the
              Site. Should you wish to opt-out of Google's use of your cookies
              please visit{" "}
              <StyledA href="www.google.com/settings/ads" _target="blank">
                www.google.com/settings/ads
              </StyledA>
              .
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>Sharing your information</strong>
            </StyledP>
            <UL>
              <li>
                We will only disclose your personal information in the following
                circumstances: when we have your consent;
              </li>
              <li>
                to third parties and Service Providers where necessary for us to
                provide our Services to you, or where you have expressly asked
                us to disclose your personal information for the purposes of
                them or us providing further Services;
              </li>
              <li>
                to banks, payment processors, or other payment service
                providers;
              </li>
              <li>
                to our related bodies corporate, and their respective directors,
                officers, agents, and employees for the purpose of providing the
                Services and for our internal business purposes;
              </li>
              <li>
                to Service Providers located outside Australia. We use a network
                of global third-party Service Providers to maintain the high
                quality of our Services. Some of our Service Providers, or the
                services they provide (like cloud-based storage solutions), are
                based outside of Australia. This could include the Philippines
                and places in, Europe, Asia and the US. In order to protect your
                information, we take care where possible to work with Service
                Providers who we consider to maintain acceptable standards of
                data security compliance, and we do our part to meet those
                standards as they apply to Rate My Inspectors; and
              </li>
              <li>
                as required or permitted by law. If you opt-in to receive
                marketing email communications from us, these will be sent to
                you directly by RMI or on our behalf. We will only send
                communications to people who have opted to receive them. You can
                unsubscribe from receiving these communications at any time by
                using the link on the emails we send you or via
                <StyledA href="mailto:info@ratemyinspectors.com.au">
                  info@ratemyinspectors.com.au
                </StyledA>
                .
              </li>
              <li>
                If Rate My Inspectors goes through a business transition, such
                as a merger, acquisition by another company, or sale of all or a
                portion of its assets, your personal information may be among
                the assets transferred.
              </li>
            </UL>

            <StyledP style={{ marginTop: 24 }}>
              <strong>How we store and protect your information</strong>
            </StyledP>

            <StyledP>
              We take reasonable precautions to protect the personal information
              we hold about you from misuse, loss and unauthorized access or
              disclosure.
            </StyledP>

            <StyledP>
              While we take all due care in ensuring the privacy and security of
              your personal information, the possibility exists that this
              information could be unlawfully intercepted while in transit over
              the internet or while stored on our systems or on our Site.
              Subject to our Terms and Conditions, we disclaim all liability to
              you to the greatest extent permitted by law should this occur.
            </StyledP>

            <StyledP>
              If you cease to use our Services, RMI may retain your personal
              information to comply with its record-keeping obligations under
              the law. We will take reasonable steps to destroy or de-identify
              personal information that is no longer needed for the purpose for
              which it was collected.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>How you may access and correct your information</strong>
            </StyledP>

            <StyledP>
              You may ask us to provide access to the personal information we
              hold about you, subject to certain exceptions under the Australian
              Privacy Law. For your protection, we may require you to confirm
              your identity before access to your personal information is
              granted. In most cases, we can provide you with a summary of your
              personal information free of charge. However, in some
              circumstances, reasonable costs may be charged to you in accessing
              your personal information.
            </StyledP>

            <StyledP>
              It is your responsibility to advise us of any changes to your
              personal information, to ensure that it is up to date, relevant
              and of appropriate quality to enable us to provide our Services to
              you. If you have registered an account via the Site, you can
              update your personal information via your account's profile
              settings.
            </StyledP>

            <StyledP>
              If you do not have a registered account, or would like our
              assistance to update your personal information, please contact us
              using the details below.
            </StyledP>

            <StyledP>
              If you have a question or want to make a complaint about how we
              handle your personal information, please contact us using the
              details below. We will review your complaint, and will respond to
              you after we have carefully considered it. We may require further
              information from you in order to resolve any complaints.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>Links to other websites</strong>
            </StyledP>

            <StyledP>
              The Site may contain links to other websites. Unless the other
              website is one of our websites, we are not responsible for the
              privacy practices of the owners of those websites. We recommend
              that you read the privacy policy of any website that asks you to
              provide your personal information.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>Contact us</strong>
            </StyledP>

            <StyledP>
              If you have a question or want to make a complaint about how we
              handle your personal information, please contact us at
              <StyledA href="mailto:info@ratemyinspectors.com.au">
                info@ratemyinspectors.com.au
              </StyledA>
              . Your queries, requests, and/or complaints will be dealt with as
              soon as possible by our privacy officer (in most cases within 14
              business days).
            </StyledP>

            <StyledP>
              Alternatively, any person may make a complaint to the Office of
              the Australian Information Commissioner.
            </StyledP>

            <StyledP>
              The Office of the Australian Information Commissioner may be
              contacted on: Tel:{" "}
              <StyledA href="tel:1300363992">1300 363 992</StyledA> or Fax: +612
              9284 9666
            </StyledP>

            <StyledP>E-mail enquries@oaic.gov.au or postal address at:</StyledP>

            <StyledP>GPO Box 5218</StyledP>

            <StyledP>Sydney NSW 2001</StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>Changes to this Privacy Policy</strong>
            </StyledP>

            <StyledP>
              We may update this Privacy Policy from time to time at our sole
              discretion. You should periodically visit this page to review the
              current Privacy Policy. If you do not agree with the terms of this
              Privacy Policy, as amended, in whole or part, you must not access
              our Services including the Site. Any changes to this Privacy
              Policy will be effective immediately on the posting of the revised
              Privacy Policy on the Site.
            </StyledP>

            <StyledP>
              For more information about privacy issues in Australia and
              protecting your privacy, visit the Office of the Australian
              Information Commissioner's website at{" "}
              <StyledA href="https://www.oaic.gov.au" target="_blank">
                www.oaic.gov.au
              </StyledA>
              .
            </StyledP>

            <StyledP style={{ marginTop: 32 }}>Rate My Inspectors</StyledP>
            <StyledP style={{ marginBottom: 64 }}>
              Email:{" "}
              <StyledA href="mailto:info@ratemyinspectors.com.au">
                info@ratemyinspectors.com.au
              </StyledA>
            </StyledP>
          </Col>
        </Row>
      </Container>
    </DocumentTitle>
  );
}
