import styled from "styled-components";
import { H4, P, PrimaryButton } from "ui-library";

const EmptyStateContainer = styled.div`
  width: 100%;
  background-color: #f5fafd;
  border-radius: 12px;
  margin-bottom: 32px;
  padding: 51px 45px;
`;

/**
 * @typedef {Object} EmptyStateProps
 * @property {string} title
 * @property {string} description
 * @property {string} actionTitle
 * @property {() => void} onAction
 * @returns
 */

/**
 *
 * @param {EmptyStateProps} props
 * @returns
 */
export function EmptyState({ title, description, actionTitle, onAction }) {
  return (
    <EmptyStateContainer>
      <H4 style={{ color: "#143A5A" }}>{title}</H4>
      <P style={{ marginTop: 4 }}>{description}</P>
      <PrimaryButton style={{ marginTop: 24 }} onClick={onAction}>
        {actionTitle}
      </PrimaryButton>
    </EmptyStateContainer>
  );
}
