import { Col, Container, Row } from "react-grid-system";
import CustomerReview from "./customer-review";
import { EmptyState } from "./empty-state";
import { useNavigate } from "react-router-dom";
import { PUBLIC_MASTER_ROUTE, PUBLIC_ROUTES } from "routes";

export function PostedReviews({ reviews }) {
  const navigate = useNavigate();

  if (reviews.length === 0) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <EmptyState
              title="You have not left any reviews yet."
              description="Start by requesting quotes from inspectors."
              actionTitle="Find an inspector"
              onAction={() =>
                navigate("/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME)
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }

  return reviews.map((review, i) => (
    <CustomerReview key={review.id} review={review} />
  ));
}
