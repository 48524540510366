import FAQList from "components/public-pages/faqs/list";
import { H1 } from "ui-library";
import { inspectors, agents, sellers, buyers } from "../helpers/faqs";
import styled from "styled-components";
import { DocumentTitle } from "components/document-title";
import { Container } from "react-grid-system";

const Wrapper = styled.div`
  padding-bottom: 150px;
  h1 {
    text-align: center;
  }
`;

const Section = styled.section`
  padding-top: 80px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e4f0f7;

  &:last-child {
    border-bottom: 0;
  }
`;

const FAQsPage = () => {
  return (
    <DocumentTitle title="FAQs">
      <Wrapper>
        <Container>
          <H1>Frequently asked questions</H1>
        </Container>

        <Section>
          <Container>
            <FAQList
              fullWidth
              noCTA
              title="Inspectors"
              faqs={inspectors}
              openedOnDefault={false}
            />
          </Container>
        </Section>

        <Section>
          <Container>
            <FAQList
              fullWidth
              noCTA
              title="Buyers"
              faqs={buyers}
              openedOnDefault={false}
            />
          </Container>
        </Section>

        <Section>
          <Container>
            <FAQList
              fullWidth
              noCTA
              title="Sellers"
              faqs={sellers}
              openedOnDefault={false}
            />
          </Container>
        </Section>

        <Section>
          <Container>
            <FAQList
              fullWidth
              noCTA
              title="Agents"
              faqs={agents}
              openedOnDefault={false}
            />
          </Container>
        </Section>
      </Wrapper>
    </DocumentTitle>
  );
};

export default FAQsPage;
