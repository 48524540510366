import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  search: null,
  current: null,
  reports: [],
  services: [],
  types: [],
  reviews: [],
  videos: [],
  stats: null,
  quoteRequest: null,
};

export const inspectorsSlice = createSlice({
  name: "inspectors",
  initialState,
  reducers: {
    setInspectors: (state, action) => {
      state.search = action.payload;
    },
    setCurrentInspector: (state, action) => {
      state.current = action.payload;
    },
    updateCurrentInspector: (state, action) => {
      state.current = {
        ...state.current,
        ...action.payload,
      };
    },
    // Reports
    setReports: (state, action) => {
      state.reports = action.payload;
    },
    appendReport: (state, action) => {
      state.reports.push(action.payload);
    },
    updateReport: (state, action) => {
      state.reports = state.reports.map((report) => {
        if (report.id === action.payload.id) {
          return action.payload;
        }

        return report;
      });
    },
    removeReport: (state, action) => {
      state.reports = state.reports.filter(
        (report) => report.id !== action.payload.id
      );
    },
    // Inspection Types
    setInspectionTypes: (state, action) => {
      state.types = action.payload;
    },
    // Services
    setServices: (state, action) => {
      state.services = action.payload;
    },
    // Reviews
    setReviews: (state, action) => {
      state.reviews = action.payload;
    },
    // Videos
    setVideos: (state, action) => {
      state.videos = action.payload;
    },
    deleteVideo: (state, action) => {
      state.videos = state.videos.filter(
        (video) => video.id !== action.payload
      );
    },
    updateVideo: (state, action) => {
      state.videos = state.videos.map((video) => {
        if (video.id === action.payload.id) {
          return action.payload;
        }

        return video;
      });
    },
    setStatistics: (state, action) => {
      state.stats = action.payload;
    },
    setQuoteRequest: (state, action) => {
      state.quoteRequest = action.payload;
    },
  },
});

// Actions
export const {
  setCurrentInspector,
  setInspectors,
  updateCurrentInspector,
  setReports,
  setInspectionTypes,
  appendReport,
  updateReport,
  removeReport,
  setServices,
  setReviews,
  setVideos,
  deleteVideo,
  updateVideo,
  setStatistics,
  setQuoteRequest,
} = inspectorsSlice.actions;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectorProfile}
 */
export const inspectorSelector = (state) => state.inspectors.current;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectorSummary[]}
 */
export const inspectorsSelector = (state) => state.inspectors.search?.data;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectionReport[]}
 */
export const reportsSelector = (state) => state.inspectors.reports;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectionService[]}
 */
export const servicesSelector = (state) => state.inspectors.services;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectionType[]}
 */
export const inspectionTypesSelector = (state) => state.inspectors.types;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectorReview[]}
 */
export const inspectorReviewsSelector = (state) =>
  state.inspectors.reviews?.data;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectorVideo[]}
 */
export const inspectorVideosSelector = (state) => state.inspectors.videos;

/**
 *
 * @param {*} state
 * @returns {import("services").Stat[]}
 */
export const statisticsSelector = (state) => state.inspectors.stats;

/**
 *
 * @param {*} state
 * @returns {import("services").InspectionQuote}
 */
export const quoteRequestSelector = (state) => state.inspectors.quoteRequest;

export default inspectorsSlice.reducer;

export { initialState as inspectorsSliceInitialState };
