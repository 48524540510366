export default function SecondIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#EB9F22"
      />
      <path
        d="M28.7357 50C26.5369 50 24.7527 48.3078 24.7527 46.2224V43.8003C22.0294 43.1551 20 40.809 20 38.0218V26.9542C20 23.6675 22.8127 21 26.2781 21H43.7197C47.1851 21 49.9977 23.6676 49.9977 26.9542L50 38.0285C50 41.3152 47.1873 43.9827 43.7219 43.9827H36.4289C36.1105 43.9827 35.8219 44.1369 35.6593 44.3759C34.4523 46.2505 32.936 47.8798 31.1518 49.2005C30.4463 49.7262 29.6103 49.9999 28.7353 49.9999L28.7357 50ZM26.2781 23.8937C24.5008 23.8937 23.0509 25.2622 23.0509 26.9544V38.0287C23.0509 39.7144 24.4939 41.0894 26.2781 41.0894C27.121 41.0894 27.8036 41.7346 27.8036 42.5362V46.2291C27.8036 46.77 28.2846 47.1066 28.7289 47.1066C28.9213 47.1066 29.0999 47.0436 29.2626 46.9242C30.7582 45.8141 32.0317 44.4456 33.0463 42.8663C33.7564 41.765 35.0459 41.0829 36.4224 41.0829H43.7155C45.4928 41.0829 46.9427 39.7144 46.9427 38.0222V26.9546C46.9427 25.2689 45.4997 23.8938 43.7155 23.8938L26.2781 23.8937Z"
        fill="#EB9F22"
      />
    </svg>
  );
}
