import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import {
  Address,
  BackButton,
  DateTimeSelector,
  FiftyFifty,
  Input,
  Loader,
  PhoneNumber,
  Select,
  TextArea,
} from "components";
import { H2, LinkButton, P, PrimaryButton } from "ui-library";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInspectorIds,
  setQuery,
  setSearchedInspectors,
  setSelectedInspectorIds,
  setInspectionTypeIds,
  selectQuery,
} from "store/features/search/searchSlice";
import { InspectionQuotesApi, InspectorsApi } from "services";
import { selectAccount } from "store/features/user/userSlice";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { DocumentTitle } from "components/document-title";
import quoteRequestImage from "assets/images/quote-request.png";
import Toast from "components/toast";
import {
  PUBLIC_MASTER_ROUTE,
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_INSPECTORS_ROUTES,
  SEARCH_MASTER_ROUTE,
  USERS_MASTER_ROUTE,
  USER_ROUTES,
} from "routes";
import { requiredValidator } from "components/form/validators";
import { useEffect, useState } from "react";
import { selectInspectorCode } from "store/features/customer-reviews/customerReviewsSlice";

const Title = styled(H2)`
  margin-top: 40px;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const Label = styled.label`
  color: #143a5a;
  margin-top: 24px;
  margin-bottom: 4px;
  display: block;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;

  @media only screen and (max-width: 576px) {
    margin-bottom: 64px;
  }
`;

export default function InspectorQuote() {
  const user = useSelector(selectAccount);
  const inspectorIds = useSelector(selectInspectorIds);
  const inspectorCode = useSelector(selectInspectorCode);
  const [inspector, setInspector] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useSelector(selectQuery);

  useEffect(() => {
    if (inspectorCode) {
      async function fetchInspector() {
        try {
          const response = await InspectorsApi.getInspectorProfileByCode(
            inspectorCode
          );
          setInspector(response);
        } catch (e) {}
      }
      fetchInspector();
    }
  }, [inspectorCode]);

  if (!inspectorIds || inspectorIds.length === 0) {
    return <Navigate to={PUBLIC_MASTER_ROUTE} />;
  }

  const isSubmittingQuoteRequestToMultipleInspectors = inspectorIds.length > 1;
  const isSubmittingQuoteRequestToSingleInspector = !!inspector;

  if (
    !isSubmittingQuoteRequestToSingleInspector &&
    !isSubmittingQuoteRequestToMultipleInspectors
  ) {
    return (
      <div style={{ textAlign: "center", marginTop: 32 }}>
        <Loader color="#feba00" size="large" loading />
      </div>
    );
  }

  let title = "";
  let description = "";

  if (isSubmittingQuoteRequestToSingleInspector) {
    title = `Get a quote from ${
      inspector.company?.name !== "Company name"
        ? inspector.company?.name
        : inspector.profile?.fullName
      // inspector.company.name || inspector.profile.fullName
    }`;
    description = `Receive a quote, make an inquiry or schedule an inspection. The inspector(s) will be in touch.`;
  } else {
    title = `Get a quote from ${inspectorIds.length} Inspectors`;
    description = `Receive a quote, make an enquiry or schedule an inspection. The
    inspectors will be in touch.`;
  }

  return (
    <DocumentTitle title="Request a Quote">
      <FiftyFifty image={quoteRequestImage}>
        <div style={{ maxWidth: 450, marginLeft: 0, width: "100%" }}>
          <Container>
            <Row>
              <Col>
                <BackButton
                  onClick={() =>
                    navigate(
                      "/" +
                        SEARCH_MASTER_ROUTE +
                        "/" +
                        SEARCH_INSPECTORS_MASTER_ROUTE +
                        "/" +
                        SEARCH_INSPECTORS_ROUTES.SEARCH_LIST
                    )
                  }
                />

                <Title>{title}</Title>
                <P style={{ marginTop: 16 }}>{description}</P>

                <Form
                  initialValues={{
                    name: user.fullName,
                    phone: user.phone,
                    preferredTimes: [null],
                    address: query,
                  }}
                  mutators={arrayMutators}
                  onSubmit={async (values) => {
                    let updatedValues = values;
                    updatedValues.preferredTimes = updatedValues.preferredTimes
                      .filter((_) => !!_)
                      .map((_) => {
                        if (typeof _ === "string") {
                          return new Date(_);
                        }

                        return _;
                      })
                      .map((_) => _.toISOString());

                    try {
                      // Add point
                      const geocoder = new window.google.maps.Geocoder();
                      const geocodingResults = await geocoder.geocode({
                        address: values.address,
                      });

                      if (!geocodingResults.results?.length) {
                        toast.error(
                          <Toast title="Please check the address of the property being inspected and try again" />
                        );
                        return;
                      }

                      updatedValues = {
                        ...updatedValues,
                        address: geocodingResults.results[0].formatted_address,
                        point: {
                          latitude:
                            geocodingResults.results[0].geometry.location.lat(),
                          longitude:
                            geocodingResults.results[0].geometry.location.lng(),
                        },
                      };

                      await InspectionQuotesApi.submitQuoteRequest({
                        ...updatedValues,
                        inspectorIds: inspectorIds || [inspector.profile?.id],
                      });

                      toast.success(
                        <Toast title="Your quote request has been submitted" />
                      );

                      // clear local store
                      navigate(
                        "/" +
                          USERS_MASTER_ROUTE +
                          "/" +
                          USER_ROUTES.DASHBOARD +
                          "?v=quotes"
                      );
                      dispatch(setQuery(null));
                      dispatch(setSearchedInspectors(null));
                      dispatch(setSelectedInspectorIds(null));
                      dispatch(setInspectionTypeIds(null));
                    } catch (e) {}
                  }}
                  render={({ handleSubmit, submitting, values }) => {
                    return (
                      <>
                        <Row>
                          <Col>
                            <Input
                              name="name"
                              label="Full name"
                              placeholder="Full name"
                              validators={requiredValidator}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <PhoneNumber
                              name="phone"
                              label="Contact number"
                              placeholder="Enter your contact number"
                              validators={requiredValidator}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Address
                              name="address"
                              label="Address of the property being inspected"
                              placeholder="Enter address"
                              validators={requiredValidator}
                            />
                          </Col>
                        </Row>

                        <FieldArray name="preferredTimes">
                          {({ fields }) => {
                            return (
                              <>
                                <Row>
                                  <Col>
                                    <Label>
                                      Preferred date and time of inspection
                                    </Label>
                                  </Col>
                                </Row>

                                <Row>
                                  {fields.map((name, index) => (
                                    <Col
                                      xs={12}
                                      style={{ marginTop: 8 }}
                                      key={index}
                                    >
                                      <DateTimeSelector
                                        name={name}
                                        validators={requiredValidator}
                                      />
                                    </Col>
                                  ))}
                                </Row>

                                {fields.length < 3 ? (
                                  <Row>
                                    <Col xs={12}>
                                      <LinkButton
                                        style={{ marginTop: 16 }}
                                        onClick={() => fields.push(null)}
                                      >
                                        Add another preference
                                      </LinkButton>
                                    </Col>
                                  </Row>
                                ) : null}
                              </>
                            );
                          }}
                        </FieldArray>

                        <Row>
                          <Col>
                            <Select
                              name="buildingType"
                              label="Building type"
                              placeholder="Select building type"
                              validators={requiredValidator}
                              items={[
                                {
                                  label: "House",
                                  value: "House",
                                },
                                {
                                  label: "Unit",
                                  value: "Unit",
                                },
                                {
                                  label: "Duplex",
                                  value: "Duplex",
                                },
                                {
                                  label: "Commercial",
                                  value: "Commerical",
                                },
                                {
                                  label: "Other",
                                  value: "Other",
                                },
                              ]}
                            />
                          </Col>
                        </Row>

                        {values.buildingType === "Other" ? (
                          <Row>
                            <Col>
                              <Input
                                name="buildingTypeOther"
                                label="Please specify type of your property"
                                placeholder="Other building type"
                                validators={requiredValidator}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <noscript />
                        )}

                        <Row>
                          <Col>
                            <TextArea
                              name="message"
                              label="Message"
                              description="Enter types of inspections you require and any relevant information or message for the inspector/s to quote"
                              validate={requiredValidator}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <StyledPrimaryButton
                              style={{
                                width: "100%",
                                marginTop: 32,
                                marginBottom: 32,
                              }}
                              onClick={submitting ? null : handleSubmit}
                            >
                              {submitting ? (
                                <Loader loading />
                              ) : (
                                "Request a quote"
                              )}
                            </StyledPrimaryButton>
                          </Col>
                        </Row>
                      </>
                    );
                  }}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </FiftyFifty>
    </DocumentTitle>
  );
}
