import { useAuth } from "common/authentication";
import { Loader } from "components";
import { Form } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import { CompaniesApi, InspectorsApi } from "services";
import { displayErrorMessage } from "services/api";
import styled from "styled-components";
import { H2, H3, P1, PrimaryButton, UL } from "ui-library";
import defaultProfilePhoto from "assets/images/default-profile-photo.svg";
import { inspectionTypesSelector } from "store/features/inspectors/inspectorsSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import addressIcon from "assets/icons/pin-drop.svg";
import editIcon from "assets/icons/edit-2.svg";
import Toast from "components/toast";
import { Hidden } from "react-grid-system";

const OnboardingPreviewContainer = styled.div`
  height: calc(100vh - 104px);
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 91px;
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    padding-top: 35px;
    overflow: auto;
    height: calc(100vh - 56px);

    ${PrimaryButton} {
      width: 100%;
    }
  }
`;

const ProfilePreview = styled.div`
  width: 100%;
  border-radius: 32px;
  border: 1px solid #e4f0f7;
  background: #fff;
  box-shadow: 0px 11px 48px 0px rgba(24, 105, 171, 0.06);
  padding: 38px 50px;
  margin-top: 47px;

  @media only screen and (max-width: 576px) {
    padding: 30px 20px;
    margin-left: -16px;
    margin-right: -16px;
    width: unset;
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-top: 1px solid #e4f0f7;
  }
`;

const ProfilePreviewHeader = styled.div`
  display: flex;

  @media only screen and (max-width: 576px) {
    align-items: center;
  }
`;

const ProfilePreviewBody = styled.div`
  display: flex;
  margin-top: 40px;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const ProfileImage = styled.img`
  width: 105px;
  height: 105px;
  border-radius: 50%;

  @media only screen and (max-width: 576px) {
    width: 80px;
    height: 80px;
  }
`;

const NameAndAddress = styled.div`
  margin-left: 29px;

  @media only screen and (max-width: 576px) {
    margin-left: 16px;

    ${H3} {
      font-size: 16px;
      line-height: 28px;
    }
  }
`;

const Bio = styled.div``;

const InspectionTypes = styled.div`
  width: 270px;
  flex-shrink: 0;
  margin-left: 74px;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
    width: 100%;
    margin-top: 32px;
  }
`;

export default function OnboardingPreview() {
  const { user } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const [address, setAddress] = useState();

  const filteredInspectionTypes = inspectionTypes
    .filter((inspectionType) =>
      state.inspectionTypeIds.includes(inspectionType.id)
    )
    .map((inspectionType) => inspectionType.name);

  useEffect(() => {
    const breakdownAddress = async () => {
      // Add point
      const geocoder = new window.google.maps.Geocoder();
      const geocodingResults = await geocoder.geocode({
        address: state.addressDisplay,
      });

      if (!geocodingResults.results?.length) {
        toast.error(
          <Toast title="Please check the business address and try again" />
        );
        return;
      }

      let addressComponents = {};
      let street = "";
      for (var addressComponent of geocodingResults.results[0]
        .address_components) {
        switch (addressComponent.types[0]) {
          case "postal_code": {
            addressComponents.postcode = addressComponent.long_name;
            break;
          }

          case "country": {
            addressComponents.country = addressComponent.long_name;
            addressComponents.region = addressComponent.short_name;
            break;
          }

          case "administrative_area_level_1": {
            addressComponents.state = addressComponent.short_name;
            break;
          }

          case "locality": {
            addressComponents.suburb = addressComponent.long_name;
            break;
          }

          case "street_number": {
            street += addressComponent.long_name;
            break;
          }
          case "route": {
            street += " ";
            street += addressComponent.long_name;
            addressComponents.street = street;
            break;
          }

          default: {
            break;
          }
        }
      }

      setAddress({
        ...addressComponents,
        point: {
          latitude: geocodingResults.results[0].geometry.location.lat(),
          longitude: geocodingResults.results[0].geometry.location.lng(),
        },
        formatted: geocodingResults.results[0].formatted_address,
      });
    };
    breakdownAddress();
  }, [state.addressDisplay]);

  return (
    <OnboardingPreviewContainer>
      <H2>Preview profile</H2>
      <P1 style={{ marginTop: 10, color: "#143A5A" }}>
        Looking good, {user.firstName}! Make any edits, then submit your
        profile. You can make more changes after we publish your profile.
      </P1>

      <Form
        initialValues={{ ...state }}
        onSubmit={async (values) => {
          let { name, ...updatedValues } = values;
          try {
            updatedValues = {
              ...updatedValues,
              address,
            };

            // Submit data
            await CompaniesApi.updateCurrentCompany({ name });

            await InspectorsApi.updateCurrentInspector(updatedValues);
            toast.success(
              <Toast
                title={`Nice work, ${user.firstName}! Your profile is now active for customers to start requesting quotes.`}
              />
            );
            navigate(
              "/" + INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.DASHBOARD
            );
          } catch (e) {
            displayErrorMessage(e);
          }
        }}
        render={({ handleSubmit, submitting }) => (
          <PrimaryButton
            style={{ marginTop: 24 }}
            onClick={submitting ? undefined : handleSubmit}
          >
            {submitting ? <Loader color="#FFF" loading /> : " Publish profile"}
          </PrimaryButton>
        )}
      />

      <ProfilePreview>
        <ProfilePreviewHeader>
          <ProfileImage
            src={
              user.profilePhotoPath
                ? user.profilePhotoPath + "?w=105&h=105"
                : defaultProfilePhoto
            }
          />

          <NameAndAddress>
            <H3>{user.fullName}</H3>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Hidden xs>
                <img
                  src={addressIcon}
                  style={{ marginRight: 12 }}
                  alt="address"
                />
              </Hidden>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "28px",
                  letterSpacing: "0.2px",
                }}
              >
                {address?.suburb}, {address?.state}
              </p>

              <img
                src={editIcon}
                alt="edit"
                width={20}
                style={{ marginLeft: 30, cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    "/" +
                      INSPECTORS_MASTER_ROUTE +
                      "/" +
                      INSPECTOR_ROUTES.ONBOARDING_STEP_3,
                    {
                      state: state,
                    }
                  )
                }
              />
            </div>
          </NameAndAddress>
        </ProfilePreviewHeader>

        <ProfilePreviewBody>
          <Bio>
            <div className="flex align-center justify-between">
              <P1
                style={{
                  fontSize: 16,
                  lineHeight: "28px",
                  color: "#143A5A",
                  fontWeight: "bold",
                }}
              >
                Bio
              </P1>

              <img
                src={editIcon}
                alt="edit"
                width={20}
                style={{ marginLeft: 30, cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    "/" +
                      INSPECTORS_MASTER_ROUTE +
                      "/" +
                      INSPECTOR_ROUTES.ONBOARDING_STEP_2,
                    {
                      state: state,
                    }
                  )
                }
              />
            </div>

            <P1 style={{ fontSize: 16, lineHeight: "28px" }}>{state.bio}</P1>
          </Bio>

          <InspectionTypes>
            <div className="flex align-center justify-between">
              <P1
                style={{
                  fontSize: 16,
                  lineHeight: "28px",
                  color: "#143A5A",
                  fontWeight: "bold",
                }}
              >
                Inspection types
              </P1>

              <img
                src={editIcon}
                alt="edit"
                width={20}
                style={{ marginLeft: 30, cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    "/" +
                      INSPECTORS_MASTER_ROUTE +
                      "/" +
                      INSPECTOR_ROUTES.ONBOARDING_STEP_1,
                    {
                      state: state,
                    }
                  )
                }
              />
            </div>

            <UL style={{ marginTop: 0 }}>
              {filteredInspectionTypes.map((inspectionType, index) => (
                <li key={index}>{inspectionType}</li>
              ))}
            </UL>
          </InspectionTypes>
        </ProfilePreviewBody>
      </ProfilePreview>
    </OnboardingPreviewContainer>
  );
}
