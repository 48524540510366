import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer, { userSliceInitialState } from "./features/user/userSlice";
import inspectorsReducer, {
  inspectorsSliceInitialState,
} from "./features/inspectors/inspectorsSlice";
import companiesReducer, {
  companiesSliceInitialState,
} from "./features/companies/companiesSlice";
import customersReducer, {
  customersSliceInitialState,
} from "./features/customers/customersSlice";
import searchReducer, {
  searchSliceInitialState,
} from "./features/search/searchSlice";
import customerReviewsReducer, {
  customerReviewsSliceInitialState,
} from "./features/customer-reviews/customerReviewsSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import subscriptionReducer, {
  subscriptionSliceInitialState,
} from "./features/subscription/subscriptionSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  inspectors: inspectorsReducer,
  companies: companiesReducer,
  customers: customersReducer,
  search: searchReducer,
  customerReviews: customerReviewsReducer,
  subscription: subscriptionReducer,
});

const ROOT_INITIAL_STATE = {
  user: userSliceInitialState,
  inspectors: inspectorsSliceInitialState,
  companies: companiesSliceInitialState,
  customers: customersSliceInitialState,
  search: searchSliceInitialState,
  customerReviews: customerReviewsSliceInitialState,
  subscription: subscriptionSliceInitialState,
};

const persistedReducer = persistReducer(persistConfig, (state, action) => {
  if (action.type === "CLEAR_OUT") {
    return ROOT_INITIAL_STATE;
  }

  return rootReducer(state, action);
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
