import { Col, Container, Row } from "react-grid-system";
import { A, LinkButton, P, UL } from "ui-library";
import styled from "styled-components";
import { Loader } from "../loader";
import { InspectorsApi } from "services";
import openIcon from "assets/icons/open.svg";
import { Socials } from "components/socials";

const InspectionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: unset;
  }
`;

const StyledSocials = styled(Socials)`
  margin-top: 0;

  @media only screen and (max-width: 768px) {
    margin-top: 24px;
    margin-left: 0;
    margin-right: auto;
  }
`;

/**
 * @typedef {Object} InspectorDetailsData
 * @property {number} address
 * @property {string} companyId
 * @property {import('services/inspectors').InspectorProfileSummary} bio
 * @property {number} establishedYear
 * @property {string[]} otherInspectionServices
 * @property {string} [website]
 * @property {string[]} socials
 * @property {boolean} hasProfessionalIndemnity
 * @property {boolean} termsAgreement
 * @property {string} inspectionAgreementPath
 * @property {string} sampleReportPath
 * @property {import('services/inspectors').InspectionLicense[]} licenses
 * @returns
 */

/**
 * @typedef {Object} InspectorProfile
 * @property {number} id
 * @property {string} code
 * @property {import('services/inspectors').InspectorCompany} company
 * @property {number[]} inspectionTypeIds
 * @property {number[]} inspectionServiceIds
 * @property {import('services/inspectors').InspectorProfileSummary} profile
 * @property {InspectorDetailsData} data
 * @returns
 */

/**
 *
 * @param {Object} props
 * @param {InspectorProfile} props.inspectorProfile
 * @param {Record<string, import('services').InspectionType>} props.inspectionTypes
 * @param {Record<string, import('services').InspectionService>} props.inspectionServices
 * @returns
 */
export function InspectorDetails(props) {
  if (!props.inspectorProfile) {
    return (
      <div style={{ textAlign: "center" }}>
        <Loader size="medium" loading color="#EB9F22" />
      </div>
    );
  }

  const { data, profile, inspectionServiceIds, inspectionTypeIds, code } =
    props.inspectorProfile;
  return (
    <>
      <InspectionInfo>
        <div>
          <P
            style={{
              fontSize: "20px",
              lineHeight: "34px",
              color: "#143A5A",
              fontWeight: 700,
            }}
          >
            {profile.fullName}
          </P>

          <P>{data.bio}</P>
        </div>

        <StyledSocials socials={data.socials} />
      </InspectionInfo>

      <Container>
        <Row>
          <Col xs={12} md={4} style={{ marginTop: 48 }}>
            {/* Licenses */}
            {data.licenses && data.licenses.length ? (
              <>
                <P
                  style={{
                    color: "#143A5A",
                    fontWeight: 700,
                  }}
                >
                  {data.licenses.length > 1 ? "Licenses" : "License"}
                </P>
                {data.licenses.map((license, i) => (
                  <P key={i}>
                    {license.issuingAuthority}{" "}
                    {license.number ? ` - ${license.number}` : null}
                  </P>
                ))}
              </>
            ) : (
              <noscript />
            )}

            {/* Established Year */}
            {data.establishedYear ? (
              <>
                <P
                  style={{
                    color: "#143A5A",
                    fontWeight: 700,
                    marginTop: 16,
                  }}
                >
                  Years in business
                </P>
                <P style={{ marginBottom: 16 }}>
                  {new Date().getFullYear() - Number(data.establishedYear)}{" "}
                  years
                </P>
              </>
            ) : (
              <noscript />
            )}

            {/* Website */}
            {data.website ? (
              <>
                <P
                  style={{
                    color: "#143A5A",
                    fontWeight: 700,
                  }}
                >
                  Website
                </P>
                <A
                  style={{
                    display: "block",
                    marginBottom: 16,
                    fontSize: "16px",
                  }}
                  href={data.website}
                  target="_blank"
                >
                  {data.website}
                </A>
              </>
            ) : (
              <noscript />
            )}

            {/* Inspection Agreement */}
            {data.inspectionAgreementPath ? (
              <>
                <P
                  style={{
                    color: "#143A5A",
                    fontWeight: 700,
                    marginTop: 8,
                  }}
                >
                  Inspection agreement
                </P>
                <LinkButton
                  onClick={() =>
                    InspectorsApi.downloadFileOfTypeForCurrentInspector(
                      "InspectionAgreement",
                      code
                    )
                  }
                >
                  <span>Click to Download</span>
                  <img
                    src={openIcon}
                    alt="open"
                    width={14}
                    style={{ marginLeft: 8 }}
                  />
                </LinkButton>
              </>
            ) : (
              <noscript />
            )}

            {/* Sample report */}
            {data.sampleReportPath ? (
              <>
                <P
                  style={{
                    color: "#143A5A",
                    fontWeight: 700,
                    marginTop: 8,
                  }}
                >
                  Sample report
                </P>
                <LinkButton
                  onClick={() =>
                    InspectorsApi.downloadFileOfTypeForCurrentInspector(
                      "SampleReport",
                      code
                    )
                  }
                >
                  <span>Click to Download</span>
                  <img
                    src={openIcon}
                    alt="open"
                    width={14}
                    style={{ marginLeft: 8 }}
                  />
                </LinkButton>
              </>
            ) : (
              <noscript />
            )}
          </Col>

          {inspectionTypeIds?.length ? (
            <Col xs={12} md={4} style={{ marginTop: 48 }}>
              <P
                style={{
                  color: "#143A5A",
                  fontWeight: 700,
                }}
              >
                Inspection types
              </P>
              <UL>
                {inspectionTypeIds
                  .map(
                    (inspectionTypeId) =>
                      props.inspectionTypes[inspectionTypeId]
                  )
                  .map((inspectionType) => (
                    <li key={inspectionType.id}>{inspectionType.name}</li>
                  ))}
              </UL>
            </Col>
          ) : (
            <noscript />
          )}

          {inspectionServiceIds?.length ||
          data?.otherInspectionServices?.length ? (
            <Col xs={12} md={4} style={{ marginTop: 48 }}>
              <P
                style={{
                  color: "#143A5A",
                  fontWeight: 700,
                }}
              >
                Other services
              </P>
              <UL>
                {inspectionServiceIds
                  .map(
                    (inspectionServiceId) =>
                      props.inspectionServices[inspectionServiceId]
                  )
                  .map((inspectionService) => (
                    <li key={inspectionService.id}>{inspectionService.name}</li>
                  ))}

                {data?.otherInspectionServices?.map(
                  (otherInspectionService, index) => (
                    <li key={`other-service-${index}`}>
                      {otherInspectionService}
                    </li>
                  )
                )}
              </UL>
            </Col>
          ) : (
            <noscript />
          )}
        </Row>
      </Container>
    </>
  );
}
