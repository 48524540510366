import Banner from "components/public-pages/banner/banner";
import AboutUsBanner from "assets/images/about-us-banner.png";
import AndrewImage from "assets/images/andrew.png";
import TrishImage from "assets/images/trish.png";
import { H2, P1 } from "ui-library";
import { DocumentTitle } from "components/document-title";
import { Container } from "react-grid-system";
import { Spacer } from "common/helpers";
import styled from "styled-components";

export const ProfileSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 80px;
  &:first-of-type {
    padding-bottom: 75px;
    border-bottom: 1px solid #e4f0f7;
  }
  &:last-of-type {
    margin-top: 80px;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  h2 {
    margin-bottom: 28px;
  }
  img {
    width: 100%;
    height: auto;
  }
  a {
    color: #028cb0;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    color: #798995;
  }

  ul {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    color: #798995;
  }
`;

export default function AboutUsPage() {
  return (
    <>
      <Banner
        image={AboutUsBanner}
        title="Over 20 years running Australia's most awarded inspection company."
        excerpt={
          <P1>
            We know the challenges of being an inspector. Rate my Inspectors
            brings together our experience and the latest technology with a
            genuine desire to make the experience of finding the right inspector
            easy and fast while supporting inspectors to provide world class
            service.
          </P1>
        }
      />

      <Spacer />

      <DocumentTitle title="About Us">
        <Container>
          <ProfileSection>
            <img src={AndrewImage} alt="Andrew" />
            <div>
              <H2>Andrew Mackie-Smith</H2>
              <p>
                Andrew is an Inspector, Builder and Building Certifier. Andrew
                has personally performed over 14,000 inspections on all manner
                of residential, commercial, industrial and government buildings
                in Queensland, Victoria and New South Wales, Australia. <br />
                <br /> Winner of Master Builders Qld Building Consultant of the
                Year 2017 and 2018. Also winner of Your Investment Property
                Magazine - Best Building and Pest business in Australia in 2018.
                Andrew is a prolific author and regularly appears in national
                media to provide expert advice on building matters. <br />
                <br /> Andrew was the founding community lead for StartBUILD
                Proptech Brisbane. <br />
                <br /> In August 2019 Andrew gave presentations at the EduCON
                international conference in NSW, Australia. <br />
                <br /> Andrew is also the co-founder of inndox.com - Digital
                Property Logbook. Inndox was awarded “Most innovative Startup
                Proptech for Property & Facilities Management” at the inaugural
                Proptech Association of Australia awards 2021. <br />
              </p>
            </div>
          </ProfileSection>
          <ProfileSection>
            <img src={TrishImage} alt="Trish" />
            <div>
              <H2>Trish Mackie-Smith</H2>
              <p>
                A former property lawyer and co-founder of Australia's most
                awarded building consultancy, Trish was awarded "Proptech Leader
                of the Year 2022" from the Australian Proptech Association. She
                also won the Housing Industry Association - Women in Building
                Award in 2019 - Business Development Professional and was a
                finalist for Optus MyBusiness Businesswoman of the Year in 2016.{" "}
                <br />
                <br />
                She has led a campaign to improve seller disclosure for property
                buyers that has included petitions to parliament and various
                media appearances.
                <br />
                <br /> Trish is a speaker and exhibitor at PropTech events
                including: the PropTech Summit, Sydney 2018 , Urbanity, Brisbane
                2018, Women in Digital Business 2019, Fishburners Australian
                Property Institute Proptech PItch 2019 and EduCon 2019
                international conference for Building Consultants.
                <br />
                <br /> Trish is also the co-founder and CEO of{" "}
                <a href="https://www.inndox.com/">inndox.com</a> - inndox
                provides the Logbook used by Rate My Inspectors.
                <br />
                <br />
              </p>
              <p>
                <strong>
                  inndox has won many awards for innovation and excellence
                  including:
                </strong>
              </p>
              <ul>
                <li>
                  Best Startup at the Australian Proptech Association Awards
                  2021
                </li>
                <li>
                  Finalist - The Urban Developer Australia Excellence Awards -
                  Proptech Category 2021
                </li>
                <li>Finalist - The Australian Construction Awards 2020</li>
                <li>
                  Finalist - The Australian Property institute excellence awards
                  2019
                </li>
              </ul>
              <a href="https://www.inndox.com/">Read more about inndox</a>
            </div>
          </ProfileSection>
        </Container>
      </DocumentTitle>

      <Spacer />
    </>
  );
}
