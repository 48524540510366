import {
  Address,
  DateSelector,
  Input,
  Loader,
  Dropzone,
  FiftyFifty,
  BackButton,
  MultiSelect,
  Select,
} from "components";
import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InspectionReportsApi } from "services";
import { displayErrorMessage } from "services/api";
import { inspectionTypesSelector } from "store/features/inspectors/inspectorsSlice";
import { H2, P, PrimaryButton } from "ui-library";
import styled from "styled-components";
import tipIcon from "assets/icons/tip.svg";
import { DocumentTitle } from "components/document-title";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import { createFormDataForAddingOrUpdatingAReport } from "../../../helpers";
import { requiredValidator } from "components/form/validators";
import dollarIcon from "assets/icons/dollar.svg";

const Info = styled.div`
  background-color: #fffdf2;
  border: 1px solid #fff1c2;
  border-radius: 3px;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
  max-width: 650px;

  ${P} {
    color: #143a5a;
  }
`;

export default function InspectorAddReport() {
  const navigate = useNavigate();
  const inspectionTypes = useSelector(inspectionTypesSelector);

  return (
    <DocumentTitle title="Add a Report">
      <FiftyFifty>
        <Form
          onSubmit={async (values, dispatch) => {
            try {
              const formData = await createFormDataForAddingOrUpdatingAReport(
                values
              );

              await InspectionReportsApi.createInspectionReportForCurrentInspector(
                formData
              );

              navigate(
                "/" + INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.REPORTS
              );
            } catch (e) {
              displayErrorMessage(e);
            }
          }}
          render={({ handleSubmit, submitting }) => (
            <Container fluid>
              <Row>
                <Col xs={12}>
                  <BackButton
                    onClick={() =>
                      navigate(
                        "/" +
                          INSPECTORS_MASTER_ROUTE +
                          "/" +
                          INSPECTOR_ROUTES.REPORTS
                      )
                    }
                  />
                  <H2 style={{ marginTop: 32 }}>Add your report</H2>
                  <P>
                    Upload the inspection report to to make it available for
                    sale.
                  </P>

                  <Info>
                    <img src={tipIcon} alt="tip" style={{ marginRight: 24 }} />
                    <div>
                      <P>
                        <strong>Tip</strong>
                      </P>
                      <P>
                        If you have an inspection agreement, we recommend that
                        you upload a copy to your profile so that report buyers
                        understand the scope, limitations and other conditions
                        of your service.
                      </P>
                    </div>
                  </Info>
                </Col>

                <div style={{ maxWidth: 414 }}>
                  <Col xs={12}>
                    <Dropzone
                      name="reportFile"
                      label="Inspection report"
                      accept=".pdf"
                      validate={requiredValidator}
                    />
                  </Col>

                  <Col xs={12}>
                    <Dropzone
                      name="propertyImageFile"
                      label="Property image"
                      accept=".png, .jpg, .jpeg"
                      validate={requiredValidator}
                      placeholder="Drop your .PNG, .JPG or .JPEG file here or select from your computer"
                    />
                  </Col>

                  <Col xs={12}>
                    <Address
                      name="address"
                      label="Property address"
                      placeholder="Enter address of inspection"
                      validators={requiredValidator}
                    />
                  </Col>

                  <Col xs={12}>
                    <Select
                      name="propertyType"
                      placeholder="Select type of property"
                      label="Property type"
                      items={[
                        "Apartment",
                        "Townhouse",
                        "House",
                        "Commercial",
                        "Industrial",
                        "Mobile",
                        "Duplex",
                        "GrannyFlat",
                        "Other",
                      ].map((propertyType) => ({
                        label: propertyType,
                        value: propertyType,
                      }))}
                      validators={requiredValidator}
                    />
                  </Col>

                  <Col xs={12}>
                    <MultiSelect
                      name="inspectionTypeIds"
                      label="Type of inspection(s)"
                      placeholder="Select type of inspection"
                      options={inspectionTypes.map((inspectionType) => ({
                        label: inspectionType.name,
                        value: inspectionType.id,
                      }))}
                      validators={requiredValidator}
                    />
                  </Col>

                  <Col xs={12}>
                    <DateSelector
                      name="inspectionDate"
                      label="Date of inspection"
                      validators={requiredValidator}
                    />
                  </Col>

                  <Col xs={12}>
                    <Input
                      name="price"
                      label="Report price"
                      type="number"
                      validate={requiredValidator}
                      icon={<img src={dollarIcon} alt="dollar" />}
                    />
                  </Col>

                  <Col xs={12}>
                    <PrimaryButton
                      style={{ marginTop: 48, width: "100%" }}
                      onClick={submitting ? null : handleSubmit}
                    >
                      {submitting ? (
                        <Loader loading />
                      ) : (
                        "Make report available for sale"
                      )}
                    </PrimaryButton>
                  </Col>
                </div>
              </Row>
            </Container>
          )}
        />
      </FiftyFifty>
    </DocumentTitle>
  );
}
