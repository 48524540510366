import styled from "styled-components";
import { P } from "ui-library";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 11px 48px rgba(51, 51, 51, 0.06);
  border-radius: 32px;
  height: 104px;
  display: flex;
  padding: 24px;
  align-items: center;
  margin-top: 24px;

  img {
    height: 100%;
    width: auto;
    margin-right: 16px;
  }

  /* @media only screen and (min-width: 992px) {
    margin-top: -52px;
  } */

  @media only screen and (max-width: 576px) {
    width: 100%;
    padding: 16px;
  }
`;

const Title = styled(P)`
  font-weight: 800;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #143a5a;

  @media only screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

const Body = styled(P)`
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #798995;
  font-weight: 400;

  @media only screen and (max-width: 576px) {
    font-size: 16px;
  }
`;

export default function StatsCard({ image, title, subtitle, className }) {
  return (
    <Wrapper className={className}>
      {image && <img src={image} alt="profile" />}
      <div className="label-wrapper">
        <Title>{title}</Title>
        <Body>{subtitle}</Body>
      </div>
    </Wrapper>
  );
}
