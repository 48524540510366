import { useEffect, useState } from "react";
import { Input } from "./form";
import facebookIcon from "assets/icons/facebook.svg";
import instagramIcon from "assets/icons/instagram.svg";
import linkedinIcon from "assets/icons/linkedin.svg";
import twitterIcon from "assets/icons/twitter.svg";
import otherLink from "assets/icons/other-link.svg";
import { useFormState } from "react-final-form";
import styled from "styled-components";
import closeIcon from "assets/icons/close-red.svg";
import { urlValidator } from "./form/validators";

const iconToNameMapping = {
  facebook: facebookIcon,
  instagram: instagramIcon,
  linkedin: linkedinIcon,
  twitter: twitterIcon,
  other: otherLink,
};

const URL_VALIDATION_REGEX = /^((http|https):\/\/)?[^ "]+$/;

const SocialInputContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  justify-content: space-between;
`;

const Button = styled.div`
  margin-left: 8px;
  margin-bottom: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 4px;

  &:hover {
    background-color: #f7ebec;
  }

  &:focus,
  &:active {
    background-color: #f1d9d9;
  }
`;

export function SocialInput({ name, index, removeField, addField }) {
  const [icon, setIcon] = useState();
  const formState = useFormState();
  const socials = formState.values["socials"];
  const value = socials ? socials[index] : "";

  useEffect(() => {
    if (value?.length) {
      if (URL_VALIDATION_REGEX.test(value)) {
        const linkedinLink = value?.indexOf("linkedin.com") > -1;
        const instagramLink = value?.indexOf("instagram.com") > -1;
        const twitterLink = value?.indexOf("twitter.com") > -1;
        const facebookLink = value?.indexOf("facebook.com") > -1;

        if (linkedinLink) {
          setIcon("linkedin");
        } else if (instagramLink) {
          setIcon("instagram");
        } else if (twitterLink) {
          setIcon("twitter");
        } else if (facebookLink) {
          setIcon("facebook");
        } else {
          setIcon("other");
        }
      } else {
        setIcon();
      }
    } else {
      setIcon();
    }
  }, [value]);

  return (
    <SocialInputContainer>
      <div style={{ flexGrow: 1 }}>
        <Input
          name={name}
          validate={urlValidator}
          onKeyDown={(event) => {
            switch (event.key) {
              case "Enter": {
                addField();
                event.preventDefault();
                break;
              }
              default:
                break;
            }
          }}
          icon={
            <img
              src={iconToNameMapping[icon]}
              alt={icon}
              style={{ maxWidth: 18, maxHeight: 18 }}
            />
          }
        />
      </div>

      <Button onClick={removeField}>
        <img width={14} src={closeIcon} alt="close" />
      </Button>
    </SocialInputContainer>
  );
}
