import StatsCard from "./icons/stats-card";
import InspectionReportsProfile from "../../assets/images/inspection-reports.png";
import InspectionTypesStatsImage from "../../assets/images/inspection-types-stats.png";
import OneThousandProfile from "../../assets/images/1k-inspectors.png";
import { ProfilesWrapper } from "public/pages/property-reports";
import styled from "styled-components";

const StatsCards = styled.div`
  position: absolute;
  top: 0px;
  right: 24px;
  display: flex;
  gap: 16px;

  @media only screen and (max-width: 576px) {
    display: block;
    position: relative;
    top: unset;
    right: unset;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export default function StatsCardWrapper({ className }) {
  return (
    <ProfilesWrapper className={className}>
      <StatsCards>
        <StatsCard
          image={OneThousandProfile}
          title="12K+ Inspectors"
          subtitle="ready to inspect"
        />
        <StatsCard
          image={InspectionReportsProfile}
          title="50,000+"
          subtitle="Inspection reports available"
        />
        <StatsCard
          image={InspectionTypesStatsImage}
          title="50+ Inspection types"
          subtitle="Variety of inspection type offerings"
        />
      </StatsCards>
    </ProfilesWrapper>
  );
}
