import Toast from "components/toast";
import { toast } from "react-toastify";

export const createFormDataForAddingOrUpdatingAReport = async (values) => {
  let updatedValues = values;
  if (typeof values.inspectionDate === "string") {
    updatedValues.inspectionDate = new Date(values.inspectionDate);
  }

  // Add point
  const geocoder = new window.google.maps.Geocoder();
  const geocodingResults = await geocoder.geocode({
    address: values.address,
  });

  if (!geocodingResults.results?.length) {
    toast.error(
      <Toast title="Please check the address of the property being inspected and try again" />
    );
    return;
  }

  const result = geocodingResults.results[0];

  updatedValues = {
    ...updatedValues,
    address: result.formatted_address,
    point: {
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
    },
  };

  const formData = new FormData();
  formData.append("inspectionDate", updatedValues.inspectionDate.toISOString());
  delete updatedValues.inspectionDate;

  Object.keys(updatedValues).forEach((key) => {
    if (key === "point") {
      Object.keys(updatedValues[key]).forEach((subKey) => {
        formData.append("point." + subKey, updatedValues[key][subKey]);
      });
    } else {
      formData.append(key, updatedValues[key]);
    }
  });

  return formData;
};
