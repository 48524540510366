import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  More,
  Lightbox,
  MoreMenuContent,
  MoreMenuItem,
  Modal,
  Rating,
  TextArea,
  Dropzone,
  Loader,
} from "components";
import {
  P,
  PrimaryButton,
  Rating as ReadonlyRating,
  TertiaryAlertButton,
  TertiaryButton,
} from "ui-library";
import moreIcon from "assets/icons/more.svg";
import TimeAgo from "react-timeago";
import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { InspectorReviewsApi } from "services";
import { useDispatch } from "react-redux";
import {
  deleteReview,
  setCustomerReviews,
} from "store/features/customers/customersSlice";
import { toast } from "react-toastify";
import Toast from "components/toast";
import NetworkImage from "components/network-image";
import { useNavigate } from "react-router-dom";
import {
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_INSPECTORS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import { displayErrorMessage } from "services/api";

const CustomerReviewContainer = styled.div``;

const Header = styled.div``;

const InspectorPhoto = styled(NetworkImage)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const MoreOptions = styled.div`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const Body = styled.div`
  margin-top: 16px;
`;

const Footer = styled.div`
  margin-top: 16px;
`;

const RateWarning = styled.div`
  background-color: #fffdf2;
  border: 1px solid #fff1c2;
  border-radius: 3px;
  padding: 24px;
  margin-top: 16px;
  margin-bottom: 32px;

  ${P} {
    color: #143a5a;
  }
`;

const Separator = styled.hr`
  margin: 0;
  margin-top: 40px;
  margin-bottom: 40px;
  border: none;
  background-color: #e4f0f7;
  height: 1px;
`;

const ReviewImages = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

/**
 * @param {Object} props
 * @param {import("services").InspectorReview} props.review
 * @returns
 */
export default function CustomerReview({ review }) {
  const [wantsToEditReview, setWantsToEditReview] = useState(false);
  const [isDeletingReview, setIsDeletingReview] = useState(false);
  const [wantsToDeleteReview, setWantsToDeleteReview] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasDeletedReview, setHasDeletedReview] = useState(false);

  const deleteInspectorReview = async () => {
    try {
      setIsDeletingReview(true);
      await InspectorReviewsApi.deleteReview(review.inspector.id, review.id);
      setWantsToDeleteReview(false);
      setIsDeletingReview(false);
      toast.success(
        <Toast
          title={`Your review for ${review.inspector.fullName} has been deleted`}
        />
      );
      setHasDeletedReview(true);
    } catch (e) {
      displayErrorMessage(e);
    }
  };

  useEffect(() => {
    if (hasDeletedReview) {
      dispatch(deleteReview({ reviewId: review.id }));
    }
  }, [dispatch, hasDeletedReview, review.id]);

  return (
    <CustomerReviewContainer>
      <Header className="flex align-center">
        <InspectorPhoto
          src={
            review.inspector.imagePath
              ? review.inspector.imagePath + "?w=40&h=40"
              : null
          }
        />
        <div style={{ marginLeft: 16, marginRight: "auto" }}>
          <P style={{ color: "#143A5A" }}>
            <strong>{review.inspector.fullName}</strong>
          </P>
          <P style={{ color: "#143A5A" }}>
            {review.inspector.company?.name &&
            review.inspector.company?.name !== "Company name"
              ? review.inspector.company.name
              : ""}
          </P>
        </div>

        <More
          trigger={
            <MoreOptions>
              <img src={moreIcon} alt="more" height={24} />
            </MoreOptions>
          }
        >
          <MoreMenuContent>
            <MoreMenuItem
              onClick={() =>
                navigate(
                  "/" +
                    SEARCH_MASTER_ROUTE +
                    "/" +
                    SEARCH_INSPECTORS_MASTER_ROUTE +
                    "/" +
                    review?.inspector.code +
                    "/" +
                    SEARCH_INSPECTORS_ROUTES.RATE_INSPECTOR
                )
              }
            >
              <P>Edit review</P>
            </MoreMenuItem>
            <MoreMenuItem onClick={() => setWantsToDeleteReview(true)}>
              <P>Delete review</P>
            </MoreMenuItem>
          </MoreMenuContent>
        </More>
      </Header>

      <Body>
        <P>
          <em>
            <TimeAgo date={review.createdOn} />
          </em>
        </P>
        <P>{review.review}</P>

        <ReviewImages>
          {review.imagePaths?.map((imagePath, index) => (
            <Lightbox key={index} src={imagePath} width={115} height={115} />
          ))}
        </ReviewImages>
      </Body>

      <Footer className="flex align-center">
        <ReadonlyRating rating={review.stars} editable={false} />
      </Footer>

      <Separator />

      <Modal
        isOpen={wantsToEditReview}
        title={`Edit review for ${review.inspector.fullName}`}
        onAfterClose={() => setWantsToEditReview(false)}
      >
        {(closeModal) => (
          <Form
            initialValues={{
              review: review.review,
              stars: review.stars,
              id: review.id,
              inspectorId: review.inspector.id,
            }}
            onSubmit={async (values) => {
              const formData = new FormData();
              Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
              });

              try {
                await InspectorReviewsApi.editReview(
                  review.inspector.id,
                  review.id,
                  formData
                );
                const response =
                  await InspectorReviewsApi.getReviewsCreatedByCurrentCustomer();
                dispatch(setCustomerReviews(response));

                toast.success(
                  <Toast
                    title={`Review for ${review.inspector.fullName} has been updated.`}
                  />
                );

                closeModal();
              } catch (e) {
                toast.error(
                  <Toast title={e?.response?.data?.globalErrors[0]?.message} />
                );
              }
            }}
            render={({ handleSubmit, submitting, values }) => (
              <Container fluid>
                <Row>
                  <Col xs={12}>
                    <Rating name="stars" />
                  </Col>
                  <Col xs={12}>
                    {values.stars <= 3 ? (
                      <RateWarning>
                        <P>
                          If you are not satisfied with the inspectors service,
                          then we recommend you contact them and try to resolve
                          the matter before you leave a review on Rate my
                          Inspectors.
                        </P>
                      </RateWarning>
                    ) : (
                      <noscript />
                    )}
                  </Col>
                  <Col xs={12}>
                    <TextArea
                      name="review"
                      label="Your review"
                      placeholder="Share details of your own experience with this inspector or inspection company."
                    />
                  </Col>

                  <Col xs={12}>
                    <Dropzone
                      name="imageFile"
                      label="Attach a photo to your review"
                      placeholder="Drop your file here or select from your computer"
                    />
                  </Col>

                  <Col xs={12}>
                    <PrimaryButton
                      style={{ marginTop: 48 }}
                      onClick={submitting ? null : handleSubmit}
                    >
                      {submitting ? <Loader loading /> : "Update review"}
                    </PrimaryButton>
                  </Col>
                </Row>
              </Container>
            )}
          />
        )}
      </Modal>

      {/* Delete Review Modal */}
      <Modal isOpen={wantsToDeleteReview} title="Delete review" size="small">
        <>
          <P>
            Are you sure you want to delete the review you posted for{" "}
            {review.inspector.fullName}?
          </P>

          <TertiaryAlertButton
            style={{
              marginTop: 40,
              border: "2px solid #EA4335",
              backgroundColor: "#EA4335",
              color: "#fff",
              width: "100%",
            }}
            onClick={() => {
              if (!isDeletingReview) {
                deleteInspectorReview();
              }
            }}
          >
            <Loader loading={isDeletingReview} color="#fff" />
            {isDeletingReview ? null : "Delete"}
          </TertiaryAlertButton>
          <TertiaryButton
            style={{
              marginTop: 8,
              width: "100%",
            }}
            onClick={() => setWantsToDeleteReview(false)}
          >
            Cancel
          </TertiaryButton>
        </>
      </Modal>
    </CustomerReviewContainer>
  );
}
