import { useEffect, useState } from "react";
import { Col, Container, Hidden, Row } from "react-grid-system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import defaultProfilePhoto from "assets/images/default-profile-photo.svg";
import caretDownIcon from "assets/icons/caret-down.svg";
import caretDownDarkIcon from "assets/icons/caret-down-dark.svg";
import closeIcon from "assets/icons/close.svg";
import logoIcon from "assets/icons/logo.svg";
import logoIconIcon from "assets/icons/logo-icon.svg";
import hamburgerIcon from "assets/icons/hamburger.svg";
import { More, MoreMenuContent, MoreMenuItem } from "./more";
import {
  H3,
  P,
  PrimaryButton,
  RoundedPrimaryButton,
  SecondaryButton,
} from "ui-library";
import { useDispatch, useSelector } from "react-redux";
import { paymentsConfigSelector } from "store/features/subscription/subscriptionSlice";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  inspectionTypesSelector,
  inspectorSelector,
} from "store/features/inspectors/inspectorsSlice";
import {
  ACCOUNT_MASTER_ROUTE,
  ACCOUNT_ROUTES,
  INSPECTORS_MASTER_ROUTE,
  INSPECTOR_ROUTES,
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  REPORTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";
import { useAuth } from "common/authentication";
import Subscribe from "account/components/subscribe";
import { UserSessionsApi } from "services";
import { setSession } from "store/features/user/userSlice";
import { handleError } from "services/api";

const NavbarContainer = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 2;
  background-color: #fff;
`;

const StyledContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  height: 104px;

  @media only screen and (min-width: 1025px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media only screen and (max-width: 576px) {
    height: 56px;

    ul {
      height: 56px;
    }

    .non-mobile {
      display: none;
    }
  }

  ${RoundedPrimaryButton} {
    width: 112px;
  }

  &.authenticated {
    border-bottom: 1px solid #e4f0f7;
  }

  &.scrolled {
    box-shadow: 0px 5px 20px rgba(24, 105, 171, 0.06);
  }
`;

const Logo = styled.img`
  width: 153px;
  margin-left: 0;
  margin-right: auto;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

const MobileLogo = styled.img`
  height: 35px;
  margin-left: 0;
  margin-right: auto;
  display: none;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    display: block;
  }
`;

const ProfilePhoto = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 8px;

  @media only screen and (max-width: 576px) {
    width: 36px;
    height: 36px;
  }
`;

const UNAUTH_NAV_ITEMS = [
  {
    name: "Property reports",
    path: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.PROPERTY_REPORTS,
  },
  {
    name: "Solutions",
    items: [
      {
        name: "For Inspectors",
        path: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.FOR_INSPECTORS,
        description: "Get more work and enter our Awards",
      },
      {
        name: "For Buyers",
        path: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.FOR_BUYERS,
        description: "Connect with the best rated inspectors",
      },
      {
        name: "For Sellers",
        path: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.FOR_SELLERS,
        description: "Uncover deal-breakers before you sell",
      },
      {
        name: "For Agents",
        path: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.FOR_AGENTS,
        description: "Find available inspectors",
      },
    ],
  },
  // {
  //   name: "Awards",
  //   path: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.AWARDS,
  // },
  {
    name: "Inspection types",
    path: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.INSPECTION_TYPES,
  },
];

const INSPECTOR_NAV_ITEMS = [
  {
    name: "Reviews",
    path: INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.REVIEWS,
  },
  {
    name: "Reports",
    path: INSPECTORS_MASTER_ROUTE + "/" + REPORTS_MASTER_ROUTE,
  },
  {
    name: "Videos",
    path: INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.VIDEOS,
    needsSubscription: true,
  },
];

const CUSTOMER_NAV_ITEMS = [
  {
    name: "Property reports",
    path: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.PROPERTY_REPORTS,
  },
];

const ProfilePhotoContainer = styled.div`
  margin-right: 0;
  height: 104px;

  @media only screen and (max-width: 576px) {
    height: 56px;
  }
`;

const HamburgerMenu = styled.div`
  width: 40px;
  height: 56px;
  align-items: center;
  justify-content: center;
  display: none;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    display: flex;
  }
`;

const MobileMenuContainer = styled.div`
  display: none;
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  padding: 24px;
  overflow: auto;
  font-family: HeadingFont;
  font-weight: 500;

  ${RoundedPrimaryButton} {
    width: 100%;
  }

  @media only screen and (max-width: 576px) {
    display: block;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        height: 48px;
        margin-bottom: 24px;

        a {
          line-height: 48px;
          font-size: 24px;
          text-decoration: none;
          color: #0d1f30;
        }
      }
    }
  }
`;

const UpgradeLabel = styled.div`
  font-size: 8px;
  line-height: 10px;
  height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 1.3px;
  color: #798995;
  font-weight: bold;
  background: #e4f0f7;
  border-radius: 2px;
  text-transform: uppercase;
  margin-top: -21px;
  margin-bottom: 5px;

  &.active {
    background: #eb9f22;
    color: #ffffff;
  }

  @media only screen and (max-width: 576px) {
    margin-left: 8px;
    padding: 2px 8px;
    display: inline-block;
    margin-top: 20px;
  }
`;

const StyledLink = styled(Link)`
  &.inactive {
    color: #c9ced6;
  }
`;

const AnonymousUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 106px;

  @media only screen and (max-width: 576px) {
    height: 56px;
  }
`;

const AnonymousUserImage = styled.img`
  width: 63px;
  height: 63px;
  border-radius: 50%;
`;

const InspectorInfo = styled.div`
  margin-left: 22px;
`;

const ImpersonationBanner = styled.div`
  height: 32px;
  background-color: #143a5a;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 14px;
    font-weight: 700;
    color: #eb9f22;
  }
`;

const LogoCol = styled(Col)`
  height: 104px;

  @media only screen and (max-width: 576px) {
    height: 56px;
  }
`;

const LoginLink = styled(Link)`
  color: #028cb0 !important;
`;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export function Navbar({ authenticated }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [hasShadow, setHasShadow] = useState(false);
  const paymentConfig = useSelector(paymentsConfigSelector);
  const hasValidSubscription = paymentConfig?.planStatus === "Active";
  const inspector = useSelector(inspectorSelector);
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const dispatch = useDispatch();
  const session = auth?.session;
  const userAccount = auth?.user;
  const isUserBeingOnboarded = pathname.startsWith(
    "/" + INSPECTORS_MASTER_ROUTE + "/onboarding"
  );

  const claimProfile = () => {
    navigate(
      "/" +
        SEARCH_MASTER_ROUTE +
        "/" +
        SEARCH_ACCOUNTS_MASTER_ROUTE +
        "/" +
        SEARCH_ACCOUNTS_ROUTES.CLAIM,
      {
        state: {
          selectedInspector: {
            ...inspector,
            profile: {
              id: inspector.id,
              fullName: session.fullName,
              imagePath: session.profilePhotoPath,
              phone: session.phone,
              email: session.email,
            },
          },
          inspectionTypes,
        },
      }
    );
  };

  const handleShadow = (e) => {
    if (window.scrollY > 0) {
      setHasShadow(true);
    } else {
      setHasShadow(false);
    }
  };

  const openDashboard = () => {
    if (session) {
      if (session.status === "Anonymous") {
        navigate(PUBLIC_MASTER_ROUTE);
        dispatch({
          type: "CLEAR_OUT",
        });
      } else {
        navigate(INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.DASHBOARD);
      }
    } else {
      navigate(PUBLIC_MASTER_ROUTE);
    }
  };

  const unimpersonateCurrentUser = async () => {
    UserSessionsApi.undoImpersonate()
      .then((newSession) => {
        if (
          session.impersonator?.roles.some((role) =>
            role.role.includes("Administrator")
          )
        ) {
          dispatch(setSession(newSession));
          window.location.href = process.env.REACT_APP_BASE_URL + "/admin";
        } else {
          dispatch(setSession(newSession));
          window.location.reload();
        }
      })
      .catch(handleError);
  };

  // To hide and show shadow on the navbar
  useEffect(() => {
    window.addEventListener("scroll", handleShadow.bind(Navbar));

    return () =>
      window.removeEventListener("scroll", handleShadow.bind(Navbar));
  }, []);

  // To close mobile menu on page change
  useEffect(() => {
    setShowMobileMenu(false);
  }, [pathname]);

  // To stop body scrolling when
  // mobile menu is showing or not
  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMobileMenu]);

  if (session?.status === "Anonymous") {
    if (!inspector || !inspectionTypes?.length) {
      return <noscript />;
    }

    const keyedInspectionTypes = inspectionTypes.reduce(
      (reduction, inspectionType) => {
        reduction[inspectionType.id] = inspectionType;
        return reduction;
      },
      {}
    );
    const inspectionTypeNames = inspector.inspectionTypeIds.map(
      (inspectionTypeId) => keyedInspectionTypes[inspectionTypeId].name
    );
    // different nav bar
    return (
      <NavbarContainer className={hasShadow ? "scrolled" : null}>
        <Container fluid>
          <Row>
            <Col className="flex align-center">
              <Logo src={logoIcon} onClick={openDashboard} />
              <MobileLogo src={logoIconIcon} onClick={openDashboard} />

              <AnonymousUserContainer>
                <Hidden xs>
                  <AnonymousUserImage
                    src={
                      session.profilePhotoPath
                        ? session.profilePhotoPath + "?w=63&h=63"
                        : defaultProfilePhoto
                    }
                  />
                </Hidden>

                <Hidden xs>
                  <InspectorInfo>
                    <H3 style={{ lineHeight: "normal" }}>{session.fullName}</H3>

                    <P>
                      {inspectionTypeNames.length > 1
                        ? inspectionTypeNames[0] +
                          ` + ${inspectionTypeNames.length - 1} more`
                        : inspectionTypeNames[0]}
                    </P>
                  </InspectorInfo>
                </Hidden>

                <PrimaryButton
                  style={{ marginLeft: 16 }}
                  onClick={claimProfile}
                >
                  Claim your free profile
                </PrimaryButton>
              </AnonymousUserContainer>
            </Col>
          </Row>
        </Container>
      </NavbarContainer>
    );
  }

  if (authenticated) {
    return (
      <Elements stripe={stripePromise}>
        {/* Navbar - Desktop */}
        <NavbarContainer>
          {/* Impersonation Banner */}
          {session?.impersonator ? (
            <ImpersonationBanner>
              <p>You are impersonating {userAccount?.fullName}.</p>
              <button
                className="button button-link"
                style={{
                  padding: 0,
                  paddingLeft: 8,
                  backgroundColor: "transparent",
                  color: "#eb9f22",
                  border: "none",
                  textDecoration: "underline",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={unimpersonateCurrentUser}
              >
                Exit View
              </button>
            </ImpersonationBanner>
          ) : (
            <noscript />
          )}

          <StyledContainer
            className={`authenticated ${hasShadow ? "scrolled" : null}`}
          >
            <Container fluid>
              <Row>
                {isUserBeingOnboarded ? (
                  <LogoCol className="flex align-center">
                    <Logo src={logoIcon} />
                    <MobileLogo src={logoIconIcon} />
                  </LogoCol>
                ) : (
                  <Col className="flex align-center">
                    <Logo src={logoIcon} onClick={openDashboard} />
                    <MobileLogo src={logoIconIcon} onClick={openDashboard} />

                    {/* Nav Links */}
                    {userAccount?.roles?.indexOf("CompanyAdmin") > -1 ? (
                      <ul className="non-mobile">
                        {INSPECTOR_NAV_ITEMS.map((navItem, index) => {
                          if (
                            !hasValidSubscription &&
                            navItem.needsSubscription
                          ) {
                            return (
                              <li key={`nav-${index}`}>
                                <UpgradeLabel
                                  className={
                                    pathname === "/" + navItem.path
                                      ? "active"
                                      : ""
                                  }
                                >
                                  Upgrade
                                </UpgradeLabel>
                                {/* <LockIcon src={lockIcon} alt="lock" /> */}
                                <StyledLink
                                  to={navItem.path}
                                  className={
                                    pathname === "/" + navItem.path
                                      ? "active"
                                      : ""
                                  }
                                >
                                  {navItem.name}
                                </StyledLink>
                              </li>
                            );
                          }

                          return (
                            <li key={`nav-${index}`}>
                              <Link
                                to={navItem.path}
                                className={
                                  pathname === "/" + navItem.path
                                    ? "active"
                                    : ""
                                }
                              >
                                {navItem.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <ul className="non-mobile">
                        {CUSTOMER_NAV_ITEMS.map((navItem, index) => {
                          return (
                            <li key={`nav-${index}`}>
                              <Link
                                to={navItem.path}
                                className={
                                  pathname === "/" + navItem.path
                                    ? "active"
                                    : ""
                                }
                              >
                                {navItem.name}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}

                    {!hasValidSubscription &&
                    userAccount?.roles?.indexOf("CompanyAdmin") > -1 ? (
                      <Hidden xs>
                        <Subscribe
                          trigger={
                            <SecondaryButton
                              style={{ marginRight: 16, padding: "8px 16px" }}
                            >
                              Upgrade
                            </SecondaryButton>
                          }
                        />
                      </Hidden>
                    ) : (
                      <noscript />
                    )}

                    <HamburgerMenu
                      style={{ marginRight: 8 }}
                      onClick={() => setShowMobileMenu((_) => !_)}
                    >
                      <img
                        src={showMobileMenu ? closeIcon : hamburgerIcon}
                        alt="menu"
                        width={20}
                      />
                    </HamburgerMenu>

                    {/* Profile photo */}
                    <More
                      trigger={
                        <ProfilePhotoContainer className="flex align-center">
                          <ProfilePhoto
                            src={
                              userAccount?.profilePhotoPath
                                ? userAccount?.profilePhotoPath + "?w=48&h=48"
                                : defaultProfilePhoto
                            }
                          />
                          <img
                            src={caretDownIcon}
                            alt="caret down"
                            width={12}
                          />
                        </ProfilePhotoContainer>
                      }
                    >
                      <MoreMenuContent>
                        <MoreMenuItem
                          onClick={() =>
                            navigate(
                              ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.MANAGE
                            )
                          }
                        >
                          Account settings
                        </MoreMenuItem>
                        {userAccount?.roles?.indexOf("CompanyAdmin") > -1 ? (
                          <MoreMenuItem
                            onClick={() =>
                              navigate(
                                INSPECTORS_MASTER_ROUTE +
                                  "/" +
                                  INSPECTOR_ROUTES.EDIT_PROFILE
                              )
                            }
                          >
                            Edit profile
                          </MoreMenuItem>
                        ) : (
                          <noscript />
                        )}
                        {userAccount?.roles?.indexOf("CompanyAdmin") > -1 ? (
                          <MoreMenuItem
                            onClick={() =>
                              navigate(
                                ACCOUNT_MASTER_ROUTE +
                                  "/" +
                                  ACCOUNT_ROUTES.SUBSCRIPTION
                              )
                            }
                          >
                            Subscription
                          </MoreMenuItem>
                        ) : (
                          <noscript />
                        )}
                        <MoreMenuItem
                          onClick={() =>
                            navigate(
                              "/" +
                                ACCOUNT_MASTER_ROUTE +
                                "/" +
                                ACCOUNT_ROUTES.LOGOUT
                            )
                          }
                        >
                          Logout
                        </MoreMenuItem>
                      </MoreMenuContent>
                    </More>
                  </Col>
                )}
              </Row>
            </Container>
          </StyledContainer>
        </NavbarContainer>

        {/* Mobile Menu */}
        {showMobileMenu ? (
          <MobileMenuContainer>
            {userAccount?.roles?.indexOf("CompanyAdmin") > -1 ? (
              <ul>
                {INSPECTOR_NAV_ITEMS.map((navItem, index) => {
                  if (!hasValidSubscription && navItem.needsSubscription) {
                    return (
                      <Subscribe
                        key={`nav-${index}`}
                        trigger={
                          <li key={`nav-${index}`} style={{ display: "flex" }}>
                            <StyledLink
                              to={navItem.path}
                              className={
                                pathname === "/" + navItem.path ? "active" : ""
                              }
                            >
                              {navItem.name}
                            </StyledLink>
                            <UpgradeLabel
                              className={
                                pathname === "/" + navItem.path ? "active" : ""
                              }
                            >
                              Upgrade
                            </UpgradeLabel>
                          </li>
                        }
                      />
                    );
                  }

                  return (
                    <li key={`nav-${index}`}>
                      <Link
                        to={navItem.path}
                        className={
                          pathname === "/" + navItem.path ? "active" : ""
                        }
                      >
                        {navItem.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <ul>
                {CUSTOMER_NAV_ITEMS.map((navItem, index) => {
                  return (
                    <li key={`nav-${index}`}>
                      <Link
                        to={navItem.path}
                        className={
                          pathname === "/" + navItem.path ? "active" : ""
                        }
                      >
                        {navItem.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}

            {!hasValidSubscription &&
            userAccount?.roles?.indexOf("CompanyAdmin") > -1 ? (
              <Subscribe
                trigger={
                  <SecondaryButton style={{ width: "100%" }}>
                    Upgrade
                  </SecondaryButton>
                }
              />
            ) : (
              <noscript />
            )}
          </MobileMenuContainer>
        ) : (
          <noscript />
        )}
      </Elements>
    );
  }

  return (
    <>
      <NavbarContainer className={hasShadow ? "scrolled" : null}>
        <Container fluid>
          <Row>
            <Col className="flex align-center">
              <Logo src={logoIcon} onClick={openDashboard} />
              <MobileLogo src={logoIconIcon} onClick={openDashboard} />

              <Hidden xs>
                {/* Nav Links */}
                <ul className="non-mobile">
                  {UNAUTH_NAV_ITEMS.map((navItem, index) => {
                    if (navItem.path) {
                      return (
                        <li key={index}>
                          <Link
                            to={navItem.path}
                            className={
                              pathname === "/" + navItem.path ? "active" : ""
                            }
                          >
                            {navItem.name}
                          </Link>
                        </li>
                      );
                    }

                    return (
                      <More
                        key={index}
                        trigger={
                          <li
                            key={index}
                            style={{
                              fontFamily: "HeadingFont",
                              fontWeight: 500,
                              fontSize: "16px",
                            }}
                          >
                            {navItem.name}

                            <img
                              src={caretDownDarkIcon}
                              alt="caret down"
                              width={12}
                              style={{ marginLeft: 8 }}
                            />
                          </li>
                        }
                      >
                        <MoreMenuContent style={{ padding: 16 }}>
                          {navItem.items.map((item, itemIndex) => (
                            <MoreMenuItem
                              style={{
                                width: "auto",
                                height: "auto",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                padding: "4px 16px",
                              }}
                              key={itemIndex}
                              onClick={() => navigate(item.path)}
                            >
                              <P style={{ color: "#143A5A" }}>
                                <strong>{item.name}</strong>
                              </P>
                              <P className="description">{item.description}</P>
                            </MoreMenuItem>
                          ))}
                        </MoreMenuContent>
                      </More>
                    );
                  })}

                  <li>
                    <LoginLink
                      to={ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.LOGIN}
                    >
                      Login
                    </LoginLink>
                  </li>
                </ul>

                {/* Profile photo */}
                <RoundedPrimaryButton
                  className="non-mobile"
                  style={{ width: 112 }}
                  onClick={() =>
                    navigate(
                      ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.REGISTER
                    )
                  }
                >
                  Join
                </RoundedPrimaryButton>
              </Hidden>

              <HamburgerMenu onClick={() => setShowMobileMenu((_) => !_)}>
                <img
                  src={showMobileMenu ? closeIcon : hamburgerIcon}
                  alt="menu"
                  width={20}
                />
              </HamburgerMenu>
            </Col>
          </Row>
        </Container>
      </NavbarContainer>

      {showMobileMenu ? (
        <MobileMenuContainer>
          <ul>
            {UNAUTH_NAV_ITEMS.map((navItem, index) => {
              if (navItem.path) {
                return (
                  <li key={index}>
                    <Link
                      to={navItem.path}
                      className={
                        pathname === "/" + navItem.path ? "active" : ""
                      }
                    >
                      {navItem.name}
                    </Link>
                  </li>
                );
              }

              return (
                <More
                  key={index}
                  trigger={
                    <li
                      key={index}
                      style={{
                        lineHeight: "48px",
                        fontSize: "24px",
                        fontWeight: 400,
                      }}
                    >
                      {navItem.name}

                      <img
                        src={caretDownDarkIcon}
                        alt="caret down"
                        width={12}
                        style={{ marginLeft: 8 }}
                      />
                    </li>
                  }
                  useInlineForMobile
                >
                  <MoreMenuContent
                    style={{ padding: 24, marginTop: -24, marginBottom: 16 }}
                  >
                    {navItem.items.map((item, itemIndex) => (
                      <MoreMenuItem
                        style={{
                          width: "auto",
                          height: "auto",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginTop: 8,
                          padding: "8px 16px",
                        }}
                        key={itemIndex}
                        onClick={() => navigate(item.path)}
                      >
                        <P style={{ color: "#143A5A" }}>
                          <strong>{item.name}</strong>
                        </P>
                        <P>{item.description}</P>
                      </MoreMenuItem>
                    ))}
                  </MoreMenuContent>
                </More>
              );
            })}

            <li>
              <LoginLink to={ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.LOGIN}>
                Login
              </LoginLink>
            </li>
          </ul>

          <RoundedPrimaryButton
            onClick={() =>
              navigate(ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.REGISTER)
            }
          >
            Join
          </RoundedPrimaryButton>
        </MobileMenuContainer>
      ) : (
        <noscript />
      )}
    </>
  );
}
