import { api } from "./api";

/**
 * @typedef {Object} CompanyUser
 * @property {number} id
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {'Registered' | 'Activated' | 'Deleted' | 'Invited' | 'Anonymous'} status
 * @property {string} lastLoginOn
 */

export const CompanyUsersApi = {
  /**
   *
   * @returns {Promise<CompanyUser[]>}
   */
  getEmployeesOfCurrentCompany: async () => api.get("/companies/current/users"),
  /**
   *
   * @param {Object} userInfo
   * @param {string} userInfo.firstName
   * @param {string} userInfo.lastName
   * @param {string} userInfo.email
   * @returns {Promise<void>}
   */
  inviteEmployeeToCompany: async (userInfo) =>
    api.post("/companies/current/users", userInfo),
  /**
   *
   * @param {string} userId
   * @returns {Promise<CompanyUser>}
   */
  getEmployeeById: async (userId) =>
    api.get(`/companies/current/users/${userId}`),
  /**
   * @param {string|number} userId
   * @param {Object} userInfo
   * @param {string} [userInfo.firstName]
   * @param {string} [userInfo.lastName]
   * @param {string} userInfo.email
   * @returns {Promise<void>}
   */
  updateCompanyEmployee: async (userId, userInfo) =>
    api.post(`/companies/current/users/${userId}`, userInfo),
  /**
   *
   * @param {string} userId
   * @returns {Promise<void>}
   */
  removeCompanyEmployee: async (userId) =>
    api.delete(`/companies/current/users/${userId}`),
};
