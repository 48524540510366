import { Accordion, Address, Loader } from "components";
import styled from "styled-components";
import circleUncheckedIcon from "assets/icons/circle-unchecked.svg";
import { PrimaryButton } from "ui-library";
import { InspectorsApi } from "services";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Form } from "react-final-form";
import { displayErrorMessage } from "services/api";
import { updateCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import Toast from "components/toast";

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
`;

/**
 *
 * @param {Object} props
 * @param {import("services").InspectorProfile} props.inspector
 */
export function InspectorAddressWidget({ inspector }) {
  const dispatch = useDispatch();

  if (inspector?.address) {
    return <noscript />;
  }

  return (
    <Form
      onSubmit={async (values) => {
        let updatedValues = values;

        try {
          if (values.addressDisplay) {
            // address has been filled in

            // Add point
            const geocoder = new window.google.maps.Geocoder();
            const geocodingResults = await geocoder.geocode({
              address: values.addressDisplay,
            });

            if (!geocodingResults.results?.length) {
              toast.error(
                <Toast title="Please check the business address and try again" />
              );
              return;
            }

            let addressComponents = {};
            let street = "";
            for (var addressComponent of geocodingResults.results[0]
              .address_components) {
              switch (addressComponent.types[0]) {
                case "postal_code": {
                  addressComponents.postcode = addressComponent.long_name;
                  break;
                }

                case "country": {
                  addressComponents.country = addressComponent.long_name;
                  addressComponents.region = addressComponent.short_name;
                  break;
                }

                case "administrative_area_level_1": {
                  addressComponents.state = addressComponent.short_name;
                  break;
                }

                case "locality": {
                  addressComponents.suburb = addressComponent.long_name;
                  break;
                }

                case "street_number": {
                  street += addressComponent.long_name;
                  break;
                }
                case "route": {
                  street += " ";
                  street += addressComponent.long_name;
                  addressComponents.street = street;
                  break;
                }

                default: {
                  break;
                }
              }
            }

            updatedValues = {
              ...updatedValues,
              address: {
                ...addressComponents,
                point: {
                  latitude: geocodingResults.results[0].geometry.location.lat(),
                  longitude:
                    geocodingResults.results[0].geometry.location.lng(),
                },
                formatted: geocodingResults.results[0].formatted_address,
              },
            };
          }

          await InspectorsApi.updateCurrentInspector(updatedValues);
          const updatedInspector =
            await InspectorsApi.getCurrentInspectorProfile();
          dispatch(updateCurrentInspector(updatedInspector));
          toast.success(<Toast title="Address added" />);
        } catch (e) {
          displayErrorMessage(e);
        }
      }}
      render={({ handleSubmit, submitting }) => (
        <StyledAccordion icon={circleUncheckedIcon} title="Address">
          <Address name="addressDisplay" label="Business address" />

          <PrimaryButton style={{ marginTop: 24 }} onClick={handleSubmit}>
            <Loader loading={submitting} color="#fff" />
            {submitting ? " Saving" : "Save"}
          </PrimaryButton>
        </StyledAccordion>
      )}
    />
  );
}
