import { FiftyFifty } from "components";
import purchaseImage from "assets/images/purchase.png";
import { H2, P, PrimaryButton } from "ui-library";
import { useNavigate } from "react-router-dom";
import { DocumentTitle } from "components/document-title";
import { USERS_MASTER_ROUTE, USER_ROUTES } from "routes";

export default function PurchaseConfirmation() {
  const navigate = useNavigate();

  return (
    <DocumentTitle title="Thank You">
      <FiftyFifty image={purchaseImage} centered expanded>
        <H2>Thank you</H2>
        <P style={{ fontSize: 20 }}>
          Your purchase was completed successfully. We have emailed you the
          receipt and you can download your report from your account.
        </P>

        <P style={{ marginTop: 16, fontSize: 16, fontStyle: "italic" }}>
          Please note that the report may take a few minutes for the report to
          appear in your dashboard.
        </P>

        <PrimaryButton
          style={{ marginTop: 32 }}
          onClick={() =>
            navigate(
              "/" +
                USERS_MASTER_ROUTE +
                "/" +
                USER_ROUTES.DASHBOARD +
                "?v=reports"
            )
          }
        >
          View my report
        </PrimaryButton>
      </FiftyFifty>
    </DocumentTitle>
  );
}
