import { useEffect, useState } from "react";
import {
  H2,
  PrimaryButton,
  Table,
  Thead,
  Trow,
  Tcell,
  P,
  TertiaryButton,
  H3,
} from "ui-library";
import plusIcon from "assets/icons/plus.svg";
import styled from "styled-components";
import moreIcon from "assets/icons/more.svg";
import { Container, Row, Col } from "react-grid-system";
import { InspectionReportsApi } from "services";
import { useDispatch, useSelector } from "react-redux";
import {
  removeReport,
  reportsSelector,
  setReports,
  inspectionTypesSelector,
} from "store/features/inspectors/inspectorsSlice";
import {
  Modal,
  More,
  Loader,
  MoreMenuContent,
  MoreMenuItem,
  StripeConnectBanner,
} from "components";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { companySelector } from "store/features/companies/companiesSlice";
import { DocumentTitle } from "components/document-title";
import { useAuth } from "common/authentication";
import {
  INSPECTORS_MASTER_ROUTE,
  INSPECTOR_ROUTES,
  REPORT_ROUTES,
} from "routes";

const PlusIcon = styled.img`
  margin-right: 16px;
`;

const MoreMenu = styled.div`
  width: 40px;
  height: 40px;
`;

const ReportNameCell = styled(Tcell)`
  color: #143a5a;
  font-weight: bold;
`;

const MoreActionsCell = styled(Tcell)`
  padding: 0;
`;

const StyledTable = styled(Table)`
  margin-top: 48px;
  margin-bottom: 88px;
`;

const EmptyStateContainer = styled.div`
  border-radius: 20px;
  background-color: #f5fafd;
  padding: 48px;
  margin-top: 16px;
  text-align: center;
`;

export default function InspectorReports() {
  const { user } = useAuth();
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const dispatch = useDispatch();
  const reports = useSelector(reportsSelector);
  const [reportToDelete, setReportToDelete] = useState(null);
  const [isFetchingReports, setIsFetchingReports] = useState(false);
  const navigate = useNavigate();
  const currentCompany = useSelector(companySelector);

  useEffect(() => {
    async function fetchReports() {
      setIsFetchingReports(true);

      const companyId = user.userRoles[0]?.companyId;

      if (companyId) {
        const response =
          await InspectionReportsApi.getInspectionReportsForCurrentInspector();
        dispatch(setReports(response));
      }

      setIsFetchingReports(false);
    }
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user]);

  if (currentCompany?.stripe?.status !== "Completed") {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <div
              className="flex justify-between align-center"
              style={{ marginTop: 76 }}
            >
              <H2>Reports</H2>

              <PrimaryButton
                style={{ fontSize: 14 }}
                onClick={() =>
                  navigate(
                    "/" +
                      INSPECTORS_MASTER_ROUTE +
                      "/" +
                      INSPECTOR_ROUTES.REPORTS +
                      "/" +
                      REPORT_ROUTES.ADD_REPORT
                  )
                }
              >
                <PlusIcon src={plusIcon} alt="add" width={14} />
                Upload a report
              </PrimaryButton>
              {/* <Modal
                title="Add a report"
                size="small"
                trigger={
                  <PrimaryButton style={{ fontSize: 14 }}>
                    <PlusIcon src={plusIcon} alt="add" width={14} />
                    Upload a report
                  </PrimaryButton>
                }
              >
                {(closeModal) => (
                  <>
                    <P>
                      {currentCompany?.stripe?.status === "Pending"
                        ? "You need to complete the setup of Stripe account before you can upload a report. Check your email account as you maybe asked to verify your email."
                        : "You will need to set up your Stripe account before you can upload your reports. This allows you to get paid for your report sales."}
                    </P>

                    <PrimaryButton
                      style={{ width: "100%", marginTop: 32 }}
                      onClick={closeModal}
                    >
                      Ok
                    </PrimaryButton>
                  </>
                )}
              </Modal> */}
            </div>

            <P style={{ marginTop: 16 }}>
              With a free account you can sell copies of your existing reports
              on Rate My Inspectors. We take a 30% commission for reports you
              sell through us. Sign up for a free Stripe account to get secure
              payments to your bank account.
            </P>
          </Col>

          <Col xs={12}>
            <StripeConnectBanner />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <DocumentTitle title="Reports">
      <Container>
        <Row>
          <Col xs={12}>
            <div
              className="flex justify-between align-center"
              style={{ marginTop: 76 }}
            >
              <H2>Reports</H2>

              <PrimaryButton
                style={{ fontSize: 14 }}
                onClick={() =>
                  navigate(
                    "/" +
                      INSPECTORS_MASTER_ROUTE +
                      "/" +
                      INSPECTOR_ROUTES.REPORTS +
                      "/" +
                      REPORT_ROUTES.ADD_REPORT
                  )
                }
              >
                <PlusIcon src={plusIcon} alt="add" width={14} />
                Upload a report
              </PrimaryButton>
            </div>
          </Col>

          <Col xs={12}>
            <StripeConnectBanner />
          </Col>

          {isFetchingReports ? (
            <Col xs={12}>
              <div style={{ textAlign: "center", marginTop: 32 }}>
                <Loader size="large" loading color="#EB9F22" />
              </div>
            </Col>
          ) : reports?.length === 0 ? (
            <Col xs={12}>
              <EmptyStateContainer style={{ marginTop: 24, marginBottom: 24 }}>
                <H3>Add your first report</H3>

                <PrimaryButton
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 16,
                    fontSize: 14,
                  }}
                  onClick={() =>
                    navigate(
                      "/" +
                        INSPECTORS_MASTER_ROUTE +
                        "/" +
                        INSPECTOR_ROUTES.REPORTS +
                        "/" +
                        REPORT_ROUTES.ADD_REPORT
                    )
                  }
                >
                  <PlusIcon src={plusIcon} alt="add" width={14} />
                  Upload a report
                </PrimaryButton>
              </EmptyStateContainer>
            </Col>
          ) : (
            <Col xs={12}>
              {/* Report  */}
              <StyledTable>
                <Thead>
                  <tr>
                    <th>Inspection type</th>
                    <th>Inspection address</th>
                    <th>Inspection date</th>
                    <th>Price</th>
                    <th></th>
                  </tr>
                </Thead>

                <tbody>
                  {reports.map((report, index) => {
                    const date = new Date(report.inspectionDate);

                    const filteredInspectionTypes = inspectionTypes
                      .filter((type) =>
                        report.inspectionTypeIds.includes(type.id)
                      )
                      .map((_) => _.name);

                    return (
                      <Trow key={`report-${index}`}>
                        <ReportNameCell>
                          {filteredInspectionTypes.length
                            ? filteredInspectionTypes.join(", ")
                            : report.inspectionTypeOther
                            ? report.inspectionTypeOther
                            : "Unknown"}
                        </ReportNameCell>
                        <Tcell>{report.address}</Tcell>
                        <Tcell>{date.toLocaleDateString("en-AU")}</Tcell>
                        <Tcell>${report.price}</Tcell>
                        <MoreActionsCell>
                          <More
                            trigger={
                              <MoreMenu className="flex align-center justify-center">
                                <img src={moreIcon} alt="more" height={16} />
                              </MoreMenu>
                            }
                          >
                            <MoreMenuContent>
                              <MoreMenuItem
                                onClick={() =>
                                  navigate(
                                    "/" +
                                      INSPECTORS_MASTER_ROUTE +
                                      "/" +
                                      INSPECTOR_ROUTES.REPORTS +
                                      "/" +
                                      REPORT_ROUTES.EDIT_REPORT +
                                      "/" +
                                      report.id
                                  )
                                }
                              >
                                Edit
                              </MoreMenuItem>
                              <MoreMenuItem
                                onClick={() =>
                                  setReportToDelete({
                                    report,
                                    inspectionTypes: filteredInspectionTypes,
                                  })
                                }
                              >
                                Delete
                              </MoreMenuItem>
                            </MoreMenuContent>
                          </More>
                        </MoreActionsCell>
                      </Trow>
                    );
                  })}
                </tbody>
              </StyledTable>

              {/* Delete report modal */}
              <Modal
                size="small"
                title="Delete report?"
                isOpen={reportToDelete !== null}
                onAfterClose={() => setReportToDelete(null)}
              >
                {(closeModal) => (
                  <>
                    <P>
                      Are you sure you want to delete the{" "}
                      <strong>
                        {reportToDelete?.inspectionTypes.join(", ")}
                      </strong>{" "}
                      report for the property at{" "}
                      <strong>{reportToDelete?.report.address}</strong>?
                    </P>

                    <div className="flex" style={{ marginTop: 24 }}>
                      <Form
                        onSubmit={async (values) => {
                          try {
                            await InspectionReportsApi.deleteInspectionReportWithId(
                              reportToDelete.report.id
                            );
                            dispatch(
                              removeReport({
                                id: reportToDelete.report.id,
                              })
                            );
                            closeModal();
                          } catch (e) {}
                        }}
                        render={({ handleSubmit, submitting }) => (
                          <PrimaryButton
                            onClick={submitting ? null : handleSubmit}
                            style={{
                              border: "2px solid #EA4335",
                              backgroundColor: "none",
                              color: "#EA4335",
                            }}
                          >
                            {submitting ? <Loader loading /> : "Delete"}
                          </PrimaryButton>
                        )}
                      />
                      <TertiaryButton
                        style={{ marginLeft: 16 }}
                        onClick={closeModal}
                      >
                        Cancel
                      </TertiaryButton>
                    </div>
                  </>
                )}
              </Modal>
            </Col>
          )}
        </Row>
      </Container>
    </DocumentTitle>
  );
}
