export default function RMIIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#EB9F22"
      />
      <path
        d="M47 47.3H47.3V47V29.5839V29.4112L47.1507 29.3245L35.8904 22.7805L35.8391 22.7507L35.7802 22.7427L35.4874 22.7028L35.4476 22.6973L35.4077 22.7026L35.2564 22.7225L35.1969 22.7304L35.145 22.7606L23.8493 29.3245L23.7 29.4112V29.5839V47V47.3H24H47ZM38.9552 42.3798L39.436 42.6262L39.391 42.0879L39.0505 38.0112L41.5722 34.9582L41.8793 34.5864L41.4101 34.4752L37.6771 33.5906L36.1104 30.8106L36.1106 30.8105L36.1053 30.8018L35.6999 30.1364L35.4338 29.6996L35.1824 30.1451L33.2329 33.6005L29.4999 34.4852L29.0298 34.5966L29.338 34.9685L31.8621 38.0141L31.5062 42.0432L31.4608 42.5566L31.9296 42.3425L35.4469 40.736L38.2277 42.007L38.9552 42.3798ZM33.4133 37.6279L33.4241 37.5045L33.3447 37.4093L31.782 35.538L34.079 34.9856L34.2061 34.955L34.2702 34.8411L35.444 32.7565L36.6205 34.8414L36.6847 34.9551L36.8117 34.9856L39.1086 35.538L37.5459 37.4093L37.4665 37.5045L37.4773 37.6279L37.6929 40.0766L35.5711 39.1117L35.447 39.0553L35.3229 39.1117L33.1977 40.0768L33.4133 37.6279ZM45.7831 45.7552H25.2073V30.3087L35.4952 24.3284L45.7831 30.3087V45.7552Z"
        fill="#EB9F22"
        stroke="#EB9F22"
        strokeWidth="0.6"
      />
    </svg>
  );
}
