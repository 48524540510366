import { useEffect } from "react";
import InspectionTypeBannerImage from "assets/images/inspection-type-banner.png";
import Banner from "components/public-pages/banner/banner";
import InspectionTypesList from "../components/inspection-type-list";
import { DocumentTitle } from "components/document-title";
import { useDispatch } from "react-redux";
import { setInspectionTypes } from "store/features/inspectors/inspectorsSlice";
import { InspectionTypesApi } from "services";
import styled from "styled-components";
import droneImage from "assets/images/drone.png";
import electricalImage from "assets/images/electrical.png";
import engineersImage from "assets/images/engineers.png";
import timberPestImage from "assets/images/timber-pest.png";
import { Container, Row } from "react-grid-system";
import { A, P1 } from "ui-library";

const SmallInspectionTypeImage = styled.img`
  box-shadow: 0px 11px 48px rgba(51, 51, 51, 0.06);
`;

const Spacer = styled.div`
  height: 60px;
  @media (min-width: 1020px) {
    height: 80px;
  }
`;

const ProfilesWrapper = styled.div`
  position: relative;
  display: none;

  > div {
    position: absolute;
    top: -50px;
    right: 24px;
    display: flex;
    gap: 16px;
  }

  @media (min-width: 1020px) {
    display: block;
  }
`;

export default function InpsectionTypesPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchInspectionTypes() {
      try {
        const types = await InspectionTypesApi.getInspectionTypes();
        dispatch(setInspectionTypes(types));
      } catch (e) {}
    }
    fetchInspectionTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DocumentTitle title="Inspection Types">
        <Banner
          title="Inspection types"
          excerpt={
            <P1>
              All the inspection types that Rate My Inspectors offer. Have a
              read to help identify what inspections you need. <br />
              If you need any assistance then contact us at{" "}
              <A href="mailto:info@ratemyinspectors.com">
                info@ratemyinspectors.com
              </A>
            </P1>
          }
          image={InspectionTypeBannerImage}
        />
      </DocumentTitle>
      <ProfilesWrapper>
        <div>
          <SmallInspectionTypeImage
            src={engineersImage}
            alt="engineers"
            width={196}
          />
          <SmallInspectionTypeImage
            src={electricalImage}
            alt="electrical"
            width={199}
          />
          <SmallInspectionTypeImage
            src={timberPestImage}
            alt="timber pest"
            width={222}
          />
          <SmallInspectionTypeImage src={droneImage} alt="drone" width={161} />
        </div>
      </ProfilesWrapper>
      <Spacer />

      <Container>
        <Row>
          <InspectionTypesList />
        </Row>
      </Container>
      <Spacer />
    </>
  );
}
