import { Accordion, Loader, MultiSelect } from "components";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import circleUncheckedIcon from "assets/icons/circle-unchecked.svg";
import { PrimaryButton } from "ui-library";
import { requiredValidator } from "components/form/validators";
import {
  inspectionTypesSelector,
  updateCurrentInspector,
} from "store/features/inspectors/inspectorsSlice";
import { InspectorsApi } from "services";
import { toast } from "react-toastify";
import { displayErrorMessage } from "services/api";
import Toast from "components/toast";

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
`;

/**
 *
 * @param {Object} props
 * @param {import("services").InspectorProfile} props.inspector
 */
export function InspectionTypesWidget({ inspector }) {
  const dispatch = useDispatch();
  const inspectionTypes = useSelector(inspectionTypesSelector);

  if (inspector?.inspectionTypeIds?.length) {
    return <noscript />;
  }

  return (
    <Form
      onSubmit={async (values) => {
        try {
          await InspectorsApi.updateCurrentInspector(values);
          const updatedInspector =
            await InspectorsApi.getCurrentInspectorProfile();
          dispatch(updateCurrentInspector(updatedInspector));
          toast.success(<Toast title="Inspection types added" />);
        } catch (e) {
          displayErrorMessage(e);
        }
      }}
      render={({ handleSubmit, submitting }) => (
        <StyledAccordion icon={circleUncheckedIcon} title="Inspection types">
          <MultiSelect
            name="inspectionTypeIds"
            label="Inspection types"
            description="These are the inspection types you offer."
            options={inspectionTypes.map((inspectionType) => ({
              label: inspectionType.name,
              value: inspectionType.id,
            }))}
            validators={requiredValidator}
          />

          <PrimaryButton style={{ marginTop: 24 }} onClick={handleSubmit}>
            <Loader loading={submitting} color="#fff" />
            {submitting ? " Saving" : "Save"}
          </PrimaryButton>
        </StyledAccordion>
      )}
    />
  );
}
