import { DocumentTitle } from "components/document-title";
import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { H2, P, UL } from "ui-library";

const StyledP = styled(P)`
  margin-top: 8px;

  strong {
    font-size: 20px;
  }
`;

export default function TermsPage() {
  return (
    <DocumentTitle title="Terms & Conditions">
      <Container>
        <Row>
          <Col xs={12}>
            <H2 style={{ marginTop: 32 }}>Terms and Conditions</H2>

            <StyledP>Last modified: June 5th, 2022</StyledP>

            <StyledP>Inspector or Multi-inspector Terms and Conditions</StyledP>

            <StyledP>
              Rate my inspectors, a website portal operated by inndox pty ltd
            </StyledP>

            <StyledP>
              Rate My Inspectors provides a portal ratemyinspectors.com for
              visitors ("End Users"), and other related websites, social media
              web pages, and smartphone applications operated by Rate My
              inspectors (with ratemyinspectors.com and ratemyinspectors.com.au,
              each related website, and each smartphone application to be known
              as the "Site"), may provide feedback or commentary on individual
              inspectors and inspection companies listed on the Site
              ("Inspectors"), or respond to such feedback or commentary.
            </StyledP>

            <StyledP>
              End Users and Inspectors may also purchase goods and services that
              Rate My Inspectors offer through the Site from time to time
              ("Products"). In these terms and conditions, "you" means a user of
              the Site, being an End User, or an Inspector (as the case may be).
            </StyledP>

            <StyledP>
              Rate My Inspectors allow you to use the Site and the Services, and
              purchase Products through the Site, in accordance with these terms
              and conditions ("this Agreement"). This Agreement forms a binding
              legal agreement between you and Rate My Inspectors. As a user of
              the Site, you will at all times be bound by and must abide by,
              this Agreement, If you are using the Site on behalf of an
              Inspection Company, then you agree to be bound by, and abide by,
              this Agreement on behalf of the Inspection Company (and you
              warrant that you have the capacity to agree to do so on behalf of
              the Inspection Company).
            </StyledP>

            <StyledP>
              Without limiting the way in which you may become bound by this
              Agreement, you will be deemed to have accepted and will be bound
              by the terms and conditions of this Agreement by signing a
              document agreeing to be bound by this Agreement, by checking the
              "I accept the terms and conditions and privacy policy of Rate My
              Inspectors" checkbox and clicking the "Sign Up" button on your
              computer screen to electronically indicate your acceptance of this
              Agreement, or by proceeding with the purchase of a Product or any
              use of the Services.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>THE SERVICES</strong>
            </StyledP>

            <StyledP>
              Subject to the terms and conditions of this Agreement, Rate My
              Inspectors will provide you with the following goods and services
              ("Services"):
            </StyledP>
            <UL>
              <li>
                the ability to create an Account on the Site and use
                functionality only available to holders of an Account;
              </li>
              <li>
                the ability for End Users to search and browse Inspectors listed
                on the Site and view information contained on the Site about
                each Inspector ("Inspector Profiles");
              </li>
              <li>
                the ability for End Users to rate Inspectors and provide
                comments on their performance, which will appear in the
                Inspector Profile for the Inspector;
              </li>
              <li>
                the ability for Inspectors to claim their Inspector Profile on
                the Site, and update and manage their Inspector Profile; and
              </li>
              <li>
                the ability to purchase Products through the Site as they are
                offered from time to time by Rate My Inspectors.
              </li>
            </UL>

            <StyledP>
              Subject to the terms and conditions of this Agreement, the
              provision of the Services and the Products which you purchase
              constitute Rate My Inspectors only obligation to you.
            </StyledP>

            <StyledP>
              You agree that Rate My Inspectors may modify the Services at any
              time, and discontinue the Services (or part thereof) at any time.
              Rate My Inspectors may also restrict your use of some or all
              Services, suspend the delivery of the Services at any time and
              subsequently reinstate them at its discretion.
            </StyledP>

            <StyledP>
              Rate My Inspectors does not guarantee the accuracy or reliability
              of any information contained on the Site about an Inspector
              (including information submitted by third parties in the form of
              ratings, comments, posts and reviews). It is your responsibility
              to conduct your own research and make your own inquiries before
              deciding to engage an Inspector.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>CREATING AN ACCOUNT WITH RATE MY INSPECTORS</strong>
            </StyledP>

            <StyledP>
              Rate My Inspectors may require you to create an online account
              with Rate My Inspectors ("Account") in order to use some or all of
              the Services. Rate My Inspectors may restrict the usage of the
              Services (or part thereof) for persons who do not have an Account.
              Rate My Inspectors may, from time to time, amend or place
              restrictions on the requirements needed to create an Account.
            </StyledP>

            <StyledP>
              If you create an Account with Rate My Inspectors then you agree:
            </StyledP>
            <UL>
              <li>
                if requested by Rate My Inspectors, to use your email address as
                your username;
              </li>
              <li>
                that the Account will be created using Rate My Inspectors online
                sign up process, or any other method specified by Rate My
                Inspectors from time to time;
              </li>
              <li>
                to keep confidential and secure any password used to access the
                Account;
              </li>
              <li>
                that you warrant that all information provided by you to Rate My
                Inspectors in the setup of the Account or subsequent editing is
                true and correct in every detail; and
              </li>
              <li>
                that you will only use the Account for the purposes of using the
                Services and/or purchasing Products, and for no other purpose.
              </li>
            </UL>

            <StyledP>
              You acknowledge and agree that Rate My Inspectors may disable or
              delete your Account at any time at its sole discretion, and for
              any reason whatsoever. If your Account is deleted or disabled then
              you acknowledge and agree that you will only be able to use the
              parts of the Site and the same Services as provided by Rate My
              Inspectors to members of the public who do not have an Account.
            </StyledP>

            <StyledP>
              You may delete or disable your Account at any time via the
              functionality contained in the Site.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>INSPECTORS</strong>
            </StyledP>

            <StyledP>
              Rate My Inspectors will list Inspectors on the Site from time to
              time. Where practicable, it will obtain the identity of each
              Inspector and information about them from the Inspector or from
              publicly available sources.
            </StyledP>

            <StyledP>
              Rate My Inspectors will detemine which Inspectors it lists on the
              Site and may remove a listing of an Inspector at any time. No
              Inspector has any right to be listed on the Site or have an
              Inspector Profile on the Site.
            </StyledP>

            <StyledP>
              If you are an Inspector and claim your Inspector Profile on the
              Site, you agree to grant Rate My Inspectors the license set out
              below. The scope of the license extends to all data submitted by
              you (including, but not limited to, all reports, images, text,
              contact details, inspection services, service areas, sample
              reports, inspection agreements, license details or other works
              associated with an inspector or inspection company) in respect of
              all properties which you are inspecting or have inspected, and you
              warrant that you are authorised and/or have the necessary rights
              to grant such a license.
            </StyledP>

            <StyledP>
              An Inspector may manage and update their Inspector Profile only
              if:
            </StyledP>
            <UL>
              <li>
                they have registered an Account with Rate My Inspectors; and
              </li>
              <li>
                they have claimed their Profile with Rate My Inspectors using
                the relevant functionality on the Site, including identifying
                themselves to Rate My Inspectors (to Rate My Inspectors
                satisfaction) as the individual agent described in the relevant
                Inspector Profile.
              </li>
            </UL>

            <StyledP>
              An Inspector may only claim their own Inspector Profile and must
              not claim the Inspector Profile of another Inspector.
            </StyledP>

            <StyledP>
              An Inspector must not comment on the Inspector Profile of another
              Inspector or in any public forums on the Site about other
              Inspectors.
            </StyledP>

            <StyledP>
              Rate My Inspectors will dtermine its sole discretion what
              functionality it provides to Inspectors to allow them to manage
              and update their Profile. Such functionality may however include,
              without limitation, the ability for Inspectors to:
            </StyledP>
            <UL>
              <li>
                upload an alternative photo, contact details, and Inspection
                Company details;
              </li>
              <li>add links to their social media accounts;</li>
              <li>add or modify a summary of their background;</li>
              <li>
                verify their inspection reports or report their inspection
                results;
              </li>
              <li>
                respond to comments and testimonials from End Users on the
                Inspector's Profile (noting, however, that only one response
                will be allowed in relation to each testimonial).
              </li>
            </UL>

            <StyledP>
              Rate My Inspectors may modify any part of the content of an
              Inspector Profile at any time at its sole discretion, with or
              without notice to that individual Inspector. Rate My Inspectors
              may also in its sole discretion, with or without notice to that
              individual Inspector, remove their Inspector Profile from the
              Site.
            </StyledP>

            <StyledP>
              Inspectors will not be able to modify comments and/or testimonials
              from End Users on their Inspector profile.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>END USERS</strong>
            </StyledP>

            <StyledP>
              Rate My Inspectors will determine the functionality that it offers
              End Users on the Site from time to time. This functionality may
              include, without limitation:
            </StyledP>

            <StyledP>
              the ability to view Inspector Profiles and search or browse for
              Inspectors using different search criteria;
            </StyledP>
            <ul>
              <li>
                the ability to view User Content and all other content on the
                Site;
              </li>
              <li>
                the ability to provide comments on Inspector Profiles ("User
                Reviews");
              </li>
              <li>the ability to comment in any public forums on the Site;</li>
              <li>
                the ability to provide ratings of Inspectors using any rating
                criteria specified by Rate My Inspectors from time to time; and
              </li>
              <li>
                the ability to view a log or history of interactions with the
                Site.
              </li>
            </ul>

            <StyledP>
              If you are both an Inspector and a recipient of Inspection
              services of another Inspector, then you are not permitted to write
              a review, be it positive or negative for any Inspector who
              actively Inspects within the same market.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>SUPPLY OF PRODUCTS</strong>
            </StyledP>

            <StyledP>
              Rate My Inspectors may determine from time to time, to supply
              Products through the Site for purchase by Inspectors or End Users.
            </StyledP>

            <StyledP>
              If Products are supplied through the Site, then Rate My Inspectors
              may impose additional terms and conditions which apply to the
              purchase and supply of the relevant Products, by setting out the
              additional terms and conditions below in an addendum to this
              Agreement ("Product Addendum"). Any Product Addendum will form
              part of this Agreement and, unless otherwise stated, will override
              any inconsistent term in this document, to the extent of the
              inconsistency only.
            </StyledP>

            <StyledP>
              By ordering and purchasing any Product from the Site, you agree:
            </StyledP>
            <UL>
              <li>
                that you are bound by and will comply with the Product Addendum
                applicable to the Product;
              </li>
              <li>to pay the applicable fee for such Product ("Fee"); and</li>
              <li>
                to be bound to a minimum term to use or purchase that Product
                ("Term") (if any).
              </li>
            </UL>

            <StyledP>
              Unless otherwise stated, you acknowledge and agree that each Term
              will automatically renew for successive Terms of the same duration
              unless you notify Rate My Inspectors, in writing and at least 7
              days prior to the expiry of the then-current Term, that you do not
              wish to renew your use of the Product, or unless your use of the
              Product is otherwise terminated in accordance with this Agreement.
            </StyledP>

            <StyledP>
              Unless otherwise stated Rate My Inspectors will provide you with
              invoices in respect of the Fee on a monthly basis and you agree to
              pay the Fee by the date indicated on Rate My Inspectors invoice.
              Rate My Inspectors may require you to provide it with an
              authorisation to direct debit the Fee from your credit card or
              nominated bank account. Once authorisation is provided the Fee
              will be automatically debited by Rate My Inspectors.
            </StyledP>

            <StyledP>
              Unless otherwise stated, you may terminate any order or agreement
              for Rate My Inspectors to supply you with Products at any time
              with no less than 30 days written notice to Rate My Inspectors.
            </StyledP>

            <StyledP>
              Rate My Inspectors may suspend or terminate any order or agreement
              for Rate My Inspectors to supply you with Products:
            </StyledP>

            <UL>
              <li>
                immediately if you are in breach of this Agreement and, in Rate
                My Inspectors's sole opinion, the breach is not capable of
                remedy or is a material breach;
              </li>
              <li>
                immediately if you are in breach of this Agreement and fail to
                remedy that breach within 7 days of receiving written notice
                from Rate My Inspectors requiring you to remedy the breach; or
              </li>
              <li>without cause, by giving 14 days' notice to you.</li>
            </UL>

            <StyledP>
              Unless otherwise stated, upon the termination of any order or
              agreement for Rate My Inspectors to supply you with Products:
            </StyledP>

            <StyledP>
              you must pay any unpaid amount of the Fee to Rate My Inspectors
              which would be owing over the remainder of the Term had such
              termination not occurred and you agree that, if an authorization
              has been given, the Fee may automatically be charged to your
              credit card or direct debited from your nominated bank account; or
            </StyledP>

            <StyledP>
              if the order or agreement is terminated, then you must pay the Fee
              incurred up to the date upon which termination becomes effective
              only. Rate My Inspectors will refund you any unused portion of the
              Fee that you have prepaid, and which relates to any period after
              the effective date of termination.
            </StyledP>

            <StyledP>
              Termination of our supply of a Product to you will not of itself
              unless stated by Rate My Inspectors to the contrary, terminate
              your ability to use the Site or the Services. Rate My Inspectors
              may, however, separately exercise any of its rights under this
              Agreement, including terminating the Services of your Account.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>PROHIBITED USES</strong>
            </StyledP>

            <StyledP>
              Content and information on the Site, including, but not limited
              to, messages, data, information, text, reports, videos, music,
              sound, photos, graphics, maps, icons, software, code or other
              material, as well as the infrastructure used to provide such
              content and information, is owned by Rate My Inspectors or its
              third-party licensors. You agree not to modify, copy, distribute,
              transmit, display, perform, reproduce, publish, license, create
              derivative works from, transfer, or sell or re-sell any
              information, software, code, products, or services obtained from
              or through the Site. Additionally, you agree not to:
            </StyledP>
            <UL>
              <li>
                use the Site or its contents for any commercial purpose, other
                than in your capacity as an Inspector (if applicable);
              </li>
              <li>
                use another person's name, account, identity or password without
                Rate My Inspectors’ permission, or use the Site while
                impersonating another person;
              </li>
              <li>
                access, monitor or copy any content or information of the Site
                using any robot, spider, scraper or other automated means or any
                manual process for any purpose without Rate My Inspectors's
                express written permission;
              </li>
              <li>
                violate the restrictions in any robot exclusion headers on the
                Site or bypass or circumvent other measures employed to prevent
                or limit access to the Site;
              </li>
              <li>
                take any action that imposes, or may impose, in Rate My
                Inspectors discretion, an unreasonable or disproportionately
                large load on its server infrastructure;
              </li>
              <li>
                deep-link to any portion of the Site for any purpose apart from
                where expressly permitted by this Agreement; or
              </li>
            </UL>

            <StyledP>
              you cannot link directly to your Inspector Profile or any other
              web pages on the Site, directly from Google Adwords or another
              digital advertising platform, unless otherwise approved by Rate My
              Inspectors by way of notice in writing to you.
            </StyledP>

            <StyledP>
              attempt to modify, translate, adapt, edit, decompile, disassemble,
              or reverse engineer any software programs used by Rate My
              Inspectors in connection with the Site or the Services (except to
              the extent specifically permitted by law).
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>PROVIDING CONTENT ON THE SITE</strong>
            </StyledP>

            <StyledP>
              You acknowledge that by submitting content to the Site, you
              expressly grant an irrevocable, perpetual, exclusive,
              royalty-free, sub-licensable, assignable license to Rate My
              Inspectors and its affiliates to use, reproduce, modify, adapt,
              publish, translate, create derivative works from, distribute,
              perform and display any content which you submit to the Site,
              including, without limitation, postings on the Site, commentary in
              forums or on Inspector Profiles or any suggestions or ideas, or
              any other work, item or thing you upload to the Site or provide to
              Rate My Inspectors ("User Content"). For the purposes of this
              Agreement, the term "Intellectual Property Rights" shall refer to
              all intellectual property rights of any kind whatsoever throughout
              the world, including all present, future, registered and
              unregistered rights which subsist in copyright, trademarks,
              patents, designs, and circuit layouts. You agree, at Rate My
              Inspectors request, to promptly execute a written assignment to
              Rate My Inspectors of any such Intellectual Property Rights, and
              to assist in every proper way and to execute those documents and
              take such acts as are reasonably requested by Rate My Inspectors
              to obtain, sustain and from time to time enforce such Intellectual
              Property Rights in any jurisdiction worldwide.
            </StyledP>

            <StyledP>
              To the extent that you have any moral rights (as that term is
              defined in the Copyright Act 1968 in any User Content you provide
              to Rate My Inspectors, then you waive all such moral rights, and
              to the extent that such waiver is not possible, then you consent
              to Rate My Inspectors making all uses, edits and modifications of
              the User Content in its sole discretion, including, without
              limitation, as further described in this Agreement.
            </StyledP>

            <StyledP>
              You warrant that any User Content that you post or upload to the
              Site will not infringe the Intellectual Property Rights or other
              rights of any person.
            </StyledP>

            <StyledP>
              You warrant that if any User Content submitted by you to the Site
              reproduces the likeness or image of any person, that the person
              whose image or likeness is reproduced in that User Content, has
              provided their express consent to you, for their image or likeness
              to be published on the Site.
            </StyledP>

            <StyledP>You agree that:</StyledP>

            <StyledP>the User Content is not confidential;</StyledP>

            <StyledP>
              you are solely responsible for the User Content you post on the
              Site; Rate My Inspectors may reproduce any User Content on the
              Site and will determine in its discretion how it presents your
              User Content on the Site;
            </StyledP>

            <StyledP>
              Rate My Inspectors may make any modifications to the User Content
              in its sole and absolute discretion, before presenting that User
              Content on the Site; and
            </StyledP>

            <StyledP>
              Rate My Inspectors may determine whether or not to attribute the
              User Content to you or remain silent on any attribution.
            </StyledP>

            <StyledP>
              You agree that Rate My Inspectors is under no obligation to edit,
              monitor, or control the User Content that is published to the Site
              by an End User or Inspector, but that Rate My Inspectors reserves
              the right to do so.
            </StyledP>

            <StyledP>
              You agree that you will not post, upload to, transmit, distribute,
              store, create or otherwise publish on the Site, any of the
              following ("Infringing Content"):
            </StyledP>

            <StyledP>
              User Content that infringes, or may infringe, the Intellectual
              Property Rights or other rights of any person;
            </StyledP>

            <StyledP>
              User Content that impersonates any person or entity or otherwise
              misrepresents your relationship with Rate My Inspectors or any
              other person;
            </StyledP>

            <StyledP>
              User Content that is false, unlawful, misleading, libelous,
              defamatory, slanderous, obscene, pornographic, indecent, lewd,
              abusive, harassing or advocates harassment of another person,
              threatening, invasive of privacy, abusive, inflammatory,
              fraudulent or otherwise objectionable;
            </StyledP>

            <StyledP>
              User Content that can reasonably be considered to be offensive,
              such as content that promotes racism, bigotry, hatred or physical
              harm of any kind against any group or individual, or which incites
              such behaviour or action from others;
            </StyledP>

            <StyledP>
              User Content that would constitute, encourage or promote, or
              provide instructions for the conduct of an illegal act or
              omission, any criminal activity, or violate the rights of any
              person or party in any country of the world;
            </StyledP>

            <StyledP>
              User Content that has been solicited by any incentive or payment
              originating from a real estate agent or a representative of their
              agency; unsolicited promotions or SPAM;
            </StyledP>

            <StyledP>
              User Content that contains the private information of any person;
              User Content that publishes an image or likeness of a person who
              has not consented to their likeness or image being published on
              the Site;
            </StyledP>

            <StyledP>
              User Content that is unrelated to the page of the Site in which
              such User Content is posted; and/or
            </StyledP>

            <StyledP>
              User Content that contains viruses, malware, or any other
              malicious software or data.
            </StyledP>

            <StyledP>
              To the maximum extent permitted by law, Rate My Inspectors will
              have no responsibility or liability for any User Content or
              Infringing Content posted, stored, or uploaded on the Site, or for
              any loss or damage suffered by you or any other person as a result
              of the Site storing, holding, or making available, any User
              Content or Infringing Content to End Users, Inspectors or other
              members of the public.
            </StyledP>

            <StyledP>
              Rate My Inspectors is under no obligation to review any User
              Content to determine its accuracy, truthfulness, or whether it is
              defamatory, slanderous or contains falsehoods.
            </StyledP>

            <StyledP>
              If you have any complaints or objections to material or content
              including User Content posted on the Site, or if you consider any
              User Content to be Infringing Content, you may contact us and
              notify us of the relevant content by following our takedown
              procedure ("Takedown Procedure") and submitting a takedown request
              ("Takedown Request").
            </StyledP>

            <StyledP>
              Where you submit a Takedown Request, you agree to follow any
              procedures that Rate My Inspectors specifies from time to time,
              and you agree to provide Rate My Inspectors with all information
              that it requests in considering your Takedown Request, including
              but not limited to information verifying your identity and the
              basis upon which you consider the relevant User Content to be
              Infringing Content.
            </StyledP>

            <StyledP>
              You acknowledge that notwithstanding your Takedown Request, Rate
              My Inspectors is under no obligation to remove any Infringing
              Content other than where stated in this Agreement or where
              required by law.
            </StyledP>

            <StyledP>
              If Rate My Inspectors does remove allegedly Infringing Content,
              then you acknowledge that such removal is done on a without
              prejudice basis, and upon such removal, you agree to release Rate
              My Inspectors from any action, claim, proceedings, damages or
              other obligation which you may make, institute or claim against
              Rate My Inspectors in relation to the allegedly Infringing
              Content.
            </StyledP>

            <StyledP>
              You indemnify and keep indemnified, Rate My Inspectors, its
              agents, employees, and officers against all loss, cost, expense or
              damage which Rate My Inspectors, its agents, employees or officers
              suffer or incur, as a direct or indirect result of:
            </StyledP>

            <StyledP>
              your breach of this Agreement or violation of any law or
              Intellectual Property Rights or other rights of a third party;
            </StyledP>

            <StyledP>
              you posting or uploading any Infringing Content to the Site; or
            </StyledP>

            <StyledP>
              any legal proceedings or any claim made against Rate My Inspectors
              by a third party, which arises directly or indirectly from any
              Infringing or inaccurate Content posted or uploaded by you to the
              Site.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>LIABILITY AND DISCLAIMER</strong>
            </StyledP>

            <StyledP>
              You acknowledge that Rate My Inspectors has made no warranties
              that the Services or Products will be error-free.
            </StyledP>

            <StyledP>
              You agree that Rate My Inspectors will not be liable or
              responsible for any failure in, or delay to, the provision of the
              Services or Products or in Rate My Inspectors complying with its
              obligations under this Agreement where such failure or delay has
              arisen as a direct or indirect result of:
            </StyledP>

            <StyledP>
              fire, earthquake, pandemic, fire, storm, flood, hurricane,
              inclement weather or other act of God, war, terrorism, explosion,
              sabotage, industrial accident or an industrial strike;
            </StyledP>

            <StyledP>
              denial of service attacks, telecommunications failure, hardware
              failure or the failure of software provided by a third party to
              function in accordance with its specifications;
            </StyledP>

            <StyledP>
              a significant demand being placed on Rate My Inspectors' services
              which is above the usual level of demand and which results in a
              failure of Rate My Inspectors' software and hardware to function
              correctly;
            </StyledP>

            <StyledP>
              the failure of any third party (including without limitation, any
              bank or other financial organisation) to fulfil any obligations to
              Rate My Inspectors; or
            </StyledP>

            <StyledP>
              any other circumstances or event similar to the above which is
              beyond the reasonable control of Rate My Inspectors.
            </StyledP>

            <StyledP>
              To the extent permitted by law, any conditions, warranties,
              guarantees, rights, remedies, liabilities and other terms implied
              or conferred by statute, custom or the general law that impose any
              liability or obligation on us are excluded under this Agreement.
              However, if a supply under this Agreement is a supply of goods or
              services to a consumer within the meaning of the Australian
              Consumer Law (contained in Schedule 2 to the Competition and
              Consumer Act 2010 (Cth), as amended or replaced from time to
              time), nothing contained in this Agreement excludes, restricts or
              modifies the application of any provision, the exercise of any
              right or remedy, or the imposition of any liability under the
              Australian Consumer Law, provided that, to the extent that the
              Australian Consumer Law permits us to limit our liability, then
              Rate My Inspectors liability is limited to:
            </StyledP>

            <StyledP>
              in the case of services, the cost of supplying the services again
              or payment of the cost of having the services supplied again; and
            </StyledP>

            <StyledP>
              in the case of software or other goods, the cost of replacing the
              goods, supplying equivalent goods or having the goods repaired, or
              payment of the cost of replacing the goods, supplying equivalent
              goods or having the goods repaired,
            </StyledP>

            <StyledP>
              and except in the case of a major failure (as that term is defined
              in the Australian Consumer Law), Rate My Inspectors can elect as
              between the remedies set out in paragraphs above.
            </StyledP>

            <StyledP>
              Rate My Inspectors liability to you arising directly or indirectly
              under or in connection with this Agreement or otherwise in
              connection with or in any way relating to the Site or any of the
              Services or Products, and whether arising under any indemnity,
              statute, in tort (for negligence or otherwise) or on any other
              basis in law or equity, is limited as follows:
            </StyledP>

            <StyledP>
              Rate My Inspectors excludes all liability for loss of revenue,
              loss of goodwill, loss of customers, loss of capital, downtime
              costs, loss of profit, loss of or damage to reputation, loss under
              or in relation to any other contract, loss of data, loss of use of
              data, loss of anticipated savings or benefits, or any indirect,
              consequential or special loss, damage, cost or expense or other
              claims for consequential compensation, incurred by or awarded
              against you under or in any way connected with this Agreement or
              otherwise in connection with or in any way relating to the Site or
              any of the Products or Services; and
            </StyledP>

            <StyledP>
              Rate My Inspector's total aggregate liability under or in any way
              connected with this Agreement or otherwise in connection with or
              in any way relating to the Site or any of the Products or Services
              is otherwise limited to the greater of:
            </StyledP>

            <StyledP>
              AUD$25; and the total amounts you have paid to us for the Products
              or Services that are the subject of the relevant claim.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>PRIVACY POLICY</strong>
            </StyledP>

            <StyledP>
              You acknowledge that, in order to provide the Services and
              Products, Rate My Inspectors (and its associated or related
              entities) may collect and use the personal information you provide
              to Rate My Inspectors in accordance with the Rate My Inspectors
              Privacy Policy. By using the Site or the Services, or by
              purchasing any Products, you agree to the terms of the Rate My
              Inspectors Privacy Policy. To view the Rate My Inspectors Privacy
              Policy, please visit the footer of the site.
            </StyledP>

            <StyledP style={{ marginTop: 24 }}>
              <strong>GENERAL CLAUSES</strong>
            </StyledP>

            <StyledP>
              Rate My Inspectors reserves the right to revise and amend this
              Agreement in its discretion, as follows:
            </StyledP>

            <StyledP>
              if Rate My Inspectors considers that the change is likely to
              benefit you or have a neutral or minor detrimental impact on you,
              it may make any changes immediately without notifying you except
              by publishing the amended Agreement (as applicable) on the Site;
            </StyledP>

            <StyledP>
              if Rate My Inspectors considers that the change is likely to have
              a significant detrimental impact on you, it will make the change
              after it has notified you of the change (solely by using the email
              address you have provided) and will display a notice on the Site
              describing the change.
            </StyledP>

            <StyledP>
              Your continued use of the Site after any amendment will mean that
              you agree to that amendment. You must stop using the Site if you
              do not agree to an amendment.
            </StyledP>

            <StyledP>
              Any notice given under this Agreement must be in writing and must
              be signed by the party giving the notice. Unless a later time is
              specified in a notice, the notice takes effect from the time it is
              received. A notice is taken to be received:
            </StyledP>

            <StyledP>
              in the case of a notice delivered by hand, when so delivered;
            </StyledP>

            <StyledP>
              in the case of a notice sent by pre-paid express post, on the
              third clear Business Day after the date of posting;
            </StyledP>

            <StyledP>
              in the case of a notice sent by email, at the time that the email
              is sent, unless the sender receives a notification that the email
              was delayed or not received; or
            </StyledP>

            <StyledP>
              in the case of a notice sent via functionality contained in the
              Site, at the time the notice was sent,
            </StyledP>

            <StyledP>
              but if the effect of paragraphs (a) - (d) above is that a notice
              is taken to have been received before 9:00am or after 5:00pm on a
              day which is not a Saturday, Sunday or public holiday in Brisbane,
              Australia ("Business Day"), or on a day which is not a Business
              Day, then the notice is taken to have been received at 9:00am of
              the next Business Day.
            </StyledP>

            <StyledP>
              Neither party is the partner, agent, employee or representative of
              any other party and neither party has the power to incur any
              obligations on behalf of any other party.
            </StyledP>

            <StyledP>
              There are no other representations, promises, warranties,
              covenants or undertakings between the parties and this Agreement
              supersedes all previous agreements in respect of its subject
              matter and embodies the entire agreement between the parties.
            </StyledP>

            <StyledP>
              A provision of or a right created under this Agreement may not be
              waived except in writing signed by the party or parties to be
              bound by the waiver. No single or partial exercise by any party of
              any right, power or remedy under this Agreement will preclude any
              other or further exercise of that or any other right, power or
              remedy. The rights, powers or remedies provided in this Agreement
              are cumulative with and not exclusive of any rights, powers or
              remedies provided independently of this Agreement.
            </StyledP>

            <StyledP>
              If any provision of this Agreement is judged invalid or
              unenforceable for any reason whatsoever by a court of competent
              jurisdiction, such invalidity or unenforceability (unless deletion
              of such provision would materially adversely affect one of the
              parties) will not affect the operation or interpretation of any
              other provision of this Agreement to the extent that the invalid
              or unenforceable provision will be treated as severed from this
              Agreement.
            </StyledP>

            <StyledP>
              You must not assign or novate any of your rights or obligations
              under this Agreement. You agree that Rate My Inspectors may
              however assign or novate this Agreement at any time, with or
              without notice to you.
            </StyledP>

            <StyledP>
              The parties acknowledge and agree that no rule of construction
              applies to the disadvantage of a party because that party was
              responsible for the preparation of this Agreement or part of it.
            </StyledP>

            <StyledP style={{ marginBottom: 56 }}>
              This Agreement will be construed in accordance with and will be
              governed by the laws in force in the State of Queensland,
              Australia. Each of the parties irrevocably submits to and accepts
              the exclusive jurisdiction of any of the Courts of the State of
              Queensland, Australia or the Commonwealth of Australia and any
              courts of appeal from these courts.
            </StyledP>
          </Col>
        </Row>
      </Container>
    </DocumentTitle>
  );
}
