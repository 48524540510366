import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { H5, P, PrimaryButton, TertiaryButton } from "ui-library";
import { Modal } from "./modal";
import Youtube from "react-youtube";
import VimeoPlayer from "@u-wave/react-vimeo";
import { More, MoreMenuItem, MoreMenuContent } from "./more";
import moreIcon from "assets/icons/more.svg";
import { Input, Loader } from ".";
import { Form } from "react-final-form";
import { InspectorVideosApi } from "services";
import { useDispatch } from "react-redux";
import {
  deleteVideo,
  updateVideo,
} from "store/features/inspectors/inspectorsSlice";
import { requiredValidator } from "./form/validators";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const VideoThumbnailContainer = styled.div`
  margin-right: 40px;
  margin-bottom: 40px;
  max-width: 228px;

  @media only screen and (max-width: 576px) {
    margin-right: unset;
    max-width: 100%;
    width: 100%;
  }
`;

const ThumbnailPlaceholder = styled.div`
  width: 228px;
  height: 144px;
  border-radius: 8px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 576px) {
    width: 100%;
    height: 210px;
  }
`;

const ThumbnailContainer = styled.div`
  width: 228px;
  height: 144px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    width: 100%;
    height: auto;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: auto;
`;

const VideoInfo = styled.div`
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
`;

const VideoCopy = styled.div``;

const MoreOptions = styled.div`
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;

  &:hover {
    background-color: #f2f2f2;
  }
`;

const Vimeo = styled(VimeoPlayer)`
  iframe {
    max-width: 100%;
  }
`;

/**
 *
 * @param {Object} props
 * @param {import('services').InspectorVideo} props.video
 */
export function VideoThumbnail(props) {
  const { video, readOnly } = props;
  const [thumbnail, setThumbnail] = useState();
  const [wantsToDeleteVideo, setWantsToDeleteVideo] = useState(false);
  const [wantsToEditVideo, setWantsToEditVideo] = useState(false);
  const dispatch = useDispatch();

  const date = new Date(video.createdOn);
  const formattedDate =
    MONTHS[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();

  const setVideoThumbnail = async () => {
    let image = "";
    if (video.type === "YouTube") {
      // easy case
      image = "https://img.youtube.com/vi/" + video.videoId + "/hqdefault.jpg";
    } else {
      try {
        const response = await axios.get(
          `https://vimeo.com/api/oembed.json?url=http%3A//vimeo.com/${video.videoId}`
        );
        image = response.data.thumbnail_url;
      } catch (e) {}
    }

    setThumbnail(image);
  };

  useEffect(() => {
    setVideoThumbnail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VideoThumbnailContainer>
      {thumbnail ? (
        <Modal
          title={video.title}
          trigger={
            <ThumbnailContainer>
              <Thumbnail src={thumbnail} />
            </ThumbnailContainer>
          }
        >
          {video.type === "YouTube" ? (
            <Youtube videoId={video.videoId} opts={{ width: "100%" }} />
          ) : (
            <Vimeo video={video.videoId} />
          )}
        </Modal>
      ) : (
        <ThumbnailPlaceholder>
          <Loader loading color="#EB9F22" size="medium" />
        </ThumbnailPlaceholder>
      )}

      <VideoInfo>
        <VideoCopy>
          <H5>{video.title}</H5>
          <P style={{ fontSize: 16 }}>
            <em>Added on {formattedDate}</em>
          </P>
        </VideoCopy>

        {!readOnly ? (
          <More
            trigger={
              <MoreOptions>
                <img src={moreIcon} alt="more" height={16} />
              </MoreOptions>
            }
          >
            <MoreMenuContent>
              <MoreMenuItem onClick={() => setWantsToEditVideo(true)}>
                Edit
              </MoreMenuItem>
              <MoreMenuItem onClick={() => setWantsToDeleteVideo(true)}>
                Delete
              </MoreMenuItem>
            </MoreMenuContent>
          </More>
        ) : (
          <noscript />
        )}
      </VideoInfo>

      <Modal size="small" title="Edit video" isOpen={wantsToEditVideo}>
        <Form
          initialValues={{ title: video.title, link: video.link }}
          onSubmit={async (values) => {
            try {
              const response = await InspectorVideosApi.updateVideoWithId(
                video.id,
                values
              );
              dispatch(updateVideo(response));
              setWantsToEditVideo(false);
            } catch (e) {}
          }}
          render={({ handleSubmit, submitting }) => (
            <>
              <Input
                name="title"
                label="Video title"
                validate={requiredValidator}
              />

              <Input
                name="link"
                label="Video URL"
                type="url"
                validate={requiredValidator}
              />

              <PrimaryButton
                style={{ width: "100%", marginTop: 48 }}
                onClick={submitting ? null : handleSubmit}
              >
                {submitting ? <Loader loading /> : "Save video"}
              </PrimaryButton>
            </>
          )}
        />
      </Modal>

      <Modal
        size="small"
        title="Delete video?"
        isOpen={wantsToDeleteVideo}
        onAfterClose={() => setWantsToDeleteVideo(false)}
      >
        {(closeModal) => (
          <>
            <P style={{ marginBottom: 16, marginTop: 16 }}>
              Are you sure you want to delete the video titled{" "}
              <strong>{video.title}</strong>
            </P>
            <Form
              onSubmit={async () => {
                try {
                  await InspectorVideosApi.deleteVideoWithId(video.id);
                  dispatch(deleteVideo(video.id));
                  closeModal();
                } catch (e) {}
              }}
              render={({ handleSubmit, submitting }) => (
                <div className="flex justify-end" style={{ marginTop: 32 }}>
                  <TertiaryButton
                    style={{ marginRight: 16, marginLeft: "auto" }}
                    onClick={closeModal}
                  >
                    Cancel
                  </TertiaryButton>

                  <PrimaryButton
                    onClick={submitting ? null : handleSubmit}
                    style={{
                      border: "2px solid #EA4335",
                      backgroundColor: "none",
                      color: "#EA4335",
                    }}
                  >
                    {submitting ? <Loader loading /> : "Delete video"}
                  </PrimaryButton>
                </div>
              )}
            />
          </>
        )}
      </Modal>
    </VideoThumbnailContainer>
  );
}
