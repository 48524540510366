import { useEffect, useState } from "react";
import { InspectorReviewsApi } from "services";
import { P } from "ui-library";
import { Loader } from "../loader";
import { Review } from "../review";

export function InspectorReviews({ inspectorId, inspectorCode }) {
  const [reviews, setReviews] = useState(null);

  const fetchInspectorReviews = async () => {
    try {
      const response = await InspectorReviewsApi.getReviewsForInspectorWithId(
        inspectorId
      );
      setReviews(response.data);
    } catch (e) {}
  };

  useEffect(() => {
    fetchInspectorReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!reviews) {
    return (
      <div style={{ textAlign: "center" }}>
        <Loader size="medium" loading color="#EB9F22" />
      </div>
    );
  }

  if (reviews.length === 0) {
    return (
      <div>
        <P style={{ marginLeft: 20 }}>
          <em>There are no reviews for this inspector yet.</em>
        </P>
      </div>
    );
  }

  return reviews.map((review, index) => (
    <Review inspectorCode={inspectorCode} review={review} key={index} />
  ));
}
