import { Col, Hidden, Row } from "react-grid-system";
import styled from "styled-components";
import { H4, P, PrimaryButton, Rating, TertiaryAlertButton } from "ui-library";
import flagIcon from "assets/icons/flag.svg";
import { Lightbox, Loader, Modal, TextArea } from ".";
import { InspectorReviewsApi } from "services";
import { Form } from "react-final-form";
import TimeAgo from "react-timeago";
import facebookIcon from "assets/icons/review-facebook.svg";
import twitterIcon from "assets/icons/review-twitter.svg";
import emailIcon from "assets/icons/review-email.svg";
import linkIcon from "assets/icons/review-link.svg";
import linkedinIcon from "assets/icons/linkedin-circle.svg";
import { displayErrorMessage } from "services/api";
import { toast } from "react-toastify";
import Toast from "./toast";
import { SEARCH_INSPECTORS_MASTER_ROUTE, SEARCH_MASTER_ROUTE } from "routes";
import { requiredValidator } from "./form/validators";
import googleReviewLogo from "assets/images/google-review.svg";

const ReviewContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ReviewContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ReviewContent = styled.div`
  flex-grow: 1;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
  }
`;

const Initials = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #4f6473;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 32px;

  ${H4} {
    color: #ffffff;
  }

  @media only screen and (max-width: 576px) {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
`;

const ReviewerImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #4f6473;
  margin-right: 32px;

  @media only screen and (max-width: 576px) {
    width: 40px;
    height: 40px;
    margin-right: 8px;
  }
`;

const Flag = styled.img`
  width: 24px;
  height: 24px;
`;

const ShareContainer = styled.div`
  margin-left: 48px;

  @media only screen and (max-width: 576px) {
    margin-left: 0;
    margin-top: 16px;
  }
`;

const ShareOptionContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 8px;
  cursor: pointer;

  > img {
    max-width: 20px;
    max-height: 20px;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const GoogleReviewImage = styled.img`
  margin-left: 24px;

  @media only screen and (max-width: 576px) {
    margin-left: 48px;
    max-width: 90px;
  }
`;

/**
 * @typedef {Object} ReviewProps
 * @property {import("services").InspectorReview} review
 * @property {string} inspectorCode
 * @property {boolean} [canFlag]
 * @property {boolean} [canShare]
 */

/**
 *
 * @param {ReviewProps} props
 */
export function Review({
  review,
  inspectorCode,
  canFlag = false,
  canShare = true,
}) {
  const reviewShareText = review.review;
  const reviewShareLink =
    process.env.REACT_APP_DOMAIN +
    "/" +
    SEARCH_MASTER_ROUTE +
    "/" +
    SEARCH_INSPECTORS_MASTER_ROUTE +
    "/" +
    inspectorCode +
    `?reviewCode=${review.code}`;

  const shareVia = (socialNetwork) => {
    switch (socialNetwork) {
      case "facebook": {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            reviewShareLink
          )}&t=${encodeURIComponent(reviewShareText)}`,
          "_blank"
        );
        break;
      }

      case "twitter": {
        window.open(
          `http://www.twitter.com/intent/tweet?url=${encodeURIComponent(
            reviewShareLink
          )}&text=${encodeURIComponent(reviewShareText.substring(0, 60))}`,
          "_blank"
        );
        break;
      }

      case "linkedin": {
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            reviewShareLink
          )}`,
          "_blank"
        );
        break;
      }

      case "email": {
        window.open(
          `mailto:?subject=${encodeURIComponent(
            "Check out my review on Rate My Inspectors"
          )}&body=${
            encodeURIComponent(reviewShareText.substring(0, 60)) +
            " ... " +
            encodeURIComponent(reviewShareLink)
          }`
        );
        break;
      }

      case "link": {
        navigator.clipboard.writeText(reviewShareLink);
        toast.success(<Toast title="Review share link copied" />);
        break;
      }

      default:
        break;
    }
  };

  return (
    <Row style={{ marginBottom: 72 }}>
      <Col>
        <ReviewContainer>
          {/* Image or initials */}
          {review.reviewer.profilePhotoPath ? (
            <ReviewerImage
              src={review.reviewer.profilePhotoPath + "?w=70&h=70"}
              alt="profile photo"
            />
          ) : (
            <Initials>
              <H4>
                {review.reviewer.fullName.split(" ")[0][0] +
                  review.reviewer.fullName.split(" ")[1][0]}
              </H4>
            </Initials>
          )}

          <ReviewContent>
            <ReviewContentHeader>
              <div className="flex column" style={{ marginRight: "auto" }}>
                <P style={{ color: "#143A5A" }}>
                  <strong>{review.reviewer.fullName}</strong>
                </P>
                <P>
                  <em>
                    <TimeAgo date={review.createdOn} />
                  </em>
                </P>
              </div>

              {canFlag && (
                <Modal
                  size="small"
                  title="Flag review"
                  trigger={
                    <TertiaryAlertButton className="flex align-center">
                      <Flag src={flagIcon} alt="flag" />

                      <Hidden xs sm>
                        <span style={{ marginLeft: 12 }}>
                          Flag as inappropriate
                        </span>
                      </Hidden>
                    </TertiaryAlertButton>
                  }
                >
                  {(closeModal) => (
                    <>
                      <P>
                        Please provide a reason for flagging this review. Our
                        team will review it as soon as possible.
                      </P>

                      <Form
                        onSubmit={async (values) => {
                          try {
                            await InspectorReviewsApi.flagReviewForReason(
                              review,
                              values.reason
                            );
                            toast.success(
                              <Toast title="You have flagged this review. This process may take up to 24 hours. In the meantime, the review will not be visible on your profile." />
                            );
                            closeModal();
                          } catch (e) {
                            displayErrorMessage(e);
                          }
                        }}
                        render={({ handleSubmit, submitting }) => (
                          <>
                            <TextArea
                              name="reason"
                              validate={requiredValidator}
                            />

                            <PrimaryButton
                              style={{ marginTop: 40, width: "100%" }}
                              onClick={submitting ? null : handleSubmit}
                            >
                              {submitting ? <Loader loading /> : "Submit"}
                            </PrimaryButton>
                          </>
                        )}
                      />
                    </>
                  )}
                </Modal>
              )}
            </ReviewContentHeader>

            <P style={{ marginBottom: 16 }}>{review.review}</P>

            {review.imagePaths ? (
              <Lightbox src={review.imagePaths[0]} width={115} height={115} />
            ) : (
              <noscript />
            )}

            <Footer>
              <Rating rating={review.stars} editable={false} />

              {review.source === "Google" ? (
                <GoogleReviewImage src={googleReviewLogo} />
              ) : canShare ? (
                <ShareContainer className="flex align-center">
                  <P style={{ marginRight: 20 }}>Share</P>

                  <ShareOptionContainer onClick={() => shareVia("facebook")}>
                    <img src={facebookIcon} alt="Share via Facebook" />
                  </ShareOptionContainer>
                  <ShareOptionContainer onClick={() => shareVia("twitter")}>
                    <img src={twitterIcon} alt="Share via Twitter" />
                  </ShareOptionContainer>
                  <ShareOptionContainer onClick={() => shareVia("linkedin")}>
                    <img src={linkedinIcon} alt="Share via LinkedIn" />
                  </ShareOptionContainer>
                  <ShareOptionContainer onClick={() => shareVia("email")}>
                    <img src={emailIcon} alt="Share via Email" />
                  </ShareOptionContainer>
                  <ShareOptionContainer onClick={() => shareVia("link")}>
                    <img src={linkIcon} alt="Copy Link" />
                  </ShareOptionContainer>
                </ShareContainer>
              ) : (
                <noscript />
              )}
            </Footer>
          </ReviewContent>
        </ReviewContainer>
      </Col>
    </Row>
  );
}
