import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import bodyFontWoff2 from "assets/fonts/BodyFont.woff2";
import bodyFontWoff from "assets/fonts/BodyFont.woff";
import { InspectionReportsApi } from "services";
import { FiftyFifty } from "./fifty-fifty";
import purchaseImage from "assets/images/purchase.png";
import { Loader } from "./loader";

// // Make sure to call `loadStripe` outside of a component’s render to avoid
// // recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
//   stripeAccount: process.env.REACT_APP_STRIPE_PLATFORM_ACCOUNT,
// });

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export function SubscriptionStripeWrapper({ paymentIntent, children }) {
  const options = {
    clientSecret: paymentIntent,
    fonts: [
      {
        family: "BodyFont",
        src: `url(${bodyFontWoff2})`,
        weight: 400,
      },
      {
        family: "BodyFont",
        src: `url(${bodyFontWoff})`,
        weight: 400,
      },
    ],
    appearance: {
      theme: "stripe",
      fontFamily: "BodyFont",
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
}

export function StripeWrapper({ reportId, price, children }) {
  const [paymentIntent, setPaymentIntent] = useState(null);

  const options = {
    clientSecret: paymentIntent?.paymentIntentSecret,
    fonts: [
      {
        family: "BodyFont",
        src: `url(${bodyFontWoff2})`,
        weight: 400,
      },
      {
        family: "BodyFont",
        src: `url(${bodyFontWoff})`,
        weight: 400,
      },
    ],
    appearance: {
      theme: "stripe",
      fontFamily: "BodyFont",
    },
  };

  useEffect(() => {
    async function fetchPaymentIntent() {
      try {
        const response = await InspectionReportsApi.createReportPurchaseIntent(
          reportId,
          price
        );
        setPaymentIntent(response);
      } catch (e) {}
    }
    fetchPaymentIntent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!paymentIntent) {
    return (
      <FiftyFifty image={purchaseImage} expanded>
        <div style={{ textAlign: "center", marginTop: 48, width: "100%" }}>
          <Loader color="#feba00" size="large" loading />
        </div>
      </FiftyFifty>
    );
  }

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
    stripeAccount: paymentIntent.stripeAccountId,
  });

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
}
