import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  query: null,
  inspectors: null,
  reports: null,
  selected: {
    // for next navigation
    nextUrl: null,
    // for report purchase
    reportId: null,

    // for multiple quotes
    inspectorIds: null,

    // for showing on search results page
    inspectionTypeIds: null,
  },
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setSearchedInspectors: (state, action) => {
      state.inspectors = action.payload;
    },
    setSearchedReports: (state, action) => {
      state.reports = action.payload;
    },

    // Selected
    setInspectionTypeIds: (state, action) => {
      state.selected.inspectionTypeIds = action.payload;
    },
    setSelectedInspectorIds: (state, action) => {
      state.selected.inspectorIds = action.payload;
    },
    setNextUrl: (state, action) => {
      state.selected.nextUrl = action.payload;
    },
    selectSelectedReportId: (state, action) => {
      state.selected.reportId = action.payload;
    },
    clearSearch: () => initialState,
  },
});

export const {
  setInspectionTypeIds,
  setQuery,
  setSearchedInspectors,
  setSelectedInspectorIds,
  setSearchedReports,
  setNextUrl,
  clearSearch,
  selectSelectedReportId,
} = searchSlice.actions;

export const selectQuery = (state) => state.search.query;

/**
 *
 * @param {*} state
 * @returns
 */
export const selectInspectionTypes = (state) =>
  state.search.selected.inspectionTypeIds;

/**
 *
 * @param {*} state
 * @returns {import("services").PagedInspectorSummary}
 */
export const selectInspectors = (state) => state.search.inspectors;

/**
 *
 * @param {*} state
 * @returns {import("services").ReportSearchSummary}
 */
export const selectSearchReports = (state) => state.search.reports;

/**
 *
 * @param {*} state
 * @returns {number[]}
 */
export const selectInspectorIds = (state) => state.search.selected.inspectorIds;

/**
 *
 * @param {*} state
 * @returns {number}
 */
export const selectReportId = (state) => state.search.selected.reportId;

/**
 *
 * @param {*} state
 * @returns {string}
 */
export const selectNextUrl = (state) => state.search.selected.nextUrl;

export default searchSlice.reducer;

export { initialState as searchSliceInitialState };
