export default function FirstIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#EB9F22"
      />
      <path
        d="M35.0001 18.85C26.0924 18.85 18.8501 26.0923 18.8501 35C18.8501 43.9076 26.0924 51.15 35.0001 51.15C43.9078 51.15 51.1501 43.9076 51.1501 35C51.1501 26.0923 43.9078 18.85 35.0001 18.85ZM35.0001 47.9547C27.8601 47.9547 22.0454 42.14 22.0454 35C22.0454 27.86 27.8601 22.0453 35.0001 22.0453C42.1401 22.0453 47.9548 27.86 47.9548 35C47.9548 42.14 42.1401 47.9547 35.0001 47.9547Z"
        fill="#EB9F22"
      />
      <path
        d="M35.7148 33.7076H34.3203C33.2326 33.7076 32.3826 32.7553 32.4848 31.6676C32.5871 30.7153 33.4703 30.0021 34.4226 30.0021H36.0548C36.6671 30.0021 37.1771 30.4444 37.3126 31.0221C37.3471 31.1921 37.4826 31.2944 37.6526 31.2944H39.5226C39.7271 31.2944 39.8971 31.1244 39.8971 30.9198C39.6926 29.0498 38.1971 27.5876 36.2924 27.4522V25.6167C36.2924 25.4122 36.1224 25.2422 35.9179 25.2422H34.0824C33.8779 25.2422 33.7079 25.4122 33.7079 25.6167V27.4867C31.3956 27.7922 29.6626 29.8667 29.9002 32.3144C30.138 34.6267 32.178 36.2921 34.4902 36.2921H35.6802C36.768 36.2921 37.618 37.2443 37.5157 38.3321C37.4134 39.2843 36.5302 39.9975 35.578 39.9975H33.9457C33.3334 39.9975 32.8234 39.5552 32.688 38.9775C32.6534 38.8075 32.518 38.7052 32.348 38.7052H30.478C30.2734 38.7052 30.0702 38.8752 30.1034 39.0798C30.3079 40.9498 31.8034 42.4121 33.7081 42.5474V44.3829C33.7081 44.5874 33.8781 44.7574 34.0826 44.7574H35.9181C36.1226 44.7574 36.2926 44.5874 36.2926 44.3829V42.4784C38.2649 42.1384 39.8973 40.6084 40.1003 38.5684C40.3726 35.952 38.298 33.7074 35.715 33.7074L35.7148 33.7076Z"
        fill="#EB9F22"
      />
    </svg>
  );
}
