import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { selectAccount, updateUser } from "store/features/user/userSlice";
import { Input, Loader, Modal, PhoneNumber, Tabs } from "components";
import { H2, Page, P, TertiaryButton, PrimaryButton } from "ui-library";
import { CompaniesApi, UsersApi } from "services";
import {
  companySelector,
  updateCurrentCompany,
} from "store/features/companies/companiesSlice";
import { toast } from "react-toastify";
import parsePhoneNumber from "libphonenumber-js";
import { DocumentTitle } from "components/document-title";
import {
  emailValidator,
  minLengthValidator,
  requiredValidator,
} from "components/form/validators";
import Toast from "components/toast";
import { displayErrorMessage } from "services/api";
import { useState } from "react";
import errorIcon from "assets/icons/error.svg";

const Label = styled(P)`
  color: #143a5a;
  font-weight: bold;
  line-height: 48px;
`;

const Value = styled(P)`
  color: #143a5a;
  line-height: 48px;
`;

const InlineErrorContainer = styled.div`
  min-height: 64px;
  margin-bottom: 16px;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  background: #fff;
  color: #757575;
  border-radius: 10px;
  position: relative;
`;

const InlineError = styled.div`
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
`;

const ErrorIcon = styled.div`
  margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
  animation-fill-mode: both;
  animation-duration: 0.3s;
  animation-name: Toastify__zoomIn;
`;

export default function InspectorManageAccountPage() {
  const user = useSelector(selectAccount);
  const company = useSelector(companySelector);
  const dispatch = useDispatch();
  const [passwordChangeError, setPasswordChangeError] = useState(null);

  const getUserContent = () => {
    return (
      <Row>
        {/* Name */}
        <Col xs={12} sm={4} md={2}>
          <Label>Name</Label>
        </Col>
        <Col xs={12} sm={8} md={10} className="flex align-center">
          <Value>{user.fullName}</Value>
          <Modal
            title="Update name"
            trigger={
              <TertiaryButton style={{ marginLeft: 16 }}>Update</TertiaryButton>
            }
          >
            {(closeModal) => (
              <Form
                initialValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                }}
                onSubmit={async (values) => {
                  try {
                    const response =
                      await UsersApi.updateLoginDetailsForCurrentUser({
                        ...values,
                        nameSpecified: true,
                      });
                    dispatch(updateUser(response));
                    closeModal();
                    toast.success(<Toast title="Name updated" />);
                  } catch (e) {
                    displayErrorMessage(e);
                  }
                }}
                render={({ handleSubmit, submitting }) => (
                  <Container style={{ marginLeft: -16, marginRight: -16 }}>
                    <Row>
                      <Col xs={12}>
                        <Input
                          label="First name"
                          name="firstName"
                          validate={requiredValidator}
                        />
                      </Col>
                      <Col xs={12}>
                        <Input
                          label="Last name"
                          name="lastName"
                          validate={requiredValidator}
                        />
                      </Col>
                      <Col xs={12}>
                        <PrimaryButton
                          style={{
                            marginTop: 32,
                            width: 160,
                            marginLeft: "auto",
                          }}
                          onClick={submitting ? null : handleSubmit}
                        >
                          {submitting ? <Loader loading /> : "Save"}
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </Container>
                )}
              />
            )}
          </Modal>
        </Col>

        {/* Email */}
        <Col xs={12} sm={4} md={2}>
          <Label>Email</Label>
        </Col>
        <Col xs={12} sm={8} md={10} className="flex align-center">
          <Value>{user.email}</Value>
          <Modal
            title="Update email address"
            trigger={
              <TertiaryButton style={{ marginLeft: 16 }}>Update</TertiaryButton>
            }
          >
            {(closeModal) => (
              <Form
                initialValues={{
                  email: user.email,
                }}
                onSubmit={async (values) => {
                  try {
                    const response =
                      await UsersApi.updateLoginDetailsForCurrentUser({
                        email: values.email,
                        emailSpecified: true,
                      });

                    dispatch(updateUser(response));
                    closeModal();
                    toast.success(<Toast title="Email address updated" />);
                  } catch (e) {
                    displayErrorMessage(e);
                  }
                }}
                render={({ handleSubmit, submitting }) => (
                  <Container style={{ marginLeft: -16, marginRight: -16 }}>
                    <Row>
                      <Col xs={12}>
                        <Input
                          label="Email address"
                          name="email"
                          validate={emailValidator}
                        />
                      </Col>
                      <Col xs={12}>
                        <PrimaryButton
                          style={{
                            marginTop: 40,
                            width: 160,
                            marginLeft: "auto",
                          }}
                          onClick={submitting ? null : handleSubmit}
                        >
                          {submitting ? <Loader loading /> : "Save"}
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </Container>
                )}
              />
            )}
          </Modal>
        </Col>

        {/* Phone */}
        <Col xs={12} sm={4} md={2}>
          <Label>Phone</Label>
        </Col>
        <Col xs={12} sm={8} md={10} className="flex align-center">
          <Value>{phoneNumber?.formatInternational()}</Value>
          <Modal
            title={user.phone ? "Update phone" : "Add phone"}
            trigger={
              user.phone ? (
                <TertiaryButton style={{ marginLeft: 16 }}>
                  Update
                </TertiaryButton>
              ) : (
                <TertiaryButton>Add phone number</TertiaryButton>
              )
            }
          >
            {(closeModal) => (
              <Form
                initialValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                  phone: user.phone,
                  email: user.email,
                }}
                onSubmit={async (values) => {
                  try {
                    const response = await UsersApi.updateCurrentUser({
                      ...values,
                    });
                    dispatch(updateUser(response));
                    closeModal();
                    toast.success(<Toast title="Phone number updated" />);
                  } catch (e) {
                    displayErrorMessage(e);
                  }
                }}
                render={({ handleSubmit, submitting }) => (
                  <Container style={{ marginLeft: -16, marginRight: -16 }}>
                    <Row>
                      <Col xs={12}>
                        <PhoneNumber
                          label="Phone"
                          name="phone"
                          placeholder="Your mobile number"
                        />
                      </Col>
                      <Col xs={12}>
                        <PrimaryButton
                          style={{
                            marginTop: 32,
                            width: 160,
                            marginLeft: "auto",
                          }}
                          onClick={submitting ? null : handleSubmit}
                        >
                          {submitting ? <Loader loading /> : "Save"}
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </Container>
                )}
              />
            )}
          </Modal>
        </Col>

        {/* Password */}
        <Col xs={12} sm={4} md={2}>
          <Label>Password</Label>
        </Col>
        <Col xs={12} sm={8} md={10} className="flex align-center">
          <Value>*********</Value>
          <Modal
            title="Update password"
            trigger={
              <TertiaryButton style={{ marginLeft: 16 }}>Update</TertiaryButton>
            }
          >
            {(closeModal) => (
              <Form
                onSubmit={async (values) => {
                  try {
                    setPasswordChangeError(null);
                    await UsersApi.updateLoginDetailsForCurrentUser({
                      ...values,
                      passwordSpecified: true,
                    });
                    closeModal();
                    toast.success(<Toast title="Password updated" />);
                  } catch (error) {
                    if (error?.response?.data?.globalErrors?.length) {
                      setPasswordChangeError([
                        error?.response?.data?.globalErrors[0]?.message,
                      ]);
                    } else if (error?.response?.data?.fieldErrors) {
                      const keys = Object.keys(
                        error?.response?.data?.fieldErrors
                      );
                      setPasswordChangeError(
                        keys.map(
                          (key) => error?.response?.data?.fieldErrors[key][0]
                        )
                      );
                    }
                  }
                }}
                render={({ handleSubmit, submitting }) => (
                  <Container style={{ marginLeft: -16, marginRight: -16 }}>
                    {passwordChangeError?.length && (
                      <Row>
                        {passwordChangeError.map((error, index) => (
                          <Col key={index} xs={12}>
                            <InlineErrorContainer>
                              <InlineError>
                                <ErrorIcon>
                                  <img src={errorIcon} alt="error" />
                                </ErrorIcon>

                                <div style={{ flex: "1 1" }}>
                                  <Toast title={passwordChangeError} />
                                </div>
                              </InlineError>
                            </InlineErrorContainer>
                          </Col>
                        ))}
                      </Row>
                    )}

                    <Row>
                      <Col xs={12}>
                        <Input
                          label="Current password"
                          name="currentPassword"
                          validate={minLengthValidator(6)}
                          type="password"
                        />
                      </Col>
                      <Col xs={12}>
                        <Input
                          label="New password"
                          name="password"
                          validate={minLengthValidator(6)}
                          type="password"
                        />
                      </Col>
                      <Col xs={12}>
                        <PrimaryButton
                          style={{
                            marginTop: 40,
                            width: 160,
                            marginLeft: "auto",
                          }}
                          onClick={submitting ? null : handleSubmit}
                        >
                          {submitting ? <Loader loading /> : "Save"}
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </Container>
                )}
              />
            )}
          </Modal>
        </Col>
      </Row>
    );
  };

  const phoneNumber = user.phone ? parsePhoneNumber(user.phone, "AU") : null;
  const isUserAnInspector = user.roles?.indexOf("CompanyAdmin") > -1;
  return (
    <DocumentTitle title="Manage Account">
      <Page>
        <Row style={{ marginTop: 72 }}>
          <Col xs={12} md={8} offset={{ md: 2 }}>
            <H2 style={{ marginBottom: 24 }}>Manage account</H2>
          </Col>

          <Col xs={12} md={8} offset={{ md: 2 }}>
            {isUserAnInspector ? (
              <Tabs
                tabs={[
                  {
                    title: "User",
                    content: getUserContent(),
                  },
                  {
                    title: "Company",
                    content: (
                      <Row>
                        {/* Company name */}
                        <Col xs={12} sm={4} md={3}>
                          <Label>Company name</Label>
                        </Col>
                        <Col
                          xs={12}
                          sm={8}
                          md={9}
                          className="flex align-center"
                          style={{ marginBottom: 24 }}
                        >
                          {company?.name === "Company name" ? (
                            <noscript />
                          ) : (
                            <Value>{company?.name}</Value>
                          )}

                          <Modal
                            title={
                              company?.name === "Company name"
                                ? "Set company name"
                                : "Update company name"
                            }
                            trigger={
                              <TertiaryButton style={{ marginLeft: 16 }}>
                                {company?.name === "Company name"
                                  ? "Set company name"
                                  : "Update"}
                              </TertiaryButton>
                            }
                          >
                            {(closeModal) => (
                              <Form
                                initialValues={{
                                  name: company.name,
                                }}
                                onSubmit={async (values) => {
                                  try {
                                    await CompaniesApi.updateCurrentCompany(
                                      values
                                    );
                                    dispatch(updateCurrentCompany(values));
                                    closeModal();
                                    toast.success(
                                      <Toast title="Company name updated" />
                                    );
                                  } catch (e) {}
                                }}
                                render={({ handleSubmit, submitting }) => (
                                  <Container
                                    style={{
                                      marginLeft: -16,
                                      marginRight: -16,
                                    }}
                                  >
                                    <Row>
                                      <Col xs={12}>
                                        <Input
                                          label="Name"
                                          name="name"
                                          validate={requiredValidator}
                                        />
                                      </Col>
                                      <Col xs={12}>
                                        <PrimaryButton
                                          style={{
                                            marginTop: 32,
                                            width: 160,
                                            marginLeft:
                                              company?.name === "Company name"
                                                ? 0
                                                : "auto",
                                          }}
                                          onClick={
                                            submitting ? null : handleSubmit
                                          }
                                        >
                                          {submitting ? (
                                            <Loader loading />
                                          ) : (
                                            "Save"
                                          )}
                                        </PrimaryButton>
                                      </Col>
                                    </Row>
                                  </Container>
                                )}
                              />
                            )}
                          </Modal>
                        </Col>

                        {/* Company Phone */}
                        <Col xs={12} sm={4} md={3}>
                          <Label>Company phone</Label>
                        </Col>
                        <Col
                          xs={12}
                          sm={8}
                          md={9}
                          className="flex align-center"
                          style={{ marginBottom: 24 }}
                        >
                          {company?.phone ? (
                            <Value style={{ marginRight: 16 }}>
                              {company?.phone}
                            </Value>
                          ) : (
                            <noscript />
                          )}

                          <Modal
                            title={
                              company?.phone
                                ? "Update company phone"
                                : "Add company phone"
                            }
                            size="small"
                            trigger={
                              <TertiaryButton>
                                {company?.phone ? "Update" : "Add a phone"}
                              </TertiaryButton>
                            }
                          >
                            {(closeModal) => (
                              <Form
                                initialValues={{
                                  name: company.name,
                                  phone: company.phone,
                                }}
                                onSubmit={async (values) => {
                                  try {
                                    await CompaniesApi.updateCurrentCompany(
                                      values
                                    );
                                    dispatch(updateCurrentCompany(values));
                                    closeModal();
                                    toast.success(
                                      <Toast title="Company phone updated" />
                                    );
                                  } catch (e) {}
                                }}
                                render={({ handleSubmit, submitting }) => (
                                  <Container
                                    style={{
                                      marginLeft: -16,
                                      marginRight: -16,
                                    }}
                                  >
                                    <Row>
                                      <Col xs={12}>
                                        <PhoneNumber
                                          label="Phone"
                                          name="phone"
                                          placeholder="Your company contact number"
                                        />
                                      </Col>
                                      <Col xs={12}>
                                        <PrimaryButton
                                          style={{
                                            marginTop: 32,
                                            width: 160,
                                          }}
                                          onClick={
                                            submitting ? null : handleSubmit
                                          }
                                        >
                                          {submitting ? (
                                            <Loader loading />
                                          ) : (
                                            "Save"
                                          )}
                                        </PrimaryButton>
                                      </Col>
                                    </Row>
                                  </Container>
                                )}
                              />
                            )}
                          </Modal>
                        </Col>

                        {/* Google reviews */}
                        {/* <Col xs={12} sm={4} md={3} style={{ marginBottom: 24 }}>
                          <Label>Google reviews</Label>
                        </Col>
                        <Col xs={12} sm={8} md={9} style={{ marginBottom: 24 }}>
                          <P>
                            Display existing Google Reviews on Rate My
                            Inspectors
                          </P>
                          <Switch
                            type="checkbox"
                            onChange={(e) => console.log(e.target.checked)}
                          />
                        </Col> */}
                      </Row>
                    ),
                  },
                ]}
              />
            ) : (
              getUserContent()
            )}
          </Col>
        </Row>
      </Page>
    </DocumentTitle>
  );
}
