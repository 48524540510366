import React, { useState } from "react";
import { Hidden, Visible } from "react-grid-system";
import ReactSelect from "react-select";
import styled from "styled-components";
import { RoundedPrimaryButton, TertiaryButton } from "ui-library";

const TabHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const RoundedTertiaryButton = styled(TertiaryButton)`
  border-radius: 32px;
`;

const SelectedRoundedTertiaryButton = styled(TertiaryButton)`
  border-radius: 32px;
  background-color: #d6eaf5;
`;

const TabHeader = styled.div`
  display: flex;
  overflow: scroll;
  max-width: 100%;
  flex-grow: 1;
  margin-right: 32px;
  font-size: 14px;

  ${RoundedPrimaryButton}, ${TertiaryButton} {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const TabContent = styled.div`
  margin-top: 32px;
`;

export function Tabs({ tabs, defaultTabIndex = 0 }) {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex);
  const options = tabs.map((tab, index) => ({
    label: tab.title,
    value: index,
  }));

  return (
    <>
      {/* For non mobile devices, we show the tabs */}
      <Hidden xs>
        <TabHeaderContainer>
          <TabHeader>
            {tabs
              .filter((_) => !!_)
              .map((tab, i) => {
                if (tabIndex === i) {
                  return (
                    <SelectedRoundedTertiaryButton key={tab.title}>
                      {tab.title}
                    </SelectedRoundedTertiaryButton>
                  );
                }

                return (
                  <RoundedTertiaryButton
                    key={tab.title}
                    onClick={() => setTabIndex(i)}
                  >
                    {tab.title}
                  </RoundedTertiaryButton>
                );
              })}
          </TabHeader>

          {tabs[tabIndex].headerAction && tabs[tabIndex].headerAction}
        </TabHeaderContainer>
      </Hidden>

      {/* For mobile devices, we show a dropdown instead */}
      <Visible xs>
        <ReactSelect
          options={options}
          defaultValue={options[tabIndex]}
          onChange={(selectedOption) => {
            setTabIndex(selectedOption.value);
          }}
          classNamePrefix="react-select"
          captureMenuScroll
          styles={{
            control: (styles) => {
              if (styles["boxShadow"]) {
                // if box shadow is defined, it means the select
                // field is focused
                return {
                  ...styles,
                  boxShadow: "none",
                  minHeight: 48,
                  borderRadius: 4,
                  backgroundColor: "#ffffff",
                  border: "3px solid #edf1f7",
                  cursor: "text",
                  "&:hover": {
                    borderColor: "#edf1f7",
                  },
                };
              }

              return {
                ...styles,
                minHeight: 48,
                borderRadius: 4,
                backgroundColor: "#f7f9fc",
                border: "3px solid #f7f9fc",
                cursor: "text",
                "&:hover": {
                  backgroundColor: "#edf1f7",
                  borderColor: "#edf1f7",
                },
              };
            },
            menu: (styles) => ({
              ...styles,
              backgroundColor: "#ffffff",
              boxShadow: "0px 5px 12px rgba(24, 105, 171, 0.15)",
              borderRadius: 8,
              padding: 8,
            }),
            option: (styles) => ({
              ...styles,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              paddingLeft: 16,
              paddingRight: 16,
              cursor: "pointer",
              color: "#143a5a",
              backgroundColor: "#ffffff",
              ":hover": {
                backgroundColor: "#efefef",
              },
              ":active": { backgroundColor: "#efefef" },
            }),
            multiValue: (styles, state) => ({
              ...styles,
              backgroundColor: "#028CB0",
              color: "#ffffff",
              borderRadius: 50,
              padding: "4px 8px",
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              color: "#ffffff",
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              cursor: "pointer",
              ":hover": {
                backgroundColor: "transparent",
              },
            }),
          }}
        />

        {tabs[tabIndex].headerAction && (
          <div style={{ marginTop: 24 }}>{tabs[tabIndex].headerAction}</div>
        )}
      </Visible>

      <TabContent>{tabs[tabIndex].content}</TabContent>
    </>
  );
}
