import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import logoIcon from "assets/icons/logo.svg";
import { A, P, H5, PrimaryButton } from "ui-library";
import { SocialIcon } from "./social-icon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "common/authentication";
import {
  INSPECTORS_MASTER_ROUTE,
  INSPECTOR_ROUTES,
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
  USERS_MASTER_ROUTE,
  USER_ROUTES,
} from "routes";
import inndoxLogo from "assets/icons/inndox-logo.svg";

const FooterContainer = styled.footer`
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
  background-color: #f5fafd;
  padding: 100px 0 32px;
  min-height: 436px;

  @media only screen and (max-width: 576px) {
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
  }

  @media only screen and (min-width: 1025px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;

const HelpText = styled(P)`
  color: #626687;
  margin-top: 16px;
`;

const FooterLink = styled(Link)`
  color: #626687;
  margin-bottom: 8px;
  margin-right: 8px;
  display: block;
  text-decoration: none;

  &:hover {
    color: rgba(2, 140, 176, 1);
    text-decoration: underline;
  }
`;

const ExternalFooterLink = styled(A)`
  color: #626687;
  margin-bottom: 8px;
  margin-right: 8px;
  display: block;
  text-decoration: none;

  &:hover {
    color: rgba(2, 140, 176, 1);
    text-decoration: underline;
  }
`;

const FooterLinks = styled.div`
  column-count: 2;
  margin-top: 24px;
`;

const Copyright = styled(P)`
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #888b97;
  text-align: center;

  > div {
    margin-left: auto;
    margin-right: auto;
  }
`;

const COMPANY_FOOTER_LINKS = [
  {
    name: "Property reports",
    link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.PROPERTY_REPORTS,
  },
  {
    name: "For inspectors",
    link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.FOR_INSPECTORS,
  },
  // {
  //   name: "Awards",
  //   link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.AWARDS,
  // },
  {
    name: "Inspection types",
    link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.INSPECTION_TYPES,
  },
  {
    name: "FAQs",
    link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.FAQS,
  },
  {
    name: "About",
    link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.ABOUT,
  },
  {
    name: "Privacy policy",
    link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.PRIVACY_POLICY,
  },
  {
    name: "Blog",
    link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.BLOG,
  },
  {
    name: "Terms & conditions",
    link: PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.TERMS,
  },
];

const StyledInternalLink = styled(A)`
  color: #626687;
  font-size: 12px;
  line-height: 28px;
`;

const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

export function Footer() {
  const { pathname } = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const isAnonymous = auth?.session?.status === "Anonymous";
  const isAuthenticated = !!auth?.session && !isAnonymous;
  const isInspector = auth?.session?.roles.indexOf("CompanyAdmin") > -1;
  const isUserBeingOnboarded = pathname.startsWith(
    "/" + INSPECTORS_MASTER_ROUTE + "/onboarding"
  );

  if (isUserBeingOnboarded) {
    return <noscript />;
  }

  return (
    <FooterContainer>
      <Container fluid>
        <Row>
          <Col xs={12} md={3}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (isAuthenticated) {
                  if (isInspector) {
                    navigate(
                      "/" +
                        INSPECTORS_MASTER_ROUTE +
                        "/" +
                        INSPECTOR_ROUTES.DASHBOARD
                    );
                  } else {
                    navigate(
                      "/" + USERS_MASTER_ROUTE + "/" + USER_ROUTES.DASHBOARD
                    );
                  }
                } else {
                  navigate(
                    "/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME
                  );
                }
              }}
              src={logoIcon}
              alt="logo"
              width={153}
            />

            <HelpText>Find the best property inspectors.</HelpText>

            <div className="flex align-center" style={{ marginTop: 16 }}>
              <SocialIcon link="https://www.facebook.com/ratemyinspectors" />
              <SocialIcon link="https://www.instagram.com/ratemyinspectors/" />
              <SocialIcon link="https://www.linkedin.com/company/rate-my-inspectors/" />
            </div>
          </Col>

          <Col xs={12} md={3}>
            <H5 style={{ marginTop: 16 }}>Company</H5>

            <FooterLinks>
              {COMPANY_FOOTER_LINKS.map((footerLink, index) => {
                if (footerLink.link.startsWith("http")) {
                  // external link
                  return (
                    <ExternalFooterLink
                      key={`footer-link-${index}`}
                      href={footerLink.link}
                      target="_blank"
                    >
                      {footerLink.name}
                    </ExternalFooterLink>
                  );
                } else {
                  return (
                    <FooterLink
                      key={`footer-link-${index}`}
                      to={footerLink.link}
                    >
                      {footerLink.name}
                    </FooterLink>
                  );
                }
              })}
            </FooterLinks>
          </Col>

          <Col xs={12} md={3}>
            <H5 style={{ marginTop: 16 }}>Contact</H5>

            <FooterLinks>
              <A href="mailto:info@ratemyinspectors.com">
                info@ratemyinspectors.com
              </A>
            </FooterLinks>
          </Col>

          <Col xs={12} md={3}>
            {isAuthenticated && auth?.session?.roles?.indexOf("User") > -1 ? (
              <>
                <H5 style={{ marginTop: 16 }}>Find an Inspector</H5>

                <PrimaryButton
                  style={{ marginTop: 8 }}
                  onClick={() => {
                    navigate(
                      "/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME
                    );
                  }}
                >
                  Compare now
                </PrimaryButton>
              </>
            ) : !isAuthenticated ? (
              <>
                <H5 style={{ marginTop: 16 }}>Find an Inspector</H5>

                <PrimaryButton
                  style={{ marginTop: 8, fontSize: "14px" }}
                  onClick={() => {
                    navigate(
                      "/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME
                    );
                  }}
                >
                  Compare now
                </PrimaryButton>
              </>
            ) : (
              <noscript />
            )}

            {!isAuthenticated && (
              <>
                <H5 style={{ marginTop: 40 }}>For Inspectors</H5>
                <PrimaryButton
                  style={{ marginTop: 8, fontSize: "14px" }}
                  onClick={() =>
                    navigate(
                      "/" +
                        SEARCH_MASTER_ROUTE +
                        "/" +
                        SEARCH_ACCOUNTS_MASTER_ROUTE +
                        "/" +
                        SEARCH_ACCOUNTS_ROUTES.FIND
                    )
                  }
                >
                  Claim your free profile
                </PrimaryButton>
              </>
            )}
          </Col>
        </Row>

        <Row
          style={{
            marginTop: 32,
          }}
        >
          <Col xs={12} md={3}>
            <PoweredBy
              onClick={() => window.open("https://inndox.com", "_blank")}
            >
              <P style={{ marginRight: 8 }}>
                <em>Powered by</em>
              </P>

              <img src={inndoxLogo} alt="inndox" width={70} />
            </PoweredBy>
          </Col>
          <Col xs={12} md={6}>
            <Copyright>
              Ⓒ {new Date().getFullYear()} Rate my Inspectors | Designed by{" "}
              <StyledInternalLink
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.beechrysdesign.com"
              >
                Bee Chrys Design
              </StyledInternalLink>
            </Copyright>
          </Col>
          <Col xs={12} md={3}></Col>
        </Row>
      </Container>
    </FooterContainer>
  );
}
