import { useEffect, useState } from "react";
import { Loader } from "../loader";
import { InspectorVideosApi } from "services";
import { VideoThumbnail } from "../video-thumbnail";
import styled from "styled-components";
import { P } from "ui-library";

const VideosContainer = styled.div`
  display: flex;
  overflow: auto;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

export function InspectorVideos({ inspectorId }) {
  const [videos, setVideos] = useState(null);

  const fetchInspectorVideos = async () => {
    try {
      const response = await InspectorVideosApi.getVideosForInspectorWithId(
        inspectorId
      );
      setVideos(response);
    } catch (e) {}
  };

  useEffect(() => {
    fetchInspectorVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!videos) {
    return (
      <div style={{ textAlign: "center" }}>
        <Loader size="medium" loading color="#EB9F22" />
      </div>
    );
  }

  if (videos.length === 0) {
    return (
      <div>
        <P style={{ marginLeft: 20 }}>
          <em>There are no videos for this inspector yet.</em>
        </P>
      </div>
    );
  }

  return (
    <VideosContainer>
      {videos.map((video, index) => (
        <VideoThumbnail key={index} video={video} readOnly />
      ))}
    </VideosContainer>
  );
}
