import { useState } from "react";
import { FiftyFifty } from "components";
import { matchPath, useSearchParams } from "react-router-dom";
import AccountTypeSelector from "../components/account-type-selector";
import { DocumentTitle } from "components/document-title";
import { useSelector } from "react-redux";
import {
  selectInspectorIds,
  selectReportId,
} from "store/features/search/searchSlice";
import { selectInspectorCode } from "store/features/customer-reviews/customerReviewsSlice";
import AccountLoginForm from "account/components/login-form";

export default function AccountLogin() {
  // If account type from query params
  const [params] = useSearchParams();
  const accountType = params?.get("type");
  const next = params?.get("next");

  // For customised login page for customers going through
  // search flow of some kind
  const inspectorCode =
    useSelector(selectInspectorCode) ||
    matchPath("/search/inspectors/:inspectorCode/rate", next || "")?.params
      .inspectorCode;
  const reportId = useSelector(selectReportId);
  const selectedInspectorIds = useSelector(selectInspectorIds);
  const isPartOfCustomerSearchFlow =
    !!reportId || !!selectedInspectorIds?.length || inspectorCode;

  // local state
  const [isInspector, setIsInspector] = useState(
    isPartOfCustomerSearchFlow
      ? false
      : accountType === "inspector"
      ? true
      : accountType === "user"
      ? false
      : undefined
  );

  // account type not defined, show account type selector
  if (isInspector === undefined) {
    return <AccountTypeSelector isInspectorSelected={setIsInspector} />;
  }

  // For login form
  let title = "";
  let description = "";

  if (isPartOfCustomerSearchFlow) {
    if (reportId) {
      // Purchasing report
      title = "Sign in to continue";
      description = "Sign in with your account to purchase the report";
    } else if (selectedInspectorIds) {
      title = "Sign in with your account to request a quote";
      description = "";
    } else {
      // inspectorCode
      title = "Sign in with your account to leave a review";
      description = "";
    }
  } else {
    title = isInspector ? "Sign in as an Inspector" : "Sign in as a Customer";
    description = isInspector ? "" : "Log in to your account";
  }

  return (
    <DocumentTitle title="Sign in">
      <FiftyFifty>
        <AccountLoginForm
          isInspector={isInspector}
          title={title}
          description={description}
        />
      </FiftyFifty>
    </DocumentTitle>
  );
}
