import { Loader } from "components";
import CustomDropzone from "components/dropzone";
import Toast from "components/toast";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { InspectorsApi } from "services";
import { displayErrorMessage } from "services/api";
import { updateCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import styled from "styled-components";
import { H3, PrimaryButton } from "ui-library";

const InspectorDocumentDialogContainer = styled.div``;

const Title = styled(H3)`
  margin-top: -60px;
  margin-bottom: 24px;
`;

export default function InspectorDocumentDialog({
  title = "Upload a file",
  type,
  onComplete,
}) {
  /**
   * @type {[File, Dispatch<SetStateAction<File>>]} fileTrackingHook
   */
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();

  const uploadFile = async () => {
    setIsUploading(true);

    try {
      if (type === "InspectionAgreement") {
        const formData = new FormData();
        formData.append("inspectionAgreementFile", file);
        await InspectorsApi.uploadFilesToCurrentInspector(formData);

        const updatedInspector =
          await InspectorsApi.getCurrentInspectorProfile();
        dispatch(updateCurrentInspector(updatedInspector));
        toast.success(<Toast title="Inspection agreement updated" />);
      } else {
        const formData = new FormData();
        formData.append("sampleReportFile", file);

        await InspectorsApi.uploadFilesToCurrentInspector(formData);

        const response = await InspectorsApi.getCurrentInspectorProfile();
        dispatch(updateCurrentInspector(response));

        toast.success(<Toast title="Sample report updated" />);
      }

      setFile(null);
      setIsUploading(false);
      onComplete();
    } catch (e) {
      setFile(null);
      setIsUploading(false);
      displayErrorMessage(e);
    }
  };

  return (
    <InspectorDocumentDialogContainer>
      <Title>{file ? "File added" : title}</Title>
      <CustomDropzone onFileSelected={setFile} />

      {file ? (
        <PrimaryButton
          style={{
            marginTop: 40,
            marginLeft: "auto",
            marginRight: 0,
            width: 200,
          }}
          onClick={uploadFile}
        >
          {isUploading ? <Loader loading color="#fff" /> : "Upload"}
        </PrimaryButton>
      ) : (
        <noscript />
      )}
    </InspectorDocumentDialogContainer>
  );
}
