import styled from "styled-components";
import { P } from "ui-library";
import cameraPlusIcon from "assets/icons/camera-plus.svg";
import { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import { useDropzone } from "react-dropzone";
import deleteIcon from "assets/icons/thumbnail-delete.svg";
import { InspectorReviewsApi } from "services";
import { Loader } from "components";

const AddedPhotos = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AddedPhotoContainer = styled.div`
  position: relative;
  margin-right: 12px;
  margin-bottom: 12px;
`;

const AddedPhoto = styled.img`
  width: 115px;
  height: 115px;
  border-radius: 4px;
  background-color: grey;
  display: inline-block;
  flex-shrink: 0;
`;

const DeleteIcon = styled.img`
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
`;

const AddAPhotoContainer = styled.div`
  width: 115px;
  height: 115px;
  border-radius: 10px;
  background: #ffffff;
  border: 2px dashed #e4f0f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;

  &.dragging-over {
    background-color: #e4f0f7;
  }

  img {
    margin-right: -12px;
    margin-top: -10px;
  }
`;

/**
 * @typedef {Object} MultipleImageUploadForReviewProps
 * @property {string | number} inspectorId
 * @property {string[]} imagePaths
 * @property {(finalisedImagePaths: string[]) => void} onImagesFinalised
 */

/**
 *
 * @param {MultipleImageUploadForReviewProps} props
 * @returns
 */
export default function MultipleImageUploadForReview({
  inspectorId,
  imagePaths,
  onImagesFinalised,
}) {
  const [acceptedFiles, setAcceptedFiles] = useState();
  const [inProgressFiles, setInProgessFiles] = useState({});
  const [finalisedFiles, setFinalisedFiles] = useState(
    imagePaths.reduce((reduction, imagePath) => {
      reduction[v4()] = imagePath;
      return reduction;
    }, {})
  );

  const uploadFiles = async () => {
    const updatedInProgressFiles = JSON.parse(JSON.stringify(inProgressFiles));
    const updatedAcceptedFiles = JSON.parse(JSON.stringify(acceptedFiles));

    // upload requests
    const fileUploadRequests = Object.keys(acceptedFiles).map((fileId) => {
      // update inprogress tracking
      updatedInProgressFiles[fileId] = "uploading";
      delete updatedAcceptedFiles[fileId];

      // return promise
      return InspectorReviewsApi.uploadImageForReview(
        inspectorId,
        acceptedFiles[fileId]
      );
    });

    // update local state
    setInProgessFiles(updatedInProgressFiles);
    // setAcceptedFiles(updatedAcceptedFiles);

    // upload the files
    const response = await Promise.all(fileUploadRequests);

    // update local state
    const updatedFinalisedFiles = JSON.parse(JSON.stringify(finalisedFiles));
    Object.keys(updatedInProgressFiles).forEach((fileId, index) => {
      updatedFinalisedFiles[fileId] = response[index];
      delete updatedInProgressFiles[fileId];
    });

    setInProgessFiles(updatedInProgressFiles);
    setFinalisedFiles(updatedFinalisedFiles);
  };

  const removeFinalisedFile = (fileId) => {
    const updatedFinalisedFiles = JSON.parse(JSON.stringify(finalisedFiles));
    delete updatedFinalisedFiles[fileId];
    setFinalisedFiles(updatedFinalisedFiles);
  };

  const onDrop = useCallback((files) => {
    setAcceptedFiles((_) => ({
      ..._,
      ...files.reduce((reduction, file) => {
        reduction[v4()] = file;
        return reduction;
      }, {}),
    }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: ".png,.jpg,.jpeg",
    maxFiles: 10,
  });

  // When accepted files change, we upload
  // each file.
  // The upload progress is tracked in the
  // 'inProgressFiles' and once uploaded,
  // they are moved to 'finalisedFiles'
  useEffect(() => {
    // upload each file.
    if (acceptedFiles) {
      uploadFiles(acceptedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    onImagesFinalised(finalisedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalisedFiles]);

  return (
    <>
      <P style={{ color: "#143a5a", marginTop: 24 }}>
        Attach up to 10 photos to your review
      </P>
      <AddedPhotos>
        <AddAPhotoContainer
          className={isDragActive ? "dragging-over" : ""}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <noscript />
          ) : (
            <img src={cameraPlusIcon} alt="add file" />
          )}
        </AddAPhotoContainer>

        {/* In Progress Files */}
        {Object.keys(inProgressFiles).map((fileId) => (
          <AddAPhotoContainer key={fileId}>
            <Loader loading color="#EB9F22" />
          </AddAPhotoContainer>
        ))}

        {/* Finalised Files */}
        {Object.keys(finalisedFiles).map((fileId, index) => (
          <AddedPhotoContainer key={index}>
            <AddedPhoto src={finalisedFiles[fileId] + "?w=115&h=115"} />
            <DeleteIcon
              src={deleteIcon}
              onClick={() => removeFinalisedFile(fileId)}
            />
          </AddedPhotoContainer>
        ))}
      </AddedPhotos>
    </>
  );
}
