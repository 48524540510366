import { Hidden } from "react-grid-system";
import styled from "styled-components";
import displayCardPersonImage from "../../../assets/images/onboarding-card-person.png";
import { H2, H3, H5, P, P1, PrimaryButton } from "ui-library";
import { DisplayRating } from "components";
import viewDocIcon from "../../../assets/icons/view-document.svg";
import shieldIcon from "../../../assets/icons/onboarding-shield.svg";
import dollarSignIcon from "../../../assets/icons/dollar-sign.svg";
import { useNavigate } from "react-router-dom";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import { useAuth } from "common/authentication";

const OnboardingIntroductionContainer = styled.div`
  height: calc(100vh - 104px);
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 91px;
  padding-left: 16px;
  padding-right: 16px;

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    padding-top: 35px;
    overflow: auto;
    height: calc(100vh - 56px);
  }
`;

const OnboardingIntroductionContent = styled.div`
  display: flex;
`;

const LeftContent = styled.div``;

const CopyContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CopyIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 18px;
  flex-shrink: 0;
`;

const Action = styled.div`
  background-color: white;

  ${PrimaryButton} {
    margin-top: 27px;
  }

  @media only screen and (max-width: 576px) {
    position: sticky;
    bottom: 0;
    padding-bottom: 16px;

    ${PrimaryButton} {
      width: 100%;
      margin-top: 16px;
    }
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e4f0f7;
  margin-top: 34px;
`;

const RightContent = styled.div`
  margin-left: 57px;
`;

const DisplayCard = styled.div`
  width: 411px;
  flex-shrink: 0;
  border-radius: 32px;
  border: 1px solid #e4f0f7;
  background: #fff;
  box-shadow: 0px 11px 48px 0px rgba(24, 105, 171, 0.06);
  padding: 38px;
  text-align: center;
  margin-top: 12px;
`;

const DisplayCardImage = styled.img`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  border-radius: 80px;
  background: url(<path-to-image>), lightgray 50% / cover no-repeat,
    url(<path-to-image>), lightgray -3.716px 0px / 109.29% 100% no-repeat;
  box-shadow: 0px 9px 32px 0px rgba(89, 92, 219, 0.1);
`;

const ReportsAndReviewsSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 6px;
`;

const ReviewsSummary = styled.div``;

const ReportsSummary = styled.div``;

const CountSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function OnboardingIntroduction() {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <OnboardingIntroductionContainer>
      <OnboardingIntroductionContent>
        <LeftContent>
          <H2>
            Hey there {user.firstName}. Are you ready for inspections with less
            effort?
          </H2>

          <CopyContainer style={{ marginTop: 43 }}>
            <CopyIcon>
              <img src={viewDocIcon} alt="profile" />
            </CopyIcon>

            <P1>Answer a few questions to build and activate your profile</P1>
          </CopyContainer>

          <CopyContainer style={{ marginTop: 27 }}>
            <CopyIcon>
              <img src={shieldIcon} alt="trust" />
            </CopyIcon>

            <P1>
              Create trust and show your prospects exactly why they should work
              with you
            </P1>
          </CopyContainer>

          <CopyContainer style={{ marginTop: 27 }}>
            <CopyIcon>
              <img src={dollarSignIcon} alt="money" />
            </CopyIcon>

            <P1>Sell past reports and make money while you sleep</P1>
          </CopyContainer>

          <Action>
            <Separator />

            <PrimaryButton
              onClick={() =>
                navigate(
                  "/" +
                    INSPECTORS_MASTER_ROUTE +
                    "/" +
                    INSPECTOR_ROUTES.ONBOARDING_STEP_1
                )
              }
            >
              GET STARTED
            </PrimaryButton>
          </Action>
        </LeftContent>

        <Hidden xs>
          <RightContent>
            <DisplayCard>
              <DisplayCardImage src={displayCardPersonImage} />

              <H3 style={{ marginTop: 21 }}>Harry Henderson</H3>
              <P1>Pool Inspector +2 more</P1>

              <ReportsAndReviewsSummary key={3}>
                <ReviewsSummary>
                  <P style={{ fontSize: "20px", lineHeight: "34px" }}>
                    91 reviews
                  </P>
                  <DisplayRating rating={4.8} />
                </ReviewsSummary>

                <ReportsSummary>
                  <CountSummary>
                    <P
                      style={{
                        color: "#143A5A",
                        fontSize: "20px",
                        lineHeight: "34px",
                        textAlign: "center",
                      }}
                    >
                      10
                    </P>
                  </CountSummary>
                  <P style={{ fontSize: "14px", lineHeight: "21px" }}>
                    Reports available
                  </P>
                </ReportsSummary>
              </ReportsAndReviewsSummary>

              <H5 style={{ marginTop: 27, color: "#798995" }}>
                “Rate My Inspectors transformed my pool cleaning business. Since
                joining, I've seen a substantial increase in inquiries and
                bookings. It's a must-have for any pool inspector looking to
                grow their business”
              </H5>
            </DisplayCard>
          </RightContent>
        </Hidden>
      </OnboardingIntroductionContent>
    </OnboardingIntroductionContainer>
  );
}
