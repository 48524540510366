import Banner from "components/public-pages/banner/banner";
import SecondSectionRow from "components/public-pages/second-section-row/second-section-row";
import ReportsIcon from "../icons/reports";
import TypesIcon from "../icons/types";
import SecureIcon from "../icons/secure";
import Caurosel from "components/public-pages/caurosel/caurosel";
import { H2, P1, PrimaryButton } from "ui-library";
import BuyersCouchImage from "assets/images/buyers-couch.png";
import HappyCoupleCookingImage from "assets/images/happy-couple-cooking.png";
import LadyCuddlingImage from "assets/images/lady-cuddling-dog.png";
import ForBuyersBannerImage from "assets/images/for-buyers.png";
import FAQList from "components/public-pages/faqs/list";
import { buyers as buyersFAQs } from "../helpers/faqs";
import { DocumentTitle } from "components/document-title";
import StatsCardWrapper from "components/public-pages/stats-card-wrapper";
import { Spacer } from "common/helpers";
import styled from "styled-components";
import { Container } from "react-grid-system";
import { PUBLIC_MASTER_ROUTE, PUBLIC_ROUTES } from "routes";
import { useNavigate } from "react-router-dom";

const StyledStatsCardWrapper = styled(StatsCardWrapper)`
  @media only screen and (min-width: 1024px) {
    margin-bottom: 32px;

    > div {
      margin-top: -52px;
    }
  }

  @media only screen and (max-width: 812px) {
    margin-bottom: 72px;
  }
`;

const items = [
  {
    icon: <ReportsIcon />,
    title: "Find past reports",
    description: `Check <a href="/property-reports">Property Reports</a> to see if there are any existing reports you can purchase on the property available now.`,
  },
  {
    icon: <TypesIcon />,
    title: "All types of inspections",
    description: `<a href="/inspection-types">Learn</a> about all the different inspection types and arrange them all here.`,
  },
  {
    icon: <SecureIcon />,
    title: "Sample Reports",
    description: `Many inspectors have Sample Reports available for you to view on their profile.`,
  },
];

export const ImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 80px;
  img {
    width: 100%;
    object-fit: contain;
  }

  p {
    width: 80%;
  }

  .image-section-div {
    padding-bottom: 54px;

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;

    p {
      width: 100%;
    }
  }
`;

export const ReverseImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 80px;
  img {
    width: 100%;
    object-fit: contain;
  }

  p {
    width: 80%;
  }

  .image-section-div {
    padding-bottom: 54px;

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  @media only screen and (max-width: 576px) {
    display: flex;
    flex-direction: column-reverse;
    grid-gap: 0;

    p {
      width: 100%;
    }
  }
`;

export const BannerButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 40px;

  button:last-child {
    color: #eb9f22;
    background: white;
    border: 1px solid #eb9f22;
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
`;

function ForBuyersPage() {
  const navigate = useNavigate();

  return (
    <>
      <DocumentTitle title="For Buyers">
        <Banner
          title="The biggest inspection marketplace in Australia #1"
          excerpt={
            <P1>
              With more than 50 types of inspections and over 12,000 inspectors,
              we are your one-stop shop for property inspections.
            </P1>
          }
          image={ForBuyersBannerImage}
        >
          <BannerButtonsWrapper>
            <PrimaryButton onClick={() => navigate("/" + PUBLIC_MASTER_ROUTE)}>
              Find my inspector
            </PrimaryButton>
            <PrimaryButton
              onClick={() =>
                navigate(
                  "/" +
                    PUBLIC_MASTER_ROUTE +
                    "/" +
                    PUBLIC_ROUTES.PROPERTY_REPORTS
                )
              }
            >
              Search existing reports
            </PrimaryButton>
          </BannerButtonsWrapper>
        </Banner>
      </DocumentTitle>

      <StyledStatsCardWrapper />

      <Spacer />

      <Container>
        <SecondSectionRow items={items} />
      </Container>

      <Spacer />

      <Container>
        <ImageSection>
          <img src={BuyersCouchImage} alt="Buyers couch" />
          <div className="image-section-div">
            <H2>Whats included in the report?</H2>
            <P1>
              Request a sample report from an inspector to understand what your
              report will cover.
            </P1>
          </div>
        </ImageSection>
      </Container>

      <Spacer />

      <Container>
        <ReverseImageSection>
          <div className="image-section-div">
            <H2>All inspectors are not the same</H2>
            <P1>
              Some offer same day reports, others speak Hindi, some include
              Thermal imaging with every report. With more than 12,000
              inspectors we have the right inspector for you.
            </P1>
          </div>
          <img src={HappyCoupleCookingImage} alt="Happy Couple Cooking" />
        </ReverseImageSection>
      </Container>

      <Spacer />

      <Container>
        <ImageSection>
          <img src={LadyCuddlingImage} alt="Lady cuddling dog" />
          <div className="image-section-div">
            <H2>Be confident before you buy</H2>
            <P1>
              Find inspectors with years in business who are licensed and
              insured. Combine that with user reviews and you know you are
              getting the best
            </P1>
          </div>
        </ImageSection>
      </Container>

      <Spacer />

      <Caurosel />

      <Container>
        <FAQList openedOnDefault={false} faqs={buyersFAQs.slice(0, 5)} />
      </Container>

      <Spacer />
    </>
  );
}

export default ForBuyersPage;
