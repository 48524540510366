import { MONTHS } from "helpers";
import { Col, Row } from "react-grid-system";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  SEARCH_MASTER_ROUTE,
  SEARCH_REPORTS_MASTER_ROUTE,
  SEARCH_REPORTS_ROUTES,
} from "routes";
import {
  selectSelectedReportId,
  setSearchedReports,
} from "store/features/search/searchSlice";
import styled from "styled-components";
import { H4, P, PrimaryButton } from "ui-library";
import NetworkImage from "./network-image";

const ReportContainer = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ReportImage = styled(NetworkImage)`
  width: 229px;
  height: 145px;
  border-radius: 8px;
  flex-shrink: 0;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 224px;
  }
`;

const ReportContent = styled.div`
  margin-left: 32px;
  margin-right: 32px;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 32px;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-right: 0;
  width: 200px;
  flex-shrink: 0;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
`;

/**
 *
 * @param {Object} props
 * @param {import("services").InspectionReport} props.report
 */
export function Report({ report, inspectionTypes }) {
  const reportDate = new Date(report.inspectionDate);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inspectionTypeNames = report.inspectionTypeIds.map(
    (inspectionTypeId) => inspectionTypes[inspectionTypeId].name
  );

  return (
    <Row style={{ marginBottom: 56 }}>
      <Col>
        <ReportContainer>
          <ReportImage
            src={`${report.propertyImagePath}?w=278&h=176`}
            isAvatar={false}
          />

          <ReportContent>
            <H4>
              {inspectionTypeNames.length
                ? inspectionTypeNames.length > 2
                  ? inspectionTypeNames
                      .slice(0, inspectionTypeNames.length - 1)
                      .join(", ") +
                    ` & ${inspectionTypeNames[inspectionTypeNames.length - 1]} `
                  : inspectionTypeNames.join(" & ")
                : "Unknown"}{" "}
              report
            </H4>
            <P>{report.address}</P>
            <P>
              <em>
                {MONTHS[reportDate.getMonth()] +
                  " " +
                  reportDate.getDate() +
                  ", " +
                  reportDate.getFullYear()}
              </em>
            </P>
          </ReportContent>

          <StyledPrimaryButton
            onClick={() => {
              dispatch(setSearchedReports([report]));
              dispatch(selectSelectedReportId(report.id));

              navigate(
                "/" +
                  SEARCH_MASTER_ROUTE +
                  "/" +
                  SEARCH_REPORTS_MASTER_ROUTE +
                  "/" +
                  report.id +
                  "/" +
                  SEARCH_REPORTS_ROUTES.PURCHASE_REPORT
              );
            }}
          >
            ${report.price} - Purchase report
          </StyledPrimaryButton>
        </ReportContainer>
      </Col>
    </Row>
  );
}
