export default function YellowBlob() {
  return (
    <svg
      width="411"
      height="678"
      viewBox="0 0 411 678"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3" filter="url(#filter0_f_222_168)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M268.209 352.001C257.173 386.669 231.914 413.19 209.037 441.462C189.326 465.821 168.32 487.535 143.292 506.373C115.414 527.356 89.1223 556.558 54.2732 557.946C19.3115 559.339 -10.0493 533.407 -38.9325 513.634C-66.0888 495.044 -85.4251 469.427 -108.834 446.286C-140.874 414.612 -196.167 396.642 -202.434 352.001C-208.493 308.845 -164.511 275.35 -137.647 241.061C-113.838 210.671 -87.2616 183.755 -54.5624 163.262C-20.9854 142.219 15.0816 115.019 54.2732 120.785C93.4007 126.54 110.038 176.499 146.088 192.779C188.116 211.758 250.755 185.58 278.836 222.192C305.703 257.222 281.604 309.919 268.209 352.001Z"
          fill="#FFC25F"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_222_168"
          x="-323"
          y="0"
          width="734"
          height="678"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="60"
            result="effect1_foregroundBlur_222_168"
          />
        </filter>
      </defs>
    </svg>
  );
}
