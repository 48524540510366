export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getFormattedDate = (date, showTime = false) => {
  const dateObject = new Date(date);

  if (!showTime) {
    return dateObject.toLocaleDateString("en-AU");
  }

  return (
    dateObject.toLocaleDateString("en-AU") +
    " - " +
    dateObject.toLocaleTimeString("en-AU", {
      timeStyle: "short",
    })
  );
};
