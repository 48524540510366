import { useEffect } from "react";
import styled from "styled-components";
import logo from "assets/icons/logo-white.svg";
import { H2, InternalA, P } from "ui-library";
import inspectorAccountImage from "assets/images/inspector-account.png";
import userAccountImage from "assets/images/user-account.png";
import chevronRight from "assets/icons/chevron-right.svg";
import { Hidden } from "react-grid-system";
import backgroundImage from "assets/images/account-selector-bg.png";
import { useNavigate } from "react-router-dom";
import {
  ACCOUNT_MASTER_ROUTE,
  ACCOUNT_ROUTES,
  PUBLIC_MASTER_ROUTE,
  PUBLIC_ROUTES,
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_ACCOUNTS_ROUTES,
  SEARCH_MASTER_ROUTE,
} from "routes";

const AccountTypeSelectorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #028cb0cc;

  @media only screen and (max-width: 576px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const Logo = styled.img`
  width: 153px;
  cursor: pointer;
`;

const OptionContainer = styled.div`
  width: 536px;
  height: 92px;
  background-color: #fff;
  margin-top: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  cursor: pointer;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

const AccountImage = styled.img`
  width: 75px;

  @media only screen and (max-width: 576px) {
    width: 56px;
  }
`;

const Copy = styled.div`
  margin-left: 20px;
`;

const Chevron = styled.img`
  height: 12px;
  margin-left: auto;
  margin-right: 0;

  @media only screen and (max-width: 576px) {
    margin-left: 16px;
  }
`;

export default function AccountTypeSelector({
  type = "login",
  isInspectorSelected,
}) {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.background = `url(${backgroundImage})`;
    document.body.style.backgroundPosition = `50% 50%`;

    return () => {
      document.body.style.background = `unset`;
      document.body.style.backgroundPosition = `unset`;
    };
  }, []);

  return (
    <AccountTypeSelectorContainer>
      <Logo
        src={logo}
        onClick={() =>
          navigate("/" + PUBLIC_MASTER_ROUTE + "/" + PUBLIC_ROUTES.HOME)
        }
      />

      <H2
        style={{
          color: "#fff",
          marginTop: 24,
          marginBottom: 8,
          textAlign: "center",
        }}
      >
        {type === "login" ? "Hey there! Welcome Back" : "Hey there!"}
      </H2>

      <OptionContainer
        onClick={() => {
          if (type !== "login") {
            navigate(
              "/" +
                SEARCH_MASTER_ROUTE +
                "/" +
                SEARCH_ACCOUNTS_MASTER_ROUTE +
                "/" +
                SEARCH_ACCOUNTS_ROUTES.FIND
            );
          } else {
            isInspectorSelected(true);
          }
        }}
      >
        <AccountImage src={inspectorAccountImage} />

        <Copy>
          <P style={{ color: "#143A5A", fontSize: "20px" }}>
            <strong>
              {type === "login"
                ? "Sign in as an Inspector"
                : "Sign up as an Inspector"}
            </strong>
          </P>
          <Hidden xs>
            <P style={{ fontSize: "16px", lineHeight: "28px" }}>
              I’m an inspector wanting to grow my business
            </P>
          </Hidden>
        </Copy>

        <Chevron src={chevronRight} />
      </OptionContainer>

      <OptionContainer onClick={() => isInspectorSelected(false)}>
        <AccountImage src={userAccountImage} />

        <Copy>
          <P style={{ color: "#143A5A", fontSize: "20px" }}>
            <strong>
              {type === "login"
                ? "Sign in as a Customer"
                : "Sign up as a Customer"}
            </strong>
          </P>
          <Hidden xs>
            <P style={{ fontSize: "16px", lineHeight: "28px" }}>
              Request a quote, buy a report, leave a review
            </P>
          </Hidden>
        </Copy>

        <Chevron src={chevronRight} />
      </OptionContainer>

      {type === "login" ? (
        <P style={{ color: "#fff", marginTop: 32 }}>
          Don’t have an account?{" "}
          <InternalA
            style={{
              color: "#fff",
              textDecoration: "underline",
              fontSize: 16,
            }}
            to={"/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.REGISTER}
          >
            Sign up
          </InternalA>
        </P>
      ) : (
        <P style={{ color: "#fff", marginTop: 32 }}>
          Already have an account?{" "}
          <InternalA
            style={{
              color: "#fff",
              textDecoration: "underline",
              fontSize: 16,
            }}
            to={"/" + ACCOUNT_MASTER_ROUTE + "/" + ACCOUNT_ROUTES.LOGIN}
          >
            Log in
          </InternalA>
        </P>
      )}

      <style
        dangerouslySetInnerHTML={{
          __html: `nav, footer { display: none } #root { padding-top: 0; } `,
        }}
      />
    </AccountTypeSelectorContainer>
  );
}
