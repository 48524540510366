import { api } from "./api";

export const BlogsApi = {
  getBlogs: async (data) =>
    api.get("/PageContents", {
      data,
    }),

  getBlogById: async (id) => api.get(`/PageContents/${id}`),
};
