import { useState } from "react";
import styled from "styled-components";
import { P } from "ui-library";
import caretDownIcon from "assets/icons/caret-down.svg";

const AccordionContainer = styled.div`
  border: 1px solid #e4f0f7;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;
`;

const AccordionHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const AccordionIcon = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${P} {
    font-weight: bold;
    color: #143a5a;
  }
`;

const Caret = styled.img`
  width: 16px;

  &.open {
    transform: rotate(180deg);
  }
`;

const AccordionContent = styled.div`
  padding: 16px 32px;

  @media only screen and (max-width: 576px) {
    padding: 16px 0;
  }
`;

const Content = styled.div`
  /* max-width: 350px; */
`;

export function Accordion({
  icon,
  title,
  children,
  className,
  isOpenInitially = false,
}) {
  const [isOpen, setIsOpen] = useState(isOpenInitially);

  return (
    <AccordionContainer className={className}>
      <AccordionHeader onClick={() => setIsOpen((_) => !_)}>
        <AccordionTitle>
          <AccordionIcon>
            <img src={icon} alt="icon" />
          </AccordionIcon>

          <P>{title}</P>
        </AccordionTitle>

        <AccordionIcon style={{ marginRight: -4 }}>
          <Caret
            className={isOpen ? "open" : ""}
            src={caretDownIcon}
            alt="caret"
          />
        </AccordionIcon>
      </AccordionHeader>

      {isOpen && (
        <AccordionContent>
          <Content>{children}</Content>
        </AccordionContent>
      )}
    </AccordionContainer>
  );
}
