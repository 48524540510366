import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Input, RoundedPrimaryButton } from "ui-library";
import { useNavigate } from "react-router-dom";
import {
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_INSPECTORS_ROUTES,
  SEARCH_MASTER_ROUTE,
  SEARCH_REPORTS_MASTER_ROUTE,
  SEARCH_REPORTS_ROUTES,
} from "routes";
import { InspectionServicesApi, InspectionTypesApi } from "services";
import {
  setInspectionTypes,
  setServices,
} from "store/features/inspectors/inspectorsSlice";
import { useDispatch } from "react-redux";
import searchIcon from "assets/icons/search-yellow.svg";

const SuburbSearch = styled.div`
  position: relative;
  width: 100%;
  max-width: 519px;

  @media only screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const InputContainer = styled.div`
  position: relative;
  margin-left: 0;
  margin-right: auto;
  width: 100%;
  border-radius: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 14px 20px;
  box-shadow: 0px 14px 50px rgba(24, 105, 171, 0.08);

  @media only screen and (max-width: 576px) {
    padding: 8px 10px;
  }
`;

const StyledInput = styled(Input)`
  background-color: transparent;
  border: none;

  &:hover {
    background-color: transparent;
    border: none;
  }

  &:focus {
    border: none;
  }
`;

const StyledRoundedPrimaryButton = styled(RoundedPrimaryButton)`
  display: flex;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }
`;

const Dropdown = styled.div`
  top: 84px;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 14px 50px rgba(24, 105, 171, 0.08);
  overflow: hidden;
  z-index: 1;

  @media only screen and (max-width: 576px) {
    top: 72px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
`;

const Prediction = styled.div`
  height: 48px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: #e5e5e5;
  }
`;

export default function InternalSearch({ product }) {
  const [inputValue, setInputValue] = useState("");
  const [predictions, setPredictions] = useState([]);
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  let autocompleteService = useRef(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const displaySuggestions = function (predictions, status) {
    if (
      status !== window.google.maps.places.PlacesServiceStatus.OK ||
      !predictions
    ) {
      setPredictions([]);
      return;
    }

    setPredictions(predictions);
  };

  const selectPrediction = async (prediction) => {
    const geocoder = new window.google.maps.Geocoder();
    const geocodingResults = await geocoder.geocode({
      address: prediction.description,
    });
    const result = geocodingResults.results[0];

    if (result) {
      setInputValue(result.formatted_address);
      setSelectedPrediction({
        ...prediction,
        description: result.formatted_address,
      });
      setPredictions([]);
    }
  };

  const navigateToWebApp = () => {
    if (product === "reports") {
      navigateToSearchResults();
    } else {
      navigateToSearchResults();
    }
  };

  const navigateToSearchResults = () => {
    setTimeout(() => {
      if (product === "reports") {
        navigate(
          "/" +
            SEARCH_MASTER_ROUTE +
            "/" +
            SEARCH_REPORTS_MASTER_ROUTE +
            "/" +
            SEARCH_REPORTS_ROUTES.SEARCH_LIST +
            `?q=${selectedPrediction.description}`
        );
      } else {
        navigate(
          "/" +
            SEARCH_MASTER_ROUTE +
            "/" +
            SEARCH_INSPECTORS_MASTER_ROUTE +
            "/" +
            SEARCH_INSPECTORS_ROUTES.SEARCH_LIST +
            `?q=${selectedPrediction.description}`
        );
      }
    }, 1000);
  };

  useEffect(() => {
    if (autocompleteService.current && inputValue.length >= 2) {
      autocompleteService.current.getPlacePredictions(
        {
          input: inputValue,
          types: ["address"],
          componentRestrictions: {
            country: "au",
          },
        },
        displaySuggestions
      );
    } else {
      setPredictions([]);
    }
  }, [inputValue]);

  useEffect(() => {
    autocompleteService.current =
      new window.google.maps.places.AutocompleteService();

    async function fetchData() {
      try {
        const types = await InspectionTypesApi.getInspectionTypes();
        dispatch(setInspectionTypes(types));

        const services =
          await InspectionServicesApi.getListOfInspectionServices();
        dispatch(setServices(services));
      } catch (e) {}
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SuburbSearch>
      <InputContainer>
        <StyledInput
          placeholder="Type an address"
          value={inputValue}
          onChange={(e) => {
            if (selectPrediction) {
              // user is editing selected suburb
              setSelectedPrediction(null);
            }
            setInputValue(e.target?.value || "");
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 13 && selectPrediction) {
              navigateToWebApp();
            }
          }}
        />

        <StyledRoundedPrimaryButton
          disabled={!selectedPrediction}
          onClick={selectedPrediction ? navigateToWebApp : null}
        >
          <img
            src={searchIcon}
            alt="caret right"
            style={{ width: 20, height: 20 }}
          />
        </StyledRoundedPrimaryButton>
      </InputContainer>

      {predictions?.length > 0 && !selectedPrediction ? (
        <Dropdown>
          {predictions.map((prediction, index) => (
            <Prediction
              key={index}
              onClick={() => selectPrediction(prediction)}
            >
              {prediction.description}
            </Prediction>
          ))}
        </Dropdown>
      ) : (
        <noscript />
      )}
    </SuburbSearch>
  );
}
