import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import dropzonePlaceholderImage from "assets/images/dropzone-placeholder.svg";
import { P, PrimaryButton } from "ui-library";
import { useCallback, useEffect, useState } from "react";
import fileImageIcon from "assets/icons/file-image.svg";
import filePdfIcon from "assets/icons/file-pdf.svg";
import closeIcon from "assets/icons/close-red.svg";

const DropzoneContainer = styled.div`
  width: 100%;
  height: 314px;
  background: #ffffff;
  border: 2px dashed #e4f0f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.active {
    background: rgba(2, 140, 176, 0.05);
    border: 2px dashed #028cb0;
  }
`;

const FileUploadedContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background: rgba(2, 140, 176, 0.05);
`;

const FileUploadedIcon = styled.img`
  width: 40px;
  height: 40px;
`;

const ClearButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;

  img {
    width: 14px;
  }
`;

const getFileIcon = (mimeType) => {
  if (mimeType === "pdf") {
    return filePdfIcon;
  }

  return fileImageIcon;
};

/**
 * @typedef {Object} CustomDropzoneProps
 * @property {string} [className]
 * @property {string} [accept]
 * @property {(file: File) => void} onFileSelected
 */

/**
 *
 * @param {CustomDropzoneProps} props
 * @returns
 */
export default function CustomDropzone({
  className,
  accept = ".pdf",
  onFileSelected,
}) {
  /**
   * @type {[File, Dispatch<SetStateAction<File>>]} fileTrackingHook
   */
  const [file, setFile] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setFile(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    onFileSelected(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept,
  });

  if (file) {
    // show file uploaded view
    return (
      <FileUploadedContainer>
        <FileUploadedIcon src={getFileIcon(file.type)} />

        <P style={{ marginLeft: 16 }}>{file.name}</P>

        <ClearButton onClick={() => setFile()}>
          <img src={closeIcon} alt="close" />
        </ClearButton>
      </FileUploadedContainer>
    );
  }

  return (
    <DropzoneContainer
      className={`${className} ${isDragActive ? "active" : ""}`}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <img src={dropzonePlaceholderImage} alt="dropzone placeholder" />

      {isDragActive ? (
        <noscript />
      ) : (
        <>
          <P style={{ marginTop: 24 }}>
            <strong>Drop and drop your file here or</strong>
          </P>

          <PrimaryButton style={{ marginTop: 16 }}>
            Select from your computer
          </PrimaryButton>
        </>
      )}
    </DropzoneContainer>
  );
}
