/**
 * Users
 */
export const USERS_MASTER_ROUTE = "users";

export const USER_ROUTES = {
  DASHBOARD: "dashboard",
};

/**
 * Reports
 */
export const REPORTS_MASTER_ROUTE = "reports";

export const REPORT_ROUTES = {
  ADD_REPORT: "add",
  EDIT_REPORT: "edit",
};

/**
 * Inspectors
 */
export const INSPECTORS_MASTER_ROUTE = "inspectors";

export const INSPECTOR_ROUTES = {
  DASHBOARD: "dashboard",
  REVIEWS: "reviews",
  VIDEOS: "videos",
  EDIT_PROFILE: "edit-profile",
  ONBOARDING_INTRO: "onboarding/intro",
  ONBOARDING_STEP_1: "onboarding/step-1",
  ONBOARDING_STEP_2: "onboarding/step-2",
  ONBOARDING_STEP_3: "onboarding/step-3",
  ONBOARDING_PREVIEW: "onboarding/preview",

  // REPORTS
  REPORTS: REPORTS_MASTER_ROUTE,
};

/**
 * Account
 */

export const ACCOUNT_MASTER_ROUTE = "account";

export const ACCOUNT_ROUTES = {
  LOGIN: "login",
  REGISTER: "register",
  VERIFY: "verify",
  MANAGE: "manage",
  SUBSCRIPTION: "subscription",
  LOGOUT: "logout",
  FORGOT_PASSWORD: "forgot-password",
  IMPERSONATE: "impersonate",
  SOCIAL_SIGNUP: "social-signup",
};

/**
 * Public Pages
 */
export const PUBLIC_MASTER_ROUTE = "public";

export const PUBLIC_ROUTES = {
  HOME: "home",
  PROPERTY_REPORTS: "property-reports",
  AWARDS: "awards",
  FOR_INSPECTORS: "for-inspectors",
  FOR_BUYERS: "for-buyers",
  FOR_SELLERS: "for-sellers",
  FOR_AGENTS: "for-agents",
  FAQS: "faqs",
  INSPECTION_TYPES: "inspection-types",
  ABOUT: "about-us",
  PRIVACY_POLICY: "privacy-policy",
  TERMS: "terms",
  BLOG: "blog",
};

/**
 * Search
 */
export const SEARCH_MASTER_ROUTE = "search";

export const SEARCH_INSPECTORS_MASTER_ROUTE = "inspectors";

export const SEARCH_INSPECTORS_ROUTES = {
  INSPECTION_TYPES: "types",
  SEARCH_LIST: "list",
  QUOTE: "quote",
  RATE_INSPECTOR: "rate",
};

export const SEARCH_REPORTS_MASTER_ROUTE = "reports";

export const SEARCH_REPORTS_ROUTES = {
  SEARCH_LIST: "list",
  PURCHASE_REPORT: "purchase",
  PURCHASE_CONFIRMATION: "purchase-confirmation",
};

export const SEARCH_ACCOUNTS_MASTER_ROUTE = "accounts";

export const SEARCH_ACCOUNTS_ROUTES = {
  FIND: "find",
  CLAIM: "claim",
  VERIFY: "verify",
};

export const SEARCH_QUOTES_MASTER_ROUTE = "quotes";

export const SEARCH_QUOTES_ROUTES = {
  REQUEST: "request",
};
