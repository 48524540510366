import RMICountryIcon from "components/public-pages/icons/rmi-country";
import RMIRegionIcon from "components/public-pages/icons/rmi-region";
import RMIStateIcon from "components/public-pages/icons/rmi-state";

export const faqsSections = [
  {
    title: "Can any inspector or inspection company enter the awards?",
    excerpt:
      "No you must be a paid subscriber to Rate My Inspectors at the time of awards. These awards are open to Australian inspectors only.",
  },
  {
    title: "How many Reviews do I need to enter?",
    excerpt:
      "Six reviews is the minimum number. Although the more 5 star reviews you have the more likely you are to win.",
  },
  {
    title: "How much does it cost to enter?",
    excerpt:
      "Entry is free and automatic for Rate My Inspectors inspectors and companies on a paid subscription.",
  },
];

export const images = [<RMIRegionIcon />, <RMIStateIcon />, <RMICountryIcon />];
