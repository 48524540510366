import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import InspectorEditProfile from "./pages/edit-profile";
import InspectorDashboard from "./pages/dashboard";
import InspectorReviews from "./pages/reviews";
import InspectorVideos from "./pages/videos";
import { INSPECTOR_ROUTES, REPORT_ROUTES } from "routes";
import InspectorAddReport from "./pages/reports/pages/add-report";
import InspectorEditReport from "./pages/reports/pages/edit-report";
import InspectorReports from "./pages/reports/pages/reports";
import { RequireAuth, useAuth } from "common/authentication";
import { useEffect } from "react";
import {
  CompaniesApi,
  InspectionServicesApi,
  InspectionTypesApi,
  InspectorsApi,
  PaymentsApi,
} from "services";
import { useDispatch, useSelector } from "react-redux";
import {
  inspectorSelector,
  setCurrentInspector,
  setInspectionTypes,
  setServices,
  setStatistics,
} from "store/features/inspectors/inspectorsSlice";
import { setCurrentCompany } from "store/features/companies/companiesSlice";
import { setPaymentsConfig } from "store/features/subscription/subscriptionSlice";
import { Loader } from "components";
import OnboardingIntroduction from "./pages/onboarding/introduction";
import OnboardingStep1 from "./pages/onboarding/step-1";
import OnboardingStep2 from "./pages/onboarding/step-2";
import OnboardingStep3 from "./pages/onboarding/step-3";
import OnboardingPreview from "./pages/onboarding/preview";

export default function Inspectors() {
  const { session } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inspector = useSelector(inspectorSelector);

  useEffect(() => {
    if (session?.roles.indexOf("CompanyAdmin") > -1) {
      async function fetchData() {
        try {
          // profile
          const inspectorProfile =
            await InspectorsApi.getCurrentInspectorProfile();
          dispatch(setCurrentInspector(inspectorProfile));

          // company
          const company = await CompaniesApi.getCurrentCompany();
          dispatch(setCurrentCompany(company));

          // inspection types
          const inspectionTypes = await InspectionTypesApi.getInspectionTypes();
          dispatch(setInspectionTypes(inspectionTypes));

          // inspection services
          const inspectionServices =
            await InspectionServicesApi.getListOfInspectionServices();
          dispatch(setServices(inspectionServices));

          // stats
          const stats = await InspectorsApi.fetchStatistics();
          dispatch(setStatistics(stats));

          // payment config
          const paymentsConfig = await PaymentsApi.getInfo();
          dispatch(setPaymentsConfig(paymentsConfig));
        } catch (e) {}
      }
      fetchData();
    } else {
      navigate("/");
    }
  }, [dispatch, navigate, session?.roles]);

  if (!inspector) {
    return (
      <div style={{ textAlign: "center", marginTop: 32 }}>
        <Loader color="#feba00" size="large" loading />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path={INSPECTOR_ROUTES.ONBOARDING_INTRO}
        element={
          <RequireAuth>
            <OnboardingIntroduction />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.ONBOARDING_STEP_1}
        element={
          <RequireAuth>
            <OnboardingStep1 />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.ONBOARDING_STEP_2}
        element={
          <RequireAuth>
            <OnboardingStep2 />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.ONBOARDING_STEP_3}
        element={
          <RequireAuth>
            <OnboardingStep3 />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.ONBOARDING_PREVIEW}
        element={
          <RequireAuth>
            <OnboardingPreview />
          </RequireAuth>
        }
      />

      <Route
        path={INSPECTOR_ROUTES.DASHBOARD}
        element={
          <RequireAuth>
            <InspectorDashboard />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.REVIEWS}
        element={
          <RequireAuth>
            <InspectorReviews />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.VIDEOS}
        element={
          <RequireAuth>
            <InspectorVideos />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.EDIT_PROFILE}
        element={
          <RequireAuth>
            <InspectorEditProfile />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.REPORTS}
        element={
          <RequireAuth>
            <InspectorReports />
          </RequireAuth>
        }
      />
      <Route
        path={INSPECTOR_ROUTES.REPORTS + "/" + REPORT_ROUTES.ADD_REPORT}
        element={
          <RequireAuth>
            <InspectorAddReport />
          </RequireAuth>
        }
      />
      <Route
        path={
          INSPECTOR_ROUTES.REPORTS +
          "/" +
          REPORT_ROUTES.EDIT_REPORT +
          "/:reportId"
        }
        element={
          <RequireAuth>
            <InspectorEditReport />
          </RequireAuth>
        }
      />

      <Route
        path="*"
        element={<Navigate to={INSPECTOR_ROUTES.DASHBOARD} replace />}
      />
    </Routes>
  );
}
