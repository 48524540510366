import styled from "styled-components";
import { H5 } from "ui-library";
import backIcon from "assets/icons/back.svg";
import { useNavigate } from "react-router-dom";

const BackButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export function BackButton({ title = "Back", onClick }) {
  const navigate = useNavigate();

  const onBackButtonClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <BackButtonContainer onClick={onBackButtonClick}>
      <img src={backIcon} alt="back" width={14} />
      <H5 style={{ marginLeft: 8, color: "#028CB0" }}>{title}</H5>
    </BackButtonContainer>
  );
}
