export default function Envelope() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.9"
        d="M31.7959 24.2439L41.7659 34.2139L40.3509 35.6279L30.3809 25.6579L31.7959 24.2439Z"
        fill="#798995"
      />
      <path
        opacity="0.9"
        d="M18.2779 24.2871L19.6919 25.7011L9.79193 35.6011L8.37793 34.1911L18.2779 24.2871Z"
        fill="#798995"
      />
      <path
        d="M24.9998 29.9C23.4998 29.9 21.8998 29.3 20.7998 28.1L8.2998 15.7L9.6998 14.3L22.1998 26.8C23.7998 28.4 26.2998 28.4 27.8998 26.8L40.3998 14.3L41.7998 15.7L29.1998 28.2C28.0998 29.3 26.4998 29.9 24.9998 29.9Z"
        fill="#798995"
      />
      <path
        d="M39 38H11C9.3 38 8 36.7 8 35V15C8 13.3 9.3 12 11 12H39C40.7 12 42 13.3 42 15V35C42 36.7 40.7 38 39 38ZM11 14C10.4 14 10 14.4 10 15V35C10 35.6 10.4 36 11 36H39C39.6 36 40 35.6 40 35V15C40 14.4 39.6 14 39 14H11Z"
        fill="#798995"
      />
    </svg>
  );
}
