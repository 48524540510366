import { Col, Container, Row } from "react-grid-system";
import styled from "styled-components";
import { H2, Page } from "ui-library";
import { PurchasedReports } from "../components/reports";
import { RequestedQuotes } from "../components/quotes";
import { PostedReviews } from "../components/reviews";
import { DocumentTitle } from "components/document-title";
import { Tabs } from "components";
import { useAuth } from "common/authentication";
import { useSelector } from "react-redux";
import {
  selectCustomerReviews,
  selectPurchasedReports,
  selectRequestedQuotes,
} from "store/features/customers/customersSlice";
import { useSearchParams } from "react-router-dom";

const Content = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  padding-bottom: 64px;
  padding-top: 72px;

  @media only screen and (max-width: 576px) {
    padding-top: 0;
  }
`;

export default function UserDashboard() {
  const { user: userAccount } = useAuth();
  const quotes = useSelector(selectRequestedQuotes);
  const reports = useSelector(selectPurchasedReports);
  const reviews = useSelector(selectCustomerReviews);
  const [params] = useSearchParams();
  const startingView = params?.get("v");

  const defaultTabIndex =
    startingView === "reviews"
      ? 2
      : startingView === "reports"
      ? 0
      : startingView === "quotes"
      ? 1
      : undefined;

  return (
    <DocumentTitle title="Dashboard">
      <Page>
        <Content>
          <Container>
            <Row>
              <Col xs={12}>
                <H2>Hi {userAccount.firstName},</H2>
              </Col>

              <Col xs={12} style={{ paddingTop: 24 }}>
                <Tabs
                  defaultTabIndex={defaultTabIndex}
                  tabs={[
                    {
                      title: "Reports purchased",
                      content: <PurchasedReports reports={reports} />,
                    },
                    {
                      title: "Requested quotes",
                      content: <RequestedQuotes quotes={quotes} />,
                    },
                    {
                      title: "Reviews left",
                      content: <PostedReviews reviews={reviews} />,
                    },
                  ]}
                />
              </Col>
            </Row>
          </Container>
        </Content>
      </Page>
    </DocumentTitle>
  );
}
