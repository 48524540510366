import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import styled from "styled-components";
import { BlogsApi } from "services/blog";
import { InternalA, P1 } from "ui-library";
import YouTube from "react-youtube";

const ArticleTileWrapper = styled.div`
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  img {
    max-width: 100%;
  }

  h2 {
    margin-bottom: 16px;
  }

  button {
    background: none;
    cursor: pointer;
    border: 0;
    color: #028cb0;
    margin-top: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5em;
    text-decoration: none;
    display: inline;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ArticleTile = ({
  id,
  title,
  heroImageUrl = "https://images.unsplash.com/photo-1655432960599-931eeab4c735?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80",
  heroVideoUrl,
  content,
  updatedOn,
}) => {
  return (
    <ArticleTileWrapper>
      {heroVideoUrl && (
        <YouTube
          opts={{ width: "100%" }}
          videoId={heroVideoUrl.split("/").pop()}
        />
      )}

      {!heroVideoUrl && (
        <img
          src={
            heroImageUrl
              ? `${heroImageUrl}?w=1200`
              : "https://images.unsplash.com/photo-1655432960599-931eeab4c735?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80"
          }
          alt="hero"
        />
      )}
      <div>
        <p className="date">{format(new Date(updatedOn), "dd-MM-yyyy")}</p>
        <h2>{title}</h2>
        <P1
          dangerouslySetInnerHTML={{
            __html: `${content.split("<br>")[0]}`,
          }}
        />
        {content.length > 50 && (
          <InternalA to={`/public/blog/${id}`}>Read more</InternalA>
        )}
      </div>
    </ArticleTileWrapper>
  );
};

const Wrapper = styled.div`
  width: 1280px;
  margin: 0 auto;
  max-width: 100%;
  margin-top: 40px;
  padding: 0 18px;
`;

export default function BlogPage() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function fetchBlogs() {
      try {
        const response = await BlogsApi.getBlogs({
          pageNumber: 1,
          pageSize: 10,
        });
        setBlogs(response.data);
      } catch (e) {}
    }
    fetchBlogs();
  }, []);

  return (
    <Wrapper>
      {blogs.map((article) => {
        return <ArticleTile key={article.id} {...article} />;
      })}
    </Wrapper>
  );
}
