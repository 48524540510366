import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  session: null,
  account: null,
  redirectUrl: null,
  isInspector: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSession: (state, action) => {
      state.session = action.payload;
    },
    updateUser: (state, action) => {
      state.account = {
        ...state.account,
        ...action.payload,
      };
    },
    clearSession: () => {
      return initialState;
    },
    setRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload;
    },
    clearRedirectUrl: (state) => {
      state.redirectUrl = null;
    },
    setIsInspector: (state, action) => {
      state.isInspector = action.payload;
    },
    clearIsInspector: (state) => {
      state.isInspector = null;
    },
  },
});

// Actions
export const {
  setSession,
  updateUser,
  clearSession,
  clearRedirectUrl,
  setRedirectUrl,
  clearIsInspector,
  setIsInspector,
} = userSlice.actions;

/**
 *
 * @param {*} state
 * @returns {UserSession}
 */
export const selectSession = (state) => state.user.session;

/**
 *
 * @param {*} state
 * @returns {import("services").User}
 */
export const selectAccount = (state) => state.user.account;

/**
 *
 * @param {*} state
 * @returns {string | null}
 */
export const selectRedirectUrl = (state) => state.user.redirectUrl;

/**
 *
 * @param {*} state
 * @returns {boolean | null}
 */
export const selectIsInspector = (state) => state.user.isInspector;

export default userSlice.reducer;

export { initialState as userSliceInitialState };
