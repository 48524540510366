export default function BubbleIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#E7F2F6"
      />
      <path
        d="M24.356 42.788C24.5636 42.9964 24.7227 43.248 24.8219 43.5249C24.9211 43.8018 24.958 44.0972 24.93 44.39C24.7894 45.7452 24.523 47.0843 24.134 48.39C26.924 47.744 28.628 46.996 29.402 46.604C29.841 46.3816 30.3466 46.329 30.822 46.456C32.1849 46.8193 33.5895 47.0022 35 47C42.992 47 49 41.386 49 35C49 28.616 42.992 23 35 23C27.008 23 21 28.616 21 35C21 37.936 22.234 40.66 24.356 42.788ZM23.37 50.598C22.8961 50.6919 22.4207 50.778 21.944 50.856C21.544 50.92 21.24 50.504 21.398 50.132C21.5756 49.7132 21.7384 49.2883 21.886 48.858L21.892 48.838C22.388 47.398 22.792 45.742 22.94 44.2C20.486 41.74 19 38.52 19 35C19 27.268 26.164 21 35 21C43.836 21 51 27.268 51 35C51 42.732 43.836 49 35 49C33.4153 49.0021 31.8372 48.7964 30.306 48.388C29.266 48.914 27.028 49.872 23.37 50.598Z"
        fill="#028CB0"
      />
      <path
        d="M33.132 32.52C32.7425 31.9133 32.1665 31.4494 31.4907 31.1981C30.8149 30.9468 30.0758 30.9216 29.3844 31.1263C28.6931 31.3311 28.0869 31.7547 27.6569 32.3335C27.2269 32.9122 26.9964 33.615 27 34.336C27.0004 34.933 27.1611 35.519 27.4653 36.0328C27.7696 36.5465 28.2062 36.9692 28.7296 37.2565C29.2529 37.5438 29.8439 37.6853 30.4406 37.6663C31.0374 37.6472 31.6181 37.4682 32.122 37.148C31.86 37.926 31.372 38.756 30.568 39.588C30.4142 39.7471 30.3299 39.9608 30.3337 40.1821C30.3355 40.2917 30.3589 40.3998 30.4026 40.5003C30.4462 40.6009 30.5092 40.6918 30.588 40.768C30.6668 40.8441 30.7599 40.904 30.8618 40.9442C30.9637 40.9845 31.0726 41.0042 31.1822 41.0023C31.4035 40.9986 31.6142 40.9071 31.768 40.748C34.74 37.668 34.354 34.32 33.132 32.524V32.52ZM41.132 32.52C40.7425 31.9133 40.1665 31.4494 39.4907 31.1981C38.8149 30.9468 38.0757 30.9216 37.3844 31.1263C36.6931 31.3311 36.0869 31.7547 35.6569 32.3335C35.2269 32.9122 34.9964 33.615 35 34.336C35.0004 34.933 35.1611 35.519 35.4653 36.0328C35.7696 36.5465 36.2062 36.9692 36.7296 37.2565C37.2529 37.5438 37.8439 37.6853 38.4406 37.6663C39.0374 37.6472 39.6181 37.4682 40.122 37.148C39.86 37.926 39.372 38.756 38.568 39.588C38.4919 39.6668 38.432 39.7598 38.3918 39.8617C38.3515 39.9637 38.3318 40.0725 38.3337 40.1821C38.3355 40.2917 38.3589 40.3998 38.4026 40.5003C38.4462 40.6009 38.5092 40.6918 38.588 40.768C38.6668 40.8441 38.7599 40.904 38.8618 40.9442C38.9637 40.9845 39.0726 41.0042 39.1822 41.0023C39.2918 41.0005 39.3999 40.9771 39.5004 40.9334C39.6009 40.8898 39.6919 40.8268 39.768 40.748C42.74 37.668 42.354 34.32 41.132 32.524V32.52Z"
        fill="#028CB0"
      />
    </svg>
  );
}
