import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import uploadVideoPlaceholder from "assets/icons/upload-video.svg";
import { P, PrimaryButton } from "ui-library";
import closeIcon from "assets/icons/close.svg";

const UploadArea = styled.div`
  background: #ffffff;
  box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
  border-radius: 32px;
  padding: 32px;
  margin-top: 48px;
`;

const DropzoneContainer = styled.div`
  position: relative;
  border-radius: 10px;
  border: 2px dashed #e4f0f7;
  padding: 0 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  transition: all 150ms linear;

  &.active {
    background-color: #e4f0f7;
  }
`;

const Close = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  justify-content: center;
  cursor: pointer;
`;

export function FullWidthUploader({
  onCancel,
  title,
  action = "Select from your computer",
  onFilesAdded,
}) {
  const onDrop = useCallback(onFilesAdded, [onFilesAdded]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <UploadArea {...getRootProps()}>
      <DropzoneContainer className={isDragActive ? "active" : ""}>
        <input {...getInputProps()} />
        <img src={uploadVideoPlaceholder} alt="placeholder" />
        <P style={{ marginTop: 24, color: "#143A5A" }}>
          <strong>{title}</strong>
        </P>
        <PrimaryButton style={{ marginTop: 16 }}>{action}</PrimaryButton>

        <Close
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCancel();
          }}
        >
          <img src={closeIcon} alt="close" />
        </Close>
      </DropzoneContainer>
    </UploadArea>
  );
}
