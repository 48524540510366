import { api } from "./api";

/**
 * @typedef {Object} InspectionQuote
 * @property {import("./inspectors").InspectorProfileSummary} inspector
 * @property {string} name
 * @property {string} phone
 * @property {string} address
 * @property {string[]} preferredTimes
 * @property {number} buildingType
 * @property {string} buildingTypeOther
 * @property {number} inspectionTypeId
 * @property {string} inspectionTypeOther
 * @property {string} message
 */

export const InspectionQuotesApi = {
  /**
   *
   * @param {Object} data
   * @param {number[]} data.inspectorIds
   * @param {string} data.name
   * @param {string} data.phone
   * @param {string} data.address
   * @param {any} data.point
   * @param {string[]} data.preferredTimes
   * @param {string} data.buildingType
   * @param {string} data.buildingTypeOther
   * @param {number} data.inspectionTypeId
   * @param {string} data.message
   * @returns
   */
  submitQuoteRequest: async (data) => api.post("/InspectionQuotes", data),

  /**
   *
   * @returns {Promise<InspectionQuote[]>}
   */
  getQuotesRequestedByCurrentCustomer: async () => api.get("/InspectionQuotes"),

  /**
   *
   * @param {string} code
   * @returns {Promise<InspectionQuote>}
   */
  getQuoteByCode: async (code) => api.get(`/InspectionQuotes/${code}`),
};
