const QualityIcon = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#E7F2F6"
      />
      <path
        d="M46.6044 30.3447L39.166 29.2637L35.8408 22.5225C35.75 22.3379 35.6005 22.1885 35.416 22.0977C34.9531 21.8692 34.3906 22.0596 34.1591 22.5225L30.8339 29.2637L23.3955 30.3447C23.1904 30.374 23.0029 30.4707 22.8593 30.6172C22.6858 30.7956 22.5901 31.0356 22.5934 31.2844C22.5967 31.5333 22.6987 31.7706 22.8769 31.9443L28.2587 37.1914L26.9873 44.6006C26.9574 44.773 26.9765 44.9502 27.0423 45.1123C27.1081 45.2743 27.218 45.4147 27.3595 45.5175C27.5011 45.6203 27.6686 45.6814 27.843 45.6938C28.0175 45.7063 28.192 45.6696 28.3466 45.5879L35 42.0899L41.6533 45.5879C41.8349 45.6846 42.0459 45.7168 42.248 45.6817C42.7578 45.5938 43.1005 45.1104 43.0127 44.6006L41.7412 37.1914L47.123 31.9443C47.2695 31.8008 47.3662 31.6133 47.3955 31.4082C47.4746 30.8955 47.1171 30.4209 46.6044 30.3447ZM39.4765 36.4531L40.5341 42.6143L35 39.708L29.4658 42.6172L30.5234 36.4561L26.0468 32.0908L32.2343 31.1914L35 25.5869L37.7656 31.1914L43.9531 32.0908L39.4765 36.4531Z"
        fill="#028CB0"
      />
    </svg>
  );
};

export default QualityIcon;
