import { useEffect, useState } from "react";
import styled from "styled-components";
import { InspectorProfile, Loader, Modal, Review } from "components";
import { Page } from "ui-library";
import { useParams, useSearchParams } from "react-router-dom";
import {
  InspectionReportsApi,
  InspectionServicesApi,
  InspectionTypesApi,
  InspectorReviewsApi,
  InspectorsApi,
  InspectorVideosApi,
} from "services";
import { useDispatch, useSelector } from "react-redux";
import { DocumentTitle } from "components/document-title";
import {
  inspectionTypesSelector,
  servicesSelector,
  setInspectionTypes,
  setServices,
} from "store/features/inspectors/inspectorsSlice";

const StyledInspectorProfile = styled(InspectorProfile)`
  margin-top: 40px;
  margin-bottom: 80px;
`;

export default function PublicInspectorProfile() {
  const dispatch = useDispatch();
  const { inspectorCode } = useParams();
  const [params] = useSearchParams();
  const reviewCode = params?.get("reviewCode");
  const [inspector, setInspector] = useState(null);
  const [reviewToDisplay, setReviewToDisplay] = useState(null);
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const keyedInspectionTypes = inspectionTypes.reduce(
    (reduction, inspectionType) => {
      reduction[inspectionType.id] = inspectionType;
      return reduction;
    },
    {}
  );
  const inspectionServices = useSelector(servicesSelector);
  const keyedInspectionServices = inspectionServices.reduce(
    (reduction, inspectionService) => {
      reduction[inspectionService.id] = inspectionService;
      return reduction;
    },
    {}
  );

  const inspectionTypeNames = inspector?.inspectionTypeIds.map(
    (inspectionTypeId) => {
      const inspectionType = keyedInspectionTypes[inspectionTypeId];
      return inspectionType.name;
    }
  );

  const removeQueryParamFromPath = () => {};

  useEffect(() => {
    async function fetchRequiredInfo() {
      try {
        const inspectorResponse = await InspectorsApi.getInspectorProfileByCode(
          inspectorCode
        );

        const inspectionTypesResponse =
          await InspectionTypesApi.getInspectionTypes();
        dispatch(setInspectionTypes(inspectionTypesResponse));

        const inspectionServicesResponse =
          await InspectionServicesApi.getListOfInspectionServices();
        dispatch(setServices(inspectionServicesResponse));

        const reviewsResponse =
          await InspectorReviewsApi.getReviewsForInspectorWithId(
            inspectorResponse.id
          );
        if (reviewCode) {
          const filteredReview = reviewsResponse.data.find(
            (review) => review.code === reviewCode
          );
          setReviewToDisplay(filteredReview);
        }

        const reportsResponse =
          await InspectionReportsApi.getInspectionReportsForInspectorWithId(
            inspectorResponse.id
          );

        const videosResponse =
          await InspectorVideosApi.getVideosForInspectorWithId(
            inspectorResponse.id
          );

        const averageRating =
          reviewsResponse.data.length > 0
            ? Number(
                (
                  reviewsResponse.data.reduce((reduction, review) => {
                    reduction += review.stars;
                    return reduction;
                  }, 0) / reviewsResponse.data.length
                ).toFixed(2)
              )
            : 0;

        const inspector = {
          id: inspectorResponse.id,
          code: inspectorResponse.code,
          profile: inspectorResponse.profile,
          company: inspectorResponse.company,
          inspectionTypeIds: inspectorResponse.inspectionTypeIds,
          reportCount: reportsResponse.length,
          videoCount: videosResponse.length,
          reviews: {
            average: averageRating,
            count: reviewsResponse.data.length,
          },
        };

        setInspector(inspector);
      } catch (e) {}
    }
    fetchRequiredInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectorCode]);

  if (!inspector) {
    return (
      <div style={{ textAlign: "center", marginTop: 32 }}>
        <Loader color="#feba00" size="large" loading />
      </div>
    );
  }

  return (
    <DocumentTitle title="Inspector">
      <Page>
        <StyledInspectorProfile
          inspector={inspector}
          types={keyedInspectionTypes}
          services={keyedInspectionServices}
          isSelectable={false}
          alwaysExpanded
        />

        <Modal
          title={inspector.company?.name || inspector.profile.fullName}
          subtitle={
            inspectionTypeNames?.length > 1
              ? inspectionTypeNames[0] +
                ` + ${inspectionTypeNames.length - 1} more`
              : inspectionTypeNames?.[0]
          }
          headerImage={
            inspector.company?.logoPath || inspector.profile.imagePath
          }
          separateHeader
          size="medium"
          isOpen={!!reviewCode}
          onAfterClose={removeQueryParamFromPath}
        >
          <Review
            inspectorCode={inspectorCode}
            review={reviewToDisplay}
            canShare={false}
          />
        </Modal>
      </Page>
    </DocumentTitle>
  );
}
