import { Accordion, Input } from "components";
import { Form } from "react-final-form";
import styled from "styled-components";
import circleUncheckedIcon from "assets/icons/circle-unchecked.svg";
import { P, PrimaryButton, TertiaryButton } from "ui-library";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FieldArray } from "react-final-form-arrays";
import closeIcon from "assets/icons/close-red.svg";
import arrayMutators from "final-form-arrays";
import { displayErrorMessage } from "services/api";
import { InspectorsApi } from "services";
import { updateCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import { requiredValidator } from "components/form/validators";
import Toast from "components/toast";

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
`;

const LicenseContainer = styled.div`
  padding: 16px 16px 32px;
  margin-bottom: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #e4f0f7;
`;

const LicenseTitle = styled.div`
  display: flex;
  align-items: center;
`;

const InputContainer = styled.div`
  width: 224px;
`;

const DeleteButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;

  &:hover {
    background-color: #f7ebec;
  }
`;

export function InspectorLicenseWidget({ inspector }) {
  const dispatch = useDispatch();

  if (inspector.licenses?.length > 0) {
    return <noscript />;
  }

  return (
    <Form
      initialValues={{
        licenses: [{}],
      }}
      mutators={arrayMutators}
      onSubmit={async (values) => {
        try {
          await InspectorsApi.updateCurrentInspector(values);
          const updatedInspector =
            await InspectorsApi.getCurrentInspectorProfile();
          dispatch(updateCurrentInspector(updatedInspector));

          toast.success(<Toast title="Inspection license(s) added" />);
        } catch (e) {
          displayErrorMessage(e);
        }
      }}
      render={({ handleSubmit }) => (
        <StyledAccordion icon={circleUncheckedIcon} title="Inspection license">
          <FieldArray name="licenses">
            {({ fields }) => {
              return (
                <>
                  {fields.map((name, index) => (
                    <LicenseContainer key={name}>
                      <LicenseTitle>
                        <P style={{ fontWeight: 700, color: "#143A5A" }}>
                          {index === 0 ? <noscript /> : `License ${index + 1}`}
                        </P>

                        <DeleteButton onClick={() => fields.remove(index)}>
                          <img src={closeIcon} alt="close" width={14} />
                        </DeleteButton>
                      </LicenseTitle>

                      <InputContainer>
                        <Input
                          name={`${name}.number`}
                          label="License number (if applicable)"
                          autocomplete="false"
                        />
                      </InputContainer>

                      <InputContainer>
                        <Input
                          name={`${name}.issuingAuthority`}
                          label="Name of license issuing authority"
                          autocomplete="false"
                          validate={requiredValidator}
                        />
                      </InputContainer>

                      <InputContainer>
                        <Input
                          name={`${name}.type`}
                          label="Type of license (optional)"
                          autocomplete="false"
                        />
                      </InputContainer>

                      <InputContainer>
                        <Input
                          name={`${name}.link`}
                          label="Link to license (optional)"
                          style={{
                            marginBottom: 16,
                          }}
                          autocomplete="false"
                        />
                      </InputContainer>
                    </LicenseContainer>
                  ))}

                  <TertiaryButton onClick={() => fields.push({})}>
                    Add license
                  </TertiaryButton>

                  {fields.length > 0 ? (
                    <PrimaryButton
                      style={{ marginTop: 24 }}
                      onClick={handleSubmit}
                    >
                      Save
                    </PrimaryButton>
                  ) : (
                    <noscript />
                  )}
                </>
              );
            }}
          </FieldArray>
        </StyledAccordion>
      )}
    />
  );
}
