import { useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "helpers";
import { usePopper } from "react-popper";
import { Hidden, Visible } from "react-grid-system";

const Trigger = styled.div`
  cursor: pointer;
`;

const Content = styled.div``;

export function More({ trigger, children, useInlineForMobile = false }) {
  // Create a ref that we add to the element for which we want to detect outside clicks
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 16],
        },
      },
    ],
  });

  useOnClickOutside(popperElement, () => setIsMenuOpen(false));

  if (useInlineForMobile) {
    return (
      <>
        <Trigger
          ref={setReferenceElement}
          onClick={() => setIsMenuOpen((_) => !_)}
        >
          {trigger}
        </Trigger>

        {isMenuOpen ? (
          <>
            <Hidden xs>
              <Content
                ref={setPopperElement}
                style={{
                  ...styles.popper,
                  zIndex: 10,
                }}
                {...attributes.popper}
                onClick={() => setIsMenuOpen(false)}
              >
                {children}
              </Content>
            </Hidden>

            <Visible xs>
              <Content>{children}</Content>
            </Visible>
          </>
        ) : (
          <noscript />
        )}
      </>
    );
  }

  return (
    <>
      <Trigger
        ref={setReferenceElement}
        onClick={() => setIsMenuOpen((_) => !_)}
      >
        {trigger}
      </Trigger>

      {isMenuOpen ? (
        <>
          <Content
            ref={setPopperElement}
            style={{
              ...styles.popper,
              zIndex: 10,
            }}
            {...attributes.popper}
            onClick={() => setIsMenuOpen(false)}
          >
            {children}
          </Content>
        </>
      ) : (
        <noscript />
      )}
    </>
  );
}

export const MoreMenuContent = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 5px 12px rgba(24, 105, 171, 0.15);
  border-radius: 8px;
  padding: 8px;

  @media only screen and (max-width: 576px) {
    box-shadow: none;
    background-color: #f5fafd;
  }
`;

export const MoreMenuItem = styled.div`
  height: 40px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;

  &:hover {
    background-color: #efefef;
    cursor: pointer;
  }
`;
