import { useEffect } from "react";
import { H2, PrimaryButton, P, H3 } from "ui-library";
import uploadIcon from "assets/icons/upload.svg";
import styled from "styled-components";
import { Input, Loader, Modal, VideoThumbnail } from "components";
import { Col, Container, Row } from "react-grid-system";
import { Form } from "react-final-form";
import { InspectorVideosApi } from "services";
import { useDispatch, useSelector } from "react-redux";
import {
  inspectorVideosSelector,
  setVideos,
} from "store/features/inspectors/inspectorsSlice";
import { DocumentTitle } from "components/document-title";
import { requiredValidator, videoValidator } from "components/form/validators";
import { paymentsConfigSelector } from "store/features/subscription/subscriptionSlice";
import Subscribe from "account/components/subscribe";
import image from "assets/images/videos-subscription-guard.png";

const UploadIcon = styled.img`
  margin-right: 16px;
  width: 18px;
`;

const ExistingVideos = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
`;

const SubscriptionRequiredGuard = styled.div`
  background: #f5fafd;
  border-radius: 20px;
  margin: 44px auto;
  padding: 59px 52px 58px 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1037px;
  width: 100%;

  @media only screen and (max-width: 576px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    max-width: calc(100% - 32px);
    padding: 32px;
    margin-top: 0px;
  }
`;

const SubscriptionRequiredGuardContent = styled.div`
  @media only screen and (max-width: 576px) {
    margin-top: 32px;
  }
`;

const SubscriptionRequiredGuardTitle = styled(H3)`
  max-width: 388px;
  line-height: 40px;
`;

const SubscriptionRequiredGuardDescription = styled(P)`
  max-width: 404px;
  font-size: 20px;
  line-height: 34px;
`;

const SubscriptionRequiredGuardImage = styled.img`
  max-width: 498px;

  @media only screen and (max-width: 576px) {
    max-width: 100%;
  }
`;

export default function InspectorVideosPage() {
  const dispatch = useDispatch();
  const videos = useSelector(inspectorVideosSelector);
  const paymentConfig = useSelector(paymentsConfigSelector);
  const hasValidSubscription = paymentConfig?.planStatus === "Active";

  const fetchVideos = async () => {
    try {
      const response = await InspectorVideosApi.getVideosForCurrentInspector();
      dispatch(setVideos(response));
    } catch (e) {}
  };

  useEffect(() => {
    fetchVideos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasValidSubscription) {
    return (
      <DocumentTitle title="Reviews">
        <SubscriptionRequiredGuard>
          <SubscriptionRequiredGuardContent>
            <SubscriptionRequiredGuardTitle>
              Show how you work
            </SubscriptionRequiredGuardTitle>
            <SubscriptionRequiredGuardDescription
              style={{ marginTop: 16, maxWidth: 404 }}
            >
              Start sharing videos of your work to show potential clients what
              they can expect when booking you.
            </SubscriptionRequiredGuardDescription>

            <Subscribe
              trigger={
                <PrimaryButton style={{ marginTop: 34 }}>
                  Activate subscription
                </PrimaryButton>
              }
            />
          </SubscriptionRequiredGuardContent>

          <SubscriptionRequiredGuardImage src={image} />
        </SubscriptionRequiredGuard>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title="Videos">
      <Container>
        <Row>
          <Col xs={12}>
            <div
              className="flex justify-between align-center"
              style={{ marginTop: 72 }}
            >
              <H2>Videos</H2>

              <Modal
                title="Upload a video"
                size="small"
                trigger={
                  <PrimaryButton>
                    <UploadIcon src={uploadIcon} alt="upload" />
                    Upload a video
                  </PrimaryButton>
                }
              >
                {(closeModal) => (
                  <Form
                    onSubmit={async (values) => {
                      try {
                        await InspectorVideosApi.addVideoForCurrentInspector(
                          values
                        );
                        await fetchVideos();
                        closeModal();
                      } catch (e) {}
                    }}
                    render={({ handleSubmit, submitting }) => (
                      <>
                        <Input
                          name="title"
                          label="Video title"
                          validate={requiredValidator}
                        />

                        <Input
                          name="link"
                          label="Video URL"
                          type="url"
                          validate={videoValidator}
                        />

                        <PrimaryButton
                          style={{ width: "100%", marginTop: 32 }}
                          onClick={submitting ? null : handleSubmit}
                        >
                          {submitting ? <Loader loading /> : "Add video"}
                        </PrimaryButton>
                      </>
                    )}
                  />
                )}
              </Modal>
            </div>

            <P style={{ marginTop: 16 }}>
              Videos will be made available on your profile. Show potential
              clients what they can expect when booking you.
            </P>

            {/* Existing Videos */}
            <ExistingVideos>
              {videos?.map((video) => (
                <VideoThumbnail key={video.id} video={video} />
              ))}
              <div></div>
            </ExistingVideos>
          </Col>
        </Row>
      </Container>
    </DocumentTitle>
  );
}
