export default function TypesIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#E7F2F6"
      />
      <path
        d="M46 22H38V21C38 19.3477 36.6523 18 35 18C33.3477 18 32 19.3477 32 21V22H24C23.4492 22 23 22.4492 23 23V51C23 51.5508 23.4492 52 24 52H46C46.5508 52 47 51.5508 47 51V23C47 22.4492 46.5508 22 46 22ZM33 24C33.5508 24 34 23.5508 34 23V21C34 20.4492 34.4492 20 35 20C35.5508 20 36 20.4492 36 21V23C36 23.5508 36.4492 24 37 24H39V26H31V24H33ZM45 50H25V24H29V27C29 27.5508 29.4492 28 30 28H40C40.5508 28 41 27.5508 41 27V24H45V50ZM33 41H28C27.4492 41 27 41.4492 27 42V47C27 47.5508 27.4492 48 28 48H33C33.5508 48 34 47.5508 34 47V42C34 41.4492 33.5508 41 33 41ZM32 46H29V43H32V46ZM42 41H37C36.4492 41 36 41.4492 36 42V47C36 47.5508 36.4492 48 37 48H42C42.5508 48 43 47.5508 43 47V42C43 41.4492 42.5508 41 42 41ZM41 46H38V43H41V46ZM33 32H28C27.4492 32 27 32.4492 27 33V38C27 38.5508 27.4492 39 28 39H33C33.5508 39 34 38.5508 34 38V33C34 32.4492 33.5508 32 33 32ZM32 37H29V34H32V37ZM41.293 33.293C41.6836 32.9024 42.3164 32.9024 42.7071 33.293C43.0978 33.6836 43.0977 34.3164 42.7071 34.7071L39.7071 37.7071C39.5118 37.9024 39.2579 38.0001 39.0001 38.0001C38.7423 38.0001 38.4884 37.9024 38.293 37.7071L36.293 35.7071C35.9024 35.3165 35.9024 34.6837 36.293 34.293C36.6837 33.9023 37.3164 33.9024 37.7071 34.293L39.0001 35.586L41.293 33.293Z"
        fill="#028CB0"
      />
    </svg>
  );
}
