export default function ReportsIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#E7F2F6"
      />
      <path
        d="M23.3637 18.9286H47.3218V51.0809H22.6777V18.9286H23.3637ZM45.9507 20.2914H24.0496V49.7012H45.9507V20.2914Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.3"
      />
      <path
        d="M33.6507 29.7765V38.4956H32.2793V29.7765H33.6507Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.3"
      />
      <path
        d="M37.7208 26.0367V38.4955H36.3579V26.0367H37.7208Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.3"
      />
      <path
        d="M41.8001 22.9196V38.4956H40.4287V22.9196H41.8001Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.3"
      />
      <path
        d="M29.5709 32.9742V38.4956H28.208V32.9742H29.5709Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.3"
      />
      <path
        d="M27.6577 42.0146H42.3429V43.3775H27.6577V42.0146Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.3"
      />
      <path
        d="M27.6577 46.2091H42.3429V47.5805H27.6577V46.2091Z"
        fill="#028CB0"
        stroke="#028CB0"
        strokeWidth="0.3"
      />
    </svg>
  );
}
