import { api } from "./api";

export const SubscriptionPlans = ["Individual", "Company"];

/**
 * @typedef {Object} PaymentConfiguration
 * @property {string} creditCardText
 * @property {boolean} creditCardIsValid
 * @property {string} [promoCode]
 * @property {string} [promoName]
 * @property {number} discount
 * @property {'Individual' | 'Company'} plan
 * @property {number} planMonthlyBasePrice
 * @property {number} planMonthlySeatPrice
 * @property {'None' | 'Active'} planStatus
 * @property {string} [nextPaymentOn]
 * @property {number} [nextPaymentAmount]
 * @property {number} subscriptionDaysRemaining
 * @property {number} freeTrialDaysRemaining
 * @property {string} planPaymentIntentSecret
 * @property {PastPayments} payments
 */

/**
 * @typedef {Object} PastPayments
 * @property {number} currentPage
 * @property {number} pageCount
 * @property {number} pageSize
 * @property {number} totalCount
 * @property {number} pagingMaxId
 * @property {PastPayment[]} data
 */

/**
 * @typedef {Object} PastPayment
 * @property {number} id
 * @property {number} amount
 * @property {string} description
 * @property {string} chargeId
 * @property {string} date
 * @property {string} receiptUrl
 */

export const PaymentsApi = {
  /**
   *
   * @returns {Promise<PaymentConfiguration>}
   */
  getInfo: () => api.get("/companies/current/subscription"),

  /**
   *
   * @returns {Promise<PaymentConfiguration>}
   */
  createSubscriptionPaymentIntent: () =>
    api.patch("/companies/current/subscription", {
      plan: "Individual",
    }),

  /**
   *
   * @param {string} cancelReason
   * @param {string} [otherCancelReason]
   * @returns {Promise<PaymentConfiguration>}
   */
  removePaymentInfo: (cancelReason, otherCancelReason) =>
    api.delete("/companies/current/subscription", {
      cancelReason,
      otherCancelReason,
    }),
};
