import { useCallback, useState } from "react";
import { Accordion, defaultFilePreview, Loader } from "components";
import styled from "styled-components";
import circleCheckedIcon from "assets/icons/circle-checked.svg";
import circleUncheckedIcon from "assets/icons/circle-unchecked.svg";
import { A, P, PrimaryButton } from "ui-library";
import { useDispatch } from "react-redux";
import { InspectorsApi } from "services";
import { setCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import Toast from "components/toast";

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed #e4f0f7;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 16px;

  &.active {
    background-color: #e4f0f7;
  }
`;

/**
 *
 * @param {Object} props
 * @param {import("services").InspectorProfile} props.inspector
 */
export function SampleReportsWidget({ inspector }) {
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const dispatch = useDispatch();

  const uploadReport = async () => {
    setSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("sampleReportFile", file);

      await InspectorsApi.uploadFilesToCurrentInspector(formData);

      const response = await InspectorsApi.getCurrentInspectorProfile();
      dispatch(setCurrentInspector(response));

      toast.success(<Toast title="Sample report added" />);
      setFile(null);
    } catch (e) {}

    setSubmitting(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: ".pdf",
  });

  if (inspector.sampleReportPath) {
    return <noscript />;
  }

  return (
    <StyledAccordion
      icon={
        inspector.sampleReportPath ? circleCheckedIcon : circleUncheckedIcon
      }
      title="Sample report"
    >
      <P>
        Upload a sample report so your customers can get a feel of what they can
        expect when purchasing one of your reports.
      </P>

      {inspector.sampleReportPath ? (
        <DropzoneContainer />
      ) : (
        <DropzoneContainer
          className={isDragActive ? "active" : ""}
          {...getRootProps()}
        >
          {file ? (
            defaultFilePreview(file, () => setFile(null))
          ) : (
            <>
              <input {...getInputProps()} />

              <P style={{ marginTop: 80, marginBottom: 80 }}>
                {isDragActive ? (
                  "Drop the files here ..."
                ) : (
                  <>
                    Drop your PDF file here or <A>select</A> from your computer
                  </>
                )}
              </P>
            </>
          )}
        </DropzoneContainer>
      )}

      <PrimaryButton
        disabled={!file}
        style={{ marginTop: 24 }}
        onClick={file ? (submitting ? null : uploadReport) : null}
      >
        {submitting ? <Loader loading /> : "Upload"}
      </PrimaryButton>
    </StyledAccordion>
  );
}
