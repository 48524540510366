import styled from "styled-components";
import { Checkbox, P } from "ui-library";

const InspectionChoiceContainer = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.06);
  padding: 32px;
  cursor: pointer;
  transition: all 150ms linear;

  @media only screen and (max-width: 576px) {
    display: flex;
    height: 96px;
    justify-content: flex-start;
    flex-direction: row;
    margin-right: unset;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(24, 105, 171, 0.06);
    box-shadow: none;
  }

  &.selected {
    box-shadow: 0px 11px 48px rgba(24, 105, 171, 0.25);
  }
`;

const InspectionChoiceTitle = styled(P)`
  color: #143a5a;
  margin-top: 24px;
  font-weight: bold;
  text-align: center;

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    text-align: left;
    margin-left: 16px;
  }
`;

const InspectionChoiceIcon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @media only screen and (max-width: 576px) {
    width: 56px;
    height: 56px;
  }

  img {
    width: 100%;
    height: 100%;
    max-width: 80px;
    max-height: 80px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: 20px;
  right: 20px;

  @media only screen and (max-width: 576px) {
    top: unset;
    right: unset;
    position: relative;
    margin-right: 0;
    margin-left: auto;
  }
`;

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export function InspectionChoice({ inspectionType, input }) {
  return (
    <InspectionChoiceContainer className={input.checked ? "selected" : ""}>
      <InspectionChoiceIcon>
        <img src={inspectionType.imagePath} alt={inspectionType.name} />
      </InspectionChoiceIcon>

      <InspectionChoiceTitle>{inspectionType.name}</InspectionChoiceTitle>

      <StyledCheckbox type="checkbox" checked={input.checked} readOnly />

      <HiddenInput {...input} />
    </InspectionChoiceContainer>
  );
}
