import styled from "styled-components";
import InternalSearch from "./internal-search";

const Container = styled.div`
  position: relative;
`;

export default function InspectorSearch() {
  return (
    <Container>
      <InternalSearch product="inspectors" />

      <style
        dangerouslySetInnerHTML={{
          __html: "#root > div { margin-left: 0; }",
        }}
      />
    </Container>
  );
}
