export default function VendorsIcon() {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
        fill="#E7F2F6"
      />
      <g clipPath="url(#clip0_247_195)">
        <path
          d="M51.2229 31.4844H42.2229C42.0682 31.4844 41.9416 31.6109 41.9416 31.7656V33.4531C41.9416 33.6078 42.0682 33.7344 42.2229 33.7344H51.2229C51.3775 33.7344 51.5041 33.6078 51.5041 33.4531V31.7656C51.5041 31.6109 51.3775 31.4844 51.2229 31.4844ZM46.5119 36.2656H42.2229C42.0682 36.2656 41.9416 36.3922 41.9416 36.5469V38.2344C41.9416 38.3891 42.0682 38.5156 42.2229 38.5156H46.5119C46.6666 38.5156 46.7932 38.3891 46.7932 38.2344V36.5469C46.7932 36.3922 46.6666 36.2656 46.5119 36.2656ZM34.7803 28.3379H33.258C33.04 28.3379 32.8643 28.5137 32.8643 28.7316V37.4504C32.8643 37.577 32.924 37.693 33.026 37.7668L38.2607 41.5848C38.4365 41.7113 38.6826 41.6762 38.8092 41.5004L39.7127 40.2664V40.2629C39.8393 40.0871 39.8006 39.841 39.6248 39.7145L35.1705 36.4941V28.7316C35.174 28.5137 34.9947 28.3379 34.7803 28.3379Z"
          fill="#028CB0"
        />
        <path
          d="M46.2938 40.6918H44.2617C44.0649 40.6918 43.8785 40.7937 43.7731 40.9625C43.3266 41.6691 42.8063 42.323 42.2086 42.9207C41.1785 43.9508 39.9797 44.7594 38.6473 45.3219C37.2657 45.9055 35.7996 46.2008 34.2879 46.2008C32.7727 46.2008 31.3067 45.9055 29.9285 45.3219C28.5961 44.7594 27.3973 43.9508 26.3672 42.9207C25.3371 41.8906 24.5285 40.6918 23.966 39.3594C23.3824 37.9812 23.0871 36.5152 23.0871 35C23.0871 33.4848 23.3824 32.0223 23.966 30.6406C24.5285 29.3082 25.3371 28.1094 26.3672 27.0793C27.3973 26.0492 28.5961 25.2406 29.9285 24.6781C31.3067 24.0945 32.7762 23.7992 34.2879 23.7992C35.8032 23.7992 37.2692 24.0945 38.6473 24.6781C39.9797 25.2406 41.1785 26.0492 42.2086 27.0793C42.8063 27.677 43.3266 28.3309 43.7731 29.0375C43.8785 29.2062 44.0649 29.3082 44.2617 29.3082H46.2938C46.5364 29.3082 46.691 29.0551 46.5821 28.8406C44.2899 24.2809 39.6422 21.3172 34.4532 21.2574C26.8559 21.1625 20.5453 27.3816 20.5313 34.9719C20.5172 42.5762 26.6801 48.7461 34.2844 48.7461C39.5403 48.7461 44.2653 45.7719 46.5821 41.1594C46.691 40.9449 46.5328 40.6918 46.2938 40.6918Z"
          fill="#028CB0"
        />
      </g>
      <defs>
        <clipPath id="clip0_247_195">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(19 19)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
