import { Navigate, Route, Routes } from "react-router-dom";
import {
  PUBLIC_MASTER_ROUTE,
  SEARCH_ACCOUNTS_MASTER_ROUTE,
  SEARCH_INSPECTORS_MASTER_ROUTE,
  SEARCH_QUOTES_MASTER_ROUTE,
  SEARCH_REPORTS_MASTER_ROUTE,
} from "routes";
import SearchAccounts from "./claim-profile/home";
import SearchInspectors from "./inspectors/home";
import SearchQuotes from "./quotes/home";
import SearchReports from "./reports/home";

export default function Search() {
  return (
    <Routes>
      <Route
        path={SEARCH_INSPECTORS_MASTER_ROUTE + "/*"}
        element={<SearchInspectors />}
      />

      <Route
        path={SEARCH_REPORTS_MASTER_ROUTE + "/*"}
        element={<SearchReports />}
      />

      <Route
        path={SEARCH_ACCOUNTS_MASTER_ROUTE + "/*"}
        element={<SearchAccounts />}
      />

      <Route
        path={SEARCH_QUOTES_MASTER_ROUTE + "/*"}
        element={<SearchQuotes />}
      />

      <Route path="*" element={<Navigate to={"/" + PUBLIC_MASTER_ROUTE} />} />
    </Routes>
  );
}
