import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: null,
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCurrentCompany: (state, action) => {
      state.current = action.payload;
    },
    updateCurrentCompany: (state, action) => {
      state.current = {
        ...state.current,
        ...action.payload,
      };
    },
    deleteCurrentCompany: (state) => {
      state.current = null;
    },
  },
});

export const { updateCurrentCompany, deleteCurrentCompany, setCurrentCompany } =
  companiesSlice.actions;

/**
 *
 * @param {*} state
 * @returns {import("services").Company}
 */
export const companySelector = (state) => state.companies.current;

export default companiesSlice.reducer;

export { initialState as companiesSliceInitialState };
