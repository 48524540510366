import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inspectorCode: null,
};

export const customerReviewsSlice = createSlice({
  name: "customerReviews",
  initialState,
  reducers: {
    setInspectorCode: (state, action) => {
      state.inspectorCode = action.payload;
    },
    clearCustomerReviews: () => initialState,
  },
});

export const { setInspectorCode, clearCustomerReviews } =
  customerReviewsSlice.actions;

/**
 *
 * @param {*} state
 * @returns string
 */
export const selectInspectorCode = (state) =>
  state.customerReviews.inspectorCode;

export default customerReviewsSlice.reducer;

export { initialState as customerReviewsSliceInitialState };
