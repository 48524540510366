import { H4 } from "ui-library";

import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 24px;
  @media (min-width: 1020px) {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
  }

  h4 {
    font-weight: 800;
  }
`;

const Item = styled.div`
  > * {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon {
    margin-bottom: 24px;
  }
`;

const Description = styled.div`
  margin-top: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #798995;

  a {
    letter-spacing: 0.2px;
    color: #028cb0;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.2px;
    text-decoration: none;
    cursor: pointer;
    &.regular {
      font-size: unset;
      line-height: unset;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default function SecondSectionRow({ items }) {
  return (
    <Wrapper>
      {items.map(({ icon, title, description }, index) => {
        return (
          <Item key={index}>
            <div className="icon">{icon}</div>
            <div>
              <H4>{title}</H4>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </Item>
        );
      })}
    </Wrapper>
  );
}
