import {
  Address,
  DateSelector,
  Input,
  Loader,
  Dropzone,
  FiftyFifty,
  BackButton,
  MultiSelect,
  Select,
} from "components";
import { DocumentTitle } from "components/document-title";
import { createFormDataForAddingOrUpdatingAReport } from "inspectors/helpers";
import { Form } from "react-final-form";
import { Col, Container, Row } from "react-grid-system";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { INSPECTORS_MASTER_ROUTE, INSPECTOR_ROUTES } from "routes";
import { InspectionReportsApi } from "services";
import { displayErrorMessage } from "services/api";
import {
  inspectionTypesSelector,
  reportsSelector,
} from "store/features/inspectors/inspectorsSlice";
import styled from "styled-components";
import { H2, PrimaryButton } from "ui-library";

const FormContent = styled.div`
  margin-left: -16px;
  margin-right: -16px;
  max-width: 400px;
`;

export default function InspectorEditReport() {
  const inspectionTypes = useSelector(inspectionTypesSelector);
  const { reportId: stringReportId } = useParams();
  const reportId = Number(stringReportId);
  const reports = useSelector(reportsSelector);
  const report = reports.find((_) => _.id === reportId);
  const navigate = useNavigate();

  if (!report) {
    return <Navigate to="/reports" />;
  }

  return (
    <DocumentTitle title="Edit Report">
      <FiftyFifty>
        <Form
          initialValues={{
            ...report,
            inspectionDate: new Date(report.inspectionDate),
          }}
          onSubmit={async (values, dispatch) => {
            try {
              const formData = await createFormDataForAddingOrUpdatingAReport(
                values
              );

              await InspectionReportsApi.updateInspectionReportWithId(
                report.id,
                formData
              );

              navigate(
                "/" + INSPECTORS_MASTER_ROUTE + "/" + INSPECTOR_ROUTES.REPORTS
              );
            } catch (e) {
              displayErrorMessage(e);
            }
          }}
          render={({ handleSubmit, submitting }) => (
            <FormContent>
              <Container>
                <Row>
                  <Col xs={12}>
                    <BackButton
                      onClick={() =>
                        navigate(
                          "/" +
                            INSPECTORS_MASTER_ROUTE +
                            "/" +
                            INSPECTOR_ROUTES.REPORTS
                        )
                      }
                    />
                    <H2 style={{ marginTop: 32 }}>Edit your report</H2>
                  </Col>

                  <Col xs={12}>
                    <Dropzone
                      name="reportFile"
                      label="Report file"
                      accept=".pdf"
                    />
                  </Col>

                  <Col xs={12}>
                    <Dropzone
                      name="propertyImageFile"
                      label="Property image"
                      accept=".png, .jpg, .jpeg"
                      placeholder="Drop your PNG, JPG, JPEG file here or select from your computer"
                    />
                  </Col>

                  <Col xs={12}>
                    <Address name="address" label="Address" />
                  </Col>

                  <Col xs={12}>
                    <Select
                      name="propertyType"
                      placeholder="Select type of property"
                      label="Property type"
                      items={[
                        "Apartment",
                        "Townhouse",
                        "House",
                        "Commercial",
                        "Industrial",
                        "Mobile",
                        "Duplex",
                        "GrannyFlat",
                        "Other",
                      ].map((propertyType) => ({
                        label: propertyType,
                        value: propertyType,
                      }))}
                    />
                  </Col>

                  <Col xs={12}>
                    <MultiSelect
                      name="inspectionTypeIds"
                      label="Type of inspection(s)"
                      placeholder="Select type of inspection"
                      options={inspectionTypes.map((inspectionType) => ({
                        label: inspectionType.name,
                        value: inspectionType.id,
                      }))}
                    />
                  </Col>

                  <Col xs={12}>
                    <DateSelector
                      name="inspectionDate"
                      label="Inspection date"
                    />
                  </Col>

                  <Col xs={12}>
                    <Input name="price" label="Report price" type="number" />
                  </Col>

                  <Col xs={12}>
                    <PrimaryButton
                      style={{ marginTop: 24 }}
                      onClick={submitting ? null : handleSubmit}
                    >
                      {submitting ? <Loader loading /> : "Save report"}
                    </PrimaryButton>
                  </Col>
                </Row>
              </Container>
            </FormContent>
          )}
        />
      </FiftyFifty>
    </DocumentTitle>
  );
}
