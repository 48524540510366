import BlueAndYellowBlob from "components/public-pages/icons/blue-and-yellow-blob";
import BlueBlob from "components/public-pages/icons/blue-blob";
import SecondSectionRow from "components/public-pages/second-section-row/second-section-row";
import LockIcon from "components/public-pages/icons/lock";
import BubbleIcon from "components/public-pages/icons/bubble";
import VendorsIcon from "components/public-pages/icons/vendors";
import Caurosel from "components/public-pages/caurosel/caurosel";
import { DocumentTitle } from "components/document-title";
import StatsCardWrapper from "components/public-pages/stats-card-wrapper";
import styled from "styled-components";
import { H1, H2, P, P2 } from "ui-library";
import { Container } from "react-grid-system";
import { Spacer } from "common/helpers";
import ReportSearch from "search/components/report-search";

export const Banner = styled.section`
  position: relative;
`;

export const BlueAndYellowBlobContainer = styled.span`
  display: none;
  @media (min-width: 1020px) {
    display: block;
    position: absolute;
    top: 0;
    left: -15vw;
    z-index: -1;
    svg {
      width: 700px;
      height: 700px;
    }
  }
`;

export const BlueBlobContainer = styled.span`
  display: none;

  @media (min-width: 1020px) {
    display: block;
    position: absolute;
    top: 60px;
    right: 0;
    transform: translate(10%, -20%);
    svg {
      width: 500px;
      height: 500px;
    }
  }
`;

export const Heading = styled(H1)`
  text-align: center;
  max-width: 100%;
  @media (min-width: 1020px) {
    width: 80%;
    margin: 60px auto 0;
  }
  @media (max-width: 576px) {
    font-size: 25px;
    line-height: 30px;
    margin: 60px auto 0;
  }
`;

export const Subheading = styled(P2)`
  text-align: center;
  max-width: 100%;
  margin-top: 16px;
  margin-bottom: 32px;
  color: #404852;
  @media (min-width: 1020px) {
    width: 60%;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 32px;
  }
`;

export const ReportSearchWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    @media (min-width: 1020px) {
      width: 519px;
      display: flex;
      justify-content: center;
    }
  }
`;

export const SecondSection = styled.section`
  position: relative;
`;

export const SecondSectionHeading = styled(H2)`
  text-align: center;
  margin-bottom: 18px;
`;

export const SecondSectionSubheading = styled(P)`
  text-align: center;
  width: 50%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const LoungeRoomSection = styled.section`
  @media (min-width: 1020px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 45px;
    .content {
      padding-bottom: 48px;
    }
  }
  img {
    width: 100%;
  }
  a {
    color: #028cb0;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ProfilesWrapper = styled.div`
  position: relative;
  /* > div {
    position: absolute;
    top: 0px;
    right: 24px;
    display: flex;
    gap: 16px;
  } */
`;

export default function PropertyReport() {
  return (
    <>
      <DocumentTitle title="Property Reports">
        <Banner>
          <BlueAndYellowBlobContainer>
            <BlueAndYellowBlob />
          </BlueAndYellowBlobContainer>
          <BlueBlobContainer>
            <BlueBlob />
          </BlueBlobContainer>
          <Container>
            <Heading>
              Search our database of more than 50,000 inspection reports
            </Heading>
            <Subheading>
              There may already be property reports for the property you are
              interested in.
            </Subheading>
            <ReportSearchWrapper>
              <ReportSearch />
            </ReportSearchWrapper>
          </Container>
        </Banner>
      </DocumentTitle>

      <StatsCardWrapper />

      <Spacer />

      <Spacer />

      <SecondSection>
        <Container>
          <SecondSectionHeading>
            Save time by accessing an existing report
          </SecondSectionHeading>
          <SecondSectionSubheading>
            Check if an inspector has already inspected the property and
            uploaded the report. Access it immediately.
          </SecondSectionSubheading>
          <SecondSectionRow
            items={[
              {
                icon: <LockIcon />,
                title: "Secure and confidential",
                description:
                  "Your payment and personal details remain private and confidential",
              },
              {
                icon: <BubbleIcon />,
                title: "Speak with the inspector",
                description:
                  "Speak with the inspector over the phone, video call or email after you have downloaded their report.",
              },
              {
                icon: <VendorsIcon />,
                title: "Sellers and agents save time",
                description: `Sellers and agents save time. Get a pre-sale inspection before you sell and make them available to prospective buyers on Rate my inspectors.`,
              },
            ]}
          />
        </Container>
      </SecondSection>

      <Spacer />

      <Caurosel />
    </>
  );
}
