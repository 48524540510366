import { RequireAuth } from "common/authentication";
import { Loader } from "components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { USER_ROUTES } from "routes";
import {
  InspectionQuotesApi,
  InspectionReportsApi,
  InspectionTypesApi,
  InspectorReviewsApi,
} from "services";
import {
  setCustomerReviews,
  setPurchasedReports,
  setRequestedQuotes,
} from "store/features/customers/customersSlice";
import { setInspectionTypes } from "store/features/inspectors/inspectorsSlice";
import UserDashboard from "./pages/dashboard";

export default function Users() {
  const dispatch = useDispatch();
  const [isFetchingData, setIsFetchingData] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const reviewsResponse =
          await InspectorReviewsApi.getReviewsCreatedByCurrentCustomer();
        dispatch(setCustomerReviews(reviewsResponse));

        const reportsResponse =
          await InspectionReportsApi.getReportsPurchasedByCurrentCustomer();
        dispatch(setPurchasedReports(reportsResponse));

        const quotesResponse =
          await InspectionQuotesApi.getQuotesRequestedByCurrentCustomer();
        dispatch(setRequestedQuotes(quotesResponse));

        const inspectionTypes = await InspectionTypesApi.getInspectionTypes();
        dispatch(setInspectionTypes(inspectionTypes));
        setIsFetchingData(false);
      } catch (e) {
        setIsFetchingData(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetchingData) {
    return (
      <div style={{ textAlign: "center", marginTop: 32 }}>
        <Loader color="#feba00" size="large" loading />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <RequireAuth>
            <UserDashboard />
          </RequireAuth>
        }
      />

      <Route
        path="*"
        element={<Navigate to={USER_ROUTES.DASHBOARD} replace />}
      />
    </Routes>
  );
}
