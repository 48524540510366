import { api } from "./api";

/**
 * @typedef {Object} InspectorVideo
 * @property {number} id
 * @property {string} videoId
 * @property {'YouTube' | 'Vimeo'} type
 * @property {string} [title]
 * @property {string} [link]
 * @property {string} [createdOn]
 */

export const InspectorVideosApi = {
  /**
   *
   * @param {Object} videoInfo
   * @param {string} videoInfo.title
   * @param {string} videoInfo.link
   * @returns {Promise<void>}
   */
  addVideoForCurrentInspector: async (videoInfo) =>
    api.post("/InspectorVideos/inspectors/current", videoInfo),

  /**
   *
   * @returns {Promise<InspectorVideo[]>}
   */
  getVideosForCurrentInspector: async () =>
    api.get("/InspectorVideos/inspectors/current"),

  /**
   *
   * @returns {Promise<InspectorVideo[]>}
   */
  getVideosForInspectorWithId: async (inspectorId) =>
    api.get(`/InspectorVideos/inspectors/${inspectorId}`),

  /**
   *
   * @param {string} videoId
   * @param {Object} videoInfo
   * @param {string} videoInfo.title
   * @param {string} videoInfo.link
   * @returns {Promise<void>}
   */
  updateVideoWithId: async (videoId, videoInfo) =>
    api.put(`/InspectorVideos/${videoId}`, videoInfo),

  /**
   *
   * @param {string} videoId
   * @returns {Promise<void>}
   */
  deleteVideoWithId: async (videoId) =>
    api.delete(`/InspectorVideos/${videoId}`),
};
