import { H1, PrimaryButton } from "ui-library";
import styled from "styled-components";

const ContentContainer = styled.div`
  max-width: 100%;
  padding: 24px;
  @media (min-width: 1020px) {
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
  }
`;

const Content = styled(ContentContainer)`
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0.3px;
    color: #404852;
    margin-top: 22px;
    width: 100%;
  }

  h1 {
    font-weight: 800;
  }

  @media (min-width: 1020px) {
    h1,
    p {
      width: 60%;
    }

    p {
      width: 50%;
    }
  }

  @media (max-width: 576px) {
    h1 {
      font-size: 25px;
      line-height: 30px;
    }
  }

  a {
    color: #028cb0;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    text-decoration: none;
    display: inline;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Section = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 1020px) {
    min-height: 600px;
  }
`;

const BackgroundImage = styled.img`
  display: none;
  @media (min-width: 1020px) {
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    right: 0;
    z-index: -1;
    object-fit: contain;
    object-position: 50% center;
  }
`;

function Banner({
  image,
  altText,
  title,
  excerpt,
  hasButton = false,
  buttonText,
  children,
}) {
  return (
    <Section>
      <BackgroundImage src={image} alt={altText} />
      <Content>
        <H1>{title}</H1>
        <div>{excerpt}</div>
        {hasButton && <PrimaryButton>{buttonText}</PrimaryButton>}
        {children && children}
      </Content>
    </Section>
  );
}

export default Banner;
