import { useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { FiftyFifty, Input, Loader } from "components";
import { H2, P, PrimaryButton, TertiaryButton } from "ui-library";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";
import {
  CompaniesApi,
  InspectorsApi,
  UsersApi,
  UserSessionsApi,
} from "services";
import { useDispatch } from "react-redux";
import { setSession } from "store/features/user/userSlice";
import { displayErrorMessage } from "services/api";
import { DocumentTitle } from "components/document-title";
import { useAuth } from "common/authentication";
import { setCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import { setCurrentCompany } from "store/features/companies/companiesSlice";

export default function AccountVerify() {
  const { user } = useAuth();
  const [isResendingCode, setIsResendingCode] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resendVerificationCode = async () => {
    try {
      setIsResendingCode(true);
      await UsersApi.triggerAccountVerificationCode(user.email);
      setIsResendingCode(false);
    } catch (e) {}
  };

  return (
    <DocumentTitle title="Verification">
      <FiftyFifty>
        <Form
          onSubmit={async (values) => {
            try {
              const session = await UserSessionsApi.signInWithOtpCode(
                user.email,
                values.token
              );
              dispatch(setSession(session));

              const inspector =
                await InspectorsApi.getCurrentInspectorProfile();
              dispatch(setCurrentInspector(inspector));

              const currentCompany = await CompaniesApi.getCurrentCompany();
              dispatch(setCurrentCompany(currentCompany));

              navigate("/*");
            } catch (e) {
              displayErrorMessage(e);
            }
          }}
          render={({ handleSubmit, submitting }) => (
            <div style={{ maxWidth: 450, marginLeft: 0, width: "100%" }}>
              <Container>
                <Row>
                  <Col xs={12}>
                    <H2 style={{ marginTop: 40 }}>SMS Verification</H2>

                    <P style={{ marginTop: 32 }}>
                      {`Please enter the 4-digit code we just sent to 04** *** ${user.phone.slice(
                        7
                      )}`}
                    </P>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Input
                      label="SMS Verification code"
                      name="token"
                      type="number"
                      placeholder="Security code"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <PrimaryButton
                      style={{ marginTop: 24, width: "100%" }}
                      onClick={submitting ? null : handleSubmit}
                    >
                      {submitting ? <Loader loading /> : "Submit"}
                    </PrimaryButton>
                  </Col>
                  <Col xs={12}>
                    <TertiaryButton
                      style={{ marginTop: 16, width: "100%", fontSize: 16 }}
                      onClick={resendVerificationCode}
                    >
                      {isResendingCode ? (
                        <Loader loading color="#028cb0" />
                      ) : (
                        "Resend text message"
                      )}
                    </TertiaryButton>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        />
      </FiftyFifty>
    </DocumentTitle>
  );
}
