export default function RMIRegionIcon() {
  return (
    <svg
      width="187"
      height="117"
      viewBox="0 0 187 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.5406 35C91.3753 35 90.1024 36.6679 90.1024 38.1602V38.4381H92.2385V38.1602C92.2385 37.136 93.0724 36.8434 93.5406 36.8434C94.038 36.8434 94.9304 37.0629 94.9304 38.2333C94.9304 39.0819 94.4038 39.6086 92.414 41.9348L90 44.7877V45.3437H97.0665V43.5295H93.7454L94.6817 42.52C96.6568 40.3694 97.0665 39.6232 97.0665 38.2333C97.0665 36.6679 95.6912 35 93.5406 35Z"
        fill="#0D1F30"
      />
      <path
        d="M102.821 35C100.525 35 98.3885 36.6679 98.3885 40.0329V40.4133C98.3885 43.7782 100.525 45.4461 102.821 45.4461C105.148 45.4461 107.255 43.7782 107.255 40.4133V40.0329C107.255 36.6679 105.148 35 102.821 35ZM102.821 36.7849C104.709 36.7849 105.162 38.6576 105.162 40.0329V40.4133C105.162 41.8031 104.709 43.6466 102.821 43.6466C100.934 43.6466 100.481 41.8031 100.481 40.4133V40.0329C100.481 38.6576 100.934 36.7849 102.821 36.7849Z"
        fill="#0D1F30"
      />
      <path
        d="M112.043 35C109.878 35 108.605 36.6679 108.605 38.1602V38.4381H110.741V38.1602C110.741 37.136 111.575 36.8434 112.043 36.8434C112.54 36.8434 113.433 37.0629 113.433 38.2333C113.433 39.0819 112.906 39.6086 110.916 41.9348L108.502 44.7877V45.3437H115.569V43.5295H112.248L113.184 42.52C115.159 40.3694 115.569 39.6232 115.569 38.2333C115.569 36.6679 114.194 35 112.043 35Z"
        fill="#0D1F30"
      />
      <path
        d="M124.63 35.1024H117.271V36.8873H121.397L119.495 40.2962C119.758 40.2377 120.007 40.2084 120.227 40.2084C121.631 40.2084 122.333 41.1594 122.333 41.9494C122.333 43.4417 121.104 43.6905 120.578 43.6905C120.007 43.6905 118.983 43.3686 118.983 42.2274H116.891C116.891 44.027 118.412 45.49 120.578 45.49C122.597 45.49 124.425 44.1733 124.425 41.9494C124.425 40.4425 123.533 39.2574 122.406 39.0234L124.63 35.1024Z"
        fill="#0D1F30"
      />
      <path
        d="M74.7786 44.6415L73.8484 43.8405L88.1634 27.2775L66.7599 22.368L66.6307 22.1441L55.5801 3.1953L44.3917 22.368L22.0063 27.5014L21.7393 26.3128L43.6079 21.3L55.5801 0.775024L67.5523 21.3L90.4114 26.5368L74.7786 44.6415Z"
        fill="#103A5A"
      />
      <path
        d="M4.79809 55.0805H1.03418V51.8075H4.79809V55.0805ZM4.79809 69.8519H1.03418V56.4413H4.79809V69.8519Z"
        fill="#143A5A"
      />
      <path
        d="M20.1118 62.1433V69.852H16.4254V62.3759C16.4254 60.1623 15.521 59.4646 14.2635 59.4646C11.9896 59.4646 10.8183 61.8849 10.7235 62.3759V69.852H7.03711V56.4415H10.7235V58.9737C10.8269 58.1985 12.3514 56.3123 15.0817 56.3123C17.5881 56.3123 20.1118 57.9143 20.1118 62.1433Z"
        fill="#143A5A"
      />
      <path
        d="M26.9415 59.3871C26.3731 59.3871 25.8907 59.6713 25.8907 60.2484C25.8907 60.8255 26.1664 61.0408 26.8382 61.2733L28.6211 61.9193C30.4471 62.5911 32.4625 63.263 32.4625 65.9158C32.4625 68.7495 29.4738 70.0673 26.9932 70.0673C24.5126 70.0673 22.0148 68.827 21.4033 66.4842L24.4696 65.5282C24.7233 65.9685 25.0925 66.3312 25.5372 66.5771C25.9819 66.823 26.4854 66.9428 26.9932 66.9235C27.7942 66.9235 28.5953 66.5359 28.5953 65.8641C28.5953 65.4248 28.311 65.1148 27.6392 64.8822L25.9855 64.2448C23.4877 63.3146 22.0407 62.3586 22.0407 60.2742C22.0407 57.7936 24.3404 56.3294 26.864 56.3294C27.9695 56.2854 29.0612 56.5868 29.9874 57.1919C30.9135 57.797 31.6282 58.6757 32.0319 59.7057L28.7331 60.5671C28.5718 60.2247 28.3192 59.9334 28.0032 59.7253C27.6871 59.5171 27.3198 59.4001 26.9415 59.3871Z"
        fill="#143A5A"
      />
      <path
        d="M47.1573 63.1251C47.1573 67.8451 43.7121 70.0156 40.8697 70.0156C38.7509 70.0156 37.4245 68.5772 37.4245 68.0604V74.7355H33.7295V56.4413H37.4245V58.0175C37.4245 57.4491 38.7337 56.2949 40.8697 56.2949C41.7352 56.3178 42.5872 56.5141 43.3754 56.8723C44.1635 57.2305 44.8718 57.7432 45.4581 58.3802C46.0445 59.0171 46.497 59.7653 46.7889 60.5803C47.0807 61.3954 47.206 62.2607 47.1573 63.1251ZM43.4709 63.1251C43.4709 60.7996 41.8947 59.4387 40.2754 59.4387C38.6562 59.4387 37.2609 60.3861 37.2609 63.1251C37.2609 65.8641 38.7251 66.8632 40.2754 66.8632C41.8258 66.8632 43.4709 65.5195 43.4709 63.1251Z"
        fill="#143A5A"
      />
      <path
        d="M61.7988 63.2026C61.8059 63.6524 61.7742 64.102 61.7041 64.5463H52.1091C52.312 65.2106 52.7209 65.7932 53.2768 66.2097C53.8327 66.6262 54.5066 66.8551 55.2012 66.8632C55.6314 66.89 56.0625 66.8272 56.4672 66.679C56.8719 66.5307 57.2415 66.3002 57.5526 66.0019L60.593 67.5523C59.9147 68.3204 59.081 68.9357 58.147 69.3574C57.213 69.7791 56.2001 69.9976 55.1754 69.9984C53.4465 69.8715 51.829 69.0977 50.6454 67.8313C49.4617 66.5648 48.799 64.8987 48.7892 63.1653C48.7794 61.4318 49.4233 59.7584 50.5926 58.4786C51.7619 57.1989 53.3706 56.4068 55.0978 56.2605C55.9964 56.267 56.8847 56.4528 57.7105 56.807C58.5364 57.1612 59.2832 57.6767 59.9073 58.3233C60.5314 58.9698 61.0202 59.7344 61.345 60.5723C61.6698 61.4101 61.8241 62.3044 61.7988 63.2026ZM52.1608 61.7557H58.0608C57.8873 61.1034 57.5022 60.5271 56.966 60.1172C56.4298 59.7072 55.7728 59.4868 55.0978 59.4904C54.4236 59.4774 53.765 59.6947 53.231 60.1066C52.697 60.5185 52.3195 61.1002 52.1608 61.7557Z"
        fill="#143A5A"
      />
      <path
        d="M75.3902 59.3353L72.1173 60.5153C71.8389 60.1622 71.4805 59.8803 71.0716 59.6931C70.6627 59.5059 70.2151 59.4187 69.7659 59.4387C68.1983 59.4387 66.648 60.7996 66.648 63.2026C66.648 65.6057 68.1983 66.8632 69.7659 66.8632C70.2142 66.8888 70.662 66.8058 71.0713 66.6214C71.4807 66.437 71.8395 66.1565 72.1173 65.8038L75.4161 66.9924C74.7505 67.9612 73.8463 68.7419 72.7907 69.259C71.7351 69.776 70.5639 70.0119 69.3904 69.9438C68.217 69.8756 67.081 69.5059 66.0923 68.8701C65.1036 68.2344 64.2957 67.3544 63.7467 66.315C63.1978 65.2757 62.9263 64.1122 62.9586 62.9372C62.991 61.7623 63.326 60.6155 63.9312 59.6079C64.5365 58.6003 65.3916 57.766 66.4137 57.1856C67.4359 56.6052 68.5905 56.2984 69.7659 56.2949C70.8819 56.2961 71.9801 56.5745 72.9618 57.1052C73.9435 57.6359 74.778 58.4023 75.3902 59.3353Z"
        fill="#143A5A"
      />
      <path
        d="M81.2641 59.4387V65.5453C81.2641 66.1396 81.4967 66.8373 82.3752 66.8373C82.965 66.8581 83.5524 66.7523 84.0978 66.5272V69.5418C83.2827 69.8898 82.3993 70.0488 81.5139 70.0069C79.3779 70.0069 77.595 69.2834 77.595 65.5712V59.4387H76.2686V56.4413H77.5777V54.1244H81.2641V56.4413H84.1323V59.4387H81.2641Z"
        fill="#143A5A"
      />
      <path
        d="M98.439 63.2026C98.2499 64.8863 97.4471 66.4413 96.1839 67.5705C94.9208 68.6997 93.2859 69.3239 91.5916 69.3239C89.8973 69.3239 88.2625 68.6997 86.9993 67.5705C85.7362 66.4413 84.9334 64.8863 84.7442 63.2026C84.636 62.2389 84.7324 61.2632 85.0271 60.3393C85.3218 59.4154 85.8083 58.5641 86.4546 57.8411C87.1009 57.1181 87.8926 56.5397 88.7778 56.1437C89.663 55.7477 90.6219 55.543 91.5916 55.543C92.5614 55.543 93.5202 55.7477 94.4055 56.1437C95.2907 56.5397 96.0823 57.1181 96.7287 57.8411C97.375 58.5641 97.8614 59.4154 98.1562 60.3393C98.4509 61.2632 98.5473 62.2389 98.439 63.2026ZM88.4392 63.2026C88.4392 65.597 90.0585 66.8632 91.6089 66.8632C93.1592 66.8632 94.7526 65.597 94.7526 63.2026C94.7526 60.8082 93.1334 59.4387 91.6089 59.4387C90.0844 59.4387 88.4392 60.722 88.4392 63.2026Z"
        fill="#143A5A"
      />
      <path
        d="M109.257 56.4155V59.8607C108.833 59.7949 108.403 59.769 107.974 59.7832C105.674 59.7832 103.9 61.5058 103.667 64.0897V69.8863H100.007V56.4413H103.667V60.7478C103.952 58.2759 105.183 56.3121 107.974 56.3121C108.404 56.2998 108.835 56.3345 109.257 56.4155Z"
        fill="#143A5A"
      />
      <path
        d="M130.979 63.2026C130.79 64.8863 129.987 66.4413 128.724 67.5705C127.461 68.6997 125.826 69.3239 124.132 69.3239C122.437 69.3239 120.803 68.6997 119.539 67.5705C118.276 66.4413 117.473 64.8863 117.284 63.2026C117.176 62.2389 117.272 61.2632 117.567 60.3393C117.862 59.4154 118.348 58.5641 118.995 57.8411C119.641 57.1181 120.433 56.5397 121.318 56.1437C122.203 55.7477 123.162 55.543 124.132 55.543C125.101 55.543 126.06 55.7477 126.946 56.1437C127.831 56.5397 128.622 57.1181 129.269 57.8411C129.915 58.5641 130.401 59.4154 130.696 60.3393C130.991 61.2632 131.087 62.2389 130.979 63.2026ZM120.971 63.2026C120.971 65.597 122.599 66.8632 124.14 66.8632C125.682 66.8632 127.293 65.597 127.293 63.2026C127.293 60.8082 125.665 59.4387 124.14 59.4387C122.616 59.4387 120.971 60.722 120.971 63.2026Z"
        fill="#143A5A"
      />
      <path
        d="M137.009 56.14V56.4414H140.023V59.5938H137.009V69.852H133.322V59.5938H131.6V56.4414H133.322V56.0625C133.322 52.1177 136.337 51.6267 137.939 51.6267C139.138 51.6433 140.318 51.9382 141.384 52.488L140.195 55.0719C139.556 54.8205 138.876 54.6891 138.189 54.6844C136.931 54.6155 137.009 55.6835 137.009 56.14Z"
        fill="#143A5A"
      />
      <path
        d="M153.925 59.4387V65.5453C153.925 66.1396 154.158 66.8373 155.028 66.8373C155.618 66.8581 156.205 66.7523 156.75 66.5272V69.5418C155.935 69.8904 155.052 70.0494 154.166 70.0069C152.022 70.0069 150.247 69.2834 150.247 65.5712V59.4387H148.93V56.4413H150.247V54.1244H153.934V56.4413H156.793V59.4387H153.925Z"
        fill="#143A5A"
      />
      <path
        d="M171.374 62.1432V69.8519H167.688V62.3758C167.688 60.1622 166.827 59.4645 165.526 59.4645C163.252 59.4645 162.081 61.8848 161.995 62.3758V69.8519H158.3V51.8075H161.995V58.9736C162.098 58.1984 163.614 56.3121 166.353 56.3121C168.851 56.3121 171.374 57.9142 171.374 62.1432Z"
        fill="#143A5A"
      />
      <path
        d="M186.404 63.2026C186.411 63.6524 186.38 64.102 186.31 64.5463H176.715C176.917 65.2106 177.326 65.7932 177.882 66.2097C178.438 66.6262 179.112 66.8551 179.807 66.8632C180.237 66.89 180.668 66.8272 181.073 66.679C181.477 66.5307 181.847 66.3002 182.158 66.0019L185.198 67.5523C184.52 68.3204 183.686 68.9357 182.752 69.3574C181.818 69.7791 180.806 69.9976 179.781 69.9984C178.052 69.8715 176.434 69.0977 175.251 67.8313C174.067 66.5648 173.404 64.8987 173.395 63.1653C173.385 61.4318 174.029 59.7584 175.198 58.4786C176.367 57.1989 177.976 56.4068 179.703 56.2605C180.602 56.267 181.49 56.4528 182.316 56.807C183.142 57.1612 183.889 57.6767 184.513 58.3233C185.137 58.9698 185.626 59.7344 185.95 60.5723C186.275 61.4101 186.43 62.3044 186.404 63.2026ZM176.766 61.7557H182.666C182.493 61.1034 182.108 60.5271 181.571 60.1172C181.035 59.7072 180.378 59.4868 179.703 59.4904C179.029 59.4774 178.37 59.6947 177.836 60.1066C177.302 60.5185 176.925 61.1002 176.766 61.7557Z"
        fill="#143A5A"
      />
      <path
        d="M14.5992 78.8353L7.16609 97.1467H3.55721L5.67603 91.8841L0 78.8353H3.63472L7.42449 87.5776L10.9558 78.8353H14.5992Z"
        fill="#143A5A"
      />
      <path
        d="M28.7764 85.5967C28.7805 86.0467 28.7459 86.4962 28.673 86.9403H19.078C19.2825 87.6053 19.6931 88.1882 20.2504 88.6046C20.8078 89.0211 21.483 89.2496 22.1787 89.2572C22.6076 89.2833 23.0371 89.2202 23.4403 89.072C23.8436 88.9238 24.2117 88.6936 24.5215 88.3959L27.5619 89.9463C26.8853 90.7142 26.0531 91.3295 25.1205 91.7512C24.188 92.1729 23.1764 92.3915 22.1529 92.3924C20.4241 92.2656 18.8066 91.4918 17.6229 90.2253C16.4393 88.9588 15.7765 87.2927 15.7667 85.5593C15.7569 83.8258 16.4009 82.1524 17.5702 80.8726C18.7394 79.5929 20.3481 78.8009 22.0754 78.6545C22.974 78.6611 23.8622 78.8469 24.6881 79.2011C25.5139 79.5553 26.2608 80.0708 26.8849 80.7173C27.509 81.3639 27.9977 82.1285 28.3225 82.9663C28.6473 83.8042 28.8016 84.6984 28.7764 85.5967ZM19.1297 84.1497H25.0383C24.8635 83.4982 24.4781 82.9227 23.9422 82.513C23.4063 82.1033 22.75 81.8823 22.0754 81.8844C21.4 81.871 20.7403 82.088 20.2048 82.4998C19.6693 82.9116 19.2901 83.4935 19.1297 84.1497Z"
        fill="#143A5A"
      />
      <path
        d="M39.8098 80.4116V78.8354H43.4962V92.246H39.8098V90.4717C39.8098 90.9885 38.492 92.4269 36.3646 92.4269C33.5567 92.4269 30.0943 90.2392 30.0943 85.5364C30.0456 84.6735 30.1703 83.8095 30.4611 82.9956C30.7519 82.1816 31.2029 81.4342 31.7874 80.7975C32.3719 80.1608 33.0781 79.6477 33.8643 79.2885C34.6505 78.9293 35.5006 78.7313 36.3646 78.7062C38.492 78.689 39.784 79.8432 39.8098 80.4116ZM33.7807 85.5192C33.7807 87.9136 35.3569 89.2573 36.9847 89.2573C38.6126 89.2573 39.9735 88.2237 39.9735 85.5192C39.9735 82.8147 38.5265 81.8328 36.9847 81.8328C35.443 81.8328 33.7721 83.1937 33.7721 85.5192H33.7807Z"
        fill="#143A5A"
      />
      <path
        d="M54.6841 78.8095V82.2547C54.2596 82.189 53.83 82.163 53.4007 82.1772C51.101 82.1772 49.3267 83.8998 49.0942 86.4838V92.2804H45.4336V78.8353H49.0942V83.1419C49.3784 80.6699 50.6101 78.7061 53.4007 78.7061C53.831 78.6939 54.2613 78.7285 54.6841 78.8095Z"
        fill="#143A5A"
      />
      <path
        d="M73.5551 80.6613L73.7877 78.8526H76.63L74.9763 92.2632H72.1426L72.401 90.1013C72.1684 91.1779 70.2305 92.444 68.0944 92.444C65.2521 92.444 62.0653 90.2305 62.6251 85.5536C62.7853 83.733 63.5994 82.032 64.9168 80.7653C66.2342 79.4986 67.9657 78.7518 69.7912 78.6631C71.9617 78.6889 73.1244 79.6622 73.5551 80.6613ZM65.433 85.5622C65.0712 88.4734 66.9316 89.9979 68.7921 89.9979C70.6525 89.9979 72.711 88.706 73.0986 85.5622C73.4862 82.4184 71.7033 81.1523 69.8687 81.1523C68.7231 81.2243 67.6425 81.709 66.8269 82.5166C66.0114 83.3243 65.5161 84.4001 65.433 85.5449V85.5622Z"
        fill="#EB9F22"
      />
      <path
        d="M90.6528 92.2459H90.1877L87.0956 83.6328L81.9278 92.2459H81.4627L77.7246 78.8353H80.5411L82.5565 86.8627L87.4057 78.8353H87.9225L90.8079 86.8627L94.856 78.8353H97.6639L90.6528 92.2459Z"
        fill="#EB9F22"
      />
      <path
        d="M107.982 80.6613L108.214 78.8526H111.048L109.394 92.2632H106.56L106.819 90.1013C106.586 91.1779 104.648 92.444 102.512 92.444C99.6785 92.444 96.4831 90.2305 97.0429 85.5536C97.2031 83.733 98.0172 82.032 99.3346 80.7653C100.652 79.4986 102.384 78.7518 104.209 78.6631C106.38 78.6889 107.534 79.6622 107.982 80.6613ZM99.8594 85.5622C99.4977 88.4734 101.349 89.9979 103.21 89.9979C105.07 89.9979 107.129 88.706 107.516 85.5622C107.904 82.4184 106.121 81.1523 104.295 81.1523C103.149 81.2225 102.067 81.7066 101.251 82.5146C100.435 83.3226 99.9408 84.3995 99.8594 85.5449V85.5622Z"
        fill="#EB9F22"
      />
      <path
        d="M121.59 78.7837L121.254 81.4193C120.877 81.3751 120.497 81.3578 120.117 81.3676C118.875 81.3979 117.678 81.8359 116.71 82.6142C115.741 83.3925 115.056 84.4678 114.76 85.6741L113.959 92.2201H111.125L112.77 78.8095H115.604L115.121 82.8318C115.426 81.6428 116.118 80.5891 117.088 79.8372C118.058 79.0854 119.251 78.6783 120.479 78.6803C120.851 78.6852 121.223 78.7198 121.59 78.7837Z"
        fill="#EB9F22"
      />
      <path
        d="M133.917 92.246H131.083L131.341 90.1617C131.109 91.2383 129.248 92.4269 127.138 92.4269C126.369 92.4318 125.607 92.2788 124.899 91.9775C124.192 91.6761 123.553 91.2329 123.024 90.6751C122.494 90.1172 122.085 89.4567 121.821 88.7344C121.556 88.012 121.443 87.2431 121.488 86.4753C121.488 83.1162 123.934 78.7235 128.577 78.7235C130.799 78.7235 132.022 79.7485 132.47 80.6528L133.331 74.2361H136.165L133.917 92.246ZM124.296 86.3719C124.256 86.8263 124.309 87.2842 124.454 87.7168C124.599 88.1495 124.831 88.5476 125.137 88.8863C125.442 89.2251 125.815 89.4971 126.23 89.6854C126.646 89.8737 127.095 89.9743 127.552 89.9808C130.799 89.9808 132.108 86.9059 132.108 84.589C132.108 82.0912 130.566 81.1438 128.887 81.1438C125.846 81.1352 124.296 84.2273 124.296 86.3719Z"
        fill="#EB9F22"
      />
      <path
        d="M141.754 81.0576C141.345 81.0334 140.942 81.1553 140.615 81.4015C140.289 81.6477 140.06 82.0021 139.971 82.4012C139.893 83.0386 140.203 83.5037 141.237 83.8913L142.736 84.4598C144.691 85.1833 146.448 85.8551 146.138 88.3787C145.828 90.9024 142.865 92.4269 140.462 92.4269C138.059 92.4269 135.819 91.1607 135.535 88.8955L138.119 88.1462C138.262 88.7124 138.599 89.2108 139.071 89.5552C139.543 89.8996 140.12 90.0684 140.703 90.0324C141.814 90.0324 143.029 89.3865 143.132 88.3787C143.227 87.5777 142.718 87.1384 141.737 86.7853L140.212 86.191C137.843 85.3297 136.767 84.3564 136.991 82.4529C137.249 80.1791 139.575 78.689 141.995 78.689C142.986 78.6192 143.972 78.8903 144.788 79.4574C145.604 80.0244 146.202 80.8535 146.482 81.8069L143.743 82.4788C143.615 82.0558 143.35 81.6872 142.99 81.4302C142.63 81.1732 142.196 81.0422 141.754 81.0576Z"
        fill="#EB9F22"
      />
      <path
        d="M151.625 92.246H147.551V88.2495H151.625V92.246Z"
        fill="#EB9F22"
      />
      <path
        d="M25.7108 32.1094V33.3669C25.5531 33.3536 25.3947 33.3536 25.237 33.3669C24.8204 33.3691 24.4211 33.5344 24.1249 33.8275C23.8287 34.1205 23.659 34.5179 23.6522 34.9345V37.0705H22.3086V32.1439H23.6522V33.7287C23.7556 32.8157 24.2121 32.0922 25.2457 32.0922C25.4008 32.078 25.5571 32.0837 25.7108 32.1094Z"
        fill="#0D1F30"
      />
      <path
        d="M29.4056 32.6951V32.118H30.7664V37.0447H29.4056V36.3901C29.291 36.6234 29.1091 36.8171 28.8835 36.946C28.6578 37.075 28.3986 37.1334 28.1395 37.1136C27.5131 37.0502 26.9325 36.7565 26.5104 36.2895C26.0882 35.8224 25.8545 35.2153 25.8545 34.5857C25.8545 33.9561 26.0882 33.3489 26.5104 32.8819C26.9325 32.4148 27.5131 32.1211 28.1395 32.0577C28.9232 32.0577 29.397 32.4884 29.4056 32.6951ZM27.192 34.5728C27.1742 34.7405 27.1908 34.9101 27.2409 35.0712C27.2909 35.2323 27.3732 35.3815 27.4829 35.5097C27.5925 35.6379 27.7271 35.7424 27.8785 35.8167C28.0299 35.8911 28.1949 35.9338 28.3634 35.9422C28.9319 35.9422 29.4659 35.5633 29.4659 34.5728C29.4659 33.5823 28.9319 33.2119 28.3634 33.2119C28.1961 33.2216 28.0325 33.2648 27.8824 33.3391C27.7322 33.4133 27.5984 33.517 27.4891 33.6439C27.3799 33.7709 27.2972 33.9186 27.2462 34.0782C27.1951 34.2377 27.1767 34.4059 27.192 34.5728Z"
        fill="#0D1F30"
      />
      <path
        d="M33.1352 33.2119V35.4599C33.1352 35.6839 33.2213 35.9336 33.54 35.9336C33.7581 35.9429 33.9755 35.9047 34.1774 35.8217V36.9327C33.8761 37.0625 33.549 37.1215 33.2213 37.105C32.4289 37.105 31.7743 36.838 31.7743 35.4685V33.2119H31.292V32.118H31.7399V30.9467L33.1007 31.2653V32.1267H34.1515V33.2205L33.1352 33.2119Z"
        fill="#0D1F30"
      />
      <path
        d="M39.328 34.5987C39.3311 34.7634 39.3167 34.9279 39.2849 35.0896H35.7622C35.8342 35.3361 35.9836 35.5529 36.1884 35.708C36.3931 35.8631 36.6423 35.9483 36.8991 35.9509C37.0557 35.9624 37.2129 35.9415 37.3611 35.8896C37.5092 35.8378 37.6452 35.756 37.7604 35.6495L38.8801 36.2179C38.6317 36.5013 38.3258 36.7286 37.9828 36.8845C37.6397 37.0404 37.2673 37.1215 36.8905 37.1223C36.2455 37.0846 35.6394 36.8019 35.1961 36.3319C34.7528 35.862 34.5059 35.2404 34.5059 34.5943C34.5059 33.9483 34.7528 33.3267 35.1961 32.8568C35.6394 32.3868 36.2455 32.1041 36.8905 32.0664C37.5492 32.08 38.1756 32.3541 38.6325 32.8288C39.0894 33.3035 39.3395 33.9399 39.328 34.5987ZM35.7794 34.0646H37.9499C37.8919 33.8201 37.7533 33.6022 37.5565 33.446C37.3596 33.2897 37.1159 33.2043 36.8646 33.2033C36.6125 33.2012 36.3673 33.2855 36.1698 33.4422C35.9723 33.5989 35.8345 33.8186 35.7794 34.0646Z"
        fill="#0D1F30"
      />
      <path
        d="M50.8684 34.4005V37.0448H49.4989V34.4608C49.4989 33.7029 49.0941 33.2378 48.5256 33.2378C48.2322 33.2505 47.9522 33.3649 47.7338 33.5613C47.5154 33.7577 47.3721 34.0239 47.3284 34.3144V37.0792H45.9503V34.4953C45.9503 33.7373 45.5541 33.2722 44.9598 33.2722C44.3655 33.2722 43.9349 33.7029 43.7884 34.3489V37.1137H42.4189V32.187H43.797V33.1344C43.8915 32.8281 44.0866 32.5628 44.3509 32.3814C44.6151 32.2 44.9329 32.1133 45.2527 32.1353C45.6326 32.1144 46.0101 32.2073 46.3369 32.4022C46.6637 32.5971 46.925 32.885 47.0872 33.2292C47.2361 32.8876 47.4858 32.5997 47.803 32.4042C48.1201 32.2087 48.4895 32.1148 48.8615 32.1353C49.8951 32.0664 50.8684 32.6952 50.8684 34.4005Z"
        fill="#0D1F30"
      />
      <path
        d="M56.614 32.118L53.8837 38.8449H52.5572L53.3324 36.9155L51.248 32.118H52.5831L53.9784 35.3307L55.279 32.118H56.614Z"
        fill="#0D1F30"
      />
      <path
        d="M11.4029 40.8861L8.95679 39.8094L6.51068 40.8861L2.01465 44.8395H8.94818H15.8903L11.4029 40.8861Z"
        fill="#EB9F22"
      />
      <path
        d="M8.94824 33.2292L8.95685 33.2119L8.94824 30.249V33.2119V33.2292Z"
        fill="#EB9F22"
      />
      <path
        d="M8.95685 27.3377L8.94824 27.3291V27.3377V30.2489L8.95685 27.3377Z"
        fill="#222B3F"
      />
      <path
        d="M6.51214 40.8861L6.75331 38.1816L4.99624 36.1403H4.98763L0.319336 33.6942V37.3548V44.8395H2.01611L6.51214 40.8861Z"
        fill="#0D1F30"
      />
      <path
        d="M4.99624 36.1403L7.59739 35.546L8.94965 33.2291V33.2119V30.249V27.3378L1.26678 31.6529L0.319336 32.1869V33.6942L4.98763 36.1403H4.99624Z"
        fill="#143A5A"
      />
      <path
        d="M12.9202 36.1404L11.1631 38.1817L11.4043 40.8862L15.8917 44.8396H17.6746V37.3549V33.6512L12.9202 36.1404Z"
        fill="#4F6473"
      />
      <path
        d="M16.6828 31.6271L9.06882 27.3464L8.95685 27.3378L8.94824 30.249L8.95685 33.2119L10.3177 35.546L12.9189 36.1403L17.6733 33.6512V32.1869L16.6828 31.6271Z"
        fill="#455A6B"
      />
      <path
        d="M23.8146 39.3186H22.4365V38.1213H23.8146V39.3186ZM23.8146 44.7362H22.4365V39.8181H23.8146V44.7362Z"
        fill="#0D1F30"
      />
      <path
        d="M29.4315 41.9025V44.7276H28.0792V41.9886C28.0792 41.1704 27.7519 40.9206 27.2868 40.9206C26.9903 40.9518 26.7096 41.0698 26.4798 41.2598C26.25 41.4498 26.0813 41.7033 25.9948 41.9886V44.7276H24.6426V39.8095H25.9948V40.7397C26.1254 40.4313 26.3493 40.1715 26.6351 39.997C26.9209 39.8224 27.2542 39.7418 27.5883 39.7664C28.5099 39.8009 29.4315 40.3866 29.4315 41.9025Z"
        fill="#0D1F30"
      />
      <path
        d="M31.937 40.9293C31.7303 40.9293 31.5494 41.0327 31.5494 41.2308C31.5494 41.4289 31.6528 41.5236 31.9025 41.6097L32.5485 41.8423C33.2203 42.0834 33.9611 42.3332 33.9611 43.3065C33.9611 44.2798 32.8672 44.831 31.9542 44.831C31.5158 44.8638 31.0786 44.753 30.7087 44.5152C30.3389 44.2774 30.0565 43.9257 29.9043 43.5132L31.0326 43.1687C31.1266 43.3282 31.2618 43.4595 31.4239 43.5489C31.586 43.6383 31.7692 43.6825 31.9542 43.6769C32.2471 43.6769 32.5399 43.5305 32.5399 43.2893C32.5399 43.0481 32.4365 43.0137 32.1954 42.9275L31.5925 42.695C30.6709 42.3505 30.1455 42.0059 30.1455 41.2394C30.1628 41.031 30.2212 40.828 30.3173 40.6423C30.4134 40.4566 30.5454 40.2917 30.7055 40.1572C30.8656 40.0227 31.0508 39.9212 31.2503 39.8586C31.4499 39.796 31.6598 39.7735 31.8681 39.7924C32.2741 39.7743 32.6755 39.8843 33.0156 40.1069C33.3556 40.3295 33.6171 40.6533 33.763 41.0327L32.5571 41.3427C32.4995 41.2252 32.4118 41.125 32.3028 41.0523C32.1939 40.9797 32.0677 40.9373 31.937 40.9293Z"
        fill="#0D1F30"
      />
      <path
        d="M39.345 42.2988C39.3672 42.6176 39.3246 42.9376 39.2199 43.2395C39.1152 43.5415 38.9504 43.8191 38.7356 44.0557C38.5207 44.2922 38.2602 44.4829 37.9697 44.6162C37.6792 44.7494 37.3648 44.8225 37.0453 44.831C36.7872 44.8513 36.5289 44.7931 36.3045 44.664C36.0801 44.5349 35.8999 44.3409 35.7878 44.1075V46.5536H34.4355V39.844H35.7878V40.4211C35.7878 40.2144 36.2701 39.7924 37.0453 39.7924C37.3625 39.801 37.6746 39.8734 37.9633 40.005C38.252 40.1367 38.5112 40.3249 38.7257 40.5587C38.9402 40.7925 39.1056 41.067 39.212 41.3659C39.3183 41.6648 39.3636 41.982 39.345 42.2988ZM38.0014 42.2988C38.0167 42.1317 37.9979 41.9632 37.9463 41.8036C37.8946 41.644 37.8111 41.4965 37.7008 41.3701C37.5905 41.2437 37.4556 41.141 37.3045 41.0682C37.1533 40.9953 36.989 40.954 36.8214 40.9465C36.2615 40.9465 35.7189 41.291 35.7189 42.2988C35.7189 43.3065 36.2615 43.6682 36.8214 43.6682C36.9906 43.661 37.1565 43.6191 37.3089 43.5452C37.4613 43.4713 37.597 43.367 37.7075 43.2387C37.8181 43.1104 37.9012 42.9608 37.9517 42.7991C38.0023 42.6375 38.0192 42.4672 38.0014 42.2988Z"
        fill="#0D1F30"
      />
      <path
        d="M44.7182 42.3247C44.7352 42.4879 44.7352 42.6524 44.7182 42.8156H41.204C41.2761 43.0621 41.4255 43.279 41.6302 43.4341C41.8349 43.5891 42.0841 43.6743 42.3409 43.6769C42.4975 43.6884 42.6547 43.6675 42.8029 43.6157C42.9511 43.5638 43.087 43.482 43.2022 43.3755L44.3133 43.9353C44.0689 44.2212 43.7649 44.4502 43.4228 44.6064C43.0806 44.7626 42.7085 44.8422 42.3323 44.8397C41.6896 44.8021 41.0856 44.5203 40.6439 44.0519C40.2021 43.5836 39.9561 42.9642 39.9561 42.3204C39.9561 41.6766 40.2021 41.0571 40.6439 40.5888C41.0856 40.1204 41.6896 39.8386 42.3323 39.801C42.9812 39.8257 43.5945 40.1038 44.0405 40.5757C44.4866 41.0475 44.7299 41.6755 44.7182 42.3247ZM41.1782 41.7906H43.3487C43.2893 41.5468 43.1504 41.3298 42.9538 41.1738C42.7573 41.0178 42.5143 40.9318 42.2634 40.9293C42.0172 40.9349 41.78 41.0228 41.5896 41.1789C41.3991 41.335 41.2664 41.5504 41.2126 41.7906H41.1782Z"
        fill="#0D1F30"
      />
      <path
        d="M49.6981 40.9034L48.5009 41.3427C48.3999 41.2122 48.2688 41.108 48.1188 41.039C47.9689 40.9701 47.8045 40.9383 47.6396 40.9465C47.4726 40.9582 47.3096 41.0036 47.1606 41.08C47.0116 41.1564 46.8796 41.2622 46.7725 41.3909C46.6655 41.5197 46.5856 41.6688 46.5378 41.8293C46.4899 41.9897 46.4751 42.1582 46.4941 42.3246C46.4775 42.4887 46.4943 42.6545 46.5435 42.8119C46.5927 42.9694 46.6732 43.1152 46.7802 43.2408C46.8872 43.3663 47.0185 43.4689 47.1662 43.5423C47.3139 43.6158 47.4749 43.6586 47.6396 43.6682C47.8036 43.6767 47.9673 43.646 48.1171 43.5786C48.2669 43.5112 48.3985 43.4091 48.5009 43.2807L49.7067 43.7113C49.4632 44.0683 49.1315 44.3562 48.7439 44.5472C48.3562 44.7381 47.9258 44.8256 47.4943 44.8011C47.0629 44.7767 46.6451 44.6411 46.2816 44.4075C45.918 44.1739 45.621 43.8503 45.4194 43.4681C45.2178 43.0859 45.1184 42.6581 45.1309 42.2261C45.1435 41.7942 45.2675 41.3728 45.4909 41.0029C45.7143 40.633 46.0295 40.3272 46.406 40.115C46.7825 39.9029 47.2075 39.7918 47.6396 39.7924C48.0479 39.7924 48.4498 39.894 48.8092 40.088C49.1685 40.2819 49.474 40.5621 49.6981 40.9034Z"
        fill="#0D1F30"
      />
      <path
        d="M51.8514 40.9464V43.1858C51.8514 43.4011 51.9375 43.6595 52.2648 43.6595C52.4806 43.6654 52.6952 43.6242 52.8935 43.5389V44.65C52.5922 44.7798 52.2652 44.8388 51.9375 44.8223C51.1537 44.8223 50.5077 44.5553 50.5077 43.1944V40.9464H50.0254V39.8439H50.5077V38.7415L51.8514 38.9999V39.8612H52.9022V40.9636L51.8514 40.9464Z"
        fill="#0D1F30"
      />
      <path
        d="M58.1466 42.3245C58.1466 42.6537 58.0818 42.9796 57.9558 43.2837C57.8299 43.5878 57.6452 43.8641 57.4125 44.0968C57.1798 44.3296 56.9034 44.5142 56.5994 44.6401C56.2953 44.7661 55.9693 44.8309 55.6402 44.8309C55.3111 44.8309 54.9851 44.7661 54.681 44.6401C54.377 44.5142 54.1006 44.3296 53.8679 44.0968C53.6352 43.8641 53.4505 43.5878 53.3246 43.2837C53.1986 42.9796 53.1338 42.6537 53.1338 42.3245C53.1338 41.9954 53.1986 41.6695 53.3246 41.3654C53.4505 41.0613 53.6352 40.785 53.8679 40.5522C54.1006 40.3195 54.377 40.1349 54.681 40.0089C54.9851 39.8829 55.3111 39.8181 55.6402 39.8181C55.9693 39.8181 56.2953 39.8829 56.5994 40.0089C56.9034 40.1349 57.1798 40.3195 57.4125 40.5522C57.6452 40.785 57.8299 41.0613 57.9558 41.3654C58.0818 41.6695 58.1466 41.9954 58.1466 42.3245ZM54.486 42.3245C54.4569 42.4928 54.4648 42.6654 54.5094 42.8302C54.5539 42.9951 54.634 43.1482 54.7439 43.2789C54.8539 43.4095 54.9911 43.5146 55.1459 43.5867C55.3007 43.6587 55.4694 43.6961 55.6402 43.6961C55.811 43.6961 55.9797 43.6587 56.1345 43.5867C56.2893 43.5146 56.4265 43.4095 56.5365 43.2789C56.6464 43.1482 56.7265 42.9951 56.771 42.8302C56.8156 42.6654 56.8235 42.4928 56.7944 42.3245C56.8235 42.1563 56.8156 41.9837 56.771 41.8188C56.7265 41.6539 56.6464 41.5008 56.5365 41.3702C56.4265 41.2395 56.2893 41.1345 56.1345 41.0624C55.9797 40.9903 55.811 40.953 55.6402 40.953C55.4694 40.953 55.3007 40.9903 55.1459 41.0624C54.9911 41.1345 54.8539 41.2395 54.7439 41.3702C54.634 41.5008 54.5539 41.6539 54.5094 41.8188C54.4648 41.9837 54.4569 42.1563 54.486 42.3245Z"
        fill="#0D1F30"
      />
      <path
        d="M62.1094 39.8354V41.0929C61.9517 41.0796 61.7933 41.0796 61.6357 41.0929C61.2205 41.0974 60.8234 41.2637 60.529 41.5565C60.2347 41.8492 60.0662 42.2454 60.0595 42.6605V44.7879H58.7158V39.8699H60.0595V41.4547C60.1628 40.5417 60.6193 39.8268 61.6443 39.8268C61.7992 39.8145 61.955 39.8174 62.1094 39.8354Z"
        fill="#0D1F30"
      />
      <path
        d="M64.3315 40.9294C64.1248 40.9294 63.9439 41.0327 63.9439 41.2308C63.9439 41.4289 64.0473 41.5237 64.2971 41.6098L64.9431 41.8423C65.6149 42.0835 66.3556 42.3333 66.3556 43.3066C66.3556 44.2799 65.2617 44.8311 64.3487 44.8311C63.9101 44.8653 63.4722 44.7551 63.1021 44.5171C62.7319 44.2792 62.4498 43.9266 62.2988 43.5133L63.4271 43.1688C63.5212 43.3282 63.6563 43.4596 63.8184 43.5489C63.9805 43.6383 64.1637 43.6825 64.3487 43.6769C64.6416 43.6769 64.9344 43.5305 64.9344 43.2894C64.9344 43.0482 64.8311 43.0137 64.5899 42.9276L63.9784 42.695C63.0654 42.3505 62.54 42.006 62.54 41.2394C62.5571 41.027 62.6168 40.8202 62.7156 40.6314C62.8144 40.4426 62.9504 40.2756 63.1152 40.1405C63.28 40.0054 63.4704 39.905 63.675 39.8452C63.8795 39.7854 64.094 39.7674 64.3057 39.7924C64.711 39.7782 65.1107 39.8908 65.4489 40.1147C65.787 40.3387 66.0468 40.6626 66.1919 41.0413L64.9861 41.3514C64.927 41.2272 64.8344 41.122 64.7188 41.0474C64.6031 40.9729 64.4691 40.932 64.3315 40.9294Z"
        fill="#0D1F30"
      />
      <path
        d="M68.1385 44.7621H66.6484V43.2979H68.1385V44.7621Z"
        fill="#E7A523"
      />
      <path
        d="M1.39549 106.208V110.609H6.14129V111.333H1.39549V116.501H0.53418V105.484H7.04567V106.208H1.39549Z"
        fill="#103A5A"
      />
      <path
        d="M18.7345 110.988C18.7057 112.063 18.3607 113.106 17.7424 113.986C17.1242 114.866 16.2603 115.544 15.2587 115.935C14.2572 116.327 13.1624 116.415 12.1113 116.187C11.0602 115.96 10.0994 115.428 9.34904 114.658C8.59872 113.887 8.09217 112.913 7.89273 111.856C7.69329 110.799 7.80982 109.707 8.22773 108.716C8.64565 107.725 9.34641 106.88 10.2424 106.285C11.1384 105.69 12.1898 105.373 13.2652 105.372C14.7349 105.393 16.1363 105.996 17.1618 107.049C18.1873 108.101 18.7529 109.518 18.7345 110.988ZM8.63137 110.988C8.61714 112.241 9.09406 113.451 9.96008 114.357C10.8261 115.263 12.0124 115.795 13.2652 115.837C14.5171 115.784 15.7001 115.25 16.5672 114.346C17.4344 113.441 17.9185 112.237 17.9185 110.984C17.9185 109.731 17.4344 108.526 16.5672 107.622C15.7001 106.717 14.5171 106.183 13.2652 106.13C12.0117 106.175 10.8253 106.708 9.95952 107.616C9.09373 108.524 8.61708 109.734 8.63137 110.988Z"
        fill="#103A5A"
      />
      <path
        d="M26.8465 116.483L23.5305 112.177H20.9465V116.483H20.1455V105.484H23.7199C25.8991 105.484 27.4236 106.854 27.4236 108.835C27.4327 109.664 27.1265 110.466 26.5669 111.079C26.0073 111.691 25.236 112.068 24.409 112.134L27.7681 116.483H26.8465ZM20.9465 111.47H23.5735C25.4598 111.47 26.6226 110.437 26.6226 108.835C26.6226 107.233 25.4598 106.191 23.5735 106.191H20.9465V111.47Z"
        fill="#103A5A"
      />
      <path
        d="M39.8094 116.483L36.4933 112.177H33.9094V116.483H33.1084V105.484H36.6742C38.8619 105.484 40.3865 106.854 40.3865 108.835C40.3934 109.665 40.0853 110.467 39.5243 111.079C38.9633 111.692 38.191 112.068 37.3633 112.134L40.731 116.483H39.8094ZM33.918 111.47H36.545C38.4313 111.47 39.5941 110.437 39.5941 108.835C39.5941 107.233 38.4313 106.191 36.545 106.191H33.918V111.47Z"
        fill="#103A5A"
      />
      <path
        d="M48.3443 115.76V116.483H41.6777V105.484H48.1892V106.208H42.496V110.592H46.613V111.315H42.496V115.76H48.3443Z"
        fill="#103A5A"
      />
      <path
        d="M54.6157 116.595C53.2067 116.485 51.8913 115.847 50.9318 114.809C49.9724 113.771 49.4395 112.41 49.4395 110.997C49.4395 109.583 49.9724 108.222 50.9318 107.184C51.8913 106.147 53.2067 105.509 54.6157 105.398C55.3456 105.385 56.0701 105.526 56.7422 105.81C57.4144 106.095 58.0192 106.518 58.5174 107.052L58.0092 107.569C57.5781 107.103 57.0519 106.736 56.4663 106.493C55.8807 106.249 55.2496 106.134 54.6157 106.156C53.3638 106.209 52.1808 106.743 51.3136 107.648C50.4465 108.552 49.9624 109.757 49.9624 111.01C49.9624 112.263 50.4465 113.467 51.3136 114.371C52.1808 115.276 53.3638 115.81 54.6157 115.863C55.1567 115.885 55.6966 115.797 56.2027 115.605C56.7088 115.413 57.1707 115.119 57.5603 114.744C57.95 114.368 58.2594 113.916 58.4697 113.418C58.6801 112.919 58.7872 112.382 58.7844 111.841H55.4942V111.134H59.551C59.6112 114.407 57.5786 116.595 54.6157 116.595Z"
        fill="#103A5A"
      />
      <path
        d="M61.5315 116.483H60.7305V105.484H61.5315V116.483Z"
        fill="#103A5A"
      />
      <path
        d="M73.9434 110.988C73.9163 112.065 73.5721 113.11 72.9539 113.993C72.3358 114.875 71.4711 115.556 70.468 115.95C69.465 116.343 68.3682 116.432 67.3148 116.206C66.2614 115.979 65.2983 115.447 64.5459 114.675C63.7935 113.904 63.2853 112.928 63.0849 111.869C62.8844 110.811 63.0007 109.716 63.419 108.723C63.8374 107.73 64.5393 106.883 65.4369 106.287C66.3345 105.691 67.388 105.373 68.4655 105.372C69.9359 105.393 71.3382 105.995 72.365 107.048C73.3919 108.101 73.9595 109.518 73.9434 110.988ZM63.8316 110.988C63.8196 112.241 64.2972 113.449 65.1628 114.355C66.0283 115.26 67.2134 115.792 68.4655 115.837C69.7173 115.784 70.9003 115.25 71.7675 114.346C72.6346 113.441 73.1187 112.237 73.1187 110.984C73.1187 109.731 72.6346 108.526 71.7675 107.622C70.9003 106.717 69.7173 106.183 68.4655 106.13C67.2165 106.182 66.0366 106.718 65.1763 107.625C64.316 108.532 63.8429 109.738 63.8574 110.988H63.8316Z"
        fill="#103A5A"
      />
      <path
        d="M83.8217 116.483H83.012L76.1819 106.682V116.483H75.3809V105.484H76.1819L83.012 115.303V105.484H83.8217V116.483Z"
        fill="#103A5A"
      />
    </svg>
  );
}
