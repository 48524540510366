import { useEffect } from "react";
import { FiftyFifty, Loader } from "components";
import { H2, P, PrimaryButton } from "ui-library";
import { DocumentTitle } from "components/document-title";
import { useAuth } from "common/authentication";

export default function InspectorLogoutPage() {
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DocumentTitle title="Logout">
      <FiftyFifty>
        <H2>Log out</H2>
        <P>Please wait a moment while we log you out</P>
        <PrimaryButton style={{ marginTop: 24, width: 200 }}>
          <Loader loading />
        </PrimaryButton>
      </FiftyFifty>
    </DocumentTitle>
  );
}
