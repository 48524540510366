import styled from "styled-components";
import paginationLeftWhite from "assets/icons/pagination-left.svg";
import paginationLeftGrey from "assets/icons/pagination-left-grey.svg";
import paginationRightWhite from "assets/icons/pagination-right.svg";
import paginationRightGrey from "assets/icons/pagination-right-grey.svg";

const PaginationControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  margin-bottom: 32px;
`;

const PaginationControl = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 4px;
  margin-right: 4px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: bolder;
  color: #c4cdd5;

  &.active {
    border: 1px solid #028cb0;
    color: #028cb0;
    cursor: text;
  }
`;

const LeftRightControl = styled(PaginationControl)`
  border: 1px solid #c4cdd5;

  &.disabled {
    background-color: #c4cdd5;
    cursor: not-allowed;
  }
`;

const LeftRightIcon = styled.img`
  width: 9px;
`;

/**
 *
 * @param {Object} props
 * @param {number} props.currentPage
 * @param {number} props.pageCount
 * @param {(pageIndex: number) => void} props.onPageSelected
 */
export function PaginationControls({ currentPage, pageCount, onPageSelected }) {
  if (pageCount <= 4) {
    const indexes = [];
    for (let i = 1; i <= pageCount; i++) {
      indexes.push(i);
    }

    return (
      <PaginationControlsContainer>
        {indexes.map((index) => (
          <PaginationControl
            key={index}
            className={currentPage === index ? "active" : null}
            onClick={currentPage === index ? null : () => onPageSelected(index)}
          >
            {index}
          </PaginationControl>
        ))}
      </PaginationControlsContainer>
    );
  }

  // There will be at most only four page numbers
  // displayed on screen. If the difference between
  // 2nd and 3rd page numbers in the array is more
  // than 1, then we display a ...
  const indexesToDisplay = [
    currentPage,
    currentPage + 1,
    pageCount - 1,
    pageCount,
  ];
  if (currentPage >= pageCount - 3) {
    indexesToDisplay[0] = pageCount - 3;
    indexesToDisplay[1] = pageCount - 2;
  }

  return (
    <PaginationControlsContainer>
      <LeftRightControl
        className={currentPage === 1 ? "disabled" : null}
        onClick={
          currentPage === 1 ? null : () => onPageSelected(currentPage - 1)
        }
      >
        <LeftRightIcon
          src={currentPage === 1 ? paginationLeftWhite : paginationLeftGrey}
        />
      </LeftRightControl>

      <PaginationControl
        className={currentPage === indexesToDisplay[0] ? "active" : null}
        onClick={
          currentPage === indexesToDisplay[0]
            ? null
            : () => onPageSelected(indexesToDisplay[0])
        }
      >
        {indexesToDisplay[0]}
      </PaginationControl>

      <PaginationControl
        className={currentPage === indexesToDisplay[1] ? "active" : null}
        onClick={
          currentPage === indexesToDisplay[1]
            ? null
            : () => onPageSelected(indexesToDisplay[1])
        }
      >
        {indexesToDisplay[1]}
      </PaginationControl>

      {indexesToDisplay[2] - indexesToDisplay[1] > 1 ? (
        <PaginationControl onClick={() => onPageSelected(currentPage + 1)}>
          ...
        </PaginationControl>
      ) : (
        <noscript />
      )}

      <PaginationControl
        className={currentPage === indexesToDisplay[2] ? "active" : null}
        onClick={
          currentPage === indexesToDisplay[2]
            ? null
            : () => onPageSelected(indexesToDisplay[2])
        }
      >
        {indexesToDisplay[2]}
      </PaginationControl>
      <PaginationControl
        className={currentPage === indexesToDisplay[3] ? "active" : null}
        onClick={
          currentPage === indexesToDisplay[3]
            ? null
            : () => onPageSelected(indexesToDisplay[3])
        }
      >
        {indexesToDisplay[3]}
      </PaginationControl>

      <LeftRightControl
        className={currentPage === pageCount ? "disabled" : null}
        onClick={
          currentPage === pageCount
            ? null
            : () => onPageSelected(currentPage + 1)
        }
      >
        <LeftRightIcon
          src={
            currentPage === pageCount
              ? paginationRightWhite
              : paginationRightGrey
          }
        />
      </LeftRightControl>
    </PaginationControlsContainer>
  );
}
