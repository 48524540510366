export const buyers = [
  {
    question: "Why do I need professional inspections when buying a property?",
    answer: `Most people nowadays see the value in having the required property inspections carried out on their behalf when buying a property, but we still hear of people who don’t bother and end up buying a lemon. Excuses for not getting an inspection include: the property was only two years old; it was a bargain; we ran out of time; we are going to renovate anyway; my brother-in-law had a look at it for me and he is a carpenter. Not getting an inspection is taking a risk with a huge financial investment. Get it right and you can potentially save thousands of dollars, understand what maintenance is necessary and cancel the contract (if you are buying and the property has too many costly issues). Skip the inspection and it can cost you thousands, maybe tens or hundreds of thousands. Not only is there the cost of repairs to consider but the time and stress involved with fixing the problems and the opportunity cost of lost capital gains. Many clients have told us horror stories about years of stress and financial hardship they endured because they bought a property without any property inspections. Learn from their mistake and get your property professionally inspected by arranging it on Rate My Inspectors.`,
  },
  {
    question: "When will I receive the report?",
    answer: `Reports can be emailed to the client on the same day by arrangement with the inspector or usually within 24 hours of the inspection being completed onsite. Check the turnaround time with your inspector.  Commercial inspections or more complex inspections are likely to take the inspector longer to prepare.  
    Allow enough time to read the entire report carefully so that you understand the findings and can clarify your understanding with the inspector and arrange any recommended further investigations or inspections.
     If you are a customer getting a pre-purchase inspection/s it is your responsibility to ensure that you receive the report with sufficient time to consider the findings and arrange further investigations or estimates/quotes for any major works needed before the contract to purchase becomes unconditional.
    In summary, ask your inspector when they are likely to have the report ready and make sure that gives you sufficient time to consider the results of the inspection and arrange further investigations and quotes if necessary.`,
  },
  {
    question: "How much does an inspection cost?",
    answer: `Usually, the quote is a fixed lump sum in Australian dollars that includes GST. Ask what methods of payment the inspector has. Most inspectors are set up to accept major credit cards or internet bank transfers. Personal cheques often need a few days to clear so many inspectors do not encourage this method of payment. Most inspectors require verification of payment in their account before they will inspect the property. It will come as no surprise that experienced inspectors with a good reputation are in demand and will cost more than inexperienced inspectors with an unknown history.  Check an inspector's reviews, services offered, and areas covered and request a free quote.`,
  },
  {
    question: "Why is there an inspection agreement?",
    answer: `Most inspectors are required by standards or their insurers to provide an inspection agreement to their clients before the inspection. If you are the client, it's important that you read the agreement and make sure you understand it. If you are unclear on any aspect of the agreement, ask for clarification from the inspector before you proceed with the inspection.`,
  },
  {
    question: "What is in the inspection agreement?",
    answer: `The agreement will vary depending upon the inspector, the type of property to be inspected and the type of inspection required. Some inspectors display a “Sample agreement” on their profile for you to read.  If the inspector does not have a sample agreement displayed on Rate my inspectors you can always ask for a copy of this to be emailed to you before the inspection. Its important to read the agreement carefully to make sure you understand your obligations and what services the inspector will and will not provide. It's normal that an inspector will not include every aspect of the property in their inspection their scope of work may specifically exclude certain items that you would like to have inspected. E.g. You arrange for a Building and Pest inspection on a house you are buying.  You would also like the inspector to check that all the electrical appliances are working and to comment on the presence of any Asbestos at the property.  These electrical installation and asbestos audits are specifically excluded from most standard Building inspection reports.  Therefore you should arrange to get those inspections done by another inspector as part of your due diligence.  That’s where Rate My Inspectors can help.  You can arrange all of the necessary inspections in one place. It is a good idea to try and arrange for the inspectors to attend the property at the same time to minimise site visits for the agent and yourself (if you are attending the inspection).`,
  },
  {
    question: "What happens after I appoint an inspector?",
    answer: `A building inspector can check properties at various stages of construction. These are sometimes called ‘stage inspections’.  The stages usually coincide with the builder’s progress claims. These typically include footing, frame, lock-up (when the roof and walls are up including windows and doors), and the final (also known as ‘Practical Completion’). The inspector verifies that the required work is of an acceptable standard and complies with the National Construction Code and applicable standards and the approved plans and specifications. These inspections can ensure that the works are progressing as required. They help to prevent or resolve disputes with the builder. The building inspector can also help to resolve construction issues and prevent delays by sometimes foreseeing problems.`,
  },
  {
    question:
      "Can the inspector check the stages of a new build?  Like before the concrete slab is poured,  the frame stage, and when the house is finished to make sure the Builder has done a good job?",
    answer: `The agreement will vary depending upon the inspector, the type of property to be inspected and the type of inspection required. Some inspectors display a “Sample agreement” on their profile for you to read.  If the inspector does not have a sample agreement displayed on Rate my inspectors you can always ask for a copy of this to be emailed to you before the inspection. Its important to read the agreement carefully to make sure you understand your obligations and what services the inspector will and will not provide. It's normal that an inspector will not include every aspect of the property in their inspection their scope of work may specifically exclude certain items that you would like to have inspected. E.g. You arrange for a Building and Pest inspection on a house you are buying.  You would also like the inspector to check that all the electrical appliances are working and to comment on the presence of any Asbestos at the property.  These electrical installation and asbestos audits are specifically excluded from most standard Building inspection reports.  Therefore you should arrange to get those inspections done by another inspector as part of your due diligence.  That’s where Rate My Inspectors can help.  You can arrange all of the necessary inspections in one place. It is a good idea to try and arrange for the inspectors to attend the property at the same time to minimise site visits for the agent and yourself (if you are attending the inspection).`,
  },
  {
    question: "What are some of the stage inspections on a new house build?",
    answer: `Inspections can be done at different stages of work. Here is a list of some of the stages which can be inspected on a typical house build.
    Set out, pre-slab, pad footings, and piers are in place.
    Steel reinforcement, termite collars, plumbing rough-in, and formwork have been completed.
    The concrete slab has been poured and site works have begun.
    Foundation brick/block walls, damp proof courses, brick/block piers, perimeter termite management system, bearers, and joists (if it’s a framed floor) have been started.
    Structural framing, including bracing, tie-downs, and structural steel, prior to lock up are in place.
    Lock up – when brickwork or external cladding and roofing is completed and ceilings and wall linings have been installed.
    Waterproofing to wet areas and tiled decks and balconies has been completed.
    Stormwater drainage is in place before trenches are backfilled, including surface drainage and retaining wall drainage.
    Internal fit-out includes completion of woodwork and joinery.
    Practical completion – when the property is almost complete. There will probably be a few items, e.g. paint touch-ups and cleaning, to be completed.
    Final inspection – the property should be finished with only a few very minor defects.
    Defects inspection – takes place usually six months after practical completion has been reached.  The inspector will report of defects and omissions.
    Once you have had the inspector confirm that sufficient work has been completed to the required standard you can be confident in handing over the progress claim payment to the Builder.  We suggest you find a top-rated local inspector on Rate My Inspector.  Then check they do stage inspections by visiting their Rate My Inspector profile. Now you can contact them or request a quote. 
    NB A commercial property like a Hotel may need hundreds of inspections.:If you need Commercial inspections find an inspector on Rate My Inspector that offers that service.
    `,
  },
  {
    question:
      "Do I need an inspection on a new building when there is a Builder’s warranty?",
    answer: `You may think that because the building is a new build that an inspection is not needed. It's very rare that a Builder’s own quality assurance process will correct every defect or remember to install every component.  After all the standard home has more than 6000 components.  
    Things can get a bit confusing when people use different terminology.  An inspection at the end of the build can be known by a few different terms, Final, Practical Completion, Handover, Pre-handover, or even Pre-settlement.   
    If you choose to skip paying for an independent inspection there is a good chance you will discover defects after you take possession of the property.  Some of these defects will be covered by a warranty and fixed by the builder and some will not.  Remember that the Builder’s warranty period will expire and some defects may be present and not reported in time to claim on the warranty.
    The terms of a Builder’s warranty are different depending upon the local authority requirements.   
    Commonly some listed Major defects like roof leaks or subsidence (severe building settlement and cracking) will be covered by the Builders Warranty and other minor defects are only covered if requested to be rectified within the first few months (often 3 to 6 months depending on the local authority).
    It's important to arrange for an independent inspection at the end of a build to make sure you get what you paid for and its well built. Its a good idea to be aware of when the minor and major defects warranties expire so you can have the property inspected again prior to the expiry of those warranties.
    `,
  },
  {
    question:
      "Can the inspector check the stages of a new build?  Like before the concrete slab is poured,  the frame stage, and when the house is finished to make sure the Builder has done a good job?",
    answer: `A building inspector can check properties at various stages of construction. These are sometimes called ‘stage inspections’.  The stages usually coincide with the builder’s progress claims. These typically include footing, frame, lock-up (when the roof and walls are up including windows and doors), and the final (also known as ‘Practical Completion’). The inspector verifies that the required work is of an acceptable standard and complies with the National Construction Code and applicable standards and the approved plans and specifications. These inspections can ensure that the works are progressing as required. They help to prevent or resolve disputes with the builder. The building inspector can also help to resolve construction issues and prevent delays by sometimes foreseeing problems.`,
  },
  {
    question: "What should I do after the inspection is completed?",
    answer: `If you are present on-site during the inspection then ask the inspector/s to show you any reportable issues they found.  This conversation typically only takes around 10 to 15 minutes for a summary of the more significant findings. Although many conversations are longer, some can take an hour. If you are not onsite with the inspector then read the report and text the inspector to make a time to have a chat with them to get any questions answered. `,
  },
  // {
  //   question: "What is the inndox logbook?",
  //   answer: `Inndox logbook is an award-winning technology platform that helps property
  //   owners manage and maintain their property.
  //   <ol>
  //     <li>
  //       With inndox your property files are kept private and accessible in a
  //       cloud-based account that only you have access to. The inndox logbook for
  //       property owners has the following features:
  //     </li>

  //   <li>
  //     Upload files like certificates, approvals, floor plans, paint colours,
  //     insurance policies and photos of your property.
  //   </li>
  //   <li>
  //     Store property contacts like Trades, Suppliers, Consultants and Neighbours.
  //   </li>
  //   <li>Set up warranty expiry alerts and maintenance reminders.</li>
  //   <li>
  //     Share files with your Accountant, Property manager and Agent to make managing
  //     and selling your property easier.
  //   </li>
  //   <li>
  //     Transfer the inndox logbook to the next owner at settlement so they can
  //     benefit from having a ‘digital twin’ of the physical asset.
  //   </li>
  //   </ol>
  //   `,
  // },
  // {
  //   question:
  //     "Why are reports sold through Rate My Inspectors issued on the inndox logbook?",
  //   answer: `When you purchase a report through the Rate My Inspectors platform your report will be provided to you on the inndox logbook. You can view and download your inspection reports and use the other features of inndox. If your inspector is a Rate My Inspectors subscriber then you get the inndox logbook provided to you at no cost to you.  If your inspector is not yet a Rate My Inspector subscriber then you will get a 60-day free trial of inndox logbook. Remember that you can always access your inspection reports at Rate My Inspector.`,
  // },
];

export const sellers = [
  {
    question: `Why should I pay for inspections when I’m selling?`,
    answer: `Many Sellers think its the buyers responsibility to arrange and pay for any inspection reports - and it usually is.  So why would a Seller bother to incur this expense? <br />
    As a vendor, it is important that you remove any barriers to a sale. If you can provide a pre-sale building and pest report and other necessary inspection reports to potential purchasers, you can greatly assist the sale process.
    The last thing you want is for the contract of sale of your property to fall through because the purchaser obtains their own reports revealing significant structural damage or severe termite infestation that you were unaware of. <br /> By arranging and paying for your own inspection reports on Rate My Inspectors you know in advance of any major defects or significant issues giving you the opportunity to have these rectified before putting the property on the market. If you don’t have the funds to get the works done you can always arrange a quote from a Builder and supply this to the prospective buyers with the inspection reports.
    `,
  },
  {
    question: `Won’t buyers try to negotiate price reductions if I supply the reports?`,
    answer: `Your sales agent can share the reports with buyers before or after the buyer makes an offer. Buyers are more likely to make an unconditional offer if they know the condition of the property from a professional independent inspector. The Buyers are less likely to renegotiate a reduced price after the contract has been signed if they were aware of any issues. <br/>
    Your inspector can make the report available on Rate My Inspectors and the agent need only tell them to visit Rate My Inspectors and search the property address to view the available reports.  This saves time for all parties involved.
    `,
  },
  {
    question: `Should I be present at the inspection?`,
    answer: `If you are paying for the inspection they definitely yes you should be present at the inspection.  If the buyer is arranging and paying for the inspection then it's usually advisable that you are not present during the inspection and instead leave this up to your Sales agent to handle this part of the sale. Ask your agent what they prefer.
    `,
  },
  // {
  //   question: "What is the inndox logbook?",
  //   answer: `Inndox logbook is an award-winning technology platform that helps property
  // owners manage and maintain their property.
  // <ol>
  //   <li>
  //     With inndox your property files are kept private and accessible in a
  //     cloud-based account that only you have access to. The inndox logbook for
  //     property owners has the following features:
  //   </li>

  // <li>
  //   Upload files like certificates, approvals, floor plans, paint colours,
  //   insurance policies and photos of your property.
  // </li>
  // <li>
  //   Store property contacts like Trades, Suppliers, Consultants and Neighbours.
  // </li>
  // <li>Set up warranty expiry alerts and maintenance reminders.</li>
  // <li>
  //   Share files with your Accountant, Property manager and Agent to make managing
  //   and selling your property easier.
  // </li>
  // <li>
  //   Transfer the inndox logbook to the next owner at settlement so they can
  //   benefit from having a ‘digital twin’ of the physical asset.
  // </li>
  // </ol>`,
  // },
  // {
  //   question:
  //     "Why are reports sold through Rate My Inspectors issued on the inndox logbook?",
  //   answer: `When you purchase a report through the Rate My Inspectors platform your report will be provided to you on the inndox logbook. You can view and download your inspection reports and use the other features of inndox. If your inspector is a Rate My Inspectors subscriber then you get the inndox logbook provided to you at no cost to you.  If your inspector is not yet a Rate My Inspector subscriber then you will get a 60-day free trial of inndox logbook. Remember that you can always access your inspection reports at Rate My Inspector.`,
  // },
];

export const agents = [
  {
    question: "Why Sales agents are using Rate My Inspectors?",
    answer: `Faster sales allow more time for prospecting and listing more properties for sale. With Rate My Inspectors you can save a lot of your valuable time.
    <ol><li>Learn about all the types of inspections, we have more than 50 types.</li>
    <li>Request multiple quotes from the best-rated inspectors</li>
    <li>Send Sellers to Rate My inspectors to arrange the inspections </li>
    <li>Send Buyers to Rate My Inspectors to view the reports</li>
    <li>Leave reviews for inspectors to help other agents, buyers and sellers</li>
    <li>Have your own FREE account to manage reports, quotes, and reviews</li>
    </ol>
    `,
  },
  {
    question:
      "Maintenance inspections. What are they and when are property managers getting them done?",
    answer: `Property managers know the property very well but they are not professional inspectors and could miss things like an unsafe balcony or an electrical issue. That’s why it is essential to recommend your owners get. professional property inspections every year.  This keeps you and the owner informed about the maintenance needed on the property. Some inspections like Pool safety barriers, Smoke alarms, and Blind cords are mandatory. Rate My Inspectors can help with every type of property inspection.`,
  },
  {
    question:
      "Why Property Managers should arrange a “Start of lease” inspection?",
    answer: `Having your property inspected before starting a lease is a good idea for both the owner and the tenant.  A long list of Safety and Structural issues will be checked by the inspector to make sure the building is safe and well maintained..  If any significant issues are reported then these matters can be rectified by the owner or Property Manager before the tenant moves in.`,
  },
  // {
  //   question: "What is the inndox logbook?",
  //   answer: `Inndox logbook is an award-winning technology platform that helps property
  // owners manage and maintain their property.
  // <ol>
  //   <li>
  //     With inndox your property files are kept private and accessible in a
  //     cloud-based account that only you have access to. The inndox logbook for
  //     property owners has the following features:
  //   </li>

  // <li>
  //   Upload files like certificates, approvals, floor plans, paint colours,
  //   insurance policies and photos of your property.
  // </li>
  // <li>
  //   Store property contacts like Trades, Suppliers, Consultants and Neighbours.
  // </li>
  // <li>Set up warranty expiry alerts and maintenance reminders.</li>
  // <li>
  //   Share files with your Accountant, Property manager and Agent to make managing
  //   and selling your property easier.
  // </li>
  // <li>
  //   Transfer the inndox logbook to the next owner at settlement so they can
  //   benefit from having a ‘digital twin’ of the physical asset.
  // </li>
  // </ol>`,
  // },
  // {
  //   question:
  //     "Why are reports sold through Rate My Inspectors issued on the inndox logbook?",
  //   answer: `When you purchase a report through the Rate My Inspectors platform your report will be provided to you on the inndox logbook. You can view and download your inspection reports and use the other features of inndox. If your inspector is a Rate My Inspectors subscriber then you get the inndox logbook provided to you at no cost to you.  If your inspector is not yet a Rate My Inspector subscriber then you will get a 60-day free trial of inndox logbook. Remember that you can always access your inspection reports at Rate My Inspector.`,
  // },
];

export const inspectors = [
  {
    question: "What is the pricing for Rate My Inspectors?",
    answer: `$69 inc GST per month `,
  },
  {
    question: "What do I get with a free Rate My Inspectors account?",
    answer: `Visit Rate My Inspectors and find your profile or create a free profile.  With a Free account you can:<ol>
    <li>Receive quote requests</li>
    <li>List the types of inspections you offer</li>
    <li>List your service areas</li>
    <li>List the services you offer e.g. Thermal imaging, Same day reports.</li>
    <li>List your licenses </li>
    <li>Display your inspection agreement</li>
    <li>Display Sample inspection reports</li>
    <li>Sell past reports (you can upload recent reports and re-sell them if your inspection agreement permits this)</li>
    <li>Display your business address</li>
    <li>Display your website</li>
    <li>Display your phone number</li>
    <li>Display your ABN</li>
    <li>Display your company logo</li>
    <li>Start collecting and displaying customer reviews</li>
    </ol>`,
  },
  {
    question: "How can I sell my past reports on Rate My Inspectors?",
    answer: `Visit Rate My Inspectors and create an account. Then upload your past reports.  It's best to first remove the name of the client on the report before you upload it for privacy reasons. Both ‘Free’ and ‘Inspector’ (Paid) accounts can sell reports.  When someone buys a report you get 70% of the report sale price if you have a ‘Free’ account and 80% of the report sale price if you are a paid subscriber with an ‘Inspector’ account. `,
  },
  {
    question: "Is it legal to sell reports I did to another person?",
    answer: `Many standard inspection agreements allow the inspector to sell the report to a third party if they wish.  Here is an example of the type of clause that is present in many inspection agreements.
    “We may sell the Report/s to any other person or party although there is no obligation for Us to do so”.
    We recommend that an inspector remove the name of the original client from the report before it is sold again.
    `,
  },
  {
    question: "Can I find other inspectors to assist me with my inspections?",
    answer: `Yes. Visit Rate My Inspectors and find other inspectors. If you are a Building inspector you can find specialist Pest inspectors, Swimming pool safety inspectors, Structural Engineers, and more to assist your clients if its outside of your expertise and insurance. `,
  },
  {
    question: "How do I get more client reviews?",
    answer: `Share your own personal Rate My Inspectors review link with every client and ask them to leave you a review. You can send it via, text, email, messenger, Whatsapp just share the link and they can log in and leave you a review.`,
  },
  {
    question: "Can agents leave reviews for inspectors?",
    answer: `Of course, agents can leave a review for inspectors. Inspectors can send their review link to the agent via, text, email, messenger, Whatsapp just share the link and they can log in in and leave you a review.`,
  },
  //   {
  //     question: "What is the inndox logbook?",
  //     answer: `Inndox logbook is an award-winning technology platform that helps property
  // owners manage and maintain their property.
  // <ol>
  //   <li>
  //     With inndox your property files are kept private and accessible in a
  //     cloud-based account that only you have access to. The inndox logbook for
  //     property owners has the following features:
  //   </li>

  // <li>
  //   Upload files like certificates, approvals, floor plans, paint colours,
  //   insurance policies and photos of your property.
  // </li>
  // <li>
  //   Store property contacts like Trades, Suppliers, Consultants and Neighbours.
  // </li>
  // <li>Set up warranty expiry alerts and maintenance reminders.</li>
  // <li>
  //   Share files with your Accountant, Property manager and Agent to make managing
  //   and selling your property easier.
  // </li>
  // <li>
  //   Transfer the inndox logbook to the next owner at settlement so they can
  //   benefit from having a ‘digital twin’ of the physical asset.
  // </li>
  // </ol>`,
  //   },
  //   {
  //     question:
  //       "Why are reports sold through Rate My Inspectors issued on the inndox logbook?",
  //     answer: `When you purchase a report through the Rate My Inspectors platform your report will be provided to you on the inndox logbook. You can view and download your inspection reports and use the other features of inndox. If your inspector is a Rate My Inspectors subscriber then you get the inndox logbook provided to you at no cost to you.  If your inspector is not yet a Rate My Inspector subscriber then you will get a 60-day free trial of inndox logbook. Remember that you can always access your inspection reports at Rate My Inspector.`,
  //   },
  {
    question: "Can I challenge a fake review?",
    answer: `Sometimes an inspector can do everything right but get a negative review because a party to the transaction feels aggrieved. Inspectors can flag a review as “Inappropriate” and the Rate My Inspectors team will investigate the situation and if the review does not meet our guidelines (not provided by the Inspector’s client or the agent or the review does not otherwise meet our terms and conditions then the review will be removed from the inspector's profile.`,
  },
  {
    question:
      "How much does an inspector make selling past reports on Rate My Inspectors?",
    answer: `Inspectors set the price for their reports. Charge $50 or $5000 it's up to you. If you subscribe to Rate My Inspectors you will get 80% of the report sale price.  If you have a free account then you will get 70% of the report sale. Payments are made via our secure trusted partner Stripe. `,
  },
  {
    question: "Can I join Rate My Inspectors as an individual or as a Company?",
    answer: `You can join Rate My Inspectors as an individual inspector or as a company.  The fee is the same. Just $69 per month including GST. `,
  },
  {
    question:
      "How can I maximise the number of leads I get from Rate My Inspectors?",
    answer: `<ol>
    <li>Subscribe - paid accounts are shown ahead of free accounts </li>
    <li>Get more reviews - Request a review from every agent and every client in every inspection </li>
    <li>Upload your Google reviews - We display your Google reviews on Rate My Inspectors </li>
    <li>Complete your profile - More details like sample reports, videos, and inspection agreements give prospective clients more trust and this will generate more responses to your profile.</li>
    <li>Awards - Win an award to really boost your profile and credibility (Rate My Inspectors Awards coming June 2023)</li>
    </ol>
    `,
  },
];
