import { Accordion, Loader, TextArea } from "components";
import styled from "styled-components";
import circleUncheckedIcon from "assets/icons/circle-unchecked.svg";
import { PrimaryButton } from "ui-library";
import { InspectorsApi } from "services";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Form } from "react-final-form";
import { displayErrorMessage } from "services/api";
import { updateCurrentInspector } from "store/features/inspectors/inspectorsSlice";
import Toast from "components/toast";

const StyledAccordion = styled(Accordion)`
  margin-top: 8px;
`;

/**
 *
 * @param {Object} props
 * @param {import("services").InspectorProfile} props.inspector
 */
export function InspectorBioWidget({ inspector }) {
  const dispatch = useDispatch();

  if (inspector?.bio?.length) {
    return <noscript />;
  }

  return (
    <Form
      onSubmit={async (values) => {
        try {
          await InspectorsApi.updateCurrentInspector(values);
          const updatedInspector =
            await InspectorsApi.getCurrentInspectorProfile();
          dispatch(updateCurrentInspector(updatedInspector));
          toast.success(<Toast title="Bio added" />);
        } catch (e) {
          displayErrorMessage(e);
        }
      }}
      render={({ handleSubmit, submitting }) => (
        <StyledAccordion icon={circleUncheckedIcon} title="Bio">
          <TextArea
            name="bio"
            label="Bio"
            rows={5}
            placeholder="Add a short bio so your potential clients get to know your business a little more"
          />

          <PrimaryButton style={{ marginTop: 24 }} onClick={handleSubmit}>
            <Loader loading={submitting} color="#fff" />
            {submitting ? " Saving" : "Save"}
          </PrimaryButton>
        </StyledAccordion>
      )}
    />
  );
}
